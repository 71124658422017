/*eslint-disable*/
import { useHandleCatch } from "hooks/useHandleCatch";
import React, { useState } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { addData, addSMEIpoSeries, downloadPhysicalFile, fetchAppReportData, fetchClientDetails, fetchClientList, fetchCsvSampleFile, fetchCurrentIpoDetails, fetchCurrentIpoMandateDetails, fetchData, fetchDownloadCsvFile, fetchIpoBidSubmit, fetchIpoDetails, fetchPdfViewVerification, fetchPhysicalPrint, fetchPhysicalPrintReportData, fetchPreferenceDetails, fetchReportData, fetchUploadFileDetails, fetchWebData, handleUploadOnlineBulkBidApi, modifyApplicationBid, updateFetchData, updateMandateData, updateMasterData, updatePreferenceDetails, uploadPdfFile, verifyPdf, verifySeries } from "services/ipo/IpoService";

export const IpoContext = createContext();

export default function IpoApis(props) {
  const [products, setProducts] = useState(null);
  const [ipoData, setIPOData] = useState([]);
  const [ipoOnlineBidClientDetails, setIpoOnlineBidClientDetails] = useState({});
  const [ipoListDetails, setIpoListDetails] = useState([]);
  const [uploadFileDetails, setUploadFileDetails] = useState([]);
  const [clientListDetails, setClientListDetails] = useState([]);
  const [preferanceData, setPreferanceData] = useState({});
  const [loding, setLoding] = useState(false);
  const [bidSubmitResponse, setBidSubmitResponse] = useState({});
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [responseBidDialogue, setResponseBidDialogue] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [reportData, setReportData] = useState([]);
  const [appReportData, setAppReportData] = useState([]);
  const [loadPhysicalPrintCsv, setLoadPhysicalPrintCsv] = useState(false);
  const [onlineBidLoader, setOnlineBidLoader] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [arhamWebData, setArhamWebData] = useState([]);
  const [arhamWebIpoValue, setArhamWebIpoValue] = useState("all");

  const [bidReportDialog, setBidReportDialog] = useState(false);


  const handleCloseBidDialog = () => {
    setBidReportDialog(false);
  }
  const handleOpenBidDialog = () => {
    setBidReportDialog(true);
  }

  const handleCatch = useHandleCatch()


  const downloadZip = (base64Data) => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/zip' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = "physical-print";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAddData = async (
    values,
    resetForm,
    setSubmitting,
    params,
    handleClose,
    status
  ) => {
    const body = {
      ipo: values.ipo_name,
      merchant: values.merchant_name,
      type: values.category,
      start: values.start,
      end: values.end,
      action: status,
    }
    try {
      const response = await addData(body)
      const resJson = response.data;
      if (resJson.status === "success") {
        resetForm({ values: "" });
        setSubmitting(true);
        handleClose();
        handleFetchData(params);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const handleAddSMEIpoSeries = async (
    values, resetForm, setSubmitting, setErrors, statusvalue, handleClose, status, fileData
  ) => {
    const formData = new FormData();
    formData.append("ipo", values.ipo_name);
    formData.append("merchant", values.merchant_name);
    formData.append("file", fileData);
    const paramsData = {
      action: status
    }
    try {
      const response = await addSMEIpoSeries(formData, paramsData)
      const resJson = response.data;
      if (resJson.status === "success") {
        console.log("params", statusvalue);
        resetForm();
        setSubmitting(true);
        handleClose();
        handleFetchData(statusvalue);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  // GET ipo-series-details
  const handleFetchData = async (param) => {
    try {
      const paramsData = {
        ipo_type: param
      }
      const response = await fetchData(paramsData)
      const resJson = response.data;
      console.log("resJson", resJson);
      if (resJson.status === "success") {
        setIPOData(resJson.data.ipo_details);
        const SeriesData = resJson.data.ipo_series_details.map(
          (item, index) => ({
            ...item,
            id: index + 1,
          })
        );
        setProducts(SeriesData);
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const handleFetchClientdetails = async (param) => {
    setIpoOnlineBidClientDetails({});
    try {
      const paramsData = {
        client_code: param
      }
      const response = await fetchClientDetails(paramsData);
      const resJson = response.data;
      if (resJson.status === "success") {
        setIpoOnlineBidClientDetails(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchIpodetails = async (status) => {
    try {
      const paramsData = {
        condition: status || "current"
      };
      const response = await fetchIpoDetails(paramsData);
      const resJson = response.data;
      if (resJson.status === "success") {
        setIpoListDetails(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchIpobidSubmit = async (values, param, id, setCategoriesData, resetForm, setCdslclientId) => {
    setOnlineBidLoader(true);
    try {
      const requestData = {
        symbol: values.selectedOption,
        category: values.categories,
        upi_id: values.bankUPI,
        quantity: values.selectedQuantity * values.lotsize,
        price: values.cutoffPrice,
        amount: Number(values.bidAmount),
        BOId: id,
      };
      const paramsData = { client_code: param };
      const response = await fetchIpoBidSubmit(requestData, paramsData);
      if (Number(values.bidAmount) <= 500000) {
        const resJson = JSON.parse(await response.data.text())
        if (response.status === 200) {
          toast.success(resJson.message);
          setBidSubmitResponse(resJson.data);
          setResponseBidDialogue(true);
          setIpoOnlineBidClientDetails({});
          resetForm();
          setOnlineBidLoader(false);
        } else {
          toast.error(resJson.message);
        }
      } else {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `BidFile.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setCategoriesData([]);
          resetForm();
          setCdslclientId("");
          setBidSubmitResponse([]);
          setOnlineBidLoader(false);
        }
      }
    } catch (error) {
      handleCatch(error)
    } finally {
      setOnlineBidLoader(false);
    }
  };

  const handleFetchClientList = async (id) => {
    try {
      const paramsData = {
        code_part: id
      };
      const response = await fetchClientList(paramsData);
      const resJson = response.data;

      if (resJson.status === "success") {
        setClientListDetails(resJson.data.client_id_list);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  // File Upload
  const handleUploadPdfFile = async (value, values, uploadedFiles) => {
    try {
      const formData = new FormData();
      formData.append("file", uploadedFiles);
      const paramsData = {
        ipo_type: value,
        symbol: values.ipo_name,
        merchant: values.merchant_name,
        category: values.category,
      };
      const response = await uploadPdfFile(formData, paramsData);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleUploadFileDetails(value);
        toast.success("File Uploaded Successfully");
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUploadFileDetails = async (value) => {
    try {
      const paramsData = {
        ipo_type: value,
      };
      const response = await fetchUploadFileDetails(paramsData);
      const resJson = response.data;
      if (resJson.status === "success") {
        const SeriesData = resJson.data.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setUploadFileDetails(SeriesData);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  // Physical Print
  const handleDownloadCsvFile = async (param) => {
    setLoadPhysicalPrintCsv(true);
    try {
      const paramsData = { csv_for: param };
      const response = await fetchDownloadCsvFile(paramsData);
      console.log('response', response)
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `sampleFile.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      const err = JSON.parse(await error.response.data.text());
      toast.error(err.message);
    } finally {
      setLoadPhysicalPrintCsv(false);
    }
  };



  const handleUploadPhysicalPrint = async (values, uploadedFiles, checked, temp) => {
    setLoadPhysicalPrintCsv(true);
    try {
      const formData = new FormData();
      formData.append("ipo", values.ipo_name);
      formData.append("ipo_category", values.categories);
      if (!checked) {
        formData.append("client_codes", values.ucc_code);
        formData.append("quantity", values.quantity * values.lotsize);
      } else {
        formData.append("file", uploadedFiles);
        formData.append("amount_by", values.upload_by);
      }
      const paramsData = { id_type: temp };

      const response = await fetchPhysicalPrint(formData, paramsData);
      const resJson = response.data;

      if (resJson) {
        downloadZip(resJson.data);
        toast.success(resJson.message);
      }
    } catch (error) {
      console.log('error', error)
      handleCatch(error);
    } finally {
      setLoadPhysicalPrintCsv(false);
    }
  };


  const handleUploadOnlineBulkBid = async (values, uploadedFiles) => {
    setLoadPhysicalPrintCsv(true);
    try {
      const formData = new FormData();
      formData.append("symbol", values.ipo_name);
      formData.append("category", values.categories);
      formData.append("file", uploadedFiles);

      const response = await handleUploadOnlineBulkBidApi(formData);
      const resJson = response.data;
      console.log("resJson", resJson);

      if (resJson.status === "success") {
        // Decode the base64 string
        const csvData = atob(resJson.data);
        // Convert the decoded base64 string to a Blob
        const blob = new Blob([csvData], { type: 'text/csv' });

        // Create a download link for the Blob
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `OnlineBidResponse.csv`;

        // Append link, trigger download, and then remove the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Show success toast
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoadPhysicalPrintCsv(false);
    }
  };

  // ###############################################prefernces#########################################################

  const handleFetchPreferanceDetails = async () => {
    setLoding(true);
    try {
      const response = await fetchPreferenceDetails();
      const resJson = response.data;
      console.log("resJson", resJson);

      if (resJson.status === "success") {
        setPreferanceData(resJson?.data);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoding(false);
    }
  };

  const handleUpadtePreferanceDetails = async (values) => {
    try {
      const requestData = {
        preferred_merchant: values.merchant,
        preferred_api: values.api
      };

      const response = await updatePreferenceDetails(requestData);
      const resJson = response.data;
      console.log("resJson", resJson);

      if (resJson.status === "success") {
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  // ###############################################Verification#########################################################

  const handleVerificationSeries = async (values, value, param) => {
    try {
      const requestData = {
        ipo: values.ipo,
        merchant: values.merchant,
        type: values.type || "",
        status: param,
      };
      const response = await verifySeries(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFetchData(value);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleVerificationPDF = async (values, value, param) => {
    try {
      const requestData = {
        ipo_type: values.ipo_type,
        ipo: values.ipo,
        merchant: values.merchant,
        category: values.category,
        status: param,
      };

      const response = await verifyPdf(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleUploadFileDetails(value);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchPdfViewVerifivation = async (values) => {
    try {
      const payload = {
        ipo_type: values.ipo_type,
        ipo: values.symbol,
        merchant: values.merchant,
        category: values.category,
      };

      const response = await fetchPdfViewVerification(payload);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
        setPdfDialogOpen(true);
        toast.success("PDF fetched successfully");
      }
    } catch (error) {
      const err = JSON.parse(await error.response.data.text());
      toast.error(err.message);
    }
  };
  // ##baki
  const handleFectchReportData = async (param, callFor, startDate, endDate) => {
    setReportData([]);
    const downloadCsv = (base64Data) => {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = "bid-report.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    try {
      const requestData = {
        exchange: param,
        call_for: callFor || "data",
        from_date: startDate || "",
        to_date: endDate || ""
      };
      const response = await fetchReportData(requestData);
      if (callFor === "data") {
        const resJson = response.data;
        if (resJson.status === "success") {
          const SeriesData = resJson.data.map((item, index) => ({
            ...item,
            id: index + 1,
          }));
          setReportData(SeriesData);
        }
      } else if (callFor === "file") {
        const resJson = response.data;
        console.log("resJson", resJson);
        if (resJson.status === "success") {
          downloadCsv(resJson.data);
        }
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateMandateData = async (param) => {
    try {
      const paramsData = {
        exchange: param
      };

      const response = await updateMandateData(paramsData);
      const resJson = response.data;

      if (resJson.status == "success") {
        handleFectchReportData(param, "data", "", "");
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFectchAppReportData = async (param) => {
    setAppReportData([]);
    try {
      const paramsData = {
        ipo_type: param
      };
      const response = await fetchAppReportData(paramsData);
      const resJson = response.data;
      if (resJson.status === "success") {
        console.log("handleFectchAppReportData", resJson);
        const SeriesData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setAppReportData(SeriesData);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //////////////////////// Dashboard APIs //////////////////////////
  const navigate = useNavigate();

  const [xdata, setXdata] = useState([]);

  const fetchMandateBids = async () => {
    try {
      const response = await fetchCurrentIpoMandateDetails();
      if (response.status === 200) {
        setXdata(response.data.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  }
  const [upcomingIpoData, setUpcomingIpoData] = useState([]);
  const [currentIpoData, setCurrentIpoData] = useState([]);


  const FetchIpoDetails = async (params) => {
    try {
      const body = {
        condition: params,
      };

      const response = await fetchIpoDetails(body);

      if (response.status === 200) {
        console.log("response", response.data.data);

        setUpcomingIpoData(
          response.data.data.map((item, index) => ({
            ...item,
            sr_no: index + 1,
          }))
        );
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //GET : Fetch Current IPO
  const FetchCurrentIpoDetails = async () => {
    try {
      const response = await fetchCurrentIpoDetails();
      if (response.status === 200) {
        console.log("response", response.data.data);
        setCurrentIpoData(
          response.data.data.map((item, index) => ({
            ...item,
            id: index + 1,
          }))
        );
      }
    } catch (error) {
      handleCatch(error);
    }
  };



  const [physicalPrintReport, setPhysicalPrintReport] = useState([]);
  const handleFectchPhysicalPrintReportData = async (startDate, endDate) => {
    setPhysicalPrintReport([]);
    try {
      const requestData = {
        from_date: startDate,
        to_date: endDate
      };
      const response = await fetchPhysicalPrintReportData(requestData);
      const resJson = response.data;

      if (resJson.status === "success") {
        const SeriesData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setPhysicalPrintReport(SeriesData);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleDownloadCsvFileSeriesUpload = async () => {
    try {
      const response = await fetchCsvSampleFile();
      const resJson = response.data;

      if (response.status === 200) {
        const byteCharacters = atob(resJson.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'SMESeries.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const handleUpdateMasterData = async () => {
    try {
      const response = await updateMasterData();
      const resJson = response.data;
      if (resJson.status === "success") {
        fetchMandateBids();
        FetchIpoDetails("upcoming");
        FetchCurrentIpoDetails();
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleModifyApplicationBid = async (body, setSubmitting) => {
    try {
      const response = await modifyApplicationBid(body);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFectchReportData(body.exchange, "data", "", "");
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setSubmitting(false)
    }
  };



  const handleDownloadPhysicalFile = async (file_path) => {
    try {
      const paramsData = { file_path };
      const response = await downloadPhysicalFile(paramsData);
      const resJson = response.data;
      if (resJson.status === "success") {
        downloadZip(resJson.data);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchWebData = async (param) => {
    try {
      const response = await fetchWebData(param);
      const resJson = response.data;
      if (resJson.status === "success") {
        setArhamWebData(resJson.data);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };



  const handleUpdateWebData = async (data) => {
    const formData = new FormData();
    formData.append('symbol', data.symbol);
    formData.append('issue_size_in_cr', data.issue_size_in_cr || "");
    formData.append('allotment_date', data.allotment_date || "");
    formData.append('refund_date', data.refund_date || "");
    formData.append('credit_share_date', data.credit_share_date || "");
    formData.append('listing_date', data.listing_date || "");
    formData.append('description', data.description || "");
    formData.append('file', data.ipoLogo || "");
    try {
      const response = await updateFetchData(formData);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFetchWebData(arhamWebIpoValue)
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  return (
    <IpoContext.Provider
      value={{
        products,
        handleFetchData,
        ipoData,
        handleAddData,
        handleFetchClientdetails,
        ipoOnlineBidClientDetails,
        setIpoOnlineBidClientDetails,
        handleFetchIpodetails,
        ipoListDetails,
        handleFetchIpobidSubmit,
        bidSubmitResponse,
        setResponseBidDialogue,
        setBidSubmitResponse,
        responseBidDialogue,
        handleUploadPdfFile,
        handleUploadFileDetails,
        uploadFileDetails,
        handleDownloadCsvFile,
        loadPhysicalPrintCsv,
        handleUploadPhysicalPrint,

        handleUploadOnlineBulkBid,

        handleFetchClientList,
        clientListDetails,
        loding,
        handleFetchPreferanceDetails,
        preferanceData,
        handleUpadtePreferanceDetails,
        handleVerificationSeries,
        handleVerificationPDF,
        handleFetchPdfViewVerifivation,
        setPdfDialogOpen,
        pdfDialogOpen,
        pdfUrl,
        handleFectchReportData,
        reportData,
        handleFectchAppReportData,
        appReportData,
        handleUpdateMandateData,
        // dashboard props
        fetchMandateBids,
        xdata,
        setXdata,
        FetchCurrentIpoDetails, FetchIpoDetails, currentIpoData, upcomingIpoData,
        handleFectchPhysicalPrintReportData,
        physicalPrintReport,
        handleAddSMEIpoSeries,
        handleDownloadCsvFileSeriesUpload,
        onlineBidLoader,
        handleUpdateMasterData,
        switchChecked, setSwitchChecked,
        bidReportDialog, setBidReportDialog,
        handleModifyApplicationBid,
        handleDownloadPhysicalFile,
        handleFetchWebData,
        arhamWebData,
        handleUpdateWebData,
        arhamWebIpoValue,
        setArhamWebIpoValue
      }}
    >
      {props.children}
    </IpoContext.Provider>
  );
}
