// assets
import {
  IconApps,
  IconPalette,
  IconShadow,
  IconWindmill,
} from "@tabler/icons";

// constant
const icons = {
  IconApps,
  IconPalette,
  IconShadow,
  IconWindmill,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: "utilities",
  // title: 'Utilities',
  type: "group",
  children: [
    {
      id: "utilities",
      title: "Apps",
      type: "item",
      url: "/utilities",
      icon: icons.IconApps,
      breadcrumbs: true,
    },
  ],
};

export default utilities;
