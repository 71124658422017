import { commonApiRequest } from "utils/CommonFunctions";

export const fetchBranchApi = () => {
    const url = `${process.env.REACT_APP_URL_BRANCH_COST}/v1/user/get-branch-cost`;
    return commonApiRequest('GET', url);
};

export const fetchBranchTotalExpenseApi = () => {
    const url = `${process.env.REACT_APP_URL_BRANCH_COST}/v1/user/get-branch-cost-report`;
    return commonApiRequest('GET', url);
};

export const handleBranchAddApi = (body) => {
    const url = `${process.env.REACT_APP_URL_BRANCH_COST}/v1/user/add-branch-cost`;
    return commonApiRequest('POST', url, body, null);
};

export const handleDeleteApi = (params) => {
    const url = `${process.env.REACT_APP_URL_BRANCH_COST}/v1/user/delete-branch-cost`;
    return commonApiRequest('POST', url, null, params);
};

export const fetchBranchLocationApi = () => {
    const url = `${process.env.REACT_APP_URL_BRANCH_COST}/v1/user/get-branch-location`;
    return commonApiRequest('GET', url);
};

export const handleBranchUpdateApi = (body, params) => {
    const url = `${process.env.REACT_APP_URL_BRANCH_COST}/v1/user/update-branch-cost`;
    return commonApiRequest('POST', url, body, params);
};
export const fetchProfitLossApi = () => {
    const url = `${process.env.REACT_APP_URL_BRANCH_COST}/v1/user/get-pnl`;
    return commonApiRequest('GET', url);
};

export const fetchReportProfitLossApi = () => {
    const url = `${process.env.REACT_APP_URL_BRANCH_COST}/v1/user/get-pnl`;
    return commonApiRequest('GET', url);
};