

// export const cardsData = [
//   {
//     title: "Revenue",
//     change: 24,
//     amount: 420567412,
//   },
//   {
//     title: "Orders",
//     change: -14,
//     amount: 52125.03,
//   },
//   {
//     title: "Expenses",
//     change: 18,
//     amount: 121647.5,
//   },
//   {
//     title: "Profit",
//     change: 12,
//     amount: 1012577.0,
//   },
// // ];

// export const ordersData = [
//   {
//     name: "Skatebnoard",
//     type: "Illustration",
//     items: 58,
//     change: 290,
//   },
//   {
//     name: "Language courses",
//     type: "Illustration",
//     items: 12,
//     change: 72,
//   },
//   {
//     name: "Office Collaboration",
//     type: "Illustration",
//     items: 7,
//     change: 70,
//   },
//   {
//     name: "Robot",
//     type: "Illustration",
//     items: 21,
//     change: 15,
//   },
// ];

// //* get the value in group number format
// export const groupNumber = (number) => {
//   return parseFloat(number.toFixed(2)).toLocaleString("en", {
//     useGrouping: true,
//   });
// };

//* calendar Events
// let eventGuid = 0;
// export const INITIAL_EVENTS = [
//   {
//     id: createEventId(),
//     title: "Lunch Pary",
//     start: "",
//   },
//   {
//     id: createEventId(),
//     title: "Timed event",
//     start: "",
//   }
// ];

// export function createEventId() {
//   return String(eventGuid++);
// }

// * tasks


export const boardData = {
  columns: [
    {
      id: 1,
      title: "Backlog",
      cards: [
        {
          id: 1,
          title: "Database Setup",
          description: "Firebase Integration",
        },
        {
          id: 2,
          title: "Data Flow",
          description: "Setup Diagram with other developers",
        },
      ],
    },
    {
      id: 2,
      title: "TODO",
      cards: [
        {
          id: 9,
          title: "Data Table Page",
          description: "Server side Pagination",
          task_name: "ToDo 1"
        },
        {
          id: 19,
          title: "Data Table Page",
          description: "Server side Pagination",
          task_name: "ToDo 1"
        },
      ],
    },
    {
      id: 3,
      title: "Doing",
      cards: [
        {
          id: 10,
          title: "Full Calendar Extension",
          description: "Make new events and store in global states",
        },
        {
          id: 11,
          title: "Custom Kanban Board",
          description:
            "Setup react-kanban dep within Dashboard as seperate page",
        },
      ],
    },
    {
      id: 4,
      title: "Completed",
      cards: [
        {
          id: 12,
          title: "Vite Server Setup",
          description: "Configure required modules and starters",
        },
        {
          id: 13,
          title: "Modular structre",
          description:
            "Write css in form of modules to reduce the naming conflicts",
        },
      ],
    },
  ],
};

// * user table data
// export const userData = [
//   {
//     name: {
//       firstName: "John",
//       lastName: "Doe",
//     },
//     address: "261 Erdman Ford",
//     city: "East Daphne",
//     state: "Kentucky",
//   },
//   {
//     name: {
//       firstName: "Jane",
//       lastName: "Doe",
//     },
//     address: "769 Dominic Grove",
//     city: "Columbus",
//     state: "Ohio",
//   },
//   {
//     name: {
//       firstName: "Joe",
//       lastName: "Doe",
//     },
//     address: "566 Brakus Inlet",
//     city: "South Linda",
//     state: "West Virginia",
//   },
//   {
//     name: {
//       firstName: "Kevin",
//       lastName: "Vandy",
//     },
//     address: "722 Emie Stream",
//     city: "Lincoln",
//     state: "Nebraska",
//   },
//   {
//     name: {
//       firstName: "Joshua",
//       lastName: "Rolluffs",
//     },
//     address: "32188 Larkin Turnpike",
//     city: "Charleston",
//     state: "South Carolina",
//   },
//   {
//     name: {
//       firstName: "Jane",
//       lastName: "Doe",
//     },
//     address: "769 Dominic Grove",
//     city: "Columbus",
//     state: "Ohio",
//   },
//   {
//     name: {
//       firstName: "Joe",
//       lastName: "Doe",
//     },
//     address: "566 Brakus Inlet",
//     city: "South Linda",
//     state: "West Virginia",
//   },
//   {
//     name: {
//       firstName: "Kevin",
//       lastName: "Vandy",
//     },
//     address: "722 Emie Stream",
//     city: "Lincoln",
//     state: "Nebraska",
//   },
//   {
//     name: {
//       firstName: "Joshua",
//       lastName: "Rolluffs",
//     },
//     address: "32188 Larkin Turnpike",
//     city: "Charleston",
//     state: "South Carolina",
//   },
// ];

