/*eslint-disable*/
import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  addApLeadApi,
  APStatusWiseDataApi,
  addBranchCreation,
} from "services/ap-on-boarding/ApOnBoardingService";
import { handleCatch } from "utils/CommonFunctions";

export const ApOnBoardingContext = createContext();

export default function ApOnBoardingApis(props) {
  const [apData, setApData] = useState([]);
  const [apStatusWiseData, setApStatusWiseData] = useState([]);
  const [verificationData, setVerificationData] = useState([]);
  const [apListData, setApListData] = useState([]);
  const [apDetails, setApDetails] = useState([]);
  const [apFullDetails, setApFullDetails] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [imgData, setImgData] = useState("");
  const [panNo, setPanNo] = useState("");
  const [selectedApData, setSelectedApData] = useState({});
  const [verifyApData, setVerifyApData] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [apStatusWiseCount, setApStatusWiseCount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCount, setVerificationCount] = useState("");
  const [apStatus, setApStatus] = useState("pending");

  const navigate = useNavigate();

  //Ap Lead Add
  const handleAddApLead = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      const response = await addApLeadApi(values);
      const resJson = await response.data;
      // console.log("resJson", resJson);
      if (resJson.status == "success") {
        setSubmitting(true);
        toast.success(resJson.message);
        resetForm();
      } else {
        setSubmitting(false);
        setErrors({ submit: resJson.message });
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setSubmitting(false);
      setErrors({ submit: error.response.data.message });
    }
  };

  // ap Data Get
  const fetchAPStatusWiseData = async (status) => {
    setApStatusWiseData([]);
    setIsLoading(true);
    try {
      const response = await APStatusWiseDataApi(status);
      if (response.status === 200) {
        setApStatusWiseData(response.data.data.data);
        setApStatusWiseCount(response.data.data.count);
        setIsLoading(false);
      } else {
        toast.error(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  const ApformObject = (values) => {
    var formdata = new FormData();
    formdata.append("name", values.ap_name);
    formdata.append("pan_no", values.pan_no);
    formdata.append("pan_name", values.pan_name);
    formdata.append("aadhar_no", values.aadhar_no);
    formdata.append("email", values.email);
    formdata.append("father_name", values.father_name);

    formdata.append("mobile_no", values.mobile_no);
    formdata.append("dob", values.dob);
    formdata.append("trade_name", values.trade_name);
    formdata.append("category", values.category);
    formdata.append("education", values.education);
    formdata.append("ref_code", values.ref_code);
    formdata.append("rm_code", values.rm_code);

    formdata.append("branch", values.branch);
    formdata.append("slab", values.slab);
    formdata.append("remark", values.remark);

    formdata.append("deposit", values.deposit);

    if (values.deposit == "yes") {
      formdata.append("amount", values.amount);
    }

    formdata.append("gender", values.gender);
    formdata.append("married_status", values.married_status);

    formdata.append("off_add1", values.off_address1);
    formdata.append("off_add2", values.off_address2);
    formdata.append("off_add3", values.off_address3);
    formdata.append("off_city", values.off_city);
    formdata.append("off_state", values.off_state);
    formdata.append("off_pincode", values.off_pincode);

    formdata.append("res_add1", values.res_address1);
    formdata.append("res_add2", values.res_address2);
    formdata.append("res_add3", values.res_address3);
    formdata.append("res_city", values.res_city);
    formdata.append("res_state", values.res_state);
    formdata.append("res_pincode", values.res_pincode);

    formdata.append("nse_segment", values.nse_segment);
    formdata.append("bse_segment", values.bse_segment);
    formdata.append("comd_segment", values.comd_segment);

    formdata.append("img_pan", values.pan_upload);
    formdata.append("img_address", values.address_proof_upload);
    formdata.append("img_qualification", values.education_upload);
    formdata.append("img_married", values.married_certi_upload);
    formdata.append("img_cheque", values.check_upload);

    formdata.append("other1", values.other1);
    formdata.append("other2", values.other2);
    formdata.append("other3", values.other3);

    return formdata;
  };

  //AP Add Data
  const handleAddApData = async (
    values,
    handleClose,
    type,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      const formData = ApformObject(values);
      const headers = {
        accept: "application/json",
        authToken: localStorage.authToken,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/add-new-ap?type=${type}`,
        formData,
        {
          headers: headers,
        }
      );

      const resJson = await response.data;
      if (resJson.status == "success") {
        setSubmitting(true);
        toast.success(resJson.message);
        resetForm();
        handleClose();
        fetchAPStatusWiseData("lead");
      } else {
        setSubmitting(false);
        setErrors({ submit: resJson.message });
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setSubmitting(false);
      setErrors({ submit: error.response.data.message });
    }
  };

  const handleVerifyAp = async (values, pan_no, status, handleClose) => {
    try {
      const headers = {
        accept: "application/json",
        authToken: localStorage.authToken,
      };
      const body =
        status === "rejected"
          ? {
              pan_no: pan_no,
              status: status,
              rejected_reason: values.reason,
              branch: "",
            }
          : {
              pan_no: pan_no,
              status: status,
              rejected_reason: "",
              branch: values.branch,
              stamp_date: values.stamp_date,
            };

      const response = await axios.put(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/verification/update-apdata-statuswise`,
        body,
        { headers: headers }
      );

      const resJson = response.data;
      if (resJson.status === "success") {
        handleClose();
        toast.success(resJson.message);
        navigate("/ap-onboarding/prepare-docs");
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  // fetchVerification Data Get
  const fetchVerificationData = async (status) => {
    setIsLoading(true);
    setVerificationData([]);
    setImgData("");
    try {
      const headers = {
        authToken: localStorage.authToken,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/verification/fetch-apdata-statuswise?status=${status}`,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        setVerificationData(response.data.data.data);
        setVerificationCount(response.data.data.count);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  //Get Annexture
  const fetchAnnexture = async (pan_no, segment, annexure, download) => {
    setImgData("");
    setPdfUrl("");
    try {
      const headers = {
        authToken: localStorage.authToken,
      };

      const response = await fetch(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/fetch-ap-annexure?pan_no=${pan_no}&segment=${segment}&annexure=${annexure}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const resJson = await response.json();
      if (resJson.status == "success") {
        const binaryString = atob(resJson.data);
        const arrayBuffer = new ArrayBuffer(binaryString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);

        if (download) {
          const link = document.createElement("a");
          link.href = url;
          link.download = `${pan_no}_${segment}_${annexure}.pdf`;
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        setPdfUrl(url);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  // ap Data Get PanWise
  const fetchAPDataPanWise = async (pan_no) => {
    setSelectedApData([]);
    try {
      const headers = {
        authToken: localStorage.authToken,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/fetch-ap-data-pan-wise?pan_no=${pan_no}`,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        setSelectedApData(response.data.data[0]);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const RegisterApDocs = (values, pan_no) => {
    var formdata = new FormData();
    formdata.append("pan_no", pan_no);
    formdata.append("batch_unit", values.batch_unit);
    formdata.append("file_no", values.file_no);
    formdata.append("ap_reg_date", values.ap_reg_date);
    formdata.append("nse_reg_no", values.nse_reg_no);
    formdata.append("bse_reg_no", values.bse_reg_no);
    formdata.append("mcx_reg_no", values.mcx_reg_no);
    formdata.append("nse_reg_certificate", values.nse_reg_certificate);
    formdata.append("bse_reg_certificate", values.bse_reg_certificate);
    formdata.append("mcx_reg_certificate", values.mcx_reg_certificate);

    return formdata;
  };
  //AP Add Data
  const handleAddVerifyDocs = async (values, pan_no, handleClose) => {
    try {
      const formData = RegisterApDocs(values, pan_no);
      const headers = {
        accept: "application/json",
        authToken: localStorage.authToken,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/add-verify-ap-docs`,
        formData,
        {
          headers: headers,
        }
      );

      const resJson = await response.data;

      if (resJson.status == "success") {
        toast.success(resJson.message);

        handleClose();
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  // Fetch Verify Ap Docs Pan Wise
  const fetchVerifyAPDocs = async (pan_no) => {
    setSelectedApData([]);
    try {
      const headers = {
        authToken: localStorage.authToken,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/fetch-verify-ap-docs?pan_no=${pan_no}`,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        if (
          Array.isArray(response.data.data) &&
          response.data.data.length > 0
        ) {
          setVerifyApData(response.data.data[0]);
        } else {
          setVerifyApData(null);
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAddMessage = async (pan_no, message) => {
    try {
      const headers = {
        accept: "application/json",
        authToken: localStorage.authToken,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/add-comment`,
        {
          pan_no: pan_no,
          message: message,
        },
        {
          headers: headers,
        }
      );
      const resJson = await response.data;

      if (resJson.status == "success") {
        setMessage("");

        fetchMessageData(pan_no);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const fetchMessageData = async (pan_no) => {
    try {
      const headers = {
        accept: "application/json",
        authToken: localStorage.authToken,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/fetch-message?pan_no=${pan_no}`,
        {
          headers: headers,
        }
      );
      const resJson = await response.data;

      if (resJson.status == "success") {
        // console.log("ss:",resJson.data);
        setMessages(resJson.data);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  ////////EXTRA/////

  //GET - app list
  const fetchApList = async () => {
    setIsLoading(true);
    setApData([]);
    try {
      const myHeaders = {
        accept: "application/json",
        authToken: localStorage.authToken,
      };

      const response = await axios.get(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/ap-list-data`,
        {
          headers: myHeaders,
        }
      );
      //code for passing id to response
      if (response.data.status === "success") {
        // setAppData(response.data.data);
        setApData(
          response.data.data.map((item, index) => ({ ...item, id: index + 1 }))
        );
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fechApName = async () => {
    try {
      const headers = {
        authToken: localStorage.authToken,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/fetch-ap-name`,
        {
          headers: headers,
        }
      );

      const resJson = await response.data;
      if (resJson.status == "success") {
        setApListData(resJson.data);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const fechApDetails = async (ap_name) => {
    try {
      const headers = {
        authToken: localStorage.authToken,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/get-ap-details?ap_name=${ap_name}`,
        {
          headers: headers,
        }
      );

      const resJson = await response.data;
      if (resJson.status == "success") {
        setApDetails(resJson.data[0]);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const formObject = (values) => {
    var formdata = new FormData();

    formdata.append("ap_name", values.ap_name);
    formdata.append("trade_name", values.trade_name);
    formdata.append(
      "off_add",
      `${values.off_address1} ${values.off_address2} ${values.off_address3} ${values.off_city} ${values.off_state} ${values.off_pincode}`
    );
    formdata.append(
      "res_add",
      `${values.res_address1} ${values.res_address2} ${values.res_address3} ${values.res_city} ${values.res_state} ${values.res_pincode}`
    );
    formdata.append("deposit", values.deposit);
    formdata.append("amount", values.amount);
    formdata.append("img_pan", values.pan_upload);
    formdata.append("img_aadhar", values.aadhar_upload);
    formdata.append("img_certi", values.certificate_upload);

    return formdata;
  };

  const handleUpdateApData = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      const formData = formObject(values);

      const headers = {
        accept: "application/json",
        authToken: localStorage.authToken,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/updated-ap-list`,
        formData,
        {
          headers: headers,
        }
      );

      const resJson = await response.data;

      if (resJson.status == "success") {
        resetForm();
        setSubmitting(true);
        toast.success(resJson.message);
      } else {
        setSubmitting(false);
        setErrors({ submit: resJson.message });
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setSubmitting(false);
      setErrors({ submit: error.response.data.message });
    }
  };

  const fetchAPFullDetails = async (status) => {
    setApFullDetails([]);
    try {
      const headers = {
        authToken: localStorage.authToken,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URL_AP_ON_BOARDING}/v1/complete-ap-data?status=${status}`,
        {
          headers: headers,
        }
      );

      const resJson = await response.data;
      if (resJson.status == "success") {
        setApFullDetails(resJson.data);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const loadBase64ToInputField = (base64String) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    var mimeType;
    if (
      byteArray[0] === 0x25 &&
      byteArray[1] === 0x50 &&
      byteArray[2] === 0x44 &&
      byteArray[3] === 0x46
    ) {
      mimeType = "application/pdf";
    } else {
      mimeType = "image/jpeg";
    }

    const blob = new Blob([byteArray], { type: mimeType });
    return blob;
  };

  function loadBase64ToFile(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    let mimeType;
    if (
      byteArray[0] === 0x25 &&
      byteArray[1] === 0x50 &&
      byteArray[2] === 0x44 &&
      byteArray[3] === 0x46
    ) {
      mimeType = "application/pdf";
    } else {
      mimeType = "image/jpeg";
    }
    const blob = new Blob([byteArray], { type: mimeType });
    const fileName =
      "Filename" + (mimeType === "application/pdf" ? ".pdf" : ".jpg");
    const file = new File(
      [blob],
      fileName,
      { type: mimeType, lastModified: new Date().getTime() },
      "utf-8"
    );
    const container = new DataTransfer();
    container.items.add(file);
    return container.files;
  }

  const handleBranchCreationAdd = async (
    values,
    others,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      const requestBody = {
        branch_code: values.branch_code || "",
        email: values.email || "",
        team_pwd: values.team_pwd || "",
        self_pwd: values.self_pwd || "",
      };

      const resJson = await addBranchCreation(requestBody);
      setSubmitting(false);
      resetForm({ values: "" });
      toast.success(resJson.data.message);
      others.setOpen(false);
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      setSubmitting(false);
      setErrors({ submit: errorMessage });
    }
  };

  return (
    <ApOnBoardingContext.Provider
      value={{
        handleAddApLead,
        fetchAPStatusWiseData,
        apStatusWiseData,
        handleAddApData,

        fetchVerificationData,
        verificationData,
        setSelectedApData,
        selectedApData,
        fetchAPDataPanWise,
        handleAddVerifyDocs,
        fetchVerifyAPDocs,
        verifyApData,
        handleAddMessage,
        message,
        setMessage,
        fetchMessageData,
        messages,
        setMessages,

        panNo,
        setPanNo,
        fetchApList,
        apData,
        fechApName,
        apListData,
        fechApDetails,
        apDetails,
        handleUpdateApData,
        fetchAPFullDetails,
        apFullDetails,
        fetchAnnexture,
        pdfUrl,
        setPdfUrl,
        handleVerifyAp,

        imgData,
        setImgData,

        loadBase64ToInputField,
        apStatusWiseCount,
        loadBase64ToFile,
        isLoading,
        verificationCount,
        apStatus,
        setApStatus,
        handleBranchCreationAdd,
      }}
    >
      {props.children}
    </ApOnBoardingContext.Provider>
  );
}
