import React, { useRef, useState, createContext } from "react";

import { toast } from "react-toastify";
import { handleCceExchangeFilesApi, handleCceTechExcelFileApi, handleFetchCceReportApi, handleFetchDiscrepancyApi, handleFetchUccReportApi, handleUccExchangeBseFileApi, handleUccExchangeNseFileApi, handleUccTechExcelBseFileApi, handleUccTechExcelNseFileApi } from "services/compliance/compliance";

export const ComplianceContext = createContext();

export default function ComplianceApis(props) {

    const [loading, setLoading] = useState(false);

    const [loadingCceExchange, setLoadingCceExchange] = useState(false);
    const [loadingCceTech, setLoadingCceTech] = useState(false);
    const [loadingNseExchange, setLoadingNseExchange] = useState(false);
    const [loadingBseExchange, setLoadingBseExchange] = useState(false);
    const [loadingNseTech, setLoadingNseTech] = useState(false);
    const [loadingBseTech, setLoadingBseTech] = useState(false);

    //exchange - CCE selected files - multiple
    const exchangeUploadRef = useRef(null);
    const [exchangeSelectedFiles, setExchangeSelectedFiles] = useState([]);

    //techExcel - CCE selected file
    const techExcelUploadRef = useRef(null);
    const [techExcelSelectedFile, setTechExcelSelectedFile] = useState(null);

    //exchange - UCC mismatch
    const nseExchangeInputRef = useRef(null);
    const bseExchangeInputRef = useRef(null);

    const [selectedExchangeNseFile, setSelectedExchangeNseFile] = useState(null);
    const [selectedExchangeBseFile, setSelectedExchangeBseFile] = useState(null);

    //tech Excel - UCC mismatch
    const nseTechExcelInputRef = useRef(null);
    const bseTechExcelInputRef = useRef(null);

    const [selectedTechExcelNseFile, setSelectedTechExcelNseFile] = useState(null);
    const [selectedTechExcelBseFile, setSelectedTechExcelBseFile] = useState(null);


    // UCC reports by segment
    const [uccReportDataBSE, setUccReportDataBSE] = useState([]);

    const [uccReportDataNSE, setUccReportDataNSE] = useState([]);

    // CCE reports by segment
    const [cceReportData, setCceReportData] = useState([]);

    // UCC Discrepancy reports by segment
    const [discrepancyReport, setDiscrepancyReport] = useState([]);

    //POST  upload-cce-exchange - multiple files
    const handleCceExchangeFiles = async (files) => {
        try {
            setLoadingCceExchange(true);
            const formData = new FormData();
            formData.append("files", files);

            const response = await handleCceExchangeFilesApi(formData);

            console.log("response", response);
            toast.success("Files Uploaded Successfully");
            setExchangeSelectedFiles([]);
            exchangeUploadRef.current.value = "";
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data.message);
        } finally {
            setLoadingCceExchange(false);
        }
    }

    //POST upload-cce-techexcel
    const handleCceTechExcelFile = async (file) => {
        try {
            setLoadingCceTech(true)
            const formData = new FormData();
            formData.append("file", file);

            const response = await handleCceTechExcelFileApi(formData);

            console.log("response", response);
            toast.success("Files Uploaded Successfully");
            techExcelUploadRef.current.value = "";
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data.message);
        } finally {
            setLoadingCceTech(false)
        }
    }

    //POST upload-ucc-exchange-nse
    const handleUccExchangeNseFile = async (file) => {
        try {
            setLoadingNseExchange(true)
            const formData = new FormData();
            formData.append("file", file);

            const response = await handleUccExchangeNseFileApi(formData);

            console.log("response", response);
            toast.success("Files Uploaded Successfully");
            nseExchangeInputRef.current.value = "";
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data.message);
        } finally {
            setLoadingNseExchange(false)
        }
    }
    //POST upload-ucc-exchange-bse
    const handleUccExchangeBseFile = async (file) => {
        try {
            setLoadingBseExchange(true)
            const formData = new FormData();
            formData.append("file", file);

            const response = await handleUccExchangeBseFileApi(formData);

            console.log("response", response);
            toast.success("Files Uploaded Successfully");
            bseExchangeInputRef.current.value = "";
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data.message);
        } finally {
            setLoadingBseExchange(false)
        }
    }

    //POST upload-ucc-tech-excel-nse
    const handleUccTechExcelNseFile = async (file) => {
        console.log("payload", file);
        try {
            setLoadingNseTech(true)
            const formData = new FormData();
            formData.append("file", file);

            const response = await handleUccTechExcelNseFileApi(formData)
            console.log("response", response);
            toast.success("Files Uploaded Successfully");
            nseTechExcelInputRef.current.value = "";
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data.message);
        } finally {
            setLoadingNseTech(false)
        }
    }
    //POST upload-ucc-tech-excel-bse
    const handleUccTechExcelBseFile = async (file) => {
        try {
            setLoadingBseTech(true)
            const formData = new FormData();
            formData.append("file", file);

            const response = await handleUccTechExcelBseFileApi(formData);
            console.log("response", response);
            toast.success("Files Uploaded Successfully");
            bseTechExcelInputRef.current.value = "";
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message);
        } finally {
            setLoadingBseTech(false)
        }
    }

    //GET Fetch CCE Report Data by date range
    const handleFetchCceReport = async (payload) => {
        setLoading(true);
        try {
            const params = {
                from_date: payload.fromDate,
                to_date: payload.toDate
            };
            const response = await handleFetchCceReportApi(params);

            console.log("log response", response);
            setCceReportData(response.data.data.map((item, index) => ({ ...item, sr_no: index + 1 })));
        } catch (error) {
            toast.error(error?.response?.data.message);
            console.error("error", error);
        } finally {
            setLoading(false);
        }
    };
    //GET Fetch UCC Report Data by segment
    const handleFetchUccReport = async (segment) => {
        setLoading(true);
        try {
            const params = { segment };
            const response = await handleFetchUccReportApi(params);

            // console.log("log response", response.data.data);
            segment == "BSE" ?
                setUccReportDataBSE(response.data.data.map((item, index) => ({ ...item, sr_no: index + 1 })))
                :
                setUccReportDataNSE(response.data.data.map((item, index) => ({ ...item, sr_no: index + 1 })))
        } catch (error) {
            toast.error(error?.response?.data.message);
            console.error("error", error);
        } finally {
            setLoading(false);
        }
    };


    //GET Fetch UCC Descripancy Report Data
    const handleFetchDiscrepancy = async () => {
        setLoading(true);
        try {
            const params = { segment: 'NSE' };

            const response = await handleFetchDiscrepancyApi(params);

            // console.log("log response", response.data.data);
            setDiscrepancyReport(response.data.data.map((item, index) => ({ ...item, sr_no: index + 1 })));
        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.error("error", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ComplianceContext.Provider value={{

            handleCceExchangeFiles,
            exchangeUploadRef, loadingCceExchange,
            exchangeSelectedFiles,
            setExchangeSelectedFiles,

            handleCceTechExcelFile,
            techExcelUploadRef, loadingCceTech,
            techExcelSelectedFile,
            setTechExcelSelectedFile,

            handleUccExchangeNseFile,
            nseExchangeInputRef, loadingNseExchange,
            selectedExchangeNseFile, setSelectedExchangeNseFile,

            handleUccExchangeBseFile,
            bseExchangeInputRef, loadingBseExchange,
            selectedExchangeBseFile, setSelectedExchangeBseFile,

            handleUccTechExcelNseFile,
            nseTechExcelInputRef, loadingNseTech,
            selectedTechExcelNseFile, setSelectedTechExcelNseFile,

            handleUccTechExcelBseFile,
            bseTechExcelInputRef, loadingBseTech,
            selectedTechExcelBseFile, setSelectedTechExcelBseFile,

            handleFetchUccReport,
            uccReportDataBSE, setUccReportDataBSE,
            uccReportDataNSE, setUccReportDataNSE,

            handleFetchCceReport, cceReportData, setCceReportData,

            handleFetchDiscrepancy, discrepancyReport,

            loading, setLoading
        }}>
            {props.children}
        </ComplianceContext.Provider>
    )
}
