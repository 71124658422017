import { commonApiRequest } from "utils/CommonFunctions";

const COMMON_URL = process.env.REACT_APP_URL_PETTY_CASH;

export const fetchBalanceDashApi = () => {
    const url = `${COMMON_URL}/v1/user/dashboard`;
    return commonApiRequest('GET', url);
};

export const fetchPettyCashRequestApi = () => {
    const url = `${COMMON_URL}/v1/user/get-petty`;
    return commonApiRequest('GET', url);
};

export const handleAddRequestApi = (body) => {
    const url = `${COMMON_URL}/v1/user/request-petty`;
    return commonApiRequest('POST', url, body);
};

export const handleUpdateRequestApi = (body, params) => {
    const url = `${COMMON_URL}/v1/user/update-petty`;
    return commonApiRequest('POST', url, body, params);
};

export const handleDeleteRequestApi = (params) => {
    const url = `${COMMON_URL}/v1/user/delete-petty`;
    return commonApiRequest('POST', url, null, params);
};

export const fetchAllEmployeeRequestApi = () => {
    const url = `${COMMON_URL}/v1/user/get-petty-app-rej`;
    return commonApiRequest('GET', url);
};

export const handleApproveRejectApi = (params) => {
    const url = `${COMMON_URL}/v1/user/app-rej-petty`;
    return commonApiRequest('POST', url, null, params);
};

export const fetchAllEmployeeExpenseApi = () => {
    const url = `${COMMON_URL}/v1/user/get-all-expenses`;
    return commonApiRequest('GET', url);
};

export const fetchPettyCashExpenseApi = () => {
    const url = `${COMMON_URL}/v1/user/get-expenses`;
    return commonApiRequest('GET', url);
};

export const handleAddExpenseApi = (formData) => {
    const url = `${COMMON_URL}/v1/user/add-expenses`;
    return commonApiRequest('POST', url, formData);
};

export const handleApproveRejectExpenseApi = (params) => {
    const url = `${COMMON_URL}/v1/user/expenses-status`;
    return commonApiRequest('POST', url, null, params);
};

export const handleUpdateExpenseApi = (params, formData) => {
    const url = `${COMMON_URL}/v1/user/update-expenses`;
    return commonApiRequest('POST', url, formData, params);
};

export const handleDeleteExpenseApi = (params) => {
    const url = `${COMMON_URL}/v1/user/delete-expenses`;
    return commonApiRequest('POST', url, null, params);
};

export const updatePaymentStatusApi = (params, body) => {
    const url = `${COMMON_URL}/v1/user/petty-payment`;
    return commonApiRequest('POST', url, body, params);
};

export const confirmPaymentApi = (params) => {
    const url = `${COMMON_URL}/v1/user/petty-received`;
    return commonApiRequest('POST', url, null, params);
};

export const fetchRequestsReportApi = () => {
    const url = `${COMMON_URL}/v1/user/get-petty-logs`;
    return commonApiRequest('GET', url);
};

export const fetchExpenseReportApi = () => {
    const url = `${COMMON_URL}/v1/user/get-all-expenses`;
    return commonApiRequest('GET', url);
};
