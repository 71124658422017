/*eslint-disable*/
import { commonApiRequest } from "utils/CommonFunctions";

const baseURL = process.env.REACT_APP_URL_INSURANCE_LEAD;

export const fetchInsurance = () => {
  const url = `${baseURL}/V1/insurance_get`;
  return commonApiRequest("GET", url);
};

export const handleBulkUpload = (formData) => {
  const url = `${baseURL}/V1/insurance_bulk_add`;
  return commonApiRequest("POST", url, formData);
};

export const handleInsuranceAdd = (formData) => {
  const url = `${baseURL}/V1/insurance_add`;
  return commonApiRequest("POST", url, formData);
};

export const handleInsuranceUpdate = (formData) => {
  const url = `${baseURL}/V1/insurance_update`;
  return commonApiRequest("PUT", url, formData);
};

export const handleDelete = (params) => {
  const url = `${baseURL}/V1/insurance_delete`;
  return commonApiRequest("DELETE", url, null, params);
};
