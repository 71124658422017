/*eslint-disable*/
import React, { useState, createContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  segmentAdditionDataApi,
  verifyOtpApi,
} from "services/customer-care/customerCareServices";
import { handleCatch } from "utils/CommonFunctions";

export const CustomerCareContext = createContext();

export default function CustomerCareApi({ children }) {
  const [segmentAdditionData, setSegmentAdditionData] = useState({});
  const [segmentAdditionApprovalData, setSegmentAdditionApprovalData] =
    useState({});
  const [ApprovalStatus, setApprovalStatus] = useState("Pending");
  const [otpData, setOtpData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [verifyOtp, setVerifyOtp] = useState("");
  const [otpVerifyDialogOpen, setOtpVerifyDialogOpen] = useState(false);
  const [mfOtpVerifyDialogOpen, setMfOtpVerifyDialogOpen] = useState(false);
  const [selectedClientCode, setSelectedClientCode] = useState("");
  const [selectedSegment, setSelectedSegment] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedMultiSegments, setSelectedMultiSegments] = useState("");
  const [switchStates, setSwitchStates] = useState({});
  const [changedSegments, setChangedSegments] = useState({});
  const [esignResponse, setResponse] = useState();
  const [clickButton, setClickButton] = useState(false);
  const [Segment, SetSegment] = useState([]);

  const handleSegmentAdditionData = async () => {
    setIsLoading(true);
    try {
      const response = await segmentAdditionDataApi(globalFilter, pagination);

      if (response.data.status === "success") {
        setRowCount(response.data.data.row_count);
        setSegmentAdditionData(response.data.data.data);
      } else {
        toast.error("Failed to fetch data");
      }
    } catch (error) {
      handleCatch(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendOtp = async (row, process, source) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("process", process);
      formData.append("source ", source);
      formData.append("client_code", row._valuesCache?.client_id);
      const switchState = changedSegments[row._valuesCache?.client_id];
      let segment = [];

      if (switchState?.MF_BSE) segment.push("Mutual fund");
      if (switchState?.SLBM) segment.push("SLBM");
      formData.append("segment", segment.join(", "));
      SetSegment(segment);

      const response = await axios.post(
        `http://192.168.119.183:1818/v1/account_profile/segmentAddition?process=${process}&source=${source}`,
        formData,
        {
          headers: {
            accept: "application/json",
            authToken: localStorage.authToken,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        if (selectedSegment === "SLBM") {
          handleSegmentAdditionData("NSE_SLBM");
        } else {
          handleSegmentAdditionData("MF_BSE");
        }
        setOtpData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSegmentAdditionApprovalData = async (segment) => {
    setIsLoading(true);
    setSegmentAdditionApprovalData([]);
    try {
      let url = `${process.env.REACT_APP_SEGMENT_ADDITION}/v1/segment_type_data?kyc_status=${ApprovalStatus}`;
      if (globalFilter) {
        url += `&client_code=${globalFilter}`;
      }

      const response = await axios.post(
        url,
        {
          page_index: pagination.pageIndex + 1,
          page_size: pagination.pageSize,
        },
        {
          headers: {
            accept: "application/json",
            "auth-token": localStorage.authToken,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (response.data.status === "success") {
        setRowCount(response.data.data.row_count);
        setSegmentAdditionApprovalData(response.data.data.data);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async (selectedClientCode, process, source) => {
    try {
      const response = await verifyOtpApi(
        selectedClientCode,
        process,
        source,
        verifyOtp,
        Segment
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setResponse(response.data.data.data);
        setClickButton(true);
        setOtpVerifyDialogOpen(false);
        handleSegmentAdditionData("NSE_SLBM");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
      setOtpVerifyDialogOpen(false);
    } finally {
      setSelectedMultiSegments("");
    }
  };

  return (
    <CustomerCareContext.Provider
      value={{
        handleSegmentAdditionData,
        segmentAdditionData,
        isError,
        handleSendOtp,
        isLoading,
        otpData,
        pagination,
        setPagination,
        rowCount,
        handleSegmentAdditionApprovalData,
        ApprovalStatus,
        setApprovalStatus,
        segmentAdditionApprovalData,
        handleVerifyOtp,
        setVerifyOtp,
        verifyOtp,
        setSelectedClientCode,
        selectedClientCode,
        setOtpVerifyDialogOpen,
        otpVerifyDialogOpen,
        setSelectedSegment,
        setMfOtpVerifyDialogOpen,
        mfOtpVerifyDialogOpen,
        setGlobalFilter,
        globalFilter,
        setSelectedMultiSegments,
        selectedMultiSegments,
        setSwitchStates,
        switchStates,
        esignResponse,
        clickButton,
        setChangedSegments,
      }}
    >
      {children}
    </CustomerCareContext.Provider>
  );
}
