/*eslint-disable*/
import React, { useContext, useEffect } from "react";

import CustomBreadcrumbs from "views/utilities/common-components/CustomBreadcrumbs";
import MainCard from "ui-component/cards/MainCard";
import { CrmsContext } from "context/CRMS/CRMSApis";

import { LinearProgress } from "@mui/material";
import MUIDataGridTable from "views/utilities/rtrms/common-component/MUIDataGridTable";

export default function ClientList() {
  const Breadcrumbs = [
    {
      to: "/utilities/crms/reports",
      label: "reports"
    }
  ];

  const { handleFetchDataClientWise, kycClientData, isLoading } =
    useContext(CrmsContext);

  useEffect(() => {
    handleFetchDataClientWise();
  }, []);

  const columns = [
    {
      field: "client_id",
      headerName: "Client Id",
      flex: 1,
      headerClassName: "header",
      align: "left"
    },
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 2,
      headerClassName: "header",
      align: "left"
    },
    {
      field: "branch_code",
      headerName: "Branch Code",
      flex: 1,
      headerClassName: "header",
      align: "left"
    },
    {
      field: "last_trade_date",
      headerName: "Last Trade Date",
      flex: 1,
      headerClassName: "header",
      align: "left",
      renderCell: (params) => {
        const dateStr = params?.value; // Assuming params.value contains the date as a string in 'yyyyMMdd' format

        if (!dateStr) return "-";
        const year = dateStr.substring(0, 4);
        const month = dateStr.substring(4, 6);
        const day = dateStr.substring(6, 8);

        // Format the date as 'dd/mm/yyyy'
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header",
      align: "left",
      renderCell: (params) => {
        return params?.value ? params?.value : "-";
      }
    },
    {
      field: "brokerage",
      headerName: "Brokerage",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => {
        if (params?.value == null || isNaN(params.value)) {
          return 0;
        }
        return params.value.toFixed(2);
      }
    }
,    
    {
      field: "total_collateral",
      headerName: "Total Collateral",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => {
        params?.value?.toFixed(2);
      }
    }
  ];

  return (
    <>
      <MainCard
        title={
          <CustomBreadcrumbs
            links={Breadcrumbs}
            title="Client List"
            moduleName="crms"
          />
        }
        contentSX={{ p: 0 }}
      >
        {isLoading ? (
          <LinearProgress />
        ) : (
          <MUIDataGridTable
            columns={columns}
            rows={kycClientData}
            filename="clientSummary"
          />
        )}
      </MainCard>
    </>
  );
}

// /*eslint-disable*/
// import React, { useState, useRef, useContext, useEffect } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { FilterMatchMode, FilterOperator } from "primereact/api";
// import CustomBreadcrumbs from "views/utilities/common-components/CustomBreadcrumbs";
// import MainCard from "ui-component/cards/MainCard";
// import { CrmsContext } from "context/CRMS/CRMSApis";

// import ScssStyle from "assets/scss/_themes-vars.module.scss";
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import { LinearProgress } from "@mui/material";
// import DownloadIcon from "@mui/icons-material/Download";

// export default function ClientList() {
//   const Breadcrumbs = [
//     {
//       to: "/utilities/crms/reports",
//       label: "reports"
//     }
//   ];

//   const { handleFetchDataClientWise, kycClientData, isLoading } =
//     useContext(CrmsContext);

//   useEffect(() => {
//     handleFetchDataClientWise();
//   }, []);

//   const styles = ScssStyle;
//   const [selectedProducts, setSelectedProducts] = useState(null);
//   const [globalFilter, setGlobalFilter] = useState(null);
//   const toast = useRef(null);
//   const dt = useRef(null);

//   const exportCSV = () => {
//     dt.current.exportCSV();
//   };
//   const [first, setFirst] = useState(0);

//   const onPageChange = (event) => {
//     setFirst(event.first);
//   };

//   const [filters, setFilters] = useState(null);
//   const [globalFilterValue, setGlobalFilterValue] = useState("");

//   useEffect(() => {
//     initFilters();
//   }, []);

//   const clearFilter = () => {
//     initFilters();
//   };

//   const onGlobalFilterChange = (e) => {
//     const value = e.target.value;
//     let _filters = { ...filters };
//     _filters["global"].value = value;
//     setFilters(_filters);
//     setGlobalFilterValue(value);
//   };

//   const initFilters = () => {
//     setFilters({
//       global: { value: null, matchMode: FilterMatchMode.CONTAINS },
//       id: {
//         operator: FilterOperator.AND,
//         constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
//       },
//       client_id: {
//         operator: FilterOperator.AND,
//         constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
//       },
//       full_name: {
//         operator: FilterOperator.AND,
//         constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
//       },
//       branch_code: {
//         operator: FilterOperator.AND,
//         constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
//       },
//       last_trade_date: {
//         operator: FilterOperator.AND,
//         constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
//       },
//       status: {
//         operator: FilterOperator.AND,
//         constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
//       },
//       brokerage: {
//         operator: FilterOperator.AND,
//         constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
//       }
//     });
//     setGlobalFilterValue("");
//   };

//   const columns = [
//     {
//       field: "id",
//       header: "S.NO",
//       minWidth: 2
//     },
//     {
//       field: "client_id",
//       header: "Client Id",
//       minWidth: 2
//     },
//     {
//       field: "full_name",
//       header: "Full Name",
//       minWidth: 2
//     },
//     {
//       field: "branch_code",
//       header: "Branch Code",
//       minWidth: 2
//     },
//     {
//       field: "last_trade_date",
//       header: "Last Trade Date",
//       minWidth: 2
//     },

//     {
//       field: "status",
//       header: "Status",
//       minWidth: 2
//     },
//     {
//       field: "brokerage",
//       header: "Brokerage",
//       minWidth: 2,
//       body: (data) => data?.brokerage?.toFixed(2)
//     },
//     {
//       field: "colletral",
//       header: "Total Collateral",
//       minWidth: 2,
//       body: (data) => data?.colletral?.toFixed(2)
//     }
//   ];

//   const header = () => {
//     return (
//       <div className="flex justify-content-between " style={{ height: "25px" }}>
//         <Button
//           type="button"
//           icon="pi pi-filter-slash"
//           label="Clear"
//           outlined
//           onClick={clearFilter}
//           style={{ height: "20px" }}
//         />
//         <span className="p-input-icon-left">
//           <i className="pi pi-search" />
//           <InputText
//             value={globalFilterValue}
//             onChange={onGlobalFilterChange}
//             placeholder="Keyword Search"
//             style={{ height: "20px" }}
//           />
//         </span>
//         <Button onClick={exportCSV}>
//           <DownloadIcon />
//         </Button>
//       </div>
//     );
//   };

//   const generateColumns = (column) => {
//     return column.map((config, index) => {
//       const { field, header, body, minWidth } = config;
//       return (
//         <Column
//           key={index}
//           field={field}
//           header={header}
//           body={body}
//           sortable
//           filter
//           headerStyle={{ height: "10px" }}
//           style={{
//             minWidth: `${minWidth}rem`,
//             fontSize: "0.70rem",
//             color: styles.secondaryDark,
//             textAlign: "left"
//           }}
//         />
//       );
//     });
//   };

//   return (
//     <>
//       <MainCard
//         title={
//           <CustomBreadcrumbs
//             links={Breadcrumbs}
//             title="Client List"
//             moduleName="crms"
//           />
//         }
//         contentSX={{ p: 0 }}
//       >
//         {/* <CRMSTable
//           rowData={kycClientData}
//           columns={columns}
//           scrollHeight="60vh"
//         /> */}

//         {isLoading ? (
//           <LinearProgress />
//         ) : (
//           <DataTable
//             size="small"
//             ref={dt}
//             value={kycClientData}
//             selection={selectedProducts}
//             onSelectionChange={(e) => setSelectedProducts(e.value)}
//             dataKey="id"
//             paginator
//             rows={25}
//             rowsPerPageOptions={[5, 25, 50]}
//             paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
//             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} KYC"
//             globalFilter={globalFilter}
//             globalFilterFields={["branch_code"]}
//             header={header}
//             scrollable
//             resizableColumns
//             height="100vh"
//             scrollHeight="60vh"
//             reorderableColumns
//             reorderableRows
//             alwaysShowPaginator={false}
//             paginatorPosition="bottom"
//           >
//             {generateColumns(columns)}
//           </DataTable>
//         )}
//       </MainCard>
//     </>
//   );
// }
