/*eslint-disable*/
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchBlog,
  handleBlogAdd,
  handleBlogDelete,
  handleBlogUpdate,
} from "services/blog/blog";

export const BlogContext = createContext();

export default function blogApi(props) {
  const [blogData, setBlogData] = useState([]);

  const fetchAllBlog = async () => {
    try {
      const response = await fetchBlog();
      if (response.status === 200) {
        console.log("response", response);
        setBlogData(response.data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const addBlog = async (values, others) => {
    try {
      const formData = new FormData();
      formData.append("blog_header", values.blog_header);
      formData.append("blog_content", values.blog_content);

      if (values.file) {
        formData.append("file", values.file);
      }
      const resJson = await handleBlogAdd(formData);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllBlog();
    } catch (error) {
      console.error("error", error);

      toast.error(resJson.message);
    }
  };

  const updateBlog = async (values, others, blog_id) => {
    try {
      const formData = new FormData();
      formData.append("blog_id", blog_id);
      formData.append("blog_header", values.blog_header);
      formData.append("blog_content", values.blog_content);

      if (values.file) {
        formData.append("file", values.file);
      }
      const resJson = await handleBlogUpdate(formData, blog_id);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllBlog();
    } catch (error) {
      console.error("error", error);
      toast.error(resJson.message);
    }
  };

  const deleteBlog = async (blog_id) => {
    try {
      const resJson = await handleBlogDelete({ blog_id });
      console.log("resJson", resJson);
      if (resJson.status === 200) {
        fetchAllBlog();
        toast.success(resJson.data.message);
      } else {
        toast.error("Failed to delete blog.");
      }
    } catch (error) {
      console.error("Delete blog error:", error);
      toast.error("An error occurred while deleting the blog.");
    }
  };

  return (
    <BlogContext.Provider
      value={{
        blogData,
        setBlogData,
        addBlog,
        fetchAllBlog,
        deleteBlog,
        updateBlog,
      }}
    >
      {props.children}
    </BlogContext.Provider>
  );
}
