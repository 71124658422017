/*eslint-disable*/
import React, { useRef, useState } from "react";
import { saveAs } from "file-saver";
import axios from "axios";
import { createContext } from "react";
import { toast } from "react-toastify";
import {
  addClientMasterApi,
  addClientTradeMasterApi,
  bankAdditionApi,
  bankDetailsDataApi,
  beniFileDownloadApi,
  bulkClientMappingApi,
  bulkOdinClientApi,
  bulkUploadXTSClientAPi,
  clientCreationApi,
  ClientMasterBulkuploadApi,
  dealerMappingApi,
  dealerMasterApi,
  deleteClientMasterApi,
  downloadApi,
  getClientMasterApi,
  getClientMasterTradeCirecleApi,
  getDealerMasterDataApi,
  getFundeDetailsApi,
  getLivemonitorApi,
  getManualAllocationApi,
  odinOrderLoggerApi,
  odinTradeLoggerApi,
  onclickApi,
  rtrmsFilesApi,
  scriptSubmitApi,
  tradeCircleMasterBulkUploadApi,
  updateOrDeleteTradeMasterApi,
  uploadFilesApi,
  xtsClientCreationApi,
  xtsMobileLoggerApi,
  xtsOrderLoggerApi,
  xtsTradeLoggerApi,
} from "services/rms/RmsService";
import { Base64ToFileDownload, handleCatch } from "utils/CommonFunctions";

export const RmsContext = createContext();

const RmsApis = (props) => {
  const [downloadFiles, setDownloadFiles] = useState(null);
  const [fileStatus, setFileStatus] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [uploadDialog, setUploadDailog] = useState(false);
  const [clientMasterTradeCircleData, setClientMasterTradeCircleData] =
    useState([]);

  const handleDownload = async () => {
    try {
      setDownloading(true);
      const response = await downloadApi();
      if (response.status == 200) {
        toast.success(response.data.message);
        setDownloadFiles(response.data.data);
        setErrorShow(true);
        setDownloading(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleOnclick = async (values, { resetForm }) => {
    try {
      const response = await onclickApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      resetForm();
    }
  };

  const fetchUploadStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_RMS}/V1/getNotUploadedFile`
      );
      if (response.data.status === "success") {
        setFileStatus(response.data.data);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUploadFiles = async (values, { resetForm }) => {
    try {
      const response = await uploadFilesApi(values);

      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      resetForm();
    }
  };

  const handleRtrmsFiles = async (values) => {
    try {
      const response = await rtrmsFilesApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleDownloadSecurityfile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_RMS}/V1/downloadSecuirtyCheckedFile`,
        {
          headers: {
            accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const zipBlob = new Blob([response.data], { type: "application/zip" });
        const file = new File([zipBlob], `XTS.zip`, {
          type: "application/zip",
        });

        saveAs(file, `Security.zip`);
        URL.revokeObjectURL(zipBlob);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //################## Download XTS Files #######################################//
  const handleXtsFilesDownload = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_RMS}/V1/downloadXTSZipFile`,
        values,
        {
          responseType: "blob",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const zipBlob = new Blob([response.data], { type: "application/zip" });
        const file = new File([zipBlob], `XTS.zip`, {
          type: "application/zip",
        });

        saveAs(file, `XTS.zip`);
        URL.revokeObjectURL(zipBlob);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //################## Upload XTS File ##########################################//
  const handleXtsNetwiseFiles = async (values) => {
    try {
      const formData = new FormData();
      formData.append("NetWiseFile", values.NetWiseFile);
      const response = await axios.post(
        `${process.env.REACT_APP_URL_RMS}/V1/Upload_Netwise_File`,
        formData,
        {
          headers: {
            accept: "application/json",
            authToken: localStorage.authToken,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_URL_RMS}/V1/downloadXTSNetwiseFile`,
            {
              method: "GET",
              headers: {
                accept: "application/json",
              },
            }
          );

          if (response.ok) {
            toast.success("Successfully Download CSVFile", "success");
          } else {
            toast.error(response.message, "warning");
          }

          if (!response.ok) {
            toast.error("Failed to fetch CSV file ", "error");
            throw new Error("Failed to fetch CSV file");
          }

          const zipData = await response.arrayBuffer();

          const zipBlob = new Blob([zipData], { type: "application/csv" });
          const file = new File([zipBlob], `XTSNetwiseFIle.csv`, {
            type: "application/csv",
          });

          saveAs(file, `XTSNetwiseFIle.csv`);

          URL.revokeObjectURL(zipBlob);
        } catch (error) {
          toast.error("Error downloading ZIP file:", error);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  //################## Client Master Apis #######################################//
  const [clientMasterData, setClientMasterData] = useState(null);
  const [openDailog, setOpenDailog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [data, setData] = useState({});

  const handleClientMaster = async () => {
    setIsLoading(true);
    try {
      const response = await getClientMasterApi();
      if (response.data.status === "success") {
        setClientMasterData(response.data.data);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddClientMaster = async (values, setOpenDailog) => {
    try {
      const response = await addClientMasterApi(values, setOpenDailog);
      if (response.status === 201) {
        toast.success(response.data.message);
        setOpenDailog(false);
        handleClientMaster();
      } else if (response.status === 400) {
        toast.error("Client Already exists");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
      setOpenDailog(false);
    }
  };

  const handleApi = async (values, action, setOpenDailog) => {
    const rowdata = await {
      ...values,
      Action: action,
    };
    try {
      if (action === "DELETE") {
        const isConfirmed = window.confirm("Are you sure you want to delete?");
        if (!isConfirmed) {
          return;
        }
      }

      const response = await deleteClientMasterApi(rowdata);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleClientMaster();
        setOpenDailog(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleBulkFileUpload = async (selectedFile) => {
    try {
      const response = await ClientMasterBulkuploadApi(selectedFile);

      if (response.status === 200) {
        toast.success(response.data.message);
        setUploadDailog(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //######################### Live Monitor ######################################//
  const [liveMonitorData, setLiveMonitorData] = useState(null);
  const [liveTime, setLiveTime] = useState("");

  const handleLiveMonitor = async () => {
    try {
      const response = await getLivemonitorApi();
      if (response.status === 200) {
        setLiveMonitorData(response.data.data.client_data);
        setLiveTime(response.data.data.file_time);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //######################### Manual Allocation ###################################//
  const [manualAllocation, setManualAllocation] = useState([]);

  const handleManualAllocation = async () => {
    try {
      const response = await getManualAllocationApi();
      if (response.status === 200) {
        setManualAllocation(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //######################## Client Master Trade Circle ############################//
  const [tradeMasterDialog, setTradeMasterDialog] = useState(false);
  const [tradeCircleData, setTradeCircleData] = useState({});
  const [bulkUploadFileDialog, setBulkUploadDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClientMasterTradeCircle = async () => {
    setIsLoading(true);
    try {
      const response = await getClientMasterTradeCirecleApi();
      if (response.data.status === "success") {
        setClientMasterTradeCircleData(response.data.data);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddClientTradeMaster = async (values) => {
    try {
      const response = await addClientTradeMasterApi(values);
      if (response.status === 201) {
        toast.success(response.data.message);
        setTradeMasterDialog(false);
        handleClientMasterTradeCircle();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateOrDeleteTradeMaster = async (data, action) => {
    const rowdata = await {
      ...data,
      Action: action,
    };
    try {
      const response = await updateOrDeleteTradeMasterApi(rowdata);
      if (response.status === 200) {
        setTradeCircleData({});
        setTradeMasterDialog(false);
        handleClientMasterTradeCircle();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleTradeCircleMasterBulkUpload = async (selectedFile) => {
    try {
      const response = await tradeCircleMasterBulkUploadApi(selectedFile);
      if (response.status === 200) {
        toast.success(response.data.message);
        setBulkUploadDialog(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //################################### Logger Api #####################################//
  const handleXtsMobileLogger = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const response = await xtsMobileLoggerApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        resetForm();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };

  const handleXtsOrderLogger = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const response = await xtsOrderLoggerApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };

  const handleXtsTradeLogger = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const response = await xtsTradeLoggerApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };

  const handleOdinOrderLogger = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const response = await odinOrderLoggerApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };

  const handleOdinTradeLogger = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const response = await odinTradeLoggerApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };

  //########################## User MAping Apis #####################################//
  const [BankDetailsData, setBankDetailsData] = useState([]);
  const [clientCode, setClientCode] = useState("");
  const [xtsClientCode, setXtsClientCode] = useState("");
  const [file, setFile] = useState(null);
  const [isClientCodeEntered, setIsClientCodeEntered] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const fileInputRef = useRef(null);

  const handleBankAddition = async (values, resetForm) => {
    setIsLoading(true);
    try {
      const response = await bankAdditionApi(values);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleBankDetailsData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      resetForm();
    }
  };

  const handleBankDetailsData = async () => {
    try {
      const response = await bankDetailsDataApi();
      if (response.data.status === "success") {
        setBankDetailsData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleClientCreation = async () => {
    setIsLoading(true);
    try {
      const response = await clientCreationApi(clientCode);
      if (response.status === 201) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      setIsFileUploaded(false);
      setIsClientCodeEntered(false);
      setClientCode("");
    }
  };

  const handleXTSClientCreation = async () => {
    setIsLoading(true);
    try {
      const response = await xtsClientCreationApi(clientCode);
      if (response.status === 201) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      setIsFileUploaded(false);
      setIsClientCodeEntered(false);
      setClientCode("");
    }
  };

  const handleBulkUploadXTSClient = async () => {
    setIsLoading(true);
    try {
      const response = await bulkUploadXTSClientAPi(file);

      if (response.status === 200) {
        toast.success(response.data.message);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "XTS_Client_Data.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      setIsFileUploaded(false);
      setIsClientCodeEntered(false);
      setFile(null);
    }
  };

  const handleBulkOdinClient = async () => {
    setIsLoading(true);
    try {
      const response = await bulkOdinClientApi(file);

      if (response.status === 200) {
        toast.success(response.data.message);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "client_data.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      setIsFileUploaded(false);
      setIsClientCodeEntered(false);
      setFile(null);
    }
  };

  //################################### Script ADD #########################
  const handleScriptSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await scriptSubmitApi(values);
      if (response.status === 200 || response.status === 201) {
        toast.success(response.data.message);
        resetForm();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setSubmitting(false);
    }
  };

  //################################### Dealer Mapping APIS ################
  const [dealerMasterData, setDealerMasterData] = useState([]);
  const handleDealerMapping = async (values, { resetForm }) => {
    try {
      const response = await dealerMappingApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      resetForm();
    }
  };

  const hanldebulkClientMapping = async (values, { fileInputRef }) => {
    try {
      const response = await bulkClientMappingApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    }
  };

  const handleDealerMaster = async (values, { resetForm }) => {
    try {
      const response = await dealerMasterApi(values);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleGetDealerMasterData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      resetForm();
    }
  };

  const handleGetDealerMasterData = async () => {
    try {
      const response = await getDealerMasterDataApi();
      if (response.status === 200) {
        toast.success(response.data.message);
        setDealerMasterData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //###################################### Dashboard APis #####################
  const [fundData, setFundData] = useState([]);
  const handleFundeDetails = async (selectedSegment) => {
    try {
      const response = await getFundeDetailsApi(selectedSegment);
      if (response.status === 200) {
        toast.success(response.data.message);
        setFundData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //############################################################################
  const [beniFileDownloadLoader, setBeniFileDownloadLoader] = useState(false);
  const handleBeniFileDownload = async () => {
    setBeniFileDownloadLoader(true);
    try {
      const response = await beniFileDownloadApi();
      if (response.status === 200) {
  
        console.log("response", response);
        Base64ToFileDownload(response.data.data, "text/plain", "BeniFile.txt");
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setBeniFileDownloadLoader(false);
    }    
  };

  return (
    <RmsContext.Provider
      value={{
        handleOnclick,
        handleDownload,
        fetchUploadStatus,
        handleUploadFiles,
        handleRtrmsFiles,
        handleClientMaster,
        handleApi,
        handleAddClientMaster,
        clientMasterData,
        openEditDialog,
        setOpenEditDialog,
        handleManualAllocation,
        handleXtsFilesDownload,
        handleLiveMonitor,
        liveMonitorData,
        liveTime,
        manualAllocation,
        downloading,
        data,
        setData,
        openDailog,
        setOpenDailog,
        errorShow,
        fileStatus,
        downloadFiles,
        setUploadDailog,
        uploadDialog,
        handleBulkFileUpload,
        handleClientMasterTradeCircle,
        clientMasterTradeCircleData,
        handleAddClientTradeMaster,
        setTradeMasterDialog,
        tradeMasterDialog,
        setTradeCircleData,
        tradeCircleData,
        handleUpdateOrDeleteTradeMaster,
        bulkUploadFileDialog,
        setBulkUploadDialog,
        isLoading,
        handleTradeCircleMasterBulkUpload,
        handleXtsMobileLogger,
        handleXtsOrderLogger,
        handleXtsTradeLogger,
        handleOdinOrderLogger,
        handleOdinTradeLogger,
        setIsLoading,
        BankDetailsData,
        handleBankDetailsData,
        handleBankAddition,
        handleClientCreation,
        setClientCode,
        clientCode,
        handleXTSClientCreation,
        handleBulkUploadXTSClient,
        xtsClientCode,
        setXtsClientCode,
        handleBulkOdinClient,
        setFile,
        file,
        setIsFileUploaded,
        isFileUploaded,
        setIsClientCodeEntered,
        isClientCodeEntered,
        fileInputRef,
        handleScriptSubmit,

        handleXtsNetwiseFiles,
        handleDownloadSecurityfile,

        handleDealerMapping,
        handleDealerMaster,
        handleGetDealerMasterData,
        dealerMasterData,
        hanldebulkClientMapping,

        handleFundeDetails,
        fundData,
        handleBeniFileDownload,
        beniFileDownloadLoader,
      }}
    >
      {props.children}
    </RmsContext.Provider>
  );
};

export default RmsApis;
