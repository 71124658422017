// assets
import { IconAccessible } from '@tabler/icons';

// constant
const icons = { IconAccessible };

// ==============================|| Report PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const roleManagement = {
    id: 'role-manage',
    // title: 'Manage',
    type: 'group',
    children: [
        {
            id: 'roleManagement',
            title: 'Role Management',
            type: 'item',
            url: '/role-management',
            icon: icons.IconAccessible,
            breadcrumbs: true,
            // type: 'collapse',
        }
    ]
};

export default roleManagement;