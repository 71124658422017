import { commonApiRequest, commonApiRequestNew } from "utils/CommonFunctions";

const COMMON_URL = process.env.REACT_APP_URL;

export const fetchErpLoginLogsApi = (params) => {
    console.log('params', params)
    const url = `${COMMON_URL}/v1/user/login-report`;
    return commonApiRequest('GET', url, null, params);
};

export const connectActiveLoginApi = () => {
    const url = `${process.env.REACT_APP_URL_CONNECTS_2}/v2/user/get-active-logins?source=erp`;
    return commonApiRequestNew("GET", url)
}

export const connectAllLoginApi = (params) => {
    const url = `${process.env.REACT_APP_URL_CONNECTS_2}/v2/user/logins-logs?source=erp`;
    return commonApiRequestNew("GET", url, null, params)
}
