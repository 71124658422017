import { lazy } from "react";
import Loadable from "ui-component/Loadable";

// const LoginLogs = Loadable(lazy(() => import("views/reports")));
const ErpLoginLogs = Loadable(lazy(() => import("views/reports/erp-login")));
const ConnectLoginLogs = Loadable(
  lazy(() => import("views/reports/connect-login"))
);

export const MainReportsModule = [
    {
      path: "erp-login",
      element: <ErpLoginLogs />,
    },
    {
      path: "connect-login",
      element: <ConnectLoginLogs />,
    },
    // {
    //   path: "ap-logins",
    //   element: <LoginLogs />,
    // },
  ]
