// assets
import { IconUpload } from '@tabler/icons';

// constant
const icons = { IconUpload };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const uploads = {
  id: 'uploads',
  type: 'group',
  children: [
    {
      id: 'uploads',
      title: 'Uploads',
      type: 'item',
      url: '/uploads',
      icon: icons.IconUpload,
      breadcrumbs: false
    }
  ]
};

export default uploads;
