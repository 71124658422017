/*eslint-disable*/
import { commonApiRequest } from "utils/CommonFunctions";

const baseURL = process.env.REACT_APP_URL_BLOG;

export const fetchEventGallery = () => {
  const url = `${baseURL}/arham_web/v1/fetch-events`;
  return commonApiRequest("GET", url);
};

export const handleEventGalleryAdd = (formData) => {
  const url = `${baseURL}/arham_web/v1/upload-events`;
  return commonApiRequest("POST", url, formData);
};

export const handleEventGalleryUpdate = (formData) => {
  const url = `${baseURL}/arham_web/v1/update-events`;
  return commonApiRequest("PUT", url, formData);
};

export const handleEventGalleryDelete = (params) => {
  const url = `${baseURL}/arham_web/v1/delete-events`;
  return commonApiRequest("DELETE", url,null, params);
};


