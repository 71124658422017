/*eslint-disable*/
import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import FeaturesRequired from "utils/FeaturesRequired";

const RmsIndex = Loadable(lazy(() => import("views/utilities/rms")));

const RmsHome = Loadable(lazy(() => import("views/utilities/rms/dashboard")));
const RmsUtils = Loadable(lazy(() => import("views/utilities/rms/utils")));
const RmsReports = Loadable(lazy(() => import("views/utilities/rms/reports")));

const RmsFileUploadBod = Loadable(
  lazy(() => import("views/utilities/rms/utils/bod/index"))
);

const RmsLiveMonitor = Loadable(
  lazy(() => import("views/utilities/rms/utils/live-monitor"))
);

const RmsClientMaster = Loadable(
  lazy(() => import("views/utilities/rms/utils/client-master"))
);
const RmsClientMasterTradeCircle = Loadable(
  lazy(() => import("views/utilities/rms/utils/client-master-trade-circle"))
);

const RmsLogger = Loadable(
  lazy(() => import("views/utilities/rms/utils/logger/index"))
);

const DealerMapping = Loadable(
  lazy(() => import("views/utilities/rms/utils/dealer-mapping/index"))
);

const RmsLoggerXts = Loadable(
  lazy(() => import("views/utilities/rms/utils/logger/xts/index"))
);

const RmsLoggerOdin = Loadable(
  lazy(() => import("views/utilities/rms/utils/logger/odin/index"))
);

const RmsManualAllocation = Loadable(
  lazy(() => import("views/utilities/rms/utils/manual-allocation"))
);
const RmsScriptAdd = Loadable(
  lazy(() => import("views/utilities/rms/utils/script-add"))
);
const RmsUserMaping = Loadable(
  lazy(() => import("views/utilities/rms/utils/user-maping"))
);

//############################## feature auth Check ##############################//

const featureCheck = (component, feature) => (
  <FeaturesRequired feature={feature}>{component}</FeaturesRequired>
);

export const rmsModule = {
  path: "rms",
  element: <RmsIndex />,
  children: [
    {
      path: "home",
      element: <RmsHome />,
    },
    {
      path: "utils",
      element: <RmsUtils />,
    },
    {
      path: "reports",
      element: <RmsReports />,
    },
  ],
};

export const rmsUtilsRoutes = {
  path: "rms",
  children: [
    {
      path: "file-upload-bod",
      element: featureCheck(<RmsFileUploadBod />, "bod"),
    },
    {
      path: "live-monitor",
      element: featureCheck(<RmsLiveMonitor />, "live_monitor"),
    },
    {
      path: "client-master",
      element: featureCheck(<RmsClientMaster />, "client_master"),
    },
    {
      path: "manual-allocation",
      element: featureCheck(<RmsManualAllocation />, "manual_allocation"),
    },
    {
      path: "script-add",
      element: featureCheck(<RmsScriptAdd />, "script_add"),
    },
    {
      path: "user-maping",
      element: featureCheck(<RmsUserMaping />, "user_maping"),
    },
    {
      path: "client-master-trade-circle",
      element: featureCheck(
        <RmsClientMasterTradeCircle />,
        "client_master_trade_circle"
      ),
    },
    {
      path: "logger",
      element: featureCheck(<RmsLogger />, "logger"),
    },
    {
      path: "dealer-mapping",
      element: featureCheck(<DealerMapping />, "dealer_mapping"),
    },
    {
      path: "logger/xts",
      element: <RmsLoggerXts />,
    },
    {
      path: "logger/odin",
      element: <RmsLoggerOdin />,
    },
  ],
};
