/*eslint-disable*/
import React from "react";
import { UtilitesCheck } from "utils/UtilitesCheck";
import ItInfraFeatures from "config-details/features/ItInfraFeatures";
import CommonUtilsIndex from "views/utilities/common-components/CommonUtilsIndex";

const ItInfraUtilities = () => {
  const accessibleFeatures = UtilitesCheck(ItInfraFeatures, "features");
  return (
    <>
      <CommonUtilsIndex allFeatures={ItInfraFeatures} />
    </>
  );
};

export default ItInfraUtilities;
