import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import LeaseDash from "views/utilities/lease-agreement/dashboard";
import LeaseUtils from "views/utilities/lease-agreement/utils";
// import LeaseReports from "views/utilities/lease-agreement/reports";

const LeaseHome = Loadable(
  lazy(() => import("views/utilities/lease-agreement"))
);

const LeaseAdd = Loadable(
  lazy(() => import("views/utilities/lease-agreement/utils/leaseAdd"))
);

export const LeaseModule = {
  path: "lease-agreement",
  element: <LeaseHome />,
  children: [
    {
      path: "home",
      element: <LeaseDash />,
    },
    {
      path: "utils",
      element: <LeaseUtils />,
    },
    {
      path: "reports",
      // element: <LeaseReports />,
    },
  ],
};

export const LeaseRoutes = {
  path: "lease-agreement",
  children: [
    {
      path: "add-lease",
      element: <LeaseAdd />,
    },
  ],
};
