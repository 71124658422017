import { lazy } from "react";
import Loadable from "ui-component/Loadable";
// import AlgoSummaryDash from "views/utilities/algo-summary/dashboard";
// import FeaturesRequired from "utils/FeaturesRequired";

const BulkMail = Loadable(lazy(() => import("views/utilities/bulk-mail")));
const BulkMailHome = Loadable(lazy(() => import("views/utilities/bulk-mail/dashboard")));
const SendMail = Loadable(
  lazy(() => import("views/utilities/bulk-mail/send-mail"))
);
const MailTemplate = Loadable(
  lazy(() => import("views/utilities/bulk-mail/templates"))
);
const MailReports = Loadable(
  lazy(() => import("views/utilities/bulk-mail/reports"))
);
const MailTemplatePreview = Loadable(
  lazy(() => import("views/utilities/bulk-mail/templates/preview/TemplatePage"))
);
const MailGroup = Loadable(
  lazy(() => import("views/utilities/bulk-mail/manage-group"))
);

//############################## feature auth Check ##############################//

// const featureCheck = (component, feature) => (
//   <FeaturesRequired feature={feature}>{component}</FeaturesRequired>
// );

export const bulkMailRoutes = {
  path: "bulk-mail",
  element: <BulkMail />,
  children: [
    {
      path: "",
      element: <BulkMailHome />,
    },
    {
      path: "send-mails",
      element: <SendMail />,
    },
    {
      path: "mail-templates",
      element: <MailTemplate />,
    },
    {
      path: "mail-templates/template/:templateId",
      element: <MailTemplatePreview />,
    },
    {
      path: "address-book",
      element: <MailGroup />,
    },
    {
      path: "mail-reports",
      element: <MailReports />,
    },
  ]
};
