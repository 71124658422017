/*eslint-disable*/
import axios from "axios";
import { commonApiRequestNew } from "utils/CommonFunctions";

// export const folderUploadAttachments = async (selectedFiles, folderId) => {
//   var formData = new FormData();
//   formData.append("folder_id", folderId ? folderId : "");
//   for (var i = 0; i < selectedFiles.length; i++) {
//     formData.append("files", selectedFiles[i]);
//   }
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/upload-item`;
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const config = {
//     headers: headers,
//   };
//   const response = await axios.post(url, formData, config);
//   return response;
// };

// export const fetchMydrive = async (folder_id, folder_type, owner_id = "") => {
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/my_drive`;
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const paramsData = {
//     folder_type: folder_type,
//   };
//   const body = {
//     folder_id: folder_id,
//     owner_id: owner_id,
//   };
//   const config = {
//     headers: headers,
//     params: paramsData,
//   };
//   const response = await axios.post(url, body, config);
//   return response;
// };

// export const createFolder = async (folderName, parentFolder) => {
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/folder`;
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const config = {
//     headers: headers,
//   };
//   const body = {
//     folder_name: folderName,
//     parent_folder_id: parentFolder ? parentFolder : "",
//   };
//   const response = await axios.post(url, body, config);
//   return response;
// };

// export const sharingFileFolder = async (
//   values,
//   participantListArr,
//   groupListArr,
//   handleDialogClose,
//   sharedFolderData
// ) => {
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/sharing`;
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const config = {
//     headers: headers,
//   };
//   const body = {
//     shared_user: participantListArr,
//     shared_item_id: sharedFolderData.item._id,
//     access_type: values.access_type,
//     group_list: groupListArr,
//   };
//   const response = await axios.post(url, body, config);
//   return response;
// };

// export const deleteItem = async (item_ids) => {
//   console.log("item_ids", item_ids);
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/delete-item`;
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const config = {
//     method: "delete",
//     url: url,
//     headers: headers,
//     data: {
//       item_id_list: item_ids,
//     },
//   };

//   const response = await axios.request(config);
//   return response;
// };

// export const copyItem = async (source_ids, dest_id) => {
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/copy-item`;
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const config = {
//     headers: headers,
//   };
//   const body = {
//     item_id: source_ids,
//     destination_folder_id: dest_id,
//   };
//   const response = await axios.post(url, body, config);
//   return response;
// };

// export const moveItem = async (source_id, dest_id) => {
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/move-item`;
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const config = {
//     headers: headers,
//   };
//   const body = {
//     item_id: source_id,
//     destination_folder_id: dest_id,
//   };
//   const response = await axios.post(url, body, config);
//   return response;
// };

// export const renameItem = async (item_id, new_name) => {
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/rename-item`;
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const config = {
//     headers: headers,
//   };
//   const body = {
//     item_id: item_id,
//     new_name: new_name,
//   };
//   const response = await axios.put(url, body, config);
//   return response;
// };

// export const crudGroupp = async (values, participantListArr = [], action) => {
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/group`;
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const paramsData = {
//     action: action,
//   };
//   const config = {
//     headers: headers,
//     params: paramsData,
//   };
//   const body = {
//     group_id: values._id || "",
//     group_name: values.groupName || "",
//     members: participantListArr,
//   };
//   const response = await axios.post(url, body, config);
//   return response;
// };

// export const fetchGroupList = async () => {
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/group_detail`;
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const config = {
//     headers: headers,
//   };
//   const response = await axios.get(url, config);
//   return response;
// };

// export const fetchBase64DataDrive = async (item) => {
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/file-content`;
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const body = {
//     item_id: item._id || "",
//     owner_id: item.owner_id || "",
//   };
//   const config = {
//     headers: headers,
//   };
//   const response = await axios.post(url, body, config);
//   console.log("response", response);
//   return response;
// };

// export const modifyShareAccess = async (action ,shared_user,shared_item_id,new_access) => {
//   const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/modify-share`;
//   const paramsData = {
//     action: action
//   }
//   const headers = {
//     "auth-token": localStorage.authToken,
//   };
//   const body = {
//     shared_user: shared_user || "",
//     shared_item_id: shared_item_id || "",
//     new_access: new_access || "",
//   };
//   const config = {
//     headers: headers,
//     params: paramsData
//   };
//   const response = await axios.post(url, body, config);
//   return response;
// };

export const folderUploadAttachments = async (formData) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/upload-item`;
  return commonApiRequestNew('POST', url, formData);
};

export const fetchMydrive = async (paramsData, body) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/my_drive`;
  return commonApiRequestNew('POST', url, body, paramsData);
};

export const createFolder = async (body) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/folder`;
  return commonApiRequestNew('POST', url, body);
};

export const sharingFileFolder = async (
  body
) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/sharing`;
  return commonApiRequestNew('POST', url, body);
};

export const deleteItem = async (paramsData) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/delete-item`;
  return commonApiRequestNew('DELETE', url, null, paramsData);
};

export const copyItem = async (source_ids, dest_id) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/copy-item`;
  const body = {
    item_id: source_ids,
    destination_folder_id: dest_id,
  };
  return commonApiRequestNew('POST', url, body);
};

export const moveItem = async (source_id, dest_id) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/move-item`;
  const body = {
    item_id: source_id,
    destination_folder_id: dest_id,
  };
  return commonApiRequestNew('POST', url, body);
};

export const renameItem = async (item_id, new_name) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/rename-item`;
  const body = {
    item_id: item_id,
    new_name: new_name,
  };
  return commonApiRequestNew('PUT', url, body);
};

export const crudGroupp = async (values, participantListArr = [], action) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/group`;
  const paramsData = { action: action };
  const body = {
    group_id: values._id || "",
    group_name: values.groupName || "",
    members: participantListArr,
  };
  return commonApiRequestNew('POST', url, body, paramsData);
};

export const fetchGroupList = async () => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/group_detail`;
  return commonApiRequestNew('GET', url);
};

export const fetchBase64DataDrive = async (item) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/file-content`;
  const body = {
    item_id: item._id || "",
    owner_id: item.owner_id || "",
  };
  return commonApiRequestNew('POST', url, body);
};

export const modifyShareAccess = async (action, shared_user, shared_item_id, new_access) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/modify-share`;
  const paramsData = { action: action };
  const body = {
    shared_user: shared_user || "",
    shared_item_id: shared_item_id || "",
    new_access: new_access || "",
  };
  return commonApiRequestNew('POST', url, body, paramsData);
};

export const favoutieDriveData = async (body, paramsData) => {
  const url = `${process.env.REACT_APP_URL_SHARED_DRIVE}/v1/favourite`;
  return commonApiRequestNew('POST', url, body, paramsData);
};
