/*eslint-disable*/
import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { fetchBranchApi, fetchBranchLocationApi, fetchBranchTotalExpenseApi, fetchProfitLossApi, fetchReportProfitLossApi, handleBranchAddApi, handleBranchUpdateApi, handleDeleteApi } from "services/branch-cost/branchCost";
import { handleCatch } from "utils/CommonFunctions";

export const BranchCostContext = createContext();

export default function BranchCostApis(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [branchData, setBranchdata] = useState([]);
  const [branchTypes, setBranchTypes] = useState([]);
  const [branchTotal, setBranchTotal] = useState([]);

  //GET - app list

  const fetchBranch = async () => {
    setIsLoading(true)
    try {
      const response = await fetchBranchApi();

      setBranchdata(response.data.data);
    } catch (err) {
      handleCatch(err)
    } finally {
      setIsLoading(false)
    }
  };

  const bulkEmployeeListUpload = async (values) => {
    try {
      const formData = new FormData();
      if (values.lease_data_file) {
        formData.append("emp_list_file", values.emp_list_file);
        // const resJson = await handleBulkUpload(formData);

        toast.success(resJson.message);
        // fetchAllLease();
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const fetchBranchTotalExpense = async () => {
    setIsLoading(true)
    try {
      const response = await fetchBranchTotalExpenseApi();

      console.log("Response:", response.data);
      const { branch_totals, grand_total } = response.data.data;

      const branchTotalsString = Object.entries(branch_totals)
        .map(([branch, amount]) => `${branch}: ${amount}`)
        .join(", ");

      setBranchTotal({ branch_totals: branchTotalsString, grand_total });
    } catch (err) {
      handleCatch(err)
    } finally {
      setIsLoading(false)
    }
  };

  const formObjectBranch = (values, id) => {
    var formData = new FormData();

    formData.append("req_ID", id);
    formData.append("expense_type", values.expense_type);
    formData.append("amount", values.amount);
    formData.append("otherExpenseType", values.otherExpenseType);
    formData.append("branch", values.branch);
    formData.append("approval_from", values.approval_from);
    formData.append("payment_date", values.payment_date);

    values.attachment != null &&
      formData.append("attachment", values.attachment);

    return formData;
  };

  const handleBranchAdd = async (
    values,
    others,
    { setErrors, setSubmitting, resetForm }
  ) => {
    setIsLoading(true)
    console.log("values,", values);
    try {

      const body = formObjectBranch(values)
      const response = await handleBranchAddApi(body);
      const resJson = response.data;
      console.log("resJson", resJson.status);
      if (resJson.status === "success") {
        setSubmitting(true);
        resetForm({ values: "" });
        others.setOpen(false);
        toast.success(resJson.message);
        fetchBranch();
      } else {
        setErrors({ submit: resJson.message });
        setSubmitting(false);
        toast.error(resJson.message);
      }
    } catch (err) {
      handleCatch(err);
      setErrors({ submit: "An error occurred while Adding Branch" });
      setSubmitting(false);
    } finally {
      setIsLoading(false)
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true)
    try {
      const params = {
        req_ID: id
      }
      const response = await handleDeleteApi(params)
      const resJson = response.data;
      setBranchdata(branchData.filter((record) => record.ID !== id));
      toast.success(resJson.message);
    } catch (err) {
      handleCatch(err)
    } finally {
      setIsLoading(false)
    }
  };

  const fetchBranchLocation = async () => {
    setIsLoading(true)
    try {
      const response = await fetchBranchLocationApi()

      console.log("resJson", response);
      setBranchTypes(response.data.data);
    } catch (err) {
      handleCatch(err)
    } finally {
      setIsLoading(false)
    }
  };

  const handleBranchUpdate = async (
    values,
    others,
    id,
    { setErrors, setSubmitting, resetForm }
  ) => {
    setIsLoading(true)
    try {
      const body = formObjectBranch(values, id);
      const params = {
        req_ID: id
      }
      const response = await handleBranchUpdateApi(body, params)

      const resJson = response.data;
      console.log("resJson", resJson);

      if (resJson.status === "success") {
        setSubmitting(true);
        resetForm({ values: "" });
        fetchBranch();

        others.setOpen(false);
        toast.success(resJson.message);
      } else {
        setErrors({ submit: resJson.message });
        setSubmitting(false);
        toast.error(resJson.message);
      }
    } catch (err) {
      handleCatch(err);
      setErrors({ submit: "An error occurred while updating the branch" });
      setSubmitting(false);
    } finally {
      setIsLoading(false)
    }
  };

  const fetchProfitLoss = async () => {
    setIsLoading(true)
    try {
      const response = await fetchProfitLossApi()

      const responseData = response.data;
      setBranchTotal(responseData.data.summary);

    } catch (err) {
      handleCatch(err)
    } finally {
      setIsLoading(false)
    }
  };
  const fetchReportProfitLoss = async () => {
    setIsLoading(true)
    try {
      const response = await fetchReportProfitLossApi()

      const responseData = response.data;
      setBranchdata(responseData.data.data);
      setBranchTotal(responseData.data.summary);

    } catch (err) {
      handleCatch(err)
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <BranchCostContext.Provider
      value={{
        isLoading, setIsLoading,
        handleBranchAdd,
        fetchBranch,
        bulkEmployeeListUpload,
        handleDelete,
        branchData,
        branchTypes,
        fetchBranchLocation,
        fetchBranchTotalExpense,
        branchTotal,
        fetchReportProfitLoss,
        handleBranchUpdate,
        fetchProfitLoss,
      }}
    >
      {props.children}
    </BranchCostContext.Provider>
  );
}
