/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";

import { Avatar, DialogContent, IconButton, Grid, TextField, DialogTitle, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ChatContext } from "context/chat-app/ChatApis";
import { getSessionStorage } from "utils/encrpytion";
import { stringAvatarChat } from "utils/CommonFunctions";
import GroupDialogForm from "./GroupDialogForm";

export default function GroupDialogComp({ openGroupDialog, setOpenGroupDialog, contactList }) {
    
    const { editDialog, setEditDialog } = useContext(ChatContext);
    
    const [allEmployees, setAllEmployees] = useState([]);
    
    const user_id = getSessionStorage("UID");
    const chatData = getSessionStorage("chatData")
    
    // converted emp ids to names
    const parti = chatData?.participants;
    const uidList = allEmployees?.filter((item) =>
        parti?.includes(item?.UID.toUpperCase())
    );
    const partiListArr = uidList.map((item) => {
        return item.full_name?.toUpperCase();
    });

    const handleClose = () => {
        setEditDialog(false);
        setOpenGroupDialog(false);
    };

    useEffect(() => {
        setAllEmployees(contactList)
    }, [contactList])

    return (
        <Dialog open={openGroupDialog} maxWidth="xs" fullWidth onClose={handleClose}>
            <DialogTitle
                sx={{ m: 0, p: 2, fontSize: "16px" }}
                id="customized-dialog-title"
            >
                {chatData?.admins?.includes(user_id) ?
                    "Group Info" :
                    (editDialog === true ? "Modify Group Info" : "Create New Group")}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {chatData?.admins?.includes(user_id) || editDialog === false ?
                    <GroupDialogForm handleClose={handleClose} allEmployees={allEmployees} setAllEmployees={setAllEmployees} />
                    :
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        padding={1}
                        spacing={2}
                    >
                        <Grid item xs={12} md={12}>
                            <TextField
                                size="small"
                                label="Group Name"
                                name="groupName"
                                fullWidth
                                value={chatData?.name}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                            {partiListArr?.map((data, index) => (
                                <div style={{ display: "block" }}>
                                    <IconButton
                                        sx={{ p: 0, mr: 0.8, my: 0.5 }}
                                    >
                                        <Avatar {...stringAvatarChat(data?.toUpperCase())} />
                                    </IconButton>
                                    <span>{data}</span>
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                }
            </DialogContent>
        </Dialog >
    );
}


