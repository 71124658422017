/*eslint-disable*/

import React from "react";

import ApOnBoardingFeatures from "config-details/features/ApOnBoardingFeatures";
import CommonUtilsIndex from "views/utilities/common-components/CommonUtilsIndex";

const ApOnBoardingUtilities = () => {
  return (
    <>
      <CommonUtilsIndex allFeatures={ApOnBoardingFeatures} />
    </>
  );
};

export default ApOnBoardingUtilities;


