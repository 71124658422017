/*eslint-disable*/
import React from "react";
import { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import {
  Base64ToFileDownload,
  formatDateDMY,
  handleCatch,
} from "utils/CommonFunctions";
import {
  addFixedAllocation,
  addLiquidBees,
  addPtb,
  bodAllocationFileDifferenceDownload,
  bodAllocationFileDownload,
  deleteFixedAllocation,
  deleteLiquidBees,
  deleteTrailBalance,
  eodAllocationFileDifferenceDownload,
  eodAllocationFileDownload,
  eodReportView,
  fetchBodAllocationData,
  fetchCashPositionDataa,
  fetchDataFavUtilisation,
  fetchDataOverallUtilisation,
  fetchDeleteFavClient,
  fetchEodAllocationData,
  fetchEodReportData,
  fetchFavClientData,
  fetchFixedAllocation,
  fetchFundDataa,
  fetchLiquidBeesData,
  fetchLiveMonitorReport,
  fetchMCXAllocationData,
  fetchReportsEODDataa,
  fetchReportsSSDataa,
  fetchSelectedClientLiveMoniterData,
  fetchSelectedClientShortAllocationData,
  fetchShortAllocationExchangeData,
  fetchShortAllocationReportsSSDataa,
  fetchShortReport,
  fetchSnapShortReportsEODDataa,
  fetchTrailBalanceData,
  fetchUpdateFunds,
  fileUploadBod,
  fileUploadEod,
  fileUploadFixedAllocation,
  fileUploadInMarket,
  fileUploadLiquidBees,
  fileUploadMCX,
  segregationMismatchApi,
  updateFixedAllocation,
  updateLiquidBees,
  updatePtb,
  uploadSegregationFiles,
  uploadShortAllocationSnapShot,
  uploadShortAllocationTemp,
  uploadSnapShot,
} from "services/rtrms/RtrmsService";

export const RtrmsContext = createContext();

const RtrmsApis = (props) => {
  const [productData, setproductData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [shortReportData, setShortReportData] = useState([]);
  const [favProductData, setFavProductData] = useState([]);
  const [fixedAllocationData, getFixedAllocationData] = useState([]);
  const [liquidBeesData, setLiquidBeesData] = useState([]);
  const [ptbData, setPtbData] = useState([]);
  const [bodAllocationData, setBodAllocationData] = useState([]);
  const [eodAllocationData, setEodAllocationData] = useState([]);
  const [mcxAllocationData, setMCXAllocationData] = useState([]);
  const [cashPositionData, setCashPositionData] = useState([]);
  const [fetchFundData, setFetchFundData] = useState([]);
  const [inMarketLoading, setInMarketLoading] = useState(false);
  const [bodLoading, setBodLoading] = useState(false);
  const [eodLoading, setEodLoading] = useState(false);
  const [snapShotLoading, setSnapShotLoading] = useState(false);
  const [favClientData, setFavClientData] = useState([]);
  const [
    uploadFileDialogVisibleFixedAllocation,
    setUploadFileDialogVisibleFixedAllocation,
  ] = useState(false);
  const [
    uploadFileDialogVisibleLiquidBees,
    setUploadFileDialogVisibleLiquidBees,
  ] = useState(false);
  const [eodData, setEodData] = useState([]);
  const [ssData, setSsData] = useState([]);
  const [snapShotSsData, setSnapShotSsData] = useState([]);
  const [eodDataSegment, setEodDataSegment] = useState([]);
  const [snapShotEodData, setSnapShotEodData] = useState([]);
  const [liveTime, setLiveTime] = useState("");
  const [selectedClientData, setSelectedClientData] = useState([]);
  const [
    selectedShortAllocationClientData,
    setSelectedShortAllocationClientData,
  ] = useState([]);

  const [loadingSnap, setLoadingSnap] = useState(false);
  const [loadingSSdata, setLoadingSSData] = useState(false);
  const [dialogRowData, setDialogRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // ################################ fixed Allocation  ############################## //

  const handleFetchFixedAllocation = async () => {
    try {
      const response = await fetchFixedAllocation();
      const resJson = response.data;
      if (resJson.status === "success") {
        const Data = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        getFixedAllocationData(Data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleAddFixedAllocation = async (values, handleClose) => {
    try {
      const body = {
        Client_id: values.clientId,
        Segment: values.Segment,
        amount: values.amount,
      };
      const response = await addFixedAllocation(body);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleClose();
        handleFetchFixedAllocation();
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateFixedAllocation = async (values, handleClose) => {
    try {
      const body = {
        Client_id: values.clientId,
        Segment: values.Segment,
        amount: values.amount,
      };
      const response = await updateFixedAllocation(body);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleClose();
        handleFetchFixedAllocation();
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleDeleteFixedAllocation = async (deleteId) => {
    try {
      const paramsData = {
        id: deleteId,
      };
      const response = await deleteFixedAllocation(paramsData);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFetchFixedAllocation();
        toast.success("Deleted Successfully");
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFileUploadFixedAllocation = async (event) => {
    const formData = new FormData();
    formData.append("files", event.files[0]);
    try {
      const response = await fileUploadFixedAllocation(formData);
      if (response.status === 200) {
        setUploadFileDialogVisibleFixedAllocation(false);
        handleFetchFixedAllocation();
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  // ################################ LiquidBeesData  ############################## //

  const handleFetchLiquidBees = async () => {
    try {
      const response = await fetchLiquidBeesData();
      const resJson = response.data;
      if (resJson.status === "success") {
        const Data = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setLiquidBeesData(Data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleAddLiquidBees = async (values, handleClose) => {
    try {
      const body = {
        Client_id: values.clientId,
        Liquid_Bees: values.Liquid_Bees,
      };

      const response = await addLiquidBees(body);
      const resJson = response.data;

      if (resJson.status === "success") {
        handleClose();
        handleFetchLiquidBees();
        toast.success("Added Successfully");
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateLiquidBees = async (values, handleClose) => {
    try {
      const body = {
        Client_id: values.clientId,
        Liquid_Bees: values.Liquid_Bees,
      };
      const response = await updateLiquidBees(body);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleClose();
        handleFetchLiquidBees();
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleDeleteLiquidBees = async (deleteId) => {
    try {
      const paramsData = {
        id: deleteId,
      };
      const response = await deleteLiquidBees(paramsData);
      const resJson = response.data;

      if (resJson.status === "success") {
        handleFetchLiquidBees();
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFileUploadLiquidBees = async (event) => {
    const formData = new FormData();
    formData.append("file", event.files[0]);

    try {
      const response = await fileUploadLiquidBees(formData);
      const json = response.data;
      if (response.status === 200) {
        setUploadFileDialogVisibleLiquidBees(false);
        handleFetchLiquidBees();
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  // ################################ PTB  ############################## //
  const handleFetchPTB = async (selectIdentity) => {
    try {
      const params = { Identity: selectIdentity };
      const response = await fetchTrailBalanceData(params);
      const resJson = response.data;

      if (resJson.status === "success") {
        const Data = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setPtbData(Data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleAddPtb = async (values, handleClose, identity) => {
    try {
      const body = {
        AccountCode: values.AccountCode,
        Branch_Code: values.Branch_Code,
        AccountName: values.AccountName,
        CreditBalance: values.CreditBalance,
        DebitBalance: values.DebitBalance,
        Identity: values.identity,
      };

      const response = await addPtb(body);
      const resJson = response.data;

      if (resJson.status === "success") {
        handleClose();
        handleFetchPTB(identity);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdatePtb = async (values, handleClose, identity) => {
    try {
      const body = {
        AccountCode: values.AccountCode,
        Branch_Code: values.Branch_Code,
        AccountName: values.AccountName,
        CreditBalance: values.CreditBalance,
        DebitBalance: values.DebitBalance,
        Identity: values.identity,
      };

      const response = await updatePtb(body);
      const resJson = response.data;

      if (resJson.status === "success") {
        handleFetchPTB(identity);
        handleClose();
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleDeleteTrailBalance = async (deleteId, identity) => {
    try {
      const response = await deleteTrailBalance(deleteId, identity);
      const resJson = response.data;

      if (resJson.status === "success") {
        handleFetchPTB(identity);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  // ################################ BOD-Allocation ############################## //
  const handleBodAllocation = async () => {
    try {
      const response = await fetchBodAllocationData();
      const resJson = response.data;

      if (resJson.status === "success") {
        setBodAllocationData(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleEodAllocation = async () => {
    try {
      const response = await fetchEodAllocationData();
      const resJson = response.data;
      if (resJson.status === "success") {
        setEodAllocationData(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleMCXAllocation = async () => {
    try {
      const response = await fetchMCXAllocationData();
      const resJson = response.data;
      if (resJson.status === "success") {
        setMCXAllocationData(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleMCXAllocationFileDownload = async () => {
    try {
      const response = await fetchMCXAllocationData();
      const resJson = response.data;

      if (resJson.status === "success") {
        const jsonData = resJson?.data;
        const array =
          typeof jsonData !== "object" ? JSON.parse(jsonData) : jsonData;
        const header =
          array.length > 0 && Object?.keys(array[0])?.join(",") + "\n";
        const csv = array
          ?.map((row) => Object.values(row).join(","))
          .join("\n");
        const csvData = header + csv;
        const blob = new Blob([csvData], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);

        const currentDate = new Date()
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "");

        link.download = `MCCLCOLL_55480_${currentDate}.001.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error(resJson.message || "Failed to download data");
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const fetchCashPositionData = async (client_id = "") => {
    setCashPositionData([]);
    try {
      const response = await fetchCashPositionDataa(client_id);
      const resJson = response.data;

      if (resJson.status === "success") {
        const data = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setCashPositionData(data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFileDownload = async (
    downloadFunction,
    fileNamePrefix,
    fileExtension
  ) => {
    try {
      const response = await downloadFunction();
      const blob = await response.data;
      if (blob) {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);

        const currentDate = new Date()
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "");

        link.download = `${fileNamePrefix}_${currentDate}${fileExtension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleBodAllocationCallFile = () =>
    handleFileDownload(bodAllocationFileDownload, "BodCollateral", ".zip");

  const handleEodAllocationCallFile = () =>
    handleFileDownload(eodAllocationFileDownload, "EodCollateral", ".zip");

  const handleBodAllocationDifference = () =>
    handleFileDownload(
      bodAllocationFileDifferenceDownload,
      "BODAllocation",
      ".csv"
    );
  const handleEodAllocationDifference = () =>
    handleFileDownload(
      eodAllocationFileDifferenceDownload,
      "EodAllocation",
      ".csv"
    );

  const handleBodAllocationRefresh = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_RTRMS}/v1/process`,
        {
          method: "POST",
          headers: {
            authToken: localStorage.authToken,
          },
        }
      );

      console.log("handleBodAllocationRefresh", response);
    } catch (error) {
      console.log(error);
      toast.error("Network Error");
    }
  };

  const handleUploadSnapShot = async (
    snap,
    selectedDate,
    snapShotSelectedFiles
  ) => {
    setSnapShotLoading(true);
    const formData = new FormData();
    formData.append("snapshot", snap);
    formData.append("date", selectedDate);
    for (let i = 0; i < snapShotSelectedFiles.length; i++) {
      formData.append("files", snapShotSelectedFiles[i]);
    }

    try {
      const response = await uploadSnapShot(formData);
      const reJson = await response.data;
      if (reJson.status === "success") {
        console.log("Upload Success:", reJson);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setSnapShotLoading(false);
    }
  };

  const handleUploadShortAllocationSnapShot = async (
    snap,
    selectedDate,
    snapShotSelectedFiles
  ) => {
    setSnapShotLoading(true);
    const formData = new FormData();
    formData.append("snapshot", snap);
    formData.append("date", selectedDate);
    for (let i = 0; i < snapShotSelectedFiles.length; i++) {
      formData.append("files", snapShotSelectedFiles[i]);
    }
    try {
      const response = await uploadShortAllocationSnapShot(formData);
      const reJson = await response.data;

      if (reJson.status === "success") {
        console.log("Upload Success:", reJson);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setSnapShotLoading(false);
    }
  };

  const handleUploadShortAllocationTemp = async (snapShotSelectedFiles) => {
    const formData = new FormData();
    for (let i = 0; i < snapShotSelectedFiles.length; i++) {
      formData.append("files", snapShotSelectedFiles[i]);
    }

    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    try {
      const response = await uploadShortAllocationTemp(formData);
      const reJson = await response.data;

      if (reJson.status === "success") {
        toast.success("File Uploaded Successfully");
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFileUploadEod = async (selectedFiles, amount) => {
    setEodLoading(true);
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }
    try {
      const response = await fileUploadEod(formData, amount);
      const json = await response.data;
      console.log(json);

      if (json.status === "success") {
        setEodLoading(false);
        toast.success(json.message);
      }
    } catch (error) {
      setEodLoading(false);
      handleCatch(error);
    }
  };

  const handleFileUploadBod = async (selectedFiles, amount) => {
    setBodLoading(true);
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }
    try {
      const response = await fileUploadBod(formData, amount);
      const json = await response.data;
      if (json.status === "success") {
        setBodLoading(false);
        toast.success(json.message);
      } else {
        setBodLoading(false);
        toast.error(json.message);
      }
    } catch (error) {
      setBodLoading(false);
      handleCatch(error);
    }
  };

  const handleFileUploadMCX = async (selectedFiles) => {
    setBodLoading(true);
    console.log("selectedFiles", selectedFiles);
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }
    try {
      const response = await fileUploadMCX(formData);
      const reJson = await response.data;
      if (reJson.status === "success") {
        setBodLoading(false);
        toast.success(reJson.message);
      } else {
        setBodLoading(false);
        toast.error(reJson.message);
      }
    } catch (error) {
      setBodLoading(false);
      handleCatch(error);
    }
  };

  const handleFileUploadInMarket = async (
    e,
    selectedFiles,
    selectedFilesData
  ) => {
    setInMarketLoading(true);
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i]);
    }
    formData.append("selectedFilesData", JSON.stringify(selectedFilesData));

    try {
      e.preventDefault();
      const response = await fileUploadInMarket(formData);
      const json = await response.data;

      if (json.status === "success") {
        toast.success(json.message);
      } else {
        toast.error(json.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setInMarketLoading(false);
    }
  };

  // ####################################### Feth Data in report

  const handleFetchDataOverallUtilisation = async (
    client_id,
    page,
    pageSize,
    sortField,
    sortDirection
  ) => {
    const queryParams = client_id
      ? { client: client_id }
      : {
          page: page,
          pageSize: pageSize,
          sortField: sortField,
          sortDirection: sortDirection,
        };
    try {
      const response = await fetchDataOverallUtilisation(queryParams);
      const resJson = await response.data;
      if (resJson.status === "success") {
        setLiveTime(resJson.data.file_time);
        setRowCount(resJson.data.count.count);
        setproductData(resJson.data.client_data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchSelectedClientLiveMoniter = async (client_id) => {
    setSelectedClientData([]);
    setLoadingSnap(true);

    const params = { client_id };
    try {
      const response = await fetchSelectedClientLiveMoniterData(params);
      const resJson = response.data;
      if (resJson.status === "success") {
        setSelectedClientData(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoadingSnap(false);
    }
  };

  const handleFetchSelectedClientShortAllocation = async (
    client_id,
    selectedDate,
    snapshot
  ) => {
    setSelectedShortAllocationClientData([]);
    setLoadingSnap(true);
    const params = {
      client: client_id,
      date: formatDateDMY(selectedDate),
      snapshot: snapshot,
    };
    try {
      const response = await fetchSelectedClientShortAllocationData(params);
      const resJson = response.data;
      console.log(resJson);

      if (resJson.status === "success") {
        setSelectedShortAllocationClientData(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoadingSnap(false);
    }
  };

  const handleFetchDataFavUtilisation = async (filter) => {
    try {
      const params = { is_filter: filter };
      const response = await fetchDataFavUtilisation(params);
      const resJson = response.data;
      if (resJson.status === "success") {
        setLiveTime(resJson.data.file_time);
        const Data = resJson.data.client_data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setFavProductData(Data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const fetchReportsEODData = async (selectedDate) => {
    try {
      const response = await fetchReportsEODDataa({
        date: formatDateDMY(selectedDate),
      });
      const resJson = response.data;
      if (resJson.status === "success") {
        const alldata = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setEodData(alldata);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const fetchSnapShortReportsEODData = async (selectedDate) => {
    setLoadingSSData(true);
    setSnapShotEodData([]);
    const params = { date: formatDateDMY(selectedDate) };
    try {
      const response = await fetchSnapShortReportsEODDataa(params);
      const resJson = response.data;
      console.log("resJson", resJson);

      if (resJson.status === "success") {
        const alldata = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setSnapShotEodData(alldata);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoadingSSData(false);
    }
  };

  const fetchReportsSSData = async (selectedDate, value, client) => {
    setLoadingSnap(true);

    if (!client) {
      setSsData([]);
    }
    const params = {
      snapshot: value,
      date: formatDateDMY(selectedDate),
      client: client || "",
    };
    try {
      const response = await fetchReportsSSDataa(params);
      const resJson = response.data;

      if (resJson.status === "success") {
        if (client) {
          const tableData = resJson.data.map((item, index) => ({
            ...item,
            id: index + 1,
          }));
          setDialogRowData(tableData);
        } else {
          const alldata = resJson.data.map((item, index) => ({
            ...item,
            id: index + 1,
          }));
          setSsData(alldata);
        }
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoadingSnap(false);
    }
  };

  //SnapShort Short Allocation
  const fetchShortAllocationReportsSSData = async (selectedDate, value) => {
    setSnapShotSsData([]);
    setLoadingSSData(true);
    const params = {
      snapshot: value,
      date: formatDateDMY(selectedDate),
    };
    try {
      const response = await fetchShortAllocationReportsSSDataa(params);
      const resJson = response.data;

      if (resJson.status === "success") {
        const alldata = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setSnapShotSsData(alldata);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoadingSSData(false);
    }
  };

  const handleEodReportDownload = async (segment, date) => {
    try {
      const params = {
        segment: segment,
        date: date,
      };

      const response = await fetchEodReportData(params);
      if (response.status == 200) {
        const blob = await response.data.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download =
          segment === "trial_bal"
            ? `rtrms_${segment}.xls`
            : `rtrms_${segment}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleEodReportView = async (segment) => {
    try {
      const params = { segment };
      const response = await eodReportView(params);
      const resJson = response.data;
      if (resJson.status === "success") {
        const Data = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setEodDataSegment(Data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchFavClient = async (client_id) => {
    try {
      const params = { client_id };
      const response = await fetchFavClient(params);
      const resJson = response.data;

      if (resJson.status === "success") {
        setFavClientData(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchShortReport = async (selectedDate) => {
    setShortReportData([]);
    const params = { date: formatDateDMY(selectedDate) };
    try {
      const response = await fetchShortReport(params);
      const resJson = response.data;
      if (resJson.status === "success") {
        setShortReportData(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFavClient = async (
    values,
    type,
    handleClose,
    checked,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      const paramsData = {
        status: type,
      };
      const response = await fetchFavClientData(values, paramsData);
      const resJson = response.data;

      if (resJson.status === "success") {
        toast.success(resJson.message);
        resetForm();
        handleClose();
        setSubmitting(false);
        checked
          ? handleFetchDataFavUtilisation("")
          : handleFetchDataFavUtilisation("true");
      }
    } catch (err) {
      setErrors({ submit: "An error occurred while adding client" });
      setErrors({ submit: err?.response?.data?.message });
      setSubmitting(false);
      handleCatch(err);
    }
  };

  const handleDeleteFavClientLiveMoniter = async (deleteId, checked) => {
    try {
      const paramsData = { client_id: deleteId };
      const response = await fetchDeleteFavClient(paramsData);
      const resJson = response.data;

      if (resJson.status === "success") {
        handleFetchDataFavUtilisation(checked ? "" : "true");
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const downloadLiveMoniterReport = async () => {
    try {
      const response = await fetchLiveMonitorReport();
      const resJson = await response.data;
      if (response.status === 200) {
        saveAs(resJson, "LiveMonitor.csv");
        console.log("resJson", resJson);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleShortAllocationExchangeFileDownload = async (date, snapShot) => {
    try {
      const params = {
        date: formatDateDMY(date),
        snapshot: snapShot,
      };
      const response = await fetchShortAllocationExchangeData(params);

      if (response.status == 200) {
        const blob = await response.data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `ExchangeFile_${formatDateDMY(date)}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchFund = async () => {
    try {
      const response = await fetchFundDataa();
      const resJson = response.data;
      if (resJson.status === "success") {
        setFetchFundData(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleChangeFund = async (values, setOpen) => {
    try {
      const formData = new FormData();
      formData.append("segment", values.segment);
      formData.append("total", parseFloat(values.total_funds));
      formData.append("clientAllocation", values.Client_Allocation);
      formData.append("prop", values.Prop_allocation);
      formData.append("freefund", values.Free_Funds);
      const response = await fetchUpdateFunds(formData);
      const resJson = await response.data;
      if (resJson.status === "success") {
        handleFetchFund();
        setOpen(false);
        toast.success(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUploadSegregationFiles = async (
    fileObject,
    selectedFiles,
    setSubmitting,
    fileInputRef,
    setIsFileSelected,
    setSelectedFiles
  ) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      selectedFiles.forEach((fileName) => {
        formData.append("isHeaders", fileName);
      });

      for (let [key, value] of Object.entries(fileObject)) {
        if (value !== null) {
          formData.append(key, value);
        }
      }
      const response = await uploadSegregationFiles(formData);

      if (response.status === 200) {
        Object.keys(fileInputRef.current).forEach((item) => {
          if (fileInputRef.current[item]) {
            fileInputRef.current[item].value = null;
          }
        });
        setIsFileSelected(false);
        setSelectedFiles([]);
        toast.success(response.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  const [segregationMismatch, setSegregationMismatch] = useState([]);
  const handleSegregationMismatch = async (date, isDownload) => {
    setIsLoading(true);
    try {
      const params = { date };
      const response = await segregationMismatchApi(params);
      if (response.status === 200) {
        setSegregationMismatch(response.data.data[0]);
        toast.success(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <RtrmsContext.Provider
        value={{
          handleFetchDataOverallUtilisation,
          productData,
          rowCount,
          liveTime,
          handleFetchSelectedClientLiveMoniter,
          selectedClientData,
          handleFetchDataFavUtilisation,
          favProductData,
          handleFetchFixedAllocation,
          fixedAllocationData,
          handleAddFixedAllocation,
          handleUpdateFixedAllocation,
          handleDeleteFixedAllocation,
          handleFileUploadFixedAllocation,
          setUploadFileDialogVisibleFixedAllocation,
          uploadFileDialogVisibleFixedAllocation,
          handleFetchLiquidBees,
          liquidBeesData,
          handleAddLiquidBees,
          handleUpdateLiquidBees,
          handleDeleteLiquidBees,
          handleFileUploadLiquidBees,
          setUploadFileDialogVisibleLiquidBees,
          uploadFileDialogVisibleLiquidBees,
          handleFetchPTB,
          ptbData,
          handleAddPtb,
          handleUpdatePtb,
          handleBodAllocation,
          bodAllocationData,
          handleBodAllocationCallFile,
          handleBodAllocationDifference,
          handleBodAllocationRefresh,
          handleUploadSnapShot,
          snapShotLoading,
          handleFileUploadEod,
          eodLoading,
          handleFileUploadBod,
          bodLoading,
          handleFileUploadInMarket,
          inMarketLoading,
          fetchReportsEODData,
          eodData,
          loadingSnap,
          fetchReportsSSData,
          ssData,
          dialogRowData,
          setDialogRowData,
          handleEodReportDownload,
          handleEodReportView,
          eodDataSegment,
          handleUploadShortAllocationSnapShot,
          fetchShortAllocationReportsSSData,
          snapShotSsData,
          fetchSnapShortReportsEODData,
          snapShotEodData,
          handleEodAllocation,
          eodAllocationData,
          fetchCashPositionData,
          cashPositionData,
          handleEodAllocationDifference,
          handleEodAllocationCallFile,
          handleUploadShortAllocationTemp,
          handleFetchFavClient,
          favClientData,
          setFavClientData,
          handleFavClient,
          handleFetchShortReport,
          shortReportData,
          handleDeleteTrailBalance,
          handleDeleteFavClientLiveMoniter,
          downloadLiveMoniterReport,
          handleFileUploadMCX,
          handleMCXAllocation,
          mcxAllocationData,
          handleMCXAllocationFileDownload,
          handleFetchSelectedClientShortAllocation,
          selectedShortAllocationClientData,
          loadingSSdata,
          handleShortAllocationExchangeFileDownload,
          handleFetchFund,
          fetchFundData,
          handleChangeFund,

          handleUploadSegregationFiles,
          segregationMismatch,
          handleSegregationMismatch,
          isLoading,
        }}
      >
        {props.children}
      </RtrmsContext.Provider>
    </>
  );
};

export default RtrmsApis;
