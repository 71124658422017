import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import BlogDash from "views/utilities/arham-app/dashboard";
import BlogUtils from "views/utilities/arham-app/utils";

const BlogHome = Loadable(
  lazy(() => import("views/utilities/arham-app/index"))
);

const BlogAdd = Loadable(
  lazy(() => import("views/utilities/arham-app/utils/Blogs"))
);
const EventGalleyAdd = Loadable(
  lazy(() => import("views/utilities/arham-app/utils/Event-Gallery"))
);
const EventVideoAdd = Loadable(
  lazy(() => import("views/utilities/arham-app/utils/Event-Video"))
);
const ArhamPdfAdd = Loadable(
  lazy(() => import("views/utilities/arham-app/utils/Arham-pdf"))
);
const MagazineAdd = Loadable(
  lazy(() => import("views/utilities/arham-app/utils/Magazine"))
);

export const ArhamModule = {
  path: "arham-web",
  element: <BlogHome />,
  children: [
    {
      path: "home",
      element: <BlogDash />,
    },
    {
      path: "utils",
      element: <BlogUtils />,
    },
    {
      path: "reports",
      element: <BlogDash />,
    },
  ],
};

export const ArhamRoutes = {
  path: "arham-web",
  children: [
    {
      path: "blog",
      element: <BlogAdd />,
    },
    {
      path: "event-gallery",
      element: <EventGalleyAdd />,
    },
    {
      path: "event-video",
      element: <EventVideoAdd />,
    },
    {
      path: "pdf",
      element: <ArhamPdfAdd />,
    },
    {
      path: "magazine",
      element: <MagazineAdd />,
    },
  ],
};
