// assets
import { IconUsers } from "@tabler/icons";

// constant
const icons = { IconUsers };

// ==============================|| Report PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const userManagement = {
  id: "user-manage",
  // title: 'Manage',
  type: "group",
  children: [
    {
      id: "userManagement",
      title: "User Management",
      type: 'item',
      url: "/user-management",
      icon: icons.IconUsers,
      breadcrumbs: true,
      // type: "collapse",
    },
  ],
};

export default userManagement;
