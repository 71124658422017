/*eslint-disable*/
import React, { useContext, useRef, useEffect, useState } from "react";
import CRMSTable from "../components/CRMSTable";
import { DataTable } from "primereact/datatable";
import CustomBreadcrumbs from "views/utilities/common-components/CustomBreadcrumbs";
import MainCard from "ui-component/cards/MainCard";
import { CrmsContext } from "context/CRMS/CRMSApis";
import { Calendar } from "primereact/calendar";
import { Grid, LinearProgress, Tooltip } from "@mui/material";
import MUIDataGridTable from "views/utilities/rtrms/common-component/MUIDataGridTable";

export default function RevenueSummary() {
  const getCurrentMonthFirstDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  };

  const [dates, setDates] = useState([getCurrentMonthFirstDate(), new Date()]);
  // const [dates, setDates] = useState([new Date(), new Date()]);

  const date1 = new Date(dates[0]);
  const date2 = new Date(dates[1]);

  const formatDateString = (dateStr) => {
    const dateObj = new Date(dateStr);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}${month}${day}`;
  };

  const startDate = formatDateString(date1);
  const endDate = formatDateString(date2);

  const Breadcrumbs = [
    {
      to: "/utilities/crms/reports",
      label: "reports",
    },
  ];

  const { handleFetchClientRevenueData, clientRevenueData, isLoading } =
    useContext(CrmsContext);

  useEffect(() => {
    handleFetchClientRevenueData(startDate, endDate);
  }, [dates]);

  const columns = [
    {
      field: "client_id",
      headerName: "Client Id",
      flex: 1,
      headerClassName: "header",
      align: "left",
    },
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 2,
      headerClassName: "header",
      align: "left",
    },
    {
      field: "bse_cash",
      headerName: "BSE CASH",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => {
        params?.value?.toFixed(2);
      },
    },
    {
      field: "nse_cash",
      headerName: "NSE CASH",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => {
        params?.value?.toFixed(2);
      },
    },
    {
      field: "cd_nse",
      headerName: "NSE CD",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => {
        params?.value?.toFixed(2);
      },
    },
    {
      field: "nse_fo",
      headerName: "NSE FO",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => {
        params?.value?.toFixed(2);
      },
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => {
        params?.value?.toFixed(2);
      },
    },
  ];

  return (
    <>
      <MainCard
        title={
          <CustomBreadcrumbs
            links={Breadcrumbs}
            title="Revenue Summaey"
            moduleName="crms"
          />
        }
        secondary={
          <Calendar
            value={dates}
            onChange={(e) => setDates(e.value)}
            selectionMode="range"
            readOnlyInput
            hideOnRangeSelection
            dateFormat="dd-mm-yy"
          />
        }
      >
        {isLoading ? (
          <LinearProgress />
        ) : (
          <MUIDataGridTable
            columns={columns}
            rows={clientRevenueData}
            filename={`RevenueSummary ${startDate}-${endDate}`}
          />
        )}
      </MainCard>
    </>
  );
}

// /*eslint-disable*/
// import React, { useContext, useRef, useEffect, useState } from "react";
// import CRMSTable from "../CRMSTable";
// import { DataTable } from "primereact/datatable";
// import CustomBreadcrumbs from "views/utilities/common-components/CustomBreadcrumbs";
// import MainCard from "ui-component/cards/MainCard";
// import { CrmsContext } from "context/CRMS/CRMSApis";
// import { Calendar } from "primereact/calendar";
// import { Grid, LinearProgress, Tooltip } from "@mui/material";
// import { Box } from "@mui/system";
// import { Column } from "primereact/column";
// import ScssStyle from "assets/scss/_themes-vars.module.scss";
// import { Button as PrimeButton } from "primereact/button";

// export default function RevenueSummary() {
//   const [dates, setDates] = useState([new Date(), new Date()]);
//   const dt = useRef(null);
//   const [selectedProducts, setSelectedProducts] = useState(null);

//   const exportCSV = () => {
//     dt.current.exportCSV();
//   };
//   const date1 = new Date(dates[0]);
//   const date2 = new Date(dates[1]);
//   const styles = ScssStyle;

//   const formatDateString = (dateStr) => {
//     const dateObj = new Date(dateStr);
//     const year = dateObj.getFullYear();
//     const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
//     const day = dateObj.getDate().toString().padStart(2, "0");
//     return `${year}${month}${day}`;
//   };

//   const startDate = formatDateString(date1);
//   const endDate = formatDateString(date2);

//   const Breadcrumbs = [
//     {
//       to: "/utilities/crms/reports",
//       label: "reports"
//     }
//   ];

//   const { handleFetchClientRevenueData, clientRevenueData, isLoading } =
//     useContext(CrmsContext);

//   useEffect(() => {
//     handleFetchClientRevenueData(startDate, endDate);
//   }, [startDate, endDate]);

//   const columns = [
//     {
//       field: "id",
//       header: "S.NO",
//       minWidth: 2
//     },
//     {
//       field: "client_id",
//       header: "Client Id",
//       minWidth: 2
//     },
//     {
//       field: "full_name",
//       header: "Full Name",
//       minWidth: 2
//     },
//     {
//       field: "bse_cash",
//       header: "BSE Cash",
//       minWidth: 2
//     },
//     {
//       field: "nse_cash",
//       header: "NSE Cash",
//       minWidth: 2
//     },
//     {
//       field: "cd_nse",
//       header: "NSE CD",
//       minWidth: 2
//     },
//     {
//       field: "nse_fo",
//       header: "NSE FO",
//       minWidth: 2
//     },
//     {
//       field: "total",
//       header: "Total",
//       minWidth: 2
//     }
//   ];
//   // const header = (
//   //   <>
//   //     <div className="flex justify-content-end" style={{ maxHeight: "25px" }}>
//   //       <Tooltip title="download">
//   //         <PrimeButton
//   //           icon="pi pi-download"
//   //           style={{ height: "20px", color: "black" }}
//   //           onClick={() => exportCSV()}
//   //           text
//   //         />
//   //       </Tooltip>
//   //     </div>
//   //   </>
//   // );

//   const generateColumns = (column) => {
//     return column.map((config, index) => {
//       const { field, header, body, minWidth } = config;
//       return (
//         <Column
//           key={index}
//           field={field}
//           header={header}
//           body={body}
//           sortable
//           headerStyle={{ height: "10px" }}
//           style={{
//             minWidth: `${minWidth}rem`,
//             fontSize: "0.70rem",
//             color: styles.secondaryDark,
//             textAlign: "left"
//           }}
//         />
//       );
//     });
//   };

//   return (
//     <>
//       <MainCard
//         title={
//           <CustomBreadcrumbs
//             links={Breadcrumbs}
//             title="Revenue Summaey"
//             moduleName="crms"
//           />
//         }
//         secondary={
//           <Tooltip title="download">
//             <PrimeButton
//               icon="pi pi-download"
//               style={{ height: "20px", color: "black" }}
//               onClick={() => exportCSV()}
//               text
//             />
//           </Tooltip>
//         }
//       >
//         <Grid container sx={{ p: 0 }}>
//           <Grid item xs={12} lg={12} sm={12} md={12}>
//             <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
//               <Calendar
//                 value={dates}
//                 onChange={(e) => setDates(e.value)}
//                 selectionMode="range"
//                 readOnlyInput
//                 hideOnRangeSelection
//               />
//             </Box>
//           </Grid>
//           <Grid item xs={12} lg={12} sm={12} md={12}>
//             {isLoading ? (
//               <LinearProgress />
//             ) : (
//               <DataTable
//                 size="small"
//                 ref={dt}
//                 value={clientRevenueData}
//                 selection={selectedProducts}
//                 onSelectionChange={(e) => setSelectedProducts(e.value)}
//                 dataKey="id"
//                 paginator
//                 rows={25}
//                 rowsPerPageOptions={[5, 25, 50]}
//                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
//                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
//                 // globalFilter={globalFilter}
//                 // header={header}
//                 scrollable
//                 resizableColumns
//                 height="100vh"
//                 scrollHeight="60vh"
//                 reorderableColumns
//                 reorderableRows
//                 alwaysShowPaginator={false}
//                 paginatorPosition="bottom"
//               >
//                 {generateColumns(columns)}
//               </DataTable>
//             )}
//           </Grid>
//         </Grid>
//       </MainCard>
//     </>
//   );
// }
