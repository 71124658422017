import axios from "axios";

// Create Axios instance
const createAxiosInstance = () => {
  const controllers = {}; // Object to track controllers by requestId

  const instance = axios.create();

  // Interceptor to handle aborting of previous requests by requestId
  instance.interceptors.request.use(async (config) => {
    const { requestId } = config;

    // Abort the previous request if it has the same requestId
    if (requestId && controllers[requestId]) {
      console.log(`Aborting previous request with requestId: ${requestId}`);
      await controllers[requestId].abort(); // Abort the previous request
      delete controllers[requestId]; // Clean up the old controller after aborting
    }

    // Create a new AbortController for the current request
    const currentController = new AbortController();
    controllers[requestId] = currentController; // Store it by requestId
    config.signal = currentController.signal; // Attach the controller signal to the request

    return config;
  });

  // Handle response and errors
  instance.interceptors.response.use(
    (response) => {
      const { requestId } = response.config;
      if (requestId) {
        delete controllers[requestId]; // Clear the controller after a successful request
      }
      return response;
    },
    (error) => {
      const { requestId } = error.config || {};
      if (error.name === 'AbortError') {
        console.log(`Request was aborted for requestId: ${requestId}`);
        return Promise.resolve({ message: 'Request aborted' });
      }
      if (requestId) {
        delete controllers[requestId]; // Clear the controller on error as well
      }
      return Promise.reject(error); // Handle other errors
    }
  );

  // Function to manually cancel a specific request by requestId
  instance.cancelRequestById = async (requestId) => {
    if (controllers[requestId]) {
      console.log(`Manually aborting request with requestId: ${requestId}`);
      await controllers[requestId].abort(); // Abort the current request
      delete controllers[requestId]; // Remove the controller after aborting
    }
  };

  return instance;
};

// Create and export the Axios instance
const axiosInstance = createAxiosInstance();
export default axiosInstance;








































// import axios from "axios";

// const requestManager = {
//   requests: new Set(),

//   // Add a request to the Set
//   addRequest(request) {
//     this.requests.add(request);
//   },

//   // Remove a request from the Set
//   removeRequest(request) {
//     this.requests.delete(request);
//   },

//   // Cancel all requests in the Set
//   cancelAllRequests() {
//     this.requests.forEach((request) => {
//       if (request.cancel) {
//         request.cancel();
//       }
//     });
//     this.requests.clear();
//   },
// };

// const axiosInstance = axios.create();

// axiosInstance.interceptors.request.use((config) => {
//   const cancelTokenSource = axios.CancelToken.source();
//   config.cancelToken = cancelTokenSource.token;
//   requestManager.addRequest(cancelTokenSource);
//   return config;
// });

// axiosInstance.interceptors.response.use(
//   (response) => {
//     if (response.config.cancelToken) {
//       requestManager.removeRequest(response.config.cancelToken);
//     }
//     return response;
//   },
//   (error) => {
//     if (error.config && error.config.cancelToken) {
//       requestManager.removeRequest(error.config.cancelToken);
//     }
//     // Handle the error as you need
//     return Promise.reject(error);
//   }
// );

// export { requestManager, axiosInstance };
