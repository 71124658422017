import { commonApi } from "utils/CommonApiFunc";
import { commonApiRequest, commonApiRequestNew } from "utils/CommonFunctions";

export const addTask = (requestData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/insert-task`;
  return commonApiRequest('POST', url, requestData);
};

export const addIndividualTask = (body) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/insert-individual-task`;
  return commonApiRequest('POST', url, body);
};

export const updateTask = (body, paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/update-task`;
  return commonApiRequest('PUT', url, body, paramsData);
};

export const deleteTask = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/delete-task`;
  return commonApiRequest('DELETE', url, data);
};

export const fetchAssignTask = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-task-list-by-user`;
  return commonApiRequest('GET', url, null, paramsData);
};


export const fetchProjectDetailsByProjectId = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-project-by-project-id`;
  return commonApiRequest('POST', url, paramsData);
};


export const fetchTaskByProject = (body, paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-task-by-user-project`;
  return commonApiRequest('POST', url, body, paramsData);
};

export const fetchListProject = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-project-list-by-user`;
  return commonApiRequest('GET', url, null, paramsData);
};

export const fetchTaskListByTaskID = (body) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-task-details-by-task-id`;
  return commonApiRequest('POST', url, body);
};

export const fetchUserDetails = () => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-user-details`;
  return commonApiRequest('GET', url);
};

export const fetchParticipantsDetails = (body, paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-participants-by-base-id`;
  return commonApiRequest('POST', url, body, paramsData);
};


export const addProject = (body) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/insert-project`;
  return commonApiRequest('POST', url, body);
};

export const updateProject = (body) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/update-project`;
  return commonApiRequest('PUT', url, body);
};


export const deleteProject = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/delete-project`;
  return commonApiRequest('DELETE', url, data);
};

export const deleteSection = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/delete-section`;
  return commonApiRequest('DELETE', url, data);
};

export const updateSection = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/update-section`;
  return commonApiRequest('PUT', url, data);
};

export const fetchSectionByProject = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-section-list-by-base-id`;
  return commonApiRequest('POST', url, data);
};


export const fetchProjectAccess = () => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-project-access-types`;
  return commonApiRequest('GET', url);
};

export const fetchAddProjectSection = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/insert-section`;
  return commonApiRequest('POST', url, data);
};

export const addComment = (data, paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/insert-comments`;
  return commonApiRequest('POST', url, data, paramsData);
};
export const editComment = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/update-comment`;
  return commonApiRequest('PUT', url, data);
};

export const fetchCommentByTask = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-comment-by-base-id`;
  return commonApiRequest('POST', url, data);
};
export const deleteComment = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/delete-comment`;
  return commonApiRequest('DELETE', url, null, paramsData);
};

export const fetchFileUploadAttachments = (formData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/insert-attachment`;
  return commonApiRequest('POST', url, formData);
};

export const fetchAttachmentsList = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-attachment-list-by-base-id`;
  return commonApiRequest('POST', url, data);
};
export const deleteAttachment = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/delete-attachment`;
  return commonApiRequest('DELETE', url, null, paramsData);
};

export const fetchChangeFavourites = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/manage-favourits_operation`;
  return commonApiRequest('POST', url, null, paramsData);
};

export const fetchAttachments = (body) => {
  const url = `${process.env.REACT_APP_URL_PMS}/v1/get-attachment-file`;
  return commonApiRequest('POST', url, body);
};


export const fetchAllTargetByUser = () => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/get-target-list-by-user`;
  return commonApiRequest('GET', url);
};

export const fetchTargetDetails = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/get-target-by-id`;
  return commonApiRequest('POST', url, data);
};

export const fetchMilestonesByTarget = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/get-milestones-list-by-target-id`;
  return commonApiRequest('POST', url, data);
};

export const fetchAllTasksByMilestone = (body, paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/get-task-list-by-base-id`;
  return commonApiRequest('POST', url, body, paramsData);
};

export const fetchAddTarget = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/insert-target`;
  return commonApiRequest('POST', url, data);
};

export const updateTarget = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/update-target`;
  return commonApiRequest('PUT', url, data);
};



export const addMileStone = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/insert-milestone`;
  return commonApiRequest('POST', url, data);
};

export const updateMileStone = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/update-milestone`;
  return commonApiRequest('PUT', url, data);
};

export const fetchMilestoneDetails = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/get-milestone-by-id`;
  return commonApiRequest('POST', url, data);
};

export const fetchTaskDetails = (data) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/get-task-details-by-task-id`;
  return commonApiRequest('POST', url, data);
};


export const startTimerr = (body) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/pms/v1/worklog/start-timer`;
  return commonApiRequest('POST', url, body);
};


export const changeTimerState = (body) => {

  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/pms/v1/worklog/stop-timer`;
  return commonApiRequest('PUT', url, body);
};


export const fetchProjectList = () => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/get-project-list`;
  return commonApiRequestNew('GET', url);
};
export const fetchUserDashboardData = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/user-dashboard-data`;
  return commonApiRequestNew('GET', url, null, paramsData);
};
export const fetchMasterDashboardData = (paramsData, signal) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/master-dashboard-data`;
  return commonApi('GET', url, null, paramsData, 'json', signal);
};
export const fetchMasterDashboardProject = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/project-list-department`;
  return commonApiRequestNew('GET', url, null, paramsData);
};
export const fetchAllWorkLogDetails = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/pms/v1/worklog/worklog-details-by-task-id`;
  return commonApiRequest('GET', url, null, paramsData);
};
export const deleteWorklog = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/pms/v1/worklog/delete-worklog`;
  return commonApiRequest('DELETE', url, null, paramsData);
};
export const updateWorklog = (body) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/pms/v1/worklog/update-worklog`;
  return commonApiRequest('PUT', url, body);
};

