// assets
import { IconHelp } from '@tabler/icons';

// constant
const icons = { IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const helpDesk = {
    id: 'helpdesk',
    type: 'group',
    children: [
        {
            id: 'helpdesk',
            title: 'Help Desk',
            type: 'item',
            url: '/helpdesk',
            icon: icons.IconHelp,
            breadcrumbs: false
        }
    ]
};

export default helpDesk;
