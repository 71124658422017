import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const ChatIndex = Loadable(lazy(() => import("views/utilities/chat-app")));
const ChatOutlet = Loadable(lazy(() => import("views/utilities/chat-app/main-components/ChatOutlet")));

export const ChatModule = {
  path: "chat",
  element: <ChatIndex />,
  children: [
    {
      path: ":chat_emp_id",
      element: <ChatOutlet />
    }
  ]
};
