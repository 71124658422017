/*eslint-disable*/
import React, { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { addSessionStorage, getSessionStorage } from "utils/encrpytion";
import {
  addBrokerageApi,
  AddClientApi,
  addFundApi,
  AddMangerApi,
  bhavCopyApi,
  ClientDetailsApi,
  CtclMappingAPi,
  dayWiseReportApi,
  deleteBrokerageApi,
  deleteClientApi,
  deleteCtclMappingApi,
  deleteFundApi,
  deleteManagerApi,
  eodProcessApi,
  getBrokerageDataApi,
  getCtclMappingApi,
  getEodFileDataApi,
  getFUndDataApi,
  getManagerDataApi,
  getManagerWiseClientApi,
  historyDashboardApi,
  liveDashboardApi,
  ManagerDetailsApi,
  monthlyReportApi,
  tradeFileApi,
  updateBrokerageApi,
  updateClientCodeTechExcelApi,
  updateCtclMapping,
  updateFundApi,
  updateManagerApi,
} from "services/algo-summary/AlgoSummaryService";
import { handleCatch } from "utils/CommonFunctions";

export const AlgoSummaryContext = createContext();

const useStyles = makeStyles((theme) => ({
  customScrollbar: {
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#9e9e9e",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#9e9e9e",
    },
  },
}));

export default function AlgoSummaryApis(props) {
  const classes = useStyles();
  // get fund manager data from localstorage
  const managerDetails = JSON.parse(getSessionStorage("manager_data") || "[]");

  //Get List of Month
  const months = Array.from({ length: 12 }, (_, i) => {
    const monthId = (i + 1).toString().padStart(2, "0");
    const monthName = new Date(0, i).toLocaleString("default", {
      month: "long",
    });
    return { id: monthId, name: monthName };
  });

  //Get List Of Year
  const years = Array.from({ length: 50 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return { id: year, value: year.toString() };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [managerData, setManagerData] = useState([]);
  const [managerId, setManagerId] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [ctclData, setCtcldata] = useState([]);
  const [historyDashboardData, setHistoryDashboardData] = useState([]);
  const [liveDashboardData, setLiveDashboardData] = useState([]);
  const [liveDashboardTime, setLiveDashboardTime] = useState("");
  const [dayWiseReportData, setDayWiseReportData] = useState([]);
  const [monthlyReportData, setMonthlyReportData] = useState([]);
  const [fundData, setFundData] = useState([]);
  const [brokerageData, setBrokerageData] = useState([]);
  const [eodFileData, setEodFileData] = useState([]);

  //############################# Manager APIS #################################
  const handleGetManagerDetails = async () => {
    setIsLoading(true);
    try {
      const response = await ManagerDetailsApi();
      if (response.status === 200) {
        const managerData = response.data.data;
        setManagerData(managerData);

        const managerArray = managerData.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        addSessionStorage("manager_data", JSON.stringify(managerArray));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddManager = async (values, setOpenDailog) => {
    setIsLoading(true);
    try {
      const response = await AddMangerApi(values, setOpenDailog);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleGetManagerDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      setOpenDailog(false);
    }
  };

  const handleGetManagerData = async (ClientID) => {
    try {
      const response = await getManagerDataApi(ClientID);
      if (response.status === 200) {
        setManagerId(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleGetManagerWiseClient = async (ManagerID) => {
    try {
      const response = await getManagerWiseClientApi(ManagerID);
      if (response.status === 200) {
        setClientId(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateManager = async (values, setOpenDailog) => {
    try {
      const response = await updateManagerApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetManagerDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleDeleteManager = async (id) => {
    try {
      const response = await deleteManagerApi(id);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetManagerDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //############################ Client APIS ###################################
  const handleGetClientDetails = async () => {
    try {
      const response = await ClientDetailsApi();
      if (response.status === 200) {
        setClientData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleAddClient = async (values, setOpenDailog) => {
    try {
      const response = await AddClientApi(values, setOpenDailog);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleGetClientDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleUpdateClient = async (values, setOpenDailog) => {
    try {
      const response = await updateClientCodeTechExcelApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetClientDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleDeleteClient = async (id) => {
    try {
      const response = await deleteClientApi(id);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetClientDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  //############################ CTCL Mapping APIS #############################
  const handleCtclMapping = async (values, setOpenDailog) => {
    try {
      const response = await CtclMappingAPi(values);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleGetCtclMappingdata();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleGetCtclMappingdata = async () => {
    try {
      const response = await getCtclMappingApi();
      if (response.status === 200) {
        setCtcldata(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateCtclMapping = async (values, setOpenDailog) => {
    try {
      const response = await updateCtclMapping(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetCtclMappingdata();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleDeleteCtclMapping = async (id) => {
    try {
      const response = await deleteCtclMappingApi(id);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetCtclMappingdata();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  //############################ Dashboard APIS ################################
  const handleHistoryDashboard = async (startDate, endDate) => {
    setIsLoading(true);
    try {
      const response = await historyDashboardApi(startDate, endDate);
      if (response.status === 200) {
        setHistoryDashboardData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLiveDashboard = async () => {
    try {
      const response = await liveDashboardApi();
      if (response.status === 200) {
        setLiveDashboardData(response.data.data.live_data);
        setLiveDashboardTime(response.data.data.live_time);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //############################## Report APIS ########################
  const handledayWiseReport = async (
    fundManagerId,
    startDate,
    endDate,
    client,
    club
  ) => {
    setIsLoading(true);
    try {
      const response = await dayWiseReportApi(
        fundManagerId,
        startDate,
        endDate,
        client,
        club
      );
      if (response.status === 200) {
        setDayWiseReportData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMonthlyReport = async (
    fundManagerId,
    selectedMonth,
    selectedYear
  ) => {
    setIsLoading(true);
    try {
      const response = await monthlyReportApi(
        fundManagerId,
        selectedMonth,
        selectedYear
      );
      if (response.status === 200) {
        setMonthlyReportData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  //############################ Fund Apis #############################
  const handleGetFundData = async () => {
    setIsLoading(true);
    try {
      const response = await getFUndDataApi();
      if (response.status === 200) {
        setFundData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddFund = async (values, setOpenDailog) => {
    try {
      const response = await addFundApi(values);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleGetFundData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleUpdateFund = async (values, setOpenDailog) => {
    try {
      const response = await updateFundApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetFundData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleDeleteFund = async (id) => {
    try {
      const response = await deleteFundApi(id);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetFundData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  //######################### Brokerage APis ############################
  const handleGetBrokerageData = async () => {
    setIsLoading(true);
    try {
      const response = await getBrokerageDataApi();

      if (response.status === 200) {
        setBrokerageData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddBrokerage = async (values, setOpenDailog) => {
    try {
      const response = await addBrokerageApi(values);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleGetBrokerageData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleUpdateBrokerage = async (values, setOpenDailog) => {
    console.log("values", values.start_date);
    console.log("values", values.start_date);

    try {
      const response = await updateBrokerageApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetBrokerageData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
      console.log("error", error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleDeleteBrokerage = async (id) => {
    try {
      const response = await deleteBrokerageApi(id);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetBrokerageData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //######################## EOD APIS ##################################
  const handleTradeFile = async (values, { resetForm, fileInputRef }) => {
    setIsLoading(true);
    try {
      const response = await tradeFileApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetEodFileData("trade_file");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      resetForm();
      setIsLoading(false);
    }
  };

  const handleBhavCopy = async (values, { resetForm, fileInputRef }) => {
    setIsLoading(true);
    try {
      const response = await bhavCopyApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetEodFileData("bhav_copy");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      resetForm();
      setIsLoading(false);
    }
  };

  const handleGetEodFileData = async (type) => {
    try {
      const response = await getEodFileDataApi(type);
      if (response.status === 200) {
        setEodFileData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleEodProcess = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const response = await eodProcessApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      resetForm();
      setIsLoading(false);
    }
  };

  return (
    <AlgoSummaryContext.Provider
      value={{
        classes,
        isLoading,
        managerDetails,

        handleAddManager,
        handleGetManagerDetails,
        handleUpdateManager,
        handleDeleteManager,
        managerData,

        handleGetClientDetails,
        handleAddClient,
        clientData,
        handleUpdateClient,
        handleDeleteClient,

        handleHistoryDashboard,
        historyDashboardData,
        handleLiveDashboard,
        liveDashboardData,
        liveDashboardTime,

        handleCtclMapping,
        handleGetCtclMappingdata,
        ctclData,
        handleUpdateCtclMapping,
        handleDeleteCtclMapping,

        handledayWiseReport,
        handleGetManagerWiseClient,
        clientId,
        dayWiseReportData,

        handleGetManagerData,
        managerId,

        handleAddFund,
        handleGetFundData,
        fundData,
        handleUpdateFund,
        handleDeleteFund,

        handleAddBrokerage,
        handleGetBrokerageData,
        brokerageData,
        handleUpdateBrokerage,
        handleDeleteBrokerage,

        months,
        years,

        handleMonthlyReport,
        monthlyReportData,

        handleTradeFile,
        handleBhavCopy,
        handleGetEodFileData,
        eodFileData,
        handleEodProcess,
      }}
    >
      {props.children}
    </AlgoSummaryContext.Provider>
  );
}
