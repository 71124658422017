import { commonApiRequest } from "utils/CommonFunctions";

const ENVURL = process.env.REACT_APP_URL_AP_ON_BOARDING;

export const addApLeadApi = async (values) => {
  const url = `${ENVURL}/v1/add-ap-lead`;
  return commonApiRequest("POST", url, values);
};

export const APStatusWiseDataApi = async (status) => {
  const url = `${ENVURL}/v1/get-apData-statusWise?status=${status}`;
  return commonApiRequest("GET", url);
};

export const addBranchCreation = (values) => {
  const url = `${ENVURL}/v1/ap-backoffice-brach-creation`;
  return commonApiRequest("POST", url, null, values);
};
