import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const E_MAIL = Loadable(
  lazy(() => import("views/utilities/e-mail"))
);

export const EmailModule = {
  path: "e-mail",
  element: <E_MAIL />,
};

export const EmailRoutes = {};
