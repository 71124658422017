import axios from "axios";
import { commonApiRequest } from "utils/CommonFunctions";

export const handleFetchGetDataApi = (params) => {
    const url = `${process.env.REACT_APP_URL_EDIS_STOCK_VERIFICATION}/edis/v1/edis-holding-data`;
    return commonApiRequest('GET', url, null, params);
};

export const fetchClientDataCodeApi = (params) => {
    const url = `${process.env.REACT_APP_URL_EDIS_STOCK_VERIFICATION}/edis/v1/client-list`;
    return commonApiRequest('GET', url, null, params);
};

export const confirmVerificationApi = (payload, params) => {
    const url = `${process.env.REACT_APP_URL_EDIS_STOCK_VERIFICATION}/edis/v1/edis-stock-verify`;
    return commonApiRequest('POST', url, payload, params);
};

export const fetchSettlementDataApi = () => {
    const url = `${process.env.REACT_APP_URL_EDIS_STOCK_VERIFICATION}/edis/v1/settlement-data`;
    return commonApiRequest('GET', url);
};
export const fetchSettleMentIdApi = (params) => {
    const url = `${process.env.REACT_APP_URL_EDIS_STOCK_VERIFICATION}/edis/v1/edis-settlement-id`;
    return commonApiRequest('GET', url, null, params);
};

export const updateSettlementDataApi = (body) => {
    const url = `${process.env.REACT_APP_URL_EDIS_STOCK_VERIFICATION}/edis/v1/update-settlement-data`;
    return commonApiRequest('POST', url, body);
};

export const fetchDptFileApi = async (dateYYYYMMDD) => {

    const headers = {
        accept: "application/json",
        authToken: localStorage.authToken,
        source: "erp",
    }

    const config = {
        headers: headers,
        responseType: 'blob',
    }
    const url = `${process.env.REACT_APP_URL_EDIS_STOCK_VERIFICATION}/edis/v1/fetch-dpt-file?date=${dateYYYYMMDD}`;
    const response = await axios.get(url, config);
    return response;
};



// const COMMON_URL = process.env.REACT_APP_URL_EDIS_STOCK_VERIFICATION;

// export const handleFetchGetDataApi = async (client_id) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/edis/v1/edis-holding-data?source=erp&client_code=${client_id}`;
//     const response = await axios.get(url, config);
//     return response;
// };
// export const fetchClientDataCodeApi = async (client_id) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/edis/v1/client-list?client_code=${client_id}`;
//     const response = await axios.get(url, config);
//     return response;
// };
// export const confirmVerificationApi = async (payload) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/edis/v1/edis-stock-verify?source=erp`;
//     const response = await axios.post(url, payload, config);
//     return response;
// };
// export const fetchSettlementDataApi = async () => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/edis/v1/settlement-data`;
//     const response = await axios.get(url, config);
//     return response;
// };
// export const updateSettlementDataApi = async (body) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/edis/v1/update-settlement-data`;
//     const response = await axios.post(url, body, config);
//     return response;
// };
// export const fetchDptFileApi = async (dateYYYYMMDD) => {
//     const config = {
//         headers: getHeaders,
//         responseType: 'blob',
//     }

//     const url = `${COMMON_URL}/edis/v1/fetch-dpt-file?date=${dateYYYYMMDD}`;
//     const response = await axios.get(url, config);
//     return response;
// };