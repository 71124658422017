/*eslint-disable*/
import React from "react";

import ArhamShareFeatures from "config-details/features/ArhamShareFeatures";
import CommonUtilsIndex from "views/utilities/common-components/CommonUtilsIndex";

const arhamWebUtilities = () => {
  return <CommonUtilsIndex allFeatures={ArhamShareFeatures} />;
};

export default arhamWebUtilities;
