/*eslint-disable*/
import { commonApiRequest, commonApiRequestNew } from "utils/CommonFunctions";

const apiUrl = process.env.REACT_APP_URL_INWARD;

export const getEntryCategoryApi = async () => {
  const url = `${apiUrl}/v1/types/inward-type`;
  return commonApiRequestNew("GET", url);
};
export const showCategoryApi = async (documentType) => {
  const url = `${apiUrl}/v1/types/inward-sub-category?inward_type=${documentType}`;
  return commonApiRequestNew("GET", url);
};

export const showProofFilesApi = async (documentType, categoryId) => {
  let url;
  let requestData;

  if (documentType === 9) {
    url = `${apiUrl}/v1/proofs/proof-lists-category-wise?inward_type=Account_opening`;
    requestData = [categoryId];
  } else if (documentType === 1) {
    url = `${apiUrl}/v1/proofs/proof-lists-category-wise?inward_type=Modification`;
    requestData = categoryId;
  }
  return commonApiRequestNew("POST", url, requestData);
};

export const getClientDetailsApi = async (tradingCode) => {
  const url = `${apiUrl}/v1/details/client-details?client_code=${tradingCode}`;
  return commonApiRequestNew("GET", url);
};

export const modificationDataApi = async (values) => {
  const url = `${apiUrl}/v1/inward-entry/modification`;
  const formdata = new FormData();
  for (const key in values) {
    formdata.append(key, values[key]);
  }

  return commonApiRequest("POST", url, formdata);
};

export const getInwardEntriesDataApi = async (
  pagination,
  inwardType,
  inwardID,
  ApprovalStatus
) => {
  console.log("inwardID", inwardID);

  let url = `${apiUrl}/v1/checker/checker-inward-data?page_index=${
    pagination.pageIndex + 1
  }&page_size=${pagination.pageSize}`;

  // Conditionally append inward_id if it exists
  if (inwardID) {
    url += `&inward_id=${inwardID}`;
  }

  // Append inward_status and inward_types
  url += `&inward_status=${ApprovalStatus}&inward_types=${inwardType}`;

  console.log("Constructed URL:", url); // Debugging statement

  // Call the API with the constructed URL
  return commonApiRequestNew("GET", url);
};

export const clientBankDetailsApi = async (tradingCode) => {
  const url = `${apiUrl}/v1/details/client-bank-details?client_code=${tradingCode}`;
  return commonApiRequestNew("GET", url);
};

export const checkerDataApi = async () => {
  const url = `${apiUrl}/v1/user/inward/inwars_history_data?status_type=Pending`;
  return commonApiRequestNew("POST", url, "");
};

export const checkerModificationApi = async (inwardID) => {
  const url = `${apiUrl}/v1/user/inward/get_modification_data?inward_id=${inwardID}`;
  return commonApiRequestNew("GET", url);
};

export const submitModificationDataApi = async (
  action,
  inwardId,
  inwardType,
  rejectReason
) => {
  const url = `${apiUrl}/v1/checker/checker-inward-aprroval-rejection?inward_id=${inwardId}&inward_status=${action}&inward_types=${inwardType}`;
  const body = {
    rejection_reason: rejectReason,
  };
  return commonApiRequestNew("POST", url, body);
};

export const otpApi = async (InwardId) => {
  const url = `${apiUrl}/v1/account_profile/send_otp`;
  const body = { inward_id: InwardId };
  return commonApiRequest("POST", url, body);
};

export const closureFormApi = async (values) => {
  const url = `${apiUrl}/v1/inward-entry/acc-close`;
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }
  return commonApiRequest("POST", url, formData);
};

export const segmentDataApi = async (values) => {
  const url = `${apiUrl}/v1/inward-entry/segment-addition`;
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }
  return commonApiRequest("POST", url, formData);
};

export const getReasonCodeApi = async () => {
  const url = `${apiUrl}`;
  return commonApiRequestNew("GET", url);
};

export const nomineeDataApi = async (values) => {
  const url = `${apiUrl}/v1/inward-entry/nominee-addition`;
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }
  return commonApiRequest("POST", url, formData);
};

export const closureClientDeatilsApi = async (clientCode) => {
  const url = `${apiUrl}/v1/details/client-acc-close-details?client_code=${clientCode}`;
  return commonApiRequestNew("GET", url);
};

export const accountOpeningApi = async (values) => {
  const url = `${apiUrl}/v1/inward-entry/acc-opening`;
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }
  return commonApiRequest("POST", url, formData);
};

export const getAccountOpeningCategoryApi = async () => {
  const url = `${apiUrl}/v1/types/inward-sub-category?inward_type=9`;
  return commonApiRequestNew("GET", url);
};
