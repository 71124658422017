import { commonApiRequest } from "utils/CommonFunctions";

export const fetchKycDashBoardApi = (period, tdate) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/dashboard-kyc?period=${period}&t_date=${tdate}`;
  return commonApiRequest("GET", url);
};

// export const fetchKycB2BData = (period, tdate) => {
//   const url = `${process.env.REACT_APP_URL_CRMS}/v1/kyc-b2b?period=${period}&t_date=${tdate}`;
//   return commonApiRequest("GET", url);
// };

export const fetchKycB2BData = (role, code, level, period, tdate) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/kyc-data?kyc_type=BTB&role=${role}&code=${code}&level=${level}&period=${period}&t_date=${tdate}`;
  return commonApiRequest("GET", url);
};

// export const fetchKycB2CData = (role, period, tdate) => {
//   const url = `${process.env.REACT_APP_URL_CRMS}/v1/kyc-b2c?role=${role}&period=$    {period}&t_date=${tdate}`;
//   return commonApiRequest("GET", url);
// };

export const fetchKycB2CData = (role, code, level, period, tdate) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/kyc-data?kyc_type=BTC&role=${role}&code=${code}&level=${level}&period=${period}&t_date=${tdate}`;
  return commonApiRequest("GET", url);
};

export const fetchKycDateWise = (
  branchType,
  branchCode,
  base_type,
  period,
  trade_month
) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/kyc-chart-data?type=${branchType}&code=${branchCode}&base_type=${base_type}&period=${period}&t_date=${trade_month}`;
  return commonApiRequest("GET", url);
};

export const fetchRoleWiseBranchCode = (role, branch_type) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/role-wise-code?role=${role}&type=${branch_type}`;
  return commonApiRequest("GET", url);
};

export const fetchKycDataTypeWise = (value, period, tdate) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/kyc-role-wise-data?type=${value}&period=${period}&t_date=${tdate}`;
  return commonApiRequest("GET", url);
};
