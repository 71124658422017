import React from "react";

import { IconButton } from "@mui/material";

import { getSessionStorage } from "utils/encrpytion";
import { getFirstAndLastInitials, stringToColor } from "utils/CommonFunctions";

export default function index() {
  const myName = getSessionStorage("full_name");
  const avatarColor = stringToColor(myName);
  const initials = getFirstAndLastInitials(myName);
  return (
    <div className="default-chat-window">
      <header className="default-chat-window-header">
        
        <div className="default-chat-window-welcome-message">
          <p style={{ textWrap: "wrap", fontWeight: "600", fontSize: "32px" }}>
            Welcome to Bounce Mail
          </p>
        </div>
        <div className="default-chat-window-profile">
          <h2
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "10px",
            }}
          >
            <IconButton
              id="createNewGroupBtn"
              className="avatar"
              variant="contained"
              style={{
                backgroundColor: avatarColor,
                fontSize: "20px",
                border: "none",
                width: "65px",
                height: "65px",
                outline: "1px solid grey",
                outlineOffset: "5px",
              }}
            >
              {initials}
            </IconButton>
          </h2>
          <h2>{myName}</h2>
        </div>
      </header>
    </div>
  );
}
