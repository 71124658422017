import { getSessionStorage } from "./encrpytion";

export const UtilitesCheck = (module, param) => {
  // const featuresAccessList = getSessionStorage(param)?.split(",");
  const featuresAccessList = getSessionStorage(param)?.split(",");
  const reportAccessList = getSessionStorage(param)?.split(",");
  let accessibleFeatures;

  if (param == "features" && featuresAccessList) {
    accessibleFeatures = module?.filter((feature) =>
      featuresAccessList?.includes(feature?.feature_id?.toString())
    );
  } else if (param == "reports" && featuresAccessList) {
    accessibleFeatures = module?.filter((feature) =>
      reportAccessList?.includes(feature?.report_id?.toString())
    );
  } else {
    accessibleFeatures = [];
  }
  return accessibleFeatures;
};
