/*eslint-disable*/
import React from 'react'

export default function index() {
  return (
    <div>RMS Reports</div>
  )
}


