/*eslint-disable*/
import React, { createContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { copyItem, createFolder, crudGroupp, deleteItem, favoutieDriveData, fetchBase64DataDrive, fetchGroupList, fetchMydrive, folderUploadAttachments, modifyShareAccess, moveItem, renameItem, sharingFileFolder } from 'services/shared-drive/SharedDrive';
import { Base64ToFileDownload, Base64ToUrl, handleCatch } from 'utils/CommonFunctions';
import { sortBy } from 'views/shared-drive/utils';


export const SharedDriveContext = createContext()
export default function SharedDrive(props) {
  const [viewStatus, setViewStatus] = useState(false)
  const [myDriveData, setMyDriveData] = useState([])
  const [sharedFolderData, setSharedFolderData] = useState({ status: "edit", folderId: "" })
  const [driveOrder, setDriveOrder] = useState()
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsId, setSelectedItemsId] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [myGroupDialog, setMygroupDialog] = useState(false)
  const [crudGroup, setCrudGroup] = useState(false)
  const [openFileViewDialog, setFileViewDialog] = useState(false);
  const [fileUrl, setfileUrl] = useState({ url: "", mimetype: "" });
  const [filterObj, setFilterObj] = useState({ asc: true, type: "date" });

  const handleFolderUploadAttachments = async (selectedFiles, folderId, setOpenDailog = () => { }) => {
    console.log('selectedFiles', selectedFiles)
    try {
      var formData = new FormData();
      formData.append("folder_id", folderId ? folderId : "");
      for (var i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i]);
      }
      const response = await folderUploadAttachments(formData);
      const resJson = response.data;
      if (resJson.status === "success") {
        setOpenDailog(false)
        handleFetchMydrive(folderId)
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const handleCreateFolder = async (folderName, parentFolder = "", setOpenDailog) => {
    try {
      const body = {
        folder_name: folderName,
        parent_folder_id: parentFolder ? parentFolder : "",
      };
      const response = await createFolder(body);
      const resJson = response.data;
      if (resJson?.status === "success") {
        handleFetchMydrive(parentFolder, "")
        setOpenDailog(false)
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error)
    }
  }

  const handleFetchMydrive = async (folder_id) => {
    setMyDriveData([])
    let temp = JSON.parse(sessionStorage.getItem("drive-Order"))
    let folder_typee = temp.pop().to.split("/").pop()
    let temp_folder_type = ""
    // let temp_folder_type = folder_type == "" ? "my-drive" : folder_type
    if (folder_typee == "my-drive" || folder_typee == "favourites" || folder_typee == "shared-access") {
      temp_folder_type = folder_typee
    } else {
      temp_folder_type = "my-drive"
    }
    let temp_owner_id =
      (folder_typee == "my-drive" || folder_typee == "shared-access") ? "" : sessionStorage.getItem('folder-owner_id')
    try {
      const paramsData = { folder_type: temp_folder_type };
      const body = { folder_id: folder_id, owner_id: temp_owner_id };
      const response = await fetchMydrive(paramsData, body)
      const resJson = response.data;
      if (resJson?.status === "success") {
        const temp = sortBy(resJson?.data, filterObj.type, filterObj.asc)
        setMyDriveData(temp)
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const handleSharingFileFolder = async (values, participantListArr, groupListArr, handleDialogClose, folder_id) => {
    try {
      const body = {
        shared_user: participantListArr,
        shared_item_id: sharedFolderData.item._id,
        access_type: values.access_type,
        group_list: groupListArr,
      };
      const response = await sharingFileFolder(body)
      const resJson = response?.data;
      if (resJson.status === "success") {
        toast.success(resJson.message);
        handleFetchMydrive(folder_id, "")
        handleDialogClose();
      }
    } catch (error) {
      handleCatch(error)
    }
  };



  const handleCopyItem = async (dest_id) => {
    try {
      const response = await copyItem(selectedItemsId, dest_id)
      const resJson = response?.data;
      if (resJson?.status === "success") {
        handleFetchMydrive(dest_id)
        setSelectedItems([])
        setSelectedItemsId([])
        setSharedFolderData({ status: "edit", folderId: "" })
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const handleMoveItem = async (dest_id) => {
    try {
      const response = await moveItem(selectedItemsId, dest_id)
      const resJson = response?.data;

      if (resJson?.status === "success") {
        toast.success(resJson.message);
        handleFetchMydrive(dest_id, "")
        setSelectedItems([])
        setSelectedItemsId([])
        setSharedFolderData({ status: "edit", folderId: "" })
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const handleRenameItem = async (item_id, new_name, folder_id, setDialogOpen) => {
    try {
      const response = await renameItem(item_id, new_name)
      const resJson = response?.data;

      if (resJson?.status === "success") {
        toast.success(resJson.message);
        handleFetchMydrive(folder_id, "")
        setDialogOpen(false)
      }
    } catch (error) {
      handleCatch(error)
    }
  };


  const handleDeleteItem = async (folder_id, ids) => {
    try {
      let commaSeparatedString = ids.join(',');
      let urlEncodedString = encodeURIComponent(commaSeparatedString);
      const paramsData = { item_id_list: urlEncodedString };
      const response = await deleteItem(paramsData)
      const resJson = response?.data;
      if (resJson?.status === "success") {
        handleFetchMydrive(folder_id, "")
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error)
    }
  };


  const handleCrudGroup = async (values, participantListArr, action, setCrudGroup) => {
    try {
      const response = await crudGroupp(values, participantListArr, action)
      const resJson = response?.data;
      if (resJson.status === "success") {
        if (action == "add" || action == "update") {
          setCrudGroup(false)
        }
        handleFetchGroupList()
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const handleFetchGroupList = async () => {
    try {
      const response = await fetchGroupList()
      const resJson = response?.data;
      if (resJson.status === "success") {
        setGroupList(resJson.data)
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const hanldeFetchBase64DataDrive = async (item, param) => {
    try {
      const response = await fetchBase64DataDrive(item)
      const resJson = response?.data;
      if (resJson.status === "success") {
        if (param == "download") {
          Base64ToFileDownload(resJson.data.file_content, resJson.data.mimetype, resJson.data.file_name)
        } else if (param == "view") {
          const url = Base64ToUrl(resJson.data.file_content, resJson.data.mimetype)
          setfileUrl({
            url: url,
            mimetype: resJson.data.mimetype
          })
        }
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const hanldeModifyShareAccess = async (action, shared_user, shared_item_id, new_access, folder_id) => {
    try {
      const response = await modifyShareAccess(action, shared_user, shared_item_id, new_access)
      const resJson = response?.data;
      if (resJson.status === "success") {
        toast.success(resJson.message);
        handleFetchMydrive(folder_id, "")
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const hanldeFavouriteDriveData = async (item_id, owner_id, action, folder_id) => {
    const paramsData = {
      action: action
    }
    const body = {
      item_id: item_id || "",
      owner_id: owner_id || "",
    };
    try {
      const response = await favoutieDriveData(body, paramsData)
      const resJson = response?.data;
      if (resJson.status === "success") {
        toast.success(resJson.message);
        handleFetchMydrive(folder_id)
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  return (
    <SharedDriveContext.Provider value={{
      driveOrder,
      setDriveOrder,
      viewStatus,
      setViewStatus,
      handleFolderUploadAttachments,
      handleCreateFolder,
      handleFetchMydrive,
      myDriveData,
      sharedFolderData,
      setSharedFolderData,
      handleSharingFileFolder,
      handleCopyItem,
      handleDeleteItem,
      handleMoveItem, selectedItems, setSelectedItems, handleCrudGroup, handleFetchGroupList, groupList, hanldeFetchBase64DataDrive,
      myGroupDialog, setMygroupDialog, selectedItemsId, setSelectedItemsId, crudGroup, setCrudGroup,
      handleRenameItem, openFileViewDialog, setFileViewDialog,
      fileUrl, setfileUrl,
      filterObj, setFilterObj, setMyDriveData, hanldeModifyShareAccess, hanldeFavouriteDriveData
    }}>
      {props.children}
    </SharedDriveContext.Provider>
  )

}
