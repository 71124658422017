/*eslint-disable*/
import React, { useState } from "react";
import axios from "axios";
import { createContext } from "react";
import { toast } from "react-toastify";
import {
  addAssignmentApi,
  addBrandApi,
  addInvoiceApi,
  addItemApi,
  addPoApi,
  addRequest,
  addRequirementApi,
  addVendorDetailsApi,
  adminListApi,
  approvalQuotationApi,
  brandListApi,
  deleteInvoiceApi,
  deleteRequestApi,
  deleteRequirementApi,
  deleteVendorApi,
  gerVendorDetailsApi,
  getAssignmentApi,
  getDeliveryApi,
  getInventoryDataApi,
  getInvoiceDataApi,
  getPoApi,
  getProductApi,
  getQuotationApi,
  getQuotationTableDataApi,
  getRequestApi,
  getRequirementApi,
  requirementApprovalApi,
  upadateVendorApi,
  updateInvoiceApi,
  updateRequestApi,
  updateRequirementApi,
  uploadQuotationApi,
  uploadTransactionApi,
} from "services/itInfra/ItInfraService";
import { handleCatch } from "utils/CommonFunctions";

export const ItinfraContext = createContext();

const ItInfraApi = (props) => {
  const [data, setData] = useState({});
  //########################### Requirement APIs ################################
  const [requirementData, setRequirementData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("my_request");
  const [adminList, setAdminList] = useState([]);

  const handleRequirementForm = async (values, setOpenDailog) => {
    setIsLoading(true);
    try {
      const response = await addRequirementApi(values, setOpenDailog);

      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetRequirementData(selectedTab);
      } else {
        toast.error;
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDailog(false);
      setIsLoading(false);
    }
  };

  const handleGetRequirementData = async (selectedTab) => {
    setIsLoading(true);
    try {
      const response = await getRequirementApi(selectedTab);
      if (response.status === 200) {
        setRequirementData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateRequirement = async (values, setOpenDailog) => {
    setIsLoading(true);
    try {
      const response = await updateRequirementApi(values);

      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetRequirementData(selectedTab);
      } else {
        toast.error;
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      setOpenDailog(false);
    }
  };

  const handleDeleteRequirement = async (rowData) => {
    alert("You Want To Delete This Requirement ????");
    try {
      const response = await deleteRequirementApi(rowData);

      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetRequirementData(selectedTab);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleRequirementApproval = async (
    rowData,
    rejectReason,
    status,
    setOpenDialog
  ) => {
    setIsLoading(true);
    try {
      const response = await requirementApprovalApi(
        rowData,
        rejectReason,
        status,
        setOpenDialog
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetRequirementData(selectedTab);
        setOpenDialog(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdminList = async (value) => {
    try {
      const response = await adminListApi(value);
      if (response.status === 200) {
        setAdminList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //########################### Vendor APIs #####################################
  const [isLoading, setIsLoading] = useState(false); // Use for Loder
  const [vendorDeatils, setVendorDetails] = useState([]);
  const [vendorData, setVendorData] = useState({});

  const handleAddVendor = async (values, setAddVendorDialog) => {
    setIsLoading(true);
    try {
      const response = await addVendorDetailsApi(values);

      if (response.status === 200) {
        toast.success(response.data.message);
        handleVendorDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setAddVendorDialog(false);
      setIsLoading(false);
    }
  };

  const handleVendorDetails = async () => {
    setIsLoading(true);
    try {
      const response = await gerVendorDetailsApi();

      if (response.status === 200) {
        setVendorDetails(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpadateVendor = async (values, setAddVendorDialog) => {
    try {
      const response = await upadateVendorApi(values);

      if (response.status === 200) {
        toast.success(response.data.message);
        handleVendorDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setAddVendorDialog(false);
    }
  };

  const handleDeleteVendor = async (rowData) => {
    alert("You Want To Delete This Vendor ????");
    try {
      const response = await deleteVendorApi(rowData);

      if (response.status === 200) {
        toast.success(response.data.message);
        handleVendorDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //########################### Quotation API #####################################
  const [quotationData, setQuotationData] = useState([]);
  const [allQuotationData, setAllQuotationData] = useState([]);
  const [selectedQuotationTab, setSelectedQuotationTab] =
    useState("all_quatation");

  //convert Base64 string into url for Quotation file
  const loadBase64ToInputField = (base64String) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    let mimeType;
    if (
      byteArray[0] === 0x25 &&
      byteArray[1] === 0x50 &&
      byteArray[2] === 0x44 &&
      byteArray[3] === 0x46
    ) {
      mimeType = "application/pdf";
    } else {
      mimeType = "image/jpeg";
    }
    const blob = new Blob([byteArray], { type: mimeType });
    const fileName =
      "Filename" + (mimeType === "application/pdf" ? ".pdf" : ".jpg");
    const file = new File(
      [blob],
      fileName,
      { type: mimeType, lastModified: new Date().getTime() },
      "utf-8"
    );
    const container = new DataTransfer();
    container.items.add(file);
    return container.files;
  };

  const handleQuotationUpload = async (values, setAddQuotationDailog) => {
    try {
      const response = await uploadQuotationApi(values);

      if (response.status === 200) {
        toast.success(response.data.message);
        setAddQuotationDailog(false);
        handleGetRequirementData(selectedTab);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleGetQuotation = async (requirementID) => {
    setIsLoading(true);
    try {
      const response = await getQuotationApi(requirementID);

      if (response.status === 200) {
        const processedData = response.data.data.map((item) => {
          if (item.file_proof) {
            const files = loadBase64ToInputField(item.file_proof)[0];
            const url = URL.createObjectURL(files);
            console.log("file proof file", url);
            return { ...item, file_proof: url };
          } else {
            return item;
          }
        });
        setQuotationData(processedData);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetAllQuotation = async (selectedQuotationTab) => {
    setIsLoading(true);
    try {
      const response = await getQuotationTableDataApi(selectedQuotationTab);

      if (response.status === 200) {
        const processedData = response.data.data.map((item) => {
          if (item.file_proof) {
            const files = loadBase64ToInputField(item.file_proof)[0];
            const url = URL.createObjectURL(files);
            console.log("file proof file", url);
            return { ...item, file_proof: url };
          } else {
            return item;
          }
        });
        setAllQuotationData(processedData);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleQuotationApproval = async (
    rowData,
    rejectReason,
    status,
    setOpenDialog
  ) => {
    try {
      const response = await approvalQuotationApi(
        rowData,
        rejectReason,
        status,
        setOpenDialog
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetAllQuotation(selectedQuotationTab);
        setOpenDialog(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //########################### Assignment APIs ###########################################
  const [assignmentData, setAssignmentData] = useState([]);

  const handleAssignment = async (values, { resetForm }, setOpenDailog) => {
    setIsLoading(true);
    try {
      const response = await addAssignmentApi(values);

      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      resetForm();
      setIsLoading(false);
      setOpenDailog(false);
    }
  };

  const handleGetAssignmentList = async () => {
    setIsLoading(true);
    try {
      const response = await getAssignmentApi();

      if (response.status === 200) {
        setAssignmentData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(
        "response" in error
          ? error.response.data.message
          : "Internal Server Error, Try Again Later"
      );
    } finally {
      setIsLoading(false);
    }
  };

  //######################### Purchase Order APIS ##########################################
  const [poData, setPoData] = useState([]);

  const handleAddPodata = async (values, setPurchaseOrderDialog) => {
    try {
      const response = await addPoApi(values);

      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setPurchaseOrderDialog(false);
    }
  };

  const handleGetPoData = async (type) => {
    setIsLoading(true);
    try {
      const response = await getPoApi(type);

      if (response.status === 200) {
        const processedData = response.data.data.map((item) => {
          if (item.po_file) {
            const files = loadBase64ToInputField(item.po_file)[0];
            const url = URL.createObjectURL(files);

            const debitFile = loadBase64ToInputField(item.debit_note)[0];
            const debitUrl = URL.createObjectURL(debitFile);
            return { ...item, po_file: url, debit_note: debitUrl };
          } else {
            return item;
          }
        });
        setPoData(processedData);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  //################################# Invoice APIS ##########################################
  const [invoiceData, setInvoiceData] = useState([]);

  const handleGetInvoiceData = async (type) => {
    setIsLoading(true);
    try {
      const response = await getInvoiceDataApi(type);

      if (response.status === 200) {
        const processedData = response.data.data.map((item) => {
          if (item.invoice_file) {
            const files = loadBase64ToInputField(item.invoice_file)[0];
            const url = URL.createObjectURL(files);
            return { ...item, invoice_file: url };
          } else {
            return item;
          }
        });
        setInvoiceData(processedData);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddInvoice = async (values, setAddInvoiceDialog) => {
    try {
      const response = await addInvoiceApi(values);

      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetInvoiceData("all_invoice");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setAddInvoiceDialog(false);
    }
  };

  const handleGetPoDetails = async (PoNumber, setFieldValue) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ITINFRA}/v1/Invoice_details/Invoice_details`,
        {
          po_no: PoNumber,
        },
        {
          headers: {
            accept: "application/json",
            authToken: localStorage.authToken,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) {
        const Podata = response.data.data;
        setFieldValue("vendor", Podata.name);
        setFieldValue("quotation_id", Podata.ap_q_ID);
        setFieldValue("transaction_id", Podata.transaction_id);
        setFieldValue("total_amount", Podata.total_amount);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateInvoice = async (values, setAddInvoiceDialog) => {
    try {
      const response = await updateInvoiceApi(values);

      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetInvoiceData("all_invoice");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setAddInvoiceDialog(false);
    }
  };

  const handleDeleteInvoice = async (rowData) => {
    alert("You Want To Delete This Invoice ????");
    try {
      const response = await deleteInvoiceApi(rowData);

      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetInvoiceData("all_invoice");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //############################### Transaction APIS ###########################################
  const handleuploadTransaction = async (values, setTransactionDialog) => {
    try {
      const response = await uploadTransactionApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetPoData("pending_transaction");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setTransactionDialog(false);
    }
  };

  //####################### get product & Brand ################################################
  const [productList, setProductList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const handleGetProduct = async () => {
    try {
      const response = await getProductApi();
      if (response.status === 200) {
        setProductList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleBrandList = async () => {
    try {
      const response = await brandListApi();
      if (response.status === 200) {
        setBrandList(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //####################### Inventory APIS #####################################################
  const [quantityData, setQuantityData] = useState([]);
  const handleGetInventoryData = async (productName, quantityType) => {
    try {
      const response = await getInventoryDataApi(productName, quantityType);

      if (response.status === 200) {
        setQuantityData(response.data.data.Data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //######################### Delivery api#####################################################
  const handleGetDelivery = async (InvoiceNumber) => {
    alert("Product Received??");
    try {
      const response = await getDeliveryApi(InvoiceNumber);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetInvoiceData("pending_delivery");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleAddItem = async (values, setAddItem) => {
    console.log("Form submitted with values:", values);
    try {
      const response = await addItemApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetInvoiceData("complete_delivery");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setAddItem(false);
    }
  };

  //################################### Request APis #########################################
  const [requestData, setRequestData] = useState([]);
  const handleGetRequestData = async (type) => {
    try {
      const response = await getRequestApi(type);
      if (response.status === 200) {
        setRequestData(response.data.data);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleAddRequest = async (values, setOpenDailog) => {
    try {
      const response = await addRequest(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetRequestData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleUpdateRequest = async (values, setOpenDailog) => {
    try {
      const response = await updateRequestApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetRequestData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleDeleteRequest = async (rowData) => {
    alert("Want To Delete Request??");
    try {
      const response = await deleteRequestApi(rowData);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetRequestData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //############################### Dashboard Apis ############################################
  const handleAddBrand = async (values, setBrandDialog) => {
    try {
      const response = await addBrandApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setBrandDialog(false);
    }
  };

  return (
    <ItinfraContext.Provider
      value={{
        setData,
        data,
        isLoading,

        handleAddVendor,
        handleVendorDetails,
        handleUpadateVendor,
        handleDeleteVendor,
        vendorDeatils,
        vendorData,
        setSelectedTab,
        selectedTab,
        setVendorData,

        handleRequirementForm,
        handleGetRequirementData,
        handleUpdateRequirement,
        handleDeleteRequirement,
        handleRequirementApproval,
        requirementData,
        handleAdminList,
        adminList,

        handleQuotationUpload,
        handleGetQuotation,
        quotationData,
        handleGetAllQuotation,
        allQuotationData,
        setSelectedQuotationTab,
        selectedQuotationTab,
        handleQuotationApproval,

        handleAddPodata,
        handleGetPoData,
        poData,

        handleAssignment,
        handleGetAssignmentList,
        assignmentData,

        handleuploadTransaction,

        handleGetPoDetails,
        handleAddInvoice,
        handleUpdateInvoice,
        handleGetInvoiceData,
        handleDeleteInvoice,
        invoiceData,

        handleGetProduct,
        productList,
        handleBrandList,
        brandList,
        quantityData,
        handleGetInventoryData,
        handleGetDelivery,
        handleAddItem,

        handleAddRequest,
        handleGetRequestData,
        requestData,
        handleUpdateRequest,
        handleDeleteRequest,

        handleAddBrand,
      }}
    >
      {props.children}
    </ItinfraContext.Provider>
  );
};

export default ItInfraApi;
