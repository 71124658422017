/*eslint-disable*/
import React from "react";

import EventReportsData from "config-details/reports/EventReportsData";
import CommonReportsIndex from "views/utilities/common-components/CommonReportsIndex";

const EventReports = () => {

  return (
    <CommonReportsIndex allReports={EventReportsData} />
  );
};

export default EventReports;
