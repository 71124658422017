import { commonApiRequest } from "utils/CommonFunctions";

export const fetchRevenueData = (period, tdate) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/dashboard-revenue?period=${period}&t_date=${tdate}`;
  return commonApiRequest("GET", url);
};

// export const fetchRevenueB2BWise = (period, tdate) => {
//   const url = `${process.env.REACT_APP_URL_CRMS}/v1/revenue-b2b?period=${period}&t_date=${tdate}`;
//   return commonApiRequest("GET", url);
// };

// export const fetchRevenueB2CWise = (role, period, tdate) => {
//   const url = `${process.env.REACT_APP_URL_CRMS}/v1/revenue-b2c?period=${period}&role=${role}&t_date=${tdate}`;
//   return commonApiRequest("GET", url);
// };


export const fetchRevenueB2BWise = (role, code, level, period, tdate) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/revenue-data?revenue_type=BTB&role=${role}&code=${code}&level=${level}&period=${period}&t_date=${tdate}`;
  return commonApiRequest("GET", url);
};


export const fetchRevenueB2CWise = (role, code, level, period, tdate) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/revenue-data?revenue_type=BTC&role=${role}&code=${code}&level=${level}&period=${period}&t_date=${tdate}`;
  return commonApiRequest("GET", url);
};

export const fetchRevenueChartData = (
  branchType,
  branchCode,
  base_type,
  period,
  tdate
) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/revenue-chart-data?type=${branchType}&code=${branchCode}&base_type=${base_type}&period=${period}&t_date=${tdate}`;
  return commonApiRequest("GET", url);
};

export const fetchRevenueSegmentWise = (period, tdate) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/revenue-segment-wise?period=${period}&t_date=${tdate}`;
  return commonApiRequest("GET", url);
};

export const fetchRevenueDataStatusWise = (status, period, tdate) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/revenue-status-wise?status=${status}&period=${period}&t_date=${tdate}`;
  return commonApiRequest("GET", url);
};
