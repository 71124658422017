/*eslint-disable*/
import { useState, useEffect, useContext } from "react";

import { useTheme } from "@mui/material/styles";
import {
  Badge,
  Drawer,
  Fab,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import ChatIcon from '@mui/icons-material/Chat';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import "styles/chat-css/chatMain.css";
import { ChatContext } from "context/chat-app/ChatApis";
import { LoginContext } from "context/login/LoginApi";
import { getSessionStorage } from "utils/encrpytion";
import ContactsBar from "views/utilities/chat-app/main-components/ContactsBar";
import MessageBar from "views/utilities/chat-app/main-components/MessageBar";
import GroupDialogComp from "views/utilities/chat-app/main-components/GroupDialogComp";
import chatNotificationSound from "assets/audio/ting_chat.mp3"
import { useWebSocket } from "context/web-socket/WebSocketMain";


const ChatShortcut = () => {
  const theme = useTheme();

  const { fetchChatList, fetchEmployeesList, chatList, chatHistory, setChatHistory, setChatList, setTotalUnseenMessages, totalUnseenMessages, openGroupDialog, setOpenGroupDialog, contactList, editDialog } = useContext(ChatContext);

  const { socket } = useWebSocket();

  const { login } = useContext(LoginContext);

  const user_id = getSessionStorage("UID");

  // total unseen msg counter function for default value when value is null
  const totalUnseenMessagesDefault = chatList?.reduce((sum, chat) => sum + chat.UnseenMessageCount, 0);

  // drawer on/off
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // notification (under development)

  // const sendNotification = (message, user) => {
  //   console.log('message', message, user);

  //   // Send the notification regardless of page visibility
  //   const notification = new Notification("New message from Open Chat", {
  //     icon: "https://cdn-icons-png.flaticon.com/512/733/733585.png",
  //     body: `@${user}: ${message}`,
  //   });

  //   notification.onclick = () => {
  //     window.open("http://192.168.119.186:3007/utilities/chat/");
  //   };
  // };

  // const checkPageStatus = (message, user) => {
  //   if (!("Notification" in window)) {
  //     console.log('first');
  //     alert("This browser does not support system notifications!");
  //   } else if (Notification.permission === "granted") {
  //     console.log('second');

  //     // Send the notification if permission is already granted
  //     sendNotification(message, user);
  //   } else if (Notification.permission === "denied") {
  //     console.log('fourth');
  //     alert("You've denied notification permissions. Please enable them in browser settings.");
  //   } else {
  //     console.log('third');

  //     // Request permission if not denied
  //     Notification.requestPermission((permission) => {
  //       if (permission === "granted") {
  //         sendNotification(message, user);
  //       }
  //     });
  //   }
  // };


  //------------------ web socket code starts here -------------
  useEffect(() => {
    if (socket && login && user_id) {

      socket.onmessage = (event) => {
        // console.log("Received message through WebSocket in chat shortcut");
        const currChatId = getSessionStorage("currChatId")

        const jsonData = JSON.parse(event.data);

        // console.log('jsonData in chat shortcut', jsonData)

        if (currChatId == jsonData?.chat_id && jsonData?.socket_msg_type === "message") {
          setChatHistory((prev) => [jsonData, ...prev]);
        } else {
          setChatList((prev) => {
            const updatedChatList = prev.map((item) => {
              if (jsonData.chat_id == item.chat_id) {
                return {
                  ...item,
                  UnseenMessageCount: item.UnseenMessageCount + 1,
                };
              } else {
                return item;
              }
            });

            const totalUnseen = updatedChatList.reduce(
              (sum, chat) => sum + chat.UnseenMessageCount,
              0
            );

            setTotalUnseenMessages(totalUnseen);

            if (jsonData?.socket_msg_type === "message") {
              // notification sound
              const pingSound = new Audio(chatNotificationSound);
              console.log('pingsound in Login', jsonData)
              pingSound?.play();

              // notification (under development)

              // checkPageStatus(jsonData?.message_text, jsonData?.created_by);
              // checkPageStatus("Hello, how are you?", "JohnDoe");

            }

            return updatedChatList;
          });
        }
      };

      // Clean up the WebSocket connection on component unmount
      // return () => {
      //   console.log("WebSocket connection closed");
      //   socket.close();
      // };
    }
  }, [socket, login, user_id]);

  //------------------- web socket code ends here ---------------

  return (

    <>
      {login &&
        <Tooltip title="Recent Messages" >
          <Fab
            component="div"
            onClick={handleToggle}
            size="medium"
            variant="circular"
            sx={{
              borderRadius: 0,
              borderTopLeftRadius: "50%",
              borderBottomLeftRadius: "50%",
              borderTopRightRadius: "50%",
              borderBottomRightRadius: "4px",
              top: "30%",
              position: "fixed",
              right: 10,
              zIndex: theme.zIndex.speedDial,
              backgroundColor: "#0E3935",
              '&:hover': {
                backgroundColor: "#0E3935",
              }
            }}
          >
            <IconButton size="large" sx={{ backgroundColor: "#0E3935" }} color="primary">
              <Badge badgeContent={totalUnseenMessages ? totalUnseenMessages : totalUnseenMessagesDefault} color="error" onClick={handleToggle}>
                <ChatIcon sx={{ color: "white" }} />
              </Badge>
            </IconButton>
          </Fab>
        </Tooltip>}

      <Drawer
        anchor="right"
        onClose={handleToggle}
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: 280,
          },
        }}
      >
        <PerfectScrollbar component="div">
          <Grid container sx={{ pl: 1, mt: 2 }}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Tabs value={value} onChange={handleChangeTab}>
                <Tab value={0} title="Messages" icon={<ChatIcon color="info" />} aria-label="Messages" />
                <Tab value={1} title="Contacts" icon={<ContactMailIcon color="info" />} aria-label="Contacts" />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              {value === 1 ?
                <ContactsBar setDrawerOpen={setDrawerOpen} />
                :
                <MessageBar setDrawerOpen={setDrawerOpen} />
              }
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Drawer>

      {/* New Group creation dialog */}
      {openGroupDialog && <GroupDialogComp openGroupDialog={openGroupDialog} setOpenGroupDialog={setOpenGroupDialog} contactList={contactList} />}
    </>
  );
};

export default ChatShortcut;
