import { commonApiRequest } from "utils/CommonFunctions";

const REACT_APP_URL_TRAVEL_MANAGEMENT =
  process.env.REACT_APP_URL_TRAVEL_MANAGEMENT;

export const requestAddUpdateApi = (formdata, data) => {
  const url = `${REACT_APP_URL_TRAVEL_MANAGEMENT}/V1/request_add_update?action=${
    data.request_id ? "update" : "add"
  }`;
  return commonApiRequest("POST", url, formdata);
};

export const requestDeleteApi = (Req_ID) => {
  const url = `${REACT_APP_URL_TRAVEL_MANAGEMENT}/V1/request_delete`;
  return commonApiRequest("DELETE", url, null, { request_id: Req_ID });
};

export const getRequestApi = () => {
  const url = `${REACT_APP_URL_TRAVEL_MANAGEMENT}/V1/request_get`;
  return commonApiRequest("GET", url);
};

export const getAllRequestApi = (ApprovalStatus) => {
  const url = `${REACT_APP_URL_TRAVEL_MANAGEMENT}/V1/request_get_all?status_type=${ApprovalStatus}`;
  return commonApiRequest("GET", url);
};

export const requestActionApi = (action, body) => {
  const url = `${REACT_APP_URL_TRAVEL_MANAGEMENT}/V1/request_approval?action=${action}`;
  return commonApiRequest("POST", url, body);
};

export const addTicketDetailsApi = (formdata) => {
  const url = `${REACT_APP_URL_TRAVEL_MANAGEMENT}/V1/ticket_details_add_update`;
  return commonApiRequest("POST", url, formdata);
};

export const TMDashboardApi = (value) => {
  const url = `${REACT_APP_URL_TRAVEL_MANAGEMENT}/V1/dashboard_get`;
  return commonApiRequest("POST", url, value);
};

export const dorpDownApi = async () => {
  const url = `${process.env.REACT_APP_URL_TRAVEL_MANAGEMENT}/V1/request_dropdown_get`;
  return commonApiRequest("GET", url);
};
