import { IconHelp, IconListDetails, IconBrandProducthunt, IconTargetArrow, IconSubtask } from "@tabler/icons";


const icons = { IconHelp, IconListDetails, IconBrandProducthunt, IconTargetArrow, IconSubtask };

const PmsMenuItems = {
  id: "pms",
  type: "group",
  children: [
    {
      id: "authentication",
      title: "PMS",
      type: "collapse",
      icon: icons.IconListDetails,
      children: [
        {
          id: "myTask",
          title: "Dashboard",
          type: "item",
          url: "/pms/dashboard",
          icon: icons.IconListDetails
        },
        {
          id: "myTask4",
          title: "My Tasks",
          type: "item",
          url: "/pms/my-task",
          icon: icons.IconSubtask
        },
        {
          id: "myTask2",
          title: "Projects",
          type: "item",
          url: "/pms/projects",
          icon: icons.IconBrandProducthunt

        },
        {
          id: "myTask3",
          title: "Target",
          type: "item",
          url: "/pms/target",
          icon: icons.IconTargetArrow

        },
      ],
    },
  ],
};

export default PmsMenuItems;
