import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import CtclHistoryReport from "views/utilities/ctcl/reports/ctcl/CtclHistoryReport";
import MisMatchReport from "views/utilities/ctcl/reports/ctcl/MisMatchReport";

const CtclHome = Loadable(lazy(() => import("views/utilities/ctcl/dashboard")));
const CtclIndex = Loadable(lazy(() => import("views/utilities/ctcl/index")));

const CtclUtilities = Loadable(
  lazy(() => import("views/utilities/ctcl/utils"))
);

const CtclReports = Loadable(
  lazy(() => import("views/utilities/ctcl/reports/index"))
);

const CTCL = Loadable(
  lazy(() => import("views/utilities/ctcl/utils/ctcl/index"))
);
const Neat = Loadable(
  lazy(() => import("views/utilities/ctcl/utils/neat/index"))
);
const Certificate = Loadable(
  lazy(() => import("views/utilities/ctcl/utils/certificate/index"))
);
const Branch = Loadable(
  lazy(() => import("views/utilities/ctcl/utils/branch/index"))
);
const TradeUpload = Loadable(
  lazy(() => import("views/utilities/ctcl/utils/trade-upload/index"))
);

//Audit Srction
const FileUpload = Loadable(
  lazy(() => import("views/utilities/ctcl/utils/file-upload/index"))
);

//Audit Srction
const Audit = Loadable(
  lazy(() => import("views/utilities/ctcl/utils/audit/index"))
);
const AddAudit = Loadable(
  lazy(() => import("views/utilities/ctcl/utils/audit/AddAudit"))
);
const ViewAudit = Loadable(
  lazy(() => import("views/utilities/ctcl/utils/audit/ViewAudit"))
);
const CTCLReport = Loadable(
  lazy(() => import("views/utilities/ctcl/reports/ctcl/index"))
);
const AuditReport = Loadable(
  lazy(() => import("views/utilities/ctcl/reports/audit/index"))
);

const CtclAuditReport = Loadable(
  lazy(() => import("views/utilities/ctcl/reports/audit/AuditedReport"))
);

const CtclUnAuditReport = Loadable(
  lazy(() => import("views/utilities/ctcl/reports/audit/UnAuditedReport"))
);
const AddCtcl = Loadable(
  lazy(() => import("views/utilities/ctcl/utils/ctcl/add/AddCtcl"))
);
const ViewCtcl = Loadable(
  lazy(() => import("views/utilities/ctcl/utils/ctcl/view/ViewCtcl"))
);

export const ctclModule = {
  path: "ctcl",
  element: <CtclIndex />,
  children: [
    {
      path: "home",
      element: <CtclHome />,
    },
    {
      path: "utils",
      element: <CtclUtilities />,
    },
    {
      path: "reports",
      element: <CtclReports />,
    },
  ],
};

export const CtclUtilsRoutes = {
  path: "ctcl",
  children: [
    {
      path: "ctcl",
      element: <CTCL />,
    },
    {
      path: "neat",
      element: <Neat />,
    },
    {
      path: "certificate",
      element: <Certificate />,
    },
    {
      path: "audit",
      element: <Audit />,
    },
    {
      path: "branch",
      element: <Branch />,
    },
    {
      path: "upload",
      element: <FileUpload />,
    },
    {
      path: "trade-upload",
      element: <TradeUpload />,
    },
    {
      path: "add-ctcl",
      element: <AddCtcl />,
    },
    {
      path: "view-ctcl",
      element: <ViewCtcl />,
    },

    {
      path: "ctcl-report",
      element: <CTCLReport />,
    },

    {
      path: "audit-report",
      element: <AuditReport />,
    },
    {
      path: "ctcl-audit-report",
      element: <CtclAuditReport />,
    },
    {
      path: "ctcl-unaudit-report",
      element: <CtclUnAuditReport />,
    },
    {
      path: "/ctcl/mis-match-report",
      element: <MisMatchReport />,
    },
    {
      path: "/ctcl/ctcl-history-report",
      element: <CtclHistoryReport />,
    },
  ],
};

export const AuditUtilsRoutes = {
  path: "audit",
  children: [
    {
      path: "add",
      element: <AddAudit />,
    },
    {
      path: "view",
      element: <ViewAudit />,
    },
  ],
};

// export const AuditReportRoutes = {
//   path: "audit",
//   children: [
//     {
//       path: "view",
//       element: <ViewAudit />
//     }
//   ]
// };
