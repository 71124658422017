/*eslint-disable*/

export const handleFirstSessionStorage = (pathEnd, label, handleFetchMydrive = () => { }, setDriveOrder = () => { }) => {
  const fullPath = `/shared-drive/home/${pathEnd}`
  sessionStorage.setItem("drive-Order", JSON.stringify([{ label: label, to: fullPath, }]));
  sessionStorage.setItem('folder-owner_id', "")
  setDriveOrder([{ label: label, to: fullPath }])
  handleFetchMydrive("")
}

export const handleItemClick = (e, item, type, setSelectedItems) => {
  e.stopPropagation();
  const isCtrlPressed = e.ctrlKey;
  const itemKey = `${type}-${item._id}`; // Unique key for each item
  if (isCtrlPressed) {
    setSelectedItems((prev) =>
      prev.includes(itemKey)
        ? prev.filter((i) => i !== itemKey)
        : [...prev, itemKey]
    );
  } else {
    setSelectedItems([itemKey]);
  }
};

export const handleOutsideClick = (setSelectedItems) => {
  setSelectedItems([]);
};


export const sortBy = (data, type, asc = false) => {
  const sortOrder = asc ? 1 : -1;
  const sortFunction = (a, b) => {
    if (type === 'date') {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return (dateA - dateB) * sortOrder;
    } else if (type === 'name') {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1 * sortOrder;
      if (nameA > nameB) return 1 * sortOrder;
      return 0;
    }
  };

  const sortedFolders = data?.folders ? [...data?.folders].sort(sortFunction) : []
  const sortedFiles = data?.files ? [...data?.files].sort(sortFunction) : []

  return {
    folders: sortedFolders,
    files: sortedFiles
  };
};

export const handleChangeFilter = (e, setFilterObj) => {
  setFilterObj((prev) => ({ ...prev, type: e.target.value }))
}
export const handleChangeSort = (param, setFilterObj) => {
  setFilterObj((prev) => ({ ...prev, asc: param }))
}