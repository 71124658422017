import { FILE } from "config";

const EventReportsData = [
  {
    app_id: 30,
    report_id: 30001,
    report_name: "event-applications",
    type: FILE,
    subfolder: [],
    label: "Application",
    path: "/event/applications"
  }
];

export default EventReportsData;
