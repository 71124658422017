import { commonApiRequest, } from "utils/CommonFunctions";

const REACT_APP_URL_IPO = process.env.REACT_APP_URL_IPO

export const addData = (body) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/upload-series-data?source=erp`;
  return commonApiRequest('POST', url, body, null);
};

export const addSMEIpoSeries = (formData, paramsData) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/manipulate-sme-ipo-series`;
  return commonApiRequest('POST', url, formData, paramsData);
};

export const fetchData = (paramsData) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/ipo-series-details?source=erp`;
  return commonApiRequest('GET', url, null, paramsData);
};

export const fetchClientDetails = (paramsData) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/fetch-client-details?source=erp`;
  return commonApiRequest('GET', url, null, paramsData);
};

export const fetchIpoDetails = (body) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/get-ipo-details?source=erp`;
  return commonApiRequest('POST', url, body);
};

export const fetchIpoBidSubmit = (data, paramsData) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/bid/bidSubmit?source=erp`;
  return commonApiRequest('POST', url, data, paramsData, "blob");
};

export const fetchClientList = (paramsData) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/get-client-ids?source=erp`;
  return commonApiRequest('GET', url, null, paramsData);
};

export const uploadPdfFile = (formData, paramsData) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/ipo-file-upload?source=erp`;
  return commonApiRequest('POST', url, formData, paramsData);
};

export const fetchUploadFileDetails = (paramsData) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/get-file-upload-details?source=erp`;
  return commonApiRequest('GET', url, null, paramsData);
};

export const fetchDownloadCsvFile = (paramsData) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/sample-file-physical-csv?source=erp`;
  return commonApiRequest('GET', url, null, paramsData, 'blob');
};

export const fetchPhysicalPrint = (formData, paramsData) => {
  const url = `${process.env.REACT_APP_URL_IPO_V2}/ipo/v2/get-ipo-physical-pdf?source=erp`;
  // const url = `http://192.168.102.166:3009/ipo/v2/get-ipo-physical-pdf?source=erp`;

  return commonApiRequest('POST', url, formData, paramsData);
};
export const downloadPhysicalFile = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_IPO}/ipo/v1/get-ipo-physical-zip-file?source=erp`;
  return commonApiRequest('GET', url, null, paramsData);
};
export const handleUploadOnlineBulkBidApi = (formData) => {
  const url = `${process.env.REACT_APP_URL_IPO}/ipo/v1/bid/bulk-applications?source=erp`;
  return commonApiRequest('POST', url, formData, null);
};

export const fetchPreferenceDetails = () => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/admin/get-preference?source=erp`;
  return commonApiRequest('GET', url);
};

export const updatePreferenceDetails = (data) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/admin/update-preference?source=erp`;
  return commonApiRequest('PUT', url, data);
};

export const verifySeries = (data) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/admin/verify-series?source=erp`;
  return commonApiRequest('PUT', url, data);
};

export const verifyPdf = (data) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/admin/verify-pdf?source=erp`;
  return commonApiRequest('PUT', url, data);
};


export const fetchPdfViewVerification = (data) => {
  // const url = `http://192.168.102.166:3009/ipo/v2/admin/get-ipo-pdf-file?source=erp`;
  const url = `${process.env.REACT_APP_URL_IPO_V2}/ipo/v2/admin/get-ipo-pdf-file?source=erp`;
  return commonApiRequest('POST', url, data, null, 'blob');
};


export const fetchReportData = (requestData) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/get-bid-Report?source=erp`;
  return commonApiRequest('POST', url, requestData);
};

export const updateMandateData = (paramsData) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/update-ipo-mandate-status?source=erp`;
  return commonApiRequest('PUT', url, null, paramsData);
};

export const fetchAppReportData = (paramsData) => {
  const url = `${REACT_APP_URL_IPO}/ipo/v1/get-ipo-series-report?source=erp`;
  return commonApiRequest('GET', url, null, paramsData);
};

export const fetchCsvSampleFile = () => {
  const url = `${process.env.REACT_APP_URL_IPO}/ipo/v1/get-sme-series-sample-file?source=erp`;
  return commonApiRequest('GET', url);
};

export const updateMasterData = () => {
  const url = `${process.env.REACT_APP_URL_IPO}/ipo/v1/update-ipo-master-data?source=erp`;
  return commonApiRequest('PUT', url);
};
export const modifyApplicationBid = (body) => {
  const url = `${process.env.REACT_APP_URL_IPO}/ipo/v1/bid/bid-modification?source=erp`;
  return commonApiRequest('PUT', url, body);
};


export const fetchCurrentIpoMandateDetails = () => {
  const url = `${process.env.REACT_APP_URL_IPO}/ipo/v1/get-current-ipo-mandate-details?source=erp`;
  return commonApiRequest('GET', url);
}


export const fetchCurrentIpoDetails = () => {
  const url = `${process.env.REACT_APP_URL_IPO}/ipo/v1/get-current-ipo-bid-details?source=erp`;
  return commonApiRequest('GET', url);
};

export const fetchPhysicalPrintReportData = (data) => {
  const url = `${process.env.REACT_APP_URL_IPO}/ipo/v1/get-ipo-pdf-logs-report?source=erp`;
  return commonApiRequest('POST', url, data);
};

export const fetchWebData = (param) => {
  const url = `${process.env.REACT_APP_URL_IPO}/ipo/v1/arham-web/get-ipo-data?condition=${param}&source=erp`;
  return commonApiRequest('GET', url);
};
export const updateFetchData = (data) => {
  const url = `${process.env.REACT_APP_URL_IPO}/ipo/v1/arham-web/update-ipo-data?source=erp`;
  return commonApiRequest('PUT', url, data);
};