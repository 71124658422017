/*eslint-disable*/
import React, { createContext, useContext, useState } from 'react';
import { getSessionStorage } from 'utils/encrpytion';

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const employeeId = getSessionStorage('UID')
    // console.log('socket in context', socket);

    // useEffect(() => {
    //     if (employeeId && socket === null) {
    //         const newSocket = new WebSocket(
    //             `${process.env.REACT_APP_URL_CHAT_SOCKET}/ws/${employeeId}`
    //         );
    //         console.log('newSocket in useeffect', newSocket)
    //         setSocket(newSocket);
    //     }
    // }, []);

    return (
        <SocketContext.Provider value={{ socket, setSocket }}>
            {children}
        </SocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(SocketContext);
