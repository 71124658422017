import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import ComplianceDash from "views/utilities/compliance/dashboard";
import ComplianceUtils from "views/utilities/compliance/utils";
import ComplianceReports from "views/utilities/compliance/reports";

// Dashboard
const ComplianceHome = Loadable(
  lazy(() => import("views/utilities/compliance"))
);

// features
const ComplianceUccMismatch = Loadable(
  lazy(() => import("views/utilities/compliance/utils/ucc-mismatch"))
);
const ComplianceCceMismatch = Loadable(
  lazy(() => import("views/utilities/compliance/utils/cce-mismatch"))
);
const ComplianceSegregation = Loadable(
  lazy(() => import("views/utilities/compliance/utils/segregation"))
);
const ComplianceAgts = Loadable(
  lazy(() => import("views/utilities/compliance/utils/agts"))
);
const ComplianceSurveillance = Loadable(
  lazy(() => import("views/utilities/compliance/utils/surveillance"))
);

const ComplianceDormat = Loadable(
  lazy(() => import("views/utilities/compliance/utils/dormat-inactive"))
);

// reports
const ComplianceCce = Loadable(
  lazy(() => import("views/utilities/compliance/reports/cce-reports"))
);
const ComplianceUcc = Loadable(
  lazy(() => import("views/utilities/compliance/reports/ucc-reports"))
);



export const ComplianceModule = {
  path: "compliance",
  element: <ComplianceHome />,
  children: [
    {
      path: "home",
      element: <ComplianceDash />,
    },
    {
      path: "utils",
      element: <ComplianceUtils />,
    },
    {
      path: "reports",
      element: <ComplianceReports />,
    },
  ],
};

export const ComplianceRoutes = {
  path: "compliance",
  children: [
    {
      path: "ucc-mismatch",
      element: <ComplianceUccMismatch />,
    },
    {
      path: "cce-mismatch",
      element: <ComplianceCceMismatch />,
    },
    {
      path: "cce-reports",
      element: <ComplianceCce />,
    },
    {
      path: "ucc-reports",
      element: <ComplianceUcc />,
    },
    {
      path: "segregation",
      element: <ComplianceSegregation />,
    },
    {
      path: "agts",
      element: <ComplianceAgts />,
    },
    {
      path: "surveillance",
      element: <ComplianceSurveillance />,
    },
    {
      path: "dormat",
      element: <ComplianceDormat />,
    }
  ],
};

