/* eslint-disable */
import React, { useEffect, useState, createContext } from "react";
import { toast } from "react-toastify";
import {
  AtomPayFileLogs,
  AtomPayFileUpload,
} from "services/accounts/AccountsService";
import { handleCatch } from "utils/CommonFunctions";

export const AccountsContext = createContext();

export default function AccountsApis({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleAtomPayFileUpload = async (types, file) => {
    setIsLoading(true);
    try {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const formData = new FormData();
      formData.append("approval_file", file);
      const params = { types };
      const response = await AtomPayFileUpload(formData, params);
      if (response.status === 200) {
        toast.success("file uploaded successfully");
        if (response.data) {
          const blob = new Blob([response.data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${types}_${formattedDate}.csv`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAtomPayFileLogs = async (types, log_type) => {
    setIsLoading(true);
    try {
      const params = { types, log_type };
      const response = await AtomPayFileLogs(params);
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AccountsContext.Provider
      value={{
        isLoading,
        handleAtomPayFileUpload,
        handleAtomPayFileLogs,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
}
