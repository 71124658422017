/*eslint-disable*/
import axios from "axios";
import { commonApiRequest } from "utils/CommonFunctions";

const apiUrl = process.env.REACT_APP_ITINFRA;

//Requirement
export const addRequirementApi = async (values) => {
  const url = `${apiUrl}/v1/requirements/requirement-add`;
  const body = {
    product: values.product,
    approx_budget: values.approx_budget,
    purpose: values.purpose,
    request_to: values.request_to,
    description: values.description,
    quantity: values.quantity,
    department_id: values.department,
  };

  return commonApiRequest("POST", url, body);
};

export const getRequirementApi = async (selectedTab) => {
  const response = await axios.get(
    `${apiUrl}/v1/requirements/requirement-data?process=${selectedTab}`,
    {
      headers: {
        accept: "application/json",
        authToken: localStorage.authToken,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const requirementApprovalApi = async (
  rowData,
  rejectReason,
  status,
  setOpenDialog
) => {
  const url = `${process.env.REACT_APP_ITINFRA}/v1/Requirement_Form/Require_Approve?process=${status}`;
  const body = {
    ID: rowData.ID,
    reason: rejectReason,
  };

  return commonApiRequest("POST", url, body);
};

export const updateRequirementApi = async (values) => {
  const url = `${apiUrl}/v1/requirements/requirement-update`;
  const body = {
    ID: values.req_id,
    product: values.product,
    approx_budget: values.approx_budget,
    purpose: values.purpose,
    request_to: values.request_to,
    description: values.description,
    quantity: values.quantity,
    department_id: values.department,
  };
  return commonApiRequest("PUT", url, body);
};

export const deleteRequirementApi = async (rowData) => {
  const url = `${apiUrl}/v1/requirements/requirement-delete?req_id=${rowData.ID}`;
  return commonApiRequest("DELETE", url);
};

export const adminListApi = async (value) => {
  const url = `${apiUrl}/v1/requirements/requirement-send-to?amount=${value}`;
  return commonApiRequest("GET", url);
};

//Vendor
export const addVendorDetailsApi = async (values) => {
  const url = `${apiUrl}/v1/vendor/vendor-add`;
  const body = {
    name: values.vendor_name,
    mobile_no: values.contact_number,
    email: values.email_id,
    gst_no: values.gst_number,
    address: values.vendor_address,
    pan_no: values.pan_number,
    bank_acc_no: values.account_number,
    ifsc_code: values.ifsc_code,
    micr_code: values.micr_code,
  };
  return commonApiRequest("POST", url, body);
};

export const gerVendorDetailsApi = async () => {
  const url = `${apiUrl}/v1/vendor/vendor-data`;
  return commonApiRequest("GET", url);
};

export const upadateVendorApi = async (values) => {
  const url = `${apiUrl}/v1/vendor/vendor-update`;
  const body = {
    vendor_id: values.vendor_id,
    name: values.vendor_name,
    mobile_no: values.contact_number,
    email: values.email_id,
    gst_no: values.gst_number,
    tech_no: values.tech_number,
    address: values.vendor_address,
  };
  return commonApiRequest("PUT", url, body);
};

export const deleteVendorApi = async (rowData) => {
  const url = `${apiUrl}/v1/vendor/vendor-delete?vendor_id=${rowData.ID}`;
  return commonApiRequest("DELETE", url);
};

//Quotation
export const uploadQuotationApi = async (values, setAddQuotationDailog) => {
  const url = `${apiUrl}/V1/quotation_form_add`;
  const formData = new FormData();
  formData.append("req_ID", values.req_ID);

  const vendors = values.vendors.map((vendor) => vendor.vendor).join(",");
  const prices = values.vendors.map((vendor) => vendor.price).join(",");
  const descriptions = values.vendors
    .map((vendor) => `"${vendor.description}"`)
    .join(",");
  formData.append("vendor", vendors);
  formData.append("price", prices);
  formData.append("description", descriptions);

  values.vendors.forEach((vendor, index) => {
    formData.append("file_proof", vendor.file_proof);
  });

  return commonApiRequest("POST", url, formData);
};

export const getQuotationApi = async (requirementID) => {
  const url = `${apiUrl}/V1/quotation_form_get`;
  const body = { req_id: requirementID };
  return commonApiRequest("POST", url, body);
};

export const getQuotationTableDataApi = async (selectedQuotationTab) => {
  const response = await axios.post(
    `${apiUrl}/V1/approval_quotation_get?process=${selectedQuotationTab}`,
    {
      page_index: 1,
      page_size: 100,
    },
    {
      headers: {
        accept: "application/json",
        authToken: localStorage.authToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const approvalQuotationApi = async (rowData, rejectReason, status) => {
  const url = `${apiUrl}/V1/approval_quotation?type=${status}`;
  const body = {
    req_ID: rowData.req_ID,
    q_id: rowData.ID,
    rejection_reason: rejectReason,
  };
  return commonApiRequest("POST", url, body);
};

//Assignment
export const addAssignmentApi = async (values) => {
  const url = `${apiUrl}/v1/Assign_Add`;
  const body = {
    product: values.product,
    brand: values.brand,
    serial_number: values.serial_number,
    assign_to: values.assign_to,
    assign_by: values.assign_by,
  };

  return commonApiRequest("POST", url, body);
};

export const getAssignmentApi = async () => {
  const url = `${apiUrl}/v1/Assign_GET`;
  return commonApiRequest("GET", url);
};

//Purchase Order
export const addPoApi = async (values) => {
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }

  const response = await axios.post(`${apiUrl}/V1/po_add`, formData, {
    headers: {
      authToken: localStorage.authToken,
    },
  });
  return response;
};

export const getPoApi = async (type) => {
  const response = await axios.get(`${apiUrl}/V1/po_get?type=${type}`, {
    headers: {
      accept: "application/json",
      authToken: localStorage.authToken,
      "Content-Type": "application/json",
    },
  });
  return response;
};

//Invoice
export const getInvoiceDataApi = async (type) => {
  const response = await axios.post(
    `${apiUrl}/v1/Invoice_Get/Invoice_Get?type=${type}`,
    {
      page_index: 1,
      page_size: 100,
    },
    {
      headers: {
        accept: "application/json",
        authToken: localStorage.authToken,
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

export const addInvoiceApi = async (values) => {
  const url = `${apiUrl}/v1/Invoice_Add/Invoice_Add`;
  const formData = new FormData();
  for (const key in values) formData.append(key, values[key]);
  return commonApiRequest("POST", url, formData);
};

export const updateInvoiceApi = async (values) => {
  const url = `${apiUrl}/v1/invoice/invoice-update`;
  const formData = new FormData();
  for (const key in values) formData.append(key, values[key]);
  return commonApiRequest("PUT", url, formData);
};

export const deleteInvoiceApi = async (rowData) => {
  const url = `${apiUrl}/v1/invoice/invoice-delete?invoice_id=${rowData.ID}`;
  return commonApiRequest("DELETE", url);
};

//Transaction
export const uploadTransactionApi = async (values) => {
  const url = `${apiUrl}/v1/Transaction/Transaction`;
  const formData = new FormData();
  for (const key in values) formData.append(key, values[key]);
  return commonApiRequest("POST", url, formData);
};

//Inventory
export const getInventoryDataApi = async (productName, quantityType) => {
  const url = `${apiUrl}/V1/product_quantity_get?type=${productName}&qty=${quantityType}`;
  return commonApiRequest("GET", url);
};

//Other
export const getProductApi = async () => {
  const url = `${apiUrl}/v1/products-get`;
  return commonApiRequest("GET", url);
};

export const brandListApi = async () => {
  const url = `${apiUrl}/v1/brand-details`;
  return commonApiRequest("GET", url);
};

export const getDeliveryApi = async (InvoiceNumber) => {
  const url = `${apiUrl}/v1/delivery-add?invoice_no=${InvoiceNumber}`;
  return commonApiRequest("GET", url);
};

export const addItemApi = async (values) => {
  const url = `${apiUrl}/v1/delivery-received`;
  const body = {
    data: values.data,
    invoice_no: values.invoice_no,
  };
  return commonApiRequest("POST", url, body);
};

export const addRequest = async (values) => {
  const url = `${apiUrl}/v1/request/request-add`;
  const body = {
    product_id: values.product,
    quantity: values.quantity,
    reason: values.reason,
  };
  return commonApiRequest("POST", url, body);
};

export const getRequestApi = async (type) => {
  const url = `${apiUrl}/v1/request/request-get?process_type=${type}`;
  return commonApiRequest("GET", url);
};

export const updateRequestApi = async (values) => {
  const url = `${apiUrl}/v1/request/request-update`;
  const body = {
    product_id: values.product,
    quantity: values.quantity,
    reason: values.reason,
    id: values.id,
  };
  return commonApiRequest("PUT", url, body);
};

export const deleteRequestApi = async (rowData) => {
  const url = `${apiUrl}/v1/request/request-delete?ID=${rowData.ID}`;
  return commonApiRequest("DELETE", url);
};

export const addBrandApi = async (values) => {
  const body = {
    brand_name: values.brand,
  };
  const url = `${apiUrl}/v1/brand/brand-add`;
  return commonApiRequest("POST", url, body);
};
