import { FILE } from "config";

const LeaseAgreementFeatures = [
  {
    app_id: 45,
    feature_id: 4501,
    type: FILE,
    subfolder: [],
    feature_name: "lease-add",
    label: "Lease",
    path: "/lease-agreement/add-lease"
  },


];

export default LeaseAgreementFeatures;
