/*eslint-disable*/
import React, { lazy, useEffect, useState, createContext } from "react";

import axios from "axios";
import { useNavigate } from "react-router";


// ==============================|| ROUTING RENDER ||============================== //

export const LoginContext = createContext();

export const LoginApi = (props) => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(!!localStorage.getItem("authToken"));
  // const [crmsModule, setCrmsModule] = useState({});
  const [authCheckErrorData, setAuthCheckErrorData] = useState("");

  
  
  //below code is for logout on browser tab/window close
  const handleLogOutApi = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/v1/user/logout`, {
        headers: {
          authToken: localStorage.authToken,
        },
      });
      console.log('response', response);
    } catch (err) {
      console.log(err);
    } finally {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }
  };

  // Logout on browser tab/window close (under maintanance)
  // const handleUnload = (event) => {
  //   // Custom logic to detect if it's a page refresh or tab/window close
  //   const url = 'http://192.168.119.187:2191/v1/user/logout';
  //   const headers = {
  //     authToken: localStorage.authToken,
  //   };
  //   const data = JSON.stringify(headers);

  //   // Use navigator.sendBeacon for the logout request
  //   navigator.sendBeacon(url, data);
  //   handleLogOutApi();
  // };

  // useLayoutEffect(() => {
  //   window.addEventListener('unload', handleUnload);
  //   return () => {
  //     window.removeEventListener('unload', handleUnload);
  //   };
  // }, []);

  return (
    <LoginContext.Provider
      value={{
        setLogin,
        login,
        // crmsModule,
        // setCrmsModule,
        authCheckErrorData,
        setAuthCheckErrorData,
        handleLogOutApi
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};
