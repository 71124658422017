import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import FeaturesRequired from "utils/FeaturesRequired";
import ReportsRequired from "utils/ReportsRequired";

const PettyCashHome = Loadable(
  lazy(() => import("views/utilities/petty-cash/dashboard"))
);
const PettyCashIndex = Loadable(
  lazy(() => import("views/utilities/petty-cash/index"))
);
const PettyCashUtilities = Loadable(
  lazy(() => import("views/utilities/petty-cash/utils"))
);
const PettyCashReports = Loadable(
  lazy(() => import("views/utilities/petty-cash/reports"))
);
const PettyCashRequest = Loadable(
  lazy(() => import("views/utilities/petty-cash/utils/request"))
);
const PettyExpense = Loadable(
  lazy(() => import("views/utilities/petty-cash/utils/expense-request"))
);
const PettyAllRequest = Loadable(
  lazy(() => import("views/utilities/petty-cash/utils/all-requests"))
);
const PettyAllExpenses = Loadable(
  lazy(() => import("views/utilities/petty-cash/utils/all-expenses"))
);
const PaymentStatus = Loadable(
  lazy(() => import("views/utilities/petty-cash/utils/payment-status"))
);
const RequestsReport = Loadable(
  lazy(() => import("views/utilities/petty-cash/reports/requests-report"))
);
const ExpenseReport = Loadable(
  lazy(() => import("views/utilities/petty-cash/reports/expense-report"))
);

const featureCheck = (component, feature) => (
  <FeaturesRequired feature={feature}>{component}</FeaturesRequired>
);
const reportCheck = (component, report) => (
  <ReportsRequired report={report}>{component}</ReportsRequired>
);

export const PettyCashModule = {
  path: "petty-cash",
  element: <PettyCashIndex />,
  children: [
    {
      path: "home",
      element: <PettyCashHome />,
    },
    {
      path: "utils",
      element: <PettyCashUtilities />,
    },
    {
      path: "reports",
      element: <PettyCashReports />,
    },
  ],
};

export const PettyCashUtilsRoutes = {
  path: "petty-cash",
  children: [
    {
      path: "request",
      element: featureCheck(<PettyCashRequest />, "petty-request"),
    },
    {
      path: "expense",
      element: featureCheck(<PettyExpense />, "petty-expense"),
    },
    {
      path: "all-request",
      element: featureCheck(<PettyAllRequest />, "petty-all-request"),
    },
    {
      path: "all-expenses",
      element: featureCheck(<PettyAllExpenses />, "petty-all-expenses"),
    },
    {
      path: "payment-status",
      element: featureCheck(<PaymentStatus />, "petty-payment-status"),
    },
  ],
};

export const PettyCashReportsRoutes = {
  path: "petty-cash",
  children: [
    {
      path: "requests-report",
      element: reportCheck(<RequestsReport />, "petty-requests-report"),
    },
    {
      path: "expense-report",
      element: reportCheck(<ExpenseReport />, "petty-expense-report"),
    },
  ],
};
