import React, { useContext } from "react";
import { Backdrop } from "@mui/material";
import { Spin } from "antd";
import { AlgoSummaryContext } from "context/Algo-summary/AlgoSummaryApis";

const Loder = () => {
  const { isLoading } = useContext(AlgoSummaryContext);
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <Spin size="large" />
    </Backdrop>
  );
};

export default Loder;
