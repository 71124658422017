/*eslint-disable*/
import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthRequired from "utils/AuthRequired";
import {
  RtrmsModule,
  RtrmsReportRoutes,
  RtrmsReportsPeakMargin,
  RtrmsUtilsFileUploadPeakMargin,
  RtrmsUtilsRoutes,
} from "./module-routes/rtrmsRoutes";
import { IpoModule, IpoReportsRoutes } from "./module-routes/ipoRoutes";
import {
  CrmsReportsRoutes,
  crmsModule,
  crmsRoutes,
} from "./module-routes/crmsRoutes";
import {
  KycModule,
  KycOnlineUtilsRoutes,
  KycReportsRoutes,
  KycUtilsRoutes,
} from "./module-routes/kycRoutes";
import { NetworkMonitorModule } from "./module-routes/networkmonitor";
import {
  LmsReportsRoutes,
  LmsUtilsRoutes,
  lmsModule,
} from "./module-routes/lmsRoutes";
import { EDisUtilsRoutes, edisModule } from "./module-routes/edisRoutes";
import { EpledgeModule, EpledgeRoutes } from "./module-routes/e_pledgeRoutes";
import { rmsModule, rmsUtilsRoutes } from "./module-routes/rmsRoutes";
import { EDDPIModule, EDDPIRoutes } from "./module-routes/eddpiRoutes";
import {AccountsModule, AccountsUtilsRoutes, AccountsReportsRoutes} from "./module-routes/accountsRoutes";

import {
  PmsDashboardModule,
  PmsMasterDashboardModule,
  PmsModule,
  PmsMyTaskModule,
  PmsProjectModule,
  PmsProjectMyTask,
  PmsTarget,
  PmsTargetModule,
} from "./module-routes/pmsRoutes";
import { InfoModule, InfoUtilsRoutes } from "./module-routes/infoRoutes";
import { BounceModule, BounceRoutes } from "./module-routes/bounceRoutes";
import {
  ComplianceModule,
  ComplianceRoutes,
} from "./module-routes/complianceRoutes";
import {
  AuditUtilsRoutes,
  CtclUtilsRoutes,
  ctclModule,
} from "./module-routes/ctclRoutes";
import {
  ApOnBoardingModule,
  ApOnBoardingRoutes,
} from "./module-routes/apOnBoarding";
import { ItInfraModule, ItInfraRoutes } from "./module-routes/itInfraRoutes";
import { EventModule, EventRoutes } from "./module-routes/event";
import {
  CustomerCareUtilsRoutes,
  CutomerCareModule,
} from "./module-routes/customerCare";
import { InwardModule, InwardUtilsRoutes } from "./module-routes/inward";
import {
  TravelManagementModule,
  TravelMangementUtilsRoutes,
} from "./module-routes/travelManagmentRoutes";
import { EmailModule, EmailRoutes } from "./module-routes/emailRoutes";
import {
  PettyCashModule,
  PettyCashReportsRoutes,
  PettyCashUtilsRoutes,
} from "./module-routes/pettyCashRoutes";
import { Chat } from "@mui/icons-material";
import { ChatModule } from "./module-routes/chatRoutes";
import {
  BranchCostModule,
  BranchCostUtilsRoutes,
  BranchCostReportsRoutes,
} from "./module-routes/branchCostRoutes";
import {
  SharedDriveFolderRoutes,
  SharedDriveModule,
} from "./module-routes/sharedDrive";
import {
  ExchangeAlertModule,
  ExchangeAlertUtils,
} from "./module-routes/exhangeAlert";
import {
  AlgoSummaryUtilsRoutes,
  algoSummaryRoutes,
} from "./module-routes/algoSummaryRoutes";
import {
  ResearchAvmModule,
  ResearchAvmUtilsRoutes,
} from "./module-routes/researchAvm";
import {
  WmsModule,
  WmsReportsRoutes,
  WmsUtilsRoutes,
} from "./module-routes/wmsRoutes";
import {
  InsuranceModule,
  InsuranceReportsRoutes,
  InsuranceUtilsRoutes,
} from "./module-routes/insurance";
import {
  RiaModule,
  RiaReportsRoutes,
  RiaUtilsRoutes,
} from "./module-routes/riaWmsRoutes";
import { ExtraNetModule, ExtraNetUtilsRoutes } from "./module-routes/extraNet";
import { LeaseModule, LeaseRoutes } from "./module-routes/leaseAgreement";
import { MainReportsModule } from "./module-routes/MainReportsModule";
import { bulkMailRoutes } from "./module-routes/bulkMailRoutes";
import { proRmsModule, proRmsUtilsRoutes } from "./module-routes/proRmsRoutes";
import { ArhamModule, ArhamRoutes } from "./module-routes/arhamShareRoutes";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const UserManagement = Loadable(
  lazy(() => import("views/user-management/index"))
);
const AppManagement = Loadable(
  lazy(() => import("views/app-management/index"))
);
const RoleManagement = Loadable(
  lazy(() => import("views/role-management/index"))
);

// utilities routing
const Utilities = Loadable(lazy(() => import("views/utilities/index")));

const AccountSettings = Loadable(
  lazy(() => import("views/account-settings/index"))
);

//**************************************/

///****************KYC*********************** */

//**************************************/

const Reports = Loadable(lazy(() => import("views/reports")));
const DirectorDashboard = Loadable(
  lazy(() => import("views/dashboard/director-dashboard"))
);

const Uploads = Loadable(lazy(() => import("views/uploads")));

const HelpDesk = Loadable(lazy(() => import("views/help-desk")));

const authCheck = (component) => <AuthRequired>{component}</AuthRequired>;

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: authCheck(<MainLayout />),
  children: [
    {
      path: "/",
      element: authCheck(<DashboardDefault />),
    },
    /* admin routes */
    {
      path: "user-management",
      element: authCheck(<UserManagement />),
    },
    {
      path: "app-management",
      element: authCheck(<AppManagement />),
    },
    {
      path: "role-management",
      element: authCheck(<RoleManagement />),
    },
    /* comman dashboard */
    {
      path: "dashboard",
      element: authCheck(<DashboardDefault />),
    },
    {
      path: "director-dashboard",
      element: authCheck(<DirectorDashboard />),
    },
    /* all utilities */
    {
      path: "utilities",
      element: <Utilities />,
    },

    /*Account-setting */
    {
      path: "account-settings",
      element: <AccountSettings />,
    },

    /* helpdesk */
    {
      path: "helpdesk",
      element: <HelpDesk />,
    },
    /* reports */
    {
      path: "reports",
      element: authCheck(<Reports />),
    },
    /* uploads */
    {
      path: "uploads",
      element: authCheck(<Uploads />),
    },
  ],
};

export const ReportsObject = { path: "reports", children: MainReportsModule};

export const utilitiesObject = { path: "utilities", children: [] };

export const PmsObject = { path: "/pms", children: [] };

export const DriveObject = { path: "/shared-drive", children: [] };

utilitiesObject.children.push(
  lmsModule,
  ctclModule,
  crmsModule,
  RtrmsModule,
  IpoModule,
  KycModule,
  NetworkMonitorModule,
  edisModule,
  EpledgeModule,
  rmsModule,
  EDDPIModule,
  InfoModule,
  BounceModule,
  ComplianceModule,
  ApOnBoardingModule,
  ItInfraModule,
  EventModule,
  CutomerCareModule,
  InwardModule,
  TravelManagementModule,
  EmailModule,
  PettyCashModule,
  ChatModule,
  BranchCostModule,
  WmsModule,
  InsuranceModule,
  RiaModule,
  ExchangeAlertModule,
  algoSummaryRoutes,
  ResearchAvmModule,
  ExtraNetModule,
  LeaseModule,
  bulkMailRoutes,
  proRmsModule,
  ArhamModule,
  AccountsModule
);

// ReportsObject.children.push(MainReports/Module);

PmsObject.children.push(
  PmsMyTaskModule,
  PmsProjectModule,
  PmsTargetModule,
  PmsDashboardModule,
  PmsMasterDashboardModule
);

DriveObject.children.push(SharedDriveModule);

//push all objects to below main routes
MainRoutes.children.push(
  utilitiesObject,
  ReportsObject,
  PmsObject,
  DriveObject,
  RtrmsReportRoutes,
  IpoReportsRoutes,
  KycReportsRoutes,
  KycOnlineUtilsRoutes,
  RtrmsUtilsRoutes,
  RtrmsUtilsFileUploadPeakMargin,
  KycUtilsRoutes,
  RtrmsReportsPeakMargin,
  CrmsReportsRoutes,
  EDisUtilsRoutes,
  EpledgeRoutes,
  rmsUtilsRoutes,
  EDDPIRoutes,
  // HelpDeskMenu,
  InfoUtilsRoutes,
  BounceRoutes,
  ComplianceRoutes,
  LmsUtilsRoutes,
  CtclUtilsRoutes,
  AuditUtilsRoutes,
  PmsProjectMyTask,
  PmsTarget,
  ApOnBoardingRoutes,
  ItInfraRoutes,
  EventRoutes,
  CustomerCareUtilsRoutes,
  InwardUtilsRoutes,
  TravelMangementUtilsRoutes,
  PettyCashUtilsRoutes,
  LmsReportsRoutes,
  PettyCashReportsRoutes,
  EmailRoutes,
  BranchCostUtilsRoutes,
  BranchCostReportsRoutes,
  WmsReportsRoutes,
  WmsUtilsRoutes,
  InsuranceReportsRoutes,
  InsuranceUtilsRoutes,
  RiaReportsRoutes,
  RiaUtilsRoutes,
  AlgoSummaryUtilsRoutes,
  SharedDriveFolderRoutes,
  ExchangeAlertUtils,
  ResearchAvmUtilsRoutes,
  ExtraNetUtilsRoutes,
  LeaseRoutes,
  proRmsUtilsRoutes,
  ArhamRoutes,
  AccountsUtilsRoutes,
  AccountsReportsRoutes
);

export default MainRoutes;

// "employee_id": "string",
//   "user_name": "string",
//   "is_enable": "0",
//   "department_id": "string",
//   "user_profile_id": "string",
//   "user_role_id": "string",
//   "user_category_id": "string",
//   "report_to": "string",
//   "joining_date": "string",
//   "first_name": "string",
//   "middle_name": "",
//   "last_name": "string",
//   "mobile_number": "string",
//   "e_mail_id": "string",
//   "qualification": "",
//   "dob": "string",
//   "marital_status": "string",
//   "gender": "string",
//   "nationality": "string",
//   "location": "string"
// }

// {
//   path: "kyc",
//   children: [
//     {
//       path: "ekyc",
//       element: <KycEkyc />,
//     },
//     {
//       path: "ekyc/live-login",
//       element: <KycEkycLiveLogin />,
//     },
//   ],
// },

// change to different file
// {
//   path: "utilities",
//   children: [
//     /* ipo */
//     {
//       path: "crms",
//       children: [
//         {
//           path: "home",
//           element: <CrmsHome />,
//         },
//       ],
//     },
//     {
//       path: "rms",
//       children: [
//         {
//           path: "home",
//           element: <RmsHome />,
//         },
//       ],
//     },
//     {
//       path: "ctcl",
//       children: [
//         {
//           path: "home",
//           element: <CtclHome />,
//         },
//       ],
//     },
//     {
//       path: "accountreco",
//       children: [
//         {
//           path: "home",
//           element: <AccountrecoHome />,
//         },
//       ],
//     },
//     {
//       path: "edis",
//       children: [
//         {
//           path: "home",
//           element: <EdisHome />,
//         },
//       ],
//     },
//     {
//       path: "eddpi",
//       children: [
//         {
//           path: "home",
//           element: <EddpiHome />,
//         },
//       ],
//     },
//     {
//       path: "epledge",
//       children: [
//         {
//           path: "home",
//           element: <EpledgeHome />,
//         },
//       ],
//     },
//     {
//       path: "kyc",
//       children: [
//         {
//           path: "home",
//           element: <KycHome />,
//         },
//       ],
//     },
//   ],
// },
