/*eslint-disable*/
import React, { useContext, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";
import { AlgoSummaryContext } from "context/Algo-summary/AlgoSummaryApis";

export default function Index() {
  const { classes, handleLiveDashboard, liveDashboardData, liveDashboardTime } =
    useContext(AlgoSummaryContext);

  const isWithinTimeRange = () => {
    const now = new Date();
    const start = new Date();
    start.setHours(9, 0, 0, 0);
    const end = new Date();
    end.setHours(16, 0, 0, 0);
    return now >= start && now <= end;
  };

  useEffect(() => {
    handleLiveDashboard();
    if (isWithinTimeRange()) {
      const interval = setInterval(() => {
        if (isWithinTimeRange()) {
          handleLiveDashboard();
        }
      }, 300000);
      return () => clearInterval(interval);
    }
  }, []);

  const getColor = (value) => {
    return value >= 0 ? "green" : "red";
  };

  return (
    <>
      <Grid container justifyContent="flex-end" sx={{ px: 2 }}>
        <Grid item>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Last Updated Data:- {liveDashboardTime}
          </Typography>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} rowSpacing={2} sx={{ p: 1 }}>
        {liveDashboardData?.length > 0 ? (
          liveDashboardData.map((data, i) => (
            <Grid item xs={12} md={6} key={i}>
              <Card
                border={true}
                content={false}
                sx={{
                  boxShadow: 2,
                  background: `#FDFDFD`,
                  borderRadius: 3,
                }}
              >
                <CardContent sx={{ py: 1, px: 2 }}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h4" component="div">
                        {data.fund_manager}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography variant="body1">
                            MTM:{" "}
                            <span style={{ color: getColor(data.mtm) }}>
                              {data.mtm}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">
                            Margin:{" "}
                            <span style={{ color: getColor(data.margin) }}>
                              {data.margin}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">
                            Fund:{" "}
                            <span style={{ color: getColor(data.fund_amount) }}>
                              {data.fund_amount}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider sx={{ borderColor: "#9e9e9e" }} />
                <TableContainer
                  sx={{ height: "150px", overflowY: "auto" }}
                  className={classes.customScrollbar}
                >
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Client ID</TableCell>
                        <TableCell>MTM</TableCell>
                        <TableCell>Margin</TableCell>
                        <TableCell>Fund</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.client_data.map((client, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ py: 0.5, fontWeight: "bold" }}>
                            {client.client_id}
                          </TableCell>
                          <TableCell
                            sx={{ py: 0.5, color: getColor(client.client_mtm) }}
                          >
                            {client.client_mtm}
                          </TableCell>
                          <TableCell
                            sx={{
                              py: 0.5,
                              color: getColor(client.client_margin),
                            }}
                          >
                            {client.client_margin}
                          </TableCell>
                          <TableCell
                            sx={{
                              py: 0.5,
                              color: getColor(client.client_fund_amount),
                            }}
                          >
                            {client.client_fund_amount}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              No data found
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}
