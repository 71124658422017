/*eslint-disable*/
import React, { createContext, useState } from "react";

import { toast } from "react-toastify";
import { downloadFileApi, uploadFileApi } from "services/e-pledge/ePledge";

export const EpledgeContext = createContext();

export const EpledgeApi = (props) => {

    const currentDate = new Date();

    // Get day, month, and year components
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = currentDate.getFullYear().toString();

    // Concatenate components in the desired format
    const formattedDate = day + month + year;


    const [downloading, setDownloading] = useState(false);

    const uploadFile = async (formData) => {
        try {
            const response = await uploadFileApi(formData);

            console.log('Response:', response.data);
            toast.success(response.data.message)
        } catch (error) {
            console.error('Error:', error);
            toast.error(error)
        }
    };


    // const downloadFile = () => {
    //     setDownloading(true);
    //     axios({
    //         url: `${process.env.REACT_APP_URL_EPLEDGE}/v1/file-respone`,
    //         method: 'GET',
    //         responseType: 'blob', // important
    //     }).then(response => {
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', `APPSEC_COLLVAL_${formattedDate}.csv`);
    //         document.body.appendChild(link);
    //         link.click();
    //         setDownloading(false);
    //     }).catch(error => {
    //         console.error('Error downloading file: ', error);
    //         setDownloading(false);
    //     });
    // };
    const downloadFile = async () => {
        setDownloading(true);
        try {
            const response = await downloadFileApi();

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `APPSEC_COLLVAL_${formattedDate}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up after the click
            setDownloading(false);
        } catch (error) {
            console.error('Error downloading file: ', error);
            setDownloading(false);
        }
    };



    return (
        <EpledgeContext.Provider
            value={{ uploadFile, downloading, downloadFile }}
        >
            {props.children}
        </EpledgeContext.Provider>
    );
}
