import axios from "axios";
import React from "react";
import { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";

export const AuditContext = createContext();

export default function AuditApis(props) {
  const [auditReportData, setAuditReportData] = useState([]);
  const [viewHistoryValue, setViewHistoryValue] = useState("");
  const [auditHistoryData, setAuditHistoryData] = useState([]);
  const [officeList, setOfficeList] = useState([]);

  // FetchOffice List Api
  const headers = {
    authToken: localStorage.getItem("authToken")
  };
  const fetchAuditedReport = (url) => {
    console.log("Api Call fetchAuditedReport");
    setAuditReportData([]);
    axios
      .get(`${process.env.REACT_APP_URL_CTCL}/${url}`, { headers: headers })
      .then((response) => {
        console.log("data", response);
        if (response.data.status === "success") {
          const data = response.data.data.map((index, item) => ({
            ...index,
            id: item + 1
          }));
          setAuditReportData(data);
          // console.log("001",response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  // FetchOffice List Api
  const fetchOfficeList = () => {
    axios
      .get(`${process.env.REACT_APP_URL_CTCL}/office-list`, {
        headers: headers
      })
      .then((response) => {
        if (response.data.status === "success") {
          console.log("data", response.data.data);
          setOfficeList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      });
  };

  // Fetch Audit History View
  const headersaudithistory = {
    authToken: localStorage.getItem("authToken"),
    accept: "application/json"
  };
  const fetchAuditedHistoryView = (value) => {
    axios
      .get(
        `${process.env.REACT_APP_URL_CTCL}/ctclid-wise-audit-history?audit_id=${value}`,
        { headers: headersaudithistory }
      )
      .then((response) => {
        if (response.data.status === "success") {
          const data = response.data.data.map((index, item) => ({
            ...index,
            id: item + 1
          }));
          console.log("data", data);
          setViewHistoryValue(data);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  // FetchOffice List Api
  const fetchAuditHistory = () => {
    const headers = {
      authToken: localStorage.getItem("authToken")
    };
    axios
      .get(`${process.env.REACT_APP_URL_CTCL}/audit-history`, {
        headers: headers
      })
      .then((response) => {
        if (response.data.status === "success") {
          const data = response.data.data.map((item, index) => ({
            ...item,
            id: index + 1
          }));
          setAuditHistoryData(data);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        // messageApi.open({
        //   type: "error",
        //   content: error.message,
        //   duration: 3
        // });
      });
  };

  return (
    <AuditContext.Provider
      value={{
        fetchAuditedReport,
        auditReportData,

        fetchAuditedHistoryView,
        viewHistoryValue,
        fetchAuditHistory,
        auditHistoryData,
        fetchOfficeList,
        officeList
      }}
    >
      {props.children}
    </AuditContext.Provider>
  );
}
