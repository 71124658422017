/*eslint-disable*/
import React, { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

import {
  addMailService,
  approveModificationApi,
  approveRejectClosureApi,
  esignFileDownloadApi,
  exportCsvFileApi,
  fetcData,
  fetchClosureDataApi,
  fetchModificationDataApi,
  onlineDormatApi,
  rejectModificationApi,
} from "services/kyc/kycAll";
import { Base64ToFileDownload, handleCatch } from "utils/CommonFunctions";

export const KycModificationContext = createContext();

export const KycModificationApis = (props) => {
  const [loading, setLoading] = useState(false);

  const [mainData, setMainData] = useState({});
  const [closureData, setClosureData] = useState({});
  const [kycStatus, setKycStatus] = useState("pending");
  const [sorting, setSorting] = useState([{ id: "user_id", desc: false }]);
  const [rowCount, setRowCount] = useState("");
  const [rowCountDummy, setRowCountDummy] = useState(0);
  const [isError, setIsError] = useState(false);
  const [dormatTable, setDormatTable] = useState("dormate_client")
  const [data, setData] = useState([])
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const [selectedRows, setSelectedRows] = useState([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataa = async (inParams) => {
    try {
      const params = {
        types: inParams,
        page_index: paginationModel.page + 1,
        page_size: paginationModel.pageSize
      };
      const response = await fetcData(params);
      const tempData = response.data.data.data.map((item, index) => ({
        ...item,
        id: index + 1
      }))
      setRowCountDummy(response.data.data.row_count)
      setData(tempData);
    } catch (error) {
      handleCatch(error);
    }
  };
  const addMail = async (abc) => {
    setSelectedRows([]);
    try {
      const params = {
        types: abc
      };
      const body = {
        client_code: selectedRows
      };
      console.log('params', params)
      const response = await addMailService(params, body);
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchDataa(abc)
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const fetchModificationData = async (types, globalFilter, staticStatus) => {
    setLoading(true);
    setMainData({});
    try {
      const params = {
        kyc_status: staticStatus ? staticStatus : kycStatus,
        types: types,
        page_size: pagination.pageSize,
        page_index: pagination?.pageIndex + 1,
        ...(globalFilter && { client_code: globalFilter }),
      };

      const response = await fetchModificationDataApi(params);

      console.log("modification res", response.data.data);
      setMainData(response.data.data);
      setRowCount(response.data.data.row_count);
    } catch (error) {
      handleCatch(error);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };


  const exportCsvFile = async (types, staticStatus) => {
    setLoading(true);
    try {
      const params = {
        kyc_status: staticStatus ? staticStatus : kycStatus,
        types: types,
      };
      const response = await exportCsvFileApi(params);
      console.log("response", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${types}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleCatch(error);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  const approveModification = async (type, clientIds) => {
    setLoading(true);
    try {
      const params = {
        process: "approve",
        types: type,
      };
      const body = {
        client_ids: clientIds,
      };
      const response = await approveModificationApi(body, params);
      console.log("modification res", response.data);
      fetchModificationData(type);
      toast.success(response.data.message);
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoading(false);
    }
  };

  //POST : reject modifications
  const rejectModification = async (
    type,
    clientIds,
    rejectReason,
    otherRejectReason
  ) => {
    setLoading(true);
    try {
      const params = {
        process: "reject",
        types: type,
      };
      const body = {
        client_ids: clientIds,
        reject_reason: rejectReason,
        reject_other_reason: otherRejectReason,
      };
      const response = await rejectModificationApi(body, params);
      fetchModificationData(type);
      console.log("modification res", response.data);
      toast.success(response.data.message);
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoading(false);
    }
  };

  //POST : Fetch Closure
  const fetchClosureData = async (types, globalFilter, staticStatus) => {
    setLoading(true);
    setClosureData([]);
    setRowCount(0);
    try {
      const params = {
        kyc_status: staticStatus ? staticStatus : kycStatus,
        types: types,
        page_size: pagination.pageSize,
        page_index: pagination?.pageIndex + 1,
        ...(globalFilter && { client_code: globalFilter }),
      };

      const response = await fetchClosureDataApi(params);

      setClosureData(response.data.data);
      setRowCount(response.data.data.row_count);
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoading(false);
    }
  };

  //POST : approve reject closure
  const approveRejectClosure = async (types, process, formData) => {
    setLoading(true);
    try {
      const params = {
        process: process,
        types: types,
      };

      const response = await approveRejectClosureApi(formData, params);
      toast.success(response.data.message);
      fetchClosureData("acc_close");
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoading(false);
    }
  };

  const [dormateData, setDormateData] = useState([]);
  const hanldeOnlineDormat = async (globalFilter) => {
    try {
      const params = {
        page_size: pagination.pageSize,
        page_index: pagination?.pageIndex + 1,
        ...(globalFilter && { client_code: globalFilter }),
      };
      const response = await onlineDormatApi(params);
      if (response.status === 200) {
        setDormateData(response.data.data.dormate_data);
        setRowCount(response.data.data.row_count);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleEsignFileDownload = async (clientCode, type, status) => {
    setLoading(true);
    try {
      const params = {
        client_code: clientCode,
        kyc_status: status,
        types: type
      };
      const response = await esignFileDownloadApi(params);
      if (response.status === 200) {
        if (response.data.data.pdf === null) {
          toast.error("No PDF found");
        } else {
          Base64ToFileDownload(response.data.data.pdf, "application/pdf", `${clientCode}_${type}_modification_esign.pdf`);
          toast.success(response.data.message);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <KycModificationContext.Provider
      value={{
        loading,
        fetchModificationData,
        exportCsvFile,
        mainData,
        setMainData,
        approveModification,
        rejectModification,
        kycStatus,
        setKycStatus,
        pagination,
        setPagination,
        sorting,
        setSorting,
        rowCount,
        isError,
        setIsError,

        fetchClosureData,
        closureData,
        approveRejectClosure,

        hanldeOnlineDormat,
        dormateData,
        dormatTable, setDormatTable,
        fetchDataa,
        data,
        addMail,
        selectedRows, setSelectedRows,
        paginationModel, setPaginationModel,
        rowCountDummy, setRowCountDummy,

        handleEsignFileDownload
      }}
    >
      {props.children}
    </KycModificationContext.Provider>
  );
};
