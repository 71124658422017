import CryptoJS from "crypto-js";

export const encryptData = (data, salt = process.env.REACT_APP_SALT) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
  const urlEncodedEncrypted = encodeURIComponent(encrypted);
  return urlEncodedEncrypted;
};
export const decryptData = (ciphertext, salt = process.env.REACT_APP_SALT) => {
  const decodedEncryptedData = decodeURIComponent(ciphertext);
  const bytes = CryptoJS.AES.decrypt(decodedEncryptedData, salt);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export const addSessionStorage = (key, value) => {
  localStorage.setItem(key, encryptData(value, process.env.REACT_APP_SALT));
};

export const getSessionStorage = (key) => {
  const data = localStorage.getItem(key);
  if (data) {
    return decryptData(data, process.env.REACT_APP_SALT);
  }
};
