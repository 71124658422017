import { FOLDER } from "config";
import { FILE } from "config";

export const ItInfraFeaturesInventory = [
  {
    app_id: 28,
    feature_id: 2809,
    feature_name: "inventory-server",
    type: FILE,
    subfolder: [],
    label: "Server",
    path: "/it-infra/inventory-server",
  },
  {
    app_id: 28,
    feature_id: 2812,
    feature_name: "inventory-network-devices",
    type: FILE,
    subfolder: [],
    label: "Network Devices",
    path: "/it-infra/inventory-network-devices",
  },
  {
    app_id: 28,
    feature_id: 2813,
    feature_name: "inventory-computer-peripheral",
    type: FILE,
    subfolder: [],
    label: "Computer Peripheral",
    path: "/it-infra/inventory-computer-peripheral",
  },
];

const ItInfraFeatures = [
  {
    app_id: 28,
    type: FOLDER,
    subfolder: ItInfraFeaturesInventory,
    label: "Inventory",
    path: "/it-infra/inventory",
  },
  {
    app_id: 28,
    feature_id: 2801,
    type: FILE,
    subfolder: [],
    feature_name: "vendor",
    label: "Vendor",
    path: "/it-infra/vendor_addition",
  },
  {
    app_id: 28,
    feature_id: 2802,
    type: FILE,
    subfolder: [],
    label: "Requirement",
    feature_name: "requirement",
    path: "/it-infra/requirement",
  },
  {
    app_id: 28,
    feature_id: 2803,
    type: FILE,
    subfolder: [],
    label: "Quotation Approval",
    feature_name: "quotation",
    path: "/it-infra/quatation-approval",
  },
  {
    app_id: 28,
    feature_id: 2804,
    type: FILE,
    subfolder: [],
    label: "Purchase Order",
    feature_name: "purchase_order",
    path: "/it-infra/purchase-order",
  },
  {
    app_id: 28,
    feature_id: 2805,
    type: FILE,
    subfolder: [],
    label: "Transaction",
    feature_name: "transaction",
    path: "/it-infra/transaction",
  },
  {
    app_id: 28,
    feature_id: 2806,
    type: FILE,
    subfolder: [],
    label: "Invoice",
    feature_name: "invoice",
    path: "/it-infra/invoice",
  },
  {
    app_id: 28,
    feature_id: 2807,
    type: FILE,
    subfolder: [],
    label: "Delivery",
    feature_name: "delivery",
    path: "/it-infra/delivery",
  },
  {
    app_id: 28,
    feature_id: 2808,
    type: FILE,
    subfolder: [],
    label: "Assignment",
    feature_name: "assignment",
    path: "/it-infra/assignment",
  },
  {
    app_id: 28,
    feature_id: 2810,
    type: FILE,
    subfolder: [],
    label: "Request",
    feature_name: "request_rais",
    path: "/it-infra/request",
  },
  {
    app_id: 28,
    feature_id: 2811,
    type: FILE,
    subfolder: [],
    label: "Request Approval",
    feature_name: "request_approval",
    path: "/it-infra/request-approval",
  },
];

export default ItInfraFeatures;

