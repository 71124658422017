/*eslint-disable*/
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchInsurance,
  handleBulkUpload,
  handleInsuranceAdd,
  handleInsuranceUpdate,
  handleDelete,
} from "services/insurance/InsuranceService";

export const InsuranceContext = createContext();

export default function InsuranceApi(props) {
  const [insuranceData, setInsuranceData] = useState([]);

  const fetchAllInsurance = async () => {
    try {
      const response = await fetchInsurance();
      if (response.status === 200) {
        console.log("response", response);
        setInsuranceData(response.data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const bulkUpload = async (values, others) => {
    try {
      const formData = new FormData();
      if (values.insurance_data_file) {
        formData.append("insurance_data_file", values.insurance_data_file);
        const resJson = await handleBulkUpload(formData);
        others.setOpen(false);
        toast.success(resJson.message);
        fetchAllInsurance();
      }
    } catch (error) {
      console.error("error", error);

      toast.error(resJson.message);
    }
  };

  const addInsurance = async (values, others) => {
    try {
      const formData = new FormData();
      // formData.append("insurance_id", id);
      formData.append("policy_holder", values.policy_holder);
      formData.append("insurance_company", values.insurance_company);
      formData.append("policy_name", values.policy_name);
      formData.append("agency_code", values.agency_code);
      formData.append("date_of_issue", values.date_of_issue);
      formData.append("renewal_date", values.renewal_date);
      formData.append("policy_no", values.policy_no);
      formData.append("premium_amount", values.premium_amount);
      formData.append("sum_assured", values.sum_assured);
      formData.append("term", values.term);
      formData.append("premium_pay_term", values.premium_pay_term);
      formData.append("premium_schedule", values.premium_schedule);
      formData.append("money_back", values.money_back);
      formData.append("status", values.status);
      formData.append(
        "accidental_death_benefit",
        values.accidental_death_benefit
      );
      formData.append("nominee", values.nominee);
      formData.append("relation", values.relation);
      formData.append("balance_units", values.balance_units);
      formData.append("current_value", values.current_value);
      formData.append("as_on_date", values.as_on_date);
      formData.append("remark", values.remark);
      formData.append("commission_in_rate", values.commission_in_rate);
      formData.append("commission_out_rate", values.commission_out_rate);

      if (values.policy_doc_file) {
        formData.append("policy_doc_file", values.policy_doc_file);
      }
      const resJson = await handleInsuranceAdd(formData);
      others.setOpen(false);
      toast.success(resJson.message);
      fetchAllInsurance();
    } catch (error) {
      console.error("error", error);

      toast.error(resJson.message);
    }
  };

  const updateInsurance = async (values, others, insurance_id) => {
    try {
      const formData = new FormData();
      formData.append("insurance_id", insurance_id);
      formData.append("policy_holder", values.policy_holder);
      formData.append("insurance_company", values.insurance_company);
      formData.append("policy_name", values.policy_name);
      formData.append("agency_code", values.agency_code);
      formData.append("date_of_issue", values.date_of_issue);
      formData.append("renewal_date", values.renewal_date);
      formData.append("policy_no", values.policy_no);
      formData.append("premium_amount", values.premium_amount);
      formData.append("sum_assured", values.sum_assured);
      formData.append("term", values.term);
      formData.append("premium_pay_term", values.premium_pay_term);
      formData.append("premium_schedule", values.premium_schedule);
      formData.append("money_back", values.money_back);
      formData.append("status", values.status);
      formData.append(
        "accidental_death_benefit",
        values.accidental_death_benefit
      );
      formData.append("nominee", values.nominee);
      formData.append("relation", values.relation);
      formData.append("balance_units", values.balance_units);
      formData.append("current_value", values.current_value);
      formData.append("as_on_date", values.as_on_date);
      formData.append("remark", values.remark);
      formData.append("commission_in_rate", values.commission_in_rate);
      formData.append("commission_out_rate", values.commission_out_rate);

      if (values.policy_doc_file) {
        formData.append("policy_doc_file", values.policy_doc_file);
      }
      const resJson = await handleInsuranceUpdate(formData, insurance_id);
      others.setOpen(false);
      toast.success(resJson.message);
      fetchAllInsurance();
    } catch (error) {
      console.error("error", error);

      toast.error(resJson.message);
    }
  };

  const deleteInsurance = async (insurance_id) => {
    try {
      const resJson = await handleDelete({ insurance_id });
      if (resJson.status === 200) {
        fetchAllInsurance();
        toast.success(resJson.data.message);
      } else {
        toast.error("Failed to delete Insurance.");
      }
    } catch (error) {
      console.error("Delete Insurance error:", error);
      toast.error("An error occurred while deleting the Insurance.");
    }
  };

  return (
    <InsuranceContext.Provider
      value={{
        insuranceData,
        setInsuranceData,
        fetchAllInsurance,
        bulkUpload,
        addInsurance,
        updateInsurance,
        deleteInsurance,
      }}
    >
      {props.children}
    </InsuranceContext.Provider>
  );
}
