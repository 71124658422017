/*eslint-disable*/
import { element } from "prop-types";
import { lazy } from "react";
import Loadable from "ui-component/Loadable";


const DriveMain = Loadable(lazy(() => import("views/shared-drive")));
const DriveHome = Loadable(lazy(() => import("views/shared-drive/drive-home")));
const DriveFav = Loadable(lazy(() => import("views/shared-drive/favourites")));
const DriveSharedPage = Loadable(lazy(() => import("views/shared-drive/shared-access")));
const MyDrivePage = Loadable(lazy(() => import("views/shared-drive/my-drive")));
const MyGroupPage = Loadable(lazy(() => import("views/shared-drive/my-groups/MyGroup")));
const InnerFolderView = Loadable(lazy(() => import("views/shared-drive/common-components/InnerFolderView")));



export const SharedDriveModule = {
  path: "home",
  element: <DriveMain />,
  children: [
    {
      path: "drive-home",
      element: <DriveHome />
    },
    {
      path: "favourites",
      element: <DriveFav />
    },
    {
      path: "shared-access",
      element: <DriveSharedPage />
    },
    {
      path: "my-drive",
      element: <MyDrivePage />
    },
    {
      path: "my-groups",
      element: <MyGroupPage />
    },
  ]
};



export const SharedDriveFolderRoutes = {
  path: "drive",
  element: <DriveMain />,
  children: [
    {
      path: "folder/:folderId",
      element: <InnerFolderView />
    }
  ]
}