import { Box, LinearProgress } from "@mui/material";

export const headerConfig = [
  { text: 'Assign To', },
  { text: 'Due Date', },
  { text: 'Task Type', },
  { text: 'Task Status' },
  { text: 'Task Category', }
];

export const options = [
  'Edit',
  'Delete',
];

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 250,
    },
  },
};

export function getColor(value) {
  if (value == "medium") {
    return "#007AFF";
  } else if (value == "high") {
    return "#FF6961";
  } else if (value == "low") {
    return "#60B158";
  } else {
    return "grey"
  }
}


export function LinearProgressWithLabel(props) {
  const progressBarColor = props.color || '#60B158';
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            background: "#BABABA",
            '& .MuiLinearProgress-bar': {
              backgroundColor: progressBarColor  // Use the color prop or a default color
            },
            borderRadius: "10px",
            height: "7px"
          }}
        />
      </Box>
    </Box>
  );
}


export const getColorPriority = (priority) => {
  switch (priority) {
    case "high":
      return "red";
    case "medium":
      return "#1F84AC";
    case "low":
      return "#00A63F";
    default:
      return 'gray';
  }
};
export const menuItemsTimer = [
  { label: 'Coding', value: 'coding' },
  { label: 'Learning', value: 'learning' },
  { label: 'Unit Testing', value: 'unit_testing' },
  { label: 'Bug Fixing', value: 'bug_fixing' },
  { label: 'Designing', value: 'designing' },
  { label: 'R&D', value: 'r_n_d' },
  { label: 'Re-testing', value: 're_testing' },
  { label: 'Meeting', value: 'meeting' }
];



export const removeQueryParam = (searchParams, setSearchParams) => {
  if (searchParams.has("task_id") || searchParams.has("base_id")) {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete("task_id");
    newSearchParams.delete("base_id");
    setSearchParams(newSearchParams);
  }
};



export const statusColors = {
  low: "#8CEBC9",
  medium: "#FFCBCB",
  high: "#F6DE90"
};