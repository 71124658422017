import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const AutoLogout = () => {

  const timeout = process.env.REACT_APP_AUTOLOGOUT;
  
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();
  const timerRef = useRef(null);

  const resetTimer = () => {
    setIsActive(true);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setIsActive(false);
    }, timeout);
  };

  const handleActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    // Set initial timeout
    resetTimer();

    // Register event listeners to track user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);

    return () => {
      // Cleanup event listeners and timeout on component unmount
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
      clearTimeout(timerRef.current);
    };
  }, []); // Empty array to ensure the effect runs only on component mount/unmount

  useEffect(() => {
    if (!isActive) {
      // Handle user logout logic
      navigate('/login'); // Redirect to login page
    }
  }, [isActive, navigate]);

  return null; // This component does not render anything
};

export default AutoLogout;
