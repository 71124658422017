/*eslint-disable*/
import React, { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { AddIntroducerCodeApi, AddNonKraPanApi, AddReopenPanApi, ChangeAppStatusApi, exportKycCsvFileApi, FetchApplicationStatusDataApi, fetchConnectCrentialsDataApi, fetchEkycCompletedLogsApi, fetchHufTableDataApi, FetchIntroducerCodeApi, FetchNonKraPanApi, FetchReopenDataApi, fetchTableDataApi, fetchTechExcelDataApi, handleDeletePanApi, handleFetchDataByPanApi, handleFetchEsignPdfApi, handleFetchIpvLogsApi, handleFetchPanNameApi, handleFetchPanReportApi, handleFetchPennyGeoLogsApi, handleFetchPhysicalDocumentsApi, handleNomineeDataApi, handlePanNameChecksApi, handleStatusPanNOApi, handleUpdateApplicationDataApi, handleUploadPhysicalDocumentsApi, updateClientCodeTechExcelApi } from "services/kyc/kycAll";
import { handleCatch } from "utils/CommonFunctions";

export const KycContext = createContext();

export const KycOnBoardingApis = (props) => {

  const [kycAdharDetails, setKycAdharDetails] = useState({});
  const [kycNomineeData, setKycNomineeData] = useState({});

  // Data and fetching state
  const [data, setData] = useState([]);
  const [hufData, setHufData] = useState([]);

  const [applicationStatusData, setApplicationStatusData] = useState([]);
  const [codes, setCodes] = useState([]);

  //tech excel states
  const [techExcelData, setTechExcelData] = useState([]);
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const [techExcelLoader, setTechExcelLoader] = useState(false);

  const [editedKycStatus, setEditedKycStatus] = useState({});

  const [nonKraPan, setNonKraPan] = useState([]);


  const [panCheckData, setPanCheckData] = useState({});


  const [isError, setIsError] = useState(false);

  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [kycStatus, setKycStatus] = useState("pending");
  const [sorting, setSorting] = useState([{ id: "user_id", desc: false }]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //fetch onboarding > kyc authorization table Data
  const fetchTableData = async (payload, globalFilter, source = null) => {
    setData([]);
    setRowCount([]);
    setIsLoading(true);
    setIsRefetching(true);

    const params = {
      kyc_status: payload || kycStatus,
      sort_field: sorting.length === 0 ? "user_id" : sorting[0].id,
      page_size: pagination.pageSize,
      page_number: pagination.pageIndex + 1,
      sort_order: sorting.length === 0 || sorting[0].desc ? "DESC" : "ASC",
      keyword: globalFilter || "",
      source: source || ""
    };

    try {
      const response = await fetchTableDataApi(params);

      if (response.status === 200) {
        setData(response.data.data.user_data);
        setRowCount(response?.data?.data.row_count);
      } else {
        console.log("Unexpected response", response);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
      console.error("Error fetching data", error);
      setIsError(true);
    } finally {
      // Update loading states
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  //fetch HUF onboarding > HUF kyc authorization table Data
  const fetchHufTableData = async (payload, globalFilter) => {
    setHufData([]);
    setIsLoading(true);
    setIsRefetching(false);

    const params = {
      kyc_status: payload || kycStatus,
      sort_field: sorting.length === 0 ? "user_id" : sorting[0].id,
      page_size: pagination.pageSize,
      page_number: pagination.pageIndex + 1,
      sort_order: sorting.length === 0 || sorting[0].desc ? "DESC" : "ASC",
      pan_type: "h",
      keyword: globalFilter || "",
    };
    try {
      // Fetch data
      const response = await fetchHufTableDataApi(params);
      // Handle successful response
      if (response.status === 200) {
        console.log("response HUF", response.data.data);
        setHufData(response.data.data.user_data);
        setRowCount(response.data.data.row_count);
      } else {
        console.log("Unexpected response", response);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
      console.error("Error fetching data", error);
      setIsError(true);
    } finally {
      // Update loading states
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  //fetch onboarding > kyc authorization get_aadhar_data
  const handleFetchDataByPan = async (pan_no) => {
    setKycAdharDetails({})
    setIsLoading(true);
    try {
      const params = {
        pan_no,
        source: 'admin'
      };
      const response = await handleFetchDataByPanApi(params);

      setKycAdharDetails(response.data.data);
    } catch (error) {
      handleCatch(error)
    } finally {
      setIsLoading(false);
    }
  };

  //fetch onboarding > kyc authorization get_nominee_data
  const handleNomineeData = async (pan_no) => {
    setIsLoading(true);
    try {
      const params = {
        pan_no,
        source: 'admin'
      };
      const response = await handleNomineeDataApi(params);

      setKycNomineeData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //fetch onboarding > Tech Excel table Data
  const fetchTechExcelData = async (globalFilter, hufPayload) => {
    setTechExcelData([]);
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const params = {
      kyc_status: 'approved',
      pan_type: hufPayload ? hufPayload : undefined,
      sort_field: sorting.length === 0 ? "form_no_new" : sorting[0].id,
      page_size: pagination.pageSize,
      page_number: pagination.pageIndex + 1,
      sort_order: sorting.length === 0 || sorting[0].desc ? "DESC" : "ASC",
      keyword: globalFilter || "",
    };

    try {
      const response = await fetchTechExcelDataApi(params)

      const formattedData = response.data.data.user_data.map((item) => {
        const date = new Date(item.created_at);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year} -${month} -${day} `;
        return {
          ...item,
          created_date: formattedDate,
          only_trading: false,
          client_code: "",
        };
      });
      setRowCount(response.data.data.row_count);
      setTechExcelData(formattedData);

    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
      console.error("Error fetching data", error);
      setIsError(true);
    } finally {
      // Update loading states
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  //GET update client code
  const updateClientCodeTechExcel = async (payload, hufPayload) => {
    setTechExcelLoader(true);
    try {
      const params = {
        pan_no: payload.pan,
        client_code: payload.clientCode,
        only_trading: payload.onlyTrading,
        fname: payload.fname,
        mname: payload.mname,
        lname: payload.lname,
        micr: payload.micr,
      }
      const response = await updateClientCodeTechExcelApi(params);

      toast.success(response.data.message);
      if (hufPayload) {
        fetchTechExcelData("", "h")
      } else {
        fetchTechExcelData()
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setTechExcelLoader(false);
    }
  };
  const handleFetchEsignPdf = async (pan_no, techExcel) => {
    setPdfUrl("")
    try {
      const params = {
        pan_no: pan_no
      };

      const response = await handleFetchEsignPdfApi(params);

      if (response.status === 200) {
        const pdfBlob = response.data;
        const url = URL.createObjectURL(pdfBlob);

        techExcel ? window.open(url, "_blank") : setPdfUrl(url);
      } else {
        console.error("Error fetching PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleStatusPanNO = async (payload) => {
    try {
      const response = await handleStatusPanNOApi(payload);
      toast.success(response.data.message);
    } catch (error) {
      console.error("error", error);
    }
  };

  //////////////////------Dashboard APIs------/////////////////////
  // POST - Fetch application status
  const FetchApplicationStatusData = async (month) => {
    try {
      const body = {
        month: month,
      };
      const params = {
        month: body.month
      };
      const response = await FetchApplicationStatusDataApi(body, params);
      setApplicationStatusData(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  ////////////////////-----Settings APIs-----//////////////////////

  const FetchIntroducerCode = async (codeType) => {
    setCodes([]);

    const chunkArray = (arr, size) => {
      return arr.reduce((acc, _, i) => {
        if (i % size === 0) {
          // console.log("acc", acc, _);
          acc.push(arr.slice(i, i + size));
        }
        return acc;
      }, []);
    };
    try {
      const params = {
        code_type: codeType,
        objective: 'get_intro_code'
      };
      const response = await FetchIntroducerCodeApi(params);
      const data = response.data.data
        ? chunkArray(response.data.data, 11)
        : [];
      setCodes(data);
    } catch (error) {
      console.log("error", error);
      toast.error(error.response ? error.response.data.message : error.message);
    }
  };

  const AddIntroducerCode = async (payload) => {
    try {
      const params = {
        code: payload.code,
        code_type: payload.codeType
      };
      const response = await AddIntroducerCodeApi(params);

      FetchIntroducerCode(payload.codeType);
      toast.success(response.data.message);
    } catch (error) {
      console.log("error", error);
      toast.error(error.response ? error.response.data.message : error.message);
    }
  };


  const ChangeAppStatus = async (panNo, updatedKyc, updatedPenny) => {
    try {
      const params = {
        pan_no: panNo,
        kyc_status: updatedKyc,
        penny_status: updatedPenny
      };
      const response = await ChangeAppStatusApi(params);

      setEditedKycStatus({});
      toast.success(response.data.message);
      fetchTableData("inprocess_f");
    } catch (error) {
      console.log("error", error);
      toast.error("Invalid PAN");
    }
  };


  // GET - Insert NON KRA PAN
  const FetchNonKraPan = async () => {
    setNonKraPan([]);
    try {
      const response = await FetchNonKraPanApi();
      setNonKraPan(response.data.data);
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
    }
  };

  // POST - Insert NON KRA PAN

  const [errorPan, setErrorPan] = useState("");

  const AddNonKraPan = async (payload) => {
    setErrorPan("");
    try {
      const params = {
        pan_list: payload
      };
      const response = await AddNonKraPanApi(params);
      toast.success(response.data.message);
      FetchNonKraPan();
    } catch (error) {
      console.log("error", error);
      setErrorPan(error?.response?.data?.message);
      toast.error("Invalid PAN");
    }
  };

  // Delete - DELETE PAN
  const handleDeletePan = async (payload) => {
    try {
      const params = {
        pan_no: payload.pan,
        reason: payload.reason
      };
      const response = await handleDeletePanApi(params);
      handleFetchPanReport();
      toast.success(response.data.message);
    } catch (error) {
      console.log("error", error);
      toast.error(error.response ? error.response.data.message : error.message);
    }
  };
  // PAN name check API 


  const handlePanNameCheck = async (params) => {
    setIsLoading(true);
    setPanCheckData({});
    try {
      const response = await handlePanNameChecksApi(params);
      console.log("response", response);
      setPanCheckData(response.data.data);
      toast.success(response.data.message);
    } catch (error) {
      console.log("error", error);
      handleCatch(error)
    } finally {
      setIsLoading(false);
    }
  };


  // GET - fetch texchexcel-reopen  PAN
  const [reopenPan, setReopenPan] = useState([]);

  const FetchReopenData = async () => {
    setReopenPan([]);
    try {
      const response = await FetchReopenDataApi();
      setReopenPan(response.data.data);
    } catch (error) {
      console.log("error", error);
      toast.error(error?.response?.data?.message);
    }
  };

  // POST - add texchexcel-reopen PAN
  const [errorReopenPan, setErrorReopenPan] = useState("");
  const AddReopenPan = async (payload) => {
    setErrorReopenPan("");
    try {
      const params = {
        pan_no: payload
      };
      const response = await AddReopenPanApi(params);

      toast.success(response.data.message);
      FetchReopenData();
    } catch (error) {
      console.log("error", error);
      setErrorReopenPan(error?.response?.data?.message);
      toast.error("Invalid PAN");
    }
  };

  // GET - GET PAN name
  const [panName, setPanName] = useState("");
  const handleFetchPanName = async (payload) => {
    try {
      const params = {
        pan_no: payload.pan
      };
      const response = await handleFetchPanNameApi(params);
      setPanName(response.data.data.pan_name);
    } catch (error) {
      console.log("error", error);
      toast.error(error.response ? error.response.data.message : error.message);
    }
  };
  // GET - GET PAN  Delete Report

  const [panReport, setPanReport] = useState([]);

  const handleFetchPanReport = async () => {
    try {
      const response = await handleFetchPanReportApi();

      setPanReport(
        response.data.data?.map((item, index) => ({ ...item, id: index + 1 }))
      );
    } catch (error) {
      console.log("error", error);
      toast.error(error.response ? error.response.data.message : error.message);
    }
  };

  const handleUploadPhysicalDocuments = async (values, pan_no) => {
    try {
      const formData = new FormData();
      values.signature && formData.append("signature_proof", values.signature);
      values.selfie && formData.append("selfie_proof", values.selfie);
      values.adhar_front &&
        formData.append("aadhar_front_proof", values.adhar_front);
      values.adhar_back &&
        formData.append("aadhar_back_proof", values.adhar_back);
      values.pan_card && formData.append("pan_proof", values.pan_card);
      values.bank_proof && formData.append("bank_proof", values.bank_proof);
      values.financial_proof &&
        formData.append("financial_proof", values.financial_proof);

      const params = {
        pan_no
      };

      const response = await handleUploadPhysicalDocumentsApi(formData, params);

      handleFetchPhysicalDocuments(params);
      console.log("response", response)
    } catch (error) {
      console.log("error", error);
      toast.error(error.response ? error.response.data.message : error.message);
    }
  };

  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  const handleFetchPhysicalDocuments = async (pan_no) => {
    try {
      const params = {
        pan_no
      };
      const response = await handleFetchPhysicalDocumentsApi(params);

      setUploadedDocuments(response.data.data);
    } catch (error) {
      console.log("error", error);
      toast.error(error.response ? error.response.data.message : error.message);
    }
  };


  const fetchConnectCrentialsData = async (globalFilter = "") => {
    setData([]);
    setRowCount([]);
    setIsLoading(true);

    const params = {
      page_index: pagination.pageIndex + 1,
      page_size: pagination.pageSize,
      ...(globalFilter && { client_code: globalFilter }),
    };

    console.log('params', params)

    try {
      const response = await fetchConnectCrentialsDataApi(params);
      setData(response.data.data.login_data);
      setRowCount(response?.data?.data.row_count);
    } catch (error) {
      handleCatch(error)
      console.error("Error fetching data", error);
      setIsError(true);
    } finally {
      // Update loading states
      setIsLoading(false);
    }
  };

  const fetchEkycCompletedLogs = async (fromDate, toDate, isDownload) => {
    setData([]);
    setIsLoading(true);
    try {
      const params = {
        from_date: fromDate,
        to_date: toDate,
        is_download: isDownload,
      }
      const response = await fetchEkycCompletedLogsApi(params);
      if (isDownload) {
        const base64String = response.data.data;

        const binaryString = window.atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link to download the file
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `CompletedApplicationsReport.xlsx`);

        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        setData(response.data.data);
      }
    } catch (error) {
      handleCatch(error)
    } finally {
      setIsLoading(false);
    }
  }

  const fetchEkycPennyGeoLogs = async (fromDate, toDate, logType, is_download) => {
    setData([]);
    setIsLoading(true);
    try {
      const params = {
        from_date: fromDate,
        to_date: toDate,
        is_download: is_download,
        log_type: logType
      }
      const response = await handleFetchPennyGeoLogsApi(params);
      if (is_download) {
        const base64String = response.data.data;

        const binaryString = window.atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link to download the file
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `PennyGeoLogsReport.xlsx`);

        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        setData(response.data.data);
      }
    } catch (error) {
      handleCatch(error)
    } finally {
      setIsLoading(false);
    }
  }

  const handleFetchIpvLogs = async () => {
    setData([]);
    setIsLoading(true);
    try {
      const response = await handleFetchIpvLogsApi();

      console.log('response', response)

      setData(
        response.data.data?.map((item, index) => ({ ...item, sr_no: index + 1 }))
      );
    } catch (error) {
      console.log("error", error);
      handleCatch(error)
    } finally {
      setIsLoading(false);
    }
  };


  const handleUpdateApplicationData = async (pan_no, values) => {
    setIsLoading(true);
    setKycAdharDetails({})
    try {
      const params = {
        pan_no: pan_no
      };

      const body = {
        "first_name": values?.first_name,
        "middle_name": values?.middle_name,
        "last_name": values?.last_name,
        "current_address1": values?.current_address1,
        "current_address2": values?.current_address2,
        "current_address3": values?.current_address3,
        "current_city": values?.current_city,
        "current_state": values?.current_state,
        "current_pincode": values?.current_pincode,
        "current_country": values?.current_country,
        "permanent_address1": values?.permanent_address1,
        "permanent_address2": values?.permanent_address2,
        "permanent_address3": values?.permanent_address3,
        "permanent_city": values?.permanent_city,
        "permanent_state": values?.permanent_state,
        "permanent_pincode": values?.permanent_pincode,
        "permanent_country": values?.permanent_country,
        "nationality": values?.nationality,
        "proof_of_address": values?.proof_of_address,
        "father_name": values?.father_name,
        "mother_name": values?.mother_name,
        "ifsc": values?.ifsc,
        "bank_name": values?.bank_name,
        "micr": values?.micr,
        "bank_address": values?.bank_address,
        "bank_city": values?.bank_city,
        "bank_state": values?.bank_state,
        "bank_pincode": values?.bank_pincode,
        "karta_father_spouse_name": values?.karta_father_spouse_name,
        "karta_mother_name": values?.karta_mother_name,
        "karta_nationality": values?.karta_nationality,
        "karta_cor_address1": values?.karta_cor_address1,
        "karta_cor_address2": values?.karta_cor_address2,
        "karta_cor_address3": values?.karta_cor_address3,
        "karta_cor_pincode": values?.karta_cor_pincode,
        "karta_cor_city": values?.karta_cor_city,
        "karta_cor_state": values?.karta_cor_state,
        "karta_cor_country": values?.karta_cor_country,
        "karta_per_address1": values?.karta_per_address1,
        "karta_per_address2": values?.karta_per_address2,
        "karta_per_address3": values?.karta_per_address3,
        "karta_per_pincode": values?.karta_per_pincode,
        "karta_per_city": values?.karta_per_city,
        "karta_per_state": values?.karta_per_state,
        "karta_per_country": values?.karta_per_country
      };

      const response = await handleUpdateApplicationDataApi(params, body);
      console.log("response", response)
      toast.success(response.data.message)
      handleFetchDataByPan(pan_no)
    } catch (error) {
      console.log("error", error);
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };


  const exportKycGetDataCsvFile = async (kycStatus, hufPayload) => {
    setIsLoading(true);
    try {
      const params = {
        kyc_status: kycStatus,
        pan_type: hufPayload ? hufPayload : undefined,
        is_download: true
      };

      const response = await exportKycCsvFileApi(params);
      console.log('response', response);

      const base64String = response.data.data;

      const binaryString = window.atob(base64String);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a link to download the file
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${kycStatus}-applications.xlsx`);

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <KycContext.Provider
      value={{
        fetchTableData,

        fetchHufTableData,
        hufData,

        kycAdharDetails,
        handleFetchDataByPan,
        isError,
        data,
        rowCount,
        isLoading,
        kycStatus,
        setKycStatus,
        sorting,
        setSorting,
        pagination,
        setPagination,
        techExcelData,
        fetchTechExcelData,
        handleFetchEsignPdf,
        pdfDialogOpen,
        setPdfDialogOpen,
        pdfUrl,
        handleNomineeData,
        kycNomineeData,
        handleStatusPanNO,

        updateClientCodeTechExcel,
        techExcelLoader,

        //Dashboard
        FetchApplicationStatusData,
        applicationStatusData,

        //settings
        AddNonKraPan,
        errorPan,
        FetchNonKraPan,
        nonKraPan,
        FetchReopenData,
        reopenPan,
        AddReopenPan,
        errorReopenPan,
        handlePanNameCheck,
        panCheckData,
        setPanCheckData,


        panName,
        setPanName,
        handleFetchPanName,
        panReport,
        setPanReport,
        handleFetchPanReport,

        codes,
        FetchIntroducerCode,
        AddIntroducerCode,

        handleDeletePan,

        ChangeAppStatus,
        editedKycStatus,
        setEditedKycStatus,

        handleUploadPhysicalDocuments,
        handleFetchPhysicalDocuments,
        uploadedDocuments,


        fetchConnectCrentialsData,

        fetchEkycCompletedLogs,

        fetchEkycPennyGeoLogs,

        handleFetchIpvLogs,

        handleUpdateApplicationData,

        exportKycGetDataCsvFile
      }}
    >
      {props.children}
    </KycContext.Provider>
  );
};
