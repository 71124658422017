import { commonApiRequest } from "utils/CommonFunctions";

// export const fetchEmployeesListApi = () => {
//     const url = `${process.env.REACT_APP_URL}/v1/user/fetch-user-list?source=active_user_list`;
//     return commonApiRequest('GET', url);
// };
export const fetchEmployeesListApi = () => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/chat_get_all_users`;
    return commonApiRequest('GET', url);
};

export const fetchChatListApi = () => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/chat_get`;
    return commonApiRequest('GET', url);
};

export const createNewChatApi = (body) => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/chat_create`;
    return commonApiRequest('POST', url, body);
};

export const fetchChatHistoryApi = (body) => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/message_get`;
    return commonApiRequest('POST', url, body);
};

export const sendMessageApi = (formData) => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/message_add`;
    return commonApiRequest('POST', url, formData);
};

export const forwardMessageApi = (body) => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/forward_message`;
    return commonApiRequest('POST', url, body);
};

export const fetchGroupListApi = () => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/groups_get`;
    return commonApiRequest('GET', url);
};

export const messagePinStarApi = (body) => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/message_pin_star`;
    return commonApiRequest('POST', url, body);
};

export const fetchAllStarredMsgApi = (body) => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/chat_get_all_starred`;
    return commonApiRequest('POST', url, body);
};

export const fetchAllFilesApi = (body) => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/chat_get_all_files`;
    return commonApiRequest('POST', url, body);
};

export const groupModifyApi = (body) => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/chat_group_update`;
    return commonApiRequest('PUT', url, body);
};

export const commonGroupsApi = (body) => {
    const url = `${process.env.REACT_APP_URL_CHAT}/V1/chat_get_common_groups`;
    return commonApiRequest('POST', url, body);
};
