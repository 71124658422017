import React from "react";

import CommonUtilsIndex from "views/utilities/common-components/CommonUtilsIndex";
import BounceFeatures from "config-details/features/BounceFeatures";

const BounceUtilities = () => {

  return (
    <CommonUtilsIndex allFeatures={BounceFeatures} />
  );
};

export default BounceUtilities;