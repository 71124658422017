/*eslint-disable*/
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import {
  addExchangeAlert,
  deleteExchangeAlert,
  fetchExchangeAlertDashboard,
  fetchExchangeAlertData,
  updateExchangeAlertData
} from "services/exchange-alert/ExchangeAlert";
import { formatDateYMD, loadBase64ToFile } from "utils/CommonFunctions";
export const ExchangeAlertContext = createContext();
export default function ExchangeAlert(props) {
  const [exchangeAlertData, setExchangeAlertData] = useState([]);
  const [dashboardAlertData, setDashboardAlertData] = useState({});
  const [openAttachmentDialog, setOpenAttachmentDialog] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState(null);
  const [exchangeType, setExchangeType] = useState({
    exchange: "nse",
    alertType: "all"
  });
  const [attachmentName, setAttachmentName] = useState("");
  const handleAddExchangeAlert = async (values, setOpenDailog) => {
    try {
      var formData = new FormData();
      formData.append("exchange", values.exchange);
      formData.append("alert_type", values.alert_type);
      formData.append("alert_id", values.alert_id);
      formData.append("alert_date", formatDateYMD(values.alert_date));
      formData.append("subject", values.subject);
      formData.append("body", values.body);

      { values?.uploadedFiles[0] && formData.append("attachment", values?.uploadedFiles[0]); }

      formData.append(
        "last_date_to_replay",
        formatDateYMD(values.last_date_to_replay)
      );
      formData.append("criticality", values.criticality);
      const response = await addExchangeAlert(formData)
      const resJson = response.data;
      if (resJson.status === "success") {
        setOpenDailog(false);
        toast.success(resJson.message);
        handleFetchExchangeAlertData(values.exchange, "all");
        setExchangeType({
          exchange: values.exchange,
          alertType: "all"
        })
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Network Error");
      }
    }
  };
  const handleFetchExchangeAlertData = async (exchange_type, alert_type) => {
    setExchangeAlertData([]);
    const paramsData = {
      exchange_type: exchange_type,
      alert_type: alert_type
    };
    try {
      const response = await fetchExchangeAlertData(paramsData)
      const resJson = response.data;
      setExchangeAlertData(resJson.data);
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Network Error");
      }
    }
  };
  const handleUpdateExchangeAlertData = async (
    values,
    from,
    setOpen = () => { }
  ) => {
    try {
      console.log("values.attachment", values.attachment.length);

      var formdata = new FormData();
      formdata.append("body", values.body);
      if (values.attachment.length > 0) {
        {
          from === "row" &&
            formdata.append(
              "attachment",
              values.uploadedFiles.length === 0
                ? Object.values(loadBase64ToFile(values.attachment))[0]
                : values.uploadedFiles[0]
            );
        }
        {
          from === "cell" &&
            formdata.append(
              "attachment",
              Object.values(loadBase64ToFile(values.attachment))[0]
            );
        }
      }

      formdata.append("last_date_to_replay", values.last_date_to_replay);
      formdata.append("subject", values.subject);
      formdata.append("criticality", values.criticality);
      formdata.append("alert_date", values.alert_date);
      formdata.append("status", values.status);
      formdata.append("assign_department_id", values.assign_department_id);
      formdata.append("id", values.id);
      formdata.append("alert_type", values.alert_type);
      formdata.append("alert_id", values.alert_id);
      const response = await updateExchangeAlertData(formdata)
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFetchExchangeAlertData(exchangeType.exchange, exchangeType.alertType);
        toast.success(resJson.message);
        if (from === "row") {
          setOpen(false);
        }
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Network Error");
      }
    }
  };
  const handleFetchExchangeAlertDashboard = async () => {
    setDashboardAlertData([]);
    try {
      const response = await fetchExchangeAlertDashboard()
      const resJson = response.data;
      if (resJson.status === "success") {
        setDashboardAlertData(resJson?.data[0]);
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Network Error");
      }
    }
  };


  const handleDeleteExchangeAlert = async (id) => {
    try {
      const paramsData = {
        id
      }

      const response = await deleteExchangeAlert(paramsData)
      if (response.status === 200) {
        handleFetchExchangeAlertData(exchangeType.exchange, exchangeType.alertType);
      }
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Network Error");
      }
    }
  };
  return (
    <ExchangeAlertContext.Provider
      value={{
        setExchangeAlertData,
        handleAddExchangeAlert,
        exchangeAlertData,
        setAttachmentName,
        attachmentName,
        setAttachmentUrl,
        attachmentUrl,
        openAttachmentDialog,
        setOpenAttachmentDialog,
        handleUpdateExchangeAlertData,
        handleFetchExchangeAlertDashboard,
        dashboardAlertData,
        handleFetchExchangeAlertData,
        exchangeType, setExchangeType,
        handleDeleteExchangeAlert
      }}
    >
      {props.children}
    </ExchangeAlertContext.Provider>
  );
}
