import { FILE } from "config";

const EDDPIFeatures = [
  {
    app_id: 9,
    feature_id: 901,
    feature_name: "eddpi-request",
    type: FILE,
    subfolder: [],
    label: "EDDPI Request",
    path: "/eddpi/eddpi-request"
  },
  {
    app_id: 9,
    feature_id: 902,
    feature_name: "eddpi-log",
    type: FILE,
    subfolder: [],
    label: "EDDPI Log",
    path: "/eddpi/eddpi-log"
  }
];

export default EDDPIFeatures;