/*eslint-disable*/
import { commonApiRequestNew } from "utils/CommonFunctions";

const apiUrl = process.env.REACT_APP_URL_BULK_MAIL;

export const sendMailsApi = async (body) => {
  const url = `${apiUrl}/api/v1/send-mails`;
  return commonApiRequestNew("POST", url, body);
};

export const getAllTemplatesApi = async () => {
  const url = `${apiUrl}/api/v1/templates`;
  return commonApiRequestNew("GET", url);
};

export const getTemplateByIDApi = async (id) => {
  const url = `${apiUrl}/api/v1/templates/${id}`;
  return commonApiRequestNew("GET", url);
};

export const addTemplatesApi = async (body) => {
  const url = `${apiUrl}/api/v1/templates`;
  return commonApiRequestNew("POST", url, body);
};

export const updateTemplatesApi = async (id, body) => {
  const url = `${apiUrl}/api/v1/templates/${id}`;
  return commonApiRequestNew("PUT", url, body);
};

export const deleteTemplatesApi = async (id) => {
  const url = `${apiUrl}/api/v1/templates/${id}`;
  return commonApiRequestNew("DELETE", url);
};

export const fetchMyListTabData = async () => {
  const url = `${apiUrl}/api/v1/mail-list`;
  return commonApiRequestNew("GET", url);
};

export const crudMyListTabData = async (body, status, id) => {
  let endPoint =
    status == "edit" || status == "delete"
      ? `api/v1/mail-list/${id}`
      : "api/v1/mail-list";
  let method =
    status == "edit" ? "PUT" : status == "delete" ? "DELETE" : "POST";
  const url = `${apiUrl}/${endPoint}`;
  return commonApiRequestNew(method, url, body);
};

export const fetchMyAddressBookData = async () => {
  const url = `${apiUrl}/api/v1/address-book`;
  return commonApiRequestNew("GET", url);
};

export const crudMyAddressBookData = async (body, status, id) => {
  let endPoint =
    status == "edit" || status == "delete"
      ? `api/v1/address-book/${id}`
      : "api/v1/address-book";
  let method =
    status == "edit" ? "PUT" : status == "delete" ? "DELETE" : "POST";
  const url = `${apiUrl}/${endPoint}`;
  return commonApiRequestNew(method, url, body);
};

export const fetchUserBook = async () => {
  const url = `${apiUrl}/api/v1/address-book/fetch-add-book`;
  return commonApiRequestNew("GET", url);
};

export const fetchDefaultTemplateAPI = async () => {
  const url = `${apiUrl}/assets/default-template.html`;
  const authToken = localStorage.getItem('authToken'); 
  console.log('hey 1.5', )

  const response = await fetch(url, {
    headers: {
      'auth-token': authToken, 
      'Content-Type': 'application/json' 
    }
  });
  console.log('hey 2', )
  return response.text();
};

export const fetchDefaultTemplate2API = async () => {
  const url = `${apiUrl}/assets/default-template-2.html`;
  const authToken = localStorage.getItem('authToken'); 

  const response = await fetch(url, {
    headers: {
      'auth-token': authToken, 
      'Content-Type': 'application/json' 
    }
  });

  return response.text();
};

export const fetchVariables = async () => {
  const url = `${apiUrl}/api/v1/variable`;
  return commonApiRequestNew("GET", url);
};

export const addVariables = async (body) => {
  const url = `${apiUrl}/api/v1/variable`;
  return commonApiRequestNew("POST", url, body);
};

export const getMailLogs = async () => {
  const url = `${apiUrl}/api/v1/mail-logs`;
  return commonApiRequestNew("GET", url);
}

export const getMailLogsByID = async (params) => {
  const url = `${apiUrl}/api/v1/mail-logs/${params.id}`;
  return commonApiRequestNew("GET", url);
}

export const getTemplate = async (filePath) => {
  const url = `${apiUrl}/${filePath}`;
  const authToken = localStorage.getItem('authToken'); 

  const response = await fetch(url, {
    headers: {
      'auth-token': authToken, 
      'Content-Type': 'application/json' 
    }
  });

  return response.text();
}