import { commonApiRequest, commonApiRequestNew } from "utils/CommonFunctions";

export const fetchFixedAllocation = () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-fixed-allocation-data`;
  return commonApiRequest("GET", url);
};

export const fetchLiquidBeesData = async () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-liquidbees-data`;
  return commonApiRequest("GET", url);
};

export const fetchTrailBalanceData = async (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-trail-balance-data`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchBodAllocationData = () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-final-report-data`;
  return commonApiRequest("GET", url);
};

export const fetchEodAllocationData = () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-eod-data`;
  return commonApiRequest("GET", url);
};

export const fetchMCXAllocationData = () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-MCX-final-report-data`;
  return commonApiRequest("GET", url);
};

export const fetchFundDataa = () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/fetch_funds`;
  return commonApiRequest("GET", url);
};

export const fetchSelectedClientLiveMoniterData = (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-overall-utilisation-data`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchSelectedClientShortAllocationData = (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/Short_utilisation_data`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchDataFavUtilisation = (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-fav-utilisation-data`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchReportsEODDataa = (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/fetch-Snapshot-EOD-data`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchSnapShortReportsEODDataa = (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/fetch-short-Snapshot-EOD-data`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchReportsSSDataa = (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/fetch-combinepeaksnapShot-data`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchShortAllocationReportsSSDataa = (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/fetch-combine-short-snapShot-data`;
  return commonApiRequest("GET", url, null, params);
};

export const eodReportView = (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-data`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchFavClient = (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/fetch-client-filter-wise`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchShortReport = (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/short_allocation_report`;
  return commonApiRequest("GET", url, null, params);
};

export const addFixedAllocation = (body) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/insert-fixed-allocation`;
  return commonApiRequest("POST", url, body);
};

export const updateFixedAllocation = async (body) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/update-fixed-allocation-data`;
  return commonApiRequest("POST", url, body);
};

export const deleteFixedAllocation = async (paramsData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/delete-fixed-allocation`;
  return commonApiRequest("POST", url, null, paramsData);
};

export const fileUploadFixedAllocation = async (formData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/upload-fixed-allocation-file`;
  return commonApiRequest("POST", url, formData);
};

export const addLiquidBees = async (body) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/insert-liquidbees-data`;
  return commonApiRequest("POST", url, body);
};

export const updateLiquidBees = async (body) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/update-liquidbees-data`;
  return commonApiRequest("POST", url, body);
};

export const deleteLiquidBees = async (paramsData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/delete_liquidbees-data`;
  return commonApiRequest("POST", url, null, paramsData);
};

export const fileUploadLiquidBees = async (formData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/upload-liquidbees-file`;
  return commonApiRequest("POST", url, formData);
};

export const addPtb = async (body) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/insert-trial-balance-data`;
  return commonApiRequest("POST", url, body);
};

export const updatePtb = async (body) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/update-trial-balance-data`;
  return commonApiRequest("POST", url, body);
};

export const deleteTrailBalance = async (deleteId, identity) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/delete-trial-balance-data?id=${deleteId.Client_Id}&Identity=${identity}`;
  return commonApiRequest("POST", url);
};

export const performMCXAllocationFileDownload = async () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-MCX-final-report-data`;
  return commonApiRequest("GET", url);
};

export const fetchCashPositionDataa = async (client_id = "") => {
  const url = client_id
    ? `/V1/getCMPOLive?client_id=${client_id}`
    : `/V1/getCMPOLive`;

  const apiUrl = `${process.env.REACT_APP_URL_RTRMS}${url}`;
  return commonApiRequest("GET", apiUrl);
};

export const bodAllocationFileDownload = async () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/download-collateral-file`;
  return commonApiRequest("GET", url, null, null, "blob");
};

export const eodAllocationFileDownload = async () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/download-eod-collateral-file`;
  return commonApiRequest("GET", url, null, null, "blob");
};

export const bodAllocationFileDifferenceDownload = async () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/download-allocation-files`;
  return commonApiRequest("GET", url, null, null, "blob");
};

export const eodAllocationFileDifferenceDownload = async () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/download-eod-allocation-files`;
  return commonApiRequest("GET", url, null, null, "blob");
};

export const uploadSnapShot = async (formData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/upload-snapshot-file`;
  return commonApiRequest("POST", url, formData);
};

export const uploadShortAllocationSnapShot = async (formData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/upload-short-snapshot-file`;
  return commonApiRequest("POST", url, formData);
};

export const uploadShortAllocationTemp = async (formData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/upload-short-file`;
  return commonApiRequest("POST", url, formData);
};

export const fileUploadEod = async (formData, amount) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/upload-EOD-file?amount=${amount}`;
  return commonApiRequest("POST", url, formData);
};

export const fileUploadBod = async (formData, amount) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/upload-BOD-file?amount=${amount}`;
  return commonApiRequest("POST", url, formData);
};

export const fileUploadMCX = async (formData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/upload-MCX-file`;
  return commonApiRequest("POST", url, formData);
};

export const fileUploadInMarket = async (formData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/upload-inmarket-file`;
  return commonApiRequest("POST", url, formData);
};

export const fetchDataOverallUtilisation = async (paramsData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-overall-utilisation-data`;
  return commonApiRequest("GET", url, null, paramsData);
};

export const fetchEodReportData = async (paramsData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/download-files`;
  return commonApiRequest("GET", url, null, paramsData, "blob");
};

export const fetchFavClientData = async (values, paramsData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/add-update-fav-client`;
  return commonApiRequest("POST", url, values, paramsData);
};

export const fetchDeleteFavClient = async (paramsData) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/delete-fav-client`;
  return commonApiRequest("DELETE", url, null, paramsData);
};

export const fetchLiveMonitorReport = async () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/download-all-utilisation-data`;
  return commonApiRequest("GET", url, null, null, "blob");
};

export const fetchShortAllocationExchangeData = async (params) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/download_manualsnapshot_data`;
  return commonApiRequest("GET", url, null, params, "blob");
};

export const fetchUpdateFunds = async (body) => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/update_funds`;
  return commonApiRequest("PUT", url, body);
};

export const uploadSegregationFiles = async (formData) => {
  const url = `${process.env.REACT_APP_URL_SEGREGATION}/upload`;
  return commonApiRequestNew("POST", url, formData);
};

export const segregationMismatchApi = async (params) => {
  const url = `${process.env.REACT_APP_URL_SEGREGATION}/getReport`;
  return commonApiRequestNew("GET", url, null, params);
};
