import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useErrorHandler = () => {
  const navigate = useNavigate();
  const handleError = async (error) => {
    let err;

    if (error?.code === "ERR_CANCELED") {
      return;
    }

    if (error?.response?.data) {
      if (error.response.data.message) {
        err = error.response.data.message;
      }
      if (error.response.data.detail) {
        err = error.response.data.detail;
      }
    }
    if (error.response?.status === 401) {
      toast.error(err || "Token has expired, Pls Login again!");
      localStorage.clear();
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else if (error.response?.status === 404) {
      toast.error(err || "No Data Found");
    } else {
      toast.error(err || error.message);
    }
  };

  return handleError;
};
