/* eslint-disable */
import React, { useContext } from "react";
import { Grid, TextField, Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import * as Yup from "yup";

import { ItinfraContext } from "context/it-infra/ItInfraApi";

export default function AddBrand({ setBrandDialog }) {
  const { handleAddBrand } = useContext(ItinfraContext);

  const initialValues = {
    brand: "",
  };

  const brandSchema = Yup.object().shape({
    brand: Yup.string().required("Brand name is Required"),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={brandSchema}
        enableReinitialize
        onSubmit={(values) => handleAddBrand(values, setBrandDialog)}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          errors,
          touched,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Brand Name"
                  value={values.brand}
                  name="brand"
                  SelectProps={{
                    style: { fontSize: 14 },
                    MenuProps: {
                      style: {
                        maxHeight: 240,
                        width: 100,
                      },
                    },
                  }}
                  size="small"
                  onChange={(e) =>
                    setFieldValue("brand", e.target.value.toUpperCase())
                  }
                  onBlur={handleBlur}
                  error={touched.brand && Boolean(errors.brand)}
                  helperText={touched.brand && errors.brand}
                />
              </Grid>
              <Grid
                item
                xs={4}
                md={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  id="submit-button"
                  type="submit"
                  variant="outlined"
                  style={{
                    color: "#0E3935",
                    border: "1px solid #dee2e6",
                    backgroundColor: "#DAE7E6",
                  }}
                  disabled={isSubmitting}
                >
                  Add
                </Button>
                <Button
                  id="cancel-button"
                  type="button"
                  variant="outlined"
                  style={{
                    color: "#0E3935",
                    border: "1px solid #dee2e6",
                    backgroundColor: "#DAE7E6",
                    marginRight: "10px",
                  }}
                  onClick={() => setBrandDialog(false)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
}
