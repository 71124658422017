/*eslint-disable*/
import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import TotalExpenseReport from "views/utilities/ria-wms/reports";


const AccountHome = Loadable(
  lazy(()=>import("views/utilities/accounts/dashboard"))
)

const AccountIndex =  Loadable(lazy(() => import("views/utilities/accounts/index")));

const AccountsUtilities = Loadable(
  lazy(() => import("views/utilities/accounts/utils"))
);

const AccountReport = Loadable(
  lazy(()=> import("views/utilities/accounts/reports")
))

const AtomPay = Loadable(
  lazy(() => import("views/utilities/accounts/utils/atom-pay"))
);

const Extra1 = Loadable(
  lazy(() => import("views/utilities/accounts/utils/extra1"))
);

const ExtraReport = Loadable(
  lazy(()=> import("views/utilities/accounts/reports/extra-report"))
)

const AtomPayReport = Loadable(
  lazy(()=> import("views/utilities/accounts/reports/atom-pay-report"))
)


export const AccountsModule = {
  path: "accounts",
  element: <AccountIndex />,
  children: [
    {
      path: "home",
      element: <AccountHome />,
    },
    {
      path: "utils",
      element: <AccountsUtilities />,
    },
    {
      path: "reports",
      element: <AccountReport />,
    },
  ],
};

export const AccountsUtilsRoutes = {
  path: "accounts",
  children: [
    {
      path: "atom-pay",
      element: <AtomPay />,
    },
    {
      path: "extra1",
      element: <Extra1 />,
    },
  ],
};

export const AccountsReportsRoutes = {
  path: "accounts",
  children: [
    {
      path: "extra-report",
      element: <ExtraReport/>
    },
    {
      path: "atom-pay-report",
      element: <AtomPayReport/>
    },
  ],
};
