/*eslint-disable*/
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Badge, Box, ButtonBase, IconButton, Tooltip } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
// import SearchSection from './SearchSection';
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";
import DisplayResizeSection from "./DisplayResizeSection";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { useNavigate } from "react-router-dom";

// assets
import { IconMenu2 } from "@tabler/icons";
import { getSessionStorage } from "utils/encrpytion";
import { Mail } from "@mui/icons-material";
import driveIcon from "assets/images/icons/drive-48.png"
import projectIcon from "assets/images/icons/project-50.png"
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();

  const navigate = useNavigate();
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto"
          }
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: "22px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: "white",
              color: theme.palette.secondary.dark,
              "&:hover": {
                // background: theme.palette.secondary.dark,
                zIndex: "1",
                background: "#F5F5F5",
                color: theme.palette.secondary.dark,
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <DisplayResizeSection />


      <Tooltip title="Mail">
        <IconButton
          onClick={() => {
            navigate("/utilities/e-mail");
          }}
        >
          <Badge
            badgeContent="!"
            color="error" // Red color
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Mail />
          </Badge>
        </IconButton>
      </Tooltip>
      <Tooltip title="My Dashboard">
        <IconButton
          onClick={() => {
            getSessionStorage("user_type") === "super_user" &&
              navigate("/director-dashboard");
          }}
        >
          <DashboardIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="My Performance">
        <IconButton>
          <ElectricBoltIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Drive">
        <IconButton onClick={() => navigate("/shared-drive/home/my-drive")}>
          <img src={driveIcon} alt="drive" width="25px" />
        </IconButton>
      </Tooltip>
      {
        (getSessionStorage("user_role")?.toLowerCase() === "admin" || getSessionStorage("user_type")?.toLowerCase() === "super_user" || getSessionStorage("user_profile")?.toLowerCase() === "dept_manager") && <Tooltip title="PMS Master Dashboard">
          <IconButton onClick={() => navigate("pms/master-dashboard")}>
            <img src={projectIcon} alt="pms dashboard" width="25px" />
          </IconButton>
        </Tooltip>
      }
      <NotificationSection />
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
