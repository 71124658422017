/*eslint-disable*/
import dashboard from "./dashboard";
// import pages from './pages';
import utilities from "./utilities";
import uploads from "./uploads";
import userManagement from "./userManagement";
import roleManagement from "./roleManagement";
import helpDesk from "./helpDesk";
import reports from "./reports";
import appManagement from "./appManagement";
import { getSessionStorage } from "utils/encrpytion";
import PmsMenuItems from "./pms";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = () => {
  const user_type = getSessionStorage("user_type");
  const user_id = getSessionStorage('UID');
  // const department_name = getSessionStorage('department_name');


  const temporaryAccessToUserManage = process.env.REACT_APP_USER_MANAGE_ACCESS_LIST?.split(",");
  const temporaryAccessToRoleManage = process.env.REACT_APP_ROLE_MANAGE_ACCESS_LIST?.split(",");

  let menuItemList = [];

  // Define a mapping of roles to menu items

  const roleMenuMapping = {
    super_user: [
      dashboard,
      utilities,
      userManagement,
      roleManagement,
      appManagement,
      helpDesk,
      PmsMenuItems,
      reports,
    ],
    admin: [
      dashboard,
      utilities,
      appManagement,
      helpDesk,
      PmsMenuItems,
      reports,
    ],
    user: [dashboard, utilities, helpDesk, PmsMenuItems, reports,],
  };

  // const departmentMapping = {
  //   Software: [
  //     dashboard, appManagement, utilities, helpDesk, PmsMenuItems, reports,
  //   ]
  // }

  if (user_type in roleMenuMapping) {
    menuItemList = roleMenuMapping[user_type];
  }
  if (temporaryAccessToUserManage?.includes(user_id) && user_type !== 'super_user') {
    menuItemList?.splice(2, 0, userManagement);
  }
  if (temporaryAccessToRoleManage?.includes(user_id) && user_type !== 'super_user') {
    menuItemList?.splice(2, 0, roleManagement);
  }
  // if (department_name in departmentMapping) {
  //   menuItemList = departmentMapping[department_name];
  // }

  return menuItemList;
};

export default menuItems;
