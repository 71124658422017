/*eslint-disable*/
import React from "react";
import { UtilitesCheck } from "utils/UtilitesCheck";
import CommonUtilsIndex from "views/utilities/common-components/CommonUtilsIndex";
import AlgoSummaryFeatures from "config-details/features/AlgoSummaryFeatures";

const Index = () => {
  const accessibleFeatures = UtilitesCheck(AlgoSummaryFeatures, "features");

  return (
    <>
      <CommonUtilsIndex allFeatures={AlgoSummaryFeatures} />
    </>
  );
};

export default Index;
