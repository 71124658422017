/*eslint-disable*/
import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const ExchangeAlertHome = Loadable(lazy(() => import("views/utilities/exchange-alert")));


const ExchangeAlertDashboard = Loadable(lazy(() => import("views/utilities/exchange-alert/dashboard")));

const ExchangeAlertUtilss = Loadable(lazy(() => import("views/utilities/exchange-alert/utils")));
const ExchangeAlertReport = Loadable(lazy(() => import("views/utilities/exchange-alert/reports")));



const UtilsNse = Loadable(lazy(() => import("views/utilities/exchange-alert/utils/exchange")));
const UtilsCidas = Loadable(lazy(() => import("views/utilities/exchange-alert/utils/cdsl")));



export const ExchangeAlertModule = {
  path: "exchange-alert",
  element: <ExchangeAlertHome />,
  children: [
    {
      path: "home",
      element: <ExchangeAlertDashboard />
    },
    {
      path: "utils",
      element: <ExchangeAlertUtilss />
    },
    {
      path: "reports",
      element: <ExchangeAlertReport />
    }
  ]
};

export const ExchangeAlertUtils = {
  path: "exchange-alert",
  children: [
    {
      path: "exchange",
      element: <UtilsNse />,
    },
    // {
    //   path: "bse",
    //   element: <UtilsBse />,
    // },
    {
      path: "cdsl",
      element: <UtilsCidas />,
    },
  ],
};
