import { commonApiRequest } from "utils/CommonFunctions";

const COMMON_URL = process.env.REACT_APP_URL;

export const fetchAccessAppInfoApi = (params) => {
    const url = `${COMMON_URL}/v1/admin/user-app-access`;
    return commonApiRequest('GET', url, null, params);
};

export const handleAccessAppInfoApi = (body) => {
    const url = `${COMMON_URL}/v1/admin/update-user-app-access`;
    return commonApiRequest('PUT', url, body);
};

export const fetchFeatureReportListApi = () => {
    const url = `${COMMON_URL}/v1/admin/app-report-feature-list`;
    return commonApiRequest('GET', url);
};

export const fetchAccessReportFeatureListApi = (params) => {
    const url = `${COMMON_URL}/v1/admin/report-feature-access`;
    return commonApiRequest('GET', url, null, params);
};

export const handleAccessReportFeatureListApi = (body) => {
    const url = `${COMMON_URL}/v1/admin/update-feature-report-access`;
    return commonApiRequest('PUT', url, body);
};

// export const fetchAccessAppInfoApi = async (newValue) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/user-app-access?user_id=${newValue}`;
//     const response = await axios.get(url, config);
//     return response;
// }

// export const handleAccessAppInfoApi = async (body) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/update-user-app-access`;
//     const response = await axios.put(url, body, config);
//     return response;
// }

// export const fetchFeatureReportListApi = async () => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/app-report-feature-list`;
//     const response = await axios.get(url, config);
//     return response;
// }

// export const fetchAccessReportFeatureListApi = async (empId, app_id = "") => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/report-feature-access?user_id=${empId}&app_id=${typeof app_id === "object" ? '' : app_id}`;
//     const response = await axios.get(url, config);
//     return response;
// }

// export const handleAccessReportFeatureListApi = async (body) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/update-feature-report-access`;
//     const response = await axios.put(url, body, config);
//     return response;
// }