// React Import
import * as React from "react";
import { useState, useEffect } from "react";

//MUI Import
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";

import "./ctclReport.css";
//Components
import MainCard from "ui-component/cards/MainCard";

//Axios Api Call
import axios from "axios";
import { toast } from "react-toastify";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

export default function CtclHistoryReport() {
  const [expanded, setExpanded] = useState(null);

  const [ctclId, setCTCLId] = useState([]);
  const [ctclData, setCtclData] = useState({});
  const [compareData, setCompareData] = useState({});

  console.log("comareData", compareData);
  const handleReport = () => {
    const headers = {
      accept: "application/json",
      authToken: localStorage.getItem("authToken")
    };
    axios
      .get(`${process.env.REACT_APP_URL_CTCL}/view-ctcl-history-data`, {
        headers: headers
      })
      .then((response) => {
        if (response.data.status === "success") {
          console.log("001", response.data.data);
          setCtclData(response.data.data);
          setCTCLId(Object.keys(response.data.data));
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    handleReport();
  }, []);

  const handleChange = (id) => {
    setExpanded((prev) => (prev != id ? id : null));
    setCompareData(ctclData[id][0]);
  };

  const columns = [
    {
      field: "allotment_date",
      headerName: "Allotment Date",
      align: "left",
      width: "100"
    },
    {
      field: "ap_father_name",
      headerName: "Father Name",
      align: "right",
      flex: "auto"
    },
    {
      field: "ap_name",
      headerName: "Name",
      align: "right",
      flex: "auto"
      // renderCell: (params) => <div style={{ color: params.value === compareData['ap_name'] ? '#2f2c2bdb' : 'red' }}>{params.value}</div>
    },
    {
      field: "ap_relation",
      headerName: "Relation",
      align: "right"
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       color:
      //         params.value === compareData["ap_relation"] ? "#2f2c2bdb" : "red"
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // )
    },
    {
      field: "auth_person_name",
      headerName: "Auth Person Name",
      align: "right"
    },
    {
      field: "auth_person_reg_code",
      headerName: "Auth Person Reg Code",
      align: "right"
    },
    { field: "certification", headerName: "certification", align: "right" },
    { field: "certified_by", headerName: "certified_by", align: "right" },
    { field: "city", headerName: "city", align: "right" },
    { field: "conn_mode", headerName: "conn_mode", align: "right" },
    { field: "conn_mode_other", headerName: "conn_mode_other", align: "right" },
    {
      field: "ctcl_address1",
      headerName: "ctcl_address1",
      align: "right"
      // renderCell: (params) => (
      //   // 2f2c2bdb
      //   <div
      //     style={{
      //       color:
      //         params.value === compareData["ctcl_address1"]
      //           ? "#2f2c2bdb"
      //           : "red"
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // )
    },
    {
      field: "ctcl_address2",
      headerName: "ctcl_address2",
      align: "right"
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       color:
      //         params.value === compareData["ctcl_address2"]
      //           ? "#2f2c2bdb"
      //           : "red"
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // )
    },
    {
      field: "ctcl_address3",
      headerName: "ctcl_address3",
      align: "right"
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       color:
      //         params.value === compareData["ctcl_address3"]
      //           ? "#2f2c2bdb"
      //           : "red"
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // )
    },
    { field: "ctcl_id", headerName: "ctcl_id", align: "right" },
    { field: "ctcl_purpose", headerName: "ctcl_purpose", align: "right" },
    { field: "ctcl_status", headerName: "ctcl_status", align: "right" },
    {
      field: "disablement_date",
      headerName: "disablement_date",
      align: "right"
    },
    { field: "dob", headerName: "dob", align: "right" },
    { field: "employment_date", headerName: "employment_date", align: "right" },
    { field: "exam_type", headerName: "exam_type", align: "right" },
    {
      field: "full_address",
      headerName: "full_address",
      align: "right"
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       color:
      //         params.value === compareData["full_address"] ? "#2f2c2bdb" : "red"
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // )
    },
    // { field: 'id', headerName: 'id', align: 'right' },
    { field: "login_id", headerName: "login_id", align: "right" },
    { field: "mar_seg", headerName: "mar_seg", align: "right" },
    {
      field: "nuser_id",
      headerName: "nuser_id",
      align: "right"
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       color:
      //         params.value === compareData["nuser_id"] ? "#2f2c2bdb" : "red"
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // )
    },
    { field: "office_status", headerName: "office_status", align: "right" },
    {
      field: "pan_no",
      headerName: "pan_no",
      align: "right"
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       color: params.value === compareData["pan_no"] ? "#2f2c2bdb" : "red"
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // )
    },
    {
      field: "phone_no",
      headerName: "phone_no",
      align: "right"
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       color:
      //         params.value === compareData["phone_no"] ? "#2f2c2bdb" : "red"
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // )
    },
    { field: "pin_code", headerName: "pin_code", align: "right" },
    {
      field: "product_id",
      headerName: "product_id",
      align: "right"
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       color:
      //         params.value === compareData["product_id"] ? "#2f2c2bdb" : "red"
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // )
    },
    { field: "reg_no", headerName: "reg_no", align: "right" },
    {
      field: "state",
      headerName: "state",
      align: "right"
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       color: params.value === compareData["state"] ? "#2f2c2bdb" : "red"
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // )
    },
    {
      field: "terminal_used_for",
      headerName: "terminal_used_for",
      align: "right"
      // renderCell: (params) => (
      //   <div
      //     style={{
      //       color:
      //         params.value === compareData["terminal_used_for"]
      //           ? "#2f2c2bdb"
      //           : "red"
      //     }}
      //   >
      //     {params.value}
      //   </div>
      // )
    },
    { field: "validity_date", headerName: "Validity Date", align: "right" }
  ];

  return (
    <>
      <MainCard title="CTCL History Data" sx={{ minHeight: "82vh" }}>
        {ctclId.length !== 0 ? (
          <div>
            {ctclId.map((id) => (
              <Accordion
                key={id}
                expanded={expanded === id}
                onChange={() => handleChange(id)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>{id}</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <div>
                    <Box
                      sx={{
                        height: "50vh",
                        width: "100%",
                        "& .header": {
                          backgroundColor: "black"
                        }
                      }}
                    >
                      <DataGrid
                        sx={{
                          border: "none"
                        }}
                        rows={ctclData[id]}
                        columns={columns}
                        hideFooter
                        density="compact"
                      />
                    </Box>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        ) : (
          <div>
            <h4>{"No Data Available"}</h4>
          </div>
        )}
      </MainCard>
    </>
  );
}

//   columns={Object.keys(ctclData[id][0])
//     .filter((key) => !['id'].includes(key)) // Exclude 'id'
//     .map((key) => ({
//       field: key,
//       headerName: key.replace(/_/g, ' ').toUpperCase(),
//       flex: 'auto' // Adjust width as needed
//     }))}
