import { FILE } from "config";

const AlgoSummaryFeatures = [
  {
    app_id: 41,
    feature_id: 4101,
    feature_name: "add_manager",
    type: FILE,
    subfolder: [],
    label: "Add Manager",
    path: "/algo-summary/add-manager",
  },
  {
    app_id: 41,
    feature_id: 4103,
    feature_name: "ctcl_mapping",
    type: FILE,
    subfolder: [],
    label: "CTCL Mapping",
    path: "/algo-summary/ctcl-mapping",
  },
  {
    app_id: 41,
    feature_id: 4104,
    feature_name: "add_brokarage",
    type: FILE,
    subfolder: [],
    label: "Add Brokerage",
    path: "/algo-summary/add-brokarage",
  },
  {
    app_id: 41,
    feature_id: 4105,
    feature_name: "add_fund",
    type: FILE,
    subfolder: [],
    label: "Add Fund",
    path: "/algo-summary/add-fund",
  },
  {
    app_id: 41,
    feature_id: 4106,
    feature_name: "add_client",
    type: FILE,
    subfolder: [],
    label: "Add Client",
    path: "/algo-summary/add-client",
  },
  {
    app_id: 41,
    feature_id: 4109,
    feature_name: "eod",
    type: FILE,
    subfolder: [],
    label: "EOD",
    path: "/algo-summary/eod",
  },
];

export default AlgoSummaryFeatures;
