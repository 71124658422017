// assets
import { IconReportAnalytics } from '@tabler/icons';

// constant
const icons = { IconReportAnalytics };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const reports = {
  id: 'reports',
  type: 'group',
  children: [
    {
      id: 'main_reports',
      title: 'Reports',
      type: 'item',
      url: '/reports',
      icon: icons.IconReportAnalytics,
      breadcrumbs: true
    }
  ]
};

export default reports;
