/*eslint-disable*/
import React from "react";

import AlgoSummaryReportData from "config-details/reports/AlgoSummaryReportData";
import CommonReportsIndex from "views/utilities/common-components/CommonReportsIndex";

const Index = () => {
  return (
    <CommonReportsIndex allReports={AlgoSummaryReportData} />
  );
};

export default Index;
