/*eslint-disable*/
import { commonApiRequestNew } from "utils/CommonFunctions";

const apiUrl = process.env.REACT_APP_ALGO_SUMMARY;

export const ManagerDetailsApi = async () => {
  const url = `${apiUrl}/v1/fund-manager`;
  return commonApiRequestNew("GET", url);
};

export const AddMangerApi = async (values) => {
  const formData = new FormData();
  for (const key in values) formData.append(key, values[key]);

  const url = `${apiUrl}/v1/fund-manager`;
  return commonApiRequestNew("POST", url, formData);
};

export const updateManagerApi = async (values) => {
  const url = `${apiUrl}/v1/fund-manager`;
  const formData = new FormData();
  for (const key in values) formData.append(key, values[key]);
  return commonApiRequestNew("PUT", url, formData);
};

export const deleteManagerApi = async (id) => {
  const url = `${apiUrl}/v1/fund-manager?id=${id}`;
  return commonApiRequestNew("DELETE", url);
};

export const ClientDetailsApi = async () => {
  const url = `${apiUrl}/v1/client`;
  return commonApiRequestNew("GET", url);
};

export const AddClientApi = async (values) => {
  const formData = new FormData();
  formData.append("client_id", values.client_id);
  formData.append("client_name", values.client_name);
  formData.append("pan", values.pan);
  formData.append("email", values.email);
  formData.append("phone_no", values.phone_no);
  formData.append("client_type", values.client_type);

  const url = `${apiUrl}/v1/client`;
  return commonApiRequestNew("POST", url, formData);
};

export const updateClientCodeTechExcelApi = async (values) => {
  const formData = new FormData();
  formData.append("id", values.id);
  formData.append("client_id", values.client_id);
  formData.append("client_name", values.client_name);
  formData.append("pan", values.pan);
  formData.append("email", values.email);
  formData.append("phone_no", values.phone_no);
  formData.append("client_type", values.client_type);
  const url = `${apiUrl}/v1/client`;
  return commonApiRequestNew("PUT", url, formData);
};

export const deleteClientApi = async (id) => {
  const url = `${apiUrl}/v1/client?id=${id}`;
  return commonApiRequestNew("DELETE", url);
};

export const historyDashboardApi = async (startDate, endDate) => {
  const url = `${apiUrl}/v1/dashboard-data?start_date=${startDate}&end_date=${endDate}`;
  return commonApiRequestNew("GET", url);
};

export const liveDashboardApi = async () => {
  const url = `${apiUrl}/v1/live-dashboard-data`;
  return commonApiRequestNew("GET", url);
};

export const CtclMappingAPi = async (values) => {
  const formData = new FormData();
  const fromDate = new Date(values.from_date).toISOString().slice(0, 10);
  const toDate = new Date(values.to_date).toISOString().slice(0, 10);
  formData.append("neat_id", values.neat_id);
  formData.append("ctcl_id", values.ctcl_id);
  formData.append("client_id", values.client_id);
  formData.append("manager_id", values.manager_id);
  formData.append("from_date", fromDate);
  formData.append("to_date", toDate);

  const url = `${apiUrl}/v1/client-ctcl-mapping`;
  return commonApiRequestNew("POST", url, formData);
};

export const getCtclMappingApi = async () => {
  const url = `${apiUrl}/v1/ctcl-mapping`;
  return commonApiRequestNew("GET", url);
};

export const updateCtclMapping = async (values) => {
  const url = `${apiUrl}/v1/client-ctcl-mapping`;
  const formData = new FormData();
  formData.append("id", values.id);
  formData.append("client_id", values.client_id);
  formData.append("manager_id", values.manager_id);
  formData.append("neat_id", values.neat_id);
  formData.append("ctcl_id", values.ctcl_id);
  formData.append("from_date", values.from_date);
  formData.append("to_date", values.to_date);
  return commonApiRequestNew("PUT", url, formData);
};

export const deleteCtclMappingApi = async (id) => {
  const url = `${apiUrl}/v1/ctcl-mapping?id=${id}`;
  return commonApiRequestNew("DELETE", url);
};

export const dayWiseReportApi = async (
  fundManagerId,
  startDate,
  endDate,
  client,
  club
) => {
  const url =
    `${process.env.REACT_APP_ALGO_SUMMARY}/v1/day-wise-mtm?start_date=${startDate}&end_date=${endDate}` +
    (fundManagerId != "all" ? `&fund_manager_id=${fundManagerId}` : "") +
    (client ? `&client_id=${client}` : "") +
    (club ? `&filter=${club}` : "");

  return commonApiRequestNew("GET", url);
};

export const monthlyReportApi = async (
  fundManagerId,
  selectedMonth,
  selectedYear
) => {
  let url =
    `${process.env.REACT_APP_ALGO_SUMMARY}/v1/monthly-summary?month=${selectedMonth}&year=${selectedYear}` +
    (fundManagerId != "all" ? `&fund_manager_id=${fundManagerId}` : "");

  return commonApiRequestNew("GET", url);
};

export const addFundApi = async (values) => {
  const formData = new FormData();
  const date = new Date(values.date).toISOString().slice(0, 10);
  formData.append("date", date);
  formData.append("client_id", values.client_id);
  formData.append("transection_type", values.transection_type);
  formData.append("amount", values.amount);
  formData.append("manager_id", values.manager_id);

  const url = `${apiUrl}/v1/fund`;
  return commonApiRequestNew("POST", url, formData);
};

export const updateFundApi = async (values) => {
  const formData = new FormData();
  const date = new Date(values.date).toISOString().slice(0, 10);
  formData.append("date", date);
  formData.append("id", values.id);
  formData.append("client_id", values.client_id);
  formData.append("transection_type", values.transection_type);
  formData.append("amount", values.amount);
  formData.append("manager_id", values.manager_id);
  const url = `${apiUrl}/v1/fund`;
  return commonApiRequestNew("PUT", url, formData);
};

export const deleteFundApi = async (id) => {
  const url = `${apiUrl}/v1/fund?id=${id}`;
  return commonApiRequestNew("DELETE", url);
};

export const getManagerDataApi = async (ClientID) => {
  const url = `${apiUrl}/v1/fund-manger-id?client_id=${ClientID}`;
  return commonApiRequestNew("GET", url);
};

export const addBrokerageApi = async (values) => {
  const formData = new FormData();
  const startDate = new Date(values.start_date).toISOString().slice(0, 10);
  const endDate = new Date(values.end_date).toISOString().slice(0, 10);
  formData.append("start_date", startDate);
  formData.append("end_date", endDate);
  formData.append("client_id", values.client_id);
  // formData.append("brk_amount", values.brk_amount);
  // formData.append("brk_type", values.brk_type);
  formData.append("brk_type_opt", values.brk_type_opt);
  formData.append("brk_type_fut", values.brk_type_fut);
  formData.append("opt_brk", values.opt_brk);
  formData.append("future_brk", values.future_brk);
  formData.append("manager_id", values.manager_id);

  const url = `${apiUrl}/v1/brokerage`;
  return commonApiRequestNew("POST", url, formData);
};

export const updateBrokerageApi = async (values) => {
  const formData = new FormData();
  const start_date = new Date(values.start_date).toISOString().slice(0, 10);
  const end_date = new Date(values.end_date).toISOString().slice(0, 10);
  formData.append("start_date", start_date);
  formData.append("end_date", end_date);
  formData.append("id", values.id);
  formData.append("client_id", values.client_id);
  // formData.append("brk_amount", values.brk_amount);
  // formData.append("brk_type", values.brk_type);
  formData.append("brk_type_opt", values.brk_type_opt);
  formData.append("brk_type_fut", values.brk_type_fut);
  formData.append("opt_brk", values.opt_brk);
  formData.append("future_brk", values.future_brk);
  formData.append("manager_id", values.manager_id);

  const url = `${apiUrl}/v1/brokerage`;
  return commonApiRequestNew("PUT", url, formData);
};

export const getBrokerageDataApi = async () => {
  const url = `${apiUrl}/v1/brokerage`;
  return commonApiRequestNew("GET", url);
};

export const deleteBrokerageApi = async (id) => {
  const url = `${apiUrl}/v1/brokerage/${id}`;
  return commonApiRequestNew("DELETE", url);
};

export const getFUndDataApi = async () => {
  const url = `${apiUrl}/v1/fund`;
  return commonApiRequestNew("GET", url);
};

export const getManagerWiseClientApi = async (ManagerID) => {
  const url = `${apiUrl}/v1/fund-manager-wise-client?manager_id=${ManagerID}`;
  return commonApiRequestNew("GET", url);
};

export const tradeFileApi = async (values) => {
  const url = `${apiUrl}/v1/upload-trade-file`;
  const formData = new FormData();
  for (const key in values) formData.append(key, values[key]);
  return commonApiRequestNew("POST", url, formData);
};

export const bhavCopyApi = async (values) => {
  const url = `${apiUrl}/v1/upload-bhavcopy`;
  const formData = new FormData();
  for (const key in values) formData.append(key, values[key]);
  return commonApiRequestNew("POST", url, formData);
};

export const getEodFileDataApi = async (type) => {
  const url = `${apiUrl}/v1/file-log?file_type=${type}`;
  return commonApiRequestNew("GET", url);
};

export const eodProcessApi = async (values) => {
  const url = `${apiUrl}/v1/eod-process`;
  const formData = new FormData();
  for (const key in values) formData.append(key, values[key]);
  return commonApiRequestNew("POST", url, formData);
};
