import React from "react";

import ComplianceFeatures from "config-details/features/ComplianceFeatures";
import CommonUtilsIndex from "views/utilities/common-components/CommonUtilsIndex";

const ComplianceUtilities = () => {

  return (
    <CommonUtilsIndex allFeatures={ComplianceFeatures} />
  );
};

export default ComplianceUtilities;
