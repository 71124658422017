import { commonApiRequest } from "utils/CommonFunctions";

const COMMON_URL = process.env.REACT_APP_URL;

export const fetchUserTableInfoApi = () => {
    const url = `${COMMON_URL}/v1/user/fetch-user-list`;
    return commonApiRequest('GET', url);
};

export const fetchUserInfoApi = (params) => {
    const url = `${COMMON_URL}/v1/user/fetch-user`;
    return commonApiRequest('GET', url, null, params);
};

export const userUpdateInfoApi = (body) => {
    const url = `${COMMON_URL}/v1/user/update-user-details`;
    return commonApiRequest('PUT', url, body);
};

// Starts here GET: all lists for dropdowns
export const fetchAllListsApi = (params) => {
    const url = `${COMMON_URL}/v1/user/fetch-lists`;
    return commonApiRequest('GET', url, null, params);
};

//all lists for dropdowns - Ends here

export const checkUserNameValidityApi = (params) => {
    const url = `${COMMON_URL}/v1/user/verify-username`;
    return commonApiRequest('POST', url, null, params);
};
export const createNewUserApi = (formData) => {
    const url = `${COMMON_URL}/v1/user/create-user-details`;
    return commonApiRequest('POST', url, formData);
};

export const updateEnableDisableStatusApi = (params) => {
    const url = `${COMMON_URL}/v1/user/change-user-enable-status`;
    return commonApiRequest('PUT', url, null, params);
};

export const updateUserPasswordApi = (body) => {
    const url = `${COMMON_URL}/v1/admin/admin-update-password`;
    return commonApiRequest('PUT', url, body);
};
