/*eslint-disable */
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";

import { useTheme } from "@mui/material/styles";
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Stack, Typography, useMediaQuery } from "@mui/material";

import logo from "assets/images/logo.png";
import AuthFooter from "ui-component/cards/AuthFooter";
import AnimateButton from "ui-component/extended/AnimateButton";

// ================================|| AUTH3 - change password ||================================ //

const ChangePasswordForm = ({ ...others }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            pt={3.5}
            px={4}
            pb={2}
        >
            <Grid item sx={{ mb: 2 }}>
                <Link to="#">
                    <img
                        src={logo}
                        alt="logo"
                        width="230px"
                        style={{ paddingTop: "5px" }}
                    />
                </Link>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    direction={matchDownSM ? "column-reverse" : "row"}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                        >
                            <Typography
                                color={theme.palette.secondary.main}
                                gutterBottom
                                variant={matchDownSM ? "h4" : "h3"}
                            >
                                Change Password
                            </Typography>
                            <Typography
                                variant="caption"
                                fontSize="13px"
                                textAlign={matchDownSM ? "center" : "center"}
                            >
                                Please fill out your current password as well as new password in below fields.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Formik
                    initialValues={{
                        currentPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                    }}
                    validationSchema={Yup.object().shape({
                        currentPassword: Yup.string()
                            .required("Current Password is required"),
                        newPassword: Yup.string()
                            .required("New Password is required"),
                        // .min(8, "Password must be at least 8 characters long"),
                        confirmPassword: Yup.string()
                            .required("Confirm Password is required")
                            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                    })}
                    onSubmit={async (
                        values,
                        { setErrors, setStatus, setSubmitting }
                    ) => {
                        try {

                            const body = {
                                "old_password": values.currentPassword,
                                "new_password": values.confirmPassword
                            };
                            const response = await axios.post(
                                `${process.env.REACT_APP_URL}/v1/user/update-password`,
                                body,
                                {
                                    headers: {
                                        'accept': 'application/json',
                                        'authToken': localStorage.authToken,
                                        'Content-Type': 'application/json',
                                    }
                                }
                            );

                            if (response.status === 200) {
                                const jsonData = response.data;
                                console.log("jsonData", jsonData);
                                setStatus({ success: true });
                                toast.success(jsonData.message);
                                navigate('/login');
                            } else {
                                setStatus({ success: false });
                                setErrors({ submit: "An error occurred while processing your request." });
                                toast.error("An error occurred while processing your request.");
                            }
                        } catch (error) {
                            console.error(error);
                            setStatus({ success: false });
                            setErrors({ submit: error.response.data.message });
                            toast.error(error.response.data.message);
                        }
                        setSubmitting(false);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form noValidate onSubmit={handleSubmit} {...others}>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.currentPassword && errors.currentPassword)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel htmlFor="outlined-adornment-curr-pwd">
                                    Current Password
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-curr-pwd"
                                    type="password"
                                    value={values.currentPassword}
                                    name="currentPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Current Password"
                                    inputProps={{}}
                                    autoComplete=""
                                />
                                {touched.currentPassword && errors.currentPassword && (
                                    <FormHelperText
                                        error
                                        id="standard-weight-helper-text-curr-pwd"
                                    >
                                        {errors.currentPassword}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.newPassword && errors.newPassword)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel htmlFor="outlined-adornment-new-pwd">
                                    New Password
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-new-pwd"
                                    type="password"
                                    value={values.newPassword}
                                    name="newPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="New Password"
                                    inputProps={{}}
                                    autoComplete=""
                                />
                                {touched.newPassword && errors.newPassword && (
                                    <FormHelperText
                                        error
                                        id="standard-weight-helper-text-new-pwd"
                                    >
                                        {errors.newPassword}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                fullWidth
                                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                sx={{ ...theme.typography.customInput }}
                            >
                                <InputLabel htmlFor="outlined-adornment2">
                                    Confirm Password
                                </InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment2"
                                    type="password"
                                    value={values.confirmPassword}
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label="Confirm Password"
                                    inputProps={{}}
                                    autoComplete=""
                                />
                                {touched.confirmPassword && errors.confirmPassword && (
                                    <FormHelperText
                                        error
                                        id="standard-weight-helper-text2"
                                    >
                                        {errors.confirmPassword}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>
                                        {errors.submit}
                                    </FormHelperText>
                                </Box>
                            )}

                            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Change Password
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%", mt: 3 }}>
                <AuthFooter />
            </Grid>
        </Grid>

    );
};

export default ChangePasswordForm;
