const subFeatureExist = (data, accessList, params) => {
  const checkSubFeatures = (node) => {
    if (node.type === "file") {
      if (params == "feature") {
        if (accessList?.includes(node.feature_id?.toString())) {
          return true;
        }
      } else if (params == "report") {
        if (accessList?.includes(node.report_id?.toString())) {
          return true;
        }
      }
    }
    if (node.type === "folder") {
      return node?.subfolder.some((sub) => checkSubFeatures(sub));
    }

    return false;
  };

  return data?.some((item) => checkSubFeatures(item));
};

export default subFeatureExist;
