/*eslint-disable*/
import React, { useContext, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  Divider,
  Button
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LmsContext } from "context/lms/LmsApis";

// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  padding: 16,
  "&:hover": {
    background: theme.palette.primary.light
  },
  "& .MuiListItem-root": {
    padding: 0
  }
}));

const Header = styled("div")(({ theme }) => ({
  background: "#fafbff",
  padding: "5px",
  paddingLeft: "15px",
  //   fontWeight: "bold",
  fontSize: "1.0rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));

const SideDrawer = ({ open, setOpen }) => {
  const { fetchNotification, notificationViewData, handleReadNotification } =
    useContext(LmsContext);

  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    // console.log("event", event.target.getAttribute('name'));
    setSelectedTab(newValue);
    fetchNotification("view", event.target.getAttribute("name"));
  };

  const handleMarkAsRead = () => {
    handleReadNotification("bulk");
    setSelectedTab(0);
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        role="presentation"
      >
        <Header>
          Notifications
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Header>

        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="notification tabs"
            >
              <Tab label="All" name="all" />
              <Tab label="Unread" name="unread" />
            </Tabs>
          </Grid>
          <Grid item sx={{ pr: 1 }}>
            {/* <Typography
              //   component={Link}
              to="#"
              variant="subtitle2"
              color="primary"
            >
              Mark as all read
            </Typography> */}
            <Button
              variant="text"
              sx={{ fontSize: "0.75rem" }}
              onClick={handleMarkAsRead}
            >
              Mark all as read
            </Button>
          </Grid>
        </Grid>
        <Divider />

        <List
          sx={{
            width: "100%",
            maxWidth: 330,
            minWidth: 330,
            py: 0,
            borderRadius: "10px",
            [theme.breakpoints.down("md")]: {
              maxWidth: 300
            },
            "& .MuiListItemSecondaryAction-root": {
              top: 22
            },
            "& .MuiDivider-root": {
              my: 0
            }
          }}
        >
          {notificationViewData.map((item, index) => (
            <>
              <ListItemWrapper
                onClick={() => handleReadNotification("individual", item.id)}
                sx={{
                  backgroundColor:
                    item.status === "Unread"
                      ? "rgba(185, 185, 199, 0.18)"
                      : "inherit",
                  "&:hover": {
                    backgroundColor:
                      item.status === "Unread"
                        ? "rgba(185, 185, 199, 0.18)"
                        : "inherit"
                  }
                }}
              >
                <Grid container direction="row" className="list-container">
                  <Grid item xs={2}>
                    <ListItemAvatar>
                      <Avatar alt="John Doe" src={item.username} />
                    </ListItemAvatar>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="subtitle2">
                      {item.notification_message}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  variant="subtitle2"
                  display="flex"
                  justifyContent="flex-end"
                >
                  {item.formated_date}
                </Typography>
              </ListItemWrapper>
              <Divider />
            </>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default SideDrawer;
