/*eslint-disable*/
import { lazy } from "react";
import Loadable from "ui-component/Loadable";

// const AddTask = Loadable(
//   lazy(() => import("views/my-task/add-task/AddTaskForm"))
// );
const TaskView = Loadable(lazy(() => import("views/pms/mytask")));
const Dashboard = Loadable(lazy(() => import("views/pms/dashboard")));
const Projects = Loadable(lazy(() => import("views/pms/projects")));
const Targets = Loadable(lazy(() => import("views/pms/targets")));
const MasterDashboard = Loadable(lazy(() => import("views/pms/master-dashboard")));
const ProjectTask = Loadable(lazy(() => import("views/pms/projects/common-component/ProjectTask")));
const TargetView = Loadable(lazy(() => import("views/pms/targets/target-detail")));
const MilestoneView = Loadable(lazy(() => import("views/pms/targets/milestone-detail")));
// const HelpIndex = Loadable(lazy(() => import("views/help-desk")));

export const PmsMyTaskModule = {
  path: "my-task",
  element: <TaskView />,
};

export const PmsDashboardModule = {
  path: "dashboard",
  element: <Dashboard />,
};
export const PmsProjectModule = {
  path: "projects",
  element: <Projects />,
};
export const PmsTargetModule = {
  path: "target",
  element: <Targets />,
};

export const PmsMasterDashboardModule = {
  path: "master-dashboard",
  element: <MasterDashboard />,
};



export const PmsProjectMyTask = {
  path: "projects",
  children: [{
    path: "my-task",
    element: <ProjectTask />,
  }
  ]
};


export const PmsTarget = {
  path: "target",
  children: [{
    path: "timeline",
    element: <TargetView />,
  },
  {
    path: "milestone",
    element: <MilestoneView />,
  }
  ]
};
