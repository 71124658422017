/*eslint-disable*/
import axios from "axios";
import { getHeaders, getHeadersNew } from "./CommonFunctions";

export const commonApi = async (
  method,
  url,
  body = null,
  params = null,
  responseType = "json",
  signal = null

) => {

  const config = {
    headers: getHeadersNew(),
    params: params,
    responseType: responseType,
    signal: signal,
  };

  let response;
  switch (method) {
    case "GET":
      response = await axios.get(url, config);
      break;
    case "POST":
      response = await axios.post(url, body, config);
      break;
    case "PUT":
      response = await axios.put(url, body, config);
      break;
    case "DELETE":
      response = await axios.delete(url, config);
      break;
    default:
      throw new Error(`Unsupported request method: ${method}`);
  }
  return response;
};
