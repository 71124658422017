/*eslint-disable*/
import axios from "axios";
import React from "react";
import { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { addMileStone, fetchAddTarget, fetchAllTargetByUser, fetchAllTasksByMilestone, fetchMilestoneDetails, fetchMilestonesByTarget, fetchTargetDetails, fetchTaskDetails, updateMileStone, updateTarget } from "services/pms/pmsServices";
import { formatDateYMD, handleCatch } from "utils/CommonFunctions";

export const PMSTargetContext = createContext();

export default function PMSTargetContextProvider({ children }) {
  const [targetList, setTargetList] = useState([]);
  const [targetDetails, settargetDetails] = useState([]);
  const [mileStoneList, setMileStoneList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [viewStatus, setViewStatus] = useState(false);
  const [mileStoneDetails, setMileStoneDetails] = useState({})
  const [taskDetails, setTaskDetails] = useState({})


  const handleFetchAllTargetByUser = async () => {
    try {
      const response = await fetchAllTargetByUser();
      const resJson = response.data;
      console.log("TargetList:", resJson);

      if (resJson.status === "success") {
        const tempData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setTargetList(tempData);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchTargetDetails = async (target_id) => {
    settargetDetails([]);
    try {
      const requestData = { target_id: target_id };
      const response = await fetchTargetDetails(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        settargetDetails(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchMilestonesByTarget = async (target_id) => {
    setMileStoneList([]);
    try {
      const requestData = { target_id };
      const response = await fetchMilestonesByTarget(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        setMileStoneList(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchAllTasksByMilestone = async (milestone_id, filterVal) => {
    setTaskList([]);
    try {
      const paramsData = {
        type: filterVal
      };
      const body = {
        base_id: milestone_id,
      };
      const response = await fetchAllTasksByMilestone(body, paramsData);
      const resJson = response.data;
      if (resJson.status === 'success') {
        setTaskList(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleAddTarget = async (values, setOpen, viewList) => {
    try {
      const requestData = {
        name: values.target_name,
        description: "",
        owner: values.target_owner,
        start_date: "",
        work_days: "",
        expected_completion_date: "",
        participants: viewList,
        access_to_mis: "",
        priority: values.target_priority,
        department: "",
        is_public: "",
        category: "",
      };
      const response = await fetchAddTarget(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFetchAllTargetByUser();
        setOpen(false);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateTarget = async (values, setOpen = () => { }, viewList, targetId) => {
    try {
      const data = {
        name: values.target_name || values.name,
        description: values.description,
        owner: values.target_owner || values.owner,
        start_date: formatDateYMD(values.start_date),
        work_days: "",
        expected_completion_date: formatDateYMD(values.expected_completion_date),
        participants: viewList || values.participants,
        access_to_mis: "",
        priority: values.target_priority || values.priority,
        department: "",
        is_public: "",
        category: values.category || "",
        publish_date: "",
        modifies_expected_completion_date: "",
        progress: "",
        timeliness: "",
        target_id: targetId || values.target_id
      };
      const response = await updateTarget(data);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFetchAllTargetByUser();
        setOpen(false);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error)
    }
  };


  const handleDeleteTarget = async (targetId) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_URL_PMS_TARGET}/v1/delete-target`,
        headers: {
          'Content-Type': 'application/json',
          authToken: localStorage.getItem('authToken'),
        },
        data: {
          target_id: targetId,
        },
      });

      const resJson = response.data;

      if (resJson.status === 'success') {
        handleFetchAllTargetByUser();
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleAddMileStone = async (values, setOpen) => {
    try {
      const requestData = {
        name: values.milestone_name,
        description: "",
        target_id: values.target_id,
        assigned_to: values.assign,
        category: "",
        priority: values.milestone_priority,
        start_date: "",
        expected_completion_date: "",
        work_days: "",
        access_to_mis: ""
      };
      const response = await addMileStone(requestData);
      if (response.data.status === "success") {
        handleFetchMilestonesByTarget(values.target_id);
        setOpen(false);
        toast.success(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };



  const handleUpdateMileStone = async (values, setOpen = () => { }) => {
    try {
      const data = {
        name: values.milestone_name || values.name,
        description: "",
        target_id: values.target_id,
        assigned_to: values.assign || values.assigned_to,
        category: "",
        priority: values.milestone_priority || values.priority,
        start_date: "",
        expected_completion_date: formatDateYMD(values.expected_completion_date) || "",
        work_days: "",
        access_to_mis: "",
        milestone_id: values.milestone_id,
        modifies_expected_completion_date: "",
        progress: "",
        timeliness: "",
        final_completion_date: "",
        tags: "",
        total_tasks: ""
      };

      const response = await updateMileStone(data);
      if (response.data.status === "success") {
        handleFetchMilestonesByTarget(values.target_id);
        toast.success(response.data.message);
        setOpen(false);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleDeleteMileStone = async (milestone_id, target_id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL_PMS_TARGET}/v1/delete-milestone`,
        {
          headers: {
            "Content-Type": "application/json",
            authToken: localStorage.getItem('authToken'),
          },
          data: {
            milestone_id: milestone_id,
          },
        }
      );
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFetchMilestonesByTarget(target_id);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleFetchMileStoneDetails = async (milestone_id) => {
    setMileStoneDetails({});
    try {
      const requestData = { milestone_id };
      const response = await fetchMilestoneDetails(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        setMileStoneDetails(resJson.data);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchTaskDetails = async (taskId) => {
    setTaskDetails({});
    try {
      const requestData = { task_id: taskId };
      const response = await fetchTaskDetails(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        setTaskDetails(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  

  return (
    <PMSTargetContext.Provider
      value={{
        targetList,
        targetDetails,
        settargetDetails,
        mileStoneList,
        taskList,
        taskDetails,
        handleFetchAllTargetByUser,
        handleFetchTargetDetails,
        handleFetchMilestonesByTarget,
        handleFetchAllTasksByMilestone,
        handleFetchTaskDetails,

        handleAddTarget,
        handleUpdateTarget,
        handleDeleteTarget,
        handleAddMileStone,
        handleUpdateMileStone,
        handleDeleteMileStone,
        handleFetchMileStoneDetails,
        mileStoneDetails,
        setMileStoneDetails,
        setViewStatus,
        viewStatus
      }}
    >
      {children}
    </PMSTargetContext.Provider>
  );
}
