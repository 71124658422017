/*eslint-disable*/
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchLease,
  handleLeaseAdd,
  handleLeaseDelete,
  handleLeaseUpdate,
  handleLeaseCity,
  handleBulkUpload,
} from "services/leaseAgreement/leaseService";

export const LeaseContext = createContext();

export default function leaseApi(props) {
  const [leaseData, setLeaseData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);

  const fetchAllLease = async () => {
    try {
      const response = await fetchLease();
      if (response.status === 200) {
        console.log("response", response);
        setLeaseData(response.data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const bulkUpload = async (values, others) => {
    try {
      const formData = new FormData();
      if (values.lease_data_file) {
        formData.append("lease_data_file", values.lease_data_file);
        const resJson = await handleBulkUpload(formData);
        others.setOpen(false);
        toast.success(resJson.message);
        fetchAllLease();
      }
    } catch (error) {
      console.error("Upload failed", error);
      toast.error(error.message || "Failed to upload. Please try again.");
    }
  };

  const addLease = async (values, others) => {
    try {
      const formData = new FormData();
      formData.append("branch_head", values.branch_head);
      formData.append("sub_department", values.sub_department);
      formData.append("name_on_agreement", values.name_on_agreement);
      formData.append("start_date", values.start_date);
      formData.append("end_date", values.end_date);
      formData.append("date_of_issue", values.date_of_issue);
      formData.append("amount", values.amount);
      formData.append("lease_duration", values.lease_duration);
      formData.append("auth_person", values.auth_person);
      formData.append("address", values.address);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("lease_type", values.lease_type);
      formData.append("doc_ref_num", values.doc_ref_num);
      formData.append("remarks", values.remarks);
      formData.append("pincode", values.pincode);

      if (values.agreement_certi_file) {
        formData.append("agreement_certi_file", values.agreement_certi_file);
      }
      const resJson = await handleLeaseAdd(formData);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllLease();
    } catch (error) {
      console.error("error", error);

      toast.error(resJson.message);
    }
  };

  const handleCityAdd = async (city) => {
    try {
      const requestBody = { city: city || "" };
      const resJson = await handleLeaseCity(requestBody);
      if (resJson.status === 200) {
        if (!city) {
          setCityData(resJson.data.data);
        } else {
          const fetchedStateData = resJson.data.data;
          if (fetchedStateData && fetchedStateData.length > 0) {
            setStateData(fetchedStateData);
          } else {
            setStateData([]);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching city/state data:", error);
    }
  };

  const updateLease = async (values, others, lease_id) => {
    try {
      const formData = new FormData();
      formData.append("lease_id", lease_id);
      formData.append("branch_head", values.branch_head);
      formData.append("sub_department", values.sub_department);
      formData.append("name_on_agreement", values.name_on_agreement);
      formData.append("start_date", values.start_date);
      formData.append("end_date", values.end_date);
      formData.append("date_of_issue", values.date_of_issue);
      formData.append("amount", values.amount);
      formData.append("lease_duration", values.lease_duration);
      formData.append("auth_person", values.auth_person);
      formData.append("address", values.address);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("lease_type", values.lease_type);
      formData.append("doc_ref_num", values.doc_ref_num);
      formData.append("remarks", values.remarks);
      formData.append("pincode", values.pincode);

      if (values.agreement_certi_file) {
        formData.append("agreement_certi_file", values.agreement_certi_file);
      }
      const resJson = await handleLeaseUpdate(formData, lease_id);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllLease();
    } catch (error) {
      console.error("error", error);

      toast.error(resJson.message);
    }
  };

  const deleteLease = async (lease_id) => {
    try {
      const resJson = await handleLeaseDelete({ lease_id });
      if (resJson.status === 200) {
        fetchAllLease();
        toast.success(resJson.data.message);
      } else {
        toast.error("Failed to delete lease.");
      }
    } catch (error) {
      console.error("Delete Lease error:", error);
      toast.error("An error occurred while deleting the lease.");
    }
  };

  return (
    <LeaseContext.Provider
      value={{
        leaseData,
        setLeaseData,
        fetchAllLease,
        addLease,
        updateLease,
        deleteLease,
        handleCityAdd,
        cityData,
        stateData,
        bulkUpload,
      }}
    >
      {props.children}
    </LeaseContext.Provider>
  );
}
