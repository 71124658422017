import { commonApiRequest } from "utils/CommonFunctions";

export const fetchExchangeAlertData = async (paramsData) => {
  const url = `${process.env.REACT_APP_URL_EXCHANGE_ALERT}/v1/fetch-exchange-alert-data`;
  const response = await commonApiRequest('GET', url, null, paramsData);
  return response
};
export const addExchangeAlert = async (body) => {
  const url = `${process.env.REACT_APP_URL_EXCHANGE_ALERT}/v1/add-exchange-alert`;
  const response = await commonApiRequest('POST', url, body);
  return response
};

export const updateExchangeAlertData = async (
  body
) => {
  const url = `${process.env.REACT_APP_URL_EXCHANGE_ALERT}/v1/update-exchange-alert-data`;
  const response = await commonApiRequest('PUT', url, body);
  return response;
};

export const fetchExchangeAlertDashboard = async () => {
  const url = `${process.env.REACT_APP_URL_EXCHANGE_ALERT}/v1/fetch-exchange-alert-dashboard-data`;
  const response = await commonApiRequest('GET', url);
  return response
};

export const deleteExchangeAlert = async (paramsData) => {
  const url = `${process.env.REACT_APP_URL_EXCHANGE_ALERT}/v1/exchange-alert`;
  const response = await commonApiRequest('DELETE', url, null, paramsData);
  return response
};