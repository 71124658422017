import { commonApiRequestNew } from "utils/CommonFunctions";

const API_URL = process.env.REACT_APP_RIA_WMS;

export const addRiaAPI = (body) => {
  console.log("body", body);
  const URL = `${API_URL}/v1/ria`;
  return commonApiRequestNew("POST", URL, body, null);
};

export const updateRiaAPI = (body) => {
  const URL = `${API_URL}/v1/ria`;
  return commonApiRequestNew("PUT", URL, body, null);
};

export const fetchAllRiaAPI = () => {
  const URL = `${API_URL}/v1/all-ria`;
  return commonApiRequestNew("GET", URL, null, null);
};

export const deleteRiaAPI = (params) => {
  const URL = `${API_URL}/v1/ria`;
  return commonApiRequestNew("DELETE", URL, null, params);
};

export const addProductAPI = (body) => {
  console.log("body", body);
  const URL = `${API_URL}/v1/product`;
  return commonApiRequestNew("POST", URL, body, null);
};

export const updateProductAPI = (body) => {
  const URL = `${API_URL}/v1/product`;
  return commonApiRequestNew("PUT", URL, body, null);
};

export const fetchAllProductAPI = () => {
  const URL = `${API_URL}/v1/all-product`;
  return commonApiRequestNew("GET", URL, null, null);
};

export const deleteProductAPI = (params) => {
  const URL = `${API_URL}/v1/product`;
  return commonApiRequestNew("DELETE", URL, null, params);
};

export const addClientAPI = (body) => {
  const URL = `${API_URL}/v1/client`;
  return commonApiRequestNew("POST", URL, body, null);
};

export const updateClientAPI = (body) => {
  const URL = `${API_URL}/v1/client`;
  return commonApiRequestNew("PUT", URL, body, null);
};

export const fetchAllClientAPI = () => {
  const URL = `${API_URL}/v1/all-client`;
  return commonApiRequestNew("GET", URL, null, null);
};

export const deleteClientAPI = (params) => {
  const URL = `${API_URL}/v1/client`;
  return commonApiRequestNew("DELETE", URL, null, params);
};

export const addClientMappingAPI = (body) => {
  const URL = `${API_URL}/v1/client-mapping`;
  return commonApiRequestNew("POST", URL, body, null);
};

export const updateClientMappingAPI = (body) => {
  const URL = `${API_URL}/v1/client-mapping`;
  return commonApiRequestNew("PUT", URL, body, null);
};

export const fetchAllClientMappingAPI = () => {
  const URL = `${API_URL}/v1/all-client-mapping`;
  return commonApiRequestNew("GET", URL, null, null);
};

export const deleteClientMappingAPI = (params) => {
  const URL = `${API_URL}/v1/client-mapping`;
  return commonApiRequestNew("DELETE", URL, null, params);
};
export const tradeFileApi = async (values) => {
  const url = `${API_URL}/v1/upload-backup-trade-file`;
  const formData = new FormData();
  for (const key in values) formData.append(key, values[key]);
  return commonApiRequestNew("POST", url, formData);
};


export const bhavCopyApi = async (values) => {
  console.log("values",values);
  
  const url = `${API_URL}/v1/upload-cm-bhavcopy`;
  const formData = new FormData();
  for (const key in values) formData.append(key, values[key]);
  return commonApiRequestNew("POST", url, formData);
};

export const getFileDataApi = async (type) => {
  const url = `${API_URL}/v1/file-log?file_type=${type}`;
  return commonApiRequestNew("GET", url);
};