/*eslint-disable*/
import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const BranchCostHome = Loadable(
  lazy(() => import("views/utilities/branch-cost/dashboard"))
);
const BranchCostIndex = Loadable(
  lazy(() => import("views/utilities/branch-cost/index"))
);
const BranchCostRequest = Loadable(
  lazy(() => import("views/utilities/branch-cost/utils/non-hr"))
);
const BranchCostHrRequest = Loadable(
  lazy(() => import("views/utilities/branch-cost/utils/hr"))
);
const BranchCostDeferredRequest = Loadable(
  lazy(() => import("views/utilities/branch-cost/utils/Deferred-Cost"))
);
const BranchCostReport = Loadable(
  lazy(() => import("views/utilities/branch-cost/reports/total-expense"))
);
const ProfitAndLossReport = Loadable(
  lazy(() => import("views/utilities/branch-cost/reports/pofit-loss"))
);
const BranchCostUtilities = Loadable(
  lazy(() => import("views/utilities/branch-cost/utils"))
);
const TotalExpenseReport = Loadable(
  lazy(() => import("views/utilities/branch-cost/reports"))
);

export const BranchCostModule = {
  path: "branch-cost",
  element: <BranchCostIndex />,
  children: [
    {
      path: "home",
      element: <BranchCostHome />,
    },
    {
      path: "utils",
      element: <BranchCostUtilities />,
    },

    {
      path: "reports",
      element: <TotalExpenseReport />,
    },
  ],
};

export const BranchCostUtilsRoutes = {
  path: "branch-cost",
  children: [
    {
      path: "non-hr",
      element: <BranchCostRequest />,
    },
    {
      path: "hr",
      element: <BranchCostHrRequest />,
    },
    // {
    //   path: "deferred-cost",
    //   element: <BranchCostDeferredRequest />,
    // },
  ],
};
export const BranchCostReportsRoutes = {
  path: "branch-cost",
  children: [
    {
      path: "total-expense",
      element: <BranchCostReport />,
    },
    {
      path: "profit-loss",
      element: <ProfitAndLossReport />,
    },
  ],
};
