/*eslint-disable*/
import axios from "axios";
import { commonApiRequest } from "utils/CommonFunctions";

const API_URL_EVENT = process.env.REACT_APP_URL_AP_EVENT;
const API_URL_BUDGET_EVENT = process.env.REACT_APP_URL_BUDGET_EVENT;

// const API_URL_EVENT = process.env.REACT_APP_URL_AP_EVENT;

const getHeaders = () => ({
  accept: "application/json",
  authToken: localStorage.authToken,
  "Content-Type": "application/json",
});

const getEventHeaders = () => ({
  accept: "application/json",
  authToken: localStorage.authToken,
});

//EventAdd

export const fetchEvent = async () => {
  try {
    const response = await axios.get(`${API_URL_EVENT}/v1/event`, {
      headers: getEventHeaders(),
    });
    return response.data;
  } catch (err) {
    throw new Error("An error occurred while fetching events");
  }
};

export const addEventService = async (values) => {
  try {
    const response = await axios.post(
      `${API_URL_EVENT}/v1/event`,
      formEvent(values),
      { headers: getEventHeaders() }
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while adding the event");
  }
};

export const updateEventService = async (values, event_id) => {
  try {
    const response = await axios.put(
      `${API_URL_EVENT}/v1/event/${event_id}`,
      formEvent(values),
      { headers: getEventHeaders() }
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while updating the event");
  }
};

//BudgetEvent

export const fetchBudgetEvents = (params) => {
  const url = `${API_URL_BUDGET_EVENT}/event/v1/get-event-budget-details?budget_status=${params}`;
  return commonApiRequest("GET", url, params);
};

export const fetchApprovedEventsService = () => {
  const url = `${API_URL_BUDGET_EVENT}/event/v1/get-event-approval-person-list`;
  return commonApiRequest("GET", url);
};

export const fetchPendingEventsService = () => {
  const url = `${API_URL_BUDGET_EVENT}/event/v1/get-pending-budget-events`;
  return commonApiRequest("GET", url);
};

export const addBudgetEvent = (body) => {
  const url = `${API_URL_BUDGET_EVENT}/event/v1/add-event-budget`;
  return commonApiRequest("POST", url, body);
};

export const updateBudgetEvent = (body, params) => {
  const url = `${API_URL_BUDGET_EVENT}/event/v1/update-event-budget`;
  return commonApiRequest("POST", url, body, params);
};

export const approveRejectEvent = (body) => {
  const url = `${API_URL_BUDGET_EVENT}/event/v1/approve-reject-event-budget`;
  return commonApiRequest("POST", url, body);
};

//Report

export const fetchEventUserService = () => {
  const url = `${API_URL_EVENT}/v1/event-user`;
  return commonApiRequest("GET", url);
};

export const fetchEventListService = () => {
  const url = `${API_URL_EVENT}/v1/event`;
  return commonApiRequest("GET", url);
};

const formEvent = (values) => {
  var formData = new FormData();
  formData.append("event", values.event);
  formData.append("date", values.date);
  formData.append("start_date", values.start_date);
  formData.append("end_date", values.end_date);
  formData.append("description", values.description);
  formData.append("location", values.location);
  values.event_image != null &&
    formData.append("event_image", values.event_image);
  formData.append("theme", values.theme);
  return formData;
};

// const formEventObject = (values) => {
//   const formData = new FormData();
//   formData.append("status", values.status);
//   formData.append("reason", values.reason);
//   return formData;
// };
