import { FILE } from "config";

const ArhamShareFeatures = [
  {
    app_id: 49,
    feature_id: 4901,
    feature_name: "arham-web-blog",
    type: FILE,
    subfolder: [],
    label: "Blogs",
    path: "/arham-web/blog",
  },
  {
    app_id: 49,
    feature_id: 4902,
    feature_name: "arham-web-event-gallery",
    type: FILE,
    subfolder: [],
    label: "Event Gallery",
    path: "/arham-web/event-gallery",
  },
  {
    app_id: 49,
    feature_id: 4903,
    feature_name: "arham-web-event-video",
    type: FILE,
    subfolder: [],
    label: "Event Video",
    path: "/arham-web/event-video",
  },
  {
    app_id: 49,
    feature_id: 4904,
    feature_name: "arham_web_pdf",
    type: FILE,
    subfolder: [],
    label: "Pdf",
    path: "/arham-web/pdf",
  },
  {
    app_id: 49,
    feature_id: 4905,
    feature_name: "arham-web-magazine",
    type: FILE,
    subfolder: [],
    label: "Magazine",
    path: "/arham-web/magazine",  
  },
];

export default ArhamShareFeatures;
