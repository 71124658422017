/*eslint-disable*/

import { commonApiRequest } from "utils/CommonFunctions";

const BASE_URL = process.env.REACT_APP_URL_INSURANCE_LEAD;

export const fetchLeads = () => {
  const url = `${BASE_URL}/V1/leads_get`;
  return commonApiRequest("GET", url);
};

export const addLead = (body) => {
  const url = `${BASE_URL}/V1/lead_add`;
  return commonApiRequest("POST", url, body);
};

export const updateLead = (body, params) => {
  const url = `${BASE_URL}/V1/leads_update`;
  return commonApiRequest("PUT", url, body, params);
};

export const deleteLead = (params) => {
  const url = `${BASE_URL}/V1/leads_delete`;
  return commonApiRequest("DELETE", url, null, params);
};
