import React from "react";

import CommonUtilsIndex from "views/utilities/common-components/CommonUtilsIndex";
import EPledgeFeatures from "config-details/features/EPledgeFeatures";

const EPledgeUtils = () => {

  return (
    <CommonUtilsIndex allFeatures={EPledgeFeatures} />
  );
};

export default EPledgeUtils;