import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import BounceDash from "views/utilities/bounce-mail/dashboard";
import BounceUtils from "views/utilities/bounce-mail/utils";
import BounceReports from "views/utilities/bounce-mail/reports";

const BounceHome = Loadable(
  lazy(() => import("views/utilities/bounce-mail"))
);

const BounceFileUploader = Loadable(
  lazy(() => import("views/utilities/bounce-mail/utils/file-uploader"))
);
const BounceUpdatePods = Loadable(
  lazy(() => import("views/utilities/bounce-mail/utils/update-pods"))
);

const BounceLogsReport = Loadable(
  lazy(() => import("views/utilities/bounce-mail/reports/bounce-logs"))
);
const BounceSendLogsReport = Loadable(
  lazy(() => import("views/utilities/bounce-mail/reports/send-logs"))
);

const BounceSummaryReport = Loadable(
  lazy(() => import("views/utilities/bounce-mail/reports/summary-report"))
);



export const BounceModule = {
  path: "bounce-mail",
  element: <BounceHome />,
  children: [
    {
      path: "home",
      element: <BounceDash />,
    },
    {
      path: "utils",
      element: <BounceUtils />,
    },
    {
      path: "reports",
      element: <BounceReports />,
    },
  ],
};

export const BounceRoutes = {
  path: "bounce-mail",
  children: [
    {
      path: "file-uploader",
      element: <BounceFileUploader />,
    },
    {
      path: "update-pods",
      element: <BounceUpdatePods />,
    },
    {
      path: "bounce-logs",
      element: <BounceLogsReport />,
    },
    {
      path: "send-logs",
      element: <BounceSendLogsReport />,
    },
    {
      path: "summary-report",
      element: <BounceSummaryReport />,
    },
  ],
};

