import React, { useContext } from 'react';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    Grid, Tooltip, IconButton, Avatar, FormControl, TextField, InputLabel, Select, MenuItem, Checkbox, ListItemText, Button, OutlinedInput
} from '@mui/material';

import { ChatContext } from 'context/chat-app/ChatApis';
import { stringAvatarChat } from 'utils/CommonFunctions';
import { addSessionStorage, getSessionStorage } from 'utils/encrpytion';

const validationSchema = Yup.object({
    groupName: Yup.string().required('Group Name is required'),
    personName: Yup.array().min(1, 'Select at least one participant'),
});
const editValidationSchema = Yup.object({
    groupName: Yup.string().required('Group Name is required'),
    personName: Yup.array().min(1, 'Select at least one participant'),
    adminName: Yup.array().min(1, 'Select at least one admin')
});

export default function GroupDialogForm({ handleClose, allEmployees, setAllEmployees }) {

    const { createNewChat, editDialog, handleGroupModify } = useContext(ChatContext);

    // importing from sesstion storage
    const chatData = getSessionStorage("chatData")
    const participants = getSessionStorage("chatParticipants");
    const admins = getSessionStorage("chatAdmins");
    const chatUserName = getSessionStorage("chatUserName");

    // showing selected participants and admins
    const uidList = allEmployees?.filter((item) =>
        participants?.includes(item?.UID.toUpperCase())
    );
    const partiListArr = uidList.map((item) => {
        return item.full_name?.toUpperCase();
    });
    const adminList = allEmployees?.filter((item) =>
        admins?.includes(item?.UID.toUpperCase())
    );
    const adminListArr = adminList?.map((item) => {
        return item.full_name?.toUpperCase();
    });

    // remove participant 
    const handleRemoveParticipant = (name, values, setFieldValue) => {
        const filterData = values.personName?.filter((item) => item.toUpperCase() !== name.toUpperCase());
        setFieldValue("personName", filterData);
    };
    const handleRemoveAdmin = (name, values, setFieldValue) => {
        const filterData = values.adminName?.filter((item) => item.toUpperCase() !== name.toUpperCase());
        setFieldValue("adminName", filterData);
    };

    // submit dialog for add group or modify group
    const handleSubmit = (values) => {
        const list = allEmployees?.filter((item) => values.personName.includes(item?.full_name.toUpperCase()));
        const participantListArr = list?.map((item) => { return item.UID });

        console.log('editDialog', editDialog)
        if (editDialog) {
            const adminList = allEmployees?.filter((item) => values.adminName.includes(item?.full_name.toUpperCase()));
            const adminListArr = adminList?.map((item) => { return item.UID });
            const requestBody = {
                participant_ids: participantListArr?.toString(),
                group_name: values?.groupName,
                admins: adminListArr?.toString()
            }
            let body = { ...requestBody, chat_id: chatData?.chat_id, }
            addSessionStorage("chatParticipants", JSON.stringify(participantListArr))
            addSessionStorage("chatAdmins", JSON.stringify(adminListArr))

            handleGroupModify(body);
        } else {
            const requestBody = {
                participant_ids: participantListArr?.toString(),
                group_name: values?.groupName,
            }
            console.log('requestBody', requestBody)
            createNewChat(requestBody);
        }

        // Reset form fields
        setAllEmployees([]);
        handleClose(); // Close the dialog after submission
    };

    return (
        <Formik
            enableReinitialize
            initialValues={{
                groupName: editDialog === false ? "" : chatUserName,
                personName: editDialog === false ? [] : partiListArr,
                adminName: editDialog === false ? [] : adminListArr
            }}
            validationSchema={editDialog ? editValidationSchema : validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            {({ values, handleChange, setFieldValue, errors, touched, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        padding={1}
                        spacing={2}
                    >
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <Field
                                    as={TextField}
                                    size="small"
                                    label="Group Name"
                                    name="groupName"
                                    fullWidth
                                    onChange={handleChange}
                                    error={touched.groupName && Boolean(errors.groupName)}
                                    helperText={touched.groupName && errors.groupName}
                                />
                            </FormControl>
                        </Grid>
                        {values.personName?.length > 0 && (
                            <>
                                <Grid item xs={12} sx={{ mt: 1, fontStyle: "italic", fontWeight: "600" }}>
                                    Selected Participants :
                                </Grid>
                                <Grid item xs={12} sx={{ mb: 1 }}>
                                    {values.personName?.map((data, index) => (
                                        <Tooltip key={index} title={data}>
                                            <IconButton type='button' sx={{ p: 0, mr: 0.8, my: 0.5, position: 'relative' }}>
                                                <button
                                                    type='button'
                                                    className='avatar-remove-btn'
                                                    onClick={() => handleRemoveParticipant(data, values, setFieldValue)}
                                                >
                                                    _
                                                </button>
                                                <Avatar {...stringAvatarChat(data?.toUpperCase())} />
                                            </IconButton>
                                        </Tooltip>
                                    ))}
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="select-participants-label">Select Participants</InputLabel>
                                <Select
                                    multiple
                                    labelId="select-participants-label"
                                    id="select-participants"
                                    value={values.personName}
                                    name='personName'
                                    input={<OutlinedInput fullWidth label="Select Participants" />}
                                    onChange={handleChange}
                                    sx={{ textTransform: 'uppercase' }}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {allEmployees?.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item.full_name?.toUpperCase()}
                                            sx={{ textTransform: 'uppercase' }}
                                        >
                                            <Checkbox
                                                sx={{ p: 0, mr: 0.8 }}
                                                size="small"
                                                checked={values.personName.indexOf(item.full_name?.toUpperCase()) > -1}
                                            />
                                            <ListItemText sx={{ p: 0 }} primary={item.full_name?.toUpperCase()} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {values.adminName?.length > 0 && editDialog && (
                            <>
                                <Grid item xs={12} sx={{ mt: 1, fontStyle: "italic", fontWeight: "600" }}>
                                    Admins :
                                </Grid>
                                <Grid item xs={12} sx={{ mb: 1 }}>
                                    {values.adminName?.map((data, index) => (
                                        <Tooltip key={index} title={data}>
                                            <IconButton type='button'
                                                sx={{ p: 0, mr: 0.8, my: 0.5, position: 'relative' }}
                                            >
                                                <button
                                                    type='button'
                                                    className='avatar-remove-btn'
                                                    onClick={() => handleRemoveAdmin(data, values, setFieldValue)}
                                                >
                                                    _
                                                </button>
                                                <Avatar {...stringAvatarChat(data?.toUpperCase())} />
                                            </IconButton>
                                        </Tooltip>
                                    ))}
                                </Grid>
                            </>
                        )}
                        {editDialog &&
                            <Grid item xs={12} md={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="select-admin-label">Admin</InputLabel>
                                    <Select multiple labelId="select-admin-label" id="select-admin" name='adminName' value={values.adminName} input={<OutlinedInput fullWidth label="Admin" />} onChange={handleChange} sx={{ textTransform: 'uppercase' }} renderValue={(selected) => selected.join(', ')}>
                                        {allEmployees?.map((item, index) => (
                                            <MenuItem key={index} value={item.full_name?.toUpperCase()} sx={{ textTransform: 'uppercase' }}>
                                                <Checkbox sx={{ p: 0, mr: 0.8 }} size="small" checked={values.adminName.indexOf(item.full_name?.toUpperCase()) > -1} />
                                                <ListItemText sx={{ p: 0 }} primary={item.full_name?.toUpperCase()} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12} display="flex" justifyContent="center">
                            <Button disabled={!values.groupName || values.personName?.length === 0} type='submit' size="large" variant="contained">
                                {editDialog === true ? "UPDATE" : "CREATE"}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )
            }
        </Formik >
    );
}


