import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const InfoIndex = Loadable(lazy(() => import("views/utilities/info/index")));
const InfoHome = Loadable(lazy(() => import("views/utilities/info/dashboard")));
const InfoUtilities = Loadable(
    lazy(() => import("views/utilities/info/utils"))
);
const InfoReports = Loadable(
    lazy(() => import("views/utilities/info/reports"))
);

const Directory = Loadable(
    lazy(() => import("views/utilities/info/utils/directory"))
);
const AboutUs = Loadable(
    lazy(() => import("views/utilities/info/utils/about-us"))
);
const CommonDocs = Loadable(
    lazy(() => import("views/utilities/info/utils/common-docs"))
);
const HolidayCalender = Loadable(
    lazy(() => import("views/utilities/info/utils/holiday-calender"))
);
const OrganizationInfo = Loadable(
    lazy(() => import("views/utilities/info/utils/organization-info"))
);
const Policies = Loadable(
    lazy(() => import("views/utilities/info/utils/policies"))
);
const QuickLinks = Loadable(
    lazy(() => import("views/utilities/info/utils/quick-links"))
);
const Tutorials = Loadable(
    lazy(() => import("views/utilities/info/utils/tutorials"))
);
const UrlInfo = Loadable(
    lazy(() => import("views/utilities/info/utils/url-info"))
);

export const InfoModule = {
    path: "info",
    element: <InfoIndex />,
    children: [
        {
            path: "home",
            element: <InfoHome />,
        },
        {
            path: "utils",
            element: <InfoUtilities />,
        },

        {
            path: "reports",
            element: <InfoReports />,
        },
    ],
};

export const InfoUtilsRoutes = {
    path: "info",
    children: [
        {
            path: "directory",
            element: <Directory />,
        },
        {
            path: "about-us",
            element: <AboutUs />,
        },
        {
            path: "url-info",
            element: <UrlInfo />,
        },
        {
            path: "organization-info",
            element: <OrganizationInfo />,
        },
        {
            path: "tutorials",
            element: <Tutorials />,
        },
        {
            path: "common-docs",
            element: <CommonDocs />,
        },
        {
            path: "holiday-calender",
            element: <HolidayCalender />,
        },
        {
            path: "quick-links",
            element: <QuickLinks />,
        },
        {
            path: "policies",
            element: <Policies />,
        },
    ],
};
