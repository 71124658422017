/*eslint-disable*/
import React, { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import {
  addClientAPI,
  addClientMappingAPI,
  addProductAPI,
  addRiaAPI,
  bhavCopyApi,
  deleteClientAPI,
  deleteClientMappingAPI,
  deleteProductAPI,
  deleteRiaAPI,
  fetchAllClientAPI,
  fetchAllClientMappingAPI,
  fetchAllProductAPI,
  fetchAllRiaAPI,
  getFileDataApi,
  tradeFileApi,
  updateClientAPI,
  updateClientMappingAPI,
  updateProductAPI,
  updateRiaAPI
} from "services/ria-wms/RiaWmsServices";
import { handleCatch } from "utils/CommonFunctions";
// import { useErrorHandler } from "hooks/useErrorHandler";

export const RiaContext = createContext();

export default function RiaApis(props) {
  const [riaData, setRiaData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [clientMappingData, setClientMappingData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileLogsData, setFileLogsData] = useState(false);

  // const { handleError } = useErrorHandler();

  const handleGetRiaDetails = async () => {
    setRiaData([]);
    setLoading(true);
    try {
      const response = await fetchAllRiaAPI();
      if (response.status === 200) {
        setRiaData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("get all ria", error);

      // handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRia = async (values, setOpenDailog) => {
    try {
      const formData = new FormData();
      formData.append("ria_name", values.ria_name);

      const response = await addRiaAPI(formData);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleGetRiaDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("add ria", error);

      // handleError(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleUpdateRia = async (values, setOpenDailog) => {
    try {
      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("ria_name", values.ria_name);

      const response = await updateRiaAPI(formData);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetRiaDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("update ria", error);

      // handleError(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleDeleteRia = async (id) => {
    try {
      const params = {
        id
      };

      const response = await deleteRiaAPI(params);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetRiaDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("delete ria", error);

      // handleError(error);
    }
  };

  const handleGetProductDetails = async () => {
    setProductData([]);
    setLoading(true);
    try {
      const response = await fetchAllProductAPI();
      if (response.status === 200) {
        setProductData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("get all product", error);

      // handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddProduct = async (values, setOpenDailog) => {
    try {
      const formData = new FormData();
      formData.append("ria_id", values.ria_id);
      formData.append("product_id", values.product_id);
      formData.append("product_name", values.product_name);

      const response = await addProductAPI(formData);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleGetProductDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("add product", error);

      // handleError(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleUpdateProduct = async (values, setOpenDailog) => {
    try {
      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("ria_id", values.ria_id);
      formData.append("product_id", values.product_id);
      formData.append("product_name", values.product_name);

      const response = await updateProductAPI(formData);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetProductDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("update product", error);

      // handleError(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      const params = {
        id
      };

      const response = await deleteProductAPI(params);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetProductDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("delete product", error);

      // handleError(error);
    }
  };

  const handleGetClientDetails = async () => {
    setClientData([]);
    setLoading(true);
    try {
      const response = await fetchAllClientAPI();
      if (response.status === 200) {
        setClientData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("get client", error);

      // handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddClient = async (values, setOpenDailog) => {
    try {
      const formData = new FormData();
      formData.append("client_id", values.client_id);
      formData.append("client_name", values.client_name);
      formData.append("pan", values.pan);
      formData.append("email", values.email);
      formData.append("phone_no", values.phone_no);
      formData.append("client_type", values.client_type);

      const response = await addClientAPI(formData);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleGetClientDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("add client", error);

      // handleError(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleUpdateClient = async (values, setOpenDailog) => {
    try {
      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("client_id", values.client_id);
      formData.append("client_name", values.client_name);
      formData.append("pan", values.pan);
      formData.append("email", values.email);
      formData.append("phone_no", values.phone_no);
      formData.append("client_type", values.client_type);

      const response = await updateClientAPI(formData);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetClientDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("update client", error);

      // handleError(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleDeleteClient = async (id) => {
    try {
      const params = {
        id
      };

      const response = await deleteClientAPI(params);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetClientDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("delete client", error);

      // handleError(error);
    }
  };

  const handleGetClientMappingDetails = async () => {
    setClientMappingData([]);
    setLoading(true);
    try {
      const response = await fetchAllClientMappingAPI();
      if (response.status === 200) {
        setClientMappingData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("get client mapping", error);

      // handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddClientMapping = async (values, setOpenDailog) => {
    try {
      const formData = new FormData();
      formData.append("client_id", values.client_id);
      formData.append("product_id", values.product_id);

      const response = await addClientMappingAPI(formData);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleGetClientMappingDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("add client mapping", error);

      // handleError(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleUpdateClientMapping = async (values, setOpenDailog) => {
    try {
      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("client_id", values.client_id);
      formData.append("product_id", values.product_id);

      const response = await updateClientMappingAPI(formData);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetClientMappingDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("update client mapping", error);

      // handleError(error);
    } finally {
      setOpenDailog(false);
    }
  };

  const handleDeleteClientMapping = async (id) => {
    try {
      const params = {
        id
      };

      const response = await deleteClientMappingAPI(params);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetClientMappingDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("delete client mapping", error);

      // handleError(error);
    }
  };

  const handleTradeFile = async (values, { resetForm, fileInputRef }) => {
    setIsLoading(true);
    try {
      const response = await tradeFileApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        // handleGetEodFileData("trade_file");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      resetForm();
      setIsLoading(false);
    }
  };

  const handleBhavCopy = async (values, { resetForm, fileInputRef }) => {
    setIsLoading(true);
    try {
      const response = await bhavCopyApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        // handleGetEodFileData("bhav_copy");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      resetForm();
      setIsLoading(false);
    }
  };

  const handleGetFileLogsData = async (type) => {
    try {
      const response = await getFileDataApi(type);
      if (response.status === 200) {
        setFileLogsData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  return (
    <RiaContext.Provider
      value={{
        clientData,
        productData,
        riaData,
        clientMappingData,

        loading,

        handleGetRiaDetails,
        handleAddRia,
        handleUpdateRia,
        handleDeleteRia,

        handleGetProductDetails,
        handleAddProduct,
        handleUpdateProduct,
        handleDeleteProduct,

        handleGetClientDetails,
        handleAddClient,
        handleUpdateClient,
        handleDeleteClient,

        handleGetClientMappingDetails,
        handleAddClientMapping,
        handleUpdateClientMapping,
        handleDeleteClientMapping,
        handleTradeFile,
        isLoading,
        setIsLoading,
        handleBhavCopy,
        handleGetFileLogsData,
        setFileLogsData,
        fileLogsData
      }}
    >
      {props.children}
    </RiaContext.Provider>
  );
}
