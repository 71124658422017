import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const CustomerCareIndex = Loadable(
  lazy(() => import("views/utilities/customer-care/index"))
);

const CustomerCareHome = Loadable(
  lazy(() => import("views/utilities/customer-care/dashboard/index"))
);

const CustomerCareUtilities = Loadable(
  lazy(() => import("views/utilities/customer-care/utils/index"))
);

const CustomerCareReports = Loadable(
  lazy(() => import("views/utilities/customer-care/reports/index"))
);

const SlbmSegmentAddition = Loadable(
  lazy(() =>
    import("views/utilities/customer-care/utils/segmentAddition/index")
  )
);

const ApprovalSlbmSegmentAddition = Loadable(
  lazy(() =>
    import("views/utilities/customer-care/utils/segmentApproval/index")
  )
);

const SegmentModification = Loadable(
  lazy(() =>
    import("views/utilities/customer-care/utils/segmentModification/index")
  )
);

export const CutomerCareModule = {
  path: "customer-care",
  element: <CustomerCareIndex />,
  children: [
    {
      path: "home",
      element: <CustomerCareHome />,
    },
    {
      path: "utils",
      element: <CustomerCareUtilities />,
    },

    {
      path: "reports",
      element: <CustomerCareReports />,
    },
  ],
};

export const CustomerCareUtilsRoutes = {
  path: "customer-care",
  children: [
    {
      path: "cc-segment-addition",
      element: <SlbmSegmentAddition />,
    },
    {
      path: "cc-segment-approval",
      element: <ApprovalSlbmSegmentAddition />,
    },
    {
      path: "segment-modification",
      element: <SegmentModification />,
    },
  ],
};
