/*eslint-disable*/
import { element } from "prop-types";
import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import FeaturesRequired from "utils/FeaturesRequired";

const KycIndex = Loadable(lazy(() => import("views/utilities/kyc")));

const KycHome = Loadable(lazy(() => import("views/utilities/kyc/dashboard")));

const KycOfflineUtilities = Loadable(
  lazy(() => import("views/utilities/kyc/offline-utils"))
);
const KycOnlineUtilities = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils"))
);
const KycReports = Loadable(lazy(() => import("views/utilities/kyc/reports")));

//############################## Reports EKYC imports ##############################//

const OnlineReports = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc"))
);
const OnlineOnHoldApps = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc/on-hold-applications"))
);
const OnlineUnfinishedApps = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc/unfinished-app"))
);
const OnlineCompletedApps = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc/completed-application"))
);
const OnlineRejectedApps = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc/rejected-application"))
);
const OnlineAcceptedApps = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc/accepted-application"))
);
const OnlineApplicationHistory = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc/applications-history"))
);
const OnlineReportsKra = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc/kra-reports"))
);
const OnlineReportsModification = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc/modification-reports"))
);
const OnlineReportsClosure = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc/closure-reports"))
);
const OnlineReportsIpvLogs = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc/ipv-logs"))
);
const OnlinePennyGeoLogs = Loadable(
  lazy(() => import("views/utilities/kyc/reports/ekyc/penny-geo-logs"))
);

const OfflineReports = Loadable(
  lazy(() => import("views/utilities/kyc/reports/offline-reports"))
);

//############################## feature auth Check ##############################//

const featureCheck = (component, feature) => (
  <FeaturesRequired feature={feature}>{component}</FeaturesRequired>
);

export const KycModule = {
  path: "kyc",
  element: <KycIndex />,
  children: [
    {
      path: "home",
      element: <KycHome />,
    },
    {
      path: "kycofflineutils",
      element: <KycOfflineUtilities />,
    },
    {
      path: "kyconlineutils",
      element: <KycOnlineUtilities />,
    },
    {
      path: "reports",
      element: <KycReports />,
    },
  ],
};

//////////////////////////////////  ONLINE Reports  ///////////////////////////////////////////


export const KycReportsRoutes = {
  path: "kyc",
  children: [
    {
      path: "online-reports",
      element: <OnlineReports />,
    },
    {
      path: "offline-reports",
      element: <OfflineReports />,
    },
    {
      path: "online-reports/on-hold-application",
      element: <OnlineOnHoldApps />,
    },
    {
      path: "online-reports/unfinished-application",
      element: <OnlineUnfinishedApps />,
    },
    {
      path: "online-reports/completed-application",
      element: <OnlineCompletedApps />,
    },
    {
      path: "online-reports/rejected-application",
      element: <OnlineRejectedApps />,
    },
    {
      path: "online-reports/accepted-application",
      element: <OnlineAcceptedApps />,
    },
    {
      path: "online-reports/application-history",
      element: <OnlineApplicationHistory />,
    },
    {
      path: "online-reports/kra",
      element: <OnlineReportsKra />,
    },
    {
      path: "online-reports/modification",
      element: <OnlineReportsModification />,
    },
    {
      path: "online-reports/closure",
      element: <OnlineReportsClosure />,
    },
    {
      path: "online-reports/ipv-logs",
      element: <OnlineReportsIpvLogs />,
    },
    {
      path: "online-reports/penny-geo-logs",
      element: <OnlinePennyGeoLogs />,
    },
  ],
};

//////////////////////////////////  ONLINE FEATURES imports  ////////////////////////////////////////

const KycOnlineKra = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/kra"))
);

const KycOnlineOnBoarding = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/on-boarding"))
);
const KycHufOnlineOnBoarding = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/huf-online-onboarding"))
);
const KycOnlineModification = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/modification"))
);

// online reKyc
const KycOnlineRekyc = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/rekyc"))
);
const ReKycOnlineAuthorization = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/rekyc/kyc-authorization"))
);
const ReKycOnlineAuthorizationKycDtails = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/online-utils/rekyc/kyc-authorization/KycDetails"
    )
  )
);

const ReKycOnlineTechExcel = Loadable(
  lazy(() =>
    import("views/utilities/kyc/online-utils/rekyc/rekyc-trading-update")
  )
);

const KycOnlineUccTradingPrefrence = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/trading-preference"))
);

const KycOnlineSettings = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/settings"))
);

const KycAccountClosure = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/closure/index"))
);
const KycConnectCredentials = Loadable(
  lazy(() =>
    import("views/utilities/kyc/online-utils/connect-credentials/index")
  )
);

const KycOnlineOnBoardingKycAuthorization = Loadable(
  lazy(() =>
    import("views/utilities/kyc/online-utils/on-boarding/kyc-authorization")
  )
);

const KycOnlineTechExcel = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/on-boarding/tech-excel"))
);

const KycOnlineUpdateStatus = Loadable(
  lazy(() =>
    import("views/utilities/kyc/online-utils/on-boarding/update-status")
  )
);
const KycOnlineOnBoardingOdinUserCreation = Loadable(
  lazy(() =>
    import("views/utilities/kyc/online-utils/on-boarding/odin-user-creation")
  )
);

const KycOnlineOnBoardingAccountOpening = Loadable(
  lazy(() =>
    import("views/utilities/kyc/online-utils/on-boarding/account-opening")
  )
);

const KycOnlineSettingsNonKra = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/settings/non-kra"))
);
const KycOnlineSettingsIntroducerCode = Loadable(
  lazy(() =>
    import("views/utilities/kyc/online-utils/settings/introducer-code")
  )
);

const KycOnlineSettingsDeletePan = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/settings/delete-pan"))
);

const KycOnlineReopenAccount = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/settings/reopen-account"))
);
const KycOnlineUpdateApplication = Loadable(
  lazy(() =>
    import("views/utilities/kyc/online-utils/settings/update-application")
  )
);
const KycOnlinePanNameCheck = Loadable(
  lazy(() =>
    import("views/utilities/kyc/online-utils/settings/pan-name-check")
  )
);

const KycOnlineOnBoardingKycAuthorizationKycDtails = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/online-utils/on-boarding/kyc-authorization/KycDetails"
    )
  )
);

// HUF Onboarding imports
const KycHufOnlineOnBoardingKycAuthorization = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/online-utils/huf-online-onboarding/huf-kyc-authorization"
    )
  )
);
const KycHufOnlineOnBoardingKycAuthorizationKycDtails = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/online-utils/huf-online-onboarding/huf-kyc-authorization/KycDetails"
    )
  )
);
const KycHufOnlineUccTechExcel = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/online-utils/huf-online-onboarding/huf-tech-excel"
    )
  )
);
const KycHufOnlineUpdateStatus = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/online-utils/huf-online-onboarding/huf-update-status"
    )
  )
);
const OnlineDormat = Loadable(
  lazy(() => import("views/utilities/kyc/online-utils/dormat/index"))
);

export const KycOnlineUtilsRoutes = {
  path: "kyc",
  children: [
    {
      path: "online-onboarding",
      // element: featureCheck(<KycOnlineOnBoarding />, "online-onboarding"),
      element: <KycOnlineOnBoarding />,
    },
    {
      path: "online-kra",
      // element: featureCheck(<KycOnlineKra />, "online-kra"),
      element: <KycOnlineKra />,
    },
    {
      path: "online-kyc-authorization",
      // element: featureCheck(<KycOnlineOnBoardingKycAuthorization />, "online-kyc-authorization"),
      element: <KycOnlineOnBoardingKycAuthorization />,
    },
    {
      path: "online-kyc-authorization",
      children: [
        {
          path: "profile",
          element: <KycOnlineOnBoardingKycAuthorizationKycDtails />,
        },
      ],
    },
    {
      path: "online-modification",
      // element: featureCheck(<KycOnlineModification />, "online-modification"),
      element: <KycOnlineModification />,
    },

    ////////// online rekyc routes /////////////////
    {
      path: "online-rekyc",
      element: <KycOnlineRekyc />,
    },
    {
      path: "online-rekyc-authorization",
      element: <ReKycOnlineAuthorization />,
    },
    {
      path: "online-rekyc-authorization",
      children: [
        {
          path: "profile",
          element: <ReKycOnlineAuthorizationKycDtails />,
        },
      ],
    },
    {
      path: "online-rekyc-trading-update",
      element: <ReKycOnlineTechExcel />,
    },
    ////////////////////////////////////////////////////
    {
      path: "online-trading-preference",
      // element: featureCheck(<KycOnlineUccTradingPrefrence />, "online-trading-preference"),
      element: <KycOnlineUccTradingPrefrence />,
    },
    {
      path: "account-closure",
      // element: featureCheck(<KycAccountClosure />, "account-closure"),
      element: <KycAccountClosure />,
    },
    {
      path: "online-connect-credentials",
      // element: featureCheck(<KycConnectCredentials />, "online-connect-credentials"),
      element: <KycConnectCredentials />,
    },
    {
      path: "online-settings",
      // element: featureCheck(<KycOnlineSettings />, "online-settings"),
      element: <KycOnlineSettings />,
    },
    {
      path: "online-tech-excel",
      // element: featureCheck(<KycOnlineTechExcel />, "online-tech-excel"),
      element: <KycOnlineTechExcel />,
    },
    {
      path: "online-update-status",
      // element: featureCheck(<KycOnlineUpdateStatus />, "online-update-status"),
      element: <KycOnlineUpdateStatus />,
    },
    {
      path: "online-odin-user-creation",
      // element: featureCheck(<KycOnlineOnBoardingOdinUserCreation />, "online-odin-user-creation"),
      element: <KycOnlineOnBoardingOdinUserCreation />,
    },
    {
      path: "online-account-opening",
      // element: featureCheck(<KycOnlineOnBoardingAccountOpening />, "online-account-opening"),
      element: <KycOnlineOnBoardingAccountOpening />,
    },
    {
      path: "online-non-kra",
      // element: featureCheck(<KycOnlineSettingsNonKra />, "online-non-kra"),
      element: <KycOnlineSettingsNonKra />,
    },
    {
      path: "online-introducer-code",
      // element: featureCheck(<KycOnlineSettingsIntroducerCode />, "online-introducer-code"),
      element: <KycOnlineSettingsIntroducerCode />,
    },
    {
      path: "online-delete-pan",
      // element: featureCheck(<KycOnlineSettingsDeletePan />, "online-delete-pan"),
      element: <KycOnlineSettingsDeletePan />,
    },
    {
      path: "online-reopen-account",
      // element: featureCheck(<KycOnlineReopenAccount />, "online-reopen-account"),
      element: <KycOnlineReopenAccount />,
    },
    {
      path: "online-update-application",
      // element: featureCheck(<KycOnlineReopenAccount />, "online-update-application"),
      element: <KycOnlineUpdateApplication />,
    },
    {
      path: "online-pan-name-check",
      element: <KycOnlinePanNameCheck />,
    },

    //HUF onboarding routes
    {
      path: "huf-online-onboarding",
      // element: featureCheck(<KycHufOnlineOnBoarding />, "huf-online-onboarding"),
      element: <KycHufOnlineOnBoarding />,
    },
    {
      path: "huf-online-kyc-authorization",
      // element: featureCheck(<KycHufOnlineOnBoardingKycAuthorization />, "huf-online-kyc-authorization"),
      element: <KycHufOnlineOnBoardingKycAuthorization />,
    },
    {
      path: "huf-online-kyc-authorization",
      children: [
        {
          path: "profile",
          element: <KycHufOnlineOnBoardingKycAuthorizationKycDtails />,
        },
      ],
    },
    {
      path: "huf-online-tech-excel",
      // element: featureCheck(<KycHufOnlineUccTechExcel />, "huf-online-tech-excel"),
      element: <KycHufOnlineUccTechExcel />,
    },
    {
      path: "huf-online-update-status",
      // element: featureCheck(<KycHufOnlineUpdateStatus />, "huf-online-update-status"),
      element: <KycHufOnlineUpdateStatus />,
    },
    {
      path: "online-dormat",
      element: <OnlineDormat />,
    },
  ],
};

//////////////////////////////////// OFFLINE FEATURES ////////////////////////////////////////

//###################   Utilities Modification #######################//
const KycModification = Loadable(
  lazy(() => import("views/utilities/kyc/offline-utils/modification"))
);

const KYCModificationDetails = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/modification/modificationDataTable/modificationData"
    )
  )
);

//######################## Utilities Closure ###############################//
const KycClosure = Loadable(
  lazy(() => import("views/utilities/kyc/offline-utils/closure"))
);

//##################### Utilities Segment Addition ##########################//
const KycSegmentAddition = Loadable(
  lazy(() => import("views/utilities/kyc/offline-utils/segmentAddition/index"))
);

const KycUpdationAlert = Loadable(
  lazy(() => import("views/utilities/kyc/offline-utils/updation-alert/index"))
);

const KycBalanceSheet = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/balance-sheet/index"
    )
  )
);

const KycFinancialProof = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/financial-proof/index"
    )
  )
);

const KycPendingMinor = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/pending-minor/index"
    )
  )
);

const KycDormat = Loadable(
  lazy(() =>
    import("views/utilities/kyc/offline-utils/updation-alert/dormat/index")
  )
);

const KycMnrl = Loadable(
  lazy(() =>
    import("views/utilities/kyc/offline-utils/updation-alert/mnrl/index")
  )
);

const KycMptt = Loadable(
  lazy(() =>
    import("views/utilities/kyc/offline-utils/updation-alert/mptt/index")
  )
);

const KycPaymentRef = Loadable(
  lazy(() =>
    import("views/utilities/kyc/offline-utils/updation-alert/payment-ref/index")
  )
);

const KycFinancialProofUploader = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/financial-proof/uploader/index"
    )
  )
);

const KycFinancialProofTable = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/financial-proof/table/index"
    )
  )
);

const KycPendingMinorUploader = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/pending-minor/uploader/index"
    )
  )
);

const KycPendingMinorTable = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/pending-minor/table/index"
    )
  )
);

const KycDormatUploader = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/dormat/uploader/index"
    )
  )
);

const KycDormatTable = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/dormat/table/index"
    )
  )
);

const KycMnrlUploader = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/mnrl/uploader/index"
    )
  )
);

const KycMnrlTable = Loadable(
  lazy(() =>
    import("views/utilities/kyc/offline-utils/updation-alert/mnrl/table/index")
  )
);

const KycMpttUploader = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/mptt/uploader/index"
    )
  )
);

const KycMpttTable = Loadable(
  lazy(() =>
    import("views/utilities/kyc/offline-utils/updation-alert/mptt/table/index")
  )
);

const KycPaymentRefUploader = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/payment-ref/uploader/index"
    )
  )
);

const KycPaymentRefTable = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/payment-ref/table/index"
    )
  )
);

const KycRekyc = Loadable(
  lazy(() => import("views/utilities/kyc/offline-utils/reKyc/index"))
);

const KycClientMaster = Loadable(
  lazy(() => import("views/utilities/kyc/offline-utils/clientMaster/index"))
);

const KycNomineeAddition = Loadable(
  lazy(() => import("views/utilities/kyc/offline-utils/nominee/index"))
);

const KycAccountOpening = Loadable(
  lazy(() => import("views/utilities/kyc/offline-utils/account-opening/index"))
);

const MobileEmailVerification = Loadable(
  lazy(() =>
    import(
      "views/utilities/kyc/offline-utils/updation-alert/mobile-email-verification"
    )
  )
);

export const KycUtilsRoutes = {
  path: "kyc",
  children: [
    {
      path: "modification",
      element: <KycModification />,
    },
    {
      path: "modification",
      children: [
        {
          path: "profile",
          element: <KYCModificationDetails />,
        },
      ],
    },
    {
      path: "closure",
      element: <KycClosure />,
    },
    {
      path: "segment_addition",
      element: <KycSegmentAddition />,
    },
    {
      path: "offline-updation-alert",
      element: <KycUpdationAlert />,
    },
    {
      path: "offline-ua-balance-sheet",
      element: <KycBalanceSheet />,
    },
    {
      path: "offline-rekyc",
      element: <KycRekyc />,
    },
    {
      path: "offline-client-master",
      element: <KycClientMaster />,
    },
    {
      path: "offline-ua-financial-proof",
      element: <KycFinancialProof />,
    },
    {
      path: "offline-ua-pending-minor",
      element: <KycPendingMinor />,
    },
    {
      path: "offline-ua-dormat",
      element: <KycDormat />,
    },
    {
      path: "offline-ua-mnrl",
      element: <KycMnrl />,
    },
    {
      path: "offline-ua-mptt",
      element: <KycMptt />,
    },
    {
      path: "offline-ua-payment-ref",
      element: <KycPaymentRef />,
    },
    {
      path: "offline-financial-proof-uploader",
      element: <KycFinancialProofUploader />,
    },
    {
      path: "offline-financial-proof-table",
      element: <KycFinancialProofTable />,
    },
    {
      path: "offline-pending-minor-uploader",
      element: <KycPendingMinorUploader />,
    },
    {
      path: "offline-pending-minor-table",
      element: <KycPendingMinorTable />,
    },
    {
      path: "offline-dormat-uploader",
      element: <KycDormatUploader />,
    },
    {
      path: "offline-dormat-table",
      element: <KycDormatTable />,
    },
    {
      path: "offline-mnrl-uploader",
      element: <KycMnrlUploader />,
    },
    {
      path: "offline-mnrl-table",
      element: <KycMnrlTable />,
    },
    {
      path: "offline-mptt-uploader",
      element: <KycMpttUploader />,
    },
    {
      path: "offline-mptt-table",
      element: <KycMpttTable />,
    },
    {
      path: "offline-payment-reference-uploader",
      element: <KycPaymentRefUploader />,
    },
    {
      path: "offline-payment-reference-table",
      element: <KycPaymentRefTable />,
    },
    {
      path: "offline-nominee-addition",
      element: <KycNomineeAddition />,
    },
    {
      path: "offline-account-opening",
      element: <KycAccountOpening />,
    },
    {
      path: "mobile-email-verification",
      element: <MobileEmailVerification />,
    },
  ],
};
