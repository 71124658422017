/*eslint-disable*/
import React, { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { fetchAccessAppInfoApi, fetchAccessReportFeatureListApi, fetchFeatureReportListApi, handleAccessAppInfoApi, handleAccessReportFeatureListApi } from "services/role-management/roleManagement";
import { handleCatch } from "utils/CommonFunctions";

export const RoleManagementContext = createContext();

export default function RoleManagementApis(props) {
  const [accessAppList, setAccessAppList] = useState([]);

  const [featureReportList, setFeatureReportList] = useState([]);
  const [tagValue, setTagValue] = useState("feature");


  const [accessFeatureReportList, setAccessFeatureReportList] = useState([]);

  const [accessReportFeatureListByAppId, setAccessReportFeatureListByAppId] = useState([]);

  //employee id
  const [userId, setUserId] = useState("");

  //GET user app access list by Employee ID
  const fetchAccessAppInfo = async (newValue) => {
    try {
      const params = { user_id: newValue };
      if (newValue) {
        const response = await fetchAccessAppInfoApi(params);
        setAccessAppList(response.data.data.access_app_id);
      } else {
        toast.error("Invalid Empployee ID / Username !");
      }
    } catch (error) {
      console.error("error", error.message);
      toast.error(error?.response?.data?.message);
    }
  };

  //PUT update user app access list by Employee ID and updated list of apps
  const handleAccessAppInfo = async (userId, combinedListArr) => {
    const body = {
      user_id: userId,
      app_list: combinedListArr,
    };
    try {
      if (userId) {
        const response = await handleAccessAppInfoApi(body);
        console.log('response', response)
        toast.success(response.data.message);
      }
      else {
        toast.error("Invalid Empployee ID / Username !");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
      console.log(error);
    }
  };

  //GET feature report list
  const fetchFeatureReportList = async (values = "feature") => {
    try {
      const response = await fetchFeatureReportListApi();
      // console.log('response', response)
      // if (values == "feature") {
      //   const arr = response.data.data.filter((item) => item.tag == "feature")
      //   console.log('faeture', arr)
      //   setFeatureReportList(arr);
      // } else if (values == "report") {
      //   const arr = response.data.data.filter((item) => item.tag == "report")
      //   console.log('report', arr)
      //   setFeatureReportList(arr);
      // }
      setFeatureReportList(response.data.data);
    } catch (error) {
      handleCatch(error)
    }
  };

  //GET access feature report list by Emp Id or Emp Id and AppId
  const fetchAccessReportFeatureList = async (empId, tagValue, app_id) => {
    try {
      if (empId) {
        const params = { user_id: empId, tag: tagValue, app_id: typeof app_id === "object" ? '' : app_id };

        const response = await fetchAccessReportFeatureListApi(params);

        const resJson = response.data.data;

        if (tagValue == "feature") {
          const feature_arr = featureReportList.filter((item) => item.tag == "feature")

          setAccessFeatureReportList(
            feature_arr.filter((item) => resJson.map(String).includes(item.id))
          );
          const arrMain = feature_arr.filter((item) =>
            resJson.map(String).includes(item.id)
          );
          console.log('arrMain', arrMain)
          setAccessReportFeatureListByAppId(arrMain);
        } else if (tagValue == "report") {
          const report_arr = featureReportList.filter((item) => item.tag == "report")

          setAccessFeatureReportList(
            report_arr.filter((item) => resJson.map(String).includes(item.id))
          );
          const arrMain = report_arr.filter((item) =>
            resJson.map(String).includes(item.id)
          );
          console.log('arrMain', arrMain)
          setAccessReportFeatureListByAppId(arrMain);
        }

        // console.log("R & F list by App Id", arr);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
      console.log(error);
    }
  };

  //PUT update access feature report list
  const handleAccessReportFeatureList = async (
    empId,
    reportFeatureList,
    appId,
    tagValue
  ) => {
    const body = {
      user_id: empId,
      app_id: appId.toString(),
      app_list: reportFeatureList,
      department_id: "",
      tag: tagValue
    };

    console.log('body', body)
    try {
      if (empId) {
        const response = await handleAccessReportFeatureListApi(body);

        console.log("success", response.data.message);
        toast.success(response.data.message);
      } else {
        toast.error("Enter Employee ID !!!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message)
      console.log(error);
    }
  };

  return (
    <RoleManagementContext.Provider
      value={{
        accessAppList,
        setAccessAppList,
        fetchAccessAppInfo,
        handleAccessAppInfo,
        userId,
        setUserId,
        fetchFeatureReportList,
        featureReportList,
        fetchAccessReportFeatureList,
        accessFeatureReportList,
        setAccessFeatureReportList,
        handleAccessReportFeatureList,
        accessReportFeatureListByAppId,
        tagValue, setTagValue
      }}
    >
      {props.children}
    </RoleManagementContext.Provider>
  );
}
