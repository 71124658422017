import { commonApiRequestNew } from "utils/CommonFunctions";

const REACT_APP_URL_EXTRANET = process.env.REACT_APP_URL_EXTRANET;

export const SagmentFolderApi = (body) => {
  const url = `${REACT_APP_URL_EXTRANET}/v1/extranet?api_type=erp`;
  return commonApiRequestNew("POST", url, body);
};

export const FileDownloadApi = (body) => {
  const url = `${REACT_APP_URL_EXTRANET}/v1/file-content?api_type=erp`;
  return commonApiRequestNew("POST", url, body);
};

export const BSESagmentFolderApi = (body) => {
  const url = `${REACT_APP_URL_EXTRANET}/v1/sftp?api_type=erp`;
  return commonApiRequestNew("POST", url, body);
};

export const BSEFileDownloadApi = (body) => {
  const url = `${REACT_APP_URL_EXTRANET}/v1/sftp-files?api_type=erp`;
  return commonApiRequestNew("POST", url, body);
};
