/*eslint-disable*/
import React from "react";

import LeaseAgreementFeatures from "config-details/features/LeaseAgreementFeatures";
import CommonUtilsIndex from "views/utilities/common-components/CommonUtilsIndex";

const LeaseUtilities = () => {

  return (
    <CommonUtilsIndex allFeatures={LeaseAgreementFeatures} />
  );
};

export default LeaseUtilities;
