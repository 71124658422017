/*eslint-disable*/
import { commonApiRequest } from "utils/CommonFunctions";

const baseURL = process.env.REACT_APP_URL_LEASE_AGREEMENT;

export const fetchLease = () => {
  const url = `${baseURL}/V1/lease_get`;
  return commonApiRequest("GET", url);
};

export const handleLeaseAdd = (formData) => {
  const url = `${baseURL}/V1/lease_add`;
  return commonApiRequest("POST", url, formData);
};

export const handleBulkUpload = (formData) => {
  const url = `${baseURL}/V1/lease_bulk_add`;
  return commonApiRequest("POST", url, formData);
};

export const handleLeaseCity = (body) => {
  const url = `${baseURL}/V1/city_state_get`;
  return commonApiRequest("POST", url, body);
};
export const handleLeaseUpdate = (formData) => {
  const url = `${baseURL}/V1/lease_update`;
  return commonApiRequest("PUT", url, formData);
};

export const handleLeaseDelete = (params) => {
  const url = `${baseURL}/V1/lease_delete`;
  return commonApiRequest("DELETE", url,null, params);
};
