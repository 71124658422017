/*eslint-disable*/
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { handleCatch } from "utils/CommonFunctions";
import { makeStyles } from "@mui/styles";
import {
  bwmDataApi,
  clinetDataApi,
  cwmDataApi,
  globalMarginDataApi,
  pocwmDataApi,
  powtDataApi,
  twmDataApi,
} from "services/pro-rms/ProRmsService";

export const ProRmsContext = createContext();

const useStyles = makeStyles((theme) => ({
  customScrollbar: {
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#555",
    },
  },
}));

export default function ProRmsApis(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [globalMarginData, setGlobalMargin] = useState([]);
  const [bwmData, setBwmData] = useState([]);
  const [twmData, setTwmData] = useState([]);
  const [powtData, setPowtData] = useState([]);
  const [cwmData, setCwmData] = useState([]);
  const [pocwmData, setPocwmData] = useState([]);

  //################################## Dashboard Apis ###########################
  // const handleLogin = async () => {
  //   localStorage.removeItem("prorms_token");
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_URL_PRO_RMS}/v3/login`,
  //       { source: "web", username: "ERP", password: "M@ster$123" },
  //       {
  //         headers: {
  //           accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       const token = response.data.Token;
  //       localStorage.setItem("prorms_token", token);
  //       toast.success(response.data.message);
  //     } else {
  //       toast.error(response.data.message);
  //     }
  //   } catch (error) {
  //     handleCatch(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleClientData = async () => {
    setIsLoading(true);
    try {
      const response = await clinetDataApi();
      if (response.status === 200) {
        setClientData(response.data.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBwmData = async () => {
    setIsLoading(true);
    try {
      const response = await bwmDataApi();
      if (response.status === 200) {
        setBwmData(response.data.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGlobalMarginData = async () => {
    try {
      const response = await globalMarginDataApi();
      if (response.status === 200) {
        setGlobalMargin(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //########################### Terminal Summary #############################
  const handleTwmdata = async () => {
    setIsLoading(true);
    try {
      const response = await twmDataApi();
      if (response.status === 200) {
        setTwmData(response.data.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const hanldePowtData = async (selectedClient) => {
    setIsLoading(true);
    try {
      const response = await powtDataApi(selectedClient);
      if (response.status === 200) {
        setPowtData(response.data.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  //######################################## Client Summary #########################
  const handleCwmData = async () => {
    setIsLoading(true);
    try {
      const response = await cwmDataApi();
      if (response.status === 200) {
        setCwmData(response.data.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePocwmData = async (selectedClient) => {
    setIsLoading(true);
    try {
      const response = await pocwmDataApi(selectedClient);
      if (response.status === 200) {
        setPocwmData(response.data.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ProRmsContext.Provider
      value={{
        handleClientData,
        clientData,
        handleBwmData,
        bwmData,
        handleGlobalMarginData,
        globalMarginData,

        handleTwmdata,
        twmData,
        hanldePowtData,
        powtData,

        handleCwmData,
        cwmData,
        handlePocwmData,
        pocwmData,

        isLoading,
        classes,
      }}
    >
      {props.children}
    </ProRmsContext.Provider>
  );
}
