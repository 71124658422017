import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const TravelIndex = Loadable(
  lazy(() => import("views/utilities/travel-management/index"))
);

const TravelManagementHome = Loadable(
  lazy(() => import("views/utilities/travel-management/dashboard/index"))
);

const TravelManagementUtilities = Loadable(
  lazy(() => import("views/utilities/travel-management/utils/index"))
);

const TravelManagementReports = Loadable(
  lazy(() => import("views/utilities/travel-management/reports/index"))
);

const TravelRequests = Loadable(
  lazy(() => import("views/utilities/travel-management/utils/TravelRequests"))
);

const TravelRequestsApproval = Loadable(
  lazy(() => import("views/utilities/travel-management/utils/TravelReqApproval"))
);

export const TravelManagementModule = {
  path: "travel-management",
  element: <TravelIndex />,
  children: [
    {
      path: "home",
      element: <TravelManagementHome />,
    },
    {
      path: "utils",
      element: <TravelManagementUtilities />,
    },

    {
      path: "reports",
      element: <TravelManagementReports />,
    },
  ],
};

export const TravelMangementUtilsRoutes = {
  path: "travel-management",
  children: [
    {
      path: "requests",
      element: <TravelRequests />,
    },
    {
      path: "requests-approval",
      element: <TravelRequestsApproval />,
    },
  ],
};
