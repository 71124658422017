
export const monthList= [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ]

export const getCurrentDates = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  // const yesterday = String(currentDate.getDate() - 1).padStart(2, "0");
  const today = String(currentDate.getDate()).padStart(2, "0");

  const financialYearStartMonth = 4;

  const year =
    currentMonth < financialYearStartMonth
      ? `${currentYear - 1}-${currentYear.toString()}`
      : `${currentYear}-${(currentYear + 1).toString()}`;
  const month = currentYear + currentMonth;
  const month2 = currentMonth + currentYear;
  const day = currentYear + currentMonth + today;

  return { year, month, day, month2 };
};

export const getDateList = () => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // Months are zero-based
  const currentYear = today.getFullYear();
  const dateList = [];

  for (let day = 1; day <= today.getDate(); day++) {
    const paddedDay = day.toString().padStart(2, "0");
    const paddedMonth = currentMonth.toString().padStart(2, "0");
    const date = `${currentYear}${paddedMonth}${paddedDay}`;
    dateList.push(date);
  }

  return dateList;
}

export const getFinancialYearMonths = () => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  const months = [];
  let startYear = currentMonth >= 4 ? currentYear : currentYear - 1;

  for (
    let month = 4, year = startYear;
    !(year === currentYear && month > currentMonth);
    month++
  ) {
    if (month > 12) {
      month = 1;
      year++;
    }
    const monthString = `${year}${month.toString().padStart(2, "0")}`;
    months.push(monthString);
  }

  return months;
}

export const getFinancialYears = () => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  const financialYears = [];

  // Financial year always starts in April
  const startYear = 2023;
  const currentFinancialYearStart =
    currentMonth >= 4 ? currentYear : currentYear - 1;
  const endYear = currentFinancialYearStart;

  for (let year = startYear; year <= endYear; year++) {
    const nextYear = year + 1;
    const financialYear = `${year}-${nextYear}`;
    financialYears.push(financialYear);
  }

  return financialYears;
}

export const formatMonth = (monthValue) => {
  if (!monthValue) {
    return;
  }
  const monthIndex = parseInt(monthValue.substring(4, 6)) - 1;
  const year = monthValue.substring(0, 4);
  return `${monthList[monthIndex]} ${year} `;
};

export const formatMonth2 = (monthValue) => {
  if (!monthValue) {
    return;
  }
  const monthIndex = parseInt(monthValue.substring(0, 2)) - 1;
  const year = monthValue.substring(2, 6);
  return `${monthList[monthIndex]} ${year} `;
};

export const formatDate = (date) => {
  if (!date) {
    return;
  }
  return `${date.substring(0, 4)}/${date.substring(4, 6)}/${date.substring(
    6,
    8
  )}`;
};
