import React from "react";
import { Link } from "react-router-dom";

import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function CustomBreadcrumbs({ links, title, moduleName, fontSize = "0.75rem" }) {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {moduleName && (
        <Link
          style={{ textDecoration: "none", fontSize: fontSize }}
          underline="hover"
          color="gray"
          to={`/utilities/${moduleName}/home`}
        >
          <Typography color="gray">{moduleName?.toUpperCase()}</Typography>
        </Link>
      )}

      {links?.map((link, index) => (
        <span key={index}>
          {link.to ? (
            <Link
              style={{ textDecoration: "none", fontSize: fontSize }}
              underline="hover"
              color="gray"
              to={link.to}
            >
              {link.icon && <link.icon />}
              {link.label && (
                <Typography color="gray">{link.label?.toUpperCase()}</Typography>
              )}
            </Link>
          ) : null}
        </span>
      ))}
      <Typography
        sx={{ fontSize: fontSize, color: "gray", fontWeight: 500 }}
      >
        {title?.toUpperCase()}
      </Typography>
    </Breadcrumbs>
  );
}
