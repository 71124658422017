// material-ui
import { Link, Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const AuthFooter = () => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="subtitle2" component={Link} href="/register" target="_blank" underline="hover">
      {`${currentYear} © Arhamshare Pvt.Ltd.`}
    </Typography>
    <Typography variant="subtitle2" component={Link} href="https://www.anvtech.co" target="_blank" underline="hover">
      Powered By Anv-Tech
    </Typography>
  </Stack>
);

export default AuthFooter;
