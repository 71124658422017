/*eslint-disable*/
import React, { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { confirmPaymentApi, fetchAllEmployeeExpenseApi, fetchAllEmployeeRequestApi, fetchBalanceDashApi, fetchExpenseReportApi, fetchPettyCashExpenseApi, fetchPettyCashRequestApi, fetchRequestsReportApi, handleAddExpenseApi, handleAddRequestApi, handleApproveRejectApi, handleApproveRejectExpenseApi, handleDeleteExpenseApi, handleDeleteRequestApi, handleUpdateExpenseApi, handleUpdateRequestApi, updatePaymentStatusApi } from "services/petty-cash/pettyCashServices";

export const PettyCashContext = createContext();

export default function PettyCashApis(props) {

  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [dialogData, setDialogData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [open, setOpen] = useState(false);

  const [allRequest, setAllRequest] = useState([]);
  const [allExpenses, setAllExpenses] = useState([]);
  const [dashBalance, setDashBalance] = useState({});
  const [requestsReport, setRequestsReport] = useState([]);
  const [expenseReport, setExpenseReport] = useState([]);


  //dash API
  const fetchBalanceDash = async () => {
    setDashBalance({});
    setLoading(true);
    try {
      const response = await fetchBalanceDashApi();
      setDashBalance(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //fetch patty request
  const fetchPettyCashRequest = async () => {
    setRequestData([]);
    setLoading(true);
    try {
      const response = await fetchPettyCashRequestApi();
      setRequestData(
        response.data.data.map((item, index) => ({ ...item, id: index + 1 }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //add new petty request API
  const handleAddRequest = async (payload) => {
    setLoading(true);
    try {
      const body = {
        expense_type: payload.expense_type,
        expense_type_other: payload.expense_type_other,
        req_amount: payload.req_amount,
      };
      const response = await handleAddRequestApi(body);
      toast.success(response?.data?.message);
      setOpen(false);
      fetchPettyCashRequest();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  //update patty request API
  const handleUpdateRequest = async (payload) => {
    setLoading(true);
    try {
      const body = {
        expense_type: payload.expense_type,
        expense_type_other: payload.expense_type_other,
        req_amount: payload.req_amount,
      };

      const params = { req_ID: payload.reqId };
      const response = await handleUpdateRequestApi(body, params);

      toast.success(response.data.data.message);
      setOpen(false);
      fetchPettyCashRequest();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // delete petty request API
  const handleDeleteRequest = async (payload) => {
    setLoading(true);
    try {
      const params = { req_ID: payload };
      const response = await handleDeleteRequestApi(params);

      toast.success(response?.data?.data?.message);
      fetchPettyCashRequest();
    } catch (error) {
      toast.error(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading(false);
    }
  };

  // fetch all request - HR 
  const fetchAllEmployeeRequest = async () => {
    setAllRequest([]);
    setLoading(true);
    try {
      const response = await fetchAllEmployeeRequestApi();

      setAllRequest(
        response?.data?.data.map((item, index) => ({ ...item, id: index + 1 }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //Petty request approve reject by HR 
  const handleApproveReject = async (reqId, status, reason) => {
    setLoading(true);
    try {
      const params = { entry_id: reqId, reason: reason || "", status: status };

      const response = await handleApproveRejectApi(params);

      toast.success(response?.data?.data?.message);
      fetchAllEmployeeRequest();

    } catch (error) {
      toast.error(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading(false);
    }
  };

  // fetch all expenses - Accounts 
  const fetchAllEmployeeExpense = async () => {
    setAllExpenses([]);
    setLoading(true);
    try {
      const response = await fetchAllEmployeeExpenseApi();

      setAllExpenses(
        response.data.data.map((item, index) => ({ ...item, id: index + 1 }))
      );

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // fetch petty cash expense
  const fetchPettyCashExpense = async () => {
    setLoading(true);
    try {
      const response = await fetchPettyCashExpenseApi();

      setExpenseData(
        response.data.data.map((item, index) => ({ ...item, id: index + 1 }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //add new Expense
  const handleAddExpense = async (values, file) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("purpose", values.purpose);
    formData.append("amount", values.amount);
    // Ensure the file object is correctly added
    formData.append("attachment", file[0]?.originFileObj || file[0]);

    try {
      const response = await handleAddExpenseApi(formData);
      toast.success(response.data.message);
      fetchPettyCashExpense();
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || "Network Error");
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  //expense approve reject by Accounts 
  const handleApproveRejectExpense = async (reqId, status, reason) => {
    setLoading(true);
    try {
      const params = { entry_id: reqId, reason: reason || "", status: status };

      const response = await handleApproveRejectExpenseApi(params);
      toast.success(response.data.data.message);
      fetchAllEmployeeExpense();
    } catch (error) {
      toast.error(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading(false);
    }
  };

  // update/edit Expense API
  const handleUpdateExpense = async (reqID, payload, fileList) => {
    setLoading(true);
    try {
      const params = { req_ID: reqID };


      const formData = new FormData();
      formData.append("purpose", payload.purpose)
      formData.append("amount", payload.amount)
      formData.append("attachment", fileList[0].originFileObj ? fileList[0].originFileObj : fileList[0])

      const response = await handleUpdateExpenseApi(params, formData);
      toast.success(response.data.data.message);
      setOpen(false);
      fetchPettyCashExpense();
    } catch (error) {
      error?.response ? toast.error(error.response?.data.message) : console.log('error', error)
    } finally {
      setLoading(false);
    }
  };

  // delete Expense req API
  const handleDeleteExpense = async (payload) => {
    setLoading(true);
    try {
      const params = { req_ID: payload };

      const response = await handleDeleteExpenseApi(params);
      fetchPettyCashExpense();
      toast.success(response.data.data.message);
    } catch (error) {
      toast.error(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading(false);
    }
  };

  // Payment update by Accounts
  const updatePaymentStatus = async (payload, setPayDialog) => {
    setLoading(true);
    try {
      const params = { entry_id: payload.reqId };

      const body = {
        paid_amount: payload.paid_amount,
        paid_to: payload.paid_to,
        description: payload.description,
      };
      const response = await updatePaymentStatusApi(params, body);
      toast.success(response.data.data.message);
      fetchAllEmployeeRequest();
    } catch (error) {
      toast.error(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading(false);
      setPayDialog(false)
    }
  };

  // Payment confirmation by receiver
  const confirmPayment = async (reqId) => {
    setLoading(true);
    try {
      const params = { entry_id: reqId };

      const response = await confirmPaymentApi(params);
      toast.success(response.data.data.message);
      fetchPettyCashRequest();
    } catch (error) {
      toast.error(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading(false);
    }
  };

  //fetch requests Report
  const fetchRequestsReport = async () => {
    setRequestsReport([]);
    setLoading(true);
    try {
      const response = await fetchRequestsReportApi();

      console.log("response", response);
      setRequestsReport(
        response.data.data.map((item, index) => ({ ...item, id: index + 1 }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //fetch expense reports
  const fetchExpenseReport = async () => {
    setExpenseReport([]);
    setLoading(true);
    try {
      const response = await fetchExpenseReportApi();
      setExpenseReport(
        response.data.data.map((item, index) => ({ ...item, id: index + 1 }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <PettyCashContext.Provider
      value={{
        handleAddRequest,
        loading,

        dashBalance,
        fetchBalanceDash,

        fetchPettyCashRequest,
        requestData,
        fetchAllEmployeeRequest,
        allRequest,

        handleApproveRejectExpense,
        fetchAllEmployeeExpense,
        allExpenses,

        handleUpdateExpense,
        handleDeleteExpense,

        open,
        setOpen,
        handleAddExpense,
        fetchPettyCashExpense,
        expenseData,
        dialogData,
        setDialogData,
        handleUpdateRequest,
        handleDeleteRequest,
        handleApproveReject,

        requestsReport,
        fetchRequestsReport,

        fetchExpenseReport,
        expenseReport,
        updatePaymentStatus,

        confirmPayment
      }}
    >
      {props.children}
    </PettyCashContext.Provider>
  );
}
