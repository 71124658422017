import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const RtrmsIndex = Loadable(lazy(() => import("views/utilities/rtrms")));

const RtrmsDashboard = Loadable(
  lazy(() => import("views/utilities/rtrms/dashboard"))
);
const RtrmsReports = Loadable(
  lazy(() => import("views/utilities/rtrms/reports"))
);
const RtrmsUtils = Loadable(lazy(() => import("views/utilities/rtrms/utils")));

// rtrms-utils
const RtrmsData = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/data/index"))
);
const RtrmsFileUpload = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/file-upload/index"))
);
const RtrmsFund = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/fund/index"))
);
const RtrmsSettings = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/settings/index"))
);
const RtrmsAllocation = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/allocation/index"))
);

// rtrms-utils-data
const RtrmsDataFixedAllocation = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/data/fixed-allocation"))
);
const RtrmsDataShowDataLiquidBees = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/data/show-data-liquid-bees"))
);
const RtrmsDataShowDataPTB = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/data/show-data-ptb"))
);
const RtrmsFileUploadBod = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/file-upload/bod"))
);
const RtrmsFileUploadEod = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/file-upload/eod"))
);
const RtrmsFileUploadMCX = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/file-upload/mcx"))
);
const RtrmsFileUploadInmarket = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/file-upload/inmarket"))
);
const RtrmsFileUploadPostClosing = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/file-upload/post-closing"))
);
const RtrmsFileUploadPeakMargin = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/file-upload/peak-margin"))
);
const RtrmsFileUploadShortAllocation = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/file-upload/short-margin"))
);
// rtrms-utils-FileUpload

const RtrmsPeakMarginSnap1 = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/file-upload/peak-margin/snapshot-1")
  )
);
const RtrmsPeakMarginSnap2 = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/file-upload/peak-margin/snapshot-2")
  )
);
const RtrmsPeakMarginSnap3 = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/file-upload/peak-margin/snapshot-3")
  )
);
const RtrmsPeakMarginSnap4 = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/file-upload/peak-margin/snapshot-4")
  )
);
const RtrmsPeakMarginEod = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/file-upload/peak-margin/eod"))
);

const RtrmsShortAllocationSnap1 = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/file-upload/short-margin/snapshot-1")
  )
);
const RtrmsShortAllocationSnap2 = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/file-upload/short-margin/snapshot-2")
  )
);
const RtrmsShortAllocationSnap3 = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/file-upload/short-margin/snapshot-3")
  )
);
const RtrmsShortAllocationSnap4 = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/file-upload/short-margin/snapshot-4")
  )
);

const RtrmsShortAllocationEod = Loadable(
  lazy(() => import("views/utilities/rtrms/utils/file-upload/short-margin/eod"))
);

//rtrms-reports
const RtrmsLiveMonitor = Loadable(
  lazy(() => import("views/utilities/rtrms/reports/live-monitor/index"))
);
const RtrmsFavourites = Loadable(
  lazy(() => import("views/utilities/rtrms/reports/favourites/index"))
);
const RtrmsPeakMargin = Loadable(
  lazy(() => import("views/utilities/rtrms/reports/peak-margin/index"))
);
const RtrmsShortAllocation = Loadable(
  lazy(() => import("views/utilities/rtrms/reports/short-allocation/index"))
);
const RtrmsBodAllocation = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/allocation/bod-allocation/index")
  )
);
const RtrmsEodAllocation = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/allocation/eod-allocation/index")
  )
);
const RtrmsMCXAllocation = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/allocation/mcx-allocation/index")
  )
);
const RtrmsManualAllocation = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/allocation/manual-allocation/index")
  )
);
const RtrmsEodReport = Loadable(
  lazy(() => import("views/utilities/rtrms/reports/eod-allocation/index"))
);
const RtrmsCashPosition = Loadable(
  lazy(() => import("views/utilities/rtrms/reports/cash-position/index"))
);

//rtrms-Peak-margin
const RtrmsPeakMarginDailyReport = Loadable(
  lazy(() => import("views/utilities/rtrms/reports/peak-margin/daily-report"))
);

const RtrmsPeakMarginHistoricalReport = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/reports/peak-margin/historical-report")
  )
);

const RtrmsShortAllocationDailyReport = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/reports/short-allocation/daily-report")
  )
);
const RtrmsShortAllocationHistoricalReport = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/reports/short-allocation/historical-report")
  )
);
const RtrmsShortAllocationReport = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/reports/short-allocation/final-short-report")
  )
);

const RtrmsDailySnapshotReport = Loadable(
  lazy(() =>
    import(
      "views/utilities/rtrms/reports/peak-margin/daily-report/snapshot-report"
    )
  )
);
const RtrmsShortAllocationDailySnapshotReport = Loadable(
  lazy(() =>
    import(
      "views/utilities/rtrms/reports/short-allocation/daily-report/snapshot-report"
    )
  )
);

const RtrmsDailyEodReport = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/reports/peak-margin/daily-report/eod-report")
  )
);
const RtrmsShortAllocationDailyEodReport = Loadable(
  lazy(() =>
    import(
      "views/utilities/rtrms/reports/short-allocation/daily-report/eod-report"
    )
  )
);
const RtrmsHistoricalSnapshotReport = Loadable(
  lazy(() =>
    import(
      "views/utilities/rtrms/reports/peak-margin/historical-report/snapshot-report"
    )
  )
);
const RtrmsSnapShotHistoricalSnapshotReport = Loadable(
  lazy(() =>
    import(
      "views/utilities/rtrms/reports/short-allocation/historical-report/snapshot-report"
    )
  )
);
const RtrmsHistoricalEodReport = Loadable(
  lazy(() =>
    import(
      "views/utilities/rtrms/reports/peak-margin/historical-report/eod-report"
    )
  )
);
const RtrmsSnapShotHistoricalEodReport = Loadable(
  lazy(() =>
    import(
      "views/utilities/rtrms/reports/short-allocation/historical-report/eod-report"
    )
  )
);

const RtrmsShortAllocationTemp = Loadable(
  lazy(() =>
    import("views/utilities/rtrms/utils/file-upload/short-margin/temp")
  )
);

const SegregationUploader = Loadable(
  lazy(() => 
    import(
      "views/utilities/rtrms/utils/segregation-uploader"
    )
  )
);

const SegregationMismatch = Loadable(
  lazy(()=>
    import(
      "views/utilities/rtrms/reports/segregation-mismatch"
    )
  )
);

export const RtrmsModule = {
  path: "rtrms",
  element: <RtrmsIndex />,
  children: [
    {
      path: "home",
      element: <RtrmsDashboard />
    },
    {
      path: "utils",
      element: <RtrmsUtils />
    },
    {
      path: "reports",
      element: <RtrmsReports />
    }
  ]
};

export const RtrmsUtilsRoutes = {
  path: "rtrms",
  children: [
    {
      path: "data",
      element: <RtrmsData />
    },
    {
      path: "file-upload",
      element: <RtrmsFileUpload />
    },
    {
      path: "fund",
      element: <RtrmsFund />
    },
    {
      path: "settings",
      element: <RtrmsSettings />
    },
    {
      path: "allocation",
      element: <RtrmsAllocation />
    },
    {
      path: "data-fixed-allocation",
      element: <RtrmsDataFixedAllocation />
    },
    {
      path: "data-show-data-liquid-bees",
      element: <RtrmsDataShowDataLiquidBees />
    },
    {
      path: "data-show-data-ptb",
      element: <RtrmsDataShowDataPTB />
    },
    {
      path: "file-upload-bod",
      element: <RtrmsFileUploadBod />
    },
    {
      path: "file-upload-eod",
      element: <RtrmsFileUploadEod />
    },
    {
      path: "file-upload-mcx",
      element: <RtrmsFileUploadMCX />
    },
    {
      path: "file-upload-inmarket",
      element: <RtrmsFileUploadInmarket />
    },
    {
      path: "file-upload-post-closing",
      element: <RtrmsFileUploadPostClosing />
    },
    {
      path: "file-upload-peak-margin",
      element: <RtrmsFileUploadPeakMargin />
    },
    {
      path: "file-upload-short-allocation",
      element: <RtrmsFileUploadShortAllocation />
    },
    {
      path: "bod-allocation",
      element: <RtrmsBodAllocation />
    },
    {
      path: "eod-allocation",
      element: <RtrmsEodAllocation />
    },
    {
      path: "mcx-allocation",
      element: <RtrmsMCXAllocation />
    },
    {
      path: "segregation-uploader",
      element: <SegregationUploader />
    },
  ],
};

export const RtrmsReportRoutes = {
  path: "rtrms",
  children: [
    {
      path: "live-monitor",
      element: <RtrmsLiveMonitor />
    },
    {
      path: "favourites",
      element: <RtrmsFavourites />
    },
    {
      path: "peak-margin",
      element: <RtrmsPeakMargin />
    },
    {
      path: "short-Allocation",
      element: <RtrmsShortAllocation />
    },
    // {
    //   path: "bod-application",
    //   element: <RtrmsBodApplication />
    // },
    // {
    //   path: "eod-application",
    //   element: <RtrmsEodApplication />
    // },
    {
      path: "eod-report",
      element: <RtrmsEodReport />
    },
    {
      path: "cash-position",
      element: <RtrmsCashPosition />
    },

    {
      path: "manual-allocation",
      element: <RtrmsManualAllocation />
    },
    {
      path: "segregation-mismatch",
      element: <SegregationMismatch />
    }
  ]
};

export const RtrmsUtilsFileUploadPeakMargin = {
  path: "rtrms",
  children: [
    {
      path: "file-upload-peak-margin/snapshot-1",
      element: <RtrmsPeakMarginSnap1 />
    },
    {
      path: "file-upload-peak-margin/snapshot-2",
      element: <RtrmsPeakMarginSnap2 />
    },
    {
      path: "file-upload-peak-margin/snapshot-3",
      element: <RtrmsPeakMarginSnap3 />
    },
    {
      path: "file-upload-peak-margin/snapshot-4",
      element: <RtrmsPeakMarginSnap4 />
    },
    {
      path: "file-upload-peak-margin/eod",
      element: <RtrmsPeakMarginEod />
    },

    {
      path: "file-upload-short-allocation/snapshot-1",
      element: <RtrmsShortAllocationSnap1 />
    },
    {
      path: "file-upload-short-allocation/snapshot-2",
      element: <RtrmsShortAllocationSnap2 />
    },
    {
      path: "file-upload-short-allocation/snapshot-3",
      element: <RtrmsShortAllocationSnap3 />
    },
    {
      path: "file-upload-short-allocation/snapshot-4",
      element: <RtrmsShortAllocationSnap4 />
    },
    {
      path: "file-upload-short-allocation/eod",
      element: <RtrmsShortAllocationEod />
    },
    {
      path: "file-upload-short-allocation/temp",
      element: <RtrmsShortAllocationTemp />
    }
  ]
};
export const RtrmsReportsPeakMargin = {
  path: "rtrms",
  children: [
    {
      path: "daily-report",
      element: <RtrmsPeakMarginDailyReport />
    },
    {
      path: "short-allocation/daily-report",
      element: <RtrmsShortAllocationDailyReport />
    },
    {
      path: "historical-report",
      element: <RtrmsPeakMarginHistoricalReport />
    },
    {
      path: "short-allocation/final-short-report",
      element: <RtrmsShortAllocationReport />
    },

    {
      path: "short-allocation/historical-report",
      element: <RtrmsShortAllocationHistoricalReport />
    },
    {
      path: "daily-snapshot-report",
      element: <RtrmsDailySnapshotReport />
    },
    {
      path: "short-allocation/daily-snapshot-report",
      element: <RtrmsShortAllocationDailySnapshotReport />
    },
    {
      path: "daily-eod-report",
      element: <RtrmsDailyEodReport />
    },
    {
      path: "short-allocation/daily-eod-report",
      element: <RtrmsShortAllocationDailyEodReport />
    },
    {
      path: "historical-snapshot-report",
      element: <RtrmsHistoricalSnapshotReport />
    },
    {
      path: "short-allocation/historical-snapshot-report",
      element: <RtrmsSnapShotHistoricalSnapshotReport />
    },

    {
      path: "historical-eod-report",
      element: <RtrmsHistoricalEodReport />
    },
    {
      path: "short-allocation/historical-eod-report",
      element: <RtrmsSnapShotHistoricalEodReport />
    }
  ]
};
