import { commonApiRequest } from "utils/CommonFunctions";

const REACT_APP_URL_EMAIL = process.env.REACT_APP_URL_EMAIL;

export const getMailDataApi = (labels, pagination) => {
  const url = `${REACT_APP_URL_EMAIL}/v1/${labels?.toLowerCase()}?page=${pagination.pageIndex + 1
    }&page_size=${pagination.pageSize}`;
  return commonApiRequest("POST", url);
};

export const composeMailApi = (formdata) => {
  const url = `${REACT_APP_URL_EMAIL}/v1/send-email`;
  return commonApiRequest("POST", url, formdata);
};

export const handleBookmarkMailApi = (formdata, labels) => {
  const url = `${REACT_APP_URL_EMAIL}/v1/request-bookmark?folder=${labels}`;
  return commonApiRequest("POST", url, formdata);
};

export const deleteMailApi = (formdata, labels) => {
  const url = `${REACT_APP_URL_EMAIL}/v1/delete-email?folder=${labels}`;
  return commonApiRequest("POST", url, formdata);
};

export const restoreMailApi = (formdata) => {
  const url = `${REACT_APP_URL_EMAIL}/v1/restore-email`;
  return commonApiRequest("POST", url, formdata);
};

export const getMailBodyApi = (code, labels) => {
  const url = `${REACT_APP_URL_EMAIL}/v1/mail-body?email_code=${code}&folder=${labels}`;
  return commonApiRequest("POST", url);
};

export const getMailSearchDataApi = (labels, globalFilter, pagination) => {
  const url = `${REACT_APP_URL_EMAIL}/v1/search?folder=${labels}&search_query=${globalFilter}&page=${pagination.pageIndex + 1
    }&page_size=${pagination.pageSize}`;
  return commonApiRequest("POST", url);
};

export const MailDetailsApi = (code, labels) => {
  const url = `${REACT_APP_URL_EMAIL}/v1/mail-details?email_code=${code}&folder=${labels}`;
  return commonApiRequest("POST", url);
};

export const ForwardMailApi = (data, labels) => {
  const url = `${REACT_APP_URL_EMAIL}/v1/forward-email?folder=${labels}`;
  return commonApiRequest("POST", url, data);
};

export const draftMailApi = (formdata) => {
  const url = `${REACT_APP_URL_EMAIL}/v1/draft-email`;
  return commonApiRequest("POST", url, formdata);
};

export const fetchGroupList = async () => {
  const url = `${REACT_APP_URL_EMAIL}/v1/group_detail`;
  return commonApiRequest('GET', url);
};

export const crudGroupp = async (values, participantListArr = [], action) => {
  const url = `${REACT_APP_URL_EMAIL}/v1/group`;
  const paramsData = { action: action };
  const body = {
    group_id: (action == "delete" ? values?.ID : values._id) || "",
    group_name: values.groupName || "",
    members: participantListArr,
  };
  return commonApiRequest('POST', url, body, paramsData);
};