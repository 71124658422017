/*eslint-disable*/
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchEventGallery,
  handleEventGalleryAdd,
  handleEventGalleryDelete,
  handleEventGalleryUpdate,
} from "services/event-gallery/eventGallery";

export const EventGalleryContext = createContext();

export default function eventGalleryApi(props) {
  const [eventGalleryData, setEventGalleryData] = useState([]);

  const fetchAllEventGallery = async () => {
    try {
      const response = await fetchEventGallery();
      if (response.status === 200) {
        console.log("response", response);
        setEventGalleryData(response.data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const addEventGallery = async (values, others) => {
    try {
      const formData = new FormData();
      formData.append("event_header", values.event_header);
      formData.append("event_start_date", values.event_start_date);
      formData.append("event_end_date", values.event_end_date);
      formData.append("event_location", values.event_location);

      values.files.forEach((file) => {
        formData.append("files", file); 
      });

      const resJson = await handleEventGalleryAdd(formData);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllEventGallery();
    } catch (error) {
      console.error("error", error);
      toast.error(resJson.data.message);
    }
  };

  const updateEventGallery = async (values, others, event_id) => {
    try {
      const formData = new FormData();
      formData.append("event_id", event_id);
      formData.append("event_header", values.event_header);
      formData.append("event_start_date", values.event_start_date);
      formData.append("event_end_date", values.event_end_date);
      formData.append("event_location", values.event_location);

      values.files.forEach((file) => {
        formData.append("files", file); 
      });

      const resJson = await handleEventGalleryUpdate(formData, event_id);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllEventGallery();
    } catch (error) {
      console.error("error", error);
      toast.error(resJson.data.message);
    }
  };

  const deleteEventGallery = async (event_id) => {
    try {
      const resJson = await handleEventGalleryDelete({ event_id });
      console.log("resJson", resJson);
      if (resJson.status === 200) {
        fetchAllEventGallery();
        toast.success(resJson.data.message);
      } else {
        toast.error("Failed to delete blog.");
      }
    } catch (error) {
      console.error("Delete blog error:", error);
      toast.error("An error occurred while deleting the blog.");
    }
  };

  return (
    <EventGalleryContext.Provider
      value={{
        eventGalleryData,
        fetchAllEventGallery,
        setEventGalleryData,
        addEventGallery,
        deleteEventGallery,
        updateEventGallery,
      }}
    >
      {props.children}
    </EventGalleryContext.Provider>
  );
}
