/*eslint-disable*/
import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const MutualFundsHome = Loadable(
  lazy(() => import("views/utilities/mutual-funds/dashboard"))
);
const MutualFundsIndex = Loadable(
  lazy(() => import("views/utilities/mutual-funds/index"))
);
const MutualFundsRequest = Loadable(
  lazy(() => import("views/utilities/mutual-funds/utils/client"))
);

const TaskManager = Loadable(
  lazy(() => import("views/utilities/mutual-funds/utils/taskManager"))
);
const MutualFundReport = Loadable(
  lazy(() => import("views/utilities/mutual-funds/reports/total-expense"))
);

const MutualFundsUtilities = Loadable(
  lazy(() => import("views/utilities/mutual-funds/utils"))
);
const TotalExpenseReport = Loadable(
  lazy(() => import("views/utilities/mutual-funds/reports"))
);

export const WmsModule = {
  path: "wms",
  element: <MutualFundsIndex />,
  children: [
    {
      path: "home",
      element: <MutualFundsHome />,
    },
    {
      path: "utils",
      element: <MutualFundsUtilities />,
    },

    {
      path: "reports",
      element: <TotalExpenseReport />,
    },
  ],
};

export const WmsUtilsRoutes = {
  path: "wms",
  children: [
    {
      path: "client",
      element: <MutualFundsRequest />,
    },

    {
      path: "task-manager",
      element: <TaskManager />,
    },
  ],
};
export const WmsReportsRoutes = {
  path: "wms",
  children: [
    {
      path: "total-expense",
      element: <MutualFundReport />,
    },
    // {
    //   path: "profit-loss",
    //   element: <ProfitAndLossReport />,
    // },
  ],
};
