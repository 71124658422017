/*eslint-disable*/
import { useHandleCatch } from 'hooks/useHandleCatch';
import React, { createContext, useState } from 'react'
import { createNotice, deleteNotice, fetchNotice, updateNotice } from 'services/notice-board/NoticeBoardService';


export const NoticeBoardContext = createContext()

export default function NoticeBoardApis(props) {

  const [noticeBoardData, setNoticeBoardData] = useState([])
  const [noticeEditData, setNoticeEditData] = useState([])
  const handleCatch = useHandleCatch()

  const handleCreateNotice = async (values, setOpenDailog) => {
    try {
      const body = {
        notice: values.noticeText,
        validity: values.validity,
      }
      const response = await createNotice(body);
      if (response.status === 201) {
        handleFetchNotice()
        setOpenDailog(false)
      }
    } catch (error) {
      handleCatch(error)
    }
  }

  const handleUpdateNotice = async (values, setOpenDailog) => {
    try {
      const body = {
        notice: values.noticeText,
        validity: values.validity,
        notice_id: values.notice_id
      }
      const response = await updateNotice(body);
      if (response.status == 201) {
        handleFetchNotice()
        setOpenDailog(false)
      }
    } catch (err) {
      handleCatch(err)
    }
  }

  const handleFetchNotice = async () => {
    try {
      const response = await fetchNotice();
      if (response?.status === 200) {
        setNoticeBoardData(response.data.data)
      }
    } catch (error) {
      handleCatch(error)
    }
  }


  const handleDeleteNotice = async (setOpenDailog) => {
    const response = await deleteNotice(noticeEditData.notice_id);
    if (response?.status === 201) {
      handleFetchNotice()
      setOpenDailog(false)
    }
  }

  return (
    <NoticeBoardContext.Provider value={{
      handleCreateNotice,
      handleFetchNotice,
      noticeBoardData,
      noticeEditData,
      setNoticeEditData,
      handleUpdateNotice,
      handleDeleteNotice
    }}>
      {props.children}
    </NoticeBoardContext.Provider>
  )
}
