import { FILE } from "config";

const ApOnBoardingFeatures = [
  {
    app_id: 29,
    feature_id: 2901,
    feature_name: 'lead_add',
    type: FILE,
    subfolder: [],
    label: "New Lead",
    path: "/ap-onboarding/new-ap-req"
  },
  {
    app_id: 29,
    feature_id: 2902,
    feature_name: 'view_status',
    type: FILE,
    subfolder: [],
    label: "Status",
    path: "/ap-onboarding/check-status"
  },
  {
    app_id: 29,
    feature_id: 2903,
    feature_name: 'list',
    type: FILE,
    subfolder: [],
    label: "List",
    path: "/ap-onboarding/list"
  },
  {
    app_id: 29,
    feature_id: 2904,
    label: "Verification",
    feature_name: 'verification',
    type: FILE,
    subfolder: [],
    path: "/ap-onboarding/prepare-docs"
  },
  {
    app_id: 29,
    feature_id: 2905,
    label: "Check List",
    feature_name: 'check_list',
    type: FILE,
    subfolder: [],
    path: "/ap-onboarding/check-list"
  },
  {
    app_id: 30,
    feature_id: 2906,
    label: "Branch Creation",
    feature_name: 'branch_creation',
    type: FILE,
    subfolder: [],
    path: "/ap-onboarding/branch-creation"
  },
  // {
  //   app_id: 21,
  //   feature_id: 3,
  //   label: "Process Flow",
  //   path: "/ap-onboarding/process-flow"
  // },

  // {
  //   id: 4,
  //   app_id: 21,
  //   feature_id: 5,
  //   label: "Upload Docs",
  //   path: "/ap-onboarding/upload-docs"
  // },

];

export default ApOnBoardingFeatures;



export const categoryList = [
  { value: "individual", label: "Individual" },
  { value: "partnership", label: "Partnership" },
  { value: "huf", label: "HUF" },
  { value: "corporate_pvt_ltd", label: "Corporate Pvt Ltd." }
];
