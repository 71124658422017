import { commonApiRequest } from "utils/CommonFunctions";

export const fetchAppInfoApi = () => commonApiRequest('GET', `${process.env.REACT_APP_URL}/v1/admin/app-list`);

export const fetchFeatureInfoApi = (app_id) => commonApiRequest('GET', `${process.env.REACT_APP_URL}/v1/admin/report-feature-by-appid`, null, { app_id });

export const fetchReportsApi = (app_id) => commonApiRequest('GET', `${process.env.REACT_APP_URL}/v1/admin/report-feature-by-appid`, null, { app_id });

export const fetchDepartmentListApi = () => commonApiRequest('GET', `${process.env.REACT_APP_URL}/v1/user/fetch-lists`, null, { entity_name: 'department_list' });

export const addNewAppApi = (body) => commonApiRequest('POST', `${process.env.REACT_APP_URL}/v1/admin/add-app`, body);

export const addNewFeatureApi = (body) => commonApiRequest('POST', `${process.env.REACT_APP_URL}/v1/admin/add-app-feature`, body);

export const addNewReportApi = (body) => commonApiRequest('POST', `${process.env.REACT_APP_URL}/v1/admin/add-app-report`, body);

export const updateFeatureInfoApi = (body) => commonApiRequest('PUT', `${process.env.REACT_APP_URL}/v1/admin/update-app-feature`, body);

export const updateReportInfoApi = (body) => commonApiRequest('PUT', `${process.env.REACT_APP_URL}/v1/admin/update-app-report`, body);

export const updateAppInfoApi = (body) => commonApiRequest('PUT', `${process.env.REACT_APP_URL}/v1/admin/update-app-detail`, body);
// import { getHeaders } from "utils/CommonFunctions";

// const COMMON_URL = process.env.REACT_APP_URL;

// export const fetchAppInfoApi = async () => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/app-list`;
//     const response = await axios.get(url, config);
//     return response;
// };

// export const fetchFeatureInfoApi = async (app_id) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/report-feature-by-appid?app_id=${app_id}`;
//     const response = await axios.get(url, config);
//     return response;
// };

// export const fetchReportsApi = async (app_id) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/report-feature-by-appid?app_id=${app_id}`;
//     const response = await axios.get(url, config);
//     return response;
// };

// export const fetchDepartmentListApi = async () => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/user/fetch-lists?entity_name=department_list`;
//     const response = await axios.get(url, config);
//     return response;
// };

// export const addNewAppApi = async (body) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/add-app`;
//     const response = await axios.post(url, body, config);
//     return response;
// };

// export const addNewFeatureApi = async (body) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/add-app-feature`;
//     const response = await axios.post(url, body, config);
//     return response;
// };

// export const addNewReportApi = async (body) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/add-app-report`;
//     const response = await axios.post(url, body, config);
//     return response;
// };

// export const updateFeatureInfoApi = async (body) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/update-app-feature`;
//     const response = await axios.put(url, body, config);
//     return response;
// };

// export const updateReportInfoApi = async (body) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/update-app-report`;
//     const response = await axios.put(url, body, config);
//     return response;
// };

// export const updateAppInfoApi = async (body) => {
//     const config = {
//         headers: getHeaders
//     }
//     const url = `${COMMON_URL}/v1/admin/update-app-detail`;
//     const response = await axios.put(url, body, config);
//     return response;
// };