/*eslint-disable*/
import React from "react";
import { Navigate } from "react-router";
import { getSessionStorage } from "./encrpytion";
// import { toast } from 'react-toastify';

export default function FeaturesRequired({ children, feature }) {
  // if (getSessionStorage("features")) {
  //   const isVerificationInList = getSessionStorage("features")
  //     ?.split(",")
  //     .includes(feature);

  //   if (!isVerificationInList) {
  //     return <Navigate to={"/error404"} />;
  //   }
  // } else {
  //   return <Navigate to={"/error404"} />;
  // }

  return children;
}
