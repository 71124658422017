/*eslint-disable*/
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchpdf,
  handlepdfAdd,
  handlePdfDelete,
  handlePdfUpdate,
  downloadPdf,
  handleMagazineAdd,
  handleMagazineUpdate,
  fetchMagazine,
  handleMagazineDelete,
  downloadMagazine,
} from "services/arham-pdf/arhampdf";

export const ArhamPdfContext = createContext();

export default function arhampdfApi(props) {
  const [pdfData, setPdfData] = useState([]);
  const [magazineData, setMagazineData] = useState([]);

  const handleDownloadPdf = async (file_identifier) => {
    try {
      const decodedPdfName = decodeURIComponent(file_identifier);

      const response = await downloadPdf(decodedPdfName);
      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const downloadUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${decodedPdfName}.pdf`; // Using decoded name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Error fetching PDF", response);
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const fetchAllPdf = async () => {
    try {
      const response = await fetchpdf();
      if (response.status === 200) {
        console.log("response", response);
        setPdfData(response.data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const addpdf = async (values, others) => {
    try {
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("file_identifier", values.file_identifier);

      const resJson = await handlepdfAdd(formData);
      console.log("resJson", resJson);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllPdf();
    } catch (error) {
      console.error("error", error);

      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
    }
  };

  const deletePdf = async (pdf_id) => {
    try {
      const resJson = await handlePdfDelete({ pdf_id });
      console.log("resJson", resJson);
      if (resJson.status === 200) {
        fetchAllPdf();
        toast.success(resJson.data.message);
      } else {
        toast.error("Failed to delete pdf.");
      }
    } catch (error) {
      console.error("Delete pdf error:", error);
      toast.error("An error occurred while deleting the pdf.");
    }
  };

  const updatePdf = async (values, others, pdf_id) => {
    try {
      const formData = new FormData();
      formData.append("pdf_id", pdf_id);
      formData.append("pdf_name", values.pdf_name);
      formData.append("file_identifier", values.file_identifier);

      if (values.file) {
        formData.append("file", values.file);
      }

      const resJson = await handlePdfUpdate(formData, pdf_id);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllPdf();
    } catch (error) {
      console.error("error", error);
      toast.error(error.message || "An error occurred");
    }
  };

  //Magazine

  const addMagazine = async (values, others) => {
    try {
      const formData = new FormData();
      formData.append("magazine_date", values.magazine_date);
      values.files.forEach((file) => {
        formData.append("files", file);
      });

      const resJson = await handleMagazineAdd(formData);
      console.log("resJson", resJson);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllMagazine();
    } catch (error) {
      console.error("error", error);

      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
    }
  };

  const updateMagazine = async (values, others, magazine_id) => {
    try {
      const params = {
        magazine_id,
      };
      const formData = new FormData();
      formData.append("magazine_date", values.magazine_date);
      values.files.forEach((file) => {
        formData.append("files", file);
      });
      const resJson = await handleMagazineUpdate(formData, params);
      console.log("resJson", resJson);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllMagazine();
    } catch (error) {
      console.error("error", error);
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
    }
  };

  const fetchAllMagazine = async () => {
    try {
      const response = await fetchMagazine();
      if (response.status === 200) {
        console.log("response", response);
        setMagazineData(response.data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const deleteMagazine = async (magazine_id) => {
    try {
      const resJson = await handleMagazineDelete({ magazine_id });
      console.log("resJson", resJson);
      if (resJson.status === 200) {
        fetchAllMagazine();
        toast.success(resJson.data.message);
      } else {
        toast.error("Failed to delete magazine.");
      }
    } catch (error) {
      console.error("Delete magazine error:", error);
      toast.error("An error occurred while deleting the magazine.");
    }
  };

  const handleDownloadMagazine = async (magazine_name) => {
    try {
      const decodedMagazineName = decodeURIComponent(magazine_name);
      const response = await downloadMagazine(decodedMagazineName);
      if (response.status === 200) {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const downloadUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = decodedMagazineName; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl); 
      } else {
        console.error("Error fetching PDF", response);
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <ArhamPdfContext.Provider
      value={{
        pdfData,
        fetchAllPdf,
        downloadPdf,
        setPdfData,
        addpdf,
        deletePdf,
        updatePdf,
        handleDownloadPdf,
        addMagazine,
        updateMagazine,
        setMagazineData,
        magazineData,
        fetchAllMagazine,
        deleteMagazine,
        handleDownloadMagazine,
      }}
    >
      {props.children}
    </ArhamPdfContext.Provider>
  );
}
