/*eslint-disable*/
import React, { useState } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import {
  BSEFileDownloadApi,
  BSESagmentFolderApi,
  FileDownloadApi,
  SagmentFolderApi,
} from "services/extra-net/ExtraNetService";
import { Base64ToFileDownload } from "utils/CommonFunctions";

export const ExtraNetContext = createContext();

export default function ExtraNetProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [SegmentData, setSegmentData] = useState([]);
  const [BSESegmentData, setBSESegmentData] = useState([]);
  const [FolderData, setFolderData] = useState([]);
  const navigate = useNavigate();

  //       Extra Net      //
  const handleSagmentFolder = async (path) => {
    setIsLoading(true);
    setSegmentData([]);
    try {
      const body = {
        outer: localStorage.outer,
        segment: localStorage.segment,
        path: path,
      };
      const response = await SagmentFolderApi(body);
      if (response.status == 200) {
        const data = response?.data?.data.map((item, index) => ({ ...item, id: index + 1 }))
        setSegmentData(data);
        setIsLoading(false);
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.log("==>", err);
      toast.error(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
      setIsLoading(false);
    }
  };

  const FileDownload = async (row) => {
    setSegmentData((prevData) =>
      prevData.map((item) =>
        item.name === row.name ? { ...item, is_download: true } : item
      )
    );
    try {
      const body = {
        outer: localStorage.outer,
        segment: localStorage.segment,
        path: row.folderPath,
        filename: row.name,
      };
      const response = await FileDownloadApi(body);
      if (response.status == 200) {
        const { file_content, mimetype, file_name } = await response.data.data;
        Base64ToFileDownload(file_content, mimetype, file_name);
      } else {
        toast.error(response.data.message);
      }
      setSegmentData((prevData) =>
        prevData.map((item) =>
          item.name === row.name ? { ...item, is_download: false } : item
        )
      );
    } catch (err) {
      setSegmentData((prevData) =>
        prevData.map((item) =>
          item.name === row.name ? { ...item, is_download: false } : item
        )
      );
      console.log("==>", err);
      toast.error(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
    }
  };

  //       BSE SFTP      //
  const handleBSESagmentFolder = async (type) => {
    setIsLoading(true);
    setSegmentData([]);
    try {
      const body = {
        path: sessionStorage.BSE_path,
      };
      const response = await BSESagmentFolderApi(body);
      if (response.status == 200) {

        const data = response?.data?.data.map((item, index) => ({ ...item, id: index + 1 }))

        type === "folder"
          ? setFolderData(data)
          : setBSESegmentData(data);
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    } catch (err) {
      console.log("==>", err);
      toast.error(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
      setIsLoading(false);
    }
  };

  const BSEFileDownload = async (row) => {
    setBSESegmentData((prevData) =>
      prevData.map((item) =>
        item.name === row.name ? { ...item, is_download: true } : item
      )
    );
    try {
      const body = {
        path: `${sessionStorage.BSE_path}/${row.name}`,
        file_name: row.name,
      };
      const response = await BSEFileDownloadApi(body);
      if (response.status == 200) {
        const { file_content, mimetype, file_name } = await response.data.data;
        Base64ToFileDownload(file_content, mimetype, file_name);
      } else {
        toast.error(response.data.message);
      }
      setBSESegmentData((prevData) =>
        prevData.map((item) =>
          item.name === row.name ? { ...item, is_download: false } : item
        )
      );
    } catch (err) {
      setBSESegmentData((prevData) =>
        prevData.map((item) =>
          item.name === row.name ? { ...item, is_download: false } : item
        )
      );
      console.log("==>", err);
      toast.error(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
    }
  };

  return (
    <ExtraNetContext.Provider
      value={{
        isLoading,
        setIsLoading,
        FolderData,
        setFolderData,
        BSESegmentData,
        setBSESegmentData,
        SegmentData,
        FileDownload,
        BSEFileDownload,
        handleSagmentFolder,
        handleBSESagmentFolder,
      }}
    >
      {children}
    </ExtraNetContext.Provider>
  );
}
