import { FILE } from "config";

const EventFeatures = [
  {
    app_id: 30,
    feature_id: 3001,
    type: FILE,
    subfolder: [],
    feature_name: "event-view",
    label: "View",
    path: "/event/view",
  },
  {
    app_id: 30,
    feature_id: 3002,
    type: FILE,
    subfolder: [],
    feature_name: "event-budget-request",
    label: "Event Budget Request",
    path: "/event/event-budget-request"
  },
  {
    app_id: 30,
    feature_id: 3003,
    type: FILE,
    subfolder: [],
    feature_name: "event-planning-request",
    label: "Event Planning Request",
    path: "/event/event-planning-request"
  }

];

export default EventFeatures;
