
/*eslint-disable*/
import { useHandleCatch } from "hooks/useHandleCatch";
import React, { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { addCommentWithAttchment, addTaskInTicket, addTickets, changeDepartmentInTicket, fetchDepartmentListApi, fetchFollowersByTicket, fetchTaskInTicket, fetchTicketsByTabs, fetchTicketsCommentsByTicketId, fetchUserList, fetchUserWithDetailsList, updateFollowersInTickets, updateTicketData, updateTicketStatus } from "services/help-desk/HelpDeskService";
import { getSessionStorage } from "utils/encrpytion";

export const HelpDeskContext = createContext();

export default function HelpDeskApis(props) {
  const [departmentList, setDepartmentList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [ticketTableData, setTicketTableData] = useState([]);
  const [ticketCommentAttachmentchData, setTicketCommentAttachmentchData] = useState([]);
  const [followersByTicket, setFollowersByTicket] = useState([]);
  const [allUsersDetails, setAllUsersDetails] = useState([]);
  const [ticketTasks, setTicketTasks] = useState([]);
  const [tabsListDrawer, setTabListDrawer] = useState(false);
  const [switchValues, setSwitchValues] = useState({
    assigned: true,
    myticket: true,
    all: true,
    following: false,
  });


  const handleCatch = useHandleCatch();

  const fetchDepartmentList = async () => {
    try {
      const response = await fetchDepartmentListApi()
      if (response.data.status === "success") {
        setDepartmentList(response.data.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleFetchUserWithDetailsList = async () => {
    try {
      const response = await fetchUserWithDetailsList()
      if (response.data.status === "success") {
        setAllUsersDetails(response.data.data)
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchUserList = async () => {
    try {
      const response = await fetchUserList()
      if (response.data.status === "success") {
        setEmployeeList(response.data.data)
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const handleAddTickets = async (values, tabVal, setOpen, setSubmitting) => {
    const formdata = new FormData();
    formdata.append("subject", values.ticket_title);
    formdata.append("description", values.desc);
    if (values.deptname) {
      formdata.append("dept_id", values.deptname);
    }
    if (values.ticket_category) {
      formdata.append("ticket_category", values.ticket_category);
    }
    for (let i = 0; i < values.uploadedFiles.length; i++) {
      formdata.append("files", values.uploadedFiles[i]);
    }
    try {
      const response = await addTickets(formdata)
      const resJson = response.data;
      if (resJson.status === "success") {
        setSubmitting(false)
        if (getSessionStorage("user_profile")?.toLowerCase() === "vp"
          || getSessionStorage("user_role")?.toLowerCase() === "admin" || getSessionStorage("user_type")?.toLowerCase() === "super_user") {
          handleFetchTicketsByTabs(tabVal)
        } else {
          if (tabVal == "all") {
            handleFetchTicketsByTabs("department")
          } else {
            handleFetchTicketsByTabs(tabVal)
          }
        }

        setOpen(false);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchTicketsByTabs = async (param) => {
    setTicketTableData([]);
    try {
      const paramsData = { ticket_for: param }
      const response = await fetchTicketsByTabs(paramsData)
      const resJson = response.data;
      if (resJson.status === "success") {
        const tempData = resJson?.data?.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setTicketTableData(tempData);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleAddCommentWithAttchment = async (ticketData, code, uploadedFiles) => {
    const formdata = new FormData();
    formdata.append("ticket_id", ticketData.row.ticket_id);
    formdata.append("comment", code.trim());
    for (let i = 0; i < uploadedFiles.length; i++) {
      formdata.append("files", uploadedFiles[i]);
    }
    try {
      const response = await addCommentWithAttchment(formdata)
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFetchTicketsCommentsByTicketId(ticketData.row.ticket_id);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchTicketsCommentsByTicketId = async (param) => {
    try {
      const paramsData = { ticket_id: param }
      const response = await fetchTicketsCommentsByTicketId(paramsData)
      const resJson = response.data;
      if (resJson.status === "success") {
        setTicketCommentAttachmentchData(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateTicketData = async (ticket_id, ticketObject, value, inOut, setopenAssignToDialog, setOpenTicket) => {
    try {
      const body = {
        ticket_id: ticket_id,
        assign_to: ticketObject.ticketAssign || "",
        priority: ticketObject.ticketPriority || "",
        due_date: ticketObject.ticketDueDate || "",
      }
      const response = await updateTicketData(body)
      const resJson = response.data;
      if (resJson.status === "success") {


        if (getSessionStorage("user_profile")?.toLowerCase() === "vp"
          || getSessionStorage("user_role")?.toLowerCase() === "admin" || getSessionStorage("user_type")?.toLowerCase() === "super_user") {
          handleFetchTicketsByTabs(value)
        } else {
          if (value == "all") {
            handleFetchTicketsByTabs("department")
          } else {
            handleFetchTicketsByTabs(value)
          }
        }



        if (inOut === "in") {
          setOpenTicket(false);
          setopenAssignToDialog(false);
        }
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateTicketStatus = async (ticket_id, ticketStatus, value, setStatus, setOpenTicket) => {
    try {
      const paramsData = { ticket_id, ticket_status: ticketStatus }
      const response = await updateTicketStatus(paramsData)
      const resJson = response.data;
      if (resJson.status === "success") {

        if (getSessionStorage("user_profile")?.toLowerCase() === "vp"
          || getSessionStorage("user_role")?.toLowerCase() === "admin" || getSessionStorage("user_type")?.toLowerCase() === "super_user") {
          handleFetchTicketsByTabs(value)
        } else {
          if (value == "all") {
            handleFetchTicketsByTabs("department")
          } else {
            handleFetchTicketsByTabs(value)
          }
        }
        setStatus(false);
        setOpenTicket(false);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateFollowersInTickets = async (ticket_id, follower_id, action) => {
    try {
      const paramsData = { ticket_id, follower_id, action }
      const response = await updateFollowersInTickets(paramsData)
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFetchFollowersByTicket(ticket_id);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchFollowersByTicket = async (ticket_id) => {
    try {
      const paramsData = { ticket_id }
      const response = await fetchFollowersByTicket(paramsData)
      const resJson = response.data;
      if (resJson.status === "success") {
        setFollowersByTicket(resJson.data.followers);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleChangeDepartmentInTicket = async (ticket_id, dept_id, action, tabVal) => {
    try {
      const body = {
        ticket_id,
        dept_id,
        action,
      }
      const response = await changeDepartmentInTicket(body)
      const resJson = response.data;
      if (resJson.status === "success") {


        if (getSessionStorage("user_profile")?.toLowerCase() === "vp"
          || getSessionStorage("user_role")?.toLowerCase() === "admin" || getSessionStorage("user_type")?.toLowerCase() === "super_user") {
          handleFetchTicketsByTabs(tabVal)
        } else {
          if (tabVal == "all") {
            handleFetchTicketsByTabs("department")
          } else {
            handleFetchTicketsByTabs(tabVal)
          }
        }



        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleAddTaskInTicket = async (values, setOpen) => {
    try {
      const body = {
        ticket_id: values.ticket_id,
        name: values.task_name,
        assigned_to: values.assign,
        task_type: values.task_type,
        category: values.task_priority,
        parent_task_id: "",
      }
      const response = await addTaskInTicket(body)
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFetchTaskInTicket(values.ticket_id);
        setOpen(false);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchTaskInTicket = async (target_id) => {
    try {
      const paramsData = { ticket_id: target_id }
      const response = await fetchTaskInTicket(paramsData)
      const resJson = response.data;
      if (resJson.status === "success") {
        const tempData = resJson.data?.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setTicketTasks(tempData);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  return (
    <HelpDeskContext.Provider
      value={{
        fetchDepartmentList,
        departmentList,
        handleFetchUserList,
        employeeList,
        handleAddTickets,
        handleFetchTicketsByTabs,
        ticketTableData,
        handleAddCommentWithAttchment,
        handleFetchTicketsCommentsByTicketId,
        ticketCommentAttachmentchData,
        handleUpdateTicketData,
        handleUpdateTicketStatus,
        handleUpdateFollowersInTickets,
        handleFetchFollowersByTicket,
        setFollowersByTicket,
        followersByTicket,
        handleChangeDepartmentInTicket,
        handleFetchUserWithDetailsList,
        allUsersDetails,
        handleAddTaskInTicket,
        handleFetchTaskInTicket,
        ticketTasks,
        tabsListDrawer, setTabListDrawer,
        switchValues, setSwitchValues
      }}
    >
      {props.children}
    </HelpDeskContext.Provider>
  );
}
