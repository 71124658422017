import { commonApiRequest } from "utils/CommonFunctions";

export const handleUploadFilesApi = (formData) => {
    const url = `${process.env.REACT_APP_URL_BOUNCE_MAIL}/v1/upload-log-file`;
    return commonApiRequest('POST', url, formData);
};

export const handleFetchFileLogsApi = () => {
    const url = `${process.env.REACT_APP_URL_BOUNCE_MAIL}/v1/fetch-file-logs`;
    return commonApiRequest('GET', url);
};

export const handleFetchBouncePodsDataApi = (params) => {
    const url = `${process.env.REACT_APP_URL_BOUNCE_MAIL}/v1/fetch-log-data`;
    return commonApiRequest('GET', url, null, params);
};

export const handleUpdatePodsApi = (formData, params) => {
    const url = `${process.env.REACT_APP_URL_BOUNCE_MAIL}/v1/upload-pod-file`;
    return commonApiRequest('POST', url, formData, params);
};

export const handleFetchSummaryReportApi = (params) => {
    const url = `${process.env.REACT_APP_URL_BOUNCE_MAIL}/v1/fetch-bounce-report`;
    return commonApiRequest('GET', url, null, params);
};
