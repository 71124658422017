// routing
import Routes from 'routes';
import ContextWrapper from 'context/ContextWrapper';
import Toastify from 'utils/Toastify';

// ==============================|| APP ||============================== //

const App = () => {
  return (
    <ContextWrapper>
      <Routes />
      <Toastify />
    </ContextWrapper>
  );
};

export default App;
