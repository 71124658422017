/*eslint-disable*/
import axios from "axios";
import { commonApiRequest, commonApiRequestNew } from "utils/CommonFunctions";

const apiUrl = process.env.REACT_APP_URL_RMS;
const BeniUrl = process.env.REACT_APP_URL_RMS_BENI_FILE_DOWNLOAD;

//Client Master
export const getClientMasterApi = async () => {
  const response = await axios.get(`${apiUrl}/V1/getRetailCLientMaster`, {
    headers: {
      accept: "application/json",
    },
  });
  return response;
};

export const addClientMasterApi = async (values) => {
  const url = `${apiUrl}/V1/addRetailCLientMaster`;
  const body = {
    client: values.client,
    Branch: values.Branch,
    IsDiscount: values.IsDiscount,
    intraday: values.intraday,
    IsIncludeAutoSquare: values.IsIncludeAutoSquare,
    IsIncludeFurtherExpo: values.IsIncludeFurtherExpo,
    ps03_upload: values.ps03_upload,
    RMSTemplate: values.RMSTemplate,
  };
  return commonApiRequest("POST", url, body);
};

export const ClientMasterBulkuploadApi = async (selectedFile) => {
  const formData = new FormData();
  formData.append("newClientFile", selectedFile);

  const url = `${apiUrl}/V1/addRetailCLientMasterBulkUpload`;
  return commonApiRequest("POST", url, formData);
};

export const deleteClientMasterApi = async (rowdata) => {
  const url = `${apiUrl}/V1/updateOrDeleteRetailCLientMaster`;
  return commonApiRequest("POST", url, rowdata);
};

//Live Monitor
export const getLivemonitorApi = async () => {
  const url = `${process.env.REACT_APP_URL_RTRMS}/v1/get-all-utilisation-data`;
  return commonApiRequest("GET", url);
};

//ManualAllocation
export const getManualAllocationApi = async () => {
  const response = await axios.get(`${apiUrl}/V1/GetManualAllocation`, {
    headers: {
      accept: "application/json",
    },
  });
  return response;
};

//CLient Master TradeCirecle
export const getClientMasterTradeCirecleApi = async () => {
  const response = await axios.get(`${apiUrl}/V1/getTradeCircleMaster`, {
    headers: {
      accept: "application/json",
    },
  });
  return response;
};

export const addClientTradeMasterApi = async (values) => {
  const url = `${apiUrl}/V1/addtradecircleCLientMaster`;
  const body = {
    client: values.client,
    Branch: values.Branch,
    IsDiscount: values.IsDiscount,
    intraday: values.intraday,
    IsIncludeAutoSquare: values.IsIncludeAutoSquare,
    IsIncludeFurtherExpo: values.IsIncludeFurtherExpo,
    ps03_upload: values.ps03_upload,
    RMSTemplate: values.RMSTemplate,
  };
  return commonApiRequest("POST", url, body);
};

export const updateOrDeleteTradeMasterApi = async (rowdata) => {
  const url = `${apiUrl}/V1/updateOrDeleteTradeCircleCLientMaster`;
  return commonApiRequest("POST", url, rowdata);
};

export const tradeCircleMasterBulkUploadApi = async (selectedFile) => {
  const formData = new FormData();
  formData.append("newClientFile", selectedFile);

  const url = `${apiUrl}/V1/addTradeCircleMasterBulkUpload`;
  return commonApiRequest("POST", url, formData);
};

//Logger
export const xtsMobileLoggerApi = async (values) => {
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }

  const url = `${apiUrl}/V1/Upload_Mobile_Logger`;
  return commonApiRequest("POST", url, formData);
};

export const xtsOrderLoggerApi = async (values) => {
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }
  const url = `${apiUrl}/V1/Upload_XTS_Order_Logger`;
  return commonApiRequest("POST", url, formData);
};

export const xtsTradeLoggerApi = async (values) => {
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }

  const url = `${apiUrl}/V1/Upload_XTS_Trade_Logger`;
  return commonApiRequest("POST", url, formData);
};

export const odinOrderLoggerApi = async (values) => {
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }

  const url = `${apiUrl}/V1/Upload_ODIN_Order_Logger`;
  return commonApiRequest("POST", url, formData);
};

export const odinTradeLoggerApi = async (values) => {
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }

  const url = `${apiUrl}/V1/Upload_ODIN_Trade_Logger`;
  return commonApiRequest("POST", url, formData);
};

//User Mapping
export const bankAdditionApi = async (values) => {
  const url = `${apiUrl}/V1/insert_odin_bank_script`;
  const body = {
    bank_name: values.bank_name,
    bank_code: values.bank_code,
  };
  return commonApiRequest("POST", url, body);
};

export const bankDetailsDataApi = async () => {
  const response = await axios.post(`${apiUrl}/V1/fetch_odin_bank_script`, {
    headers: {
      accept: "application/json",
    },
  });
  return response;
};

export const clientCreationApi = async (clientCode) => {
  const url = `${apiUrl}/v1/rms/create-user?client_code=${clientCode}`;
  const body = null;
  return commonApiRequest("POST", url, body);
};

export const xtsClientCreationApi = async (clientCode) => {
  const url = `${apiUrl}/v1/rms/xts-create-user?client_code=${clientCode}`;
  const body = null;
  return commonApiRequest("POST", url, body);
};

export const bulkUploadXTSClientAPi = async (file) => {
  const formData = new FormData();
  formData.append("newClientFile", file);

  const url = `${apiUrl}/V1/makeBulkXtsClient`;
  return commonApiRequest("POST", url, formData);
};

export const bulkOdinClientApi = async (file) => {
  const formData = new FormData();
  formData.append("newClientFile", file);

  const url = `${apiUrl}/V1/makeBulkOdinClient`;
  return commonApiRequest("POST", url, formData);
};

//Script
export const scriptSubmitApi = async (values) => {
  const url = `${apiUrl}/V1/insert_trading_script`;
  return commonApiRequest("POST", url, values);
};

//Dealer Mapping
export const dealerMappingApi = async (values) => {
  const url = `${apiUrl}/V1/odin-dealer-mapping?client_code=${values.client_code}&branch_id=${values.Branch_id}`;
  return commonApiRequest("POST", url);
};

export const dealerMasterApi = async (values) => {
  const url = `${apiUrl}/V1/insert_dealer`;
  const body = {
    Branch_Id: values.branch_id,
    Dealer_Id: values.dealer_id,
  };
  return commonApiRequest("POST", url, body);
};

export const getDealerMasterDataApi = async () => {
  const url = `${apiUrl}/V1/fetch_Delears`;
  return commonApiRequest("POST", url);
};

export const bulkClientMappingApi = async (values) => {
  const url = `${apiUrl}/V1/bulkClientMapping`;
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }

  return commonApiRequest("POST", url, formData);
};

//other
export const rtrmsFilesApi = async (values) => {
  const url = `${apiUrl}/V1/rtrms_file_upload`;
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }
  return commonApiRequest("POST", url, formData);
};

export const uploadFilesApi = async (uploadFiles) => {
  const url = `${apiUrl}/V1/Upload_Commanlimit_Files`;
  const formData = new FormData();

  for (const key in uploadFiles) {
    formData.append(key, uploadFiles[key]);
  }
  return commonApiRequest("POST", url, formData);
};

export const onclickApi = async (values) => {
  const url = `${apiUrl}/V1/Non_Downloadable_Files_Upload`;
  const formData = new FormData();
  for (const key in values) {
    formData.append(key, values[key]);
  }
  return commonApiRequest("POST", url, formData);
};

export const downloadApi = async () => {
  const url = `${apiUrl}/V1/Auto_Download`;
  return commonApiRequest("POST", url);
};

export const getFundeDetailsApi = async (selectedSegment) => {
  const url = `${apiUrl}/V1/GetFunds?exchange=${selectedSegment}`;
  return commonApiRequest("GET", url);
};

export const downloadFileApi = async () => {
  const url = `http://192.168.119.188:3325/V1/get-daily-beni-mapping-data`;
  return commonApiRequest("GET", url);
};

export const beniFileDownloadApi = async () => {
  const url = `${BeniUrl}/V1/get-daily-beni-mapping-data`;
  return commonApiRequest("GET", url, null, null);
};