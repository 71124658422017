/*eslint-disable*/
import React from "react";
import { Navigate } from "react-router";
import { getSessionStorage } from "./encrpytion";

export default function ReportsRequired({ children, report }) {

    // if (getSessionStorage("reports")) {
    //     const isVerificationInList = getSessionStorage("reports")
    //         ?.split(",")
    //         .includes(report)

    //     if (!isVerificationInList) {
    //         return <Navigate to={"/error404"} />;
    //     }
    // } else {
    //     return <Navigate to={"/error404"} />
    // }

    return children;
}
