import React from "react";
import Dashboard from "./inner-components/index";

export default function index() {
  return (
    <div>
      <Dashboard />
    </div>
  );
}
