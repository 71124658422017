/*eslint-disable*/
import { commonApiRequest } from "utils/CommonFunctions";

const baseURL = process.env.REACT_APP_URL_BLOG;

export const fetchBlog = () => {
  const url = `${baseURL}/arham_web/v1/fetch-blogs`;
  return commonApiRequest("GET", url);
};

export const handleBlogAdd = (formData) => {
  const url = `${baseURL}/arham_web/v1/upload-blogs`;
  return commonApiRequest("POST", url, formData);
};

export const handleBlogUpdate = (formData) => {
  const url = `${baseURL}/arham_web/v1/update-blogs`;
  return commonApiRequest("PUT", url, formData);
};

export const handleBlogDelete = (params) => {
  const url = `${baseURL}/arham_web/v1/delete-blogs`;
  return commonApiRequest("DELETE", url,null, params);
};


