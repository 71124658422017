import { lazy } from "react";
import Loadable from "ui-component/Loadable";
// import AlgoSummaryDash from "views/utilities/algo-summary/dashboard";
import AlgoSummaryHistoryDash from "views/utilities/algo-summary/dashboard/historyDashboard";
import AlgoSummaryIntradadyDash from "views/utilities/algo-summary/dashboard/IntradayDashboard";
import AlgoSummaryUtils from "views/utilities/algo-summary/utils";
import AlgoSummaryReports from "views/utilities/algo-summary/reports";
import FeaturesRequired from "utils/FeaturesRequired";

const AlgoSummaryHome = Loadable(
  lazy(() => import("views/utilities/algo-summary"))
);

const AddManager = Loadable(
  lazy(() => import("views/utilities/algo-summary/utils/add-manager"))
);

const CtclMapping = Loadable(
  lazy(() => import("views/utilities/algo-summary/utils/ctcl-mapping"))
);

const AddBrokarage = Loadable(
  lazy(() => import("views/utilities/algo-summary/utils/add-brokarage"))
);

const AddFund = Loadable(
  lazy(() => import("views/utilities/algo-summary/utils/add-fund/index"))
);

const AddClient = Loadable(
  lazy(() => import("views/utilities/algo-summary/utils/add-client/index"))
);

const AlgoSummaryEod = Loadable(
  lazy(() => import("views/utilities/algo-summary/utils/eod/index"))
);

const MonthlySummaryReport = Loadable(
  lazy(() =>
    import("views/utilities/algo-summary/reports/monthly-summary/index")
  )
);

const DayWiseMtmReport = Loadable(
  lazy(() => import("views/utilities/algo-summary/reports/daywise-mtm/index"))
);

const PerformanceReport = Loadable(
  lazy(() =>
    import("views/utilities/algo-summary/reports/performance-chart/index")
  )
);

//############################## feature auth Check ##############################//

const featureCheck = (component, feature) => (
  <FeaturesRequired feature={feature}>{component}</FeaturesRequired>
);

export const algoSummaryRoutes = {
  path: "algo-summary",
  element: <AlgoSummaryHome />,
  children: [
    {
      path: "intradayDashboardalgo",
      element: <AlgoSummaryIntradadyDash />,
    },
    {
      path: "historyDashboardalgo",
      element: <AlgoSummaryHistoryDash />,
    },
    {
      path: "utils",
      element: <AlgoSummaryUtils />,
    },
    {
      path: "reports",
      element: <AlgoSummaryReports />,
    },
  ],
};

export const AlgoSummaryUtilsRoutes = {
  path: "algo-summary",
  children: [
    {
      path: "add-manager",
      element: featureCheck(<AddManager />, "add_manager"),
    },
    {
      path: "ctcl-mapping",
      element: featureCheck(<CtclMapping />, "ctcl_mapping"),
    },
    {
      path: "add-brokarage",
      element: featureCheck(<AddBrokarage />, "add_brokarage"),
    },
    {
      path: "add-fund",
      element: featureCheck(<AddFund />, "add_fund"),
    },
    {
      path: "add-client",
      element: featureCheck(<AddClient />, "add_client"),
    },
    {
      path: "eod",
      element: featureCheck(<AlgoSummaryEod />, "eod"),
    },
    {
      path: "monthly-summary",
      element: <MonthlySummaryReport />,
    },
    {
      path: "daywise-mtm",
      element: <DayWiseMtmReport />,
    },
    {
      path: "performance-chart",
      element: <PerformanceReport />,
    },
  ],
};
