/*eslint-disable*/
import React, { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import Config from "../../views/dashboard/director-dashboard/tab-components/revenue/config";

export const MasterDashBoardContext = createContext();

export default function MasterDashBoardApi(props) {
  const [currentLayoutTab, setCurrentLayoutTab] = useState("revenue");
  const [selectTablePeriod, setSelectTablePeriod] = useState({
    areabranch: "current_month",
    branch: "current_month",
    top25client: "current_month",
    b2b: "current_month"
  });
  const [selectBranch, setSelectBranch] = useState("region_branch");

  const [selectedBranchData, setSelectedBranchData] = useState([]);
  const [selectedB2BData, setSelectedB2BData] = useState([]);
  const [top25ClientData, setTop25ClientData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedBrokerageData, setSelectedBrokerageData] = useState([]);
  const [brokerageSegmentData, setBrokerageSegmentData] = useState([]);
  const [selectedTurnoverData, setSelectedTurnoverData] = useState([]);
  const [selectedMobileLoginData, setSelectedMobileLoginData] = useState([]);
  const [selectedOrderPunchData, setSelectedOrderPunchData] = useState([]);
  const [selectedTradedClientData, setSelectedTradedClientData] = useState([]);

  const [isBrkSegmentLoading, setIsBrkSegmentLoading] = useState(false);

  const [selectedKycData, setSelectedKycData] = useState([]);
  const [selectedAllKycData, setSelectedAllKycData] = useState([]);

  const [timeValue, setTimeValue] = useState("month");
  const [selectedPeriodvalue, setSelectedPeriodvalue] = useState({
    brokerage: "month",
    traded_client: "month",
    mobile_logger: "month",
    turnover: "month",
    order_punch: "month",
    kyc: "month"
  });
  
  const [isCardsLoading, setIsCardsLoading] = useState({
    brokerage: false,
    traded_client: false,
    mobile_logger: false,
    turnover: false,
    order_punch: false,
    kyc: false,
    all_kyc_data: false
  });

  const [isBrkDialogOpen, setIsBrkDialogOpen] = useState(false);
  // const [selectedAreaBranchData, setSelectedAreaBranchData] = useState([]);

  const [isChartOpen, setIsChartOpen] = useState(false);
  const [currentChartTag, setCurrentChartTag] = useState("");
  const [chartDate, setChartDate] = useState([]);
  const [chartData, setChartData] = useState([]);

  const { monthList } = Config;

  const formatValue = (value) => {
    if (value == null || isNaN(value)) {
      return 0;
    }
    if (value >= 1.0e7) {
      return (value / 1.0e7).toFixed(2) + "Cr"; // Crores
    } else if (value >= 1.0e5) {
      return (value / 1.0e5).toFixed(2) + "L"; // Lakhs
    } else if (value >= 1.0e3) {
      return (value / 1.0e3).toFixed(2) + "K"; // Thousands
    } else {
      return value.toFixed(2); // Less than thousand
    }
  };

  const getMonthNumber = () => {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    return "0" + month + year;
  };

  const getFinancialDate = (date, callback) => {
    let yesterday = new Date(date);
    yesterday.setDate(date.getDate() - 1);
    let formattedYesterday = yesterday.toISOString().split("T")[0];

    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let currentMonthName = monthNames[date.getMonth()];

    let currentYear = date.getFullYear();
    let financialYearStartMonth = 3; // April (0-indexed)
    let financialYear =
      date.getMonth() >= financialYearStartMonth
        ? `${currentYear}-${currentYear + 1}`
        : `${currentYear - 1}-${currentYear}`;

    callback({
      yesterday: formattedYesterday,
      currentMonth: currentMonthName,
      year: financialYear
    });
  };

  const getCurrentDates = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    // const yesterday = String(currentDate.getDate() - 1).padStart(2, "0");
    const today = String(currentDate.getDate()).padStart(2, "0");

    const financialYearStartMonth = 4;

    const year =
      currentMonth < financialYearStartMonth
        ? `${currentYear - 1}-${currentYear.toString()}`
        : `${currentYear}-${(currentYear + 1).toString()}`;
    const month = currentYear + currentMonth;
    const month2 = currentMonth + currentYear;
    const day = currentYear + currentMonth + today;

    return { year, month, day, month2 };
  };

  const getDateList = useMemo(() => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // Months are zero-based
    const currentYear = today.getFullYear();
    const dateList = [];

    for (let day = 1; day <= today.getDate(); day++) {
      const paddedDay = day.toString().padStart(2, "0");
      const paddedMonth = currentMonth.toString().padStart(2, "0");
      const date = `${currentYear}${paddedMonth}${paddedDay}`;
      dateList.push(date);
    }

    return dateList;
  }, []);

  const getCurrentMonth = () => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    return `${currentYear}${currentMonth.toString().padStart(2, "0")}`;
  };

  const updateDataState = (tag, data) => {
    switch (tag) {
      case "brokerage":
        setSelectedBrokerageData(data?.brokerage[0]);
        break;
      case "turnover":
        setSelectedTurnoverData(data?.turnover[0]);
        break;
      case "mobile_logger":
        setSelectedMobileLoginData(data?.mobile_logger[0]);
        break;
      case "order_punch":
        setSelectedOrderPunchData(data?.order_punch[0]);
        break;
      case "traded_client":
        setSelectedTradedClientData(data?.traded_client[0]);
        break;
      case "kyc":
        setSelectedKycData(data?.kyc[0]);
        break;
      default:
        break;
    }
  };

  const getFinancialYearMonths = useMemo(() => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    const months = [];
    let startYear = currentMonth >= 4 ? currentYear : currentYear - 1;

    for (
      let month = 4, year = startYear;
      !(year === currentYear && month > currentMonth);
      month++
    ) {
      if (month > 12) {
        month = 1;
        year++;
      }
      const monthString = `${year}${month.toString().padStart(2, "0")}`;
      months.push(monthString);
    }

    return months;
  }, []);

  const getFinancialYears = useMemo(() => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
    const financialYears = [];

    // Financial year always starts in April
    const startYear = 2023;
    const currentFinancialYearStart =
      currentMonth >= 4 ? currentYear : currentYear - 1;
    const endYear = currentFinancialYearStart;

    for (let year = startYear; year <= endYear; year++) {
      const nextYear = year + 1;
      const financialYear = `${year}-${nextYear}`;
      financialYears.push(financialYear);
    }

    return financialYears;
  }, []);

  const formatMonth = (monthValue) => {
    if (!monthValue) {
      return;
    }
    const monthIndex = parseInt(monthValue.substring(4, 6)) - 1;
    const year = monthValue.substring(0, 4);
    return `${monthList[monthIndex]} ${year} `;
  };

  const formatMonth2 = (monthValue) => {
    if (!monthValue) {
      return;
    }
    const monthIndex = parseInt(monthValue.substring(0, 2)) - 1;
    const year = monthValue.substring(2, 6);
    return `${monthList[monthIndex]} ${year} `;
  };

  const formatDate = (date) => {
    if (!date) {
      return;
    }
    return `${date.substring(0, 4)}/${date.substring(4, 6)}/${date.substring(
      6,
      8
    )}`;
  };

  const formatDateChartWise = (date) => {
    const dateStr = String(date);

    const year = dateStr.slice(0, 4);
    const month = dateStr.slice(4, 6);
    const day = dateStr.slice(6, 8);
    const newDate = new Date(`${year}-${month}-${day}`);
    return newDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short"
    });
  };

  const currentMonth = getCurrentMonth();

  const [dialogParams, setdialogParams] = useState({
    period: "month",
    value: currentMonth,
    formattedPeriod: formatMonth(currentMonth)
  });

  const handleBrkDialogOpen = () => {
    fetchBrokerageSegmentWiseData(dialogParams.period, dialogParams.value);
    setIsBrkDialogOpen(true);
  };

  const handleBrkDialogClose = () => {
    setIsBrkDialogOpen(false);
  };

  const handleChartOpen = (tag) => {
    if (tag === "turnover") {
      setCurrentChartTag("Turnover");
      handleFetchTunOver(dialogParams.period, dialogParams.value);
    } else if (tag === "mobile_logger") {
      setCurrentChartTag("Mobile Logger");
      handleFetchLoggerChartData();
    } else if (tag === "kyc") {
      setCurrentChartTag("Kyc");
      handleFetchKycChartData(dialogParams.period, dialogParams.value);
    }
    setIsChartOpen(true);
  };

  const handleChartClose = () => {
    setIsChartOpen(false);
  };

  console.log('process.env.REACT_APP_URL_MASTER_DASHBOARD', process.env.REACT_APP_URL_MASTER_DASHBOARD)

  const fetchBrokerageSegmentWiseData = async (period, tdate) => {
    setBrokerageSegmentData([]);
    setIsBrkSegmentLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_MASTER_DASHBOARD}/v1/fetch-super-user-dashboard-revenue-data?type=segment_wise_brokerage&period=${period}&t_date=${tdate}`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.authToken
          }
        }
      );
      const resJson = await response.json();
      if (resJson.status === "success") {
        setBrokerageSegmentData(resJson.data?.segment_wise_brokerage[0]);
      } else {
        toast.error(resJson.message || "Failed to fetch data");
      }
    } catch (error) {
      console.log("error", error);
      // toast.error(error.message);
    } finally {
      setIsBrkSegmentLoading(false);
    }
  };

  const fetchSuperUserdashboardRevenueData = async (
    type = "",
    period,
    tdate
  ) => {
    if (type) {
      setIsCardsLoading((prevState) => ({ ...prevState, [type]: true }));
    } else {
      setIsCardsLoading((prevState) =>
        Object.fromEntries(Object.keys(prevState).map((key) => [key, true]))
      );
    }

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_URL_MASTER_DASHBOARD
        }/v1/fetch-super-user-dashboard-revenue-data?${
          type ? `type=${type}&` : ""
        }period=${period}&t_date=${tdate}`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.authToken
          }
        }
      );
      const resJson = await response.json();
      if (resJson.status === "success") {
        if (type) {
          updateDataState(type, resJson.data);
        } else {
          setSelectedBrokerageData(resJson.data?.brokerage[0]);
          setSelectedTurnoverData(resJson.data?.turnover[0]);
          setSelectedMobileLoginData(resJson.data?.mobile_logger[0]);
          setSelectedTradedClientData(resJson.data?.traded_client[0]);
        }
      } else {
        toast.error(resJson.message || "Failed to fetch data");
      }
    } catch (error) {
      console.log("error", error);
      // toast.error(error.message);
    } finally {
      setIsCardsLoading((prevState) =>
        Object.fromEntries(Object.keys(prevState).map((key) => [key, false]))
      );
    }
  };

  const fetchSuperUserdashboardKycData = async (type = "", period, tdate) => {
    if (type) {
      setIsCardsLoading((prevState) => ({ ...prevState, [type]: true }));
    } else {
      setIsCardsLoading((prevState) =>
        Object.fromEntries(Object.keys(prevState).map((key) => [key, true]))
      );
    }

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_URL_MASTER_DASHBOARD
        }/v1/fetch-super-user-dashboard-kyc-data?${
          type ? `type=${type}&` : ""
        }period=${period}&t_date=${tdate}`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.authToken
          }
        }
      );
      const resJson = await response.json();
      if (resJson.status === "success") {
        if (type) {
          updateDataState(type, resJson.data);
        } else {
          setSelectedKycData(resJson.data?.kyc[0]);
          setSelectedAllKycData(resJson.data?.all_kyc_data[0]);
        }
      } else {
        toast.error(resJson.message || "Failed to fetch data");
      }
    } catch (error) {
      console.log("error", error);
      // toast.error(error.message);
    } finally {
      if (type) {
        setIsCardsLoading((prevState) => ({ ...prevState, [type]: false }));
      } else {
        setIsCardsLoading((prevState) =>
          Object.fromEntries(Object.keys(prevState).map((key) => [key, false]))
        );
      }
    }
  };

  const fetchBranchWiseRevenue = async (branchName, period) => {
    setSelectedBranchData([]);
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_MASTER_DASHBOARD}/v1/branch-revenue-summary?branch=${branchName}&period=${period}`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.authToken
          }
        }
      );
      const resJson = await response.json();
      if (resJson.status === "success") {
        // {
        //   branchName === "area_branch"
        //     ? setSelectedAreaBranchData(resJson.data)
        //     : setSelectedBranchData(resJson.data);
        // }
        setSelectedBranchData(resJson.data);
      } else {
        toast.error(resJson.message || "Failed to fetch data");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTop25ClientRevenue = async (period) => {
    setTop25ClientData([]);
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_MASTER_DASHBOARD}/v1/top25RevenueReport?period=${period}`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.authToken
          }
        }
      );

      const resJson = await response.json();
      if (resJson.status === "success") {
        console.log("Data:", resJson.data);
        setTop25ClientData(resJson.data);
      } else {
        // toast.error(resJson.message || "Failed to fetch data");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchRevenueB2BWise = async (period, trade_month = "") => {
    setSelectedB2BData([]);
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_CRMS}/v1/revenue-b2b?period=${period}&t_date=${trade_month}`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken,
            accept: "application/json"
          }
        }
      );

      const resJson = await response.json();

      if (resJson.status == "success") {
        const revenueB2BData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1
        }));
        setSelectedB2BData(revenueB2BData);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchTunOver = async (period, tDate) => {
    setChartDate([]);
    setChartData([]);
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_MASTER_DASHBOARD}/v1/fetch-super-user-turnover-chart-data?period=${period}&t_date=${tDate}`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.authToken,
            accept: "application/json"
          }
        }
      );

      const resJson = await response.json();
      if (resJson.status === "success") {
        const tDate = [];
        const tunOverCount = [];

        resJson.data.forEach((item) => {
          let formattedDate = formatDateChartWise(item.trade_date);

          tDate.push(formattedDate);
          tunOverCount.push(item.trunover);
        });

        setChartDate(tDate);
        setChartData(tunOverCount);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchLoggerChartData = async () => {
    setChartDate([]);
    setChartData([]);
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_MASTER_DASHBOARD}/v1/logger-chart-data`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.authToken,
            accept: "application/json"
          }
        }
      );

      const resJson = await response.json();
      if (resJson.status === "success") {
        const tDate = [];
        const loggerCount = [];

        resJson.data.forEach((item) => {
          let formattedDate = formatDateChartWise(item.Logged_date);

          tDate.push(formattedDate);
          loggerCount.push(item.total_login);
        });

        setChartDate(tDate);
        setChartData(loggerCount);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchKycChartData = async (period, tDate) => {
    setChartDate([]);
    setChartData([]);
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_MASTER_DASHBOARD}/v1/fetch-super-user-kyc-chart-data?period=${period}&t_date=${tDate}`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.authToken,
            accept: "application/json"
          }
        }
      );

      const resJson = await response.json();
      if (resJson.status === "success") {
        const tDate = [];
        const kycCount = [];

        resJson.data.forEach((item) => {
          let formattedDate = formatDateChartWise(item.registration_date);

          tDate.push(formattedDate);
          kycCount.push(item.kyc);
        });

        setChartDate(tDate);
        setChartData(kycCount);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBranchWiseKyc = async (branchName, period) => {
    setSelectedBranchData([]);
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_MASTER_DASHBOARD}/v1/branch-wise-kyc-summary?branch=${branchName}&period=${period}`,
        {
          method: "GET",
          headers: {
            "auth-token": localStorage.authToken
          }
        }
      );
      const resJson = await response.json();
      if (resJson.status === "success") {
        setSelectedBranchData(resJson.data);
      } else {
        toast.error(resJson.message || "Failed to fetch data");
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchKycB2BWise = async (period, trade_month = "") => {
    setSelectedB2BData([]);
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_CRMS}/v1/kyc-b2b?period=${period}&t_date=${trade_month}`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken,
            accept: "application/json"
          }
        }
      );

      const resJson = await response.json();

      if (resJson.status == "success") {
        const revenueB2BData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1
        }));
        setSelectedB2BData(revenueB2BData);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MasterDashBoardContext.Provider
      value={{
        currentLayoutTab,
        setCurrentLayoutTab,

        handleFetchTunOver,
        handleFetchLoggerChartData,
        chartDate,
        chartData,
        getFinancialDate,
        fetchBranchWiseRevenue,
        selectedBranchData,
        // selectedAreaBranchData,
        selectTablePeriod,
        setSelectTablePeriod,
        fetchTop25ClientRevenue,
        isLoading,
        top25ClientData,
        getMonthNumber,
        timeValue,
        setTimeValue,
        getDateList,
        getFinancialYearMonths,
        getFinancialYears,
        getCurrentMonth,
        fetchSuperUserdashboardRevenueData,

        selectBranch,
        setSelectBranch,

        handleFetchRevenueB2BWise,
        selectedB2BData,

        selectedBrokerageData,
        selectedTurnoverData,
        selectedMobileLoginData,
        selectedTradedClientData,

        getCurrentDates,
        selectedPeriodvalue,
        setSelectedPeriodvalue,
        isCardsLoading,

        isBrkDialogOpen,
        handleBrkDialogOpen,
        handleBrkDialogClose,

        brokerageSegmentData,
        isBrkSegmentLoading,
        dialogParams,
        setdialogParams,

        formatDate,
        formatMonth,
        formatMonth2,

        handleChartClose,
        handleChartOpen,
        isChartOpen,
        currentChartTag,

        formatValue,

        fetchSuperUserdashboardKycData,
        selectedKycData,
        selectedAllKycData,

        fetchBranchWiseKyc,
        handleFetchKycB2BWise
      }}
    >
      {props.children}
    </MasterDashBoardContext.Provider>
  );
}
