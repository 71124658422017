/*eslint-disable*/
import React, { useState, createContext } from "react";
import { toast } from "react-toastify";

import { addNewAppApi, addNewFeatureApi, addNewReportApi, fetchAppInfoApi, fetchDepartmentListApi, fetchFeatureInfoApi, fetchReportsApi, updateAppInfoApi, updateFeatureInfoApi, updateReportInfoApi } from "services/app-management/appManagement";
import { handleCatch } from "utils/CommonFunctions";

export const AppManagementContext = createContext();

export default function AppManagementApis(props) {

  const [appData, setAppData] = useState([]);
  const [featureData, setFeatureData] = useState([]);
  const [reports, setReports] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  //GET - app list
  const fetchAppInfo = async () => {
    try {
      const response = await fetchAppInfoApi();
      setAppData(
        response?.data?.data.map((item) => ({ ...item, id: item.app_id }))
      );
    } catch (error) {
      handleCatch(error);
    }
  };

  //GET - feature list by App Id
  const fetchFeatureInfo = async (app_id) => {
    try {
      const response = await fetchFeatureInfoApi(app_id);
      setFeatureData(response.data.data.filter((item) => {
        return item.tag === "feature"
      }));
    } catch (error) {
      handleCatch(error)
    }
  };

  //GET - report list by App Id
  const fetchReports = async (app_id) => {
    try {
      const response = await fetchReportsApi(app_id);

      setReports(response.data.data.filter((item) => {
        return item.tag === "report"
      }));
      fetchFeatureInfo(app_id)
    } catch (error) {
      handleCatch(error)
    }
  };

  // GET - department list
  const fetchDepartmentList = async () => {
    try {
      const response = await fetchDepartmentListApi();
      setDepartmentList(response.data.data)
    } catch (error) {
      handleCatch(error)
    }
  };

  //POST - Add New App
  const addNewApp = async (app_name, department_id) => {
    const body = {
      "app_name": app_name,
      "department_id": department_id
    };
    try {
      if (app_name) {
        const response = await addNewAppApi(body);

        toast.success(response?.data?.message);
      } else {
        toast.error("Invalid Input !!!");
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  //POST - Add New Feature
  const addNewFeature = async (feature_name, app_id, description) => {
    const body = {
      "feature_name": feature_name,
      "app_id": app_id,
      "department_id": description
    };
    try {
      if (feature_name && app_id) {
        const response = await addNewFeatureApi(body);
        toast.success(response?.data?.message);
        fetchFeatureInfo(app_id)
      } else {
        toast.error("Invalid Input !!!");
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  //POST - Add New Report
  const addNewReport = async (report_name, app_id, description) => {
    const body = {
      "report_name": report_name,
      "app_id": app_id,
      "department_id": description
    };
    try {
      if (report_name && app_id) {
        const response = await addNewReportApi(body);
        toast.success(response.data.message);
        fetchReports(app_id);
      } else {
        toast.error("Invalid Input !!!");
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  // PUT - update feature info
  const updateFeatureInfo = async (payload) => {
    const body = {
      "feature_id": payload.feature_id,
      "feature_name": payload.feature_name,
      "app_id": payload.app_id?.toString(),
      "description": payload.description
    };
    try {
      if (payload.feature_name && payload.app_id) {
        const response = await updateFeatureInfoApi(body);
        toast.success(response.data.message);
        fetchFeatureInfo(payload.oldAppId)
      } else {
        toast.error("Invalid Input !!!");
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  // PUT - update reports info
  const updateReportInfo = async (payload) => {
    const body = {
      "report_id": payload.report_id,
      "report_name": payload.report_name,
      "app_id": payload.app_id.toString(),
      "description": payload.description
    };
    try {
      if (payload.report_name && payload.app_id) {
        const response = await updateReportInfoApi(body);
        toast.success(response.data.message);
        fetchReports(payload.oldAppId);
      } else {
        toast.error("Invalid Input !!!");
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  // PUT - update app info
  const updateAppInfo = async (payload) => {
    const body = {
      "app_id": payload.app_id.toString(),
      "app_name": payload.app_name,
      "department_id": payload.department_id
    };
    try {
      if (payload.app_id && payload.department_id && payload.app_name) {
        const response = await updateAppInfoApi(body);
        toast.success(response.data.message);
        fetchAppInfo()
      } else {
        toast.error("Invalid Input !!!");
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  return (
    <AppManagementContext.Provider
      value={{ appData, fetchAppInfo, featureData, setFeatureData, fetchFeatureInfo, fetchReports, reports, setReports, fetchDepartmentList, departmentList, addNewApp, addNewFeature, addNewReport, updateFeatureInfo, updateReportInfo, updateAppInfo }}
    >
      {props.children}
    </AppManagementContext.Provider>
  );
}
