import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const EDisHome = Loadable(lazy(() => import("views/utilities/edis/dashboard")));
const EDisIndex = Loadable(lazy(() => import("views/utilities/edis/index")));

const EDisUtilities = Loadable(
  lazy(() => import("views/utilities/edis/utils"))
);
const EDisReports = Loadable(
  lazy(() => import("views/utilities/edis/reports/index"))
);
const StockVerification = Loadable(
  lazy(() => import("views/utilities/edis/utils/stockVerification"))
);
const Settlment = Loadable(
  lazy(() => import("views/utilities/edis/utils/settlment"))
);
const FileDownload = Loadable(
  lazy(() => import("views/utilities/edis/reports/file-download"))
);
// const Mis = Loadable(
//   lazy(() => import("views/utilities/lms/reports/mis/index"))
// );

export const edisModule = {
  path: "edis",
  element: <EDisIndex />,
  children: [
    {
      path: "home",
      element: <EDisHome />
    },
    {
      path: "utils",
      element: <EDisUtilities />
    },

    {
      path: "reports",
      element: <EDisReports />
    }
  ]
};

export const EDisUtilsRoutes = {
  path: "edis",
  children: [
    {
      path: "stock-verification",
      element: <StockVerification />
    },
    {
      path: "settlment",
      element: <Settlment />
    },
    {
      path: "file-download",
      element: <FileDownload />
    }
  ]
};

// export const LmsReportsRoutes = {
//   path: "lms",
//   children: [
//     {
//       path: "mis-report",
//       element: <Mis />,
//     },
//   ],
// };
