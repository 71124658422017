/*eslint-disable*/
import React from "react";

import { Box, Grid } from "@mui/material";

// import folderIcon from "assets/images/file icons/folder-48.png";
import folderIcon from "assets/images/icons/folder_11538209.png";

// import featureIcon from "assets/images/file icons/app-48.png";
import featureIcon from "assets/images/icons/purplefaeture.png";

import { getSessionStorage } from "utils/encrpytion";
import subFeatureExist from "utils/subFeatureCheck";
import FileCard from "./FileCard";
import FolderCard from "./FolderCard";

const CommonUtilsIndex = ({ allFeatures }) => {
  const access = getSessionStorage("features")?.split(",") || [];
  return (
    <>
      <Box sx={{ background: "white", minHeight: "70vh", p: 3 }}>
        <Grid
          container
          sx={{ display: "flex", flexWrap: "wrap" }}
          spacing={2}
          rowSpacing={2}
        >
          {allFeatures?.map((item) => {
            if (
              item.type == "file" &&
              access.includes(item.feature_id.toString())
            ) {
              return (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100px",
                    pl: 0,
                  }}
                >
                  <FileCard card={item} icon={featureIcon} />
                </Grid>
              );
            } else if (subFeatureExist(item.subfolder, access, "feature")) {
              return (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100px",
                    pl: 0,
                  }}
                >
                  <FolderCard card={item} icon={folderIcon} />
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    </>
  );
};

export default CommonUtilsIndex;
