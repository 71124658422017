/*eslint-disable*/
import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import FeaturesRequired from "utils/FeaturesRequired";
import TotalExpenseReport from "views/utilities/ria-wms/reports";

const RiaHome = Loadable(
  lazy(() => import("views/utilities/ria-wms/dashboard"))
);
const RiaIndex = Loadable(lazy(() => import("views/utilities/ria-wms/index")));

const RiaUtilities = Loadable(
  lazy(() => import("views/utilities/ria-wms/utils"))
);

const AddClient = Loadable(
  lazy(() => import("views/utilities/ria-wms/utils/add-client"))
);

const AddProduct = Loadable(
  lazy(() => import("views/utilities/ria-wms/utils/add-product"))
);

const UploadFile = Loadable(
  lazy(() => import("views/utilities/ria-wms/utils/upload-file"))
);

const FundAllocation = Loadable(
  lazy(() => import("views/utilities/ria-wms/utils/fund-allocation"))
);

const AdvisoryFees = Loadable(
  lazy(() => import("views/utilities/ria-wms/utils/advisory-fees"))
);

const AddRia = Loadable(
  lazy(() => import("views/utilities/ria-wms/utils/add-ria"))
);

const ClientMapping = Loadable(
  lazy(() => import("views/utilities/ria-wms/utils/client-mapping"))
);

const featureCheck = (component, feature) => (
  <FeaturesRequired feature={feature}>{component}</FeaturesRequired>
);

export const RiaModule = {
  path: "ria-wms",
  element: <RiaIndex />,
  children: [
    {
      path: "home",
      element: <RiaHome />,
    },
    {
      path: "utils",
      element: <RiaUtilities />,
    },

    {
      path: "reports",
      element: <TotalExpenseReport />,
    },
  ],
};

export const RiaUtilsRoutes = {
  path: "ria-wms",
  children: [
    {
      path: "add-client",
      element: <AddClient />,
      // element: featureCheck(<AddClient />, "add_client"),
    },
    {
      path: "add-product",
      element: <AddProduct />,
      // element: featureCheck(<AddManager />, "add_manager"),
    },
    {
      path: "fund-allocation",
      element: <FundAllocation />,
      // element: featureCheck(<FundAllocation />, "fund_allocation"),
    },
    {
      path: "upload-file",
      element: <UploadFile />,
      // element: featureCheck(<UploadFile />, "upload_file"),
    },
    {
      path: "advisory-fees",
      element: <AdvisoryFees />,
      // element: featureCheck(<AdvisoryFees />, "advisory_fees"),
    },
    {
      path: "add-ria",
      element: <AddRia />,
      // element: featureCheck(<AdvisoryFees />, "advisory_fees"),
    },
    {
      path: "client-mapping",
      element: <ClientMapping />,
      // element: featureCheck(<AdvisoryFees />, "advisory_fees"),
    },
  ],
};

export const RiaReportsRoutes = {
  path: "ria-wms",
  children: [],
};
