import React from "react";
import { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
// import { Logger } from "sass";

export const NetworkMonitorContext = createContext();

export default function NetworkMonitor(props) {
  const [networkData, setNetworkData] = useState([]);
  const [smsStatus, setSmsStatus] = useState();
  //API Call
  const handleFetchData = async (param) => {
    console.log("param", param);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NETWORK_MONITOR}/v1/admin/network-status-detail`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken,
          },
        }
      );

      console.log("response", response);
      console.log(localStorage.authToken);

      const resJson = await response.json();
      console.log("resJson", resJson);

      if (resJson.status == "success") {
        // toast.success(resJson.message);
        setNetworkData(resJson.data.network_data);
        setSmsStatus(resJson.data.sms_status);
        console.log("SUccess");
      } else {
        toast.error(resJson.message);

        console.log("Not Success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchSmsData = async (param) => {
    console.log("param", param);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NETWORK_MONITOR}/v1/admin/update-sms-status?status=${param}`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken,
          },
        }
      );

      console.log("response", response);

      const resJson = await response.json();
      console.log("resJson", resJson);

      if (resJson.status == "success") {
        toast(resJson.message.toUpperCase());
        console.log("SUccess");
      } else {
        console.log("Not Success");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <NetworkMonitorContext.Provider
      value={{
        handleFetchData,
        networkData,
        setSmsStatus,
        smsStatus,
        handleFetchSmsData,
      }}
    >
      {props.children}
    </NetworkMonitorContext.Provider>
  );
}
