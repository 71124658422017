/*eslint-disable*/
import React, { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import {
  addTicketDetailsApi,
  dorpDownApi,
  getAllRequestApi,
  getRequestApi,
  requestActionApi,
  requestAddUpdateApi,
  requestDeleteApi,
  TMDashboardApi,
} from "services/travel-management/travelService";
import { handleCatch } from "utils/CommonFunctions";

export const TravelContext = createContext();

export default function TravelContextProvider({ children }) {
  const [data, setData] = useState({});
  const [dashboarddata, setDashboardData] = useState({});
  // const [open, setOpen] = useState(false);
  const [RequestData, setRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [ApprovalData, setApprovalData] = useState([]);
  const [openPatty, setOpenPatty] = useState(false);
  const [ApprovalStatus, setApprovalStatus] = useState("fetchall");
  const [dropDownList, setDropDownList] = useState({});

  const handleAddRequest = async (values, setOpen) => {
    try {
      const formdata = new FormData();
      for (const key in values) {
        formdata.append(key, values[key]);
      }
      const response = await requestAddUpdateApi(formdata, data);

      if (response.status == 200) {
        if (response.data.status === "error") {
          alert(response.data.message);
        } else {
          data.request_id
            ? toast.success("Request Updated Successfully!")
            : toast.success("Request Add Successfully!");
        }
        setOpenPatty(true);
        setData({});
        FetchRequestData();
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
    } finally {
      setOpen(false);
    }
  };

  const handleDeleteRequest = async (Req_ID) => {
    if (confirm("Are You Want to Delete Request !")) {
      try {
        const response = await requestDeleteApi(Req_ID);

        if (response.status == 200) {
          toast.success("Request Delete Successfully!");
          FetchRequestData();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const FetchRequestData = async () => {
    try {
      const response = await getRequestApi();

      if (response.status == 200) {
        setRequestData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchAllRequestData = async () => {
    setIsLoading(true);
    setApprovalData([]);
    try {
      const response = await getAllRequestApi(ApprovalStatus);

      if (response.status == 200) {
        setApprovalData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestAction = async (requestId, action, reason) => {
    try {
      const body = { request_id: requestId, reason: reason };
      const response = await requestActionApi(action, body);

      if (response.status == 200) {
        action === "approved"
          ? toast.success("Request Approved Successfully!")
          : toast.success("Request Rejected Successfully!");
        FetchAllRequestData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTicketDetails = async (requestId, values, setOpenDialog) => {
    try {
      const formdata = new FormData();
      formdata.append("request_id", requestId);
      for (const key in values) {
        formdata.append(key, values[key]);
      }
      const response = await addTicketDetailsApi(formdata);

      if (response.status == 200) {
        toast.success("Ticket Details Add Successfully!");
        setOpenDialog(false);
        FetchAllRequestData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTMDashboard = async (value) => {
    try {
      const response = await TMDashboardApi(value);

      if (response.status == 200) {
        setDashboardData(response.data?.data[0]);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDropDownList = async () => {
    try {
      const response = await dorpDownApi();

      if (response.status == 200) {
        setDropDownList(response.data.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  return (
    <TravelContext.Provider
      value={{
        data,
        // open,
        setData,
        // setOpen,
        isError,
        isLoading,
        openPatty,
        setOpenPatty,
        RequestData,
        ApprovalData,
        dashboarddata,
        ApprovalStatus,
        setApprovalData,
        handleAddRequest,
        FetchRequestData,
        setApprovalStatus,
        handleTMDashboard,
        FetchAllRequestData,
        handleRequestAction,
        handleDeleteRequest,
        handleTicketDetails,
        fetchDropDownList,
        dropDownList,
      }}
    >
      {children}
    </TravelContext.Provider>
  );
}
