/*eslint-disable*/
import axios from "axios";
import { AccountSetting } from "context/AccountSetting/AccountSettingApis";
import { useHandleCatch } from "hooks/useHandleCatch";
import React, { useContext, useState } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  composeMailApi,
  crudGroupp,
  deleteMailApi,
  draftMailApi,
  fetchGroupList,
  ForwardMailApi,
  getMailBodyApi,
  getMailDataApi,
  getMailSearchDataApi,
  handleBookmarkMailApi,
  MailDetailsApi,
  restoreMailApi,
} from "services/e-mail/EMailService";

export const EmailContext = createContext();

export default function EmailContextProvider({ children }) {
  const [mails, setMails] = useState([]);
  const [mailData, setMailData] = useState({});
  const [mailsDetails, setMailsDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [composeMail, setComposeMail] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [labels, setLabels] = useState("inbox");
  const [globalFilter, setGlobaleFilter] = useState("");
  const [htmlBody, setHtmlBody] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [crudGroup, setCrudGroup] = useState(false)
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const { setValue } = useContext(AccountSetting);
  const navigate = useNavigate();
  const handleCatch = useHandleCatch()
  const handleEmailData = async () => {
    setIsLoading(true);
    try {
      const response = await getMailDataApi(labels, pagination);
      if (response.status == 200) {
        setMails(response.data.data.emails);
        setRowCount(response.data.data.total_emails);
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    } catch (err) {
      if (err?.response?.data?.message === "Invalid Credential") {
        navigate("/account-settings");
        toast.info("Please add your credentials to access your email.");
        setValue("email_setting");
      } else {
        toast.error(
          "response" in err
            ? err.response.data.message
            : "Internal Server Error, Try Again Later"
        );
      }
      setIsLoading(false);
    }
  };

  const handleComposeMail = async (values, files) => {
    setIsSubmiting(true);
    try {
      const formdata = new FormData();
      formdata.append("to_email", values.to_email);
      formdata.append("cc_email", values.cc_email);
      formdata.append("bcc_email", values.bcc_email);
      formdata.append("subject", values.subject);
      formdata.append("body", values.body);
      for (const key in files) {
        formdata.append("files", files[key]);
      }
      const response = await composeMailApi(formdata);
      if (response.status == 200) {
        toast.success(response.data.message);

        setComposeMail(false);
      } else {
        toast.error(response.data.message);
      }
      setIsSubmiting(false);
    } catch (err) {
      setIsSubmiting(false);
      if (err.response.data.message === "Invalid Credential") {
        navigate("/account-settings");
        toast.info("Please add your credentials to access your email.");
        setValue("email_setting");
      } else {
        toast.error(
          "response" in err
            ? err.response.data.message
            : "Internal Server Error, Try Again Later"
        );
      }
    }
  };

  const handleBookmarkMail = async (mail) => {
    setIsLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("code", mail.code);
      const response = await handleBookmarkMailApi(formdata, labels);

      if (response.status === 200) {
        if (labels === "Bookmarked") {
          setMails((prevMails) =>
            prevMails.filter((email) => email.code !== mail.code)
          );
        } else {
          setMails((prevMails) =>
            prevMails.map((email) =>
              email.code === mail.code
                ? { ...email, bookmarked: !email.bookmarked }
                : email
            )
          );
        }
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
      setIsLoading(false);
    }
  };

  const handleDeleteMail = async (mail) => {
    setIsLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("code", mail.code);
      const response = await deleteMailApi(formdata, labels);

      if (response.status == 200) {
        handleEmailData();
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
      setIsLoading(false);
    }
  };

  const handleRestorMail = async (mail) => {
    setIsLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("code", mail.code);
      const response = await restoreMailApi(formdata);

      if (response.status == 200) {
        handleEmailData();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
      setIsLoading(false);
    }
  };

  const handleAttachment = async (code) => {
    setIsLoading(true);
    setHtmlBody(null);
    setAttachment([]);
    try {
      const response = await getMailBodyApi(code, labels);

      if (response.status == 200) {
        setAttachment(response.data.data.attachments);
        setHtmlBody(response.data.data.html_body);
        setMails((prevEmails) =>
          prevEmails.map((email) =>
            email.code === code ? { ...email, is_seen: true } : email
          )
        );
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
      setIsLoading(false);
    }
  };

  const handleEmailSearchData = async () => {
    setIsLoading(true);
    try {
      const response = await getMailSearchDataApi(
        labels,
        globalFilter,
        pagination
      );
      if (response.status == 200) {
        setMails(response.data.data.emails);
        setRowCount(response.data.data.total_emails);
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
      setIsLoading(false);
    }
  };

  const handleMailDetails = async (code) => {
    setMailsDetails({});
    try {
      const response = await MailDetailsApi(code, labels);

      if (response.status == 200) {
        setMailsDetails(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
    }
  };

  const handleForwardMail = async (values, code, setOpen) => {
    const formdata = new FormData();
    formdata.append("to_email", values.to_email);
    formdata.append("cc_email", values.cc_email);
    formdata.append("bcc_email", values.bcc_email);
    formdata.append("original_msg_id", code);
    try {
      const response = await ForwardMailApi(formdata, labels);

      if (response.status == 200) {
        setOpen(false);
        setOpenMail(false);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
    }
  };

  const handleDraftMail = async (values, files) => {
    setIsSubmiting(true);
    try {
      const formdata = new FormData();
      formdata.append("to_email", values.to_email);
      formdata.append("cc_email", values.cc_email);
      formdata.append("bcc_email", values.bcc_email);
      formdata.append("subject", values.subject);
      formdata.append("body", values.body);
      for (const key in files) {
        formdata.append("files", files[key]);
      }
      const response = await draftMailApi(formdata);
      if (response.status == 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      setIsSubmiting(false);
    } catch (err) {
      setIsSubmiting(false);
      toast.error(
        "response" in err
          ? err.response.data.message
          : "Internal Server Error, Try Again Later"
      );
    }
  };



  const handleFetchGroupList = async () => {
    try {
      const response = await fetchGroupList()
      const resJson = response?.data;
      if (resJson.status === "success") {
        setGroupList(resJson.data)
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  const handleCrudGroup = async (values, participantListArr, action, setCrudGroup) => {
    try {
      const response = await crudGroupp(values, participantListArr, action)
      const resJson = response?.data;
      if (resJson.status === "success") {
        if (action == "add" || action == "update") {
          setCrudGroup(false)
        }
        handleFetchGroupList()
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error)
    }
  };

  return (
    <EmailContext.Provider
      value={{
        mails,
        labels,
        setLabels,
        isLoading,
        isSubmiting,
        rowCount,
        setRowCount,
        pagination,
        setPagination,
        openMail,
        setOpenMail,
        mailData,
        setMailData,
        composeMail,
        mailsDetails,
        setComposeMail,
        handleEmailData,
        handleComposeMail,
        setGlobaleFilter,
        handleAttachment,
        attachment,
        htmlBody,
        handleBookmarkMail,
        handleDeleteMail,
        handleRestorMail,
        handleDraftMail,
        handleMailDetails,
        handleForwardMail,
        globalFilter,
        handleEmailSearchData,
        handleFetchGroupList,
        groupList,
        handleCrudGroup,
        crudGroup, setCrudGroup, setMailsDetails
      }}
    >
      {children}
    </EmailContext.Provider>
  );
}
