// assets
import { IconApiApp } from "@tabler/icons";

// constant
const icons = { IconApiApp };

// ==============================|| Report PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const appManagement = {
  id: "app-manage",
  // title: 'Manage',
  type: "group",
  children: [
    {
      id: "appManagement",
      title: "App Management",
      type: "item",
      url: "/app-management",
      icon: icons.IconApiApp,
      breadcrumbs: true,
      // type: 'collapse',
    },
  ],
};

export default appManagement;
