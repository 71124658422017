import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import FeaturesRequired from "utils/FeaturesRequired";

const InwardIndex = Loadable(
  lazy(() => import("views/utilities/inward/index"))
);

const InwardHome = Loadable(
  lazy(() => import("views/utilities/inward/dashboard/index"))
);

const InwardUtilities = Loadable(
  lazy(() => import("views/utilities/inward/utils/index"))
);

const InwardReports = Loadable(
  lazy(() => import("views/utilities/inward/reports/index"))
);

// const AccountOpning = Loadable(
//   lazy(() => import("views/utilities/inward/utils/accountOpening/index"))
// );

const MyDoc = Loadable(
  lazy(() => import("views/utilities/inward/utils/myDoc/index"))
);

const InwardEntry = Loadable(
  lazy(() => import("views/utilities/inward/utils/entry/index"))
);

const InwardReceiver = Loadable(
  lazy(() => import("views/utilities/inward/utils/checker/index"))
);

const AllInwardEntry = Loadable(
  lazy(() => import("views/utilities/inward/utils/allInwardEntry/index"))
);

const Checker = Loadable(
  lazy(() => import("views/utilities/inward/utils/checker/index"))
);

const Maker = Loadable(
  lazy(() => import("views/utilities/inward/utils/maker/index"))
);

//################################################################################//
const InwardCheckerModification = Loadable(
  lazy(() => import("views/utilities/inward/utils/checker/modification/index"))
);

const InwardCheckerClouser = Loadable(
  lazy(() => import("views/utilities/inward/utils/checker/clouser/index"))
);

const InwardCheckerSegmentModification = Loadable(
  lazy(() =>
    import("views/utilities/inward/utils/checker/segment_modification/index")
  )
);

const InwardCheckerModificationDetails = Loadable(
  lazy(() =>
    import(
      "views/utilities/inward/utils/checker/modification/modificationTable/modificationData"
    )
  )
);

//############################################################################################//

const InwardReceiverModification = Loadable(
  lazy(() =>
    import("views/utilities/inward/utils/allInwardEntry/modification/index")
  )
);

const InwardMakerClosure = Loadable(
  lazy(() =>
    import("views/utilities/inward/utils/allInwardEntry/closure/index")
  )
);

//############################## feature auth Check ##############################//

const featureCheck = (component, feature) => (
  <FeaturesRequired feature={feature}>{component}</FeaturesRequired>
);

export const InwardModule = {
  path: "inward",
  element: <InwardIndex />,
  children: [
    {
      path: "home",
      element: <InwardHome />,
    },
    {
      path: "utils",
      element: <InwardUtilities />,
    },

    {
      path: "reports",
      element: <InwardReports />,
    },
  ],
};

export const InwardUtilsRoutes = {
  path: "inward",
  children: [
    {
      path: "entry",
      element: <InwardEntry />,
    },
    {
      path: "receiver",
      element: <InwardReceiver />,
    },
    {
      path: "all-inward-entry",
      element: <AllInwardEntry />,
    },
    {
      path: "all-inward-entry/reciever-modification",
      element: featureCheck(
        <InwardReceiverModification />,
        "receiver-modification"
      ),
    },
    {
      path: "all-inward-entry/maker-closure",
      element: featureCheck(<InwardMakerClosure />, "maker-closure"),
    },
    {
      path: "update-inwardEntries",
      element: <Maker />,
    },
    {
      path: "checker",
      element: <Checker />,
    },
    {
      path: "my-doc",
      element: <MyDoc />,
    },
    {
      path: "checker/inward-modification",
      element: featureCheck(
        <InwardCheckerModification />,
        "inward-modification"
      ),
    },
    {
      path: "checker/inward-modification",
      children: [
        {
          path: "profile",
          element: <InwardCheckerModificationDetails />,
        },
      ],
    },
    {
      path: "checker/inward-clouser",
      element: featureCheck(<InwardCheckerClouser />, "clouser"),
    },
    {
      path: "checker/inward-segmentModification",
      element: featureCheck(
        <InwardCheckerSegmentModification />,
        "segment-modification"
      ),
    },
  ],
};
