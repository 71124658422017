/*eslint-disable*/
import React from "react";

import { Box, Grid } from "@mui/material";

// import folderIcon from "assets/images/file icons/folder-48.png";
import folderIcon from "assets/images/icons/folder_11538209.png";
// import reportIcon from "assets/images/file icons/report-48.png";
import reportIcon from "assets/images/icons/report_icon.png";

import { getSessionStorage } from "utils/encrpytion";
import subFeatureExist from "utils/subFeatureCheck";
import ReportCard from "./ReportCard";
import FolderCard from "./FolderCard";

const CommonReportsIndex = ({ allReports }) => {
  const access = getSessionStorage("reports")?.split(",") || [];

  return (
    <>
      <Box sx={{ background: "white", minHeight: "70vh", p: 3 }}>
        <Grid
          container
          sx={{ display: "flex", flexWrap: "wrap" }}
          spacing={2}
          rowSpacing={2}
        >
          {allReports?.map((item) => {
            if (
              item.type == "file" &&
              access?.includes(item.report_id?.toString())
            ) {
              return (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100px",
                    pl: 0,
                  }}
                >
                  <ReportCard card={item} icon={reportIcon} />
                </Grid>
              );
            } else if (subFeatureExist(item.subfolder, access, "report")) {
              return (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100px",
                    pl: 0,
                  }}
                >
                  <FolderCard card={item} icon={folderIcon} />
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    </>
  );
};

export default CommonReportsIndex;
