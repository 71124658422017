import axios from "axios";
import { getHeaders } from "utils/CommonFunctions";

const COMMON_URL = process.env.REACT_APP_URL_EPLEDGE;

export const uploadFileApi = async (formData) => {
    const config = {
        headers: getHeaders
    }
    const url = `${COMMON_URL}/v1/epledge`;
    const response = await axios.post(url, formData, config);
    return response;
};

export const downloadFileApi = async () => {
    const response = await axios({
        url: `${COMMON_URL}/v1/file-respone`,
        method: 'GET',
        responseType: 'blob',
    });
    return response;
};
