/*eslint-disable*/
import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import { getSessionStorage } from "utils/encrpytion";
import ClientList from "views/utilities/crms/Reports/ClientList";
import RevenueSummary from "views/utilities/crms/Reports/RevenueSummary";

const TabLayout = Loadable(lazy(() => import("views/utilities/crms/index")));

const KYC = Loadable(
  lazy(() => import("views/utilities/crms/dashboard/user/Kyc"))
);
// const KYCBM = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/BM/KYC"))
// );
// const KYCCH = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/CH/KYC"))
// );
// const KYCRO = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/RO/KYC"))
// );
// const KYCRM = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/RM/KYC"))
// );
// const KYCSM = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/SM/KYC"))
// );
// const KYCPR = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/PROMOTER/KYC"))
// );
const Reports = Loadable(lazy(() => import("views/utilities/crms/Reports")));
const Revenue = Loadable(
  lazy(() => import("views/utilities/crms/dashboard/user/Revenue"))
);
// const RevenueBM = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/BM/Revenue"))
// );
// const RevenueRO = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/RO/Revenue"))
// );
// const RevenueRM = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/RM/Revenue/RevenueRmIndex"))
// );
// const RevenueSM = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/SM/Revenue"))
// );
// const RevenueCH = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/CH/Revenue"))
// );
// const RevenuePR = Loadable(
//   lazy(() => import("views/utilities/crms/dashboard/PROMOTER/Revenue"))
// );
const Utilities = Loadable(lazy(() => import("views/utilities/crms/utils")));
const CRMSDefaultDashBoard = Loadable(
  lazy(() => import("views/utilities/crms/dashboard/index"))
);
const DataFetchTechExcel = Loadable(
  lazy(() => import("views/utilities/crms/utils/FetchTechExcel"))
);
const TradeFile = Loadable(
  lazy(() => import("views/utilities/crms/utils/trade-file"))
);

// const user_profile = localStorage.getItem("user_profilee");
const user_profile = getSessionStorage("user_profile");

// const data = {
//   BM: { kyc: <KYCBM />, revenue: <RevenueBM /> },
//   VP: { kyc: <KYC />, revenue: <Revenue /> },
//   CM: { kyc: <KYCCH />, revenue: <RevenueCH /> },
//   RO: { kyc: <KYCRO />, revenue: <RevenueRO /> },
//   RM: { kyc: <KYCRM />, revenue: <RevenueRM /> },
//   SM: { kyc: <KYCSM />, revenue: <RevenueSM /> },
//   PROMOTER: { kyc: <KYCPR />, revenue: <RevenuePR /> }
// };

export const crmsModule = {
  path: "crms",
  element: <TabLayout />,
  children: [
    {
      path: "kyc",
      element: <KYC />,
      // element: data[user_profile]?.kyc || <></>
    },
    // {
    //   path: "kyc/:filter",
    //   element: data[user_profile]?.kyc || <></>
    // },
    {
      path: "revenue",
      element: <Revenue />,
      // element: data[user_profile]?.revenue || <></>
    },
    // {
    //   path: "revenue/:filter",
    //   element: data[user_profile]?.revenue || <></>
    // },
    {
      path: "reports",
      element: <Reports />
    },
    {
      path: "home",
      element: <CRMSDefaultDashBoard />
    },
    {
      path: "utils",
      element: <Utilities />
    }
  ]
};

export const CrmsReportsRoutes = {
  path: "crms",
  children: [
    {
      path: "client-list",
      element: <ClientList />
    },
    {
      path: "revenue-summary",
      element: <RevenueSummary />
    },

    // {
    //   path: "bmkyc",
    //   element: <KYCBM />
    // },
    // {
    //   path: "chkyc",
    //   element: <KYCCH />
    // },
    // {
    //   path: "rokyc",
    //   element: <KYCRO />
    // },
    // {
    //   path: "rmkyc",
    //   element: <KYCRM />
    // },
    {
      path: "fetch-tech-excel-data",
      element: <DataFetchTechExcel />
    },
    {
      path: "trade-file",
      element: <TradeFile />
    }
  ]
};
