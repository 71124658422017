import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchLeads,
  addLead,
  deleteLead,
  updateLead,
} from "../../services/insurance/LeadService";

export const InsuranceLeadContext = createContext();

export default function LeadApi(props) {
  const [leadData, setLeadData] = useState([]);

  const fetchLead = async () => {
    try {
      const response = await fetchLeads();
      if (response.status === 200) {
        console.log("response", response);
        setLeadData(response.data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleLeadAdd = async (
    values,
    others,
    {
      setErrors,
      setSubmitting,
      resetForm,
      selectedTobacco,
      selectedSmoking,
      showExstingIllness,
    }
  ) => {
    try {
      const familyDetails =
        values.family_detail &&
        values.family_detail.map((detail) => ({
          relation: detail.relation || "",
          name: detail.name || "",
          dob: detail.dob || "",
        }));

      const requestBody = {
        insecure_name: values.insecure_name || "",
        dob: values.dob || "",
        pob: values.pob || "",
        pincode: values.pincode || "",
        mobile: values.mobile || "",
        emergency_contact: values.emergency_contact || "",
        email: values.email || "",
        nature_of_duties: values.nature_of_duties || "",
        education: values.education || "",
        occupation: values.occupation || "",
        income: values.income || "",
        height: values.height || "",
        weight: values.weight || "",
        illness_reason: values.illness_reason || "",
        tobacco: selectedTobacco,
        smoking: selectedSmoking,
        existing_illness: showExstingIllness,
        other_company_sa: values.other_company_sa || "",
        family_detail: familyDetails || [],
      };

      const resJson = await addLead(requestBody);
      setSubmitting(false);
      resetForm({ values: "" });
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchLead();
    } catch (error) {
      console.error("Add lead error:", error);
      setErrors({ submit: "An error occurred while Adding Lead" });
      setSubmitting(false);
      toast.error("An error occurred while Adding Lead");
    }
  };

  const handleLeadUpdate = async (
    values,
    others,
    lead_id,
    {
      setErrors,
      setSubmitting,
      resetForm,
      selectedTobacco,
      selectedSmoking,
      showExstingIllness,
    }
  ) => {
    try {
      const familyDetails =
        values.family_detail &&
        values.family_detail.map((detail) => ({
          relation: detail.relation || "",
          name: detail.name || "",
          dob: detail.dob || "",
        }));

      const requestBody = {
        lead_id: lead_id,
        insecure_name: values.insecure_name || "",
        dob: values.dob || "",
        pob: values.pob || "",
        pincode: values.pincode || "",
        mobile: values.mobile || "",
        emergency_contact: values.emergency_contact || "",
        email: values.email || "",
        nature_of_duties: values.nature_of_duties || "",
        education: values.education || "",
        occupation: values.occupation || "",
        income: values.income || "",
        height: values.height || "",
        weight: values.weight || "",
        illness_reason: values.illness_reason || "",
        tobacco: selectedTobacco,
        smoking: selectedSmoking,
        existing_illness: showExstingIllness,
        other_company_sa: values.other_company_sa || "",
        family_detail: familyDetails || [],
      };

      const resJson = await updateLead(requestBody);
      toast.success(resJson.data.message);
      resetForm({ values: "" });
      others.setOpen(false);
      fetchLead();
    } catch (error) {
      console.error("Update lead error:", error);
      setErrors({ submit: "An error occurred while updating lead" });
      setSubmitting(false);
      toast.error("An error occurred while updating lead");
    }
  };

  const handleDelete = async (lead_id) => {
    try {
      const resJson = await deleteLead({ lead_id });
      if (resJson.status === 200) {
        fetchLead();
        toast.success(resJson.data.message);
      } else {
        toast.error("Failed to delete Lead.");
      }
    } catch (error) {
      console.error("Delete Lead error:", error);
      toast.error("An error occurred while deleting the Lead.");
    }
  };

  return (
    <InsuranceLeadContext.Provider
      value={{
        handleLeadAdd,
        handleLeadUpdate,
        fetchLead,
        handleDelete,
        leadData,
      }}
    >
      {props.children}
    </InsuranceLeadContext.Provider>
  );
}
