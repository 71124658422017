/*eslint-disable*/
import React from "react";

import { UtilitesCheck } from "utils/UtilitesCheck";
import CommonReportsIndex from "views/utilities/common-components/CommonReportsIndex";
import ComplianceReportsData from "config-details/reports/ComplianceReportData";

const ComplianceReports = () => {
  const accessibleReports = UtilitesCheck(ComplianceReportsData, "reports")
  return (
    <CommonReportsIndex allReports={ComplianceReportsData} />
  );
};

export default ComplianceReports;