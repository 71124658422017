import React from "react";

import EDDPIFeatures from "config-details/features/EDDPIFeatures";
import CommonUtilsIndex from "views/utilities/common-components/CommonUtilsIndex";

const EddpiUtilities = () => {

  return (
    <CommonUtilsIndex allFeatures={EDDPIFeatures} />
  );
};

export default EddpiUtilities;
