import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const NetworkMonitorIndex = Loadable(
  lazy(() => import("views/utilities/network-monitor"))
);

const NetworkMonitorHome = Loadable(
  lazy(() => import("views/utilities/network-monitor/dashboard"))
);
const NetworkMonitorUtilities = Loadable(
  lazy(() => import("views/utilities/network-monitor/utils"))
);
const NetworkMonitorReports = Loadable(
  lazy(() => import("views/utilities/network-monitor/reports"))
);

export const NetworkMonitorModule = {
  path: "network-monitor",
  element: <NetworkMonitorIndex />,
  children: [
    {
      path: "home",
      element: <NetworkMonitorHome />,
    },
    {
      path: "utils",
      element: <NetworkMonitorUtilities />,
    },
    {
      path: "reports",
      element: <NetworkMonitorReports />,
    },
  ],
};

// export const NetworkMonitorReportsRoutes = {
//   path: "network-monitor",
//   children: [
//     {
//       path: "ekyc",
//       element: <Ekyc />,
//     },
//     {
//       path: "ekyc/live-login",
//       element: <Ekyc />,
//     },
//   ],
// };
