/*eslint-disable*/
import React, { useState, createContext } from "react";

import { toast } from "react-toastify";
import { handleApproveRejectApi, handleFetchEsignPdfApi, handleFetchLogDataApi, handleFetchTableDataApi } from "services/eddpi/eddpiServices";

export const EddpiContext = createContext();

export const EddpiApis = (props) => {

    const [allData, setAllData] = useState([]);
    const [logData, setLogData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');


    //GET request Table data
    const handleFetchTableData = async () => {
        try {
            const response = await handleFetchTableDataApi();

            console.log("response", response.data.data);
            setAllData(response.data.data.map((item, index) => ({ ...item, sr_no: index + 1 })));
        } catch (error) {
            toast.error(error?.response?.data.message);
            console.error("error", error);
        }
    };

    //GET log Table data
    const handleFetchLogData = async () => {
        setLoading(true);
        try {
            const response = await handleFetchLogDataApi();
            console.log("response log", response.data.data);
            setLogData(response.data.data.map((item, index) => ({ ...item, sr_no: index + 1 })));
        } catch (error) {
            toast.error(error?.response?.data.message);
            console.error("error", error);
        } finally {
            setLoading(false);
        }
    };

    //POST approve reject
    const handleApproveReject = async (payload) => {
        setLoading(true);
        console.log("apiPaylaod", payload);
        const formData = new FormData();
        formData.append('client_code', payload.client_code);
        formData.append('status', payload.status);
        formData.append('reason', payload.reason);
        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ': ' + pair[1]);
        // }
        try {
            const response = await handleApproveRejectApi(formData);
            console.log("response", response);
            toast.success(response.data.message);
            handleFetchTableData();
        } catch (error) {
            toast.error(error?.response?.data.message);
            console.error("error", error);
        } finally {
            setLoading(false);
        }
    };

    //POST esign pdf
    const handleFetchEsignPdf = async (payload) => {
        setLoading(true);
        try {
            const response = await handleFetchEsignPdfApi(payload);

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            setPdfUrl(url);
            setPdfDialogOpen(true);
            toast.success(response.data.message);

        } catch (error) {
            const err = JSON.parse(await error.response.data.text());
            toast.error(err.message);
            console.error('error', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <EddpiContext.Provider value={{ loading, handleFetchTableData, allData, handleApproveReject, handleFetchEsignPdf, pdfDialogOpen, setPdfDialogOpen, pdfUrl, handleFetchLogData, logData }}>
            {props.children}
        </EddpiContext.Provider>
    )
}