/*eslint-disable*/
import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const ResearchAvmHome = Loadable(lazy(() => import("views/utilities/research-aum")));
const ResearchAvmDashboard = Loadable(lazy(() => import("views/utilities/research-aum/dashboard")));
const ResearchAvmUtilss = Loadable(lazy(() => import("views/utilities/research-aum/utils")));
const ResearchAvmReport = Loadable(lazy(() => import("views/utilities/research-aum/reports")));



const UtilsAddClient = Loadable(lazy(() => import("views/utilities/research-aum/utils/add-client")));
const UtilsAddFundDetail = Loadable(lazy(() => import("views/utilities/research-aum/utils/add-fund-detail")));
const UtilsUploadTrade = Loadable(lazy(() => import("views/utilities/research-aum/utils/upload-trade")));


const ReportsBhavCopy = Loadable(lazy(() => import("views/utilities/research-aum/reports/bhav-copy")));
const ReportsDaywHolding = Loadable(lazy(() => import("views/utilities/research-aum/reports/dayw-holding")));
const ReportsDaywNav = Loadable(lazy(() => import("views/utilities/research-aum/reports/dayw-nav")));
const ReportsnavReturn = Loadable(lazy(() => import("views/utilities/research-aum/reports/nav-return")));
export const ResearchAvmReportsRoutes = {
  path: "research-aum",
  children: [
    {
      path: "bhav-copy",
      element: <ReportsBhavCopy />,
    },
    {
      path: "dayw-holding",
      element: <ReportsDaywHolding />,
    },
    {
      path: "dayw-nav",
      element: <ReportsDaywNav />,
    },
    {
      path: "nav-return",
      element: <ReportsnavReturn />,
    },
  ],
};

export const ResearchAvmUtilsRoutes = {
  path: "research-aum",
  children: [
    {
      path: "add-client",
      element: <UtilsAddClient />,
    },
    {
      path: "add-fund-detail",
      element: <UtilsAddFundDetail />,
    },
    {
      path: "upload-trade",
      element: <UtilsUploadTrade />,
    },
  ],
};


export const ResearchAvmModule = {
  path: "research-aum",
  element: <ResearchAvmHome />,
  children: [
    {
      path: "home",
      element: <ResearchAvmDashboard />
    },
    {
      path: "utils",
      element: <ResearchAvmUtilss />
    },
    {
      path: "reports",
      element: <ResearchAvmReport />
    }
  ]
};
