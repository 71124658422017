/*eslint-disable*/
import React, { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";
import { boardData } from "../../config-details/kanbanboarddata";
import { toast } from "react-toastify";
import { PMSTargetContext } from "./PmsTargetContext";
import { formatDateYMD, handleCatch } from "utils/CommonFunctions";
import { addComment, addIndividualTask, addProject, addTask, changeTimerState, deleteAttachment, deleteComment, deleteWorklog, editComment, fetchAddProjectSection, fetchAllWorkLogDetails, fetchAssignTask, fetchAttachments, fetchAttachmentsList, fetchChangeFavourites, fetchCommentByTask, fetchFileUploadAttachments, fetchListProject, fetchMasterDashboardData, fetchMasterDashboardProject, fetchParticipantsDetails, fetchProjectAccess, fetchProjectDetailsByProjectId, fetchProjectList, fetchSectionByProject, fetchTaskByProject, fetchTaskListByTaskID, fetchUserDashboardData, startTimerr, updateProject, updateSection, updateTask, updateWorklog } from "services/pms/pmsServices";
import axios from "axios";
import useApiCancellation from "hooks/useApiCancellation";
import { useSearchParams } from "react-router-dom";
import { removeQueryParam } from "views/pms/Config";

export const PMSContext = createContext();
export default function PMSContextProvider({ children }) {
  const {
    handleFetchAllTasksByMilestone,
    handleFetchMilestonesByTarget
  } = useContext(PMSTargetContext);
  const [board, setBoard] = useState(boardData);
  const [taskDetails, setTaskDetails] = useState([]);
  const [projectTaskDetails, setProjectsTaskDetails] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [sectionByProject, setSectionByProject] = useState([]);
  const [sectionByProjectState, setSectionByProjectState] = useState([]);
  const [userList, setUserList] = useState([]);
  const [participantsList, setParticipantsList] = useState([]);
  const [taskDataByTaskId, setTaskDataByTaskId] = useState({});
  const [tempTaskDataByTaskId, setTempTaskDataByTaskId] = useState({});
  const [projectAccessList, setProjectAccessList] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});
  const [commentData, setCommentData] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [viewStatus, setViewStatus] = useState(false);
  const [dashboardFilter, setDashboardFilter] = useState(false);
  const [kanbanFilter, setKanbanFilter] = useState("section");
  const [formValues, setFormValues] = useState({
    duedate: "",
    sortby: "",
    projectstatus: "",
    tag: "",
    employee: ""
  });
  const [editSectionDialogue, setEditSectionDialogue] = useState(false);
  const [commentReplayData, setCommentReplayData] = useState({
    commentId: "",
    comment: "",
    status: false
  });
  const [commentEditData, setCommentEditData] = useState({
    commentId: "",
    comment: "",
    status: false,
  });
  const [isRunning, setIsRunning] = useState(false)
  const [currentTime, setCurrentTime] = useState("")
  const [filterVal, setFilterVal] = useState("notcomp")
  const [worklogId, setWorklogId] = useState("")
  const [totalTimeInMin, setTotalTimeInMin] = useState(null)
  const [projectListDashboard, setProjectListDashboard] = useState([])
  const [userDashboardData, setUserDashboardData] = useState({})
  const [masterDashboardData, setMasterDashboardData] = useState([])
  const [masterDashboardDataHOD, setMasterDashboardDataHOD] = useState([])
  const [masterDashboardDataCommon, setMasterDashboardDataCommon] = useState([])
  const [masterDashboardProject, setMasterDashboardProject] = useState([])
  const [openStopWatch, setOpenStopWatch] = useState(false)
  const [allWorkLog, setAllWorkLog] = useState([])

  const [searchParams, setSearchParams] = useSearchParams();

  const [cancelTokenSource, setCancelTokenSource] = useState(null);



  const { cancelPreviousRequest, createAbortController } = useApiCancellation();


  ////////////////////////////////////////////////////////////////Task//////////////////////////////////////////////////////////



  const handleAddTask = async (values, setOpen, filterVal = "notcomp", taskType, kanbanFilter) => {
    try {
      const requestData = {
        name: values.task_name,
        description: "",
        base_id: values.project,
        assigned_to: values.assign,
        start_date: "",
        task_type: values.task_type,
        category: values.task_priority,
        tag: "",
        due_date: "",
        followers: [],
        task_section: values.section || "",
        parent_task_id: values.parent_task_id || "",
        status: values.status || ""
      };
      const response = await addTask(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        if (taskType === "mytasks") {
          handleFetchAssignTask(filterVal);
        } else if (taskType === "projecttasks") {
          handleFetchTaskByProject(values.project, "kanban", filterVal, kanbanFilter);
          handleFetchTaskByProject(values.project, "tasks", filterVal);
        } else if (taskType === "milestoneTask") {
          handleFetchAllTasksByMilestone(values.project, filterVal);
          handleFetchMilestonesByTarget(values.target_id);
        }
        setOpen(false);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleAddIndividualTask = async (values, setOpen, filterVal = "all", taskType) => {
    try {
      const requestData = {
        name: values.task_name,
        description: "",
        assigned_to: values.assign,
        start_date: "",
        task_type: values.task_type,
        category: values.task_priority,
        tag: "",
        due_date: "",
        followers: [],
        parent_task_id: "",
      };
      const response = await addIndividualTask(requestData);
      if (response.data.status === "success") {
        if (taskType === "mytasks") {
          handleFetchAssignTask(filterVal);
        }
        setOpen(false);
        toast.success(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateTask = async (values, setOpen, filterVal = "all", from, kanbanSection = "", kanbanFilter = "section", target_id) => {
    try {
      const requestData = {
        base_id: values.base_id,
        task_id: values.task_id,
        name: values.name,
        description: values.description,
        assigned_to: values.assigned_to,
        start_date: formatDateYMD(values.start_date),
        task_type: values.task_type,
        category: values.category,
        tag: values.tag,
        due_date: formatDateYMD(values.due_date),
        followers: values.followers,
        task_section: from === "kanban" ? kanbanSection : values.task_section_id || "",
        final_completion_date: "",
        status: values.status || "",
        progress: values.progress == null && "" || "",
      };
      const paramsData = {
        task_type: values.task_flag
      };
      const response = await updateTask(requestData, paramsData);
      const resJson = response.data;
      if (resJson.status === "success") {
        if (from === "mytasks") {
          removeQueryParam(searchParams, setSearchParams)
          handleFetchAssignTask(filterVal);
        } else if (from === "project") {
          handleFetchTaskByProject(values.base_id, "kanban", filterVal);
          handleFetchTaskByProject(values.base_id, "tasks", filterVal);
        } else if (from === "target") {
          handleFetchAllTasksByMilestone(values.base_id, filterVal);
          handleFetchMilestonesByTarget(target_id);
        } else if (from === "kanban") {
          setFilterVal("all")
          handleFetchTaskByProject(values.base_id, "kanban", "all", kanbanFilter);
        } else if (from === "milestone") {
          handleFetchMilestonesByTarget(values.target_id);
        }
        toast.success(resJson.message);
        if (from !== "kanban") {
          setOpen(false);
        }
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  const handleDeleteTask = async (task_id, filterVal = "all", projectId, param, setOpen = () => { }, target_id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL_PMS_TARGET}/v1/delete-task`,
        {
          headers: {
            "Content-Type": "application/json",
            authToken: localStorage.getItem('authToken'),
          },
          data: {
            task_id: task_id,
          },
        }
      );

      const resJson = response.data;
      if (resJson.status === "success") {
        if (param === "target") {
          handleFetchAllTasksByMilestone(projectId, filterVal);
          handleFetchMilestonesByTarget(target_id);
          setOpen(false);
        } else if (param === "mytasks") {
          removeQueryParam(searchParams, setSearchParams)
          handleFetchAssignTask(filterVal);
          setOpen(false);
        } else {
          if (projectId) {
            handleFetchTaskByProject(projectId, "tasks", filterVal);
            handleFetchTaskByProject(projectId, "kanban", filterVal);
            setOpen(false);
          } else {
            handleFetchAssignTask(filterVal);
          }
        }
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };



  const handleFetchAssignTask = async (param) => {
    setTaskDetails([]);
    try {
      const paramsData = param;
      const response = await fetchAssignTask(paramsData);
      if (response.data.status === "success") {
        setTaskDetails(response.data.data.task_data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchProjectDetailsByProjectId = async (param) => {
    setProjectDetails({});
    try {
      const paramsData = {
        project_id: param
      };
      const response = await fetchProjectDetailsByProjectId(paramsData);
      const resJson = response.data;
      console.log("handleFetchProjectDetailsByProjectId:", resJson.data);

      if (resJson.status === "success") {
        setProjectDetails(resJson.data[0]);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleFetchTaskByProject = async (param, value, filterVal, kanban_filter = "section") => {
    setProjectsTaskDetails([]);
    try {
      const requestData = {
        project_id: param,
      };
      const paramsData = {
        task_view: value,
        kanban_filter: kanban_filter,
        task_filter: filterVal,
      };
      const response = await fetchTaskByProject(requestData, paramsData);
      const resJson = response.data;
      if (resJson.status === "success") {
        if (value === "tasks") {
          setProjectsTaskDetails(resJson.data);
        } else if (value === "kanban") {
          setBoard(resJson.data);
        }
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchListProject = async () => {
    const paramsData = {
      project_status: formValues.projectstatus || "",
      emp_filter: formValues.employee || "",
      sort_by: formValues.sortby || ""
    }
    try {
      const response = await fetchListProject(paramsData);
      const resJson = response.data;
      if (resJson.status === "success") {
        const tempData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setProjectList(tempData);
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  const handleTaskListByTaskID = async (taskId) => {
    setTaskDataByTaskId([]);
    try {
      const requestData = { task_id: taskId };
      const response = await fetchTaskListByTaskID(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        setTaskDataByTaskId(resJson.data);
        setTempTaskDataByTaskId(resJson.data)
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchUserDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_PMS}/v1/get-user-details`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken,
          },
        }
      );
      const resJson = await response.json();
      if (resJson.status == "success") {
        setUserList(resJson.data.users_list);
      }
    } catch (error) {
      handleCatch(error)
    }
  };



  const handleFetchParticipantsDetails = async (baseId, param) => {
    setParticipantsList([]);
    try {
      const requestData = {
        base_id: baseId
      };
      const paramsData = {
        base_type: param
      };

      const response = await fetchParticipantsDetails(requestData, paramsData);
      const resJson = response.data;

      if (resJson.status === "success") {
        setParticipantsList(resJson.data[0].participants);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleAddProject = async (param, setOpen, viewList) => {
    try {
      const requestData = {
        project_manager: param.project_manager,
        project_name: param.project_name,
        project_status: param.project_status,
        description: "",
        priority: param.project_priority,
        tags: "",
        start_date: "",
        estimated_hours: "",
        expected_completion_date: "",
        participants: viewList,
        progress: "",
      };
      const response = await addProject(requestData);
      toast.success(response.data.message);
      handleFetchListProject();
      setOpen(false);
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleUpdateProject = async (param, setOpen = () => { }, viewList) => {
    try {
      const requestData = {
        project_id: param.project_id,
        project_manager: param.project_manager,
        project_name: param.project_name,
        description: param.description,
        project_status: param.project_status,
        priority: param.project_priority || param.priority,
        tags: param.tags,
        start_date: formatDateYMD(param.start_date),
        estimated_hours: "",
        expected_completion_date: formatDateYMD(param.expected_completion_date),
        participants: param.participants || viewList,
        progress: "",
        modified_expected_completion_date: "",
        work_days: ""
      };
      const response = await updateProject(requestData);
      if (response.status === 200) {
        setOpen(false);
        toast.success(response.data.message);
        handleFetchListProject();
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleDeleteProject = async (param) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL_PMS_TARGET}/v1/delete-project`,
        {
          headers: {
            "Content-Type": "application/json",
            authToken: localStorage.getItem('authToken'),
          },
          data: {
            project_id: param
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        handleFetchListProject();
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleDeleteSection = async (project_id, section_id, filterVal, viewType) => {
    try {

      const response = await axios.delete(
        `${process.env.REACT_APP_URL_PMS_TARGET}/v1/delete-section`,
        {
          headers: {
            "Content-Type": "application/json",
            authToken: localStorage.getItem('authToken'),
          },
          data: {
            section_id: section_id,
            base_id: project_id,
          },
        }
      );
      const resJson = response.data;
      console.log('handleDeleteSection', resJson);
      if (response.status === 200) {
        toast.success(resJson.message);
        handleFetchTaskByProject(project_id, viewType, filterVal);
        handleFetchSectionByProject(project_id);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleUpdateSection = async (sectionDetails, filterVal, viewType, dnd = "notdnd") => {
    try {
      const requestData = {
        base_id: viewType === "kanban" ? sectionDetails.projectId : sectionDetails.row.original.project_id,
        section_id: viewType === "kanban" ? sectionDetails.row.section_id : sectionDetails.row.original.section_id,
        name: sectionDetails.name,
      };
      const response = await updateSection(requestData);
      if (response.status === 200) {
        toast.success(response.data.message);
        if (viewType === "kanban") {
          if (dnd == "dnd") {
            handleFetchTaskByProject(sectionDetails.projectId, "tasks", filterVal);
          } else {
            handleFetchTaskByProject(sectionDetails.projectId, "kanban", filterVal);
          }
          handleFetchSectionByProject(sectionDetails.projectId)
        } else {
          handleFetchTaskByProject(sectionDetails.row.original.project_id, "tasks", filterVal);
        }
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleFetchSectionByProject = async (param) => {
    setSectionByProject([]);
    try {
      const requestData = { base_id: param };
      const response = await fetchSectionByProject(requestData);
      const resJson = response.data;
      console.log("handleFetchSectionByProject", resJson);

      if (resJson.status === "success") {
        setSectionByProject(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchProjectAccess = async () => {
    try {
      const response = await fetchProjectAccess();
      const resJson = response.data;
      console.log("handleFetchProjectAccess", resJson);

      if (resJson.status === "success") {
        setProjectAccessList(resJson.data);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleAddProjectSection = async (param, sectionName) => {
    try {
      const requestData = {
        base_id: param,
        name: sectionName
      };

      const response = await fetchAddProjectSection(requestData);
      const resJson = response.data;
      console.log("handleAddProjectSection", resJson);

      if (resJson.status === "success") {
        handleFetchTaskByProject(param, "tasks", "all");
        handleFetchTaskByProject(param, "kanban", "all");
        handleFetchSectionByProject(param);

        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  // ############################################# Comment

  const handleAddComment = async (taskId, comment, parent_comment_id, status) => {
    try {
      const paramsData = {
        comment_option: status ? "replay" : "comment"
      }

      const requestData = {
        comment,
        parent_comment_id: parent_comment_id || "",
        base_id: taskId
      };
      const response = await addComment(requestData, paramsData);
      const resJson = response.data;


      if (resJson.status === "success") {
        handleFetchCommentByTask(taskId);
        toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleEditComment = async (taskId, comment, comment_id) => {
    try {
      const requestData = {
        comment,
        comment_id
      };
      const response = await editComment(requestData);
      if (response.data.status === "success") {
        handleFetchCommentByTask(taskId);
        // toast.success(resJson.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchCommentByTask = async (taskId) => {
    setCommentData([]);
    try {
      const requestData = {
        base_id: taskId
      };
      const response = await fetchCommentByTask(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        setCommentData(resJson.data.comments_list);
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  const handleDeleteComment = async (comment_id, taskId) => {
    try {
      const requestData = {
        comment_id
      };
      const response = await deleteComment(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleFetchCommentByTask(taskId);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFileUploadAttachments = async (files, taskId) => {
    const formData = new FormData();
    formData.append("base_id", taskId);
    formData.append("followers", "");
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    try {
      const response = await fetchFileUploadAttachments(formData);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleGetAttachmentsList(taskId);
      }
    } catch (error) {
      handleCatch(error);
    }
  };



  const handleGetAttachmentsList = async (taskId) => {
    try {
      const requestData = {
        base_id: taskId
      };
      const response = await fetchAttachmentsList(requestData);
      const resJson = response.data;
      if (resJson.status === "success") {
        setAttachmentList(resJson.data.attachment_list);
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleDeleteAttachmentsList = async (attachment_id, taskId) => {
    try {
      const paramsData = {
        attachment_id
      };
      const response = await deleteAttachment(paramsData);
      const resJson = response.data;
      if (resJson.status === "success") {
        handleGetAttachmentsList(taskId)
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleGetAttachments = async (param, item_type, action) => {

    const Base64ToBlob = (base64String, item_type, action, filename) => {
      const binaryString = atob(base64String);
      const arrayBuffer = new ArrayBuffer(binaryString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
      }
      const mimeTypes = {
        zip: 'application/zip',
        png: 'image/png',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        gif: 'image/gif',
        pdf: 'application/pdf',
        csv: 'text/csv',
      };

      const mimeType = mimeTypes[item_type] || 'image/png';

      const blob = new Blob([arrayBuffer], { type: mimeType });
      const url = URL.createObjectURL(blob);

      if (action === 'view') {
        window.open(url, '_blank');
      } else if (action === 'download') {
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = filename || 'download';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        console.error('Invalid action. Please specify "view" or "download".');
      }

      return () => {
        URL.revokeObjectURL(url);
      };
    };
    const
      body = { attachment_id: param }
    try {
      const response = await fetchAttachments(body);
      const resJson = response.data;
      if (resJson.status === "success") {
        Base64ToBlob(resJson.data, item_type, action);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleChangeFavourites = async (baseId) => {
    try {
      const paramsData = {
        base_id: baseId
      };
      const response = await fetchChangeFavourites(paramsData);
      const resJson = response.data;

      if (resJson.status === "success") {
        handleFetchListProject();
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleStartTimer = async (paramData) => {
    try {
      const bodyData = {
        task_id: paramData.task_id
      };
      const response = await startTimerr(bodyData);
      const resJson = response.data;

      if (resJson.status === "success") {
        if (paramData.worklog_id !== resJson?.data?.worklog_id) {
          const timee = new Date()
          setTempTaskDataByTaskId(taskDataByTaskId)
          setWorklogId(resJson?.data?.worklog_id)
          setCurrentTime(timee)
          setIsRunning(true)
        }
      }
    } catch (error) {
      console.log("error ", error)
      if (error?.response?.status == 400) {
        const requestData = { task_id: error.response.data.data.working_task_id };
        const response = await fetchTaskListByTaskID(requestData);
        let updatedTempData = { ...response.data.data, warning: 'Below task is already in running stage Select activity and stop the timer to continue with new task timer' }
        console.log("updatedTempData", updatedTempData);
        setWorklogId(updatedTempData.worklog_id)
        setTempTaskDataByTaskId(updatedTempData)
        setOpenStopWatch(true)
      } else {
        handleCatch(error)
      }

    }
  };


  const handleChangeSaveTimerState = async (values, paramData, handleClose) => {
    try {
      const bodyData = {
        task_id: paramData.task_id || "",
        worklog_id: worklogId,
        activity_type: values.activity || "",
        description: values.description || "",
        is_billable: values.billable || false
      };

      const response = await changeTimerState(bodyData);

      if (response.status === 201) {
        if (paramData.warning) {
          handleStartTimer(taskDataByTaskId)
        } else {
          setTotalTimeInMin(response.data.data.total_worklog_time)
        }
        setIsRunning(false)
        handleClose()
      }
    } catch (error) {
      handleCatch(error);
    }
  };


  const handleFetchProjectList = async () => {
    try {
      const response = await fetchProjectList();
      if (response.status === 200) {
        setProjectListDashboard(response.data.data)
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchUserDashboardData = async (project_id) => {
    try {
      setUserDashboardData({})
      const paramsData = {
        project_id
      }
      const response = await fetchUserDashboardData(paramsData);
      if (response.status === 200) {
        setUserDashboardData(response.data.data)
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleFetchMasterDashboardData = async (department_id = "all", project_id = "all", filter_date = "all") => {
    try {
      const paramsData = {
        department_id,
        project_id,
        filter_date
      }

      const requestKey = `${localStorage.authToken}-fetchMasterDashboardData`;

      // if (cancelTokenSource) {
      //   cancelTokenSource.cancel('Operation canceled due to new request.');
      // }
      // const source = axios.CancelToken.source();
      // setCancelTokenSource(source);
      // axiosInstance.cancelRequestById('fetchMasterDashboardData');
      // cancelPreviousRequest(requestKey);
      // const token = createCancelToken(requestKey);

      cancelPreviousRequest(requestKey); // Cancel the previous request if it exists
      const signal = createAbortController(requestKey);


      const response = await fetchMasterDashboardData(paramsData, signal);
      if (response.status === 200) {
        if (department_id == "all" && project_id == "all") {
          setMasterDashboardData(response?.data?.data)
        } else if (project_id == "all") {
          setMasterDashboardDataHOD(response?.data?.data)
        } else {
          setMasterDashboardDataCommon(response?.data?.data)
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Previous request canceled:', error.message);
      } else {
        handleCatch(error);
      }
    }
  };

  const handleFetchProjectsOfDepartment = async (department_id) => {
    try {
      const paramsData = {
        department_id,
      }
      const response = await fetchMasterDashboardProject(paramsData);
      if (response.status === 200) {
        setMasterDashboardProject(response?.data?.data)
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  const handleFetchAllWorklog = async (task_id) => {
    console.log("task_is", typeof task_id);

    try {
      const paramsData = {
        task_id,
      }
      const response = await fetchAllWorkLogDetails(paramsData);
      if (response.status === 200) {
        setAllWorkLog(response?.data?.data)
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  const handleDeleteWorklog = async (worklog_id, from, handleClose = () => { }, taskId = "") => {
    try {
      const paramsData = {
        worklog_id: from == "worklog" ? worklog_id : worklogId,
      }
      const response = await deleteWorklog(paramsData);
      if (response.status === 200) {
        if (from == "worklog") {
          setTotalTimeInMin(response.data.data.total_worklog_time)
          handleFetchAllWorklog(taskId)
          handleClose()
        } else if (from == "working_timer") {
          handleClose()
          setIsRunning(false)
        }
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  const handleUpdateWorklog = async (values, from, handleClose = () => { }, taskId = "") => {
    try {
      const paramsData =
      {
        worklog_id: values.worklog_id || 0,
        start_time: values.start_date || "",
        end_time: values.end_date || "",
        activity_type: values.activity || "",
        description: values.description || "",
        is_billable: values.billable || false,
        task_id: values.task_id || ""
      }
      const response = await updateWorklog(paramsData);
      if (response.status === 200) {
        if (from == "worklog") {
          handleFetchAllWorklog(taskId)
          setTotalTimeInMin(response.data.data.total_worklog_time)
          handleClose()
        }
      }
    } catch (error) {
      handleCatch(error);
    }
  };



  return (
    <PMSContext.Provider
      value={{
        board,
        setBoard,
        handleFetchAssignTask,
        taskDetails,
        setTaskDetails,
        handleFetchTaskByProject,
        projectTaskDetails,
        handleFetchListProject,
        projectList,
        handleAddTask,
        handleUpdateTask,
        handleDeleteTask,
        handleDeleteProject,
        handleDeleteSection,
        handleTaskListByTaskID,
        taskDataByTaskId,
        handleFetchUserDetails,
        userList,
        setUserList,
        handleFetchParticipantsDetails,
        participantsList,
        handleAddProject,
        handleUpdateProject,
        handleUpdateSection,
        handleFetchSectionByProject,
        sectionByProject,
        setSectionByProject,
        handleFetchProjectAccess,
        projectAccessList,
        handleFetchProjectDetailsByProjectId,
        projectDetails,
        setProjectDetails,
        handleAddProjectSection,
        handleAddComment,
        handleFetchCommentByTask,
        commentData,
        handleFileUploadAttachments,
        handleGetAttachmentsList,
        attachmentList,
        handleGetAttachments,
        handleAddIndividualTask,
        setCommentReplayData,
        commentReplayData,
        kanbanFilter,
        setKanbanFilter,
        sectionByProjectState,
        handleChangeFavourites,
        setViewStatus,
        viewStatus,
        editSectionDialogue, setEditSectionDialogue,
        dashboardFilter, setDashboardFilter,
        formValues, setFormValues,
        handleStartTimer, isRunning, setIsRunning,
        handleChangeSaveTimerState,
        currentTime, setCurrentTime,
        setWorklogId, filterVal, setFilterVal, totalTimeInMin, setTotalTimeInMin,
        handleFetchProjectList,
        projectListDashboard, setProjectListDashboard,
        handleDeleteAttachmentsList,
        handleFetchUserDashboardData,
        userDashboardData,
        handleFetchMasterDashboardData,
        masterDashboardData,
        openStopWatch, setOpenStopWatch,
        tempTaskDataByTaskId, setTempTaskDataByTaskId,
        handleFetchProjectsOfDepartment,
        masterDashboardProject, setMasterDashboardProject,
        masterDashboardDataHOD, setMasterDashboardDataHOD,
        masterDashboardDataCommon, setMasterDashboardDataCommon,
        handleFetchAllWorklog,
        allWorkLog, setAllWorkLog,
        handleDeleteWorklog,
        handleUpdateWorklog,
        handleDeleteComment,
        commentEditData, setCommentEditData,
        handleEditComment
      }}
    >
      {children}
    </PMSContext.Provider>
  );
}