import { FILE } from "config";

const AlgoSummaryReportData = [
  {
    app_id: 41,
    report_id: 41002,
    type: FILE,
    subfolder: [],
    label: "Day Wise MTM",
    report_name: "day_wise_mtm",
    path: "/algo-summary/daywise-mtm",
  },
  {
    app_id: 41,
    report_id: 41001,
    type: FILE,
    subfolder: [],
    label: "Monthly Summary",
    report_name: "monthly_summary",
    path: "/algo-summary/monthly-summary",
  },
  {
    app_id: 41,
    report_id: 41003,
    type: FILE,
    subfolder: [],
    label: "Performance Chart",
    report_name: "performance-charts",
    path: "/algo-summary/performance-chart",
  },
];

export default AlgoSummaryReportData;
