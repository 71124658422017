/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { handleCatch } from "utils/CommonFunctions";

export const AccountSetting = createContext();

const Base64ToUrl = (base64String) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "image/jpeg" });
  const url = URL.createObjectURL(blob);
  return url;
};

export default function AccountSettingApis({ children }) {
  const [avtar, setAvtar] = useState("");
  const [value, setValue] = useState("general");
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setAvtar("");
    const base64String = localStorage.avatarBase64;
    if (base64String !== undefined) {
      const url = Base64ToUrl(base64String);
      setAvtar(url);
    }
  }, []);

  const handleGetDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_EMAIL}/v1/user-profile-details`,
        {
          headers: {
            authToken: localStorage.authToken,
          },
        }
      );
      if (response.status === 200) {
        const base64String = response.data.data.profile_picture;
        if (base64String) {
          localStorage.setItem("avatarBase64", base64String);
          const url = Base64ToUrl(base64String);
          setAvtar(url);
        }
        setUserInfo(response.data.data);
      }
    } catch (err) {
      console.log('err', err)
    }
  };

  const handleAvtarChange = async (e) => {
    const file = e.target.files[0];
    try {
      const formdata = new FormData();
      formdata.append("profile_picture", file);
      const response = await axios.post(
        `${process.env.REACT_APP_URL_EMAIL}/v1/user-profile`,
        formdata,
        {
          headers: {
            authToken: localStorage.authToken,
          },
        }
      );
      if (response.status === 200) {
        const base64String = response.data.data;
        if (base64String) {
          localStorage.setItem("avatarBase64", base64String);
          const url = Base64ToUrl(base64String);
          setAvtar(url);
        }
      }
    } catch (err) {
      handleCatch(err);
    }
  };

  const handleEmailCredentials = async (values) => {
    try {
      const formdata = new FormData();
      for (const key in values) {
        formdata.append(key, values[key]);
      }
      const response = await axios.post(
        `${process.env.REACT_APP_URL_EMAIL}/v1/user-profile`,
        formdata,
        {
          headers: {
            authToken: localStorage.authToken,
          },
        }
      );
      if (response.status === 200) {
        values.display_name
          ? toast.success("Nick Name Updated !")
          : navigate("/utilities/e-mail");
      }
    } catch (err) {
      handleCatch(err);
    }
  };

  return (
    <AccountSetting.Provider
      value={{
        avtar,
        setAvtar,
        userInfo,
        value,
        setValue,
        setUserInfo,
        handleGetDetails,
        handleAvtarChange,
        handleEmailCredentials,
      }}
    >
      {children}
    </AccountSetting.Provider>
  );
}
