import axios from "axios";
import { commonApiRequest, getHeaders } from "utils/CommonFunctions";

const COMMON_URL = process.env.REACT_APP_URL_KYC;
const KYC_MODIFICATION_URL = process.env.REACT_APP_URL_KYC_MODIFICATION;
const REKYC_URL = process.env.REACT_APP_URL_REKYC;

export const fetchTableDataApi = (params) => {
  const url = `${COMMON_URL}/v1/e-kyc/get_data`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchHufTableDataApi = (params) => {
  const url = `${COMMON_URL}/v1/e-kyc/get_data`;
  return commonApiRequest("GET", url, null, params);
};

export const handleFetchDataByPanApi = (params) => {
  const url = `${COMMON_URL}/v1/e-kyc/get_aadhar_data`;
  return commonApiRequest("GET", url, null, params);
};

export const handleNomineeDataApi = (params) => {
  const url = `${COMMON_URL}/v1/e-kyc/get_nominee_data`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchTechExcelDataApi = (params) => {
  const url = `${COMMON_URL}/v1/e-kyc/get_data`;
  return commonApiRequest("GET", url, null, params);
};

export const exportKycCsvFileApi = (params) => {
  const url = `${COMMON_URL}/v1/e-kyc/get_data`;
  return commonApiRequest("GET", url, null, params);
};

export const updateClientCodeTechExcelApi = (params) => {
  const url = `${COMMON_URL}/v1/e-kyc/update-client-code`;
  return commonApiRequest("GET", url, null, params);
};

export const handleFetchEsignPdfApi = (params) => {
  const url = `${COMMON_URL}/v1/e-kyc/get-esign-pdf`;
  return commonApiRequest("GET", url, null, params, "blob");
};

export const handleStatusPanNOApi = (payload) => {
  const url = `${COMMON_URL}/v1/e-kyc/manage-application`;
  return commonApiRequest("POST", url, payload);
};

export const FetchApplicationStatusDataApi = (body, params) => {
  const url = `${COMMON_URL}/v1/user/monthly-report`;
  return commonApiRequest("POST", url, body, params);
};

export const FetchIntroducerCodeApi = (params) => {
  const url = `${COMMON_URL}/v1/introducer-code-data`;
  return commonApiRequest("GET", url, null, params);
};

export const AddIntroducerCodeApi = (params) => {
  const url = `${COMMON_URL}/v1/add-introducer-code-data`;
  return commonApiRequest("POST", url, null, params);
};

export const ChangeAppStatusApi = (params) => {
  const url = `${COMMON_URL}/v1/ekyc-application-status`;
  return commonApiRequest("PUT", url, null, params);
};

export const FetchNonKraPanApi = () => {
  const url = `${COMMON_URL}/v1/non-kra-pan`;
  return commonApiRequest("GET", url);
};

export const AddNonKraPanApi = (params) => {
  const url = `${COMMON_URL}/v1/add-non-kra-pan`;
  return commonApiRequest("POST", url, null, params);
};

export const handleDeletePanApi = (params) => {
  const url = `${COMMON_URL}/v1/delete-pan-data`;
  return commonApiRequest("DELETE", url, null, params);
};
export const handlePanNameChecksApi = (params) => {
  const url = `${COMMON_URL}/v1/pan-name-check`;
  return commonApiRequest("GET", url, null, params);
};

export const handleUpdateApplicationDataApi = (params, body) => {
  const url = `${COMMON_URL}/v1/update-application-data`;
  return commonApiRequest("PUT", url, body, params);
};

export const FetchReopenDataApi = () => {
  const url = `${COMMON_URL}/v1/texchexcel-reopen-data`;
  return commonApiRequest("GET", url);
};

export const AddReopenPanApi = (params) => {
  const url = `${COMMON_URL}/v1/texchexcel-reopen-data`;
  return commonApiRequest("POST", url, null, params);
};

export const handleFetchPanNameApi = (params) => {
  const url = `${COMMON_URL}/v1/get-pan-name`;
  return commonApiRequest("GET", url, null, params);
};

export const handleFetchPanReportApi = () => {
  const url = `${COMMON_URL}/v1/get-pan-delete-report`;
  return commonApiRequest("GET", url);
};

export const handleUploadPhysicalDocumentsApi = (formData, params) => {
  const url = `${COMMON_URL}/v1/physical-kyc/upload-physical-kyc-documents`;
  return commonApiRequest("POST", url, formData, params);
};

export const handleFetchPhysicalDocumentsApi = (params) => {
  const url = `${COMMON_URL}/v1/physical-kyc/get-physical-kyc-documents`;
  return commonApiRequest("GET", url, null, params);
};

// reports
export const fetchEkycCompletedLogsApi = (params) => {
  const url = `${COMMON_URL}/v1/user/ekyc-log-details`;
  return commonApiRequest("GET", url, null, params);
};
export const handleFetchIpvLogsApi = () => {
  const url = `${COMMON_URL}/v1/get-ipv-logs`;
  return commonApiRequest("GET", url, null, null);
};
export const handleFetchPennyGeoLogsApi = (params) => {
  const url = `${COMMON_URL}/v1/user/penny-geolocation-logs`;
  return commonApiRequest("GET", url, null, params);
};

//-------------- kyc connect credentials --------------------
export const fetchConnectCrentialsDataApi = (params) => {
  const url = `${KYC_MODIFICATION_URL}/v1/kyc/login_credential`;
  return commonApiRequest("GET", url, null, params);
};

// ----------------- kyc modification ----------------------
export const fetchModificationDataApi = (params) => {
  const url = `${KYC_MODIFICATION_URL}/v1/Modification/get_data`;
  return commonApiRequest("GET", url, null, params);
};
export const fetcData = (params) => {
  const url = `http://192.168.119.183:1818/v1/kyc/dormate_client_list`;
  return commonApiRequest("GET", url, null, params);
};

export const addMailService = (params, body) => {
  const url = `http://192.168.119.183:1818/v1/kyc/dormate_client_list_sent_mail`;
  return commonApiRequest("POST", url, body, params);
};

export const exportCsvFileApi = (params) => {
  const url = `${KYC_MODIFICATION_URL}/v1/Modification/get_reports`;
  return commonApiRequest("GET", url, null, params, "blob");
};

export const approveModificationApi = (body, params) => {
  const url = `${KYC_MODIFICATION_URL}/v1/Modification/approve_reject`;
  return commonApiRequest("POST", url, body, params);
};

export const rejectModificationApi = (body, params) => {
  const url = `${KYC_MODIFICATION_URL}/v1/Modification/approve_reject`;
  return commonApiRequest("POST", url, body, params);
};


export const esignFileDownloadApi = (params) => {
  const url = `${KYC_MODIFICATION_URL}/v1/Modification/get_pdf`;
  return commonApiRequest("GET", url, null, params);
};

// -------------- kyc Closure --------------------------------------
export const fetchClosureDataApi = (params) => {
  const url = `${KYC_MODIFICATION_URL}/v1/Modification/get_data`;
  return commonApiRequest("GET", url, null, params);
};

export const approveRejectClosureApi = (body, params) => {
  const url = `${KYC_MODIFICATION_URL}/v1/acc-clouser/approve-reject`;
  return commonApiRequest("POST", url, body, params);
};

// ----------------- kyc KRA ----------------------

export const fetchKraTableDataApi = async (params) => {
  const url = `${COMMON_URL}/v1/e-kyc/get_data`;
  return commonApiRequest("GET", url, null, params);
};

export const handleFetchEsignXmlApi = async (pan_no) => {
  const config = {
    headers: getHeaders,
    responseType: "blob",
    params: {
      pan_no: pan_no,
      xml: true,
    },
  };
  const url = `${COMMON_URL}/v1/e-kyc/get-esign-pdf`;
  const response = await axios.get(url, config);
  return response;
};
export const handleFetchEsignPdfKraApi = async (pan_no) => {
  const config = {
    headers: getHeaders,
    responseType: "blob",
    params: {
      pan_no: pan_no,
    },
  };
  const url = `${COMMON_URL}/v1/e-kyc/get-esign-pdf`;
  const response = await axios.get(url, config);
  return response;
};

export const kraDataPushApiApi = (params) => {
  const url = `${COMMON_URL}/v1/kra-push`;
  return commonApiRequest("GET", url, null, params);
};

//----------------------- RE KYC --------------------------------

export const fetchRekycDataApi = async (params) => {
  const url = `${REKYC_URL}/v1/re-kyc/kyc-get-data`;
  return commonApiRequest("GET", url, null, params);
};

export const fetchReKycDetailsByClientCodeApi = async (params) => {
  const url = `${REKYC_URL}/v1/re-kyc/updated-details`;
  return commonApiRequest("GET", url, null, params);
};

export const handleFetchRekycEsignPdfApi = (params) => {
  const url = `${REKYC_URL}/v1/re-kyc/kyc-pdf`;
  return commonApiRequest("GET", url, null, params, "blob");
};
export const handleFetchRekycApprovalApi = (body, params) => {
  const url = `${REKYC_URL}/v1/re-kyc/kyc-approval`;
  return commonApiRequest("POST", url, body, params);
};
export const handleTradingUpdateApi = (body, params) => {
  const url = `${REKYC_URL}/v1/re-kyc/kyc-api-push`;
  return commonApiRequest("POST", url, body, params);
};

export const onlineDormatApi = (params) => {
  const url = `http://192.168.119.183:1818/v1/kyc/dormate_client_list`;
  return commonApiRequest("GET", url, null, params);
};


