import React, { createContext, useState } from "react";
import { toast } from "react-toastify";

export const DashBoardContext = createContext();

export default function DashBoardApis(props) {
  const [ticketAndTaskData, setTicketAndTaskData] = useState([]);

  const fetchTicketAndTaskData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_HELPDESK}/helpdesk/v1/get-pending-task-ticket-details`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken,
            accept: "application/json"
          }
        }
      );

      const resJson = await response.json();
      if (resJson.status === "success") {
        // console.log("m,.hbs", resJson.data);
        setTicketAndTaskData(resJson.data);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      // console.log("ERROR", error);
      toast.error(error.message);
    }
  };
  return (
    <DashBoardContext.Provider
      value={{
        fetchTicketAndTaskData,
        ticketAndTaskData
      }}
    >
      {props.children}
    </DashBoardContext.Provider>
  );
}
