import { FILE } from "config";

const EPledgeFeatures = [
  {
    app_id: 10,
    feature_id: 1001,
    feature_name: "epledge-haircut-fileupload",
    type: FILE,
    subfolder: [],
    label: "Haircut File Upload",
    path: "/epledge/haircut-fileupload",
  },
];

export default EPledgeFeatures;
