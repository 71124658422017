/*eslint-disable*/
import { commonApiRequest } from "utils/CommonFunctions";

const baseURL = process.env.REACT_APP_URL_BLOG;

export const fetchpdf = () => {
  const url = `${baseURL}/arham_web/v1/fetch-all-identifiers/`;
  return commonApiRequest("GET", url);
};

export const downloadPdf = (file_identifier) => {
  const url = `${baseURL}/arham_web/v1/fetch-pdf-by-identifier/?file_identifier=${file_identifier}`;
  return commonApiRequest("GET", url, null, null, "blob");
};

export const handlepdfAdd = (formData) => {
  const url = `${baseURL}/arham_web/v1/upload-pdf/`;
  return commonApiRequest("POST", url, formData);
};

export const handlePdfUpdate = (formData) => {
  const url = `${baseURL}/arham_web/v1/update-pdfs`;
  return commonApiRequest("PUT", url, formData);
};

export const handlePdfDelete = (params) => {
  const url = `${baseURL}/arham_web/v1/delete-pdfs/`;
  return commonApiRequest("DELETE", url, null, params);
};

//Magazine

export const handleMagazineAdd = (formData) => {
  const url = `${baseURL}/arham_web/v1/upload-magazines/`;
  return commonApiRequest("POST", url, formData);
};

export const handleMagazineUpdate = (formData, params) => {
  const url = `${baseURL}/arham_web/v1/update-magazines/`;
  return commonApiRequest("PUT", url, formData, params);
};
export const fetchMagazine = () => {
  const url = `${baseURL}/arham_web/v1/fetch-magazines/`;
  return commonApiRequest("GET", url);
};
export const handleMagazineDelete = (params) => {
  const url = `${baseURL}/arham_web/v1/delete-magazines/`;
  return commonApiRequest("DELETE", url, null, params);
};
export const downloadMagazine = (magazine_name) => {
  const url = `${baseURL}/arham_web/v1/download-magazines/?magazine_name=${magazine_name}`;
  return commonApiRequest("GET", url, null, null, "blob");
};
