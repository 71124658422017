/*eslint-disable*/
import React, { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import {
  addBudgetEvent,
  addEventService,
  approveRejectEvent,
  fetchApprovedEventsService,
  fetchBudgetEvents,
  fetchEvent,
  fetchEventListService,
  fetchEventUserService,
  fetchPendingEventsService,
  updateBudgetEvent,
  updateEventService,
} from "services/event/eventService";

export const EventContext = createContext();

export default function EventApis(props) {
  const [eventData, setEventdata] = useState([]);
  const [eventBudgetData, setEventBudgetdata] = useState([]);
  const [eventPendingData, setPendingdata] = useState([]);
  const [eventUserData, setEventUserData] = useState([]);
  const [eventName, setEventName] = useState([]);

  //new-event
  const loadEvents = async () => {
    try {
      const resJson = await fetchEvent();
      if (resJson.status === "success") {
        setEventdata(resJson.data);
      } else {
        toast(resJson.message);
      }
    } catch (err) {
      toast.error("An error occurred while fetching events");
    }
  };

  const handleEventAdd = async (
    values,
    others,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      const resJson = await addEventService(values);
      if (resJson.status == "success") {
        setSubmitting(true);
        resetForm({ values: "" });
        // others.setOpen(false);
        toast.success(resJson.message);
        loadEvents();
      } else {
        setErrors({ submit: resJson.message });
        setSubmitting(false);
        toast.error(resJson.message);
      }
    } catch (err) {
      setErrors({ submit: "An error occurred while Add Event" });
      setSubmitting(false);
      toast.error("An error occurred while Add Event");
    } finally {
      others.setOpen(false);
    }
  };

  const handleEventUpdate = async (
    values,
    others,
    event_id,
    { setErrors, setSubmitting, resetForm }
  ) => {

    try {
      const resJson = await updateEventService(values, event_id);

      if (resJson.status == "success") {
        setSubmitting(true);
        resetForm({ values: "" });
        // others.setOpen(false);
        toast.success(resJson.data.message);
        loadEvents();
      } else {
        setErrors({ submit: resJson.message });
        setSubmitting(false);
        toast.error(resJson.data.message);
      }
    } catch (err) {
      setErrors({ submit: "An error occurred while Update Event" });
      setSubmitting(false);
      toast.error("An error occurred while Update Event");
    } finally {
      others.setOpen(false);
    }
  };

  //Budget-event
  const loadBudgetEvents = async (params) => {
    try {
      const resJson = await fetchBudgetEvents(params);
      if (resJson.status === 200) {
        setEventdata(resJson.data.data);
      } else {
        toast(resJson.data.message || "Failed to fetch data");
      }
    } catch (err) {
      toast.error("An error occurred while fetching budget events");
    }
  };

  const fetchApprovedEvents = async () => {
    try {
      const resJson = await fetchApprovedEventsService();
      if (resJson.status === 200) {
        setEventBudgetdata(resJson.data.data);
      } else {
        toast(resJson.message);
      }
    } catch (err) {
      toast.error("An error occurred while fetching approved events");
    }
  };

  const fetchPendingEvents = async () => {
    try {
      const resJson = await fetchPendingEventsService();
      if (resJson.status === 200) {
        setPendingdata(resJson.data.data);
      } else {
        toast(resJson.message);
      }
    } catch (err) {
      toast.error("An error occurred while fetching pending events");
    }
  };

  // const formObject = (values) => {
  //   var formData = new FormData();
  //   formData.append("event", values.event);
  //   formData.append("date", values.date);
  //   formData.append("start_date", values.start_date);
  //   formData.append("end_date", values.end_date);
  //   formData.append("description", values.description);
  //   formData.append("location", values.location);
  //   values.event_image != null &&
  //     formData.append("event_image", values.event_image);
  //   formData.append("theme", values.theme);
  //   return formData;
  // };

  // const formEventObject = (values) => {
  //   var formData = new FormData();
  //   // formData.append("entry_id", id);
  //   formData.append("reason", values.reason);
  //   formData.append("status", values.status);
  //   return formData;
  // };

  const handleBudgetEventAdd = async (
    values,
    status,
    setOpen,

    { showVenueExpense, showHotelExpense, showfoodExpense }
  ) => {
    const eventData = {
      event_id: values.event_id || 0,
      total_budget: values.total_budget || 0,
      approval_from: values.approval_from || "",
      message: values.message || "",
      what_we_are_doing: values.what_we_are_doing || "",
      print_cost: values.print_cost || 0,
      print_cost_desc: values.print_cost_desc || "",
      led_plasma_projector_cost: values.led_plasma_projector_cost || 0,
      led_plasma_projector_desc: values.led_plasma_projector_desc || "",
      advertising_cost: values.advertising_cost || 0,
      anchoring_cost: values.anchoring_cost || 0,
      speaker_gifting_cost: values.speaker_gifting_cost || 0,
      goodies_cost: values.goodies_cost || 0,
      decoration_cost: values.decoration_cost || 0,
      transportation_cost: values.transportation_cost || 0,
      sound_system_cost: values.sound_system_cost || 0,
      approximate_budget: values.approximate_budget || 0,
      hotel: showHotelExpense ? "yes" : "no",
      venue: showVenueExpense ? "yes" : "no",
      food: showfoodExpense ? "yes" : "no",
      hotel_stay_expense: values.hotel_stay_expense || 0,
      venue_place_name: values.venue_place_name || "",
      venue_address: values.venue_address || "",
      venue_capacity: values.venue_capacity || 0,
      min_person: values.min_person || 0,
      food_rate: values.food_rate || 0,
      food_total: values.food_total || 0,
    };

    try {
      const resJson = await addBudgetEvent(eventData);
      if (resJson.status === 201) {
        toast.success(resJson.data.message);
        loadBudgetEvents(status);
        setOpen(false);
      }
    } catch (error) {
      toast.error("An error occurred while Adding Lead");
    }
  };

  const handleBudgetEventUpdate = async (
    values,
    setOpen,
    status = "all",

    { showVenueExpense, showHotelExpense, showfoodExpense }
  ) => {
    const eventData = {
      budget_id: values.budget_id || 0,
      event_id: values.event_id || 0,
      total_budget: values.total_budget || 0,
      approval_from: values.approval_from,
      message: values.message,
      what_we_are_doing: values.what_we_are_doing,
      print_cost: values.print_cost || 0,
      print_cost_desc: values.print_cost_desc,
      led_plasma_projector_cost: values.led_plasma_projector_cost || 0,
      led_plasma_projector_desc: values.led_plasma_projector_desc,
      advertising_cost: values.advertising_cost || 0,
      anchoring_cost: values.anchoring_cost || 0,
      speaker_gifting_cost: values.speaker_gifting_cost || 0,
      goodies_cost: values.goodies_cost || 0,
      decoration_cost: values.decoration_cost || 0,
      transportation_cost: values.transportation_cost || 0,
      sound_system_cost: values.sound_system_cost || 0,
      approximate_budget: values.approximate_budget || 0,
      hotel: showHotelExpense ? "yes" : "no",
      venue: showVenueExpense ? "yes" : "no",
      food: showfoodExpense ? "yes" : "no",
      hotel_stay_expense: values.hotel_stay_expense || 0,
      venue_place_name: values.venue_place_name,
      venue_address: values.venue_address,
      venue_capacity: values.venue_capacity || 0,
      min_person: values.min_person || 0,
      food_rate: values.food_rate || 0,
      food_total: values.food_total || 0,
    };
    try {
      const resJson = await updateBudgetEvent(eventData);
      if (resJson.status === 201) {
        toast.success(resJson.data.message);
        loadBudgetEvents(status);
        setOpen(false);
      }
    } catch (error) {
      toast.error("An error occurred while updating lead");
    }
  };

  const handleApproveRejectStatus = async (
    event_id,
    budget_id,
    reason = "",
    action,
    status = "all",
    setOpenDailogStatus = () => { }
  ) => {
    try {
      const response = await approveRejectEvent({
        event_id,
        budget_id,
        reason,
        action,
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchBudgetEvents(status);
        setOpenDailogStatus(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  //Report

  const handleFetchEventUser = async () => {
    try {
      const response = await fetchEventUserService();
      const resJson = response.data
      if (resJson.status === "success") {
        setEventUserData(
          resJson.data.map((item, index) => ({ ...item, id: index + 1 }))
        );
        toast.success(resJson.data.message);
      } else {
        toast.error(resJson.data.message);
      }
    } catch (err) {
      toast.error("An error occurred while fetching event users");
    }
  };

  const handleFetchEventList = async () => {
    try {
      const resJson = await fetchEventListService();
      if (resJson.status === 200) {
        setEventName(resJson.data.data);
      } else {
        toast.error(resJson.data.message);
      }
    } catch (err) {
      toast.error("An error occurred while fetching event list");
    }
  };

  return (
    <EventContext.Provider
      value={{
        handleEventAdd,
        loadEvents,
        loadBudgetEvents,
        eventData,
        handleEventUpdate,
        handleFetchEventUser,
        eventUserData,
        handleBudgetEventAdd,
        handleFetchEventList,
        eventName,
        eventBudgetData,
        eventPendingData,
        setEventUserData,
        handleBudgetEventUpdate,
        fetchApprovedEvents,
        fetchPendingEvents,
        handleApproveRejectStatus,
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
}
