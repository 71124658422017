/*eslint-disable*/
import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import FeaturesRequired from "utils/FeaturesRequired";

const ProRmsIndex = Loadable(lazy(() => import("views/utilities/pro-rms")));

const ProRmsHome = Loadable(lazy(() => import("views/utilities/pro-rms/dashboard")));
const ProRmsUtils = Loadable(lazy(() => import("views/utilities/pro-rms/utils")));
const ProRmsReports = Loadable(lazy(() => import("views/utilities/pro-rms/reports")));

const ProRmsClientSummary = Loadable(lazy(() => import("views/utilities/pro-rms/utils/clientSummary")));
const ProRmsTerminalSummary = Loadable(lazy(() => import("views/utilities/pro-rms/utils/terminalSummary")));

//############################## feature auth Check ##############################//

const featureCheck = (component, feature) => (
  <FeaturesRequired feature={feature}>{component}</FeaturesRequired>
);

export const proRmsModule = {
  path: "pro-rms",
  element: <ProRmsIndex />,
  children: [
    {
      path: "home",
      element: <ProRmsHome />,
    },
    {
      path: "utils",
      element: <ProRmsUtils />,
    },
    {
      path: "reports",
      element: <ProRmsReports />,
    },
  ],
};

export const proRmsUtilsRoutes = {
  path: "pro-rms",
  children: [
    {
      path: "client-summary",
      element: featureCheck(<ProRmsClientSummary />, "client-summary"),
    },
    {
      path: "terminal-summary",
      element: featureCheck(<ProRmsTerminalSummary />, "terminal-summary"),
    },
  ],
};