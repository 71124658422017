/*eslint-disable*/
import React from "react";

import { UtilitesCheck } from "utils/UtilitesCheck";
import CommonReportsIndex from "views/utilities/common-components/CommonReportsIndex";
import BounceMailReportData from "config-details/reports/BounceMailReportData";

const BounceReports = () => {
  const accessibleReports = UtilitesCheck(BounceMailReportData, "reports")
  return (
    <CommonReportsIndex allReports={BounceMailReportData} />
  );
};

export default BounceReports;