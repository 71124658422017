/*eslint-disable*/
import React, { useState, createContext } from "react";

import { toast } from "react-toastify";
import _ from "lodash";

import { useErrorHandler } from "hooks/useErrorHandler";
import {
  addTemplatesApi,
  deleteTemplatesApi,
  getAllTemplatesApi,
  getTemplateByIDApi,
  sendMailsApi,
  updateTemplatesApi,
  fetchMyListTabData,
  crudMyListTabData,
  fetchMyAddressBookData,
  crudMyAddressBookData,
  fetchUserBook,
  fetchVariables,
  addVariables,
  getMailLogs,
  getMailLogsByID,
} from "services/bulk-mail/BulkMailServices";
import {
  addGroupApi,
  deleteGroupApi,
  getAllClientApi,
  getAllEmployeeApi,
  getAllGroupsApi,
  getGroupByIDApi,
  removeUserApi,
  updateGroupApi,
} from "services/bulk-mail/GroupServices";

export const BulkMailContext = createContext();

export default function BulkMailApis(props) {
  const [selectedFeature, setSelectedFeature] = useState(0);

  const [mailLogs, setMailLogs] = useState([]);
  const [logsLoader, setLogsLoader] = useState(false);
  const [openLog, setOpenLog] = useState(false);
  const [selectedMailLogData, setSelectedMailLogData] = useState(null);

  const [templates, setTemplates] = useState([]);
  const [variables, setVariables] = useState([]);
  const [variablesLoader, setVariablesLoader] = useState(false);
  const [template, setTemplate] = useState({});

  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState({});

  const [currentLayoutTab, setCurrentLayoutTab] = useState("commonMails");

  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [loading, setLoading] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [openGroupDialog, setOpenGroupDialog] = useState(false);
  const [openVariableDialog, setOpenVariableDialog] = useState(false);

  const [myListData, setMyListData] = useState([]);
  const [addressBook, setAddressBook] = useState([]);
  const [addressBookUsers, setAddressBookUsers] = useState([]);
  const [groupUsers, setGroupUsers] = useState([]);

  const [userBook, setUserBook] = useState([]);
  const [isBookLoading, setIsBookLoading] = useState(false);

  const [sendMailLoader, setSendMailLoader] = useState(false);

  const [templateFormStatus, setTemplateFormStatus] = useState("add");

  const handleError = useErrorHandler();

  const handleTemplateFormOpen = (value) => {
    setOpenTemplateDialog(true);
  };

  const handleTemplateFormClose = () => {
    setTemplate({});
    setOpenTemplateDialog(false);
  };

  const handleGroupFormOpen = (value) => {
    setOpenGroupDialog(true);
  };

  const handleGroupFormClose = () => {
    setOpenGroupDialog(false);
  };

  const handleVariableFormOpen = (value) => {
    setOpenVariableDialog(true);
  };

  const handleVariableFormClose = () => {
    setOpenVariableDialog(false);
  };

  const handleMailLogsOpen = (row) => {
    setSelectedMailLogData(row);
    handleGetMailLog(row.id);
    setOpenLog(true);
  };

  const handleMailLogsClose = () => {
    setSelectedMailLogData(null);
    setOpenLog(false);
  };

  const handleGetMailLogs = async () => {
    setLogsLoader(true);
    setMailLogs([]);
    try {
      const response = await getMailLogs();
      if (response.status === 200) {
        setMailLogs(response.data.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLogsLoader(false);
    }
  };

  const handleGetMailLog = async (id) => {
    setLoading(true);
    setSelectedMailLogData([]);
    const params = {
      id: id,
    }
    try {
      const response = await getMailLogsByID(params);
      if (response.status === 200) {
        setSelectedMailLogData(response.data.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendMails = async (values) => {
    const formData = new FormData();
    setSendMailLoader(true);

    try {
      formData.append("templateId", values.templateId);
      formData.append("sendingOption", values.sendingOption);
      const otherUsers =
        values.otherUsers && values.otherUsers.length > 0
          ? values.otherUsers
          : [];
      formData.append("otherUsers", JSON.stringify(otherUsers));
      formData.append("isForcedMail", values.isForcedMail);
      formData.append("from", values.from);

      if (values.groupId) {
        formData.append("groupId", values.groupId);
      }

      if (values.userType) {
        formData.append("userType", values.userType);
      }

      if (values.csvType) {
        formData.append("csvType", values.csvType);
      }

      if (values.csvFile) {
        formData.append("csvFile", values.csvFile);
      }

      if (values.attachmentFile) {
        formData.append("attachmentFile", values.attachmentFile);
      }

      const excludedUsers =
        values.groupExcludedUsers && values.groupExcludedUsers.length > 0
          ? values.groupExcludedUsers
          : [];
      formData.append("groupExcludedUsers", JSON.stringify(excludedUsers));

      const response = await sendMailsApi(formData);
      if (response.status === 200) {
        toast.success(response.data.message);
        console.log(response.data);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      setSendMailLoader(false);
    }
  };

  const handleAddTemplate = async (selectedIcon, values) => {
    console.log("val", values);

    const formData = new FormData();
    try {
      const htmlFile = new Blob([values.content], { type: "text/html" });

      formData.append("icon", selectedIcon);
      formData.append("name", values.templateName);
      formData.append("subject", values.subject);
      formData.append("htmlFile", htmlFile, `${values.templateName}.html`);
      formData.append("variables", JSON.stringify(values.variables));
      formData.append("isDefaultTemplate", values.isDefaultTemplate);
      formData.append("templateType", values.templateType);
      formData.append("defaultContent", values.defaultContent);

      const response = await addTemplatesApi(formData);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleTemplateFormClose();
        handleGetTemplates();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleUpdateTemplate = async (templateId, selectedIcon, values) => {
    console.log("val", values);
    const formData = new FormData();
    try {
      const htmlFile = new Blob([values.content], { type: "text/html" });

      formData.append("icon", selectedIcon);
      formData.append("name", values.templateName);
      formData.append("subject", values.subject);
      formData.append("htmlFile", htmlFile, `${values.templateName}.html`);
      formData.append("variables", JSON.stringify(values.variables));
      formData.append("isDefaultTemplate", values.isDefaultTemplate);
      formData.append("templateType", values.templateType);
      formData.append("defaultContent", values.defaultContent);

      console.log("formData", formData);

      const response = await updateTemplatesApi(templateId, formData);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleTemplateFormClose();
        handleGetTemplates();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      const response = await deleteTemplatesApi(templateId);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetTemplates();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleGetTemplates = async () => {
    setLoading(true);

    setTemplates([]);
    try {
      const response = await getAllTemplatesApi();
      if (response.status === 200) {
        setTemplates(response.data.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetTemplateById = async (templateId) => {
    setTemplateLoading(true);
    setTemplate({});
    try {
      const response = await getTemplateByIDApi(templateId);
      if (response?.status === 200) {
        setTemplate(response.data.data);
        return response.data.data;
      }
    } catch (error) {
      handleError(error);
    } finally {
      setTemplateLoading(false);
    }
  };

  const handleGetClients = _.debounce(async (query) => {
    setLoading(true);
    setClients([]);
    try {
      const response = await getAllClientApi(query);
      if (response.status === 200) {
        setClients(response.data.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, 500);

  const handleGetEmployees = async () => {
    setLoading(true);
    setEmployees([]);
    try {
      const response = await getAllEmployeeApi();
      if (response.status === 200) {
        setEmployees(response.data.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddGroup = async (values) => {
    try {
      const payload = {
        name: values.name,
        users: values.users,
      };

      const response = await addGroupApi(payload);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleGroupFormClose();
        handleGetGroups();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleUpdateGroup = async (groupId, values) => {
    try {
      const payload = {
        name: values.name,
        users: values.users,
      };

      const response = await updateGroupApi(groupId, payload);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGroupFormClose();
        handleGetGroups();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeleteGroup = async (groupId) => {
    try {
      const response = await deleteGroupApi(groupId);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleGetGroups();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleRemoveUser = async (userId) => {
    try {
      const response = await removeUserApi(userId);
      if (response.status === 200) {
        toast.success(response.data.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleGetGroups = async () => {
    setLoading(true);
    setGroups([]);
    try {
      const response = await getAllGroupsApi();
      if (response.status === 200) {
        setGroups(response.data.data.groups);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetGroupById = async (groupId) => {
    setGroupLoading(true);
    setGroup({});
    try {
      const response = await getGroupByIDApi(groupId);
      if (response?.status === 200) {
        setGroup(response.data.data);
        return response.data.data;
      }
    } catch (error) {
      handleError(error);
    } finally {
      setGroupLoading(false);
    }
  };

  const handleFetchMyListTabData = async () => {
    setIsBookLoading(true);
    setMyListData([]);

    try {
      const response = await fetchMyListTabData();
      if (response?.status === 200) {
        const tempmDataWithId = response.data.data.map((item, index) => {
          return { ...item, sr_no: index + 1 };
        });
        setMyListData(tempmDataWithId);
        return tempmDataWithId;
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsBookLoading(false);
    }
  };
  const handleCrudMyListTabData = async (values, handleClose, status) => {
    const body = {
      name: values.name,
      email: values.email,
      mobileNumber: values.mobileNumber,
      description: values.description,
      companyCode: values.companyCode,
      department: values.department,
      role: values.role,
    };

    try {
      const response = await crudMyListTabData(body, status, values?.id);
      if (response?.status === 201 || response?.status === 200) {
        handleFetchMyListTabData();
        handleClose();
        toast.success(response.data.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleFetchMyAddressBookData = async () => {
    setIsBookLoading(true);
    setAddressBook([]);
    try {
      const response = await fetchMyAddressBookData();
      if (response?.status === 200) {
        const tempmDataWithId = response.data?.data?.map((item, index) => {
          return { ...item, sr_no: index + 1 };
        });
        setAddressBook(tempmDataWithId);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsBookLoading(false);
    }
  };

  const handleCrudMyAddressBookData = async (values, handleClose, status) => {
    const body = {
      name: values.name,
      email: values.email,
      mobileNumber: values.mobileNumber,
      category: values.category,
      city: values.city,
      departmentName: values.department,
      branch: values.role,
    };

    try {
      const response = await crudMyAddressBookData(body, status, values?.id);
      if (response?.status === 201 || response?.status === 200) {
        handleFetchMyAddressBookData();
        handleClose();
        toast.success(response.data.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleFetchUserBook = async () => {
    setIsBookLoading(true);
    setUserBook([]);
    try {
      const response = await fetchUserBook();
      if (response?.status === 200) {
        setUserBook(response.data.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsBookLoading(false);
    }
  };

  const handleFetchVariables = async () => {
    setVariablesLoader(true);
    setVariables([]);
    try {
      const response = await fetchVariables();
      if (response?.status === 200) {
        setVariables(response.data.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setVariablesLoader(false);
    }
  };

  const handleAddVariables = async (values) => {
    setVariablesLoader(true);
    try {
      const payload = {
        name: values,
      };

      const response = await addVariables(payload);
      if (response?.status === 201) {
        toast.success(response.data.message);
        handleVariableFormClose();
        handleFetchVariables();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setVariablesLoader(false);
    }
  };

  return (
    <BulkMailContext.Provider
      value={{
        template,
        templates,

        group,
        groups,

        setTemplate,
        setGroup,

        templateLoading,
        groupLoading,
        loading,

        selectedFeature,
        setSelectedFeature,

        openTemplateDialog,
        handleTemplateFormOpen,
        handleTemplateFormClose,

        openGroupDialog,
        handleGroupFormOpen,
        handleGroupFormClose,

        openVariableDialog,
        handleVariableFormOpen,
        handleVariableFormClose,

        handleAddTemplate,
        handleUpdateTemplate,
        handleDeleteTemplate,
        handleGetTemplates,
        handleGetTemplateById,

        handleAddGroup,
        handleUpdateGroup,
        handleDeleteGroup,
        handleGetGroups,
        handleGetGroupById,
        handleRemoveUser,

        clients,
        employees,

        handleGetClients,
        handleGetEmployees,

        currentLayoutTab,
        setCurrentLayoutTab,

        handleSendMails,

        userBook,
        myListData,
        setMyListData,
        addressBook,
        isBookLoading,
        variables,
        variablesLoader,
        handleCrudMyListTabData,
        handleFetchMyListTabData,
        handleCrudMyAddressBookData,
        handleFetchMyAddressBookData,
        handleFetchUserBook,

        handleFetchVariables,
        handleAddVariables,

        mailLogs,
        logsLoader,
        handleGetMailLogs,
        handleGetMailLog,

        handleMailLogsOpen,
        handleMailLogsClose,
        openLog,
        selectedMailLogData,

        sendMailLoader,

        addressBookUsers,
        setAddressBookUsers,

        groupUsers,
        setGroupUsers,

        templateFormStatus,
        setTemplateFormStatus,
      }}
    >
      {props.children}
    </BulkMailContext.Provider>
  );
}
