/*eslint-disable*/
import React from "react";

import EventFeatures from "config-details/features/EventFeatures";
import CommonUtilsIndex from "views/utilities/common-components/CommonUtilsIndex";

const EventUtilities = () => {

  return (
    <CommonUtilsIndex allFeatures={EventFeatures} />
  );
};

export default EventUtilities;
