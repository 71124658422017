/*eslint-disable*/
import React, { createContext, useState } from 'react'
import { toast } from 'react-toastify';

import { connectActiveLoginApi, connectAllLoginApi, fetchErpLoginLogsApi } from 'services/reports-services/reportServices';
import { handleCatch } from 'utils/CommonFunctions';

export const ReportsContext = createContext();

export const ReportsApis = (props) => {

    const [erpLoginLogs, setErpLoginLogs] = useState([])
    const [loading, setLoading] = useState(false)


    const fetchErpLoginLogs = async (payload) => {
        setErpLoginLogs([])
        setLoading(true)
        try {
            const params = {
                category: payload === 'today' ? "" : payload
            }
            const response = await fetchErpLoginLogsApi(params);
            const res = response.data.data
            const transformedData = res?.map(item => ({
                ...item,
                is_active: item.is_active ? 'Online' : 'Offline'
            }));
            setErpLoginLogs(transformedData);
        } catch (error) {
            handleCatch(error)
        } finally {
            setLoading(false)
        }
    }

    const [connectActiveLogin, setConnectActiveLogin] = useState()
    const fetchConnectActiveLoginLogs = async () => {
        setLoading(true)
        try {
            const response = await connectActiveLoginApi()
            if(response.data.statusCode === 200){
                setConnectActiveLogin(response.data.data.activeLogins)
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            handleCatch(error)
        } finally {
            setLoading(false)
        }
    }

    const [connectAllLogin, setConnectAllLogin] = useState()
    const fetchConnectAllLoginLogs = async (from_date, to_date) => {
        const params = {
            from_date,
            to_date
        }
        setLoading(true)
        try {
            const response = await connectAllLoginApi(params)
            if(response.data.statusCode === 200){
                setConnectAllLogin(response.data.data.login)
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            handleCatch(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <ReportsContext.Provider
            value={{ 
                loading, 
                erpLoginLogs, 
                setErpLoginLogs, 
                fetchErpLoginLogs, 

                fetchConnectActiveLoginLogs,
                connectActiveLogin,

                fetchConnectAllLoginLogs,
                connectAllLogin
            }}
        >{props.children}</ReportsContext.Provider>
    )
}

