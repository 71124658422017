import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import EDDPIDash from "views/utilities/eddpi/dashboard";
import EDDPIReports from "views/utilities/eddpi/reports";
import EDDPIUtils from "views/utilities/eddpi/utils";

const EDDPIHome = Loadable(
  lazy(() => import("views/utilities/eddpi"))
);
const EDDPIRequest = Loadable(
  lazy(() => import("views/utilities/eddpi/utils/eddpi-request"))
);
const EDDPILog = Loadable(
  lazy(() => import("views/utilities/eddpi/utils/eddpi-log"))
);

export const EDDPIModule = {
  path: "eddpi",
  element: <EDDPIHome />,
  children: [
    {
      path: "home",
      element: <EDDPIDash />,
    },
    {
      path: "utils",
      element: <EDDPIUtils />,
    },
    {
      path: "reports",
      element: <EDDPIReports />,
    },
  ],
};

export const EDDPIRoutes = {
  path: "eddpi",
  children: [
    {
      path: "eddpi-request",
      element: <EDDPIRequest />,
    },
    {
      path: "eddpi-log",
      element: <EDDPILog />,
    },
  ],
};

