/*eslint-disable*/
import React, { useRef, useState, createContext } from "react";

import { toast } from "react-toastify";
import { handleFetchBouncePodsDataApi, handleFetchFileLogsApi, handleFetchSummaryReportApi, handleUpdatePodsApi, handleUploadFilesApi } from "services/bounce-mail/bounceMail";
import { handleCatch } from "utils/CommonFunctions";

export const BounceMailContext = createContext();

export default function BounceMailApis(props) {

    const fileUploadRef = useRef();

    //update PODS
    const imgRef = useRef();
    const [selectedImg, setSelectedImg] = useState(null);

    const [fileLogs, setFileLogs] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [bouncePodsData, setBouncePodsData] = useState([]);

    const [summaryData, setSummaryData] = useState([]);

    const [loading, setLoading] = useState(false);

    //POST - file uploader
    const handleUploadFiles = async (files, exchange) => {
        console.log("payload", files, exchange);
        try {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i]);
            }
            const response = await handleUploadFilesApi(formData);

            console.log("response", response);
            toast.success("Files Uploaded Successfully");
            setSelectedFiles([]);
            fileUploadRef.current.value = "";
            handleFetchFileLogs()
        } catch (error) {
            handleCatch(error);
        }
    }

    //GET uploaded file logs
    const handleFetchFileLogs = async () => {
        setLoading(true);
        try {
            const response = await handleFetchFileLogsApi();
            setFileLogs(response.data.data.map((item, index) => ({ ...item, sr_no: index + 1 })));
        } catch (error) {
            handleCatch(error)
        } finally {
            setLoading(false);
        }
    };

    //GET request bounce pods data
    const handleFetchBouncePodsData = async (payload, sendLogs) => {
        console.log("payload", payload);
        setLoading(true)
        setBouncePodsData([]);
        try {

            const params = {
                from_date: payload.fromDate,
                to_date: payload.toDate,
                is_bounce: sendLogs ? true : false
            };

            const response = await handleFetchBouncePodsDataApi(params);

            setLoading(false);
            console.log("response PODS", response.data.data);

            response.data.data.length == 0
                ?
                toast.error("Data is unavailable.")
                :
                setBouncePodsData(response.data.data.map((item, index) => ({ ...item, sr_no: index + 1 })))

        } catch (error) {
            handleCatch(error)
        } finally {
            setLoading(false);
        }
    };

    //POST - update PODS data
    const handleUpdatePods = async (file, payLoadId, payloadForSecondApi) => {
        console.log("payload", file, payLoadId);
        try {
            const formData = new FormData();
            formData.append("file", file);

            const params = { id: payLoadId };

            const response = await handleUpdatePodsApi(formData, params);

            console.log('response', response)
            toast.success("File Updated Successfully");
            handleFetchBouncePodsData(payloadForSecondApi);
            setSelectedFiles([]);
        } catch (error) {
            handleCatch(error);
        }
    }

    //GET request bounce pods data
    const handleFetchSummaryReport = async (payload) => {
        setLoading(true)
        setSummaryData([])
        try {
            const params = {
                from_date: payload.fromDate,
                to_date: payload.toDate
            };

            const response = await handleFetchSummaryReportApi(params);

            console.log("response summary", response.data.data);

            response.data.data.length == 0
                ?
                toast.error("Data is unavailable.")
                :
                setSummaryData(response.data.data.map((item, index) => ({ ...item, sr_no: index + 1 })))

        } catch (error) {
            handleCatch(error)
            console.error("error", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <BounceMailContext.Provider
            value={{
                handleUploadFiles,
                selectedFiles,
                setSelectedFiles,
                fileUploadRef,
                loading,
                handleFetchBouncePodsData,
                bouncePodsData,
                setBouncePodsData,
                imgRef,
                selectedImg,
                setSelectedImg,
                handleUpdatePods,
                fileLogs,
                handleFetchFileLogs,
                summaryData,
                setSummaryData,
                handleFetchSummaryReport
            }}
        >
            {props.children}
        </BounceMailContext.Provider>
    )
}
