/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Box,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Calendar } from "primereact/calendar";
import { AlgoSummaryContext } from "context/Algo-summary/AlgoSummaryApis";
import Loder from "../../Loder";

export default function Index() {
  const getCurrentMonthFirstDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  };

  const [dates, setDates] = useState([getCurrentMonthFirstDate(), new Date()]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  console.log("selectedManager", selectedManager);

  const formatDateString = (dateStr) => {
    const dateObj = new Date(dateStr);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const { classes, handleHistoryDashboard, historyDashboardData } =
    useContext(AlgoSummaryContext);

  useEffect(() => {
    if (dates[0] && dates[1]) {
      const startDate = formatDateString(dates[0]);
      const endDate = formatDateString(dates[1]);
      handleHistoryDashboard(startDate, endDate);
    }
  }, [dates]);

  const getColor = (value) => {
    return value >= 0 ? "green" : "red";
  };

  const handleClientClick = (event, client) => {
    setSelectedClient(client);
    setSelectedManager(null);
    setAnchorEl(event.currentTarget);
  };

  const handleFundManager = (event, fundManager) => {
    setSelectedClient(null);
    setSelectedManager(fundManager);
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedClient(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "client-popover" : undefined;

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} md={12} sx={{ textAlign: "right" }}>
          <Calendar
            value={dates}
            onChange={(e) => setDates(e.value)}
            selectionMode="range"
            readOnlyInput
            hideOnRangeSelection
            dateFormat="dd-mm-yy"
            style={{ height: "40px" }}
          />
        </Grid>
        {historyDashboardData?.length > 0 ? (
          historyDashboardData.map((data, i) => (
            <Grid item xs={12} md={6} key={i}>
              <Card
                border={true}
                content={false}
                sx={{
                  boxShadow: 2,
                  background: `#FDFDFD`,
                  borderRadius: 3,
                }}
              >
                <CardContent sx={{ py: 1, px: 2 }}>
                  <Grid container alignItems="center" spacing={1}>
                    {/* <Grid
                      item
                      xs={5}
                      onClick={(e) => handleFundManager(e, data.fund_manager)}
                    >
                      <Typography variant="h4" component="div">
                        {data.fund_manager}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography variant="body1">
                          NET MTM:{" "}
                          <span style={{ color: getColor(data.brk) }}>
                            {data.net}
                          </span>
                        </Typography>
                        <Typography variant="body1">
                          Fund:{" "}
                          <span style={{ color: getColor(data.fund_amount) }}>
                            {data.fund_amount}
                          </span>
                        </Typography>
                      </Box>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Typography variant="h4" component="div">
                        {data.fund_manager}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography variant="body1">
                          MTM:{" "}
                          <span style={{ color: getColor(data.mtm) }}>
                            {data.mtm}
                          </span>
                        </Typography>
                        <Typography variant="body1">
                          Brokerage:{" "}
                          <span style={{ color: getColor(data.brk) }}>
                            {data.brk}
                          </span>
                        </Typography>
                        <Typography variant="body1">
                          Fund:{" "}
                          <span style={{ color: getColor(data.fund_amount) }}>
                            {data.fund_amount}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider sx={{ borderColor: "#9e9e9e" }} />
                <TableContainer
                  sx={{ height: "150px", overflowY: "auto" }}
                  className={classes.customScrollbar}
                >
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ py: 1 }}>Client ID</TableCell>
                        <TableCell sx={{ py: 1 }}>NET MTM</TableCell>
                        <TableCell sx={{ py: 1 }}>Fund</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.client_data.map((client, index) => (
                        <TableRow key={index} hover>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              cursor: "pointer",
                              py: 1,
                            }}
                            onClick={(e) => handleClientClick(e, client)}
                          >
                            {client.client_id}
                          </TableCell>
                          <TableCell sx={{ py: 1 }}>
                            <span
                              style={{ color: getColor(client.client_mtm) }}
                            >
                              {client.client_net}
                            </span>
                          </TableCell>
                          <TableCell sx={{ py: 1 }}>
                            <span
                              style={{
                                color: getColor(client.client_fund_amount),
                              }}
                            >
                              {client.client_fund_amount}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              No data found
            </Typography>
          </Grid>
        )}
      </Grid>
      <Loder />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={2} sx={{ backgroundColor: "#fafafa" }}>
          {selectedClient && (
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              gap={2}
            >
              <Typography>
                MTM:
                <span
                  style={{
                    color: getColor(selectedClient.client_mtm),
                  }}
                >
                  {selectedClient.client_mtm}
                </span>
              </Typography>
              <Typography>
                Brokerage:
                <span
                  style={{
                    color: getColor(selectedClient.client_total_brk),
                  }}
                >
                  {selectedClient.client_total_brk}
                </span>
              </Typography>
              <Typography>
                Expense:
                <span
                  style={{
                    color: getColor(selectedClient.client_total_expense),
                  }}
                >
                  {selectedClient.client_total_expense}
                </span>
              </Typography>
              <Typography>
                Net:
                <span
                  style={{
                    color: getColor(selectedClient.client_net),
                  }}
                >
                  {selectedClient.client_net}
                </span>
              </Typography>
            </Box>
          )}
          {selectedManager && (
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              gap={2}
            >
              <Typography>
                Brokerage: <strong>{selectedManager.brk}</strong>
              </Typography>
              <Typography>
                Expance: <strong>{selectedManager.expense}</strong>
              </Typography>
              <Typography>
                MTM: <strong>{selectedManager.mtm}</strong>
              </Typography>
              <Typography>
                NET: <strong>{selectedManager.net}</strong>
              </Typography>
              <Typography>
                Fund: <strong>{selectedManager.fund_amount}</strong>
              </Typography>
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
}
