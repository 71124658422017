import { FILE } from "config";

const BounceFeatures = [
  {
    app_id: 18,
    feature_id: 1801,
    label: "File Uploader",
    type: FILE,
    subfolder: [],
    feature_name: "bounce-file-uploader",
    path: "/bounce-mail/file-uploader"
  },
  {
    app_id: 18,
    feature_id: 1802,
    label: "Update PODS",
    type: FILE,
    subfolder: [],
    feature_name: "bounce-update-pods",
    path: "/bounce-mail/update-pods"
  }
];

export default BounceFeatures;