/* eslint-disable*/
import { useNavigate } from "react-router";

import { Typography, Card, Button, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import GradeIcon from "@mui/icons-material/Grade";

import ScssStyle from "assets/scss/_themes-vars.module.scss";
import { useState } from "react";

const FileCard = ({ card, icon }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = ScssStyle;

  const [file, setFile] = useState(card);

  const handleFavClick = (id) => {
    setFile(item => ({ ...item, isFavourite: !item.isFavourite }))
  };

  return (
    <Card
      sx={{
        width: "100%",
        p: 1,
        backgroundColor: styles.secondary100,
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Button
        size="small"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          px: 2,
          width: "100%",
        }}
        onClick={() => navigate(file.path)}
      >
        <img style={{ paddingRight: "10px", width: "45px" }} src={icon} alt="chat" />
        <Typography
          variant="subtitle1"
          textAlign="start"
          fontSize="15px"
          fontWeight="600"
          color={theme.palette.secondary.dark}
        >
          {file.label}
        </Typography>
      </Button>
      <IconButton
        size="small"
        sx={{ pr: 1 }}
        onClick={() => handleFavClick(file.feature_id)}
      >
        {file.isFavourite ? (
          <GradeIcon color="secondary" />
        ) : (
          <StarOutlineIcon color="primary" />
        )}
      </IconButton>
    </Card>
  );
};

export default FileCard;
