import React from "react";
// import { Grid } from "@mui/material";
// import ArhamDashboard from "./arhamDashboard";

export default function index() {
  return (
    <>
      {/* <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ArhamDashboard /> */}
      
      {/* </Grid>
        </Grid>
      </Grid>
    </Grid> */}
    </>
  );
}
