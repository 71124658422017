import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import FeaturesRequired from "utils/FeaturesRequired";

const IpoHome = Loadable(lazy(() => import("views/utilities/ipo/dashboard")));
const IpoIndex = Loadable(lazy(() => import("views/utilities/ipo/index")));

const SeriesUpload = Loadable(
  lazy(() => import("views/utilities/ipo/utils/series-upload"))
);
const FileUpload = Loadable(
  lazy(() => import("views/utilities/ipo/utils/file-upload"))
);
const Verification = Loadable(
  lazy(() => import("views/utilities/ipo/utils/verification"))
);
const PhysicalPrint = Loadable(
  lazy(() => import("views/utilities/ipo/utils/physical-print"))
);
const OnlineBid = Loadable(
  lazy(() => import("views/utilities/ipo/utils/online-bid"))
);

const IpoUtilities = Loadable(lazy(() => import("views/utilities/ipo/utils")));
const IpoReports = Loadable(
  lazy(() => import("views/utilities/ipo/reports/index"))
);
const VerifySeries = Loadable(
  lazy(() => import("views/utilities/ipo/utils/verification/verify-series"))
);
const VerifyPDF = Loadable(
  lazy(() => import("views/utilities/ipo/utils/verification/verify-pdf"))
);
const ArhamWebIpoData = Loadable(
  lazy(() => import("views/utilities/ipo/utils/arham-web-ipo-data"))
);
const Preferance = Loadable(
  lazy(() => import("views/utilities/ipo/utils/preferance"))
);
const BidReport = Loadable(
  lazy(() => import("views/utilities/ipo/reports/bid"))
);
const AppReport = Loadable(
  lazy(() => import("views/utilities/ipo/reports/app"))
);

const PhysicalPrintReport = Loadable(
  lazy(() => import("views/utilities/ipo/reports/physical-print"))
);

const featureCheck = (component, feature) => (
  <FeaturesRequired feature={feature}>{component}</FeaturesRequired>
);

export const IpoModule = {
  path: "ipo",
  element: <IpoIndex />,
  children: [
    {
      path: "home",
      element: <IpoHome />,
    },
    {
      path: "utils",
      element: <IpoUtilities />,
    },
    {
      path: "reports",
      element: <IpoReports />,
    },
  ],
};

export const IpoReportsRoutes = {
  path: "ipo",
  children: [
    {
      path: "series-upload",
      element: featureCheck(<SeriesUpload />, "ipo-series-upload"),
    },
    {
      path: "file-upload",
      element: featureCheck(<FileUpload />, "ipo-pdf-upload"),
    },
    {
      path: "verification",
      element: <Verification />,
    },
    {
      path: "physical-print",
      element: featureCheck(<PhysicalPrint />, "ipo-physical-print"),
    },
    {
      path: "online-bid",
      element: featureCheck(<OnlineBid />, "ipo-online-bid"),
    },
    {
      path: "preferance",
      element: featureCheck(<Preferance />, "ipo-preference"),
    },
    {
      path: "verify-series",
      element: featureCheck(<VerifySeries />, "ipo-physical-print-individual"),
    },
    {
      path: "verify-pdf",
      element: featureCheck(<VerifyPDF />, "ipo-physical-print-bulk"),
    },
    {
      path: "arham-web-ipo-data",
      element: featureCheck(<ArhamWebIpoData />, "arham-web-ipo-data"),
    },
    {
      path: "bid-report",
      element: <BidReport />,
    },
    {
      path: "app-report",
      element: <AppReport />,
    },
    {
      path: "physical-print-report",
      element: <PhysicalPrintReport />,
    },
  ],
};
