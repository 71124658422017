/*eslint-disable*/
import { commonApiRequest } from "utils/CommonFunctions";

const EVENT_PLANING = process.env.REACT_APP_URL_EVENT_PLANNING_URL;

export const addEvent = (body) => {
  const url = `${EVENT_PLANING}/event/v1/add-event`;
  return commonApiRequest("POST", url, body);
};

export const getEventDetails = (params) => {
  const url = `${EVENT_PLANING}/event/v1/get-event-details?event_status=${params}`;
  return commonApiRequest("GET", url, params);
};

export const updateEvent = (body, params) => {
  const url = `${EVENT_PLANING}/event/v1/update-event`;
  return commonApiRequest("POST", url, body, params);
};

export const getApprovalPersonList = () => {
  const url = `${EVENT_PLANING}/event/v1/get-event-approval-person-list`;
  return commonApiRequest("GET", url);
};

export const approveRejectEvent = (body) => {
  const url = `${EVENT_PLANING}/event/v1/approve-reject-event`;
  return commonApiRequest("POST", url, body);
};
