import React, { createContext, useState } from 'react'
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';

import { fetchRekycDataApi, fetchReKycDetailsByClientCodeApi, handleFetchRekycApprovalApi, handleFetchRekycEsignPdfApi, handleTradingUpdateApi } from 'services/kyc/kycAll';
import { handleCatch } from 'utils/CommonFunctions';

export const RekycContext = createContext();
export const ReKycApis = (props) => {
    const navigate = useNavigate();

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sorting, setSorting] = useState([{ id: "client_code" }]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);

    const [reKycStatus, setReKycStatus] = useState("pending");
    const [rekycData, setRekycData] = useState([]);
    const [rekycDetails, setRekycDetails] = useState({});
    const [pdfUrl, setPdfUrl] = useState("");

    const fetchRekycData = async (status, globalFilter) => {
        setRekycData([]);
        setRowCount([]);
        setIsLoading(true);

        const params = {
            re_kyc_status: status || reKycStatus,
            filter_sorting: sorting.length === 0 ? "client_code" : sorting[0].id,
            client_code: globalFilter || "",
            page_index: pagination.pageIndex + 1,
            page_size: pagination.pageSize,
        };

        try {
            const response = await fetchRekycDataApi(params);
            console.log("rekyc res", response);
            setRekycData(response.data.data.data);
            setRowCount(response.data.data.row_count);
        } catch (error) {
            handleCatch(error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };
 

    const fetchReKycDetailsByClientCode = async (clientCode) => {
        setRekycDetails({})
        setIsLoading(true);

        const params = {
            client_code: clientCode,
        };

        try {
            const response = await fetchReKycDetailsByClientCodeApi(params);
            console.log("rekyc details", response);
            setRekycDetails(response.data.data);
        } catch (error) {
            handleCatch(error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFetchRekycEsignPdf = async (clientCode) => {
        setPdfUrl("")
        try {
            const params = {
                client_code: clientCode
            };

            const response = await handleFetchRekycEsignPdfApi(params);
            console.log('response pdf', response)
            if (response?.status === 200) {
                const pdfBlob = response?.data;
                const url = URL.createObjectURL(pdfBlob);
                setPdfUrl(url);
            } else {
                console.error("Error fetching PDF:", response?.statusText);
            }
        } catch (error) {
            handleCatch(error)
        }
    };

    const handleFetchRekycApproval = async (body, action) => {
        try {
            const params = {
                action: action
            };

            const response = await handleFetchRekycApprovalApi(body, params);

            if (response.status === 200) {
                const res = response.data;
                toast.success(res.message)
                navigate("/kyc/online-rekyc-authorization")
            } else {
                console.error("response", response);
            }
        } catch (error) {
            handleCatch(error)
        }
    };

    const handleTradingUpdate = async (body, action) => {
        try {
            const params = {
                action: action
            };

            const response = await handleTradingUpdateApi(body, params);

            if (response.status === 200) {
                const res = response.data;
                toast.success(res.message);
                fetchRekycData("approved")
            } else {
                console.error("response", response);
            }
        } catch (error) {
            handleCatch(error)
        }
    };

    return (
        <RekycContext.Provider
            value={{
                isError,
                sorting,
                rowCount,
                setSorting,
                pagination,
                setPagination,

                fetchRekycData,
                rekycData,
                reKycStatus,
                setReKycStatus,
                isLoading,

                handleFetchRekycEsignPdf,
                pdfUrl,
                fetchReKycDetailsByClientCode,
                rekycDetails,
                handleFetchRekycApproval,

                handleTradingUpdate
            }}
        >{props.children}</RekycContext.Provider>
    )
}
