import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import ItnfraDash from "views/utilities/it-infra/dashboard/index";
import ItnfraUtils from "views/utilities/it-infra/utils/index";
import ItnfraReports from "views/utilities/it-infra/reports/index";
import FeaturesRequired from "utils/FeaturesRequired";

const ItInfraHome = Loadable(lazy(() => import("views/utilities/it-infra")));

const ItInfraInventory = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/inventory/index"))
);
const ItInfraAquisition = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/Requirement/index"))
);
const ItInfraInvoice = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/invoice"))
);

const ItInfraRequirementApproval = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/requirementApproval"))
);

const ItInfraQuatation = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/quatation"))
);

const ItInfraQuatationApproval = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/quatationApproval"))
);

const ItInfraPurchaseOrder = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/purchaseOrder"))
);

const ItInfraTransaction = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/transaction"))
);

const ItInfraDelivery = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/delivery"))
);

const ItInfraComputerPeripheral = Loadable(
  lazy(() =>
    import("views/utilities/it-infra/utils/inventory/computerperipheral/index")
  )
);
const ItInfraNetworkDevices = Loadable(
  lazy(() =>
    import("views/utilities/it-infra/utils/inventory/networkDevices/index")
  )
);
const ItInfraServer = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/inventory/server/index"))
);
const ItInfraAssignement = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/assignment/index"))
);
const ItInfraVendorAddition = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/Vendor/index"))
);
const ItInfraRequest = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/request/index"))
);
const ItInfraRequestApproval = Loadable(
  lazy(() => import("views/utilities/it-infra/utils/requestApproval/index"))
);

//############################## feature auth Check ##############################//

const featureCheck = (component, feature) => (
  <FeaturesRequired feature={feature}>{component}</FeaturesRequired>
);

export const ItInfraModule = {
  path: "it-infra",
  element: <ItInfraHome />,
  children: [
    {
      path: "home",
      element: <ItnfraDash />,
    },
    {
      path: "utils",
      element: <ItnfraUtils />,
    },
    {
      path: "reports",
      element: <ItnfraReports />,
    },
  ],
};

export const ItInfraRoutes = {
  path: "it-infra",
  children: [
    {
      path: "inventory",
      element: <ItInfraInventory />,
    },
    {
      path: "requirement",
      element: featureCheck(<ItInfraAquisition />, "requirement"),
    },
    {
      path: "requirement-approval",
      element: <ItInfraRequirementApproval />,
    },
    {
      path: "quatation",
      element: <ItInfraQuatation />,
    },
    {
      path: "quatation-approval",
      element: featureCheck(<ItInfraQuatationApproval />, "quotation"),
    },
    {
      path: "purchase-order",
      element: featureCheck(<ItInfraPurchaseOrder />, "purchase_order"),
    },
    {
      path: "transaction",
      element: featureCheck(<ItInfraTransaction />, "transaction"),
    },
    {
      path: "invoice",
      element: featureCheck(<ItInfraInvoice />, "invoice"),
    },
    {
      path: "vendor_addition",
      element: featureCheck(<ItInfraVendorAddition />, "vendor"),
    },
    {
      path: "delivery",
      element: featureCheck(<ItInfraDelivery />, "delivery"),
    },
    {
      path: "assignment",
      element: featureCheck(<ItInfraAssignement />, "assignment"),
    },
    {
      path: "request",
      element: featureCheck(<ItInfraRequest />, "request_rais"),
    },
    {
      path: "request-approval",
      element: featureCheck(<ItInfraRequestApproval />, "request_approval"),
    },
    {
      path: "inventory-computer-peripheral",
      element: <ItInfraComputerPeripheral />,
    },
    {
      path: "inventory-network-devices",
      element: <ItInfraNetworkDevices />,
    },
    {
      path: "inventory-server",
      element: <ItInfraServer />,
    },
  ],
};
