/* eslint-disable*/
import { useNavigate } from "react-router";

import { Typography, Card, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import ScssStyle from "assets/scss/_themes-vars.module.scss";

const FolderCard = ({ card, icon }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = ScssStyle;
  return (
    <Card
      sx={{
        width: "100%",
        p: 1,
        backgroundColor: styles.secondary100,
        borderRadius: "10px",
      }}
    >
      <Button
        size="small"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          px: 2,
          width: "100%",
        }}
        onClick={() => navigate(card.path)}
      >
        <img style={{ paddingRight: "10px", width: "50px" }} src={icon} alt="chat" />
        <Typography
          variant="subtitle1"
          fontSize="16px"
          textAlign="start"
          color={theme.palette.secondary.dark}
        >
          {card.label}
        </Typography>
      </Button>
    </Card>
  );
};

export default FolderCard;
