const Config = {
  monthList: [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ],
  columnsKyc: [
    // {
    //   field: "id",
    //   headerName: "ID ",
    //   flex:1,
    //   headerClassName: "header",
    //   align: "left"
    // },
    {
      field: "client_id",
      headerName: "Client Id",
      minWidth: 80,
      headerClassName: "header",
      align: "left"
    },
    {
      field: "agreement_date",
      headerName: "Agreement Date",
      minWidth: 110,
      headerClassName: "header",
      align: "right",
    },
    {
      field: "client_name",
      headerName: "Full Name",
      minWidth: 250,
      headerClassName: "header",
      align: "left"
    },
    {
      field: "branch_code",
      headerName: "Branch Code",
      headerClassName: "header",
      align: "center"
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 100,
      headerClassName: "header"
    },
    {
      field: "mobile_no",
      headerName: "Mobile No.",
      minWidth: 130,
      headerClassName: "header"
    },
    {
      field: "relation_officer",
      headerName: "RO Code",
      minWidth: 90,
      headerClassName: "header",
      renderCell: (params) => {
        const officer = params.row.relation_officer?.trim().toLowerCase();
        return officer === "none" || officer === ""
          ? "-"
          : params.row.relation_officer;
      }
    },
    {
      field: "relationshipofficer_name",
      headerName: "RO Name",
      minWidth: 100,
      headerClassName: "header",
      renderCell: (params) => {
        console.log(params);
        const officer = params.row.relationshipofficer_name
          ?.trim()
          .toLowerCase();
        return officer === "none" || officer === ""
          ? "-"
          : params.row.relationshipofficer_name;
      }
    },
    {
      field: "relation_manager",
      headerName: "RM Code",
      minWidth: 90,
      headerClassName: "header",
      renderCell: (params) => {
        const officer = params.row.relation_manager?.trim().toLowerCase();
        return officer === "none" || officer === ""
          ? "-"
          : params.row.relation_manager;
      }
    },
    {
      field: "relationmanager_name",
      headerName: "RM Name",
      minWidth: 100,
      headerClassName: "header",
      renderCell: (params) => {
        const officer = params.row.relationmanager_name?.trim().toLowerCase();
        return officer === "none" || officer === ""
          ? "-"
          : params.row.relationmanager_name;
      }
    },
    {
      field: "segment",
      headerName: "Segment",
      minWidth: 250,
      headerClassName: "header",
      align: "right"
    }
  ],
  columnsMargin: [
    {
      field: "id",
      headerName: "SR No",
      flex: 1,
      headerClassName: "header",
      align: "left"
    },
    {
      field: "client_id",
      headerName: "Client Id",
      flex: 1,
      headerClassName: "header",
      align: "left"
    },
    {
      field: "bse_cash",
      headerName: "BSE Cash",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_cash",
      headerName: "NSE Cash",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_cd",
      headerName: "NSE CD",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_fno",
      headerName: "NSE F&O",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_slbm",
      headerName: "NSE SLBM",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "mcx",
      headerName: "MCX",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "total_margin",
      headerName: "Total Margin",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    }
  ],
  columnsPayout: [
    {
      field: "client_id",
      headerName: "Client Id",
      flex: 1,
      headerClassName: "header",
      align: "right"
    },
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      headerClassName: "header",
      align: "right"
    },
    {
      field: "region_branch_code",
      headerName: "Region Branch Code",
      flex: 1,
      headerClassName: "header",
      align: "right"
    },
    {
      field: "pay_out",
      headerName: "Pay Out",
      flex: 1,
      headerClassName: "header",
      align: "right"
    }
  ],
  revenueColumns: [
    {
      accessorKey: "id",
      header: "S.NO",
      size: 50
    },
    {
      accessorKey: "branch_code",
      header: "Branch Code"
    },
    {
      accessorKey: "branch_name",
      header: "Branch Name"
    },
    {
      accessorKey: "total_net_brokerage",
      header: "total_net_brokerage",
      Cell: ({ row }) => {
        const value = row.original.total_net_brokerage;
        return value ? value.toFixed(2) : "0";
      }
    },
    {
      accessorKey: "actbrk",
      header: "actbrk",
      Cell: ({ row }) => {
        const value = row.original.actbrk;
        return value ? value.toFixed(2) : "0";
      }
    },
    {
      accessorKey: "rembrk",
      header: "rembrk",
      Cell: ({ row }) => {
        const value = row.original.rembrk;
        return value ? value.toFixed(2) : "0";
      }
    },
    {
      accessorKey: "online_brokerage",
      header: "online_brokerage",
      Cell: ({ row }) => {
        const value = row.original.online_brokerage;
        return value ? value.toFixed(2) : "0";
      }
    },
    {
      accessorKey: "offline_brokerage",
      header: "offline_brokerage",
      Cell: ({ row }) => {
        const value = row.original.offline_brokerage;
        return value ? value.toFixed(2) : "0";
      }
    }
  ],
  b2bkycColumns: [
    {
      accessorKey: "id",
      header: "S.NO",
      size: 50
    },
    {
      accessorKey: "branchCode",
      header: "Branch Code",
      size: 110
    },
    {
      accessorKey: "branchName",
      header: "Branch Name",
      size: 300
    },
    {
      accessorKey: "kyc",
      header: "Kyc",
      size: 100
    }
  ],
  b2ckycColumns: [
    {
      accessorKey: "id",
      header: "S.NO",
      size: 50
    },
    {
      accessorKey: "branchCode",
      header: "Branch Code",
      size: 180
    },
    {
      accessorKey: "branchName",
      header: "Branch Name"
    },
    {
      accessorKey: "kyc",
      header: "Kyc",
      size: 100
    }
  ],
  onlineRevenue: [
    // {
    //   field: "id",
    //   headerName: "SR No",
    //   flex: 1,
    //   headerClassName: "header",
    //   align: "right"
    // },

    {
      field: "client_id",
      headerName: "client Id",
      flex: 1,
      headerClassName: "header",
      align: "left"
    },
    {
      field: "branch_code",
      headerName: "Branch Code",
      flex: 1,
      headerClassName: "header",
      align: "right"
    },
    {
      field: "bse_cash",
      headerName: "BSE Cash",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_cash",
      headerName: "NSE Cash",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_cd",
      headerName: "NSE CD",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_fo",
      headerName: "NSE F&O",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_slbm",
      headerName: "NSE SLBM",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    }
  ],
  btbRevenueColumn: [
    // {
    //   field: "id",
    //   headerName: "SR No",
    //   flex: 1,
    //   headerClassName: "header",
    //   align: "right"
    // },

    {
      field: "client_id",
      headerName: "client Id",
      flex: 1,
      headerClassName: "header",
      align: "left"
    },
    {
      field: "branch_code",
      headerName: "Branch Code",
      flex: 1,
      headerClassName: "header",
      align: "right"
    },

    {
      field: "bse_cash",
      headerName: "BSE Cash",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_cash",
      headerName: "NSE Cash",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_cd",
      headerName: "NSE CD",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_fo",
      headerName: "NSE F&O",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "nse_slbm",
      headerName: "NSE SLBM",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
      headerClassName: "header",
      align: "right",
      renderCell: (params) => params?.value?.toFixed(2)
    }
  ],
  clientColumn: [
    {
      field: "client_id",
      headerName: "Client Id",
      flex: 1,
      headerClassName: "header",
      align: "left"
    },
    {
      field: "branch_code",
      headerName: "Branch Code",
      flex: 1,
      headerClassName: "header",
      align: "left"
    },
    {
      field: "branch_name",
      headerName: "Branch Name",
      flex: 1,
      headerClassName: "header",
      align: "left"
    }
  ]
};

const commonRoles = {
  VP: ["VP", "CM", "BM", "SM", "RM", "RO"],
  CM: ["CM", "BM", "SM", "RM", "RO"],
  BM: ["BM", "SM", "RM", "RO"],
  SM: ["SM", "RM", "RO"],
  RM: ["RM"],
  RO: ["RO"]
};
const commonTabs = ["kyc", "revenue", "reports"];
const employeeTabs = ["dashboard", "utilities"];

export const bottomLevel = ["RM", "RO"];
export const b2bAccess = ["PROMOTER", "VP", "CM", "BM"];

export const userAccessConfig = [
  {
    value: "VP",
    label: "VP",
    roles: commonRoles.VP
  },
  {
    value: "super_user",
    label: "Super User",
    roles: commonRoles.VP
  },
  {
    value: "CM",
    label: "Cluster",
    roles: commonRoles.CM
  },
  {
    value: "BM",
    label: "Branch",
    roles: commonRoles.BM
  },
  {
    value: "SM",
    label: "SM",
    roles: commonRoles.SM
  },
  {
    value: "RM",
    label: "RM",
    roles: commonRoles.RM
  },
  {
    value: "RO",
    label: "RO",
    roles: commonRoles.RO
  }
];

export const tabsConfig = {
  PROMOTER: commonTabs,
  VP: commonTabs,
  CM: commonTabs,
  BM: commonTabs,
  SM: commonTabs,
  RO: commonTabs,
  RM: commonTabs,
  Employee: employeeTabs
};

export default Config;
