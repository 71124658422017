/*eslint-disable*/
import { commonApiRequest, } from "utils/CommonFunctions";

const REACT_APP_URL_HELPDESK = process.env.REACT_APP_URL_HELPDESK

export const fetchDepartmentListApi = () => {
  const url = `${process.env.REACT_APP_URL}/v1/admin/department-list`;
  return commonApiRequest('GET', url, null, null);
};

export const fetchUserWithDetailsList = () => {
  const url = `${process.env.REACT_APP_URL}/v1/user/fetch-user-list?source=ticket`;
  return commonApiRequest('GET', url, null, null);
};

export const fetchUserList = () => {
  const url = `${process.env.REACT_APP_URL}/v1/user/userlist`;
  return commonApiRequest('GET', url, null, null);
};

export const addTickets = (formdata) => {
  const url = `${REACT_APP_URL_HELPDESK}/helpdesk/v1/create-ticket`;
  return commonApiRequest('POST', url, formdata, null);
};

export const fetchTicketsByTabs = (paramsData) => {
  const url = `${REACT_APP_URL_HELPDESK}/helpdesk/v1/get-tickets-details`;
  return commonApiRequest('GET', url, null, paramsData);
};

export const addCommentWithAttchment = (formdata) => {
  const url = `${REACT_APP_URL_HELPDESK}/helpdesk/v1/insert-comment-attachment-by-ticket`;
  return commonApiRequest('POST', url, formdata, null);
};

export const fetchTicketsCommentsByTicketId = (paramsData) => {
  const url = `${REACT_APP_URL_HELPDESK}/helpdesk/v1/get-comment-attachment-by-ticket-id`;
  return commonApiRequest('GET', url, null, paramsData);
};


export const updateTicketData = (body) => {
  const url = `${REACT_APP_URL_HELPDESK}/helpdesk/v1/update-ticket-details`;
  return commonApiRequest('PUT', url, body, null);
};

export const updateTicketStatus = (paramsData) => {
  const url = `${REACT_APP_URL_HELPDESK}/helpdesk/v1/update-ticket-status`;
  return commonApiRequest('PUT', url, null, paramsData);
};

export const updateFollowersInTickets = (paramsData) => {
  const url = `${REACT_APP_URL_HELPDESK}/helpdesk/v1/manipulate-ticket-followers`;
  return commonApiRequest('PUT', url, null, paramsData);
};


export const fetchFollowersByTicket = (paramsData) => {
  const url = `${REACT_APP_URL_HELPDESK}/helpdesk/v1/get-followers-by-ticket-id`;
  return commonApiRequest('GET', url, null, paramsData);
};


export const changeDepartmentInTicket = (body) => {
  const url = `${REACT_APP_URL_HELPDESK}/helpdesk/v1/update-ticket-department`;
  return commonApiRequest('PUT', url, body, null);
};

export const addTaskInTicket = (body) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/insert-ticket-task`;
  return commonApiRequest('POST', url, body, null);
};

export const fetchTaskInTicket = (paramsData) => {
  const url = `${process.env.REACT_APP_URL_PMS_TARGET}/v1/get-ticket-tasks-by-ticket-id`;
  return commonApiRequest('GET', url, null, paramsData);
};