/* eslint-disable */
import { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import { Avatar, Box, Chip, ClickAwayListener, Dialog, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, Stack, Typography } from "@mui/material";
import { IconLogout, IconPassword, IconSettings, IconUser } from "@tabler/icons";

import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import User1 from "assets/images/users/user-round.svg";
import { getSessionStorage } from "utils/encrpytion";
import ChangePasswordForm from "./change-password/ChangePasswordForm";
import { AccountSetting } from "context/AccountSetting/AccountSettingApis";
import { LoginContext } from "context/login/LoginApi";
import { toast } from "react-toastify";
import { requestManager } from "utils/AbortController";
import { useWebSocket } from "context/web-socket/WebSocketMain";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const { socket, setSocket } = useWebSocket();

  const theme = useTheme();
  const { avtar } = useContext(AccountSetting);
  const { handleLogOutApi } = useContext(LoginContext);
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  /* anchorRef is used on different componets and specifying one type leads to other components throwing an error */
  const anchorRef = useRef(null);

  const user_id = getSessionStorage("UID");

  const handleLogout = () => {
    // Logout on browser tab/window close (under maintanance)
    handleLogOutApi();

    if (socket) {
      socket.onclose = () => {
        console.log("WebSocket connection closed");
      };
      socket.close();
      // This ensures the WebSocket connection is closed
      setSocket(null);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  //change password functions
  const handleChangePassword = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Avatar
        src={localStorage.avatarBase64 ? avtar : User1}
        sx={{
          ...theme.typography.mediumAvatar,
          cursor: "pointer",
          height: "40px",
          width: "40px",
          border: "0.5px solid grey",
        }}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="inherit"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper sx={{ height: "30px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 1 }}>
                    <Stack>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        alignItems="center"
                        sx={{ p: 0, m: 0 }}
                      >
                        <Typography variant="h4" sx={{ fontSize: "12px" }}>
                          Good Morning,
                        </Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ fontWeight: 400, fontSize: "12px" }}
                        >
                          {getSessionStorage("user_name")?.toUpperCase()}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
                        {getSessionStorage("user_role")?.toUpperCase()}
                      </Typography>
                    </Stack>

                    <Divider sx={{ mt: 0.5 }} />
                  </Box>

                  <Box>
                    <List
                      component="nav"
                      sx={{
                        p: 0,
                        m: 0,
                        width: "100%",
                        maxWidth: 200,
                        minWidth: 200,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                      }}
                    >
                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`,
                          m: 0,
                          p: 0,
                          px: 1,
                        }}
                        selected={selectedIndex === 0}
                        onClick={(event) => navigate("account-settings")}
                      >
                        <ListItemIcon>
                          <IconSettings stroke={1.5} size="1rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "12px" }}
                            >
                              Account Settings
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`,
                          m: 0,
                          p: 0,
                          px: 1,
                        }}
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1, "#")}
                      >
                        <ListItemIcon>
                          <IconUser stroke={1.5} size="1rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Grid
                              container
                              spacing={1}
                              justifyContent="space-between"
                            >
                              <Grid
                                item
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ fontSize: "12px" }}
                                >
                                  Social Profile
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Chip
                                  label={
                                    <Typography variant="body2">02</Typography>
                                  }
                                  size="small"
                                  sx={{
                                    bgcolor: theme.palette.warning.dark,
                                    color: theme.palette.background.default,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`,
                          m: 0,
                          p: 0,
                          px: 1,
                          pb: 1,
                        }}
                        selected={selectedIndex === 2}
                        onClick={handleChangePassword}
                      >
                        <ListItemIcon>
                          <IconPassword stroke={1.5} size="1rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "12px" }}
                            >
                              Change Password
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        sx={{
                          borderRadius: `${customization.borderRadius}px`,
                          m: 0,
                          p: 0,
                          px: 1,
                          pb: 1,
                        }}
                        selected={selectedIndex === 3}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <IconLogout stroke={1.5} size="1rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "12px" }}
                            >
                              Logout
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
        }}
        maxWidth="xs"
        fullWidth
      >
        <ChangePasswordForm {...{ setOpenDialog }} />
      </Dialog>
    </>
  );
};

export default ProfileSection;
