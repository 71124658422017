/*eslint-disable*/
import axios from "axios";
import { toast } from "react-toastify";
import { commonApiRequest } from "utils/CommonFunctions";

export const createNotice = async (body) => {
  const url = `${process.env.REACT_APP_URL_HELPDESK}/notice-board/v1/notice/add`;
  const response = await commonApiRequest("POST", url, body)
  return response
};


export const fetchNotice = async () => {
  const url = `${process.env.REACT_APP_URL_HELPDESK}/notice-board/v1/notice/details`;
  const response = await commonApiRequest("GET", url);
  return response
};

export const updateNotice = async (body) => {
  const url = `${process.env.REACT_APP_URL_HELPDESK}/notice-board/v1/notice/update`;
  const response = await commonApiRequest("PUT", url, body)
  return response;
};


export const deleteNotice = async (notice_id) => {
  const url = `${process.env.REACT_APP_URL_HELPDESK}/notice-board/v1/notice/delete`;
  const paramsData = {
    notice_id: notice_id
  }
  const response = await commonApiRequest("DELETE", url, null, paramsData)
  return response

};


