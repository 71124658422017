import axios from "axios";
import { commonApiRequest } from "utils/CommonFunctions";

const COMMON_URL = process.env.REACT_APP_URL_EDDPI;

export const handleFetchTableDataApi = () => {
    const url = `${COMMON_URL}/v1/get-data`;
    return commonApiRequest('GET', url);
};

export const handleFetchLogDataApi = () => {
    const url = `${COMMON_URL}/v1/get-log-data`;
    return commonApiRequest('GET', url);
};

export const handleApproveRejectApi = (formData) => {
    const url = `${COMMON_URL}/v1/approve-reject`;
    return commonApiRequest('POST', url, formData);
};

export const handleFetchEsignPdfApi = async (payload) => {
    const headers = {
        accept: 'application/json',
        authToken: localStorage.authToken,
        'Content-Type': 'application/x-www-form-urlencoded',
    };

    const config = {
        headers: headers,
        responseType: 'blob',
    }
    const url = `${COMMON_URL}/v1/get-esign-pdf`;
    const response = await axios.post(url, payload, config);
    return response;
};


