import { commonApiRequest } from "utils/CommonFunctions"

export const AtomPayFileUpload = async(body, params) => {
    const url = `${process.env.REACT_APP_URL_ATOM_PAY}/v1/atom-pay/file-uploader`
    return commonApiRequest("POST", url, body, params)
}

export const AtomPayFileLogs = async(params) => {
    const url = `${process.env.REACT_APP_URL_ATOM_PAY}/v1/atom-pay/file-logs`
    return commonApiRequest("POST", url, null, params, "blob")
}