import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import ApOnBoardingDash from "views/utilities/ap-on-boarding/dashboard";
import ApOnBoardingUtils from "views/utilities/ap-on-boarding/utils";
import ApOnBoardingReports from "views/utilities/ap-on-boarding/reports";

const BounceHome = Loadable(
  lazy(() => import("views/utilities/ap-on-boarding"))
);
const ApList = Loadable(
  lazy(() => import("views/utilities/ap-on-boarding/utils/list"))
);
const ApRequest = Loadable(
  lazy(() => import("views/utilities/ap-on-boarding/utils/new_ap_req"))
);
const ProcessFlow = Loadable(
  lazy(() => import("views/utilities/ap-on-boarding/utils/process-flow"))
);
const CheckList = Loadable(
  lazy(() => import("views/utilities/ap-on-boarding/utils/check-list"))
);
const UploadDocs = Loadable(
  lazy(() => import("views/utilities/ap-on-boarding/utils/upload-docs"))
);
const CheckStatus = Loadable(
  lazy(() => import("views/utilities/ap-on-boarding/utils/check-status"))
);
const PrepareDocs = Loadable(
  lazy(() => import("views/utilities/ap-on-boarding/utils/prepare-docs"))
);
const APOnBoardingAuthorizationDetails = Loadable(
  lazy(() =>
    import(
      "views/utilities/ap-on-boarding/utils/prepare-docs/authorization/APAuthorizationDetails"
    )
  )
);
const BranchCreation = Loadable(
  lazy(() => import("views/utilities/ap-on-boarding/utils/branch-creation"))
);
// const APOnBoardingPDFDetails = Loadable(
//   lazy(() =>
//     import("views/utilities/ap-on-boarding/utils/prepare-docs/pdfDownload")
//   )
// );

export const ApOnBoardingModule = {
  path: "ap-onboarding",
  element: <BounceHome />,
  children: [
    {
      path: "home",
      element: <ApOnBoardingDash />
    },
    {
      path: "utils",
      element: <ApOnBoardingUtils />
    },
    {
      path: "reports",
      element: <ApOnBoardingReports />
    }
  ]
};

export const ApOnBoardingRoutes = {
  path: "ap-onboarding",
  children: [
    {
      path: "list",
      element: <ApList />
    },
    {
      path: "new-ap-req",
      element: <ApRequest />
    },
    {
      path: "process-flow",
      element: <ProcessFlow />
    },
    {
      path: "check-list",
      element: <CheckList />
    },
    {
      path: "upload-docs",
      element: <UploadDocs />
    },
    {
      path: "check-status",
      element: <CheckStatus />
    },
    {
      path: "check-status",
      children: [
        {
          path: "profile",
          element: <APOnBoardingAuthorizationDetails />
        }
      ]
    },
    {
      path: "prepare-docs",
      element: <PrepareDocs />
    },
    {
      path: "prepare-docs",
      children: [
        {
          path: "profile",
          element: <APOnBoardingAuthorizationDetails />
        },
        // {
        //   path: "pdfDownload",
        //   element: <APOnBoardingPDFDetails />
        // }
      ]
    },
    {
    path: "branch-creation",
    element: <BranchCreation />
  },
  ]
};
