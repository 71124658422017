/*eslint-disable*/
import { message } from "antd";
import React, { useState } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  accountOpeningApi,
  checkerDataApi,
  checkerModificationApi,
  clientBankDetailsApi,
  closureClientDeatilsApi,
  closureFormApi,
  gerReasonCodeApi,
  getAccountOpeningCategoryApi,
  getClientDetailsApi,
  getEntryCategoryApi,
  getInwardEntriesDataApi,
  getReasonCodeApi,
  modificationDataApi,
  nomineeDataApi,
  otpApi,
  segmentDataApi,
  showCategoryApi,
  showProofFilesApi,
  submitModificationDataApi,
} from "services/inward/InwardService";
import { handleCatch } from "utils/CommonFunctions";

export const InwardContext = createContext();

export default function InwardApi({ children }) {
  const config = {
    indianStates: [
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
      "Andaman and Nicobar Islands",
      "Chandigarh",
      "Dadra Nagar Haveli",
      "Daman",
      "Diu",
      "Lakshadweep",
      "Delhi",
      "Puducherry",
    ],
  };

  const navigate = useNavigate();
  const [entryCategory, setEntryCategory] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [entryFiles, setEntryFiles] = useState([]);
  const [showExtraFields, setShowExtraFields] = useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [tradingCode, setTradingCode] = useState("");
  const [segmentDetails, setSegmentDetails] = useState("");
  const [inwardData, setInwardData] = useState({});
  const [ApprovalStatus, setApprovalStatus] = useState("pending");
  const [globalFilter, setGlobalFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [bankDetails, setBankDetails] = useState([]);
  const [checkerModificationData, setCheckerModificationData] = useState({});
  const [checkerCategory, setCheckerCategory] = useState([]);
  //#################################Category & inwardType API###############################//
  const handleEntryCategory = async () => {
    try {
      const response = await getEntryCategoryApi();

      if (response.status === 200) {
        setEntryCategory(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  const handleShowCategory = async () => {
    setCategoryData([]);
    try {
      const response = await showCategoryApi(documentType);

      if (response.status === 200) {
        setCategoryData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleShowProofFiles = async (categoryId) => {
    try {
      const response = await showProofFilesApi(documentType, categoryId);

      if (response.status === 200) {
        setEntryFiles(response.data.data);
        console.log("response successfully", response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const getClientDetails = async (setFieldValue, inwardType) => {
    setIsLoading(true);
    try {
      const response = await getClientDetailsApi(tradingCode);

      if (response.status === 200) {
        const clientDetails = response.data.data;
        setClientDetails(clientDetails);
        setSegmentDetails(clientDetails.active_segment);
        if (inwardType === "Closure") {
          setFieldValue(
            "bo_id",
            clientDetails.personal_details?.client_dp_code
          );
          setFieldValue("holding_value", clientDetails.holding_balance);
          setFieldValue("trading_balance", clientDetails.ledger_balance);
          setFieldValue("join_holder", clientDetails.dp_details);
          setFieldValue(
            "nominee_name",
            clientDetails.personal_details?.nominee_name
          );
        }
        handleClientBankDetails();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  //###################################Modification Api######################################//
  const handleModificationData = async (
    values,
    callbackProps = {},
    resetForm
  ) => {
    setIsLoading(true);

    try {
      const response = await modificationDataApi(values);

      if (response.data.status === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      resetForm();

      if (callbackProps.setRejectDialog) {
        callbackProps.setRejectDialog(false);
        callbackProps.setFormValues({});
      }
      if (callbackProps.setFormValues) {
        callbackProps.setFormValues({});
        setTradingCode("");
        setClientDetails({});
        setEntryFiles([]);
      }
    }
  };

  //#############################Inward Entries Table APi####################################//
  const [segmentData, setSegmentData] = useState([]);
  const [nomineeData, setNomineeData] = useState([]);
  const [closureData, setClosureData] = useState([]);
  const handleGetInwardEntriesData = async (inwardType, inwardID) => {
    setIsLoading(true);
    setInwardData([]);
    setCheckerModificationData({});
    try {
      const response = await getInwardEntriesDataApi(
        pagination,
        inwardType,
        inwardID,
        ApprovalStatus
      );

      if (response.data.status === "success") {
        setRowCount(response.data.data.row_count);
        setCheckerModificationData(response.data.data.modification_data);
        setSegmentData(response.data.data.data);
        setNomineeData(response.data.data.data);
        setClosureData(response.data.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  //############################# Client Bank-details ######################################//
  const handleClientBankDetails = async () => {
    try {
      const response = await clientBankDetailsApi(tradingCode);

      const result = response.data;
      const bankDetailsObj = result.data.bank_details;

      const bankDataArray = Object.keys(bankDetailsObj.bank_acno).map(
        (key, index) => ({
          id: index + 1,
          bank_name: bankDetailsObj.bank_name[key],
          bank_acno: bankDetailsObj.bank_acno[key],
          ifsc_code_act: bankDetailsObj.ifsc_code_act[key],
          micr_code: bankDetailsObj.micr_code[key],
          bank_acctype: bankDetailsObj.bank_acctype[key],
          default_ac: bankDetailsObj.default_ac[key],
        })
      );

      setBankDetails(bankDataArray);
    } catch (error) {
      handleCatch(error);
    }
  };

  //############################# Checker data #############################################//
  const [openDialog, setOpenDialog] = useState(false);
  const handleCheckerData = async () => {
    try {
      const response = await checkerDataApi();

      if (response.data.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleCheckerModification = async (inwardID) => {
    setCheckerModificationData({});
    try {
      const response = await checkerModificationApi(inwardID);

      if (response.status === 200) {
        toast.success(response.data.message);
        setCheckerModificationData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleSubmitModificationData = async (
    action,
    inwardId,
    inwardType,
    rejectReason
    // setOpenDialog
  ) => {
    try {
      const response = await submitModificationDataApi(
        action,
        inwardId,
        inwardType,
        rejectReason
        // setOpenDialog
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        if (action === "rejected") {
          setOpenDialog(false);
        }
        if (inwardType === "Modification") {
          navigate("/kyc/modification");
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //############################ OTP ###################################################//
  const handleOtp = async (InwardId) => {
    try {
      const response = await otpApi(InwardId);

      if (response.data.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //################################ Closure Apis #####################################//
  const [closureClientData, setClosureClientData] = useState([]);
  const handleClosureForm = async (values, setFormValues, resetForm) => {
    setIsLoading(true);

    try {
      const response = await closureFormApi(values);

      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      setTradingCode("");
      setFormValues(false);
      setClientDetails({});
      setSegmentDetails("");
      resetForm();
    }
  };

  const handleClosureClientDetails = async (clientCode) => {
    setIsLoading(true);
    try {
      const response = await closureClientDeatilsApi(clientCode);
      if (response.status === 200) {
        setClosureClientData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  //################################# Segment APi #########################################/
  const handleSegmentData = async (values, setFormValues, resetForm) => {
    setIsLoading(true);
    try {
      const response = await segmentDataApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      setTradingCode("");
      setFormValues(false);
      setClientDetails({});
      setSegmentDetails("");
      resetForm();
    }
  };

  //######################### Receiver Apis ###################################
  const [reasonCode, setReasonCode] = useState([]);
  const handleReasonCode = async (clientCode) => {
    try {
      const response = await getReasonCodeApi(clientCode);
      if (response.status === 200) {
        toast.success(response.data.message);
        setReasonCode(response.data.data);
      } else {
        toast.error(response.data, message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  //############################### Nominee Api #####################################
  const handleNomineeData = async (
    values,
    resetForm,
    setNomineeDialog,
    setNomineeFormValues,
    setFormValues
  ) => {
    try {
      const response = await nomineeDataApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setNomineeDialog(false);
      resetForm();
      setNomineeFormValues("");
      setFormValues("");
    }
  };

  //############################### Account Api #####################################
  const [accountOpeningCategory, setAccountOpeningCategory] = useState([]);
  const handleAccountOpeningCategory = async () => {
    try {
      const response = await getAccountOpeningCategoryApi();
      if (response.status === 200) {
        setAccountOpeningCategory(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };
  const handleAccountOpening = async (values, setFormValues) => {
    try {
      const response = await accountOpeningApi(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setFormValues("");
    }
  };

  return (
    <InwardContext.Provider
      value={{
        setDocumentType,
        handleShowCategory,
        categoryData,
        handleShowProofFiles,
        entryFiles,
        showExtraFields,
        setShowExtraFields,
        getClientDetails,
        clientDetails,
        handleShowProofFiles,
        entryFiles,
        setEntryFiles,
        setTradingCode,
        tradingCode,
        setClientDetails,
        handleModificationData,
        setApprovalStatus,
        ApprovalStatus,
        setGlobalFilter,
        globalFilter,
        handleGetInwardEntriesData,
        inwardData,
        pagination,
        setPagination,
        rowCount,
        handleClientBankDetails,
        bankDetails,
        handleCheckerData,
        handleCheckerModification,
        checkerModificationData,
        setCheckerCategory,
        checkerCategory,
        handleSubmitModificationData,
        // setInwardID,
        // inwardID,
        handleOtp,
        config,
        segmentDetails,
        isLoading,
        handleClosureForm,
        handleSegmentData,

        handleReasonCode,
        reasonCode,

        handleEntryCategory,
        entryCategory,
        setSegmentDetails,

        handleNomineeData,
        segmentData,
        nomineeData,
        closureData,

        handleClosureClientDetails,
        closureClientData,

        handleAccountOpening,
        handleAccountOpeningCategory,
        accountOpeningCategory,
        openDialog,
        setOpenDialog,
      }}
    >
      {children}
    </InwardContext.Provider>
  );
}
