/*eslint-disable*/
import axios from "axios";

import { toast } from "react-toastify";
import pdfIcon from "assets/images/file icons/pdf.png";
import excelIcon from "assets/images/file icons/excel.png";
import rarIcon from "assets/images/file icons/rar.png";
import videoIcon from "assets/images/file icons/video.png";
import imageIcon from "assets/images/file icons/image.png";
import documentIcon from "assets/images/file icons/document.png";
import fileIcon from "assets/images/file icons/file1.png";
import audioIcon from "assets/images/file icons/audio.png";
import pythonIcon from "assets/images/file icons/Python.png";
import textPlain from "assets/images/file icons/text-plain.jfif";
import fileUrl from "assets/images/cards/file.png";

export const handleCatch = async (error) => {
  console.log("error", error);

  if (error?.config?.responseType == "blob") {
    const err = JSON.parse(await error?.response?.data.text());
    toast.error(err.message);
  } else if (error?.response?.data) {
    {
      error?.response?.data?.message &&
        toast.error(error?.response?.data?.message);
    }
    {
      error?.response?.data?.detail &&
        toast.error(error?.response?.data?.detail);
    }
  } else {
    toast.error("Network Error");
  }
};

// common headers for all APIs
export const getHeaders = () => ({
  accept: "application/json",
  authToken: localStorage.authToken,
  source: "erp",
  "Cache-Control": "no-cache, no-store, must-revalidate",
  // Pragma: "no-cache",
  Expires: "0",
});

export const getHeadersNew = () => ({
  accept: "application/json",
  "auth-token": localStorage.authToken,
  "Cache-Control": "no-cache, no-store, must-revalidate",
  Pragma: "no-cache",
  Expires: "0",
});

// common function for all APIs
export const commonApiRequest = async (
  method,
  url,
  body = null,
  params = null,
  responseType = "json"
) => {
  const config = {
    headers: getHeaders(),
    params: params,
    responseType: responseType,
  };

  let response;
  switch (method) {
    case "GET":
      response = await axios.get(url, config);
      break;
    case "POST":
      response = await axios.post(url, body, config);
      break;
    case "PUT":
      response = await axios.put(url, body, config);
      break;
    case "DELETE":
      response = await axios.delete(url, config);
      break;
    default:
      throw new Error(`Unsupported request method: ${method}`);
  }
  return response;
};

export const commonApiRequestNew = async (
  method,
  url,
  body = null,
  params = null,
  responseType = "json"
) => {
  const config = {
    headers: getHeadersNew(),
    params: params,
    responseType: responseType,
  };

  let response;
  switch (method) {
    case "GET":
      response = await axios.get(url, config);
      break;
    case "POST":
      response = await axios.post(url, body, config);
      break;
    case "PUT":
      response = await axios.put(url, body, config);
      break;
    case "DELETE":
      response = await axios.delete(url, config);
      break;
    default:
      throw new Error(`Unsupported request method: ${method}`);
  }
  return response;
};

//random color generator
export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str?.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    const lightValue = Math.floor((value + 100) / 2);
    color += ("00" + lightValue.toString(16)).substr(-2);
  }
  return color;
};

export const formatDateDMY = (date) => {
  const year = date && date?.getFullYear();
  const month = String(date && date.getMonth() + 1).padStart(2, "0");
  const day = String(date && date.getDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
};

export const formatDateStringBounceMail = (dateStr) => {
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObj.getDate().toString().padStart(2, "0");
  return `${day}/${month}/${year}`;
};

export const stringAvatarChat = (
  name,
  widthSize = 32,
  heightSize = 32,
  fontSize = 12
) => {
  const nameParts = name?.trim()?.split(" ");
  const avatar = {
    sx: {
      bgcolor: stringToColor(name),
      color: "white",
      width: widthSize,
      height: heightSize,
      fontSize: fontSize,
    },
    children:
      name &&
      `${nameParts[0][0]?.toUpperCase()}${nameParts[
        nameParts.length - 1
      ][0]?.toUpperCase()}`,
  };

  return avatar;
};

export function getFirstAndLastInitials(fullName) {
  if (!fullName) return "";

  const names = fullName?.trim().split(" ");
  if (names.length === 0) return "";

  const firstNameInitial = names[0].charAt(0).toUpperCase();
  if (names.length === 1) {
    return firstNameInitial;
  }

  const lastNameInitial = names[names.length - 1].charAt(0).toUpperCase();
  return firstNameInitial + lastNameInitial;
}

export const formatValue = (value) => {
  if (value == null || isNaN(value)) {
    return 0;
  }
  if (value >= 1.0e7) {
    return (value / 1.0e7).toFixed(2) + "Cr"; // Crores
  } else if (value >= 1.0e5) {
    return (value / 1.0e5).toFixed(2) + "L"; // Lakhs
  } else if (value >= 1.0e3) {
    return (value / 1.0e3).toFixed(2) + "K"; // Thousands
  } else {
    return value.toFixed(2); // Less than thousand
  }
};

export const formatDateTimeDMY = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(2);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    // ${hours}:${minutes}:${seconds}
    return `${day}-${month}-${year} `;
  }
};

export function formatTimestampToHHMM(timestamp) {
  if (timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(2);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    // ${hours}:${minutes}:${seconds}
    return `${hours}:${minutes} `;
  }
}

export const formatDateYMD = (value) => {
  if (value) {
    const date = new Date(value);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${year}-${month}-${day}`;
  } else {
    return "";
  }
};

export function formatTimestampToDDMMYY(timestamp) {
  if (timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(2);
    return `${day}/${month}/${year}`;
  }
}

export function formatTimestampToDDMMYYHHMM(timestamp) {
  if (timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" }); // Get short month name
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format, and adjust for 12 AM/PM

    return `${day}-${month}-${year} \n ${hours}:${minutes} ${ampm}`;
  }
  return "";
}

export function isValidURL(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

//Base64 to file download
export function Base64ToFileDownload(base64, file_type, file_name) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: file_type });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = file_name || "attachment"; // Specify the file name
  document.body.appendChild(link); // Append the link to the body
  link.click(); // Programmatically click the link to trigger the download
  document.body.removeChild(link); // Remove the link from the body

  URL.revokeObjectURL(url); // Revoke the object URL
}

// download csv on array
export const downloadCSV = (columnNames, fileName = "data.csv") => {
  console.log("columnNames", columnNames);
  const csvContent = `data:text/csv;charset=utf-8,${columnNames.join(",")}\n`;

  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

//base64 to URL
export function Base64ToUrl(base64, fileType) {
  // const mimeType = type === 'pdf' ? `application/${type}` : `image/${type}`;
  // console.log('fileType', fileType)
  if (base64 == "no_thumbnail_available") {
    return fileUrl;
  } else {
    return `data:${fileType};base64,${base64}`;
  }
}

export const imageSizeLimitFn = (files) => {
  const validFiles = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (file.type.startsWith("image/")) {
      if (file.size > 2 * 1024 * 1024) {
        toast.error(`Image file ${file.name} is larger than 2 MB.`);
        continue;
      }
    } else {
      if (file.size > 5 * 1024 * 1024) {
        toast.error(`File ${file.name} is larger than 5 MB.`);
        continue;
      }
    }
    validFiles.push(file);
  }
  return validFiles;
};

export const equalsCheck = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const Base64ToImgFn = (base64String) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  var mimeType;
  if (
    byteArray[0] === 0x25 &&
    byteArray[1] === 0x50 &&
    byteArray[2] === 0x44 &&
    byteArray[3] === 0x46
  ) {
    mimeType = "application/pdf";
  } else {
    mimeType = "image/png";
  }
  const blob = new Blob([byteArray], { type: mimeType });
  const url = URL.createObjectURL(blob);
  return url;
};

export const Base64ToImgFnMimeType = (base64String) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  var mimeType;
  if (
    byteArray[0] === 0x25 &&
    byteArray[1] === 0x50 &&
    byteArray[2] === 0x44 &&
    byteArray[3] === 0x46
  ) {
    mimeType = "application/pdf";
  } else {
    mimeType = "image/png";
  }

  return mimeType;
};

export function loadBase64ToFile(base64String) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  let mimeType;
  if (
    byteArray[0] === 0x25 &&
    byteArray[1] === 0x50 &&
    byteArray[2] === 0x44 &&
    byteArray[3] === 0x46
  ) {
    mimeType = "application/pdf";
  } else {
    mimeType = "image/jpeg";
  }
  const blob = new Blob([byteArray], { type: mimeType });
  const fileName =
    "Filename" + (mimeType === "application/pdf" ? ".pdf" : ".jpg");
  const file = new File(
    [blob],
    fileName,
    { type: mimeType, lastModified: new Date().getTime() },
    "utf-8"
  );
  const container = new DataTransfer();
  container.items.add(file);
  return container.files;
}

export const fileSizeLimitFn = (files) => {
  const sizeLimits = {
    "image/": 3 * 1024 * 1024,
    "application/zip": 20 * 1024 * 1024,
    "application/x-rar-compressed": 20 * 1024 * 1024,
    "video/mp4": 50 * 1024 * 1024,
    "text/plain": 5 * 1024 * 1024,
    "application/vnd.ms-excel": 5 * 1024 * 1024,
    "text/csv": 5 * 1024 * 1024,
    "application/msword": 5 * 1024 * 1024,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      5 * 1024 * 1024,
    "image/gif": 2 * 1024 * 1024,
    "application/pdf": 5 * 1024 * 1024,
    default: 5 * 1024 * 1024,
  };

  const validFiles = [];
  for (const file of files) {
    const fileType = file.type;
    const fileSizeLimit =
      Object.keys(sizeLimits).find((key) => fileType.startsWith(key)) ||
      "default";
    const maxSize = sizeLimits[fileSizeLimit];

    if (file.size > maxSize) {
      toast.error(
        `File ${file.name} is larger than ${maxSize / (1024 * 1024)} MB.`
      );
    } else {
      validFiles.push(file);
    }
  }
  return validFiles;
};

// icons as per filetype
export const fileTypeIcons = (fileType, width = "40px") => {
  const imgIcons = {
    "image/": <img src={imageIcon} alt="img" width={width} />,
    "image/jpeg": <img src={imageIcon} alt="img" width={width} />,
    "image/png": <img src={imageIcon} alt="img" width={width} />,
    "image/heic": <img src={imageIcon} alt="img" width={width} />,
    "application/zip": <img src={rarIcon} alt="zip" width={width} />,
    "application/x-rar-compressed": (
      <img src={rarIcon} alt="rar" width={width} />
    ),
    "video/mp4": <img src={videoIcon} alt="vid" width={width} />,
    "audio/mpeg": <img src={audioIcon} alt="mp3" width={width} />,
    "text/plain": <img src={documentIcon} alt="txt" width={width} />,
    "application/vnd.ms-excel": <img src={excelIcon} alt="xls" width={width} />,
    "text/csv": <img src={excelIcon} alt="csv" width={width} />,
    "text/plain": <img src={textPlain} alt="plain" width={width} />,
    "text/x-python": <img src={pythonIcon} alt="python" width={width} />,
    "application/msword": <img src={documentIcon} alt="doc" width={width} />,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": (
      <img src={documentIcon} alt="xls" width={width} />
    ),
    "image/gif": <img src={imageIcon} alt="gif" width={width} />,
    "application/pdf": <img src={pdfIcon} alt="pdf" width={width} />,
    default: <img src={fileIcon} alt="file" width={width} />,
  };

  const icon =
    Object.keys(imgIcons).find((key) => fileType?.startsWith(key)) || "default";

  return imgIcons[icon];
};

export const getMenuProps = (height = 48, paddingTop = 10) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: height * 5.5 + paddingTop,
        width: 250,
        marginTop: 0.8,
      },
    },
  };
  return MenuProps;
};

// attachment view in dialog
export const renderAttachment = (attachmentUrl, attachmentName) => {
  if (attachmentUrl) {
    // iframe type
    const iframeTypes = [".pdf", ".txt", ".sod"];
    const isIframeType = iframeTypes.some((ext) =>
      attachmentName?.toLowerCase().endsWith(ext)
    );
    // imge types
    const imageTypes = [".png", ".jpeg", ".jpg", ".gif", ".svg"];
    const isImgType = imageTypes.some((ext) =>
      attachmentName?.toLowerCase().endsWith(ext)
    );

    if (isIframeType) {
      return (
        <iframe
          title="Attachment"
          src={attachmentUrl}
          style={{ width: "100%", minHeight: "calc(100vh - 100px)" }}
        />
      );
    } else if (attachmentName.endsWith(".mp4")) {
      return (
        <video
          controls
          style={{ width: "100%", maxHeight: "calc(100vh - 64px)" }}
        >
          <source src={attachmentUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else if (isImgType) {
      return (
        <img
          src={attachmentUrl}
          alt=""
          style={{ maxWidth: "calc(100vw - 80px)" }}
        />
      );
    } else if (
      attachmentName.endsWith(".mp3") ||
      attachmentName.endsWith(".wav")
    ) {
      return (
        <audio
          controls
          style={{ width: "100%", maxHeight: "calc(100vh - 64px)" }}
        >
          <source
            src={attachmentUrl}
            type={`audio/${attachmentName.split(".").pop()}`}
          />
          Your browser does not support the audio element.
        </audio>
      );
    } else {
      return (
        <p
          style={{
            width: "100%",
            textAlign: "center",
            padding: "0 10px",
            marginTop: "10px",
          }}
        >
          This file is not viewable here, Please download the file.
        </p>
      );
    }
  } else {
    return (
      <p
        style={{
          width: "100%",
          textAlign: "center",
          padding: "0 10px",
          marginTop: "10px",
        }}
      >
        File Not Found
      </p>
    );
  }
};

export const renderAttachmentMimeType = (attachmentUrl, mimeType) => {
  if (!attachmentUrl) return null;
  if (
    mimeType?.includes("pdf") ||
    mimeType?.includes("text") ||
    mimeType?.includes("SOD")
  ) {
    return (
      <iframe
        title="Attachment"
        src={attachmentUrl}
        style={{ width: "100%", minHeight: "calc(100vh - 100px)" }}
      />
    );
  } else if (mimeType?.includes("image")) {
    return <img src={attachmentUrl} alt="" style={{ maxWidth: "100%  " }} />;
  } else if (mimeType === "video/mp4") {
    return (
      <video
        controls
        style={{ width: "100%", maxHeight: "calc(100vh - 64px)" }}
      >
        <source src={attachmentUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  } else if (mimeType === "audio/mp3" || mimeType === "audio/wav") {
    return (
      <audio
        controls
        style={{ width: "100%", maxHeight: "calc(100vh - 64px)" }}
      >
        <source src={attachmentUrl} type={mimeType} />
        Your browser does not support the audio element.
      </audio>
    );
  } else {
    return (
      <p style={{ width: "100%", textAlign: "center", padding: "0 10px" }}>
        This file is not viewable here. Please download the file.
      </p>
    );
  }
};

export const randomPasswordGenerator = (length) => {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%&*";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

export const getRandomColor = () => {
  const colors = [
    "#d3f2e4",
    "#cdd3ff",
    "#BCE6EC",
    "#FFEBD8",
    "#D8EFD3",
    "#F2F0FF",
    "#dcf0fa",
  ];

  return colors[Math.floor(Math.random() * colors.length)];
};

export const formatDateWithTime = (dateTimeStr) => {
  const dateTime = new Date(dateTimeStr);
  const formattedDate = dateTime.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const formattedTime = dateTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return `${formattedDate}  ${formattedTime}`;
};

export function onlyBase64ToUrl(base64String) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  return url;
}

export function LinkifyText(text) {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const processedText = text.split(urlPattern).map((part, index) => {
    if (urlPattern.test(part)) {
      return (
        <a
          style={{ fontSize: 12, textDecoration: "none", color: "#5E8FF7" }}
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
        >
          {part}
        </a>
      );
    }
    return part;
  });

  return (
    <p
      style={{
        color: "#6F727B",
        fontSize: 16,
        marginBottom: 0,
        marginTop: 0,
        lineHeight: 1,
        textWrap: "wrap",
        fontWeight: 600,
        wordBreak: "break-word",
      }}
    >
      {processedText}
    </p>
  );
}
