/*eslint-disable*/
import React, { useState, createContext } from "react";

import { toast } from "react-toastify";
import { confirmVerificationApi, fetchClientDataCodeApi, fetchDptFileApi, fetchSettlementDataApi, fetchSettleMentIdApi, handleFetchGetDataApi, updateSettlementDataApi } from "services/edis/edisServices";
import { handleCatch } from "utils/CommonFunctions";

export const EdisContext = createContext();

export const EdisApis = (props) => {
  const [stockVerificationData, setStockVerificationData] = useState([]);
  const [verifiedData, setVerifiedData] = useState([]);
  const [settlementId, setSettlementId] = useState("");
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [settlementData, setSettlementData] = useState([]);


  //GET client's information
  const handleFetchGetData = async (client_id) => {
    setLoading(true)
    // setSettlementId("")
    setStockVerificationData([]);
    try {
      const params = {
        source: 'erp',
        client_code: client_id
      };
      const response = await handleFetchGetDataApi(params)

      console.log("response", response);

      const uniqueIdData = response?.data?.data?.data?.map(
        (row, index) => ({
          ...row,
          id: index + 1,
        })
      );

      // setSettlementId(response?.data?.data?.settlement_id)
      setStockVerificationData(uniqueIdData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  // GET client code list
  const fetchClientDataCode = async (client_id) => {
    try {
      const params = {
        client_code: client_id
      };
      const response = await fetchClientDataCodeApi(params);

      setClientData(response.data.data);

    } catch (error) {
      console.error("error in catch", error);
    }
  };


  //POST confirm Edis verification on button submit
  const confirmVerification = async (payload) => {
    try {
      const params = {
        source: 'erp'
      };
      const response = await confirmVerificationApi(payload, params);
      console.log("fetch response", response.data.data);
      setVerifiedData(response.data.data);
    } catch (error) {
      handleCatch(error);
    }
  };

  // GET settlement
  const fetchSettlementData = async () => {
    try {
      const response = await fetchSettlementDataApi();
      if (response.status === 200) {
        console.log("response", response);
        setSettlementData(response.data.data)
      }
    } catch (error) {
      handleCatch(error);
    }
  }

  // POST settlement data update
  const updateSettlementData = async (payload) => {
    const body = { data: payload };
    try {
      const response = await updateSettlementDataApi(body);
      console.log("Response data:", response);
    } catch (error) {
      handleCatch(error);
    }
  };
  // GET settlement id
  const fetchSettleMentId = async (payload) => {
    setSettlementId("")
    const params = { source: 'erp', post_trade_date: payload };
    try {
      const response = await fetchSettleMentIdApi(params);
      setSettlementId(response.data?.data?.settlement_id)
    } catch (error) {
      handleCatch(error);
    }
  };

  // GET fetch dpt file
  const fetchDptFile = async (dateYYYYMMDD) => {
    try {
      const response = await fetchDptFileApi(dateYYYYMMDD);
      const blob = new Blob([response.data], { type: "text/plain" }); // Adjust the MIME type according to your file type
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      const parts = dateYYYYMMDD.split("-");
      const formattedDate = parts[2] + parts[1] + parts[0];
      a.download = `08DPT3U.${formattedDate}032000.SOD`;
      document.body.appendChild(a); // Append the anchor element to the body
      a.click();
      document.body.removeChild(a); // Remove the anchor element after download

    } catch (error) {
      const err = JSON.parse(await error.response.data.text());
      toast.error(err.message);
      console.error('Error fetching file:', error);
      toast.error("Network Error")
    }
  };



  return (
    <EdisContext.Provider value={{ handleFetchGetData, fetchClientDataCode, clientData, settlementId, stockVerificationData, loading, setLoading, confirmVerification, verifiedData, fetchSettlementData, settlementData, fetchSettleMentId, updateSettlementData, fetchDptFile }}>
      {props.children}
    </EdisContext.Provider>
  );
};
