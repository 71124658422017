import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const LmsHome = Loadable(lazy(() => import("views/utilities/lms/dashboard")));
const LmsIndex = Loadable(lazy(() => import("views/utilities/lms/index")));

const LmsUtilities = Loadable(lazy(() => import("views/utilities/lms/utils")));
const LmsReports = Loadable(
  lazy(() => import("views/utilities/lms/reports/index"))
);
const Mis = Loadable(
  lazy(() => import("views/utilities/lms/reports/mis/index"))
);
const LmsMeeting = Loadable(
  lazy(() => import("views/utilities/lms/utils/Meeting/index"))
);
const LeadData = Loadable(lazy(() => import("views/utilities/lms/utils/data")));

export const lmsModule = {
  path: "lms",
  element: <LmsIndex />,
  children: [
    {
      path: "home",
      element: <LmsHome />
    },
    {
      path: "utils",
      element: <LmsUtilities />
    },
    {
      path: "reports",
      element: <LmsReports />
    }
  ]
};

export const LmsUtilsRoutes = {
  path: "lms",
  children: [
    {
      path: "meeting",
      element: <LmsMeeting />
    },
    {
      path: "lead",
      element: <LeadData />
    }
  ]
};

export const LmsReportsRoutes = {
  path: "lms",
  children: [
    {
      path: "mis-report",
      element: <Mis />
    }
  ]
};
