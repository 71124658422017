/*eslint-disable*/
import { commonApiRequestNew } from "utils/CommonFunctions";

const apiUrl = process.env.REACT_APP_URL_PRO_RMS;

export const clinetDataApi = async () => {
  const url = `${apiUrl}/v1/top10-cwm`;
  return commonApiRequestNew("GET", url);
};

export const bwmDataApi = async () => {
  const url = `${apiUrl}/v1/bwm`;
  return commonApiRequestNew("GET", url);
};

export const globalMarginDataApi = async () => {
  const url = `${apiUrl}/v1/global-margin`;
  return commonApiRequestNew("GET", url);
};

export const twmDataApi = async () => {
  const url = `${apiUrl}/v1/twm`;
  return commonApiRequestNew("GET", url);
};

export const powtDataApi = async (selectedClient) => {
  const url = `${apiUrl}/v1/potwm?UserId=${selectedClient}`;
  return commonApiRequestNew("GET", url);
};

export const cwmDataApi = async () => {
  const url = `${apiUrl}/v1/cwm`;
  return commonApiRequestNew("GET", url);
};

export const pocwmDataApi = async (selectedClient) => {
  const url = `${apiUrl}/v1/pocwm?UserId=${selectedClient}`;
  return commonApiRequestNew("GET", url);
};
