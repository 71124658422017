import { FILE } from "config";

const ComplianceReportsData = [
  {
    app_id: 19,
    report_id: 19001,
    type: FILE,
    subfolder: [],
    report_name: "compliance-cce-reports",
    label: "CCE",
    path: "/compliance/cce-reports"
  },
  {
    app_id: 19,
    report_id: 19002,
    type: FILE,
    subfolder: [],
    report_name: "compliance-ucc-reports",
    label: "UCC",
    path: "/compliance/ucc-reports"
  }
];

export default ComplianceReportsData;
