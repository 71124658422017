/*eslint-disable*/
import { commonApiRequest, commonApiRequestNew } from "utils/CommonFunctions";

const apiUrl = process.env.REACT_APP_URL_INWARD;

export const closureApprovalApi = async (action, inwardId, rejectReason) => {
  const url = `${apiUrl}/v1/user/closure_checker_approval?process=${action}`;
  const body = {
    inward_id: inwardId,
    rejection_reason: rejectReason,
  };

  return commonApiRequestNew("POST", url, body);
};

export const ClouserDataApi = async (
  ApprovalStatus,
  globalFilter,
  pagination
) => {
  let url = `${apiUrl}/v1/user/inward_clouser_checker_data?status_type=${ApprovalStatus}`;
  if (globalFilter) {
    if (globalFilter.length < 2) return;
    url += `?client_code=${globalFilter}`;
  }

  const body = {
    page_index: pagination.pageIndex + 1,
    page_size: pagination.pageSize,
  };
  return commonApiRequestNew("POST", url, body);
};

export const segmentAdditionDataApi = async (
  ApprovalStatus,
  globalFilter,
  pagination
) => {
  let url = `${apiUrl}/v1/user/inward/segment_checker_data?status_type=${ApprovalStatus}`;
  if (globalFilter) {
    if (globalFilter.length < 2) return;
    url += `?client_code=${globalFilter}`;
  }

  const body = {
    page_index: pagination.pageIndex + 1,
    page_size: pagination.pageSize,
  };
  return commonApiRequestNew("POST", url, body);
};

export const segmentApprovalApi = async (action, inwardId, rejectReason) => {
  const url = `${apiUrl}/v1/user/inward/segment_checker_approval?process=${action}`;
  const body = {
    inward_id: inwardId,
    rejection_reason: rejectReason,
  };
  return commonApiRequestNew("POST", url, body);
};

export const reKycApis = async (values) => {
  const url = `${process.env.REACT_APP_URL_KYC_MODIFICATION}/v1/offline-kyc/re-kyc-close?client_code=${values.client_id}&types=${values.type}`;
  return commonApiRequest("GET", url);
};

export const cleintMasterApi = async (clientCode, file) => {
  const formData = new FormData();
  if (clientCode) {
    formData.append("client_code", clientCode);
  } else {
    formData.append("csv_file", file);
  }
  // const url = `${process.env.REACT_APP_URL_INWARD}/trading_alert/v1/client-master/`;
  const url = "http://192.168.119.192:3010/trading_alert/v1/client-master/";
  return commonApiRequest("POST", url, formData);
};

export const modificationPdfApi = async (inwardId) => {
  const url = `${apiUrl}/v1/checker/checker-inward-proof?inward_id=${inwardId}`;
  return commonApiRequestNew("GET", url);
};

export const techExcelPushApi = async (
  inwardId,
  clientCode,
  status,
  rejectReason
) => {
  const formData = new FormData();
  formData.append("client_code", clientCode);
  formData.append("rejection_reason", rejectReason);

  const url = `${apiUrl}/v1/checker/checker-inward-tech-push-acc-opening?inward_id=${inwardId}&inward_status=${status}&inward_types=Account_opening`;
  return commonApiRequestNew("POST", url, formData);
};

export const dormantApi = async (
  pagination,
  type,
  dormantType,
  globalFilter
) => {
  console.log("globalFilter", globalFilter);
  const url = `http://192.168.119.183:4004/v1/checker/dormat/client-lists?page_number=${
    pagination.pageIndex + 1
  }&page_size=${
    pagination.pageSize
  }&doormat_type=${type}&status=${dormantType}`;

  if (globalFilter) {
    url += `&client_code=${globalFilter}`;
  }
  return commonApiRequestNew("GET", url);
};

export const verificationDetails = (params) => {
  const url = `${process.env.REACT_APP_URL_VERIFICATION_DETAIL}/trading_alert/v1/v1/email_mobile_verification/email_verify`;
  return commonApiRequestNew("GET", url, null, params);
};

export const uploadProof = (params, formData) => {
  const url = `${process.env.REACT_APP_URL_VERIFICATION_DETAIL}/trading_alert/v1/v1/email_mobile_verification/email_verify`;
  return commonApiRequestNew("POST", url, formData, params);
};

export const getClientMasterApi = (body) => {
  const url = `${process.env.REACT_APP_URL_INWARD}/v1/client-master`;
  return commonApiRequestNew("POST", url, body)
}

export const sendEmailApi = (params) => {
  const url = `${process.env.REACT_APP_URL_INWARD}/v1/checker/checker-mail-sent`
  return commonApiRequestNew("GET", url, null, params)
}