import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const ForgotPass = Loadable(lazy(() => import('views/pages/authentication/forgot-password/ForgotPass')));
const NewPassword = Loadable(lazy(() => import('views/pages/authentication/forgot-password/new-password/NewPassword')));
const Error404Page = Loadable(lazy(() => import('views/Error404Page')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'forgotPassword',
      element: <ForgotPass />
    },
    {
      path: 'change-password',
      element: <NewPassword />
    },
    {
      path: 'error404',
      element: <Error404Page />
    },
    {
      path: '*',
      element: <Error404Page />
    }
  ]
};

export default AuthenticationRoutes;
