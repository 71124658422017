/*eslint-disable*/
import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { Grid, IconButton, Link, useMediaQuery, useTheme } from '@mui/material';
import { Group } from '@mui/icons-material';

import { ChatContext } from 'context/chat-app/ChatApis';
import { formatTimestampToDDMMYY, formatTimestampToHHMM, getFirstAndLastInitials, stringToColor } from 'utils/CommonFunctions';
import { addSessionStorage, encryptData } from 'utils/encrpytion';

const MessageBar = ({ setDrawerOpen = () => { }, setMsgBarOpen = () => { }, setIsOpen = () => { } }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();

    const { contactList, chatList, fetchChatHistory, setChatList, createNewChat, fetchChatList, setMsgSent, fetchEmployeesList } = useContext(ChatContext);

    const [chats, setChats] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [msg, setMsg] = useState(true);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        filterUsers(e.target.value);
    };

    const filterUsers = (query) => {
        const filtered = chatList?.filter((item) =>
            item?.full_name?.toLowerCase()?.includes(query?.toLowerCase())
        );

        // If chatList has results, set them, otherwise filter contactList
        if (filtered?.length > 0) {
            setMsg(true);
            setChats(filtered);
        } else {
            setMsg(false);
            const filteredContactList = contactList?.filter((item) =>
                item?.full_name?.toLowerCase()?.includes(query?.toLowerCase())
            );
            setContacts(filteredContactList);
        }
    };


    // console.log('chatList', chatList)

    const handleOpenChatMsg = (user) => {

        const urlEncoded = encryptData(user?.type === "group" ? `group-${user?.chat_id}` : user?.participants);
        if (setDrawerOpen) {
            navigate(`/utilities/chat/${urlEncoded}`)
            setDrawerOpen(false);
        } else if (isMobile) {
            setMsgSent(false);
            setMsgBarOpen(false);
        }

        console.log('user chatlist', user)

        addSessionStorage("chatData", user)
        addSessionStorage("currChatId", user?.chat_id)
        addSessionStorage("currChatEmpId", user?.participants)
        addSessionStorage("chatUserName", user.full_name)
        addSessionStorage("chatUserActive", user.is_active)
        addSessionStorage("chat_type", user.type)
        addSessionStorage("chatParticipants", user.participants)
        addSessionStorage("chatAdmins", user.admins)
        fetchChatHistory(user.chat_id, 1);
        setChatList((prev) => prev.map((item) => {
            if (user?.chat_id == item?.chat_id) {
                return { ...item, UnseenMessageCount: 0 }
            } else {
                return item;
            }
        }))
    }

    const handleOpenChatContact = (user) => {
        const urlEncoded = encryptData(user?.UID);
        if (setDrawerOpen) {
            navigate(`/utilities/chat/${urlEncoded}`)
            setDrawerOpen(false);
        }
        console.log('user contact', user)
        setIsOpen(false);
        setMsgBarOpen(false);

        addSessionStorage("chatData", user)
        addSessionStorage("chatParticipants", user?.participants)
        addSessionStorage("currChatEmpId", user?.UID)
        addSessionStorage("chatUserName", user?.full_name)
        addSessionStorage("chatUserActive", user?.is_active)
        addSessionStorage("chat_type", user?.type)

        const body = {
            participant_ids: user?.UID
        }
        createNewChat(body);
    }

    useEffect(() => {
        fetchChatList();
        fetchEmployeesList();
    }, [])

    useEffect(() => {
        setChats(chatList);
    }, [chatList])

    return (
        <div className="sidebar">
            <input
                type="text"
                className="search-bar"
                placeholder="Search Contact..."
                value={searchQuery}
                onChange={handleSearchChange}
            />
            <div className="chats">
                {!msg
                    ?
                    contacts?.map((user, index) => {
                        const avatarColor = stringToColor(user?.full_name);

                        const initials = getFirstAndLastInitials(user?.full_name);

                        return (
                            <Grid container
                                key={index}
                                className='chat'
                                id="createNewGroupContainer"
                                onClick={() => handleOpenChatContact(user)}
                            >
                                <Grid item xs={2}>
                                    <IconButton
                                        id="createNewGroupBtn"
                                        className="contactbar-avatar"
                                        variant="contained"
                                        style={{
                                            backgroundColor: avatarColor,
                                        }}
                                    >
                                        {initials}
                                        <div
                                            style={{
                                                backgroundColor: user?.is_active ? "green" : "#F44336",
                                            }}
                                        />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <Grid container display="flex" flexDirection="column">
                                        <Grid item xs={12} display="flex" justifyContent="end">
                                            <label
                                                htmlFor="createNewGroupBtn"
                                                className="details"
                                                style={{ fontSize: "13px" }}
                                            >
                                                {user?.full_name}
                                            </label>
                                        </Grid>
                                        <Grid item xs={12} display="flex" justifyContent="space-between">
                                            <span className='contactbar-department'>{user?.department_name}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })

                    :
                    chats?.map((user, index) => {
                        const avatarColor = stringToColor(user?.full_name);

                        const initials = getFirstAndLastInitials(user?.full_name);

                        // Get today's date in the format used in datestring
                        const today = new Date();
                        const todayDate = today.toISOString().split('T')[0];
                        const timeHhMm = formatTimestampToHHMM(user?.updated_at ? user.updated_at : user?.created_at);
                        const timeDdMmYyHhMm = formatTimestampToDDMMYY(user?.updated_at ? user.updated_at : user?.created_at)

                        return (
                            <Link key={index} onClick={() => handleOpenChatMsg(user)
                            }
                                className='messagebar-link'
                            >
                                <Grid container
                                    className='chat'
                                >
                                    <Grid item xs={2}>
                                        <IconButton
                                            id="createNewGroupBtn"
                                            className="avatar"
                                            variant="contained"
                                            style={{
                                                backgroundColor: avatarColor,
                                                fontSize: "12px",
                                                border: "none",
                                                position: "relative"
                                            }}
                                        >
                                            {initials}
                                            {user?.type === "group" ?
                                                <Group className='group-avatar' color='info' />
                                                :
                                                <div
                                                    className='avatar-common'
                                                    style={{
                                                        backgroundColor: user?.is_active === true ? "green" : "#F44336"
                                                    }}
                                                />}
                                        </IconButton>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <Grid container display="flex" flexDirection="column">
                                            <Grid item xs={12} display="flex" justifyContent="space-between">
                                                {
                                                    user?.UnseenMessageCount == 0 ?
                                                        <span className='chat-name'>
                                                            {user?.full_name}
                                                        </span>
                                                        :
                                                        <>
                                                            <span className='chat-name2'>
                                                                {user?.full_name}
                                                            </span>
                                                            <span className='unseen-msg-count-wrapper'>
                                                                <small className='unseen-msg-count'>
                                                                    {user?.UnseenMessageCount}
                                                                </small>
                                                            </span>
                                                        </>
                                                }
                                            </Grid>
                                            <Grid item xs={12} display="flex" justifyContent="space-between">
                                                <span className='messagebar-text'>Say Hi to {user?.full_name}</span>
                                                <span className='messagebar-time'>
                                                    {(user?.updated_at || user?.created_at).includes(todayDate) ? timeHhMm : timeDdMmYyHhMm}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Link>
                        )
                    })
                }
            </div>
        </div >
    );
};

export default MessageBar;
