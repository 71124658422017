import { commonApiRequest } from "utils/CommonFunctions";

export const fetchClientReport = () => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/client-report`;
  return commonApiRequest("GET", url);
};

export const fetchRevenueReport = (startDate, endDate) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/revenue-report?start_date=${startDate}&end_date=${endDate}`;
  return commonApiRequest("GET", url);
};

export const fetchMarginReport = (period) => {
  const url = `${process.env.REACT_APP_URL_CRMS}/v1/margin-report?period=${period}`;
  return commonApiRequest("GET", url);
};

export const fetchTechExcelData = (values) => {
  const url = `${
    process.env.REACT_APP_URL_CRMS
  }/v1/insert-tech-excel-data?start_date=${values.start_date.replace(
    /-/g,
    ""
  )}&end_date=${values.end_date.replace(/-/g, "")}&type=${values.type}`;
  return commonApiRequest("POST", url);
};
