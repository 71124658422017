import { tabsConfig, userAccessConfig } from "../Config";

export const getBranchOptions = (userProfile, userType) => {
  const profile = userAccessConfig.find(
    (config) => config.value === userProfile || config.value === userType
  );

  if (profile) {
    return userAccessConfig
      .filter((config) => profile.roles.includes(config.value))
      .map((config) => ({ value: config.value, label: config.label }));
  }

  return [];
};

export const getUserTabs = (userProfile) => {
  const tabs = tabsConfig[userProfile] || [];
  // if (!tabs.includes("utilities")) {
  //   tabs.push("utilities");
  // }
  return tabs;
};