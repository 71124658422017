/*eslint-disable*/
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import {
  addEvent,
  updateEvent,
  getEventDetails,
  getApprovalPersonList,
  approveRejectEvent,
} from "services/event/EventPlanningService";

export const EventPlanningContext = createContext();

export default function EventPalnningApis(props) {
  const [eventPlanningData, setEventPlanningData] = useState([]);
  const [approvalPersonList, setApprovalPersonList] = useState([]);

  const handleAddEventPlanning = async (values, status, setOpenDailog) => {
    try {
      const response = await addEvent(values);
      if (response.status === 201) {
        toast.success(response.data.message);
        handleFetchEventPlanning(status);
        setOpenDailog(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleFetchEventPlanning = async (params) => {
    try {
      const resJson = await getEventDetails(params);
      if (resJson.status === 200) {
        setEventPlanningData(resJson.data.data);
      } else {
        toast(resJson.data.message || "Failed to fetch data");
      }
    } catch (err) {
      toast.error("handleFetchEventPlanning Error");
    }
  };

  const handleUpdateEventPlanning = async (
    values,
    status = "all",
    setOpenDailog
  ) => {
    try {
      const response = await updateEvent(values);
      if (response.status === 200) {
        toast.success(response.data.message);
        handleFetchEventPlanning(status);
        setOpenDailog(false);
      }
    } catch (error) {
      toast.error(error.data.message);
    }
  };

  const handleFetchApprovalPersonList = async () => {
    try {
      const response = await getApprovalPersonList();
      if (response.status === 200) {
        setApprovalPersonList(response.data.data);
        // handleFetchEventPlanning(status);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleApproveRejectStatus = async (
    event_id,
    reason = "",
    action,
    status = "all",
    setOpenDailog = () => {}
  ) => {
    try {
      const response = await approveRejectEvent({
        event_id,
        reason,
        action,
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        handleFetchEventPlanning(status);
        setOpenDailog(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <EventPlanningContext.Provider
      value={{
        handleAddEventPlanning,
        handleFetchEventPlanning,
        eventPlanningData,
        handleUpdateEventPlanning,
        handleFetchApprovalPersonList,
        approvalPersonList,
        handleApproveRejectStatus,
      }}
    >
      {props.children}
    </EventPlanningContext.Provider>
  );
}
