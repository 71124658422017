/*eslint-disable*/
import React, { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import {
  fetchKycB2BData,
  fetchKycB2CData,
  fetchKycDashBoardApi,
  fetchKycDataTypeWise,
  fetchKycDateWise,
  fetchRoleWiseBranchCode,
} from "services/crms/kyc";
import {
  fetchClientReport,
  fetchMarginReport,
  fetchRevenueReport,
  fetchTechExcelData,
} from "services/crms/report";
import {
  fetchRevenueB2BWise,
  fetchRevenueB2CWise,
  fetchRevenueChartData,
  fetchRevenueData,
  fetchRevenueDataStatusWise,
  fetchRevenueSegmentWise,
} from "services/crms/revenue";
import { formatMonth, getCurrentDates } from "utils/DateUtils";
import { getSessionStorage } from "utils/encrpytion";
import Config, { b2bAccess, bottomLevel } from "views/utilities/crms/Config";
import { getBranchOptions } from "views/utilities/crms/utils/commonUtils";
import { useErrorHandler } from "hooks/useErrorHandler";
import { fetchUploadedFileLogs, uploadTradeFile } from "services/crms/utils";

export const CrmsContext = createContext();

const CRMSApis = (props) => {
  const { monthList } = Config;

  const [tradeFileData, setTradeFileData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLinear, setIsLoadingLinear] = useState(false);
  const [isB2BChartLoading, setIsB2BChartLoading] = useState(false);
  const [isB2CChartLoading, setIsB2CChartLoading] = useState(false);
  const [btbBranchCodeData, setBTBBranchCodeData] = useState([]);
  const [btcBranchCodeData, setBTCBranchCodeData] = useState([]);
  const [kycData, setKycData] = useState({});
  const [kycMarginData, setKycMarginData] = useState([]);
  const [revenueData, setRevenueData] = useState({});

  const [b2bKycData, setB2BKycData] = useState([]);
  const [isB2BLoading, setIsB2BLoading] = useState(false);

  const [b2cKycData, setB2CKycData] = useState([]);
  const [isB2CLoading, setIsB2CLoading] = useState(false);

  const [kycB2BChartData, setKycB2BChartData] = useState([]);
  const [kycB2BChartDate, setKycB2BChartDate] = useState([]);
  const [kycB2CChartData, setKycB2CChartData] = useState([]);
  const [kycB2CChartDate, setKycB2CChartDate] = useState([]);

  const [kycClientData, setKycClientData] = useState([]);
  const [clientRevenueData, setClientRevenueData] = useState([]);
  const [revenueChartDataBTB, setRevenueChartDataBTB] = useState([]);
  const [revenueDateBTB, setRevenueDateBTB] = useState([]);

  const [revenueChartDataBTC, setRevenueChartDataBTC] = useState([]);
  const [revenueDateBTC, setRevenueDateBTC] = useState([]);

  const [revenueB2CData, setRevenueB2CData] = useState([]);
  const [revenueB2BWiseData, setRevenueB2BWiseData] = useState([]);
  const [kycDetails, setKycDetails] = useState([]);
  const [revenueDataStatusWise, setRevenueDataStatusWise] = useState([]);
  const [revenueSegmentWise, setRevenueSegmentWise] = useState([]);

  const { year, month, day } = getCurrentDates();
  const handleError = useErrorHandler();

  const [selectedPeriod, setSelectedPeriod] = useState("month");
  const [showTitle, setShowTitle] = useState(formatMonth(month));
  const [selectedPeriodValue, setSelectedPeriodValue] = useState(month);
  const [selectedValue, setSelectedValue] = useState({
    year,
    month,
    day,
  });
  const [b2cHierarchyLevel, setB2CHierarchyLevel] = useState(0);
  const [b2cSelectedRole, setB2CSelectedRole] = useState("");
  const [b2bHierarchyLevel, setB2BHierarchyLevel] = useState(0);
  const [b2bSelectedRole, setB2BSelectedRole] = useState("");

  const userProfile = getSessionStorage("user_profile");
  const userType = getSessionStorage("user_type");
  const techCode = getSessionStorage("tech_code");

  const branchOptions = getBranchOptions(userProfile, userType);
  const hasB2BAccess = b2bAccess.includes(userProfile);
  const atBottomLevel = bottomLevel.includes(userProfile);

  const clearKycData = () => {
    setKycData({});
    setB2BKycData([]);
    setB2CKycData([]);
    setKycB2CChartData([]);
    setKycB2BChartData([]);
  };

  const clearRevenueData = () => {
    setRevenueData({});
    setRevenueB2BWiseData([]);
    setRevenueB2CData([]);
    setRevenueChartDataBTB([]);
    setRevenueChartDataBTC([]);
  };

  //Fetch KYC DashBoard Data
  const handleFetchKycData = async (period, tdate) => {
    setIsLoading(true);
    setKycData({});
    try {
      const response = await fetchKycDashBoardApi(period, tdate);
      const resJson = response.data;

      if (resJson.status == "success") {
        console.log("res", resJson);

        setKycData(resJson.data);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  ///KYC- FetchKycB2BData
  const handleFetchKycB2BData = async (
    role = "",
    code = "",
    level = 0,
    period,
    tdate = "",
    branchCode
  ) => {
    setIsB2BLoading(true);
    setB2BKycData([]);
    setB2BHierarchyLevel("");
    setB2BSelectedRole("");
    try {
      const response = await fetchKycB2BData(role, code, level, period, tdate);
      const resJson = response.data;
      if (resJson.status == "success") {
        const tempData = resJson.data.kyc.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setB2BKycData(tempData);
        setB2BHierarchyLevel(resJson?.data?.level);
        setB2BSelectedRole(resJson?.data?.role);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsB2BLoading(false);
    }
  };

  ///KYC- FetchKycB2CData
  const handleFetchKycB2CData = async (
    role = "",
    code = "",
    level = 0,
    period,
    tdate = "",
    branchCode
  ) => {
    setIsB2CLoading(true);
    setB2CKycData([]);
    setB2CHierarchyLevel("");
    setB2CSelectedRole("");
    try {
      const response = await fetchKycB2CData(role, code, level, period, tdate);
      const resJson = response.data;

      if (resJson.status == "success") {
        const tempData = resJson?.data?.kyc?.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setB2CKycData(tempData);
        setB2CHierarchyLevel(resJson?.data?.level);
        setB2CSelectedRole(resJson?.data?.role);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsB2CLoading(false);
    }
  };

  ///KYC - Fetch Kyc Data Chart Wise
  const handleFetchKycDateWise = async (
    branchType,
    branchCode,
    base_type,
    period,
    trade_month = ""
  ) => {
    setLoadingFunction(base_type, true);

    if (base_type === "BTB") {
      setKycB2BChartDate([]);
      setKycB2BChartData([]);
    } else {
      setKycB2CChartDate([]);
      setKycB2CChartData([]);
    }

    try {
      const response = await fetchKycDateWise(
        branchType,
        branchCode,
        base_type,
        period,
        trade_month
      );

      const resJson = response.data;
      if (resJson.status === "success") {
        const tDate = [];
        const kycCount = [];

        resJson.data.forEach((item) => {
          const dateStr = String(item.kyc_date);

          let formattedDate;

          if (period === "month") {
            // kyc_date format: "20240504"
            const year = dateStr.slice(0, 4);
            const month = dateStr.slice(4, 6);
            const day = dateStr.slice(6, 8);
            const date = new Date(`${year}-${month}-${day}`);
            formattedDate = date.toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
            });
          } else {
            // kyc_date format: "042024"
            const month = dateStr.slice(0, 2);
            const year = dateStr.slice(2, 6);
            const date = new Date(`${year}-${month}-01`); // Use the first day of the month for formatting
            formattedDate = date.toLocaleDateString("en-GB", {
              month: "short",
              year: "numeric",
            });
          }

          tDate.push(formattedDate);
          kycCount.push(item.kyc);
        });

        if (base_type === "BTB") {
          setKycB2BChartDate(tDate);
          setKycB2BChartData(kycCount);
        } else {
          setKycB2CChartDate(tDate);
          setKycB2CChartData(kycCount);
        }
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingFunction(base_type, false);
    }
  };

  //KYC- Kyc Type wise ex:b2b,btc
  const fetchKycData = async (value, period, tdate) => {
    setIsLoadingLinear(true);
    setKycDetails([]);

    try {
      const response = await fetchKycDataTypeWise(value, period, tdate);
      const resJson = response.data;

      if (resJson.status == "success") {
        const tempData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setKycDetails(tempData);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoadingLinear(false);
    }
  };

  // Fetch Branch Code
  const handleFetchRoleWiseBranchCode = async (role, branch_type) => {
    if (branch_type == "BTB") {
      setBTBBranchCodeData([]);
    } else {
      setBTCBranchCodeData([]);
    }
    try {
      const response = await fetchRoleWiseBranchCode(role, branch_type);

      const resJson = response.data;
      if (resJson.status == "success") {
        if (branch_type == "BTB") {
          setBTBBranchCodeData(resJson.data);
        } else {
          setBTCBranchCodeData(resJson.data);
        }
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Revenue - Fetch Revenue DashBoard data
  const handleFetchRevenueData = async (period, tdate = "") => {
    setIsLoading(true);
    setRevenueData({});
    try {
      const response = await fetchRevenueData(period, tdate);
      const resJson = response.data;

      if (resJson.status == "success") {
        setRevenueData(resJson.data);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setLoadingFunction = (base_type, isLoading) => {
    if (base_type === "BTB") {
      setIsB2BChartLoading(isLoading);
    } else if (base_type === "BTC") {
      setIsB2CChartLoading(isLoading);
    }
  };

  //Revenue - Fetch Revenue B2B Wise
  const handleFetchRevenueB2BWise = async (role = "",
    code = "",
    level = 0,
    period,
    tdate = "",
    branchCode) => {
    setIsB2BLoading(true);
    setRevenueB2BWiseData([]);
    setB2BHierarchyLevel("");
    setB2BSelectedRole("");
    try {
      const response = await fetchRevenueB2BWise(role, code, level, period, tdate);
      const resJson = response.data;

      if (resJson.status == "success") {
        const revenueB2BData = resJson.data.revenue.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setRevenueB2BWiseData(revenueB2BData);
        setB2BHierarchyLevel(resJson?.data?.level);
        setB2BSelectedRole(resJson?.data?.role);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsB2BLoading(false);
    }
  };

  //Revenue - Fetch Revenue B2C Wise
  const handleFetchRevenueB2CWise = async (
    role = "",
    code = "",
    level = 0,
    period,
    tdate = "",
    branchCode
  ) => {
    setIsB2CLoading(true);
    setRevenueB2CData([]);
    setB2CHierarchyLevel("");
    setB2CSelectedRole("");
    try {
      const response = await fetchRevenueB2CWise(
        role,
        code,
        level,
        period,
        tdate
      );
      const resJson = response.data;

      if (resJson.status == "success") {
        const revenueData = resJson.data.revenue.map((item, index) => ({
          ...item,
          id: index + 1,
        }));

        setRevenueB2CData(revenueData);
        setB2CHierarchyLevel(resJson?.data?.level);
        setB2CSelectedRole(resJson?.data?.role);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsB2CLoading(false);
    }
  };

  ///Revenue - Fetch Chart Data
  const handleFetchRevenueDateWise = async (
    branchType,
    branchCode,
    base_type,
    period,
    tdate = ""
  ) => {
    setLoadingFunction(base_type, true);

    if (base_type === "BTB") {
      setRevenueChartDataBTB([]);
      setRevenueDateBTB([]);
    } else {
      setRevenueChartDataBTC([]);
      setRevenueDateBTC([]);
    }

    try {
      const response = await fetchRevenueChartData(
        branchType,
        branchCode,
        base_type,
        period,
        tdate
      );

      const resJson = response.data;

      if (resJson.status === "success") {
        const tDate = [];
        const revenues = [];

        resJson.data.forEach((item) => {
          const dateStr = String(item.tdate);

          let formattedDate;

          if (period === "month") {
            // kyc_date format: "20240504"
            const year = dateStr.slice(0, 4);
            const month = dateStr.slice(4, 6);
            const day = dateStr.slice(6, 8);
            const date = new Date(`${year}-${month}-${day}`);
            formattedDate = date.toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
            });
          } else {
            // kyc_date format: "042024"
            const month = dateStr.slice(0, 2);
            const year = dateStr.slice(2, 6);
            const date = new Date(`${year}-${month}-01`);
            formattedDate = date.toLocaleDateString("en-GB", {
              month: "short",
              year: "numeric",
            });
          }

          tDate.push(formattedDate);
          revenues.push(item.revenue);
        });

        if (base_type === "BTB") {
          setRevenueChartDataBTB(revenues);
          setRevenueDateBTB(tDate);
        } else {
          setRevenueChartDataBTC(revenues);
          setRevenueDateBTC(tDate);
        }
      } else {
        console.error("Error:", resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingFunction(base_type, false);
    }
  };

  ///Revenue - Fetch Revenue Segment Wise Dashboard
  const handleFetchRevenueSegmentWise = async (period, tdate) => {
    setRevenueSegmentWise([]);
    setIsLoading(true);
    try {
      const response = await fetchRevenueSegmentWise(period, tdate);
      const resJson = response.data;

      if (resJson.status == "success") {
        if (resJson.data && resJson.data[0]) {
          setRevenueSegmentWise(resJson.data[0]);
        } else {
          setRevenueSegmentWise([{}]);
        }
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  ///Revenue - Fetch Revenue Status Wise ---BTB BTC
  const handleFetchRevenueDataStatusWise = async (status, period, tdate) => {
    setRevenueDataStatusWise([]);
    setIsLoadingLinear(true);
    try {
      const response = await fetchRevenueDataStatusWise(status, period, tdate);
      const resJson = response.data;

      if (resJson.status === "success") {
        const tempData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setRevenueDataStatusWise(tempData);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoadingLinear(false);
    }
  };

  ////Report

  ///Fetch Client Report
  const handleFetchDataClientWise = async () => {
    setIsLoading(true);
    setKycClientData([]);
    try {
      const response = await fetchClientReport();
      const resJson = response.data;
      if (resJson.status == "success") {
        const kycClientData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setKycClientData(kycClientData);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  ///Fetch Client Report
  const handleFetchClientRevenueData = async (startDate, endDate) => {
    setIsLoading(true);
    setClientRevenueData([]);
    try {
      const response = await fetchRevenueReport(startDate, endDate);
      const resJson = response.data;

      if (resJson.status == "success") {
        const revenueData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setClientRevenueData(revenueData);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  ///Fetch Margin Report
  const fetchMarginData = async (period) => {
    setKycMarginData([]);
    try {
      const response = await fetchMarginReport(period);
      const resJson = response.data;

      if (resJson.status == "success") {
        const tempData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setKycMarginData(tempData);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      handleError(error);
    }
  };

  ///Fetch Tech Excel Data
  const handleTechExcelData = async (
    values,
    { setErrors, setSubmitting, resetForm }
  ) => {
    setIsLoading(true);
    try {
      const response = await fetchTechExcelData(values);
      const resJson = response.data;

      if (resJson.status == "success") {
        // console.log("hsf", resJson);
        toast.success(resJson.message);
        setSubmitting(true);
        resetForm({ values: "" });
      } else {
        // console.log("Eror");
        setErrors({ submit: resJson.message });
        setSubmitting(false);
        toast.error(resJson.message);
      }
    } catch (error) {
      setErrors({ submit: "An error occurred while Fetch Data" });
      setSubmitting(false);
      handleError(error);
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTradeFileUpload = async (values) => {
    setIsLoading(true);

    const formData = new FormData();
    try {
      formData.append("segment", values.fileType);
      formData.append("trade_date", values.trade_date);
      formData.append("file", values.file);

      const response = await uploadTradeFile(formData);
      const resJson = response.data;

      if (resJson.status == "success") {
        toast.success(resJson.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTradeFileLogs = async (segment) => {
    setIsLoading(true);
    setTradeFileData([]);
    try {
      const response = await fetchUploadedFileLogs(segment);
      const resJson = response.data;

      if (resJson.status == "success") {
        setTradeFileData(resJson?.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CrmsContext.Provider
        value={{
          isLoading,
          showTitle,
          setShowTitle,

          isB2BChartLoading,
          isB2CChartLoading,

          handleFetchRoleWiseBranchCode,
          btbBranchCodeData,
          btcBranchCodeData,
          handleFetchKycData,
          kycData,
          setKycData,
          handleFetchRevenueData,
          revenueData,
          handleFetchKycB2BData,
          isB2BLoading,
          b2bKycData,
          handleFetchKycB2CData,
          isB2CLoading,
          b2cKycData,

          handleFetchKycDateWise,
          kycB2BChartData,
          kycB2BChartDate,
          kycB2CChartDate,
          kycB2CChartData,

          handleFetchDataClientWise,
          kycClientData,
          handleFetchClientRevenueData,
          clientRevenueData,

          handleFetchRevenueDateWise,
          revenueChartDataBTB,
          revenueDateBTB,
          revenueChartDataBTC,
          revenueDateBTC,

          handleFetchRevenueB2CWise,
          revenueB2CData,

          handleFetchRevenueB2BWise,
          revenueB2BWiseData,

          fetchKycData,
          kycDetails,

          fetchMarginData,
          kycMarginData,

          handleFetchRevenueDataStatusWise,
          revenueDataStatusWise,

          isLoadingLinear,
          handleFetchRevenueSegmentWise,
          revenueSegmentWise,
          handleTechExcelData,
          setSelectedPeriod,

          selectedPeriod,
          selectedPeriodValue,
          setSelectedPeriodValue,

          selectedValue,
          setSelectedValue,

          branchOptions,
          hasB2BAccess,
          atBottomLevel,
          clearRevenueData,
          clearKycData,

          userProfile,
          techCode,

          b2cHierarchyLevel,
          b2cSelectedRole,

          b2bHierarchyLevel,
          b2bSelectedRole,

          handleTradeFileUpload,
          handleTradeFileLogs,
          tradeFileData,
        }}
      >
        {props.children}
      </CrmsContext.Provider>
    </>
  );
};

export default CRMSApis;
