import { FILE } from "config";

const BounceMailReportData = [
  {
    app_id: 18,
    report_id: 18001,
    label: "Bounce Log",
    type: FILE,
    subfolder: [],
    report_name: "bounce-mail-logs",
    path: "/bounce-mail/bounce-logs"
  },
  {
    app_id: 18,
    report_id: 18002,
    type: FILE,
    subfolder: [],
    report_name: "bounce-send-logs",
    label: "Send Log",
    path: "/bounce-mail/send-logs"
  },
  {
    app_id: 18,
    report_id: 18003,
    type: FILE,
    subfolder: [],
    report_name: "bounce-summary-report",
    label: "Summary Report",
    path: "/bounce-mail/summary-report"
  },
];

export default BounceMailReportData;
