/*eslint-disable*/
import React, { useState, createContext } from "react";

import { toast } from "react-toastify";

import { checkUserNameValidityApi, createNewUserApi, fetchAllListsApi, fetchUserInfoApi, fetchUserTableInfoApi, updateEnableDisableStatusApi, updateUserPasswordApi, userUpdateInfoApi } from "services/user-management/userManagement";
import { handleCatch } from "utils/CommonFunctions";

export const UserManagementContext = createContext();

export default function UserManagementApis(props) {

  const [tableData, setTableData] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [userInfoProfile, setUserInfoProfile] = useState([]);
  const [userInfoRole, setUserInfoRole] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [reportTo, setReportTo] = useState({});

  const formatDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}-${month}-${year}`
    } else {
      return ""
    }
  };

  //GET employees table data
  const fetchUserTableInfo = async () => {
    try {
      const response = await fetchUserTableInfoApi();
      setTableData(
        response.data.data.map((item, index) => ({ ...item, id: index + 1 }))
      );
      setReportTo(
        response.data.data.reduce((myobj, item) => {
          const myArray = item.full_name?.split(" ");
          const full_name = myArray
            ? `${myArray[0]} ${myArray[myArray.length - 1]}`
            : "";
          return { ...myobj, [item.UID]: full_name };
        }, {})
      );
    } catch (error) {
      handleCatch(error);
      console.log(error);
    }
  };

  //GET employee details form data
  const fetchUserInfo = async (empID) => {
    try {
      const params = { employee_id: empID };

      const response = await fetchUserInfoApi(params);
      setUserInfo(response.data.data);
    } catch (error) {
      console.error("error", error);
      handleCatch(error);
    }
  };

  //put : update employee details form data
  const userUpdateInfo = async (values) => {
    console.log('values', values)
    try {
      const body = {
        employee_id: values.uid,
        user_name: values.user_name,
        department_id: values.department_name.toString(),
        user_profile_id: values.user_profile.toString(),
        user_role_id: values.user_role.toString(),
        user_category_id: values.category_name.toString(),
        user_designation_id: values.designation.toString(),
        report_to: values.report_to,
        joining_date: values.joining_date,
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        mobile_number: values.mobile_number,
        e_mail_id: values.e_mail_id,
        qualification: values.qualification,
        dob: values.dob,
        marital_status: values.marital_status,
        gender: values.gender,
        nationality: values.nationality,
        location: values.location,
        te_branch_code: values.tech_branch_code
      };
      const response = await userUpdateInfoApi(body);
      toast.success(response.data.message);
      fetchUserInfo(values.uid);
    } catch (error) {
      console.error("error", error);
      handleCatch(error);
    }
  };

  //GET : all lists for dropdowns
  const fetchUserInfoList = async () => {
    try {
      const params = { entity_name: 'department_list' };

      const response = await fetchAllListsApi(params);
      setDeptList(response.data.data);
    } catch (error) {
      console.error("error", error);
      handleCatch(error);
    }
    try {
      const params = { entity_name: 'user_profile' };

      const response = await fetchAllListsApi(params);
      setUserInfoProfile(response.data.data);
    } catch (error) {
      handleCatch(error);
    }

    try {
      const params = { entity_name: 'user_role' };

      const response = await fetchAllListsApi(params);
      setUserInfoRole(response.data.data);
    } catch (error) {
      console.error("error", error);
      handleCatch(error);
    }

    try {
      const params = { entity_name: 'user_category' };

      const response = await fetchAllListsApi(params);
      setCategoryList(response.data.data);
    } catch (error) {
      console.error("error", error);
      handleCatch(error);
    }

    try {
      const params = { entity_name: 'user_designation' };

      const response = await fetchAllListsApi(params);
      setDesignationList(response.data.data);
    } catch (error) {
      console.error("error", error);
      handleCatch(error);
    }
  };

  // const checkUserNameValidity = async (userName, setFieldError) => {
  //   try {
  //     const params = { id: userName };
  //     const response = await checkUserNameValidityApi(params);
  //     console.log('response', response)
  //     setFieldError('user_name', ''); // Clear the error if valid
  //   } catch (error) {
  //     setFieldError('user_name', 'Username is invalid.'); // Show Formik error
  //     console.log('error', error)
  //   }
  // };
  const checkUserNameValidity = async (userName) => {
    try {
      const params = { id: userName };
      const response = await checkUserNameValidityApi(params);
      console.log('response', response)
      if (userName.length > 3) {
        return "valid";
      }
    } catch (error) {
      console.log('error', error)
      return "taken";
    }
  };

  const createNewUser = async (values, setAddNewUserDialog) => {
    try {
      const formData = new FormData();

      // Append form data to FormData object
      formData.append('employee_id', values.uid);
      formData.append('user_name', values.user_name);
      formData.append('department_id', values.department_name);
      formData.append('user_profile_id', values.user_profile);
      formData.append('user_role_id', values.user_role);
      formData.append('user_category_id', values.category_name);
      formData.append('user_designation_id', values.designation);
      formData.append('report_to', values.report_to);
      values.joining_date ==
        "" ? formData.append('joining_date', null) : formData.append('joining_date', formatDate(values.joining_date));
      formData.append('first_name', values.first_name);
      formData.append('middle_name', values.middle_name);
      formData.append('last_name', values.last_name);
      formData.append('e_mail_id', values.e_mail_id);
      formData.append('mobile_number', values.mobile_number);
      formData.append('qualification', values.qualification);
      values.dob ==
        "" ? "" :
        formData.append('dob', formatDate(values.dob));

      formData.append('marital_status', values.marital_status);
      formData.append('gender', values.gender);
      formData.append('nationality', values.nationality);
      formData.append('location', values.location);
      formData.append('te_branch_code', values.tech_branch_code);

      console.log("formData", formData);

      const response = await createNewUserApi(formData);

      setAddNewUserDialog(false);
      fetchUserTableInfo();
      toast.success(response.data.message);

    } catch (error) {
      console.error("error", error);
      handleCatch(error);
    }
  };

  //put : update enable disable status
  const updateEnableDisableStatus = async (uid, status) => {
    try {
      const params = { uid: uid, is_enable: status };

      const response = await updateEnableDisableStatusApi(params);
      toast.success(response.data.message);
      fetchUserTableInfo();
    } catch (error) {
      console.error("error", error);
      handleCatch(error);
    }
  };

  //put : update user password
  const updateUserPassword = async (body, setOpen) => {
    try {
      const response = await updateUserPasswordApi(body);
      toast.success(response.data.message);
      fetchUserTableInfo();
      setOpen(false);
    } catch (error) {
      handleCatch(error)
    }
  };

  return (
    <UserManagementContext.Provider
      value={{
        fetchUserInfo,
        tableData,
        setTableData,
        fetchUserTableInfo,
        userInfo,
        setUserInfo,
        fetchUserInfoList,
        userUpdateInfo,
        userInfoRole,
        userInfoProfile,
        deptList,
        categoryList,
        designationList,
        reportTo,
        checkUserNameValidity,
        createNewUser,
        updateEnableDisableStatus,
        updateUserPassword
      }}
    >
      {props.children}
    </UserManagementContext.Provider>
  );
}
