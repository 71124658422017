// config.js

const Config = {
  
    monthList: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC"
    ],
  
    
  
  };
  
  
  export default Config;
  