import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import EPledgeDashboard from "views/utilities/e-pledge/dashboard";
import EPledgereports from "views/utilities/e-pledge/reports";
import EPledgeUtils from "views/utilities/e-pledge/utils";

const EpledgeHome = Loadable(
  lazy(() => import("views/utilities/e-pledge/index"))
);
const HaircutFileUpload = Loadable(
  lazy(() => import("views/utilities/e-pledge/utils/haircut-fileupload"))
);
export const EpledgeModule = {
  path: "epledge",
  element: <EpledgeHome />,
  children: [
    {
      path: "home",
      element: <EPledgeDashboard />,
    },
    {
      path: "utils",
      element: <EPledgeUtils />,
    },
    {
      path: "reports",
      element: <EPledgereports />,
    },
  ],
};

export const EpledgeRoutes = {
  path: "epledge",
  children: [
    {
      path: "haircut-fileupload",
      element: <HaircutFileUpload />,
    },
  ],
};

