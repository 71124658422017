import { commonApiRequest } from "utils/CommonFunctions";

export const handleCceExchangeFilesApi = (formData) => {
    const url = `${process.env.REACT_APP_URL_COMPLIANCE}/v1/upload-cce-exchange`;
    return commonApiRequest('POST', url, formData);
};

export const handleCceTechExcelFileApi = (formData) => {
    const url = `${process.env.REACT_APP_URL_COMPLIANCE}/v1/upload-cce-techexcel`;
    return commonApiRequest('POST', url, formData);
};

export const handleUccExchangeNseFileApi = (formData) => {
    const url = `${process.env.REACT_APP_URL_COMPLIANCE}/v1/upload-ucc-exchange-nse`;
    return commonApiRequest('POST', url, formData);
};

export const handleUccExchangeBseFileApi = (formData) => {
    const url = `${process.env.REACT_APP_URL_COMPLIANCE}/v1/upload-ucc-exchange-bse`;
    return commonApiRequest('POST', url, formData);
};

export const handleUccTechExcelNseFileApi = (formData) => {
    const url = `${process.env.REACT_APP_URL_COMPLIANCE}/v1/upload-ucc-techexcel-nse`;
    return commonApiRequest('POST', url, formData);
};

export const handleUccTechExcelBseFileApi = (formData) => {
    const url = `${process.env.REACT_APP_URL_COMPLIANCE}/v1/upload-ucc-techexcel-bse`;
    return commonApiRequest('POST', url, formData);
};

export const handleFetchCceReportApi = (params) => {
    const url = `${process.env.REACT_APP_URL_COMPLIANCE}/v1/cce-report`;
    return commonApiRequest('GET', url, null, params);
};

export const handleFetchUccReportApi = (params) => {
    const url = `${process.env.REACT_APP_URL_COMPLIANCE}/v1/ucc-report`;
    return commonApiRequest('GET', url, null, params);
};

export const handleFetchDiscrepancyApi = (params) => {
    const url = `${process.env.REACT_APP_URL_COMPLIANCE}/v1/ucc-discrepancy-report`;
    return commonApiRequest('GET', url, null, params);
};
