/*eslint-disable*/
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchEventVideo,
  handleEventVideoAdd,
  handleEventVideoDelete,
  handleEventVideoUpdate,
} from "services/event-video/eventVideo";

export const EventVideoContext = createContext();

export default function eventVideoApi(props) {
  const [eventVideoData, setEventVideoData] = useState([]);

  const fetchAllEventVideo = async () => {
    try {
      const response = await fetchEventVideo();
      console.log("response", response);
      if (response.status === 200) {
        console.log("response", response);
        setEventVideoData(response.data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const addEventVideo = async (values, others) => {
    try {
      const formData = new FormData();
      formData.append("event_video_header", values.event_video_header);
      formData.append("event_video_start_date", values.event_video_start_date);
      formData.append("event_video_end_date", values.event_video_end_date);
      formData.append("event_video_location", values.event_video_location);

      if (values.file) {
        formData.append("file", values.file);
      }
      const resJson = await handleEventVideoAdd(formData);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllEventVideo();
    } catch (error) {
      console.error("error", error);

      toast.error(resJson.message);
    }
  };

  const updateEventVideo = async (values, others, event_video_id) => {
    try {
      const formData = new FormData();
      formData.append("event_video_id", event_video_id);
      formData.append("event_video_header", values.event_video_header);
      formData.append("event_video_start_date", values.event_video_start_date);
      formData.append("event_video_end_date", values.event_video_end_date);
      formData.append("event_video_location", values.event_video_location);

      if (values.file) {
        formData.append("file", values.file);
      }
      const resJson = await handleEventVideoUpdate(formData, event_video_id);
      others.setOpen(false);
      toast.success(resJson.data.message);
      fetchAllEventVideo();
    } catch (error) {
      console.error("error", error);
      toast.error(resJson.message);
    }
  };

  const deleteEventVideo = async (event_video_id) => {
    try {
      const resJson = await handleEventVideoDelete({ event_video_id });
      console.log("resJson", resJson);
      if (resJson.status === 200) {
        fetchAllEventVideo();
        toast.success(resJson.data.message);
      } else {
        toast.error("Failed to delete blog.");
      }
    } catch (error) {
      console.error("Delete blog error:", error);
      toast.error("An error occurred while deleting the blog.");
    }
  };

  return (
    <EventVideoContext.Provider
      value={{
        eventVideoData,
        fetchAllEventVideo,
        setEventVideoData,
        addEventVideo,
        deleteEventVideo,
        updateEventVideo,
      }}
    >
      {props.children}
    </EventVideoContext.Provider>
  );
}
