/*eslint-disable*/
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Dialog,
  DialogContent,
} from "@mui/material";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import StorageIcon from "@mui/icons-material/Storage";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import AddBoxIcon from "@mui/icons-material/AddBox";
import StockTable from "./StockTable";
import AssignChart from "./AssignChart";
import AddBrand from "./addBrand";

const CardItem = ({ Icon, value, label }) => (
  <Box display="flex" flexDirection="column" width="15%" gap={0.5}>
    <Card sx={{ minWidth: 180, border: "1px solid #a5d6a7" }}>
      <CardContent sx={{ display: "flex", alignItems: "center" }}>
        <Icon
          sx={{ fontSize: "2.75rem", color: "primary.main", marginRight: 2 }}
        />
        <Typography variant="h1" sx={{ fontWeight: 300 }}>
          {value}
        </Typography>
      </CardContent>
    </Card>
    <Card
      sx={{
        minWidth: 180,
        height: 35,
        textAlign: "center",
        border: "1px solid #a5d6a7",
      }}
    >
      <Typography variant="body1" sx={{ p: 1 }}>
        {label}
      </Typography>
    </Card>
  </Box>
);

export default function Index() {
  const [brandDialog, setBrandDialog] = useState(false);
  const navigate = useNavigate();
  const cardData = [
    { Icon: StorageIcon, value: 1000, label: "Stock Inventory" },
    { Icon: AssignmentIcon, value: 500, label: "Assigned Inventory" },
    { Icon: AddShoppingCartIcon, value: 200, label: "Pending Delivery" },
    { Icon: CallReceivedIcon, value: 70, label: "Received Delivery" },
    { Icon: Inventory2Icon, value: 100, label: "Pending Requirement" },
    { Icon: ChecklistRtlIcon, value: 50, label: "Approve Requirement" },
  ];

  return (
    <>
      <Grid container spacing={2}>
        {cardData.map((data, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
            <CardItem Icon={data.Icon} value={data.value} label={data.label} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12} md={5}>
          <StockTable />
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="h4" align="center">
            Department
          </Typography>
          <AssignChart />
          <Box sx={{ pt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4.5}>
                <Card>
                  <CardContent sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h5">Add Requirement</Typography>
                    <AddBoxIcon
                      sx={{
                        fontSize: "1.50rem",
                        color: "primary.main",
                        marginLeft: 1,
                      }}
                      onClick={() => navigate("/it-infra/requirement")}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={3.5}>
                <Card>
                  <CardContent sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h5">Add Vendor</Typography>
                    <AddBoxIcon
                      onClick={() => navigate("/it-infra/vendor_addition")}
                      sx={{
                        fontSize: "1.50rem",
                        color: "primary.main",
                        marginLeft: 1,
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h5">Add Brand</Typography>
                    <AddBoxIcon
                      onClick={() => setBrandDialog(true)}
                      sx={{
                        fontSize: "1.50rem",
                        color: "primary.main",
                        marginLeft: 1,
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={brandDialog}
        onClose={() => setBrandDialog(false)}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "300px",
            borderRadius: "15px",
            boxShadow: 6,
          },
        }}
      >
        <DialogContent sx={{ p: 2 }}>
          <AddBrand {...{setBrandDialog}}/>
        </DialogContent>
      </Dialog>
    </>
  );
}
