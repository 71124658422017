import { FILE } from "config";

const ComplianceFeatures = [
  {
    app_id: 19,
    feature_id: 1901,
    feature_name: "compliance-ucc-mismatch",
    type: FILE,
    subfolder: [],
    label: "UCC-Mismatch",
    path: "/compliance/ucc-mismatch"
  },
  {
    app_id: 19,
    feature_id: 1902,
    feature_name: "compliance-cce-mismatch",
    type: FILE,
    subfolder: [],
    label: "CCE-Mismatch",
    path: "/compliance/cce-mismatch"
  },
  {
    app_id: 19,
    feature_id: 1903,
    feature_name: "compliance-segregation",
    type: FILE,
    subfolder: [],
    label: "Segregation",
    path: "/compliance/segregation"
  },
  {
    app_id: 19,
    feature_id: 1904,
    feature_name: "compliance-agts",
    type: FILE,
    subfolder: [],
    label: "AGTS",
    path: "/compliance/agts"
  },
  {
    app_id: 19,
    feature_id: 1905,
    feature_name: "compliance-surveillance",
    type: FILE,
    subfolder: [],
    label: "Surveillance",
    path: "/compliance/surveillance"
  },
  {
    app_id: 19,
    feature_id: 1906,
    feature_name: "compliance-dormat",
    type: FILE,
    subfolder: [],
    label: "Dormat",
    path: "/compliance/dormat"
  }
];

export default ComplianceFeatures;