import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import EventDash from "views/utilities/event/dashboard";
import EventUtils from "views/utilities/event/utils";
import EventReports from "views/utilities/event/reports";

const EventHome = Loadable(lazy(() => import("views/utilities/event")));
// const NewEvent = Loadable(
//   lazy(() => import("views/utilities/event/utils/new-event"))
// );
const View = Loadable(lazy(() => import("views/utilities/event/utils/view")));
const BudgetRequest = Loadable(
  lazy(() => import("views/utilities/event/utils/budget-event"))
);
// const AllRequest = Loadable(
//   lazy(() => import("views/utilities/event/utils/all-request"))
// );
const RequestEventPlanning = Loadable(
  lazy(() => import("views/utilities/event/utils/planning-request"))
);
const Applications = Loadable(
  lazy(() => import("views/utilities/event/reports/Applications"))
);

export const EventModule = {
  path: "event",
  element: <EventHome />,
  children: [
    {
      path: "home",
      element: <EventDash />
    },
    {
      path: "utils",
      element: <EventUtils />
    },
    {
      path: "reports",
      element: <EventReports />
    }
  ]
};

export const EventRoutes = {
  path: "event",
  children: [
    // {
    //   path: "add-event",
    //   element: <NewEvent />
    // },
    {
      path: "view",
      element: <View />,
    },
    {
      path: "event-budget-request",
      element: <BudgetRequest />,
    },
    // {
    //   path: "all-request",
    //   element: <AllRequest />,
    // },
    {
      path: "applications",
      element: <Applications />,
    },
    {
      path: "event-planning-request",
      element: <RequestEventPlanning />,
    },
  ],
};
