/*eslint-disable*/
import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const InsuranceHome = Loadable(
  lazy(() => import("views/utilities/insurance/dashboard"))
);
const InsuranceIndex = Loadable(
  lazy(() => import("views/utilities/insurance/index"))
);
const LeadRequest = Loadable(
  lazy(() => import("views/utilities/insurance/utils/lead"))
);
const InsuranceRequest = Loadable(
  lazy(() => import("views/utilities/insurance/utils/insurance"))
);
const MutualFundsUtilities = Loadable(
  lazy(() => import("views/utilities/insurance/utils"))
);
const TotalExpenseReport = Loadable(
  lazy(() => import("views/utilities/insurance/reports"))
);

export const InsuranceModule = {
  path: "insurance",
  element: <InsuranceIndex />,
  children: [
    {
      path: "home",
      element: <InsuranceHome />,
    },
    {
      path: "utils",
      element: <MutualFundsUtilities />,
    },

    {
      path: "reports",
      element: <TotalExpenseReport />,
    },
  ],
};

export const InsuranceUtilsRoutes = {
  path: "insurance",
  children: [
    {
      path: "lead",
      element: <LeadRequest />,
    },
    {
      path: "add-insurance",
      element: <InsuranceRequest />,
    },
  ],
};
export const InsuranceReportsRoutes = {
  path: "insurance",
  children: [
    // {
    //   path: "total-expense",
    //   element: <InsuranceReport />,
    // },
    // {
    //   path: "profit-loss",
    //   element: <ProfitAndLossReport />,
    // },
  ],
};
