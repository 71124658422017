import { commonApiRequestNew } from "utils/CommonFunctions";

const apiUrl = process.env.REACT_APP_URL_BULK_MAIL;

export const getAllGroupsApi = async () => {
  const url = `${apiUrl}/api/v1/groups`;
  return commonApiRequestNew("GET", url);
};

export const getGroupByIDApi = async (id) => {
  const url = `${apiUrl}/api/v1/groups/${id}`;
  return commonApiRequestNew("GET", url);
};

export const addGroupApi = async (body) => {
  console.log('body', body)
  const url = `${apiUrl}/api/v1/groups`;
  return commonApiRequestNew("POST", url, body);
};

export const updateGroupApi = async (id, body) => {
  const url = `${apiUrl}/api/v1/groups/${id}`;
  return commonApiRequestNew("PUT", url, body);
};

export const deleteGroupApi = async (id) => {
  const url = `${apiUrl}/api/v1/groups/${id}`;
  return commonApiRequestNew("DELETE", url);
};

export const removeUserApi = async (id) => {
  const url = `${apiUrl}/api/v1/groups/user/${id}`;
  return commonApiRequestNew("DELETE", url);
};

export const getAllClientApi = async (query) => {
  const url = `${apiUrl}/api/v1/data/clients`;
  return commonApiRequestNew("GET", url, null, {
    name: query,
    limit: 50,
  });
};

export const getAllEmployeeApi = async () => {
  const url = `${apiUrl}/api/v1/data/employees`;
  return commonApiRequestNew("GET", url);
};
