import { commonApiRequest } from "utils/CommonFunctions";

export const uploadTradeFile = (body) => {
    const url = `${process.env.REACT_APP_URL_CRMS}/v1/trade-file-upload`;
    return commonApiRequest("POST", url, body);
  };
  
  export const fetchUploadedFileLogs = (segment) => {
    const url = `${process.env.REACT_APP_URL_CRMS}/v1/uploaded-file-log?segment=${segment}`;
    return commonApiRequest("GET", url);
  };
  