import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const ExtraNet = Loadable(lazy(() => import("views/utilities/extra-net")));

const ExtraNetDashboard = Loadable(
  lazy(() => import("views/utilities/extra-net/dashboard"))
);
const ExtraNetUtilities = Loadable(
  lazy(() => import("views/utilities/extra-net/utils"))
);
const ExtraNetReports = Loadable(
  lazy(() => import("views/utilities/extra-net/reports"))
);

const ExtraNetFiles = Loadable(
  lazy(() => import("views/utilities/extra-net/utils/extranet-files"))
);
const OuterFolder = Loadable(
  lazy(() =>
    import("views/utilities/extra-net/utils/extranet-files/OuterFolder")
  )
);
const SegmentFolder = Loadable(
  lazy(() =>
    import("views/utilities/extra-net/utils/extranet-files/segment-folder")
  )
);

const BseSftpFiles = Loadable(
  lazy(() => import("views/utilities/extra-net/utils/bse-sftp"))
);

const BSEOuterFolder = Loadable(
  lazy(() => import("views/utilities/extra-net/utils/bse-sftp/OuterFolder"))
);

const BSE_SFTPSegmentFolder = Loadable(
  lazy(() => import("views/utilities/extra-net/utils/bse-sftp/segment-folder"))
);

export const ExtraNetModule = {
  path: "daily-files",
  element: <ExtraNet />,
  children: [
    {
      path: "home",
      element: <ExtraNetDashboard />,
    },
    {
      path: "utils",
      element: <ExtraNetUtilities />,
    },

    {
      path: "reports",
      element: <ExtraNetReports />,
    },
  ],
};

export const ExtraNetUtilsRoutes = {
  path: "daily-files",
  children: [
    {
      path: "extranet",
      element: <ExtraNetFiles />,
    },
    {
      path: "extranet/:outer",
      element: <OuterFolder />,
    },
    {
      path: "extranet/Common/:folder",
      element: <SegmentFolder />,
    },
    {
      path: "extranet/Member/:folder",
      element: <SegmentFolder />,
    },
    {
      path: "bse-sftp",
      element: <BseSftpFiles />,
    },
    {
      path: "bse-sftp/FNO/:folder",
      element: <BSE_SFTPSegmentFolder />,
    },
    {
      path: "bse-sftp/EQ/:folder",
      element: <BSE_SFTPSegmentFolder />,
    },
    {
      path: "bse-sftp/CURRENCY/:folder",
      element: <BSE_SFTPSegmentFolder />,
    },
    {
      path: "bse-sftp/:outer",
      element: <BSEOuterFolder />,
    },
  ],
};
