/*eslint-disable*/
import axios from "axios";
const apiUrl = process.env.REACT_APP_SEGMENT_ADDITION;

export const verifyOtpApi = async (
  selectedClientCode,
  process,
  source,
  verifyOtp,
  Segment
) => {
  const formData = new FormData();
  formData.append("process", process);
  formData.append("source", source);
  formData.append("client_code", selectedClientCode);
  formData.append("otp", verifyOtp);
  formData.append("segment", Segment.join(", "));

  const response = await axios.post(
    `${apiUrl}/v1/account_profile/segmentAddition?process=${process}&source=${source}`,
    formData,
    {
      headers: {
        accept: "application/json",
        authToken: localStorage.authToken,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response;
};

// export const sendOtpApi = async (process, source, formData) => {
//   const response = await axios.post(
//     `${apiUrl}/v1/account_profile/segmentAddition?process=${process}&source=${source}`,
//     formData,
//     {
//       headers: {
//         accept: "application/json",
//         authToken: localStorage.authToken,
//         "Content-Type": "multipart/form-data",
//       },
//     }
//   );
//   return response;
// };

export const segmentAdditionDataApi = async (globalFilter, pagination) => {
  let url = `${apiUrl}/v1/segment_data`;
  if (globalFilter) {
    if (globalFilter.length < 2) return;
    url += `?client_code=${globalFilter}`;
  }

  const response = await axios.post(
    url,
    {
      page_index: pagination.pageIndex + 1,
      page_size: pagination.pageSize,
    },
    {
      headers: {
        accept: "application/json",
        "auth-token": localStorage.authToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response;
};

export const segmentAdditionApprovalDataApi = async () => {
  let url = `${apiUrl}/v1/segment_type_data?kyc_status=${ApprovalStatus}`;
  if (globalFilter) {
    url += `&client_code=${globalFilter}`;
  }

  const response = await axios.post(
    url,
    {
      page_index: pagination.pageIndex + 1,
      page_size: pagination.pageSize,
    },
    {
      headers: {
        accept: "application/json",
        "auth-token": localStorage.authToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response;
};
