/*eslint-disable*/
import axios from "axios";
import React, { useRef, useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";

export const LmsContext = createContext();

export default function LmsApis(props) {
  const [status, setStatus] = useState("pending");
  const [updateMeetStatus, setUpdateMeetStatus] = useState("scheduled");
  const [meetCondition, setMeetCondition] = useState();
  const [allLeadData, setAllLeadData] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [condition, setCondition] = useState("");
  const [userData, setUserData] = useState([]);
  const [meetingData, setMeetingData] = useState([]);
  const [comment, setComment] = useState([]);
  const [comments, setComments] = useState([]);
  const [misReportData, setMisReportData] = useState([]);
  const [notificationCount, setNotificationCount] = useState("");
  const [notificationViewData, setNotificationViewData] = useState([]);
  const [lmsDashboardData, setLmsDashboardData] = useState({});
  const [lmsDashboardChartInsightsData, setLmsDashboardChartInsightsData] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardLoading, setDashBoardLoading] = useState(false);
  const [dashboardDataLoading, setDashBoardDataLoading] = useState(false);
  const [dashboardDialogOpen, setDashBoardDialogOpen] = useState({
    open: false,
    type: "A",
    apiKey: ""
  });

  const currentMonthNo = new Date().getMonth() + 1;

  const [selectedMonth, setSelectedMonth] = useState(currentMonthNo);

  const handleDialogOpen = (typeVal, apikey) => {
    fetchLMSDashboardChartInsightsData(apikey);
    setDashBoardDialogOpen({
      open: true,
      type: typeVal,
      apiKey: apikey
    });
  };

  const handleDialogClose = () => {
    setDashBoardDialogOpen((prev) => ({ ...prev, open: false }));
  };

  const getCurrentMonthFirstDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  };
  const [dates, setDates] = useState([getCurrentMonthFirstDate(), new Date()]);

  const formatDateString = (dateStr) => {
    const dateObj = new Date(dateStr);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  console.log("dashboardDialogOpen", dashboardDialogOpen);

  const startDate = formatDateString(dates[0]);
  const endDate = formatDateString(dates[1]);

  const fetchAllLeadData = async (status = "pending", start_date, end_date) => {
    setAllLeadData([]);
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_LMS}/v1/get-lead-all-data?status=${status}&start_date=${start_date}&end_date=${end_date}`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken
          }
        }
      );
      const resJson = await response.json();

      if (resJson.status === "success") {
        const leadData = resJson?.data.map((item, index) => ({
          ...item,
          id: index + 1
        }));
        setAllLeadData(leadData);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      console.error("Error fetching lead data:", error);
      toast.error("Network error"); // Notify about network error
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLeadData = async () => {
    try {
      setLeadData([]);
      const response = await fetch(
        `${process.env.REACT_APP_URL_LMS}/v1/get-lead-data-assigned-by`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken
          }
        }
      );
      const resJson = await response.json();

      if (resJson.status === "success") {
        const leadData = resJson?.data.map((item, index) => ({
          ...item,
          id: index + 1
        }));
        setLeadData(leadData);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      console.error("Error fetching lead data:", error);
      toast.error("Network error");
    }
  };

  const fechUserList = async (userValue) => {
    try {
      setUserData([]);
      const response = await fetch(
        `${process.env.REACT_APP_URL_LMS}/v1/get-username?username=${userValue}`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken
          }
        }
      );
      const resJson = await response.json();
      if (resJson.status == "success") {
        setUserData(resJson?.data);
        console.log("data00", resJson?.data);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Network error");
    }
  };

  const handleAssignLead = async (assigned_to, lead_id, handleCloseDialog) => {
    try {
      console.log("lead_id", lead_id);
      const response = await fetch(
        `${process.env.REACT_APP_URL_LMS}/v1/assigned-lead`,
        {
          method: "POST",
          headers: {
            authToken: localStorage.authToken,
            "Content-type": "application/json"
          },
          body: JSON.stringify({
            assigned_to: assigned_to,
            lead_id: lead_id
          })
        }
      );
      const resJson = await response.json();
      if (resJson.status == "success") {
        fetchAllLeadData();
        console.log("data00", resJson?.data);
        handleCloseDialog();
        toast.success(resJson.message);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Network error");
    }
  };

  const handleFileBulkUpload = async (event, handleCloseDialog) => {
    const formData = new FormData();
    formData.append("file", event.files[0]);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_LMS}/v1/bulk-upload-lead`,
        {
          method: "POST",
          headers: {
            authToken: localStorage.authToken,
            // "content-type": " multipart/form-data",
            accept: "application/json"
          },
          body: formData
        }
      );
      const resJson = await response.json();
      if (resJson.status == "success") {
        handleCloseDialog();
        toast.success(resJson.message);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Network Error");
    }
  };

  const handleAddMeeting = async (
    values,
    handleCloseDialog,
    { setErrors, setSubmitting, resetForm }
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_LMS}/v1/create-meeting`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authToken: localStorage.authToken
          },
          body: JSON.stringify(values)
        }
      );

      const resJson = await response.json();

      if (resJson.status == "success") {
        setSubmitting(true);
        resetForm({ values: "" });
        toast.success(resJson.message);
        handleCloseDialog();
        fetchAllLeadData("assigned");
      } else {
        setErrors({ submit: resJson.message });
        setSubmitting(false);
        toast.error(resJson.message);
      }
    } catch (err) {
      console.error(err);
      setErrors({ submit: "An error occurred while Add Meet" });
      setSubmitting(false);
      toast.error("An error occurred while Add Meet");
    }
  };

  const handleUpdateMeeting = async (
    values,
    status,
    handleCloseDialog,
    { setErrors, setSubmitting, resetForm }
  ) => {
    console.log("values", values);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_LMS}/v1/update-meeting`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authToken: localStorage.authToken
          },
          body: JSON.stringify({
            meeting_id: values.meeting_id,
            meeting_title: values.meeting_title,
            status: status,
            remark: status === "success" ? values.remarks : "",
            date: status === "success" ? "" : values.meeting_date,
            reference: values.reference,
            description: values.description,
            sales_person_name: values.sales_person_name,
            place: values.place
          })
        }
      );

      const resJson = await response.json();

      if (resJson.status == "success") {
        setSubmitting(true);
        resetForm({ values: "" });
        toast.success(resJson.message);
        handleCloseDialog();
        fetchMeetingData("scheduled");
      } else {
        setErrors({ submit: resJson.message });
        setSubmitting(false);
        toast.error(resJson.message);
      }
    } catch (err) {
      console.error(err);
      setErrors({ submit: "An error occurred while Update Meet" });
      setSubmitting(false);
      toast.error("An error occurred while Update Meet");
    }
  };

  const handleSuccessMeeting = async (
    values,
    status,
    handleCloseDialog,
    { setErrors, setSubmitting, resetForm }
  ) => {
    console.log("values", values);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_LMS}/v1/complete-meeting`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authToken: localStorage.authToken
          },
          body: JSON.stringify({
            meeting_id: values.meeting_id,
            status: status,
            remark: status === "success" ? values.remarks : ""
          })
        }
      );

      const resJson = await response.json();

      if (resJson.status == "success") {
        setSubmitting(true);
        resetForm({ values: "" });
        toast.success(resJson.message);
        handleCloseDialog();
        fetchMeetingData("scheduled", startDate, endDate);
      } else {
        setErrors({ submit: resJson.message });
        setSubmitting(false);
        toast.error(resJson.message);
      }
    } catch (err) {
      console.error(err);
      setErrors({ submit: "An error occurred while Update Meet" });
      setSubmitting(false);
      toast.error("An error occurred while Update Meet");
    }
  };

  const fetchMeetingData = async (status, start_date, end_date) => {
    setIsLoading(true);
    setMeetingData([]);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_LMS}/v1/meeting-data?status=${status}&start_date=${start_date}&end_date=${end_date}`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken
          }
        }
      );
      const resJson = await response.json();

      if (resJson.status === "success") {
        const meetData = resJson?.data.map((item, index) => ({
          ...item,
          id: index + 1
        }));
        setMeetingData(meetData);
      } else {
        toast.error(resJson.message); // Inform the user about the failure
      }
    } catch (error) {
      console.error("Error fetching Meeting data:", error);
      toast.error("Network error"); // Notify about network error
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddComment = async (phone_no, comment, activity_type) => {
    try {
      const headers = {
        accept: "application/json",
        authToken: localStorage.authToken
      };
      const response = await axios.post(
        `${process.env.REACT_APP_URL_LMS}/v1/add-comment?activity_type=${activity_type}`,
        {
          phone_no: phone_no,
          message: comment
        },
        {
          headers: headers
        }
      );
      const resJson = await response?.data;

      console.log("resJson", resJson);
      if (resJson.status == "success") {
        setComment("");
        console.log(resJson?.data);
        fetchCommentData(phone_no, activity_type);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const fetchCommentData = async (phone_no, activity_type) => {
    setComments([]);
    try {
      const headers = {
        accept: "application/json",
        authToken: localStorage.authToken
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URL_LMS}/v1/fetch-comment?phone_no=${phone_no}&activity_type=${activity_type}`,
        {
          headers: headers
        }
      );
      const resJson = await response?.data;

      console.log("resJson", resJson);
      if (resJson.status == "success") {
        setComments(resJson?.data);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const fetchMisReport = async () => {
    setMisReportData([]);
    try {
      const headers = {
        accept: "application/json",
        authToken: localStorage.authToken
      };
      const response = await axios.get(
        `${process.env.REACT_APP_URL_LMS}/v1/fetch-mis-report`,
        {
          headers: headers
        }
      );
      const resJson = await response?.data;

      if (resJson.status == "success") {
        const misData = resJson.data.map((index, item) => ({
          ...index,
          id: item + 1
        }));

        setMisReportData(misData);
        console.log("resJson", resJson?.data);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };

  const fetchNotification = async (type, status) => {
    try {
      const headers = {
        accept: "application/json",
        authToken: localStorage.authToken
      };

      const response = await axios.get(
        `${process.env.REACT_APP_URL_LMS}/v1/fetch-all-notification?type=${type}&status=${status}`,
        { headers }
      );

      console.log("Response received:", response);

      if (response?.data && response?.data?.status === "success") {
        if (type === "count") {
          setNotificationCount(
            response.data.data && response.data.data.length > 0
              ? response.data.data[0]
              : 0
          );
        } else {
          setNotificationViewData(response?.data?.data);
        }
      } else {
        console.log("Error in response:", response?.data);
        // toast.error(response?.data?.message || "An error occurred");
      }
    } catch (error) {
      console.error("Error in catch:", error);

      // type === "count" &&
      //   toast.error(error?.response?.data?.message || "Network Error");
    }
  };

  const handleReadNotification = async (type, notification_id = "") => {
    try {
      const headers = {
        accept: "application/json",
        authToken: localStorage.authToken
      };

      const response = await axios.put(
        `${process.env.REACT_APP_URL_LMS}/v1/update-notification?type=${type}&noti_id=${notification_id}`,
        {},
        { headers: headers }
      );

      console.log("response", response);

      if (response.data.status === "success") {
        // toast.success(response.data.message);
        console.log(response.data.status);
        fetchNotification("view", "all");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const fetchLMSDashboardData = async (month) => {
    setDashBoardLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_LMS}/v1/lms-dashboard-count?month=${month}`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken
          }
        }
      );
      const resJson = await response.json();

      if (resJson.status === "success") {
        setLmsDashboardData(resJson.data);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      console.error("Error fetching lead data:", error);
      toast.error("Network error");
    } finally {
      setDashBoardLoading(false);
    }
  };
  const fetchLMSDashboardChartInsightsData = async (status_filter) => {
    setDashBoardDataLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_LMS}/v1/lms-dashboard-data?status_filter=${status_filter}&month=${selectedMonth}`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.authToken
          }
        }
      );
      const resJson = await response.json();

      if (resJson.status === "success") {
        const tempData = resJson.data.map((item, index) => ({
          ...item,
          id: index + 1
        }));
        console.log("tempData", tempData);
        setLmsDashboardChartInsightsData(tempData);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      console.error("Error fetching lead data:", error);
      toast.error("Network error");
    } finally {
      setDashBoardDataLoading(false);
    }
  };

  return (
    <LmsContext.Provider
      value={{
        isLoading,
        setUpdateMeetStatus,
        updateMeetStatus,
        fetchAllLeadData,
        allLeadData,
        setCondition,
        condition,
        fetchLeadData,
        leadData,
        fechUserList,
        userData,
        handleAssignLead,

        handleFileBulkUpload,
        handleAddMeeting,
        handleUpdateMeeting,
        fetchMeetingData,
        meetingData,
        handleAddComment,
        setComment,
        comment,
        fetchCommentData,
        comments,
        meetCondition,
        setMeetCondition,

        fetchMisReport,
        misReportData,

        fetchNotification,
        notificationCount,
        notificationViewData,
        handleReadNotification,
        fetchLMSDashboardData,
        lmsDashboardData,
        setStatus,
        status,
        handleSuccessMeeting,

        dashboardLoading,
        setDates,
        dates,
        dashboardDialogOpen,
        setDashBoardDialogOpen,
        handleDialogOpen,
        handleDialogClose,
        lmsDashboardChartInsightsData,
        dashboardDataLoading,
        selectedMonth,
        setSelectedMonth
      }}
    >
      {props.children}
    </LmsContext.Provider>
  );
}
