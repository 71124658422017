/*eslint-disable*/
import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { handleCatch } from "utils/CommonFunctions";

export const CtclContext = createContext();

export default function CtclApis(props) {
  const [isError, setIsError] = useState(false);
  const [ctclViewData, setCtclViewData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [sorting, setSorting] = useState([{ id: "ctcl_id", desc: false }]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [segment, setSegment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [bseFile, setBseFile] = useState(null);
  const [files, setFiles] = useState({
    nse_cd_ctcl: null,
    nse_cm_ctcl: null,
    nse_fo_ctcl: null,
    nse_cd_neat: null,
    nse_cm_neat: null,
    nse_fo_neat: null
  });
  const [certificateData, setCertificateData] = useState([]);

  const [isFileUploadLoading, setIsFileUploadLoading] = useState({
    nse_cd_ctcl: false,
    nse_cm_ctcl: false,
    nse_fo_ctcl: false,
    nse_cd_neat: false,
    nse_cm_neat: false,
    nse_fo_neat: false,
    bse_file: false
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });

  const BSEInputRef = useRef(null);
  const nseUploadRef = useRef(null);

  const handleAddCtclData = async (
    values,
    selectedMarket,
    { setErrors, setSubmitting, resetForm }
  ) => {
    // console.log("values", values);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_CTCL}/add-ctcl-id?segement=${selectedMarket}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authToken: localStorage.authToken
          },
          body: JSON.stringify(values)
        }
      );

      const resJson = await response.json();
      if (resJson.status == "success") {
        setSubmitting(true);
        resetForm({ values: "" });
        toast.success(resJson.message);
      } else {
        setErrors({ submit: resJson.message });
        setSubmitting(false);
        toast.error(resJson.message);
      }
    } catch (err) {
      console.error(err);
      setErrors({ submit: "An error occurred while Add Bene" });
      setSubmitting(false);
      toast.error("An error occurred while Add Bene");
    }
  };

  const fetchCtclViewData = async (selectedExchange, selectedSegments) => {
    // console.log("selectedSegments", selectedSegments);
    try {
      const params = {
        search_field:
          columnFilters.length === 0
            ? {}
            : { [columnFilters[0].id]: columnFilters[0].value },
        sorting_field:
          sorting.length == 0
            ? { ctcl_id: "ASC" }
            : { [sorting[0].id]: sorting[0].desc == false ? "ASC" : "DESC" },
        page_size: pagination.pageSize,
        page_number: pagination.pageIndex + 1,
        exchange: selectedExchange,
        segment: selectedSegments
      };

      const response = await fetch(`${process.env.REACT_APP_URL_CTCL}/all`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authToken: localStorage.authToken
        },
        body: JSON.stringify(params)
      });

      const resJson = await response.json();
      // console.log("resJson", resJson);
      if (resJson.status == "success") {
        const CtclData = resJson.data.data.map((item, index) => ({
          ...item,
          id: index + 1
        }));
        console.log(CtclData);
        setCtclViewData(CtclData);
        // console.log("000", resJson.data.row_count);
        setRowCount(resJson.data.row_count);
      } else {
        toast.error(resJson.message);
      }
    } catch (err) {
      console.error(err);
      toast.error("An error ");
      setIsError(true);
    }
  };

  // console.log("setCtclViewData", ctclViewData);

  // useEffect(() => {
  //   fetchCtclViewData();
  // }, []);

  const handleBSEFileUpload = async (type) => {
    try {
      if (type) {
        setIsFileUploadLoading((prevState) => ({ ...prevState, [type]: true }));
      } else {
        setIsFileUploadLoading((prevState) =>
          Object.fromEntries(Object.keys(prevState).map((key) => [key, true]))
        );
      }

      const formData = new FormData();
      formData.append("file", bseFile);

      const headers = {
        "auth-token": localStorage.authToken,
        accept: "application/json"
      };

      const response = await axios.post(
        `${process.env.REACT_APP_URL_CTCL}/v1/upload-bse-file`,
        formData,
        {
          headers: headers
        }
      );
      const resJson = await response.data;
      if (resJson.status === "success") {
        setBseFile([]);
        BSEInputRef.current.clear();
        toast.success(resJson.message);
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsFileUploadLoading((prevState) =>
        Object.fromEntries(Object.keys(prevState).map((key) => [key, false]))
      );
    }
  };

  const handleNSEFileUploadCtcl = async (type, filename, FileData) => {
    console.log("Dd", type, filename);

    try {
      if (type) {
        setIsFileUploadLoading((prevState) => ({ ...prevState, [type]: true }));
      } else {
        setIsFileUploadLoading((prevState) =>
          Object.fromEntries(Object.keys(prevState).map((key) => [key, true]))
        );
      }
      const formData = new FormData();
      formData.append("file", FileData);
      const headers = {
        "auth-token": localStorage.authToken,
        accept: "application/json"
      };

      const response = await axios.post(
        `${process.env.REACT_APP_URL_CTCL}/v1/upload-nse-file?filename=${filename}`,
        formData,
        {
          headers: headers
        }
      );

      const resJson = await response.data;
      if (resJson.status === "success") {
        setFiles([]);
        toast.success(resJson.message);
        nseUploadRef.current.clear();
      } else {
        toast.error(resJson.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsFileUploadLoading((prevState) =>
        Object.fromEntries(Object.keys(prevState).map((key) => [key, false]))
      );
    }
  };

  const handleEODNeatFileUpload = (filename, FileData) => {
    const formData = new FormData();
    formData.append("file", FileData);
    const headers = {
      "auth-token": localStorage.authToken,
      accept: "application/json"
    };

    axios
      .post(
        `${process.env.REACT_APP_URL_CTCL}/v1/upload-nse-neat-file?filename=${filename}`,
        formData,
        {
          headers: headers
        }
      )
      .then((res) => {
        if (res.data.status == "Success") {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred while uploading the file.");
      });
  };

  const fetchCertificateData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_CTCL}/v1/viewCertification`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authToken: localStorage.authToken
          }
        }
      );

      const resJson = await response.json();
      console.log("resJson", resJson);
      if (resJson.status == "success") {
        setCertificateData(resJson.data);
      } else {
        toast.error(resJson.message);
      }
    } catch (err) {
      console.error(err);
      toast.error("An error ");
      setIsError(true);
    }
  };

  const formObject = (values) => {
    var formData = new FormData();
    formData.append("reg_no", values.reg_no);
    formData.append("ap_name", values.ap_name);
    formData.append("pan_no", values.pan_no);
    formData.append("dob", values.date_of_birth);
    formData.append("allotment_date", values.allotment_date);
    formData.append("validity_date", values.validity_date);
    formData.append("exam_type", values.exam_type);
    formData.append("certified_by", values.certified_by);
    formData.append("certi_type", values.certificate_type);
    values.img_pan != null && formData.append("image_pan", values.img_pan);
    values.img_certificate != null &&
      formData.append("image_certi", values.img_certificate);
    values.img_photo != null &&
      formData.append("image_photo", values.img_photo);

    return formData;
  };

  const handleCertificateAdd = async (
    values,
    others,
    { setErrors, setSubmitting, resetForm }
  ) => {
    console.log("values", values);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_CTCL}/v1/addNewCertification`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            authToken: localStorage.authToken
          },
          body: formObject(values)
        }
      );
      const resJson = await response.json();
      console.log(resJson);
      if (resJson.status == "success") {
        setSubmitting(true);
        resetForm({ values: "" });
        others.setOpen(false);
        toast.success(resJson.message);
        fetchEvent();
      } else {
        setErrors({ submit: resJson.message });
        setSubmitting(false);
        toast.error(resJson.message);
      }
    } catch (err) {
      console.error(err);
      setErrors({ submit: "An error occurred while Add Certificte" });
      setSubmitting(false);
      toast.error("An error occurred while Add Certificte");
    }
  };

  const handleTradeUpload = async (values, { resetForm, fileInputRef }) => {
    try {
      const response = await axios.post("");
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.err(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      resetForm();
    }
  };

  return (
    <CtclContext.Provider
      value={{
        handleAddCtclData,
        fetchCtclViewData,
        ctclViewData,
        isError,
        setPagination,
        pagination,
        setSorting,
        sorting,
        setColumnFilters,
        columnFilters,

        rowCount,
        setRowCount,
        isLoading,
        handleBSEFileUpload,
        bseFile,
        setBseFile,
        BSEInputRef,
        nseUploadRef,
        isLoading,
        handleNSEFileUploadCtcl,
        handleEODNeatFileUpload,
        fetchCertificateData,
        certificateData,
        handleCertificateAdd,
        handleTradeUpload,
        isFileUploadLoading,
        files,
        setFiles
      }}
    >
      {props.children}
    </CtclContext.Provider>
  );
}
