import React from "react";
import IpoApis from "context/ipo/IpoApis";
import CRMSApis from "context/CRMS/CRMSApis";
import UserManagementApis from "context/user-management-api/UserManagementApis";
import AppManagementApis from "context/app-management-api/AppManagementApis";
import RtrmsApis from "context/rtrms/RtrmsApis";
import RoleManagementApis from "context/role-management-api/RoleManagementApis";
import NetworkMonitor from "context/network-monitor/NetworkMonitor";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
// for dark mode
// import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import { PrimeReactProvider } from "primereact/api";
import { useSelector } from "react-redux";
import { KycOnBoardingApis } from "context/kyc/KycOnBoardingApis";
import { EdisApis } from "./edis/EdisApis";
import { LoginApi } from "./login/LoginApi";
import { EpledgeApi } from "./e-pledge/EpledgeApi";
import { EddpiApis } from "./eddpi/EddpiApis";
import RmsApis from "./rms/RmsApis";
import ItInfraApis from "./it-infra/ItInfraApi";
import { KycModificationApis } from "./kyc/KycModificationApis";
import BounceMailApis from "./bounce-mail/BounceMailApis";
import ComplianceApis from "./compliance/ComplianceApis";
import PMSContextProvider from "./pms/PmsContext";
import AuditApis from "./ctcl/AuditApis";
import CtclApis from "./ctcl/CtclApis";
import ApOnBoardingApis from "./ap-on-boarding/ApOnBoardingApis";
import { KycKraApis } from "./kyc/KycKraApis";
import EventApis from "./event/EventApis";
import BranchCostApis from "./branch-cost/BranchCostApis";
import PMSTargetContextProvider from "./pms/PmsTargetContext";
import LmsApis from "./lms/LmsApis";
import InwardApi from "./inward/InwardApi";
import HelpDeskApis from "./help-desk/HelpDeskApis";
import TravelContextProvider from "./travel-management/TravelContext";
import CustomerCareApi from "./CustomerCare/CustomerCareApi";
import EmailContextProvider from "./email/EmailContext";
import PettyCashApis from "./petty-cash/PettyCashApis";
import ChatApis from "./chat-app/ChatApis";
import DashBoardApis from "./dashboard/DashBoardApis";
import MasterDashBoardApi from "./master-dashboard-api/MasterDashBoardApi";
// import InwardCheckerApi from "./inward/InwardCheckerApis";
import EventPalnningApis from "./event/EventPalnningApis";
import AccountSettingApis from "./AccountSetting/AccountSettingApis";
import SharedDrive from "./shared-drive/SharedDrive";
import ExchangeAlert from "./exchange-alert/ExchangeAlert";
import AlgoSummaryApis from "./Algo-summary/AlgoSummaryApis";
import NoticeBoardApis from "./notice-board/NoticeBoardApis";
import LeadApi from "./insurance/LeadApi";
import InsuranceApi from "./insurance/InsuranceApi";
import RiaWmsApis from "./riaWms/RiaWmsApis";
import ExtraNetProvider from "./daily-files/ExtraNetContext";
import { ReKycApis } from "./kyc/ReKycApis";
import { ReportsApis } from "./reports/ReportsApis";
import LeaseApi from "./leaseAgreement/leaseApi";
import BlogApi from "./blog/blogApi";
import EventGalleryApi from "./event-gallery/eventGalleryApi";
import EventVideoApi from "./event-video/eventVideoApi";
import ArhampdfApi from "./arham-pdf/arhamPdfApi";

import { SocketProvider } from "./web-socket/WebSocketMain";
import ProRmsApis from "./pro-rms/ProRmsApis";
import BulkMailApis from "./bulk-mail/BulkMailApis";
import { KycOfflineApis } from "./kyc/KycOfflineApis";
import AccountsApis from "./Accounts/AccountsApis";

export default function ContextWrapper({ children }) {
  const customization = useSelector((state) => state.customization);

  return (
    <SocketProvider>
      <StyledEngineProvider injectFirst>
        <PrimeReactProvider>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <LoginApi>
                <AccountSettingApis>
                  <DashBoardApis>
                    <MasterDashBoardApi>
                      <ReportsApis>
                        <RoleManagementApis>
                          <AppManagementApis>
                            <UserManagementApis>
                              <NetworkMonitor>
                                <RtrmsApis>
                                  <AuditApis>
                                    <ApOnBoardingApis>
                                      <CtclApis>
                                        <CRMSApis>
                                          <LmsApis>
                                            <EventApis>
                                              <EventPalnningApis>
                                                <BranchCostApis>
                                                  <IpoApis>
                                                    <EdisApis>
                                                      <EpledgeApi>
                                                        <EddpiApis>
                                                          <KycOnBoardingApis>
                                                            <ReKycApis>
                                                              <KycModificationApis>
                                                                <KycKraApis>
                                                                  <RmsApis>
                                                                    <ItInfraApis>
                                                                      <CustomerCareApi>
                                                                        <InwardApi>
                                                                          <AlgoSummaryApis>
                                                                            <KycOfflineApis>
                                                                              <BounceMailApis>
                                                                                <ComplianceApis>
                                                                                  <PMSTargetContextProvider>
                                                                                    <PMSContextProvider>
                                                                                      <HelpDeskApis>
                                                                                        <TravelContextProvider>
                                                                                          <PettyCashApis>
                                                                                            <ChatApis>
                                                                                              <SharedDrive>
                                                                                                <EmailContextProvider>
                                                                                                  <ExchangeAlert>
                                                                                                    <NoticeBoardApis>
                                                                                                      <LeadApi>
                                                                                                        <InsuranceApi>
                                                                                                          <ExtraNetProvider>
                                                                                                            <LeaseApi>
                                                                                                              <RiaWmsApis>
                                                                                                                <BlogApi>
                                                                                                                  <EventGalleryApi>
                                                                                                                    <EventVideoApi>
                                                                                                                      <ProRmsApis>
                                                                                                                        <BulkMailApis>
                                                                                                                          <ArhampdfApi>
                                                                                                                            <AccountsApis>
                                                                                                                              {
                                                                                                                                children
                                                                                                                              }
                                                                                                                            </AccountsApis>
                                                                                                                          </ArhampdfApi>
                                                                                                                        </BulkMailApis>
                                                                                                                      </ProRmsApis>
                                                                                                                    </EventVideoApi>
                                                                                                                  </EventGalleryApi>
                                                                                                                </BlogApi>
                                                                                                              </RiaWmsApis>
                                                                                                            </LeaseApi>
                                                                                                          </ExtraNetProvider>
                                                                                                        </InsuranceApi>
                                                                                                      </LeadApi>
                                                                                                    </NoticeBoardApis>
                                                                                                  </ExchangeAlert>
                                                                                                </EmailContextProvider>
                                                                                              </SharedDrive>
                                                                                            </ChatApis>
                                                                                          </PettyCashApis>
                                                                                        </TravelContextProvider>
                                                                                      </HelpDeskApis>
                                                                                    </PMSContextProvider>
                                                                                  </PMSTargetContextProvider>
                                                                                </ComplianceApis>
                                                                              </BounceMailApis>
                                                                            </KycOfflineApis>
                                                                          </AlgoSummaryApis>
                                                                        </InwardApi>
                                                                      </CustomerCareApi>
                                                                    </ItInfraApis>
                                                                  </RmsApis>
                                                                </KycKraApis>
                                                              </KycModificationApis>
                                                            </ReKycApis>
                                                          </KycOnBoardingApis>
                                                        </EddpiApis>
                                                      </EpledgeApi>
                                                    </EdisApis>
                                                  </IpoApis>
                                                </BranchCostApis>
                                              </EventPalnningApis>
                                            </EventApis>
                                          </LmsApis>
                                        </CRMSApis>
                                      </CtclApis>
                                    </ApOnBoardingApis>
                                  </AuditApis>
                                </RtrmsApis>
                              </NetworkMonitor>
                            </UserManagementApis>
                          </AppManagementApis>
                        </RoleManagementApis>
                      </ReportsApis>
                    </MasterDashBoardApi>
                  </DashBoardApis>
                </AccountSettingApis>
              </LoginApi>
            </NavigationScroll>
          </ThemeProvider>
        </PrimeReactProvider>
      </StyledEngineProvider>
    </SocketProvider>
  );
}
