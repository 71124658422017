/*eslint-disable*/
import React from "react";

import CommonReportsIndex from "views/utilities/common-components/CommonReportsIndex";
import RiaReportData from "config-details/reports/RiaReportData";

const IpoReports = () => {
  return (
    <CommonReportsIndex allReports={RiaReportData} />
  );
};

export default IpoReports;