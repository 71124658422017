import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { Button, CircularProgress, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';

import { ChatContext } from 'context/chat-app/ChatApis';
import { getFirstAndLastInitials, stringToColor } from 'utils/CommonFunctions';
import { addSessionStorage, encryptData } from 'utils/encrpytion';

const ContactsBar = ({ setDrawerOpen = () => { }, setMsgBarOpen = () => { }, setIsOpen = () => { } }) => {
  const contactListSize = 10; // Number of items to load per page
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const { contactList, fetchEmployeesList, createNewChat, setOpenGroupDialog, editDialog } = useContext(ChatContext);

  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  //slow loading of contacts on scroll ----starts here
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contactItem, setContactItem] = useState(1);

  // Function to load more items
  const loadItems = () => {
    // If already loading, return and do nothing
    if (loading) return;

    setLoading(true);
    const interval = setInterval(() => {
      if (!loading) {
        clearInterval(interval);

        setTimeout(() => {
          const startIndex = (contactItem - 1) * contactListSize;
          const newItems = items.concat(users.slice(startIndex, startIndex + contactListSize));
          setItems(newItems);
          setContactItem(contactItem + 1);
          setLoading(false);
        }, 800); // Simulating loading delay
      }
    }, 100); // Check every 100ms if loading is false
  };
  //slow loading on scroll ------ code ends here

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    filterUsers(e.target.value);
  };

  const filterUsers = (query) => {
    const filtered = contactList?.filter((item) =>
      item?.full_name?.toLowerCase()?.includes(query?.toLowerCase())
    );
    setUsers(filtered);
  };

  const handleOpenChat = (user) => {
    const urlEncoded = encryptData(user?.participants);
    if (setDrawerOpen) {
      navigate(`/utilities/chat/${urlEncoded}`)
      setDrawerOpen(false);
    } else if (isMobile) {
      setMsgBarOpen(false);
      setIsOpen(false);
    }

    console.log('user in contactsbar', user)

    addSessionStorage("chatData", user)
    addSessionStorage("chatParticipants", user?.participants)
    addSessionStorage("currChatEmpId", user.UID)
    addSessionStorage("chatUserName", user.full_name)
    addSessionStorage("chatUserActive", user.is_active)

    const body = {
      participant_ids: user.UID
    }
    createNewChat(body);
  }

  // group add fn
  const handleAddGroup = () => {
    if (editDialog === false) {
      setOpenGroupDialog(true)
    }
  }

  useEffect(() => {
    setUsers(contactList)
  }, [contactList])

  useEffect(() => {
    fetchEmployeesList();
  }, [])

  // Simulating data load on component mount
  useEffect(() => {
    setItems(users?.slice(0, contactListSize)); // Initially load first 10 items
  }, [users]);

  return (
    <div className="sidebar">
      <input
        type="text"
        className="search-bar"
        placeholder="Search Contact..."
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <div className="chats" onScroll={loadItems}>
        <div role='button' className='chat' tabIndex="0"
          onClick={handleAddGroup}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleAddGroup(e);
            }
          }}>
          <IconButton
            className="group-icon"
            variant='text'
          >
            +
          </IconButton>
          <span className="details">Create New Group</span>
        </div>
        {items?.map((user, index) => {
          const avatarColor = stringToColor(user?.full_name);

          const initials = getFirstAndLastInitials(user?.full_name);
          return (
            <Grid container
              key={index}
              className='chat'
              id="createNewGroupContainer"
            >
              <Grid item xs={2}>
                <IconButton
                  className="contactbar-avatar"
                  variant="contained"
                  style={{ backgroundColor: avatarColor }}
                  onClick={() => handleOpenChat(user)}
                >
                  {initials}
                  <div
                    className='avatar-common'
                    style={{
                      backgroundColor: user.is_active ? "green" : "#F44336"
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <Grid container display="flex" flexDirection="column">
                  <Grid item xs={12} display="flex" justifyContent="left">
                    <Button
                      className='contactbar-name'
                      variant='text'
                      onClick={() => handleOpenChat(user)}
                    >
                      {user?.full_name}
                    </Button>
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="space-between">
                    <span className='contactbar-department'>{user?.department_name}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
        {loading &&
          <div className='contactbar-loader'>
            <CircularProgress size={20} color='info' />
          </div>
        }
      </div>

    </div>
  );
};

export default ContactsBar;
