/*eslint-disable*/
import { commonApiRequest } from "utils/CommonFunctions";

const baseURL = process.env.REACT_APP_URL_BLOG;

export const fetchEventVideo = () => {
  const url = `${baseURL}/arham_web/v1/fetch-video-events`;
  return commonApiRequest("GET", url);
};

export const handleEventVideoAdd = (formData) => {
  const url = `${baseURL}/arham_web/v1/upload-video-events`;
  return commonApiRequest("POST", url, formData);
};

export const handleEventVideoUpdate = (formData) => {
  const url = `${baseURL}/arham_web/v1/update-video-events`;
  return commonApiRequest("PUT", url, formData);
};

export const handleEventVideoDelete = (params) => {
  const url = `${baseURL}/arham_web/v1/delete-video-events`;
  return commonApiRequest("DELETE", url,null, params);
};


