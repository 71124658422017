/*eslint-disable*/
import React, { useState, createContext, useRef } from "react";
import {
  cleintMasterApi,
  closureApprovalApi,
  ClouserDataApi,
  dormantApi,
  getClientMasterApi,
  modificationPdfApi,
  reKycApis,
  segmentAdditionDataApi,
  segmentApprovalApi,
  sendEmailApi,
  techExcelPushApi,
  uploadProof,
  verificationDetails,
} from "services/kyc/kycOfflineServices";
import { Base64ToFileDownload, handleCatch } from "utils/CommonFunctions";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";

export const KycOfflineContext = createContext();

export const KycOfflineApis = (props) => {
  const [segmentAdditionData, setSegmentAdditionData] = useState([]);
  const [ApprovalStatus, setApprovalStatus] = useState("Pending");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [closureData, setClosureData] = useState({});
  const [modificationPdf, setModificationPdf] = useState({});

  //######################### Segment Addition ##################################//
  const handleSegmentAdditionData = async () => {
    try {
      const response = await segmentAdditionDataApi(
        ApprovalStatus,
        globalFilter,
        pagination
      );

      if (response.data.status === "success") {
        setRowCount(response.data.data.row_count);
        setSegmentAdditionData(response.data.data.inward_data);
        setIsLoading(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSegmentApproval = async (
    action,
    inwardId,
    rejectReason,
    setOpenDialog
  ) => {
    try {
      const response = await segmentApprovalApi(action, inwardId, rejectReason);
      if (response.data.status === "success") {
        toast.success(response.data.message);
        handleSegmentAdditionData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDialog(false);
    }
  };

  //######################### Closure ###########################################//
  const handleClouserData = async () => {
    setIsLoading(true);
    setClosureData([]);
    try {
      const response = await ClouserDataApi(
        ApprovalStatus,
        globalFilter,
        pagination
      );

      if (response.data.status === "success") {
        setRowCount(response.data.data.row_count);
        setClosureData(response.data.data.inward_data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClosureApproval = async (
    action,
    inwardId,
    rejectReason,
    setOpenDialog
  ) => {
    try {
      const response = await closureApprovalApi(action, inwardId, rejectReason);
      if (response.data.status === "success") {
        toast.success(response.data.message);
        handleClouserData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setOpenDialog(false);
    }
  };

  const handleRekyc = async (values, { resetForm }) => {
    try {
      const response = await reKycApis(values);
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      resetForm();
    }
  };

  const [clientCode, setClientCode] = useState("");
  const [file, setFile] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isClientCodeEntered, setIsClientCodeEntered] = useState(false);
  const fileInputRef = useRef(null);
  const handleClientMaster = async () => {
    setIsLoading(true);
    try {
      const response = await cleintMasterApi(clientCode, file);

      if (response.status === 200) {
        toast.success(response.data.message);
        if (clientCode) {
          // const pdfBlob = new Blob([response.data], {
          //   type: "application/pdf",
          // });
          // const file = new File([pdfBlob], `XTS.pdf`, {
          //   type: "application/pdf",
          // });
          // console.log("response", pdfBlob);

          // saveAs(file, `ClientMaster.pdf`);
          // URL.revokeObjectURL(pdfBlob);
          Base64ToFileDownload(
            response.data.data,
            "application/pdf",
            "ClientMaster.pdf"
          );
        } else {
          // const zipBlob = new Blob([response.data], {
          //   type: "application/zip",
          // });
          // const file = new File([zipBlob], `XTS.zip`, {
          //   type: "application/zip",
          // });

          // saveAs(file, `ClientMaster.zip`);
          Base64ToFileDownload(
            response.data.data,
            "application/zip",
            "ClientMaster.zip"
          );
        }
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
      setIsFileUploaded(false);
      setIsClientCodeEntered(false);
      setClientCode("");
    }
  };
  const loadBase64ToInputField = (base64String) => {
    // Decode the base64 string to binary data
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Determine the MIME type based on the first few bytes
    let mimeType;
    if (
      byteArray[0] === 0x25 &&
      byteArray[1] === 0x50 &&
      byteArray[2] === 0x44 &&
      byteArray[3] === 0x46
    ) {
      mimeType = "application/pdf";
    } else {
      mimeType = "image/jpeg";
    }

    // Create a blob and file based on the mimeType
    const blob = new Blob([byteArray], { type: mimeType });
    const fileName =
      mimeType === "application/pdf" ? "Filename.pdf" : "Filename.jpg";
    const file = new File([blob], fileName, { type: mimeType });

    // Return the file object in a DataTransfer container
    const container = new DataTransfer();
    container.items.add(file);
    return container.files;
  };

  const handleModificationPdf = async (id) => {
    try {
      const response = await modificationPdfApi(id);
      console.log("Response:", response);

      if (response.status === 200) {
        const files = loadBase64ToInputField(response.data.data.file_proof);
        const file = files[0];

        const url = URL.createObjectURL(file);
        setModificationPdf(url);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const handleTechExcelPush = async (
    inwardId,
    clientCode,
    status,
    rejectReason
  ) => {
    try {
      const response = await techExcelPushApi(
        inwardId,
        clientCode,
        status,
        rejectReason
      );
      if (response.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      handleCatch(error);
    }
  };

  const [dormantData, setDormantData] = useState([]);
  const handleDormant = async (type, dormantType, globalFilter) => {
    setIsLoading(true);
    try {
      const response = await dormantApi(
        pagination,
        type,
        dormantType,
        globalFilter
      );
      if (response.status === 200) {
        setDormantData(response.data.data.data);
        setRowCount(response.data.data.row_count);
      } else {
        toast.success(response.data.message);
      }
    } catch (error) {
      // handleCatch(error);
      console.log("first", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [verificationDetail, setVerificationDetail] = useState();
  const getVerificationDetail = async () => {
    setIsLoading(true);
    try {
      const params = {
        page_size: pagination.pageSize,
        page_number: pagination.pageIndex+1,
        status :ApprovalStatus,
      };
      const response = await verificationDetails(params);
      if (response.data.status === "success") {
        let processedData = response.data.data.data.map((item) => {
          if (item.upload_proof) {
            const files = loadBase64ToInputField(item.upload_proof)[0];
            const url = URL.createObjectURL(files);
            return { ...item, upload_proof: url };
          } else {
            return item;
          }
        });
        setVerificationDetail(processedData);
        setRowCount(response?.data?.data?.row_count);
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadDocument = async (clientCode, file, status) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("client_code", clientCode);
      formData.append("file", file);

      const params = {
        status: status,
      };
      const response = await uploadProof(params, formData);
      if (response.status === 200) {
        toast.success("File upload successfully");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      handleCatch(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [documentUrl, setDocumentUrl] = useState("")
  const getClientMaster = async(clientCode) => {
    setIsLoading(true);
    setDocumentUrl("")
    try {
      const formData = new FormData()
      formData.append("client_code", clientCode)
      formData.append("csv_file", "")
      const response = await getClientMasterApi(formData)
      if(response.status === 200) {
        const file = loadBase64ToInputField(response.data.data)[0]
        const url = URL.createObjectURL(file)
        setDocumentUrl(url)
        toast.success(response.data.message)
      } else {
        toast.error(response.message)
      }
    } catch (error) {
      handleCatch(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSendEmail = async(inwardId, inwardType, inwardStatus) => {
    setIsLoading(true)
    try {
      const params = {
        inward_id: inwardId,
        inward_types: inwardType,
        inward_status : inwardStatus
      }
      const response = await sendEmailApi(params)
      if(response.status === 200){
        toast.success(response.data.message)
      } else {
        toast.error(response.message)
      }
    } catch (error) {
      handleCatch(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <KycOfflineContext.Provider
      value={{
        segmentAdditionData,
        handleSegmentAdditionData,
        rowCount,
        isLoading,
        pagination,
        setPagination,
        setApprovalStatus,
        ApprovalStatus,
        globalFilter,
        setGlobalFilter,
        handleClouserData,
        closureData,
        handleSegmentApproval,
        handleClosureApproval,
        handleRekyc,
        handleClientMaster,

        handleModificationPdf,
        modificationPdf,
        handleTechExcelPush,

        handleDormant,
        dormantData,

        setClientCode,
        clientCode,
        setFile,
        file,
        setIsFileUploaded,
        isFileUploaded,
        setIsClientCodeEntered,
        isClientCodeEntered,
        fileInputRef,

        getVerificationDetail,
        verificationDetail,
        uploadDocument,

        getClientMaster,
        documentUrl,
        handleSendEmail,
      }}
    >
      {props.children}
    </KycOfflineContext.Provider>
  );
};
