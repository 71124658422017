/*eslint-disable*/
import React, { useContext, useEffect, useState, createContext } from "react";

import { toast } from "react-toastify";

import { LoginContext } from "context/login/LoginApi";
import { commonGroupsApi, createNewChatApi, fetchAllFilesApi, fetchAllStarredMsgApi, fetchChatHistoryApi, fetchChatListApi, fetchEmployeesListApi, fetchGroupListApi, forwardMessageApi, groupModifyApi, messagePinStarApi, sendMessageApi } from "services/chat-app/chatApp";
import { addSessionStorage, getSessionStorage } from "utils/encrpytion";
import { handleCatch } from "utils/CommonFunctions";

export const ChatContext = createContext();

export default function ChatApis({ children }) {

    const [contactList, setContactList] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const [groupList, setGroupList] = useState([]);
    // state for infinite scroll
    const [pageCount, setPageCount] = useState(1);

    const [totalUnseenMessages, setTotalUnseenMessages] = useState(null);

    // online offline status to show in header
    const [userOnlineStatus, setUserOnlineStatus] = useState({});

    const [totalMsgCount, setTotalMsgCount] = useState(0);
    const [msgSent, setMsgSent] = useState(false);

    const [chatList, setChatList] = useState([]);

    const [newChatData, setNewChatData] = useState({});
    const [chatLoading, setChatLoading] = useState(false);

    const [forwardMsgDialog, setForwardMsgDialog] = useState(false);

    const [allStarredMsgs, setAllStarredMsgs] = useState([]);
    const [allFilesMsgs, setAllFilesMsgs] = useState([]);


    const [commonGroupsList, setCommonGroupsList] = useState([]);

    // Group Dialog
    const [openGroupDialog, setOpenGroupDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);


    // attachment dialog
    const [openAttachmentDialog, setOpenAttachmentDialog] = useState(false);
    const [attachmentUrl, setAttachmentUrl] = useState(null);
    const [attachmentName, setAttachmentName] = useState("");


    // clicked msg data state for menu features
    const [clickedMsgData, setClickedMsgData] = useState(null);

    const { login } = useContext(LoginContext)

    // updating live online/offline status to chatList & chatheader ----- Logic start
    const data = sessionStorage.getItem("isOfflineData");
    const parsedData = JSON.parse(data);
    const empId = getSessionStorage("currChatEmpId");
    // console.log('parsedData', parsedData)
    useEffect(() => {
        const updatedChatListArray = chatList?.map((item) => {
            const match = parsedData?.find((emp) => emp.emp_id === item.participants);
            if (match) {
                return { ...item, is_active: match.is_online };
            }
            return item;
        });
        const updatedContactArray = contactList?.map((item) => {
            const match = parsedData?.find((emp) => emp.emp_id === item.UID);
            if (match) {
                return { ...item, is_active: match.is_online };
            }
            return item;
        });

        const user_online = parsedData?.find((item) => { return item.emp_id == empId })

        setUserOnlineStatus(user_online)

        setChatList(updatedChatListArray);
        setContactList(updatedContactArray);
    }, [data, login]);
    // updating live online/offline status to chatList & chatheader ---- logic end


    //GET employees table data
    const fetchEmployeesList = async () => {
        try {
            const response = await fetchEmployeesListApi();
            setContactList(
                response.data.data.map((item, index) => ({ ...item, id: index + 1 }))
            );

        } catch (error) {
            console.log(error);
        }
    };

    //GET chats
    const fetchChatList = async () => {
        try {
            const response = await fetchChatListApi();
            setChatList(
                response.data.data.map((item, index) => ({ ...item, id: index + 1 }))
            );
        } catch (error) {
            console.log(error);
        }
    };

    //POST Create new chat
    const createNewChat = async (body) => {
        try {
            setChatHistory([]);
            const response = await createNewChatApi(body);

            setNewChatData(response.data.data);
            const chatId = response.data?.data.chat_id;
            addSessionStorage("currChatId", chatId);
            fetchChatHistory(chatId, 1);
            await fetchChatList();
        } catch (error) {
            console.log(error);
        }
    };

    //POST msgs get
    const fetchChatHistory = async (chatId, pageInit) => {
        setChatLoading(true);
        setMsgSent(false);
        setChatHistory([]);
        try {
            const body = {
                "chat_id": chatId,
                "page": pageInit ? pageInit : (pageCount === 0 ? 1 : pageCount),
                "limit": 15
            }
            const response = await fetchChatHistoryApi(body);
            setTotalMsgCount(response?.data?.data?.total_message_count);
            setChatHistory(response?.data?.data?.message_data);
        } catch (error) {
            console.log(error);
        } finally {
            setChatLoading(false);
        }
    };

    // POST send msg
    const sendMessage = async (payload) => {
        try {
            const formData = new FormData();
            formData.append("chat_id", payload.chat_id);
            if (payload?.message_text) {
                formData.append("message_text", payload.message_text);
            }
            if (payload?.revert_msg_id) {
                formData.append("revert_msg_id", payload.revert_msg_id);
            }
            if (payload?.attachment_file && payload.attachment_file.length > 0) {
                for (let i = 0; i < payload.attachment_file.length; i++) {
                    formData.append("attachment_file", payload.attachment_file[i]);
                }
            }
            const response = await sendMessageApi(formData);
            setMsgSent(true)
            if (payload.revert_msg_id) {
                await fetchChatHistory(payload.chat_id, 1)
            };
        } catch (error) {
            console.log('An error occurred:', error.message);
            toast.error(error.response?.data?.message)
            console.log('Error details:', error);
        }
    };

    //POST forward msg
    const handleForwardMessage = async (body) => {
        try {
            const response = await forwardMessageApi(body);
            console.log('response', response.data.data);
            toast.success(response?.data?.message);
        } catch (error) {
            console.log(error);
        }
    };

    // GET fetch group
    const fetchGroupList = async () => {
        setGroupList([])
        try {
            const response = await fetchGroupListApi();
            setGroupList(response.data.data)
        } catch (error) {
            console.log(error);
        }
    };

    //POST pin-star
    const handleMessagePinStar = async (body) => {
        try {
            const response = await messagePinStarApi(body);
            console.log("response", response);
            toast.success(response?.data?.message);
        } catch (error) {
            console.log(error);
        }
    };

    // GET all starred msg
    const fetchAllStarredMsg = async (chatId) => {
        setChatLoading(true);
        try {
            const body = {
                chat_id: chatId
            }
            const response = await fetchAllStarredMsgApi(body);
            setAllStarredMsgs(response.data.data)
        } catch (error) {
            console.log(error);
        } finally {
            setChatLoading(false)
        }
    };

    // GET all files
    const fetchAllFiles = async (chatId) => {
        setChatLoading(true)
        try {
            const body = {
                chat_id: chatId
            }
            const response = await fetchAllFilesApi(body);
            setAllFilesMsgs(response.data.data)
        } catch (error) {
            console.log(error);
        } finally {
            setChatLoading(false)
        }
    };

    // PUT Group modify
    const handleGroupModify = async (payload) => {
        try {
            const body = {
                "chat_id": payload.chat_id,
                "participant_ids": payload.participant_ids,
                "group_name": payload.group_name,
                "admins": payload.admins
            }
            const response = await groupModifyApi(body);
            console.log('response', response);
            toast.success(response?.data?.message)
            addSessionStorage("chatUserName", payload.group_name)
            fetchChatHistory(payload?.chat_id, 1);
            fetchChatList();
        } catch (error) {
            console.log(error);
        }
    };

    // POST common Group
    const fetchCommonGroups = async (empId) => {
        setCommonGroupsList([])
        try {
            const body = {
                "emp_id": empId,
            }
            const response = await commonGroupsApi(body);
            console.log('response', response);
            setCommonGroupsList(response?.data?.data)
        } catch (error) {
            handleCatch(error)
        }
    };

    return (
        <ChatContext.Provider
            value={{
                fetchEmployeesList,
                contactList,
                chatList,
                setChatList,

                newChatData,
                setNewChatData,
                createNewChat,

                fetchChatList,
                fetchChatHistory,
                chatHistory,
                setChatHistory,
                sendMessage,

                totalUnseenMessages, setTotalUnseenMessages,

                pageCount, setPageCount,
                totalMsgCount,
                chatLoading,
                setChatLoading,

                attachmentName, setAttachmentName,
                attachmentUrl, setAttachmentUrl,
                openAttachmentDialog, setOpenAttachmentDialog,

                msgSent, setMsgSent,

                userOnlineStatus, setUserOnlineStatus,

                forwardMsgDialog, setForwardMsgDialog,
                handleForwardMessage,
                fetchGroupList, groupList,

                handleMessagePinStar,

                clickedMsgData, setClickedMsgData,

                fetchAllStarredMsg, allStarredMsgs,

                fetchAllFiles, allFilesMsgs,
                handleGroupModify,

                openGroupDialog, setOpenGroupDialog,
                editDialog, setEditDialog,
                fetchCommonGroups,
                commonGroupsList, setCommonGroupsList
            }}
        >
            {children}
        </ChatContext.Provider>
    );
}
