import { useRoutes } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
// import { useContext } from "react";
// import { LoginContext } from "context/login/LoginApi";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // const { crmsModule } = useContext(LoginContext);
  return useRoutes([MainRoutes, AuthenticationRoutes, 
    // crmsModule
  ]);
}
