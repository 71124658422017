/*eslint-disable*/
import React, { useState, createContext } from "react";

import { toast } from "react-toastify";
import { exportKycCsvFileApi, fetchKraTableDataApi, handleFetchEsignPdfKraApi, handleFetchEsignXmlApi, kraDataPushApiApi } from "services/kyc/kycAll";

export const KycKraContext = createContext();

export const KycKraApis = (props) => {

  const [isError, setIsError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [kraData, setKraData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [sorting, setSorting] = useState([{ id: "user_id", desc: false }]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //KRA - fetch table data
  const fetchKraTableData = async (src, globalFilter) => {
    setKraData([]);
    setLoading(true);

    const params = {
      datasource: src,
      kyc_status: "completed",
      sort_field: sorting.length === 0 ? "user_id" : sorting[0].id,
      page_size: pagination.pageSize,
      page_number: pagination.pageIndex + 1,
      sort_order: sorting.length === 0 || sorting[0].desc ? "DESC" : "ASC",
      pan_type: "h",
      keyword: globalFilter || "",
    };

    try {
      const response = await fetchKraTableDataApi(params);
      console.log("response kra", response);
      setKraData(response.data.data.user_data);
      setRowCount(response.data.data.row_count);
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
      console.error("Error fetching data", error);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchEsignXml = async (pan_no) => {
    setLoading(true);
    try {
      const response = await handleFetchEsignXmlApi(pan_no);
      const blobUrl = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      console.log('response', response)

      link.href = blobUrl;
      link.setAttribute("download", `${pan_no}.xml`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error:", error);
        toast.error("Network Error");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFetchEsignPdfKra = async (pan_no) => {
    setLoading(true);
    try {
      const response = await handleFetchEsignPdfKraApi(pan_no);

      const url = URL.createObjectURL(response.data);
      window.open(url, "_blank");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      toast.error(error?.message);
    }
  };

  const kraDataPushApi = async (pan, src, globalFilter) => {
    setLoading(true);
    try {
      const params = {
        pan_no: pan
      };
      const response = await kraDataPushApiApi(params);

      if (response.status === 200) {
        fetchKraTableData(src, globalFilter);
        toast.success(response.data.message);
      } else {
        console.log("response errors", response);
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };


  const exportCsvFile = async (src) => {
    setLoading(true);
    try {
      const params = {
        datasource: src,
        kyc_status: "completed",
        pan_type: "h",
        is_download: true
      };

      const response = await exportKycCsvFileApi(params);
      console.log('response', response);

      const base64String = response.data.data;

      const binaryString = window.atob(base64String);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a link to download the file
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${src}.xlsx`);

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      handleCatch(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <KycKraContext.Provider
      value={{
        isError,
        sorting,
        rowCount,
        setSorting,
        pagination,
        setPagination,

        handleFetchEsignPdfKra,
        handleFetchEsignXml,
        kraDataPushApi,
        fetchKraTableData,
        kraData,
        setKraData,
        loading,
        exportCsvFile
      }}
    >
      {props.children}
    </KycKraContext.Provider>
  );
};
