/*eslint-disable*/

import React from "react";

// MUI Material-ui
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { purple } from '@mui/material/colors';

// Import your custom components, if needed
import CustomNoRowsOverlay from "ui-component/Tables/styleGridOverlay";
import CustomPagination from "ui-component/Tables/CustomPagination";

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles({
//   root: {
//     '& .MuiDataGrid-virtualScroller': {
//       '&::-webkit-scrollbar': {
//         width: '9px',
//         height: '9px'
//       },
//       '&::-webkit-scrollbar-thumb': {
//         backgroundColor: purple[100],
//         borderRadius: '6px'
//       },
//       '&::-webkit-scrollbar-thumb:hover': {
//         backgroundColor: purple[700]
//       },
//       '&::-webkit-scrollbar-track': {
//         backgroundColor: 'transparent'
//       }
//     }
//   }
// });

export default function MUIDataGridTable(props) {
  const {
    columns,
    rows,
    filename,
    onRowDoubleClick = false,
    handleRowDoubleClick = null,
    checkboxSelection = null,
    setSelectedIDs = () => [],
    selectedIDs
  } = props;

  // // Pending Bene Get Id in checkbox click
  // const handleRowSelection = (selectedRowIds) => {
  //   console.log('selectedRowIds', selectedRowIds);
  //   setSelectedIDs(selectedRowIds);
  // };

  //   const classes = useStyles();
  return (
    // <div className={classes.root}>
    <Box
      sx={{
        height: "72vh",
        width: "100%",
        backgroundColor: "white",
        borderRadius: "15px",
        "& .header": {
          backgroundColor: "rgb(201 183 229 / 48%)"
        }
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        onRowDoubleClick={onRowDoubleClick ? handleRowDoubleClick : null}
        checkboxSelection={checkboxSelection}
        onRowSelectionModelChange={(selectedRowIds) =>
          setSelectedIDs(selectedRowIds)
        }
        rowSelectionModel={selectedIDs}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50
            }
          }
        }}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          pagination: CustomPagination
        }}
        slotProps={{
          toolbar: {
            csvOptions: { fileName: filename },
            printOptions: { fileName: filename }
          }
        }}
        // density="compact"
        autoHeight={false}
        rowHeight={22}
        columnHeaderHeight={30}
      />
    </Box>
    // </div>
  );
}
