import { useRef } from 'react';

// Custom hook for managing API call cancellation
const useApiCancellation = () => {
  const abortControllers = useRef({});

  const cancelPreviousRequest = (key) => {
    if (abortControllers.current[key]) {
      abortControllers.current[key].abort();
    }
  };

  const createAbortController = (key) => {
    const controller = new AbortController();
    abortControllers.current[key] = controller;
    return controller.signal;
  };

  return { cancelPreviousRequest, createAbortController };
};

export default useApiCancellation;
