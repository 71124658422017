import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

export default function BasicDemo() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const data = {
      labels: [
        "Account",
        "Compliance",
        "DP",
        "Security",
        "RMS",
        "Customer Care",
        "Promotor",
        "Digital",
        "Software",
        "Research",
        "WMS",
        "Dealing",
        "Sales",
        "B2B",
        "MIS",
        "EKYC",
        "IT",
        "HR",
        "ADMIN",
        "Managment",
        "Other",
      ],
      datasets: [
        {
          label: "Assigned Inventory",
          data: [
            65, 59, 80, 81, 56, 55, 40, 60, 30, 100, 10, 50, 56, 90, 15, 63, 89,
            54, 30, 100, 200
          ],
          backgroundColor: [
            "rgba(255, 159, 64, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(153, 102, 255, 0.2)",
          ],
          borderColor: [
            "rgb(255, 159, 64)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(153, 102, 255)",
          ],
          borderWidth: 1,
        },
      ],
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <div className="card">
      <Chart type="bar" data={chartData} options={chartOptions} />
    </div>
  );
}
