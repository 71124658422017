/*eslint-disable*/
import { LoginContext } from "context/login/LoginApi";
import React, { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AuthCheck = ({ children }) => {

  const { setAuthCheckErrorData } = useContext(LoginContext)
  const navigate = useNavigate();
  const authCheckFunction = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/user/verify-user`,
        {
          method: "GET",
          headers: {
            authToken: localStorage.getItem("authToken"),
            accept: "application/json"
          }
        }
      );
      const resJson = await response.json();

      if (resJson.status === "error") {
        // toast.error(resJson.message);
        setAuthCheckErrorData(resJson.message)
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      console.error("Failed to verify user", error);
      // toast.error("An error occurred during authentication check");
      navigate("/login");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("authToken") != null) {
      authCheckFunction();
    }
  }, [navigate]);

  if (!localStorage || !localStorage.authToken) {
    return <Navigate to={"/login"} />;
  }

  // if (loading) {
  //   return <div>Loading...</div>; // Or a spinner/loading component
  // }

  return <>{children}</>; // Render child components if authenticated
};

export default AuthCheck;

// import React from "react";
// import { Navigate } from "react-router";
// // import { toast } from "react-toastify";
// // import { toast } from 'react-toastify';

// // const authCheckFunction = async () => {
// //   const response = await fetch(
// //     `${process.env.REACT_APP_URL}/v1/user/verify-user`,
// //     {
// //       method: "GET",
// //       headers: {
// //         authToken: localStorage.authToken,
// //         accept: "application/json"
// //       }
// //     }
// //   );

// //   const resJson = await response.json();

// //   console.log("resJson", resJson);
// //   if (resJson.status == "error") {
// //     toast.error(resJson.message);
// //     return <Navigate to={"/login"} />;
// //   }
// // };

// export default function AuthRequired({ children }) {
//   if (!localStorage || !localStorage.authToken) {
//     // toast.success('Your login session has expired. Please log in again.');
//     return <Navigate to={"/login"} />;
//   }

//   return children;
// }
